/* eslint-disable @typescript-eslint/no-explicit-any */ // todo because Kurento doesn't have types, it's js only
import { Logger } from '@utils/logger';
import { WsKurentoOutgoingTopicType } from '@utils/websocket.topics';
import { WebRtcPeer } from 'kurento-utils';
import { IKurentoWsMessage } from 'src/contexts/kurento.context';

export type ParticipantSelectedType = 'select' | 'success' | 'warning' | 'failed' | 'call' | 'chat' | 'done' | null;

export interface IParticipantPerson {
    firstName?: string;
    id?: string;
    lastName?: string;
    middleName?: string;
    role?: string;
    trustee?: {
        firstName?: string;
        lastName?: string;
        kinship?: string;
        middleName?: string;
    };
}

export class KurentoParticipant {
    public rtcPeer: WebRtcPeer;
    public rtcPeerScreen: WebRtcPeer;

    private _id: string;
    private _person: IParticipantPerson;
    private _isHost: boolean;
    private _sendMessageCallback: (message: IKurentoWsMessage) => void;
    private _connectedDate: Date;

    private _inputAudioEnabled;
    private _outputAudioEnabled;
    private _videoEnabled;

    // only for host
    public outputAudioEnabledForAll;
    public inputAudioEnabledForAll;
    public videoEnabledForAll;

    private _video: HTMLVideoElement;
    private _screenSharingVideo: HTMLVideoElement;
    private _button: HTMLButtonElement;

    public screenSharingRequested = false;

    private _screenSharingActive = false;
    public get screenSharingActive() {
        return this._screenSharingActive;
    }
    public set screenSharingActive(value: boolean) {
        this._screenSharingActive = value;
    }

    public get id() {
        return this._id;
    }
    public get person() {
        return this._person;
    }
    public get isHost() {
        return this._isHost;
    }
    public get video() {
        return this._video;
    }
    public get screenSharingVideo() {
        return this._screenSharingVideo;
    }
    public get button() {
        return this._button;
    }
    public get connectedDate() {
        return this._connectedDate;
    }
    public get videoEnabled() {
        return this._videoEnabled;
    }
    public set videoEnabled(value: boolean) {
        this._videoEnabled = value;
    }
    public get inputAudioEnabled() {
        return this._inputAudioEnabled;
    }
    public set inputAudioEnabled(value: boolean) {
        this._inputAudioEnabled = value;
    }
    public get outputAudioEnabled() {
        return this._outputAudioEnabled;
    }
    public set outputAudioEnabled(value: boolean) {
        this._outputAudioEnabled = value;
    }

    public screenSharingCallback: () => any;

    constructor(name: string, person: IParticipantPerson, host = false, sendMessageCallback: any) {
        this._id = name;
        this._person = person;

        this._isHost = host;

        this._outputAudioEnabled = false;
        this._inputAudioEnabled = true;
        this._videoEnabled = true;

        this.outputAudioEnabledForAll = false;
        this.inputAudioEnabledForAll = true;
        this.videoEnabledForAll = true;

        this._sendMessageCallback = sendMessageCallback;

        this._prepareHtmlElements();

        this._connectedDate = new Date();
    }

    public offerToReceiveVideo(error: string | undefined, sdp: string) {
        if (error) {
            return Logger.error('sdp offer error');
        }
        const msg: IKurentoWsMessage = {
            type: WsKurentoOutgoingTopicType.RECEIVE_VIDEO_FROM,
            payload: { sdpOffer: sdp, sender: this.id, source: 'WEB_CAMERA' },
        };
        this._sendMessageCallback(msg);
    }

    public offerToReceiveVideoScreen(error: string | undefined, sdp: string) {
        if (error) {
            return Logger.error('sdp share offer error');
        }
        const msg: IKurentoWsMessage = {
            type: WsKurentoOutgoingTopicType.RECEIVE_VIDEO_FROM,
            payload: { sdpOffer: sdp, sender: this.id, source: 'SCREEN' },
        };

        if (this.isHost) {
            const msg: IKurentoWsMessage = {
                type: WsKurentoOutgoingTopicType.START_VIDEO_SCREEN_RECORD,
                payload: { sdpOffer: sdp },
            };
            this._sendMessageCallback(msg);
        }

        this._sendMessageCallback(msg);
    }

    public onIceCandidate(candidate: any) {
        const message: IKurentoWsMessage = {
            type: WsKurentoOutgoingTopicType.ON_ICE_CANDIDATE,
            payload: {
                candidate: candidate,
                fhirId: this._id,
                source: 'WEB_CAMERA',
            },
        };
        this._sendMessageCallback(message);
    }

    public onIceCandidateScreen(candidate: any) {
        const message: IKurentoWsMessage = {
            type: WsKurentoOutgoingTopicType.ON_ICE_CANDIDATE,
            payload: {
                candidate,
                fhirId: this._id,
                source: 'SCREEN',
            },
        };
        this._sendMessageCallback(message);
    }

    public changeInputMute(isHost = false): void {
        const msg: IKurentoWsMessage = {
            type: WsKurentoOutgoingTopicType.ON_OFF_MEDIA_TYPE,
            payload: {
                value: isHost ? !this.inputAudioEnabledForAll : !this._inputAudioEnabled,
                type: 'HOST_AUDIO',
            },
        };
        if (!isHost) {
            msg.payload.targetId = this._id;
        }

        this._sendMessageCallback(msg);
        // this._inputAudioEnabled = !this._inputAudioEnabled;
    }

    public changeOutputMute(isHost = false): void {
        const msg: IKurentoWsMessage = {
            type: WsKurentoOutgoingTopicType.ON_OFF_MEDIA_TYPE,
            payload: {
                value: isHost ? !this.outputAudioEnabledForAll : !this._outputAudioEnabled,
                type: 'PARTICIPANT_AUDIO',
            },
        };
        if (!isHost) {
            msg.payload.targetId = this._id;
        }

        this._sendMessageCallback(msg);
        // this._outputAudioEnabled = !this._outputAudioEnabled;
    }

    public changeVideoMute(isHost = false): void {
        const msg: IKurentoWsMessage = {
            type: WsKurentoOutgoingTopicType.ON_OFF_MEDIA_TYPE,
            payload: {
                value: isHost ? !this.videoEnabledForAll : !this._videoEnabled,
                type: 'HOST_VIDEO',
            },
        };
        if (!isHost) {
            msg.payload.targetId = this._id;
        }

        this._sendMessageCallback(msg);
        // this._videoEnabled = !this._videoEnabled;
    }

    public leaveRoom(wasKicked = false): void {
        this._sendMessageCallback({
            type: wasKicked ? WsKurentoOutgoingTopicType.KICK_PARTICIPANT : WsKurentoOutgoingTopicType.LEAVE_SESSION,
            payload: {
                targetId: this._id,
            },
        });
    }

    public toggleScreenSharing(state = true): void {
        this.screenSharingRequested = state;

        if (this.screenSharingCallback) {
            this.screenSharingCallback();
        }
    }

    public dispose() {
        // this.procedures.forEach((x) => x.dispose());

        this.rtcPeer?.dispose();
        this.rtcPeerScreen?.dispose();
    }

    private _prepareHtmlElements(): void {
        this._video = document.createElement('video');
        this._video.id = 'video-web-' + this._id;
        this._video.classList.add('kurento-participant-video');
        this._video.autoplay = true;
        this._video.controls = false;

        if (!this._isHost) {
            this._screenSharingVideo = document.createElement('video');
            this._screenSharingVideo.id = 'video-screen-' + this._id;
            this._screenSharingVideo.autoplay = true;
            this._screenSharingVideo.controls = false;
        }
    }
}
