import {
    PatientAppointmentDetailsDTO,
    PatientAppointmentDetailsDTOServiceType,
    getPatientAppointmentDetails,
    updateStatusEncounter,
} from '@api/mainServiceAPI';
import { ColorControllerBoard, ProcedureCanvas, VideoParticipant } from '@components';
import { ProcedureTypeEnum } from '@enums';
import { Bubble, ChatWith3DotsIcon, CloseIcon, Dino1, Dino2, Dino2_3, Dino3, Dino5, Glasses, ScreenChangedIcon, SmileIcon } from '@icons';
import { IChatMessage, IChatMessageDto, IPatientProcedureInteraction } from '@models';
import { setPatientWorkspaceSettings } from '@slicePatient';
import { selectActiveProcedure, selectActiveScreenSharing, setActiveProcedure, setActiveScreenSharing } from '@sliceProcedures';
import { selectCurrentProfile } from '@sliceUser';
import { StompSubscription } from '@stomp/stompjs';
import { Logger } from '@utils/logger';
import { openFullscreen } from '@utils/utils';
import { WsProcedureTopicType, WsTopic } from '@utils/websocket.topics';
import { Button, Col, Modal, Row, message } from 'antd';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Chat } from 'src/components/Chat/Chat';
import { KurentoContext } from 'src/contexts/kurento.context';
import { WebsocketContext } from 'src/contexts/ws.context';
import { IGeneralProcedure } from 'src/models/general-procedure.model';
import { SessionParticipant } from 'src/models/session-participant';
import { LSScreenSettingsPatientSettings, ScreenSettingsLocalStorage } from 'src/services';
import styles from './PatientAppointmentPage.module.scss';

export const PatientAppointmentPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { connectToAppointment, closeSession, meAsParticipant, host, kurentoConnected } = useContext(KurentoContext);

    const { wsClient, send, wsReconnected } = useContext(WebsocketContext);

    const myParticipantId = useRef<string | undefined>();
    const hostId = useRef<string | undefined>('');
    const activeProcedureRef = useRef<IGeneralProcedure | null>();
    const procedurePausedRef = useRef<boolean>();

    const currentProfile = useSelector(selectCurrentProfile);
    const activeProcedure = useSelector(selectActiveProcedure);
    const activeScreenSharing = useSelector(selectActiveScreenSharing);

    const [hostSessionParticipant, setHostSessionParticipant] = useState<SessionParticipant | undefined>();
    const [meAsSessionParticipant, setMeAsSessionParticipant] = useState<SessionParticipant | undefined>();

    const [colorPicker, setColorPicker] = useState<{
        red: number;
        blue: number;
        green: number;
    }>({
        red: 0,
        blue: 0,
        green: 0,
    });
    const [screenSettings, setScreenSettings] = useState<LSScreenSettingsPatientSettings>();

    const [procedureConfirmationModalOpen, setProcedureConfirmationModalOpen] = useState(false);
    const [screenSharingConfirmationModalOpen, setScreenSharingConfirmationModalOpen] = useState(false);

    const [dpi, setDpi] = useState(0);
    const [userDistance, setUserDistance] = useState(1);
    const [procedureDistance, setProcedureDistance] = useState(1);
    const [calibrationStarted, setCalibrationStarted] = useState(false);
    const [startedTime, setStartedTime] = useState<Date | null>(null);
    const [wsMessage, setWsMessage] = useState<any>(null);
    const [isLastProcedure, setIsLastProcedure] = useState(false);
    const [proceduresStarted, setProceduresStarted] = useState(false);
    const [procedurePaused, setProcedurePaused] = useState(false);
    const [procedureIsActive, setProcedureIsActive] = useState(false);
    const [hostInfo, setHostInfo] = useState<{ name?: string; avatar?: string; role?: 'nurse' | 'doctor' }>({});
    const [appointmentDetails, setAppointmentDetails] = useState<PatientAppointmentDetailsDTO>();

    // not used for now
    const [needChangeGlasses, setNeedChangeGlasses] = useState(false);
    const [connectionTime, setConnectionTime] = useState('');
    const [isDiagonalUpdated, setIsDiagonalUpdated] = useState(false);

    const [leaveRoomConfirmationModalOpen, setLeaveRoomConfirmationModalOpen] = useState(false);

    useEffect(() => {
        activeProcedureRef.current = activeProcedure;
    }, [activeProcedure]);

    useEffect(() => {
        procedurePausedRef.current = procedurePaused;
    }, [procedurePaused]);

    useEffect(() => {
        if (wsClient) {
            const keyupHandler = (e: any) => {
                if (!!activeProcedureRef.current && (e.key == ' ' || e.code == 'Space' || e.keyCode == 32)) {
                    send('/msg/gateway-ws/message', '/procedure/' + hostId.current, {
                        type: WsProcedureTopicType.togglePauseProcedure,
                        participantId: myParticipantId.current,
                        procedureId: activeProcedureRef.current?.id,
                        state: !procedurePausedRef.current,
                    });
                }
            };

            window.addEventListener('keyup', keyupHandler);

            return () => {
                window.removeEventListener('keyup', keyupHandler);
            };
        }
    }, [wsClient]);

    useEffect(() => {
        if (host) {
            hostId.current = host.id;

            const participant = new SessionParticipant();
            participant.kurentoParticipant = host;
            participant.fhirId = host.id;
            setHostSessionParticipant(participant);
        } else {
            hostId.current = undefined;
            setHostSessionParticipant(undefined);

            dispatch(setActiveProcedure(null));
            dispatch(setActiveScreenSharing(false));
            setProcedureIsActive(false);
            setProcedurePaused(false);
            setNeedChangeGlasses(false);
            setIsLastProcedure(false);
            setCalibrationStarted(false);
        }
    }, [host]);

    useEffect(() => {
        if (meAsParticipant) {
            setMeAsSessionParticipant((prev) => {
                if (prev) {
                    prev.kurentoParticipant = meAsParticipant;
                    prev.fhirId = meAsParticipant.id;
                }

                return prev;
            });
        }
    }, [meAsParticipant]);

    useEffect(() => {
        if (wsReconnected) {
            location.reload(); // todo hotfix
        }
    }, [wsReconnected]);

    useEffect(() => {
        if (wsMessage) {
            switch (wsMessage.type) {
                case WsProcedureTopicType.requestScreenSharing:
                    {
                        if (wsMessage.state as boolean) {
                            setScreenSharingConfirmationModalOpen(true);
                        } else {
                            setScreenSharingConfirmationModalOpen(false);
                            dispatch(setActiveScreenSharing(false));
                        }
                    }
                    break;
                case WsProcedureTopicType.startProcedure:
                    {
                        setProceduresStarted(true);
                        setProcedureDistance(wsMessage.distance || 0);

                        dispatch(setActiveProcedure(wsMessage.procedure as IGeneralProcedure));
                        setProcedureIsActive(true);
                        setProcedurePaused(false);
                        setNeedChangeGlasses(false);
                    }
                    break;
                case WsProcedureTopicType.stopProcedure:
                    dispatch(setActiveProcedure(null));
                    setProcedureIsActive(false);
                    setProcedurePaused(false);
                    setNeedChangeGlasses(false);
                    setIsLastProcedure((wsMessage.procedureIsLast as boolean) || false);
                    setCalibrationStarted(false);
                    break;
                case WsProcedureTopicType.togglePauseProcedure:
                    setProcedurePaused(wsMessage.state as boolean);
                    break;
                case WsProcedureTopicType.toggleNeedChangeGlasses:
                    setNeedChangeGlasses(wsMessage.state as boolean);
                    break;

                case WsProcedureTopicType.chatMessage:
                    {
                        const msg = wsMessage.message as IChatMessage;
                        meAsSessionParticipant!.chatHistory = [...meAsSessionParticipant!.chatHistory, msg];

                        const message: IChatMessage = {
                            id: msg.id,
                            senderId: currentProfile?.fhirId,
                        };

                        send('/msg/gateway-ws/message', '/procedure/' + msg?.senderId, {
                            type: WsProcedureTopicType.messageSent,
                            message,
                        });
                    }
                    break;
                case WsProcedureTopicType.messageSent:
                    {
                        const msg = wsMessage.message as IChatMessage;
                        const message = meAsSessionParticipant?.chatHistory.find((x) => x.id === msg.id);

                        if (message) {
                            message.sent = true;
                        }
                    }
                    break;
                case WsProcedureTopicType.messageViewed:
                    {
                        const msg = wsMessage.message as IChatMessage;
                        const message = meAsSessionParticipant?.chatHistory.find((x) => x.id === msg.id);

                        if (message) {
                            message.viewed = true;
                        }
                    }
                    break;
                case WsProcedureTopicType.startCalibration:
                    setCalibrationStarted(wsMessage.state);
                    break;
                case WsProcedureTopicType.screenSettingsChange:
                    onScreenSettingsChanged(wsMessage.body?.diagonal);
                    break;
                case WsProcedureTopicType.screenSettingsUpdate:
                    {
                        const screenSettingsLS = ScreenSettingsLocalStorage.getScreenSettings(currentProfile?.fhirId);
                        if (screenSettingsLS && screenSettingsLS.diagonal) {
                            const id = host?.id || currentProfile?.fhirId;
                            console.log(id);

                            send('/msg/gateway-ws/message', '/procedure/' + id, {
                                type: WsProcedureTopicType.screenSettingsUpdate,
                                body: {
                                    diagonal: screenSettingsLS.diagonal,
                                },
                            });
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }, [wsMessage, meAsParticipant]);

    useEffect(() => {
        let subscription: StompSubscription;

        if (currentProfile && wsClient) {
            const workspacePrepared = checkLs();

            if (!workspacePrepared) {
                navigate('/');
                return;
            }

            getPatientAppointmentDetails(currentProfile!.fhirId!, searchParams.get('appointmentId')!).then((res) => {
                if (res.data.encounterStatus === 'finished' || res.data.encounterStatus === 'cancelled') {
                    message.warning(t("patient_appointment.appointment_finished"));
                    navigate('/');
                    return;
                }

                forceFullScreenMode();
                document.addEventListener('fullscreenchange', forceFullScreenMode);

                updateStatusEncounter(res.data.encounterId!, { aidEncounterStatus: 'in-progress' });

                setAppointmentDetails(res.data);
                setMeAsSessionParticipant(new SessionParticipant({ patientPractitionerChat: res.data.patientPractitionerChat }));
                setHostInfo({
                    name: res.data.practitionerName,
                    role: res.data.practitionerRoleCode || 'nurse',
                    avatar: res.data.practitionerPhoto,
                });
                connectToAppointment(currentProfile.fhirId!, searchParams.get('appointmentId')!);

                subscription = wsClient.subscribe(WsTopic.procedureTopic + currentProfile.fhirId, (message: any) => {
                    const msg = JSON.parse(message.body);
                    console.log('procedureTopic', msg);

                    setWsMessage(msg);
                });

                myParticipantId.current = currentProfile.fhirId;
            });
        }

        return () => {
            subscription?.unsubscribe();
            dispatch(setActiveScreenSharing(false));
            dispatch(setActiveProcedure(null));

            document.removeEventListener('fullscreenchange', forceFullScreenMode);
        };
    }, [currentProfile, wsClient]);

    useEffect(() => {
        if (kurentoConnected) {
            setStartedTime(new Date());
        }
    }, [kurentoConnected]);

    useEffect(() => {
        if (startedTime) {
            // just show current connection time
            const connectionTimeInterval = setInterval(() => {
                const milliseconds = new Date().getTime() - startedTime.getTime();
                setConnectionTime(new Date(milliseconds).toISOString().slice(11, 19));
            }, 1000);

            return () => {
                clearInterval(connectionTimeInterval);
            };
        }
    }, [startedTime]);

    useEffect(() => {
        if (!meAsSessionParticipant) {
            // todo
            // Logger.error('No meAsSessionParticipant');
            return;
        }
        meAsSessionParticipant.kurentoParticipant?.toggleScreenSharing(activeScreenSharing.share);
    }, [activeScreenSharing]);

    const checkLs = (): boolean => {
        const screenSettingsLS = ScreenSettingsLocalStorage.getScreenSettings(currentProfile?.fhirId);

        if (screenSettingsLS) {
            setScreenSettings(screenSettingsLS);
            setColorPicker(screenSettingsLS.colors);
            const diagonal = screenSettingsLS?.diagonal;
            if (!!diagonal) {
                const screenResolutionXpx = screen.width * window.devicePixelRatio;
                const screenResolutionYpx = screen.height * window.devicePixelRatio;
                const diagonalPx = Math.sqrt(screenResolutionXpx ** 2 + screenResolutionYpx ** 2);
                const dpiMm = diagonalPx / +(+diagonal / 25.4);

                setDpi(dpiMm);
            } else {
                Logger.error(t("patient_appointment.diagonal_not_specified"));
                alert(t("patient_appointment.diagonal_not_specified"));
                return false;
            }

            const userDistance = screenSettingsLS?.distance;
            if (!!userDistance) {
                setUserDistance(+userDistance * 0.01);
            } else {
                Logger.error(("patient_appointment.diagonal_not_specified"));
                alert(t("patient_appointment.distance_not_specified"));
                return false;
            }

            return true;
        } else {
            Logger.error(t("patient_appointment.settings_not_calibrated"));
            alert(t("patient_appointment.settings_not_calibrated"));
            return false;
        }
    };

    const handleChangeColor = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setColorPicker({ ...colorPicker, [name]: +value });
    };

    const decreaseColorBar = (name: 'red' | 'blue' | 'green') => {
        setColorPicker({ ...colorPicker, [name]: colorPicker[name] - 1 });
    };

    const increaseColorBar = (name: 'red' | 'blue' | 'green') => {
        setColorPicker({ ...colorPicker, [name]: colorPicker[name] + 1 });
    };

    const handleColorSuccess = (colorPicker?: { red: number; blue: number; green: number }) => {
        ScreenSettingsLocalStorage.setScreenSettings(currentProfile?.fhirId, { ...screenSettings!, colors: colorPicker! });
        send('/msg/gateway-ws/message', '/procedure/' + host?.id, {
            type: WsProcedureTopicType.calibrationReady,
            state: true,
        });
    };

    const forceFullScreenMode = () => {
        if (!document.fullscreenElement) {
            const confirmationPrompt = Modal.info({
                content: (
                    <p className="confirmPopup_text">{t("patient_appointment.full_screen_annotation")}</p>
                ),
                footer: [
                    <Button
                        type="primary"
                        key="close"
                        className="confirmPopup_button w-100"
                        onClick={() => {
                            if (process.env.REACT_APP_PRODUCTION === 'true') {
                                openFullscreen();
                                confirmationPrompt.destroy();
                            } else {
                                confirmationPrompt.destroy();
                            }
                        }}
                    >
                        {t("patient_appointment.good")}
                    </Button>,
                ],
            });
        }
    };

    const leaveRoom = () => {
        closeSession(true);
        setLeaveRoomConfirmationModalOpen(false);
    };

    const runProcedure = () => {
        setProcedureConfirmationModalOpen(false);
    };

    const confirmScreenSharing = () => {
        setScreenSharingConfirmationModalOpen(false);
        dispatch(setActiveScreenSharing(true));

        const screenSettingsLS = ScreenSettingsLocalStorage.getScreenSettings(currentProfile?.fhirId);
        if (screenSettingsLS && screenSettingsLS.diagonal) {
            send('/msg/gateway-ws/message', '/procedure/' + host?.id, {
                type: WsProcedureTopicType.screenSettingsUpdate,
                body: {
                    diagonal: screenSettingsLS.diagonal,
                },
            });
        }
    };

    const procedureInteractionHandler = (interaction: IPatientProcedureInteraction) => {
        send('/msg/gateway-ws/message', '/procedure/' + host?.id, {
            type: WsProcedureTopicType.procedureInteraction,
            participantId: currentProfile?.fhirId,
            procedureId: activeProcedure?.id,
            interaction,
        });
    };

    const callDoctor = () => {
        send('/msg/gateway-ws/message', '/procedure/' + hostId.current, {
            type: WsProcedureTopicType.callDoctor,
            participantId: myParticipantId.current,
        });
    };

    const sendChatMessage = (messageText: string) => {
        if (!messageText) {
            return;
        }

        const message: IChatMessage = {
            id: nanoid(),
            text: messageText,
            createdDate: new Date(),
            senderId: currentProfile?.fhirId,
            receiverId: host?.id,
        };

        send('/msg/gateway-ws/message', '/procedure/' + host?.id, {
            type: WsProcedureTopicType.chatMessage,
            message,
        });

        meAsSessionParticipant!.chatHistory = [...meAsSessionParticipant!.chatHistory, message];

        // to save message in the history
        if (meAsSessionParticipant?.chatId) {
            const messageDto: IChatMessageDto = {
                type: 'NEW_MSG',
                conversationId: meAsSessionParticipant?.chatId,
                payload: messageText,
                sender: currentProfile?.fhirId as any,
            };

            send('/msg/chat/message', '/chat/' + meAsSessionParticipant?.chatId, messageDto);
        }
    };

    const markChatMessageAsRead = (messageId: string): void => {
        const message: IChatMessage = {
            id: messageId,
            senderId: currentProfile?.fhirId,
        };

        send('/msg/gateway-ws/message', '/procedure/' + host?.id, {
            type: WsProcedureTopicType.messageViewed,
            message,
        });

        // to save message in the history
        send('/msg/chat/message', '/chat/' + host?.id, {
            type: 'MSG_SET_READ',
            conversationId: host?.id,
            messageId,
            sender: currentProfile?.fhirId,
            read: true,
        });
    };

    const renderHostFullScreenVideo = (hideVideo?: boolean) => {
        return (
            !!hostSessionParticipant?.kurentoParticipant && (
                <div
                    className={classNames(
                        styles.fullScreenVideo,
                        hostSessionParticipant?.kurentoParticipant?.videoEnabled ? 'd-flex' : 'd-none',
                        !hideVideo ? 'd-flex' : 'd-none',
                    )}
                >
                    <VideoParticipant fullScreenVideo participant={hostSessionParticipant} hostView={true} withoutTitles={true} />

                    {!!meAsSessionParticipant?.kurentoParticipant && (
                        <div className={styles.preview}>
                            <VideoParticipant participant={meAsSessionParticipant} hostView={true} withoutTitles={true} />
                        </div>
                    )}
                </div>
            )
        );
    };

    const onScreenSettingsChanged = (diagonal: string) => {
        const screenSettingsLS = ScreenSettingsLocalStorage.getScreenSettings(currentProfile?.fhirId);
        if (screenSettingsLS) {
            screenSettingsLS.diagonal = diagonal;
            ScreenSettingsLocalStorage.setScreenSettings(currentProfile?.fhirId, screenSettingsLS);
            dispatch(setPatientWorkspaceSettings(ScreenSettingsLocalStorage.getScreenSettings(currentProfile?.fhirId)!));
            checkLs();
            setIsDiagonalUpdated(true);
        }
    };

    return (
        <div className={styles.wrapper} onClick={() => isDiagonalUpdated && setIsDiagonalUpdated(false)}>
            {kurentoConnected ? (
                <>
                    <section className={styles.conference}>
                        <div
                            className={classNames(
                                styles.room,
                                (!hostSessionParticipant || isLastProcedure || (!activeProcedure && proceduresStarted)) && styles.hostNotHere,
                                !activeProcedure && styles.procedureNotStarted,
                                needChangeGlasses && styles.needChangeGlasses,
                            )}
                        >
                            {calibrationStarted && (
                                <div className={styles.colorController}>
                                    <ColorControllerBoard
                                        increaseColorBar={increaseColorBar}
                                        decreaseColorBar={decreaseColorBar}
                                        handleChangeColor={handleChangeColor}
                                        colorPicker={colorPicker}
                                        onSaveColor={handleColorSuccess}
                                    />
                                </div>
                            )}

                            {(appointmentDetails?.serviceType === PatientAppointmentDetailsDTOServiceType.consultation ||
                                appointmentDetails?.serviceType === PatientAppointmentDetailsDTOServiceType.diagnostics) &&
                                renderHostFullScreenVideo(procedureIsActive)}

                            {!hostSessionParticipant ? (
                                // ===== if host is not here
                                <div className={styles.dino1}>
                                    <Dino1 />
                                    <div className={styles.bubble}>
                                        <p>{t(`appointmentPage.texts.${hostInfo.role}WillComeSoon`)}</p>
                                        <Bubble />
                                    </div>
                                </div>
                            ) : (
                                // ===== if host is here
                                <>
                                    {procedureIsActive ? (
                                        <>
                                            {procedurePaused && (
                                                <div className={styles.pause_overlay}>
                                                    <span className={styles.pause_text}>{t("patient_appointment.pause")}</span>
                                                </div>
                                            )}

                                            {needChangeGlasses && (
                                                <div className={styles.putOutGlassesWrapper}>
                                                    <Dino5 className={styles.dino5} />
                                                    <div className={styles.putOutGlasses}>
                                                        <Glasses />
                                                        <div className={classNames('h2 mt-5 mb-5', styles.instruction)}>
                                                            {currentProfile?.userProfileDetailsDTO?.firstName},{' '}
                                                            {activeProcedure?.type === ProcedureTypeEnum.BIFIXATION_RECOVERY
                                                                ? t('appointmentPage.texts.putOutPrism')
                                                                : t('appointmentPage.texts.putOutGlasses')}
                                                        </div>
                                                        {/* <Button type="primary" className={classNames('w-100', styles.bigBtn)}>
                                                            {t('appointmentPage.labels.iPutOutGlasses')}
                                                        </Button> */}
                                                    </div>
                                                </div>
                                            )}

                                            {/* // ===== if procedure started */}
                                            <ProcedureCanvas
                                                procedure={activeProcedure}
                                                dpi={dpi}
                                                distance={procedureDistance || userDistance}
                                                interactionEvent={procedureInteractionHandler}
                                                paused={procedurePaused}
                                                colors={colorPicker}
                                            />
                                        </>
                                    ) : // ===== if procedure not started
                                    proceduresStarted ? (
                                        isLastProcedure ? (
                                            <>
                                                {/* all procedures finished */}
                                                <div className={classNames(styles.dino1, styles.step4)}>
                                                    <Dino1 />
                                                    <div className={styles.bubble}>
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: t('appointmentPage.texts.hurray'),
                                                            }}
                                                        ></p>
                                                        <Bubble />
                                                    </div>
                                                </div>
                                                <Button
                                                    type="primary"
                                                    className={classNames(styles.endProcedure, styles.bigBtn)}
                                                    onClick={() => setLeaveRoomConfirmationModalOpen(true)}
                                                >
                                                    {t('appointmentPage.labels.finishTest')}
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                {/* todo improve these conditions */}
                                                {appointmentDetails?.serviceType === PatientAppointmentDetailsDTOServiceType.consultation ||
                                                appointmentDetails?.serviceType === PatientAppointmentDetailsDTOServiceType.diagnostics ? (
                                                    // renderHostFullScreenVideo()
                                                    <></>
                                                ) : (
                                                    <div className={classNames(styles.dino1, styles.step4)}>
                                                        <Dino1 />
                                                        <div className={styles.bubble}>
                                                            <p>{t('appointmentPage.texts.nextProcedureWillStartSoon')}</p>
                                                            <Bubble />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )
                                    ) : appointmentDetails?.serviceType === PatientAppointmentDetailsDTOServiceType.consultation ||
                                      appointmentDetails?.serviceType === PatientAppointmentDetailsDTOServiceType.diagnostics ? (
                                        <></>
                                    ) : (
                                        <div className={styles.dino2_3}>
                                            <div className={styles.dino2_3img}>
                                                <Dino2_3 />
                                            </div>
                                            <div className={styles.bubble}>
                                                <p>{t('appointmentPage.texts.procedureWillStartSoon')}</p>
                                                <Bubble />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {/* don't hide it through "&&", only with css ! Because there will be some issues with <video> from Kurento */}
                        <aside className={classNames(styles.hostSide, !!procedureIsActive && 'd-none')}>
                            <div className={styles.aside_content}>
                                <Row justify="space-between">
                                    <Col className="d-flex align-items-center">
                                        {/* <span className={styles.indicator}></span>
                                        <span>{connectionTime}</span> */}
                                        <h5>{t('appointmentPage.titles.' + appointmentDetails?.serviceType)}</h5>
                                    </Col>
                                    <Col>
                                        <Button
                                            className={styles.closeBtn}
                                            type="default"
                                            icon={<CloseIcon />}
                                            onClick={() => setLeaveRoomConfirmationModalOpen(true)}
                                        ></Button>
                                    </Col>
                                </Row>
                            </div>
                            <div
                                className={classNames(
                                    styles.aside_host,
                                    !hostSessionParticipant?.kurentoParticipant?.videoEnabled && styles.nurseIsNotStreaming,
                                )}
                            >
                                {isLastProcedure ? (
                                    <div className={classNames(styles.nurseOnTheWay, styles.bye)}>
                                        <div className={styles.bubble}>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: t('appointmentPage.texts.bye'),
                                                }}
                                            ></p>
                                            <Bubble />
                                        </div>
                                        <Dino3 className="mb-2" />
                                    </div>
                                ) : (
                                    <>
                                        {!hostSessionParticipant?.kurentoParticipant ? (
                                            <div className={styles.nurseOnTheWay}>
                                                <Dino2 className="mb-2" />
                                                {t(`appointmentPage.texts.${hostInfo.role}OnTheWay`)}
                                            </div>
                                        ) : appointmentDetails?.serviceType === PatientAppointmentDetailsDTOServiceType.consultation ||
                                          appointmentDetails?.serviceType === PatientAppointmentDetailsDTOServiceType.diagnostics ? (
                                            <></>
                                        ) : (
                                            <>
                                                <div
                                                    className={classNames(
                                                        hostSessionParticipant?.kurentoParticipant?.videoEnabled ? 'd-block' : 'd-none',
                                                    )}
                                                >
                                                    <VideoParticipant participant={hostSessionParticipant} hostView={true} withoutTitles={true} />
                                                </div>
                                                <div className={classNames(!host?.videoEnabled ? 'd-block' : 'd-none')}>
                                                    <img
                                                        className={styles.nurseAvatar}
                                                        src={hostInfo.avatar || '/icons/doc-main-page/doc_test.png'}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}

                                {hostInfo && (
                                    <div className={styles.doctorName}>
                                        <strong>{hostInfo.name}</strong>
                                        <span>
                                            {t('appointment.practitionerRoles.' + hostInfo.role)}
                                            {appointmentDetails?.serviceType === 'therapy-session' ? t("patient_appointment.see_procedure") : null}
                                        </span>
                                        {appointmentDetails?.serviceType !== PatientAppointmentDetailsDTOServiceType.consultation &&
                                            appointmentDetails?.serviceType !== PatientAppointmentDetailsDTOServiceType.diagnostics &&
                                            !!hostSessionParticipant?.kurentoParticipant &&
                                            !isLastProcedure && (
                                                <Button type="primary" className="mt-3" onClick={callDoctor}>
                                                    {t('appointmentPage.texts.callHost')}
                                                </Button>
                                            )}
                                    </div>
                                )}
                            </div>
                            <div className={styles.chat}>
                                {hostSessionParticipant ? (
                                    <>
                                        {isLastProcedure ? (
                                            <>
                                                <h6>{t("patient_appointment.chat")}</h6>
                                                <div className={styles.chatBody}>
                                                    <p className={classNames(styles.description)}>
                                                        <SmileIcon className={styles.chatIcon} />
                                                        {t('appointmentPage.chat.didntChat')}
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <h6>{t(`appointmentPage.chat.${hostInfo.role}ChatWith`)}</h6>

                                                <Chat
                                                    messages={meAsSessionParticipant?.chatHistory}
                                                    currentUserId={currentProfile?.fhirId}
                                                    placeholder={t(`appointmentPage.chat.${hostInfo.role}ToMessage`)}
                                                    noMessagesLabel={
                                                        appointmentDetails?.serviceType === 'therapy-session'
                                                            ? t(`appointmentPage.chat.${hostInfo.role}DescriptionTherapy`)
                                                            : t(`appointmentPage.chat.${hostInfo.role}Description`)
                                                    }
                                                    sendMessage={(e) => sendChatMessage(e)}
                                                    markAsRead={markChatMessageAsRead}
                                                />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <div className={styles.startOfChat}>
                                        <p className={styles.description}>
                                            <ChatWith3DotsIcon className={styles.chatIcon} />
                                            {t(`appointmentPage.chat.${hostInfo.role}ChatIsNotAvailable`)}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </aside>
                    </section>

                    <Modal
                        title={t('appointmentPage.modals.leaveRoom.title')}
                        okText={t('appointmentPage.modals.common.yes')}
                        cancelText={t('appointmentPage.modals.common.cancel')}
                        open={leaveRoomConfirmationModalOpen}
                        onOk={leaveRoom}
                        onCancel={() => setLeaveRoomConfirmationModalOpen(false)}
                    >
                        <p>{t('appointmentPage.modals.leaveRoom.text')}</p>
                    </Modal>

                    <Modal
                        title={t("patient_appointment.procedure_starting")}
                        okText={t("yes")}
                        cancelText={t("no")}
                        open={procedureConfirmationModalOpen}
                        onOk={runProcedure}
                        onCancel={() => setProcedureConfirmationModalOpen(false)}
                    >
                        <p>{t("patient_appointment.patient_appointment.procedure_ready")}</p>
                    </Modal>

                    <Modal
                        title={t("patient_appointment.screen_cast")}
                        okText={t("yes")}
                        cancelText={t("no")}
                        open={screenSharingConfirmationModalOpen}
                        onOk={confirmScreenSharing}
                        onCancel={() => {
                            setScreenSharingConfirmationModalOpen(false);
                            send('/msg/gateway-ws/message', '/procedure/' + hostId.current, {
                                type: WsProcedureTopicType.cancelScreenSharing,
                                participantId: myParticipantId.current,
                            });
                        }}
                    >
                        <p>{t("patient_appointment.nurse_question_screen")}</p>
                    </Modal>

                    {isDiagonalUpdated && (
                        <div className={styles.diagonalChangedOverlay} onClick={() => setIsDiagonalUpdated(false)}>
                            <div>
                                <ScreenChangedIcon></ScreenChangedIcon>
                                <span>{t("patient_appointment.changed_diagonal")}</span>
                            </div>
                        </div>
                    )}
                </>
            ) : null}
        </div>
    );
};
