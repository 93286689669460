import { updateInfo } from '@api/documentServiceAPI';
import { CarePlanDTOStatus, getActivePatientConsultations, getPatientCarePlans, getPatientEncountersByParams } from '@api/mainServiceAPI';
import { addProfile } from '@api/userServiceAPI';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import {getAuthUser, remoteValidationError} from '@sliceUser';
import { Logger } from '@utils/logger';
import { RootState } from './../Store';
import {
    addNewPatient,
    getEntityToCheckPayment,
    getPatientActiveConsultations,
    getPatientCarePlan,
    getPatientEncountersById,
    setEntityToCheckPayment,
    setPatientActiveConsultations,
    setPatientCarePlan,
    setPatientEncountersById,
} from './slice';
import { IEntityToCheckPayment } from './state';
import {TIME_LIMIT_PATIENT_MEDCARD, USER_NOT_FOUND} from "@utils/constants";

export const patientListenerMiddleware = createListenerMiddleware();

patientListenerMiddleware.startListening({
    actionCreator: addNewPatient,
    effect: ({ payload }, listenerApi) => {
        const user = (listenerApi.getState() as RootState)?.userReducer;
        const userId = user?.authUser?.id;
        if (!userId) {
            Logger.error('Not AuthUser ID');
            return;
        }
        const { oms, omsFile, ...rest } = payload;
        let requestUpdateOMS: Promise<any>;
        if (oms && omsFile) {
            requestUpdateOMS = updateInfo({
                number: oms,
                uuid: omsFile,
                fhirId: userId,
            });
        } else {
            requestUpdateOMS = Promise.resolve();
        }
        requestUpdateOMS.then(() => {
            addProfile(userId, rest).then(() => {
                listenerApi.dispatch(getAuthUser({ newProfileAdded: true }));
            }).catch(() => {
                listenerApi.dispatch(remoteValidationError({ error: `apiErrors.${TIME_LIMIT_PATIENT_MEDCARD}`, field: 'email' }));
            });
        });
    },
});

patientListenerMiddleware.startListening({
    actionCreator: getPatientEncountersById,
    effect: ({ payload }, listenerApi) => {
        getPatientEncountersByParams(payload.patientFhirId, payload.params).then((res) => {
            listenerApi.dispatch(setPatientEncountersById(res.data));
        });
    },
});

patientListenerMiddleware.startListening({
    actionCreator: getPatientActiveConsultations,
    effect: ({ payload }, listenerApi) => {
        getActivePatientConsultations(payload.patientFhirId).then((res) => {
            listenerApi.dispatch(
                setPatientActiveConsultations(res.data.filter((x) => !(x.encounterStatus === 'cancelled' || x.paymentStatus === 'failed'))),
            );
        });
    },
});

patientListenerMiddleware.startListening({
    actionCreator: getPatientCarePlan,
    effect: ({ payload }, listenerApi) => {
        getPatientCarePlans(payload.patientFhirId).then((res) => {
            const cPlan = res.data?.find((cp) => cp.status !== CarePlanDTOStatus.completed);
            // now - we don't support multi care plans. It should be always one

            listenerApi.dispatch(setPatientCarePlan(cPlan || res.data?.[0]));
        });
    },
});

patientListenerMiddleware.startListening({
    actionCreator: getEntityToCheckPayment,
    effect: ({ payload }, listenerApi) => {
        const paymentInfo = localStorage.getItem('paymentInfo');
        if (paymentInfo) {
            listenerApi.dispatch(setEntityToCheckPayment(JSON.parse(paymentInfo) as IEntityToCheckPayment));
        }
    },
});

patientListenerMiddleware.startListening({
    actionCreator: setEntityToCheckPayment,
    effect: ({ payload }) => {
        if (payload) {
            localStorage.setItem('paymentInfo', JSON.stringify(payload));
        }
    },
});
