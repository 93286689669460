import { ProcedureStatus, ProcedureTypeEnum } from '@enums';
import {
    CheckIcon,
    MinusIcon,
    PlusIcon,
    ProcedureEditIcon,
    ProcedureFailedIcon,
    ProcedurePauseIcon,
    ProcedurePauseSmallIcon,
    ProcedurePlayIcon,
    ProcedureSettingsSmallIcon,
    ProcedureStopIcon,
    ProcedureSuccessIcon,
    ProcedureToggleIcon,
} from '@icons';
import { ParticipantProcedure, ProcedureSettingsValueType } from '@models';
import { getProcedureSettingsAsString } from '@procedures';
import { secondsAsTime } from '@utils/utils';
import { Button, Collapse, Form, Input } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AvetisovMatsResult } from '@api/mainServiceAPI';
import { useForm } from 'antd/es/form/Form';
import { IProcedureResult } from 'src/models/procedure-result.model';
import styles from './ProcedureHostChip.module.scss';

const { Panel } = Collapse;
const { TextArea } = Input;

interface IProcedureHostChipProps {
    procedure: ParticipantProcedure;
    changeStatusEvent: (status: ProcedureStatus) => void;

    doctorConfirmEvent?: (result: IProcedureResult) => void;
    needOpenSettings?: (procedureId: string) => void;
    doctorComment?: string;
    disabled?: boolean;
    viewOnly?: boolean;
    isClosest?: boolean;
    expandable?: boolean;
    expanded?: boolean;
    settings?: Partial<ProcedureSettingsValueType>;
    accommodationCapacityCurrent?: string;
    accommodationCapacityHistory?: AvetisovMatsResult[];
}

export const ProcedureHostChip = ({
    procedure,
    changeStatusEvent,

    needOpenSettings,
    doctorConfirmEvent,
    doctorComment,
    disabled,
    viewOnly,
    isClosest,
    expandable = false,
    expanded = true,
    settings,
    accommodationCapacityCurrent,
    accommodationCapacityHistory = [],
}: IProcedureHostChipProps) => {
    const { t } = useTranslation();
    const [form] = useForm();

    const [settingsString, setSettingsString] = useState('');
    const [panelIsActive, setPanelIsActive] = useState(false);
    const [commentMode, setCommentMode] = useState(false);

    useEffect(() => {
        switch (procedure.type) {
            case ProcedureTypeEnum.DISSOCIATION:
                {
                    const { duration, ...otherSettings } = settings as any;
                    setSettingsString(getProcedureSettingsAsString(otherSettings));
                }
                break;
            case ProcedureTypeEnum.RESTORATION_OF_FUSION:
                {
                    const { amblyopia, ...otherSettings } = settings as any;
                    setSettingsString(getProcedureSettingsAsString(otherSettings));
                }
                break;
            default:
                setSettingsString(getProcedureSettingsAsString(settings));
                break;
        }
    }, [settings]);

    useEffect(() => {
        setPanelIsActive(expanded);
    }, [expanded]);

    const playPauseHandler = () => {
        const procedureNotStarted =
            procedure.status === ProcedureStatus.notStarted ||
            procedure.status === ProcedureStatus.finished ||
            procedure.status === ProcedureStatus.failed;

        const procedureStepped =
            procedure.type === ProcedureTypeEnum.AVETISOV_MATS ||
            procedure.type === ProcedureTypeEnum.BIFIXATION_RECOVERY ||
            procedure.type === ProcedureTypeEnum.AMBLYOPIA;

        if (procedureNotStarted) {
            changeStatusEvent(ProcedureStatus.started);
        } else {
            if (procedure.type === ProcedureTypeEnum.DISSOCIATION) {
                if (procedure.status === ProcedureStatus.waitingApproveToChangeGlasses) {
                    changeStatusEvent(ProcedureStatus.changeGlasses);
                } else {
                    changeStatusEvent(ProcedureStatus.waitingApproveToChangeGlasses);
                }

                return;
            }

            if (procedureStepped) {
                if (procedure.status === ProcedureStatus.waitingApproveToChangeGlasses) {
                    changeStatusEvent(ProcedureStatus.changeGlasses);
                    return;
                }
            }

            if (procedure.status === ProcedureStatus.paused) {
                changeStatusEvent(ProcedureStatus.continued);
            } else {
                changeStatusEvent(ProcedureStatus.paused);
            }
        }
    };

    const changeAccommodationCapacity = (increase: boolean, step = 0.5) => {
        const currentValue = +form.getFieldValue('accommodationCapacity');
        const newValue = increase ? currentValue + step : currentValue - step;

        const result = newValue.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });
        form.setFieldValue('accommodationCapacity', result);
    };

    const isPlayButton = () =>
        procedure.status === ProcedureStatus.paused ||
        procedure.status === ProcedureStatus.waitingApproveToChangeGlasses ||
        procedure.status === ProcedureStatus.notStarted ||
        procedure.status === ProcedureStatus.finished ||
        procedure.status === ProcedureStatus.failed;

    return (
        <Collapse
            className={classNames(styles.collapse, disabled && styles.disabled, styles['status_' + procedure.status])}
            defaultActiveKey={expanded ? ['1'] : []}
            activeKey={panelIsActive ? ['1'] : []}
            onChange={(e) => setPanelIsActive(!!e.length)}
            expandIcon={({ isActive }) => <ProcedureToggleIcon style={{ transform: `rotate(${isActive ? -180 : 0}deg)` }} />}
            expandIconPosition="end"
        >
            <Panel
                showArrow={expandable}
                collapsible={expandable ? 'header' : 'icon'}
                key={'1'} // it's okay, because it's not an accordion, it's just a spoiler with single item only
                header={
                    <div className={classNames(styles.header)} title={t('procedures.list.' + procedure?.type)}>
                        {t('procedures.list.' + procedure?.type)}
                    </div>
                }
            >
                <div className={classNames(styles.content)}>
                    {!!settingsString && (
                        <div className={styles.settings_row}>
                            <span>{settingsString}</span>

                            <ProcedureSettingsSmallIcon
                                className={classNames(
                                    'iconBtn',
                                    styles.settingsIcon,
                                    (procedure.status === ProcedureStatus.inProgress ||
                                        procedure.status === ProcedureStatus.continued ||
                                        procedure.status === ProcedureStatus.started) &&
                                        styles.disabled,
                                )}
                                onClick={() => needOpenSettings?.(procedure.id!)}
                            />
                        </div>
                    )}

                    <div className={styles.actions_row}>
                        <div className={styles.action_buttons}>
                            <button
                                className={classNames(styles.startStopBtn, !expandable && isClosest && isPlayButton() && styles.active)}
                                onClick={playPauseHandler}
                                disabled={viewOnly || procedure.status === ProcedureStatus.finishedButNeedConfirmation}
                            >
                                {isPlayButton() ? <ProcedurePlayIcon /> : <ProcedurePauseIcon />}
                            </button>

                            <button
                                className={styles.startStopBtn}
                                onClick={() => changeStatusEvent(ProcedureStatus.stopped)}
                                disabled={
                                    viewOnly ||
                                    !(
                                        procedure.status === ProcedureStatus.paused ||
                                        procedure.status === ProcedureStatus.waitingApproveToChangeGlasses ||
                                        procedure.status === ProcedureStatus.inProgress ||
                                        procedure.status === ProcedureStatus.started ||
                                        procedure.status === ProcedureStatus.continued
                                    )
                                }
                            >
                                {<ProcedureStopIcon />}
                            </button>

                            {(procedure.type === ProcedureTypeEnum.AVETISOV_MATS || procedure.type === ProcedureTypeEnum.DISSOCIATION) &&
                                procedure.status === ProcedureStatus.waitingApproveToChangeGlasses && (
                                    <button
                                        className={styles.startStopBtn}
                                        onClick={() => changeStatusEvent(ProcedureStatus.finishedButNeedConfirmation)}
                                        disabled={viewOnly}
                                    >
                                        {<CheckIcon />}
                                    </button>
                                )}
                        </div>

                        <div className={classNames(styles.progress_block, procedure.status === ProcedureStatus.notStarted && styles.inactive)}>
                            {procedure.isInteractive && (
                                <>
                                    <div className="d-flex align-items-center" title={t("procedure_host.error") + procedure.interaction?.failedTries}>
                                        <span className={styles.fails_count}>{procedure.interaction?.successTries || 0}</span>/
                                        <span className={styles.success_count}>{procedure.targetPoints}</span>
                                        {/* <span className={styles.target_count}>{procedure.interactionSettings.targetPoints}</span> */}
                                        {(procedure.status === ProcedureStatus.finishedButNeedConfirmation ||
                                            procedure.status === ProcedureStatus.finished) && (
                                            <ProcedureSuccessIcon className={styles.progressIcon} />
                                        )}
                                        {procedure.status === ProcedureStatus.failed && <ProcedureFailedIcon className={styles.progressIcon} />}
                                    </div>
                                    <div className={styles.divider}></div>
                                </>
                            )}
                            <span
                                className={styles.time}
                                title={
                                    procedure.settings?.duration ? t("procedure_host.total_duration") + procedure.settings?.duration + t("procedure_host.seconds") : t("procedure_host.no_time")
                                }
                            >
                                {procedure.status === ProcedureStatus.finished ||
                                procedure.status === ProcedureStatus.failed ||
                                procedure.status === ProcedureStatus.finishedButNeedConfirmation ? (
                                    <>{secondsAsTime(procedure.totalDuration)}</>
                                ) : (
                                    <>
                                        {procedure.type === ProcedureTypeEnum.DISSOCIATION
                                            ? secondsAsTime(procedure.progressTime!)
                                            : secondsAsTime(+(procedure.settings?.duration || 0) - procedure.progressTime!)}
                                    </>
                                )}

                                {procedure.status === ProcedureStatus.paused && <ProcedurePauseSmallIcon className={styles.progressIcon} />}
                            </span>
                        </div>
                    </div>

                    {/* for procedures with comment */}
                    {procedure.status === ProcedureStatus.failed ||
                    procedure.status === ProcedureStatus.finishedButNeedConfirmation ||
                    (procedure.status === ProcedureStatus.finished &&
                        (procedure.type === ProcedureTypeEnum.AVETISOV_MATS ||
                            procedure.type === ProcedureTypeEnum.DISSOCIATION ||
                            procedure.type === ProcedureTypeEnum.BIFIXATION_RECOVERY)) ? (
                        <>
                            {commentMode ||
                            procedure.status === ProcedureStatus.finishedButNeedConfirmation ||
                            (!doctorComment && procedure.type !== ProcedureTypeEnum.AVETISOV_MATS) ? (
                                <Form
                                    form={form}
                                    name="doctorApproveForm"
                                    wrapperCol={{ span: 24 }}
                                    initialValues={{
                                        comment: doctorComment || '',
                                        accommodationCapacity: accommodationCapacityCurrent || -0.5,
                                    }}
                                    autoComplete="off"
                                    onFinish={(value) => {
                                        doctorConfirmEvent?.({
                                            comment: value.comment,
                                            accommodationCapacity: value.accommodationCapacity,
                                            status:
                                                procedure.status === ProcedureStatus.finishedButNeedConfirmation
                                                    ? ProcedureStatus.finished
                                                    : procedure.status!,
                                        });
                                        setCommentMode(false);
                                    }}
                                    className={styles.comment_row}
                                >
                                    {procedure.type === ProcedureTypeEnum.AVETISOV_MATS ? (
                                        <div className={styles.accommodation}>
                                            <div className={styles.label}>{dayjs().format('DD.MM.YYYY')}</div>
                                            <div className={styles.toggleInputWrapper}>
                                                <MinusIcon className={styles.minusBtn} onClick={() => changeAccommodationCapacity(false)} />
                                                <Form.Item
                                                    name="accommodationCapacity"
                                                    className={classNames('w-100', 'mb-0')}
                                                    rules={[{ required: true, message: t("required_field") }]}
                                                >
                                                    <Input readOnly />
                                                </Form.Item>
                                                <PlusIcon className={styles.plusBtn} onClick={() => changeAccommodationCapacity(true)} />
                                            </div>

                                            {accommodationCapacityHistory.map((x, i) => (
                                                <Fragment key={'ac_' + i}>
                                                    <div className={styles.label} title={x.nurseFullName}>
                                                        {dayjs(x.dateTime).format('DD.MM.YYYY')}
                                                    </div>
                                                    <div className={styles.value}>{x.result?.accommodationCapacity || '-/-'}</div>
                                                </Fragment>
                                            ))}
                                        </div>
                                    ) : (
                                        <Form.Item
                                            name="comment"
                                            className={classNames('w-100', 'mb-0')}
                                            rules={[{ required: true, message: t("required_field") }]}
                                        >
                                            <TextArea rows={3} placeholder={t("placeholder_comment")} />
                                        </Form.Item>
                                    )}

                                    <Button htmlType="submit" type="primary">
                                        {t("save_result")}
                                    </Button>
                                </Form>
                            ) : (
                                <>
                                    {procedure.type === ProcedureTypeEnum.AVETISOV_MATS ? (
                                        <div className={styles.accommodation}>
                                            <div className={styles.label}>{dayjs().format('DD.MM.YYYY')}</div>
                                            <div className={classNames(styles.value, styles.forEdit)}>
                                                {accommodationCapacityCurrent || '-/-'}
                                                <ProcedureEditIcon className={styles.editBtn} onClick={() => setCommentMode(true)} />
                                            </div>

                                            {accommodationCapacityHistory.map((x, i) => (
                                                <Fragment key={'ac_' + i}>
                                                    <div className={styles.label} title={x.nurseFullName}>
                                                        {dayjs(x.dateTime).format('DD.MM.YYYY')}
                                                    </div>
                                                    <div className={styles.value}>{x.result?.accommodationCapacity || '-/-'}</div>
                                                </Fragment>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className={styles.comment_block}>
                                            <span className={styles.comment_label}>{doctorComment}</span>
                                            <ProcedureEditIcon className="iconBtn" onClick={() => setCommentMode(true)} />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ) : null}
                </div>
            </Panel>
        </Collapse>
    );
};
