import { SettingsHelpBlock } from '@components';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PATIENT_DIAGONAL } from 'src/ADMINISTRATIVE_CONFIG';
import { ScreenDiagonalModal } from '../ScreenDiagonalModal/ScreenDiagonalModal';
import { ScreenHeightWidthModal } from '../ScreenHeightWidthModal/ScreenHeightWidthModal';
import styles from './ScreenSettingsModal.module.scss';

export interface IDiagonalProps {
    onHandleChangeDiagonal: (value: string) => void;
    diagonal: string;
}

interface IScreenSettingsProps {
    showModal: boolean;
    onCancel: () => void;
    isFirst: boolean;
    onSuccess: () => void;
    onHandleChangeDiagonal: (value: string) => void;
    diagonal: string;
    title?: string;
}

export const ScreenSettingsModal = ({ showModal, onCancel, isFirst, onSuccess, onHandleChangeDiagonal, diagonal, title }: IScreenSettingsProps) => {
    const { t } = useTranslation();

    const [settingIndex, setSettingIndex] = useState<number>(0);
    const [isDiagonalValid, setIsDiagonalValid] = useState<boolean>(true);

    const handleChooseSetting = (i: number) => {
        setSettingIndex(i);
    };

    const submitDiagonal = () => {
        if (!diagonal) {
            onHandleChangeDiagonal(DEFAULT_PATIENT_DIAGONAL.toString());
            onSuccess();
        } else {
            onSuccess();
        }
    };

    const handleChangeMonitorDiagonal = (value: string) => {
        const valueInt = Math.round(parseFloat(value)) || 0;

        if (valueInt < 100) {
            setIsDiagonalValid(false);
        } else {
            setIsDiagonalValid(true);
            onHandleChangeDiagonal(value);
        }
    };

    return (
        <Modal
            width={1000}
            title={title || t('modals.screenSettings.title')}
            open={showModal}
            onCancel={onCancel}
            footer={
                settingIndex > 0
                    ? [
                          // <Button key="support" onClick={techSupport}>
                          //     <ChatWithDotsIcon className="me-2" /> {t('modals.buttons.techSupport')}
                          // </Button>,
                          <SettingsHelpBlock key="support" />,
                          <div key="block" className="d-flex">
                              <Button onClick={() => handleChooseSetting(0)} key="back">
                                  {t('modals.buttons.back')}
                              </Button>
                              <Button onClick={submitDiagonal} disabled={!isDiagonalValid} key="submit" type="primary">
                                  {isFirst ? t('modals.buttons.next') : t('modals.buttons.ready')}
                              </Button>
                          </div>,
                      ]
                    : [<SettingsHelpBlock key="support" />]
            }
        >
            {settingIndex === 1 && <ScreenHeightWidthModal diagonal={diagonal} onHandleChangeDiagonal={handleChangeMonitorDiagonal} />}
            {settingIndex === 2 && <ScreenDiagonalModal diagonal={diagonal} onHandleChangeDiagonal={handleChangeMonitorDiagonal} />}
            {/* {settingIndex === 3 && <ScreenCardModal diagonal={diagonal} onHandleChangeDiagonal={handleChangeMonitorDiagonal} />} */}
            {settingIndex === 0 && (
                <div className={styles.content}>
                    <p className="mb-5">{t('modals.screenSettings.description')}</p>
                    <div className="d-flex justify-content-between mb-4">
                        <div className="col-5 d-flex flex-column align-items-center">
                            <img className="mb-4" src="/icons/modals/height-width-screen.png" alt="monitor" />
                            <p className={styles['setting-title']}>{t('modals.screenSettings.categories.widthHeight.title')}</p>
                            <p className={styles['setting-description']}>{t('modals.screenSettings.categories.widthHeight.subtitle')}</p>
                            <Button onClick={() => handleChooseSetting(1)} className="mb-5">
                                {t('modals.buttons.choose')}
                            </Button>
                        </div>
                        <div className="col-5 d-flex flex-column align-items-center">
                            <img className="mb-4" src="/icons/modals/diagonal-screen.png" alt="monitor" />
                            <p className={styles['setting-title']}>{t('modals.screenSettings.categories.diagonal.title')}</p>
                            <p className={styles['setting-description']}>{t('modals.screenSettings.categories.diagonal.subtitle')}</p>
                            <Button onClick={() => handleChooseSetting(2)} className="mb-5">
                                {t('modals.buttons.choose')}
                            </Button>
                        </div>
                        {/* <div className="col-3 d-flex flex-column align-items-center">
                            <img className="mb-4" src="/icons/modals/card-screen.png" alt="monitor" />
                            <p className={styles['setting-title']}>{t('modals.screenSettings.categories.card.title')}</p>
                            <p className={styles['setting-description']}>{t('modals.screenSettings.categories.card.subtitle')}</p>
                            <Button onClick={() => handleChooseSetting(3)} className="mb-5">
                                {t('modals.buttons.choose')}
                            </Button>
                        </div> */}
                    </div>
                </div>
            )}
        </Modal>
    );
};
