import { FileSafeDTOBucketType } from '@api/documentServiceAPI';
import { CourseAppointmentDetails, EncounterDetailDTOStatus, UserDocumentDTO, getCourseAppointmentDetails } from '@api/mainServiceAPI';
import { AppointmentStatus } from '@enums';
import { AlertIconOutlined, CalendarIcon, CameraIconGrey, ChevronLeft, ChevronRight, DownloadIcon, UserIcon } from '@icons';
import { setFileViewer } from '@sliceCore';
import { UserRole, selectAuthUser, selectCurrentProfile } from '@sliceUser';
import { Button, Col, Row } from 'antd';
import classNames from 'classnames';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Chats } from 'src/components/Chats/Chats';
import { EncounterStatusBadge } from 'src/components/EncounterStatusBadge/EncounterStatusBadge';
import { getEncounterStatus } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/dictionary';
import styles from './HeadPhysicianProcedurePage.module.scss';

export const HeadPhysicianProcedurePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const authUser = useSelector(selectAuthUser);
    const currentProfile = useSelector(selectCurrentProfile);

    const [encounter, setEncounter] = useState<CourseAppointmentDetails>();

    useEffect(() => {
        retrieveEncounterDetails();
    }, []);
    const retrieveEncounterDetails = () => {
        if (params.encounterOrEventId) {
            getCourseAppointmentDetails(params.encounterOrEventId).then((result) => {
                setEncounter(result.data);
            });
        }
    };

    const onBackToAllAppointments = () => {
        navigate(-1);
    };

    const onDoctorDetails = (practitionerUserId?: string) => {
        navigate('/staff/' + practitionerUserId);
    };

    const onPatientDetails = (patientId?: string) => {
        if (encounter?.okoEventDTO?.manager?.id === authUser?.id && encounterStatus === AppointmentStatus.pending) {
            navigate(
                '/medcard/' +
                    patientId +
                    '?appointmentId=' +
                    encounter?.appointmentDetailsDTO?.appointmentId +
                    '&encounterFhirId=' +
                    encounter?.appointmentDetailsDTO?.appointmentId +
                    '&managerId=' +
                    encounter?.okoEventDTO?.manager?.id +
                    '&appointmentStatus=' +
                    encounterStatus,
            );
        } else {
            navigate('/medcard/' + patientId);
        }
    };

    const onPatientProcedureDetails = (encounterId: string) => {
        navigate(`/procedure/patient/${encounterId}`);
    };

    const onDownload = (doc: UserDocumentDTO & { s3Key?: string }) => {
        const key = doc.s3Key || doc.s3key;

        if (key) {
            dispatch(
                setFileViewer({
                    s3Key: key,
                    bucketType: doc.type === FileSafeDTOBucketType.VIDEO ? FileSafeDTOBucketType.VIDEO : FileSafeDTOBucketType.DOCUMENT,
                    fileName: doc.extension ? `${doc.name}.${doc.extension}` : (doc.name as string),
                    extension: doc.extension,
                }),
            );
        }
    };

    const encounterStatus = getEncounterStatus(encounter?.appointmentDetailsDTO?.appointmentStatus, encounter?.okoEventDTO?.paymentStatus);

    return (
        <div className={styles.wrapper}>
            <div className="d-flex justify-content-between">
                <div className={styles.titleContainer}>
                    <Button onClick={() => onBackToAllAppointments()} className="backBtn">
                        <ChevronLeft className="transparent" />
                    </Button>
                    <p className={styles.title}>
                        {encounter?.appointmentDetailsDTO?.partOf && t(`enums.planDefinition.${encounter?.appointmentDetailsDTO?.partOf}`)}
                    </p>
                </div>
            </div>

            <Row gutter={24}>
                <Col md={16} sm={24} xs={24}>
                    <Col md={24} sm={24} xs={24}>
                        <div className={styles.eventBottomBlock}>
                            <div className={styles.infoField}>
                                <div>
                                    <div className="d-flex">
                                        <div>
                                            <CalendarIcon />
                                        </div>
                                        <div>
                                            <p>{t(`appointment.dateTime`)}</p>
                                            <div className="d-flex">
                                                <p className={styles.dateText}>
                                                    {encounter?.appointmentDetailsDTO?.start &&
                                                        format(new Date(encounter?.appointmentDetailsDTO?.start), 'dd.MM.yyyy HH:mm')}
                                                </p>
                                                <div className={styles.statusField}>
                                                    <EncounterStatusBadge
                                                        encounterStatus={encounter?.appointmentDetailsDTO?.appointmentStatus}
                                                        paymentStatus={encounter?.okoEventDTO?.paymentStatus}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {encounter?.appointmentDetailsDTO?.appointmentStatus === EncounterDetailDTOStatus.cancelled && (
                                        <div className={styles.cancelReasonBlock}>
                                            <AlertIconOutlined />
                                            <p>{t('appointment.cancelReason')}</p>
                                            <p>
                                                {t(
                                                    'enums.encounterChangeReason.' + encounter?.appointmentDetailsDTO?.changeReason?.at(-1)?.type,
                                                    t("not_specified_1"),
                                                )}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={styles.infoField}>
                                <div className="d-flex align-items-center">
                                    <div className={styles.avatar}>
                                        {encounter?.appointmentDetailsDTO?.practitionerPhoto ? (
                                            <img src={encounter?.appointmentDetailsDTO?.practitionerPhoto} />
                                        ) : (
                                            <UserIcon />
                                        )}
                                    </div>
                                    <div>
                                        <p
                                            onClick={() => onDoctorDetails(encounter?.appointmentDetailsDTO?.practitionerId)}
                                            className={styles.nameText}
                                        >
                                            {encounter?.appointmentDetailsDTO?.practitionerName}
                                        </p>
                                        <div className="d-flex">
                                            <p>
                                                {encounter?.appointmentDetailsDTO?.practitionerRoleCode &&
                                                    t(`appointment.practitionerRoles.${encounter?.appointmentDetailsDTO?.practitionerRoleCode}`)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {encounter?.patientParticipants &&
                                encounter?.patientParticipants?.length > 0 &&
                                encounter?.patientParticipants.map((item, i) => (
                                    <div className={styles.infoField} key={`patient - ${i}`}>
                                        <div className="d-flex">
                                            <div className={styles.avatar}>
                                                <UserIcon />
                                            </div>
                                            <div>
                                                <p onClick={() => onPatientDetails(item?.patient?.id)} className={styles.nameText}>
                                                    {item?.patient?.fullName
                                                        ? item?.patient?.fullName
                                                        : `${item?.patient?.lastName} ${item?.patient?.firstName} ${item?.patient?.middleName}`}
                                                </p>
                                                <div className="d-flex">
                                                    <p>{dayjs(item?.patient?.birthDate).fromNow(true)}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            {/* {encounterStatus === AppointmentStatus.completed && (
                                                <a className={styles.resultsLink} onClick={() => onThreatmentResultsDetails(item.id)}>
                                                    {t('appointment.results')}
                                                </a>
                                            )} */}
                                            <Button onClick={() => onPatientProcedureDetails(item.encounterId!)} className="gotoBtn">
                                                <ChevronRight />
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Col>
                    {encounter?.videos && encounter?.videos?.length > 0 && (
                        <Col span={24}>
                            <div className={styles['procedure-container']}>
                                <p className={styles.title}>{t("head_physician_procedure.results")}</p>
                                <p className={styles.labelVideo}>{t("head_physician_procedure.video")}</p>
                                {encounter.videos.map((video) => (
                                    <div
                                        onClick={() => onDownload(video)}
                                        key={video.documentFhirId}
                                        className={classNames(styles.videoContainer, 'pointer')}
                                    >
                                        <div className="d-flex align-items-center">
                                            <CameraIconGrey />
                                            <p className={styles.videoName}>{video.name}</p>
                                        </div>
                                        <DownloadIcon />
                                    </div>
                                ))}
                            </div>
                        </Col>
                    )}
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Chats
                        currentUserRole={UserRole.Head_physician}
                        currentUserId={currentProfile?.fhirId}
                        chats={encounter?.chat}
                        isProcedure
                        haveAccessForEdit={encounter?.okoEventDTO?.manager?.id === authUser?.id && encounter?.okoEventDTO?.status === 'in_progress'}
                    />
                </Col>
            </Row>
        </div>
    );
};
