import styles from './SettingButton.module.scss';

interface ISettingButtonProps {
    icon: any;
    isHorizontal?: boolean;
    onClick: () => void;
    title: string;
    variable?: string;
}

export const SettingButton = ({ icon, isHorizontal = false, onClick, variable = '', title }: ISettingButtonProps) => {
    return (
        <button onClick={onClick} className={!isHorizontal ? styles['button-settings'] : styles['button-settings-horizontal']}>
            {icon}
            {!isHorizontal ? (
                <p>{title}</p>
            ) : (
                <div className="d-flex flex-column align-items-start ms-3">
                    <p>{title}</p>
                    <p className={styles.bold}>{variable}</p>
                </div>
            )}
        </button>
    );
};
