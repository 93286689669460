import { AvetisovMatsResult, PatientFormDTO } from '@api/mainServiceAPI';
import { DiagnosticVisualAcuity } from '@components';
import { ProcedureTypeEnum } from '@enums';
import { ConsultationUserIcon, ScreenDisabledIcon } from '@icons';
import { ScreenSettingsModal } from '@modals';
import { CoursePackageType } from '@sliceProcedures';
import { selectCurrentProfile } from '@sliceUser';
import { WsProcedureTopicType } from '@utils/websocket.topics';
import { Button } from 'antd';
import classNames from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    DiagnosticBinocular,
    IDiagnosticBinocularResult,
    IDiagnosticVisualAcuityLastResult,
    IDiagnosticVisualAcuityResult,
} from 'src/components/diagnostics';
import { ILobbyParticipant } from 'src/contexts/kurento.context';
import { WebsocketContext } from 'src/contexts/ws.context';
import { CourseDiagnosticEnum } from 'src/enums/course-diagnostic.enum';
import { SessionParticipant } from 'src/models/session-participant';
import styles from './DiagnosticSession.module.scss';
import { DiagnosticMyopia, IDiagnosticMyopiaForm } from './diagnostics/DiagnosticMyopia/DiagnosticMyopia';
import { DiagnosticStrabismus, IDiagnosticStrabismusForm } from './diagnostics/DiagnosticStrabismus/DiagnosticStrabismus';

const steps = [
    { number: 0, label: '' },

    { number: 100, label: '' },
    { number: 200, label: '' },
];

interface IDiagnosticSessionProps {
    planDefinitionID?: CoursePackageType;
    diagnosticType?: CourseDiagnosticEnum;
    participant?: SessionParticipant;
    activeScreenSharingParticipant?: SessionParticipant;
    onRequestScreenSharing?: (state: boolean, participant: SessionParticipant) => void;
    participantInLobby?: ILobbyParticipant;
    lastConsultationResult?: PatientFormDTO;
    lastVisualAcuityResult?: IDiagnosticVisualAcuityLastResult;
    accommodationCapacityHistory?: AvetisovMatsResult[];
    isCalibrationReady: boolean;
    participantDiagonal?: string;

    onApproveFromLobby?: (fhirId: string) => void;
    onToggleCalibration?: (state: boolean) => void;
    diagnosticChange?: (start: boolean, type?: ProcedureTypeEnum) => void;
    diagnosticSaveResult?: (result: any) => void;
    releaseParticipant?: () => void;
    onToggleCalibrationReady?: (ready: boolean) => void;
}

export const DiagnosticSession = ({
    planDefinitionID,
    diagnosticType,
    participant,
    activeScreenSharingParticipant,
    onRequestScreenSharing,
    participantInLobby,
    lastConsultationResult,
    lastVisualAcuityResult,
    accommodationCapacityHistory = [],
    isCalibrationReady,
    participantDiagonal,

    onApproveFromLobby,
    onToggleCalibration,
    diagnosticChange,
    diagnosticSaveResult,
    onToggleCalibrationReady,
    releaseParticipant,
}: IDiagnosticSessionProps) => {
    const { t } = useTranslation();

    const { send } = useContext(WebsocketContext);

    const currentProfile = useSelector(selectCurrentProfile);

    const [currentStep, setCurrentStep] = useState(0);
    const [diagnosticVisualAcuityResult, setDiagnosticVisualAcuityResult] = useState<IDiagnosticVisualAcuityResult>();
    const [diagnosticBinocularResult, setDiagnosticBinocularResult] = useState<IDiagnosticBinocularResult>();

    const [showEditPatientDiagonalModal, setShowEditPatientDiagonalModal] = useState(false);
    const [patientDiagonal, setPatientDiagonal] = useState<string>('');

    const screenSharingWrapperRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const videoElement = activeScreenSharingParticipant?.kurentoParticipant?.screenSharingVideo;

        if (screenSharingWrapperRef.current && videoElement) {
            screenSharingWrapperRef.current.appendChild(videoElement);
        }

        return () => {
            if (screenSharingWrapperRef.current && videoElement) {
                screenSharingWrapperRef.current.removeChild(videoElement);
            }
        };
    }, [activeScreenSharingParticipant]);

    useEffect(() => {
        if (participantDiagonal) {
            setPatientDiagonal(participantDiagonal);
        }
    }, [participantDiagonal]);

    const changeStep = (step: number) => {
        setCurrentStep(step);
    };

    const diagnosticVisualAcuityFinished = (result?: IDiagnosticVisualAcuityResult) => {
        setDiagnosticVisualAcuityResult(result);
        setCurrentStep(0);
    };

    const diagnosticBinocularFinished = (result?: IDiagnosticBinocularResult) => {
        setDiagnosticBinocularResult(result);
        setCurrentStep(0);
    };

    const onFinishAndSave = (formValue: IDiagnosticMyopiaForm | IDiagnosticStrabismusForm) => {
        formValue.diagnosticBinocular = JSON.stringify(formValue.diagnosticBinocular!) === '{}' ? undefined : formValue.diagnosticBinocular;
        formValue.diagnosticVisualAcuity = JSON.stringify(formValue.diagnosticVisualAcuity!) === '{}' ? undefined : formValue.diagnosticVisualAcuity;

        diagnosticSaveResult?.(formValue);
    };

    const renderCurrentStepContent = () => {
        switch (currentStep) {
            case 0:
                return getTargetDiagnostic();
            case 100:
                return (
                    <DiagnosticVisualAcuity
                        onSaveResults={diagnosticVisualAcuityFinished}
                        diagnosticChange={diagnosticChange}
                        lastResult={lastVisualAcuityResult}
                    />
                );
            case 200:
                return (
                    <DiagnosticBinocular
                        onSaveResults={diagnosticBinocularFinished}
                        onToggleCalibration={onToggleCalibration}
                        diagnosticChange={diagnosticChange}
                        isCalibrationReady={isCalibrationReady}
                        onToggleCalibrationReady={onToggleCalibrationReady}
                    />
                );
            default:
                return null;
        }
    };

    const getTargetDiagnostic = () => {
        switch (diagnosticType) {
            case CourseDiagnosticEnum.MYOPIA:
                return (
                    <DiagnosticMyopia
                        lastConsultationResult={lastConsultationResult}
                        accommodationCapacityHistory={accommodationCapacityHistory}
                        onSubmit={onFinishAndSave}
                        diagnosticVisualAcuityResult={diagnosticVisualAcuityResult}
                        disabledForNurse={currentProfile?.profileType === 'Nurse'}
                    />
                );
            case CourseDiagnosticEnum.STRABISMUS:
                return (
                    <DiagnosticStrabismus
                        lastConsultationResult={lastConsultationResult}
                        accommodationCapacityHistory={accommodationCapacityHistory}
                        onSubmit={onFinishAndSave}
                        diagnosticVisualAcuityResult={diagnosticVisualAcuityResult}
                        diagnosticBinocularResult={diagnosticBinocularResult}
                        disabledForNurse={currentProfile?.profileType === 'Nurse'}
                    />
                );
            default:
                return null;
        }
    };

    const handleChangeDiagonal = (value: string) => {
        setPatientDiagonal(value);
    };

    const handleDistanceSuccess = () => {
        setShowEditPatientDiagonalModal(false);

        send('/msg/gateway-ws/message', '/procedure/' + participant?.fhirId, {
            type: WsProcedureTopicType.screenSettingsChange,
            body: { diagonal: patientDiagonal },
        });
    };

    return (
        <div
            className={classNames(
                styles.wrapper,
                currentStep !== 100 && currentStep !== 200 && 'd-flex',
                !!activeScreenSharingParticipant && styles.screenSharing,
            )}
        >
            {participantInLobby && (
                <div className={styles.notificationModal}>
                    <div className={styles.avatar}>
                        <ConsultationUserIcon className={styles.icon} />
                    </div>
                    <p>{t("diagnostic_session.patient_in_wait_room")}</p>
                    <Button type="primary" onClick={() => onApproveFromLobby?.(participantInLobby.id!)}>
                        {t("diagnostic_session.admit_patient_button")}
                    </Button>
                </div>
            )}
            {currentStep !== 100 && currentStep !== 200 && <div className={styles.fakeAside}></div>}
            {currentStep !== 100 && currentStep !== 200 && (
                <aside className={styles.aside}>
                    <div className={styles.content}>
                        <ul className={styles.steps}>
                            {steps
                                .filter((x) => x.number < 100)
                                .map((step) => (
                                    <li key={step.number}>
                                        <div
                                            className={classNames(styles.step, currentStep === step.number && styles.active)}
                                            onClick={() => changeStep(step.number)}
                                        >
                                            {t('diagnosticSession.title.' + planDefinitionID)}
                                        </div>
                                    </li>
                                ))}
                        </ul>

                        <div className={styles.actionsList}>
                            <Button type="default" onClick={() => setCurrentStep(100)}>
                                {t("diagnostic_session.diagnostic_vision_button")}
                            </Button>
                            <Button type="default" onClick={() => setCurrentStep(200)}>
                                {t("diagnostic_session.diagnostic_binocular_button")}
                            </Button>
                            <Button
                                type="default"
                                onClick={() => setShowEditPatientDiagonalModal(true)}
                                disabled={!participantInLobby && !participant?.kurentoParticipant}
                            >
                                {t("diagnostic_session.set_patient_screen_button")}
                            </Button>
                            <Button type="default" onClick={() => releaseParticipant?.()} disabled={!participant?.kurentoParticipant}>
                                {t("diagnostic_session.release_patient_button")}
                            </Button>
                        </div>
                    </div>
                </aside>
            )}

            {/* render it with bigger zIndex to avoid diagnostic component destruction */}
            {activeScreenSharingParticipant && (
                <div className={styles.screenVideoWrapper}>
                    <div className={styles.participant_screenSharing} ref={screenSharingWrapperRef}></div>
                    <Button className={styles.disableScreenBtn} onClick={() => onRequestScreenSharing?.(false, activeScreenSharingParticipant)}>
                        <ScreenDisabledIcon />
                    </Button>
                </div>
            )}

            <div className={classNames(currentStep !== 100 && currentStep !== 200 ? styles.contentWrapper : styles.viewOnlyContent)}>
                {currentStep !== 100 && currentStep !== 200 && <h1>{t('diagnosticSession.title.' + planDefinitionID)}</h1>}

                {renderCurrentStepContent()}
            </div>

            {showEditPatientDiagonalModal && (
                <ScreenSettingsModal
                    title={t("diagnostic_session.set_patient_screen_button")}
                    diagonal={patientDiagonal}
                    onHandleChangeDiagonal={handleChangeDiagonal}
                    onCancel={() => setShowEditPatientDiagonalModal(false)}
                    onSuccess={handleDistanceSuccess}
                    isFirst={false}
                    showModal={true}
                />
            )}
        </div>
    );
};
