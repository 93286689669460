import { LandoltRingIcon } from '@icons';
import styles from './DiagnosticRings.module.scss';

interface IDiagnosticRingsProps {
    size?: number;
    direction?: string;
}

export const DiagnosticRings = ({ size = 14, direction }: IDiagnosticRingsProps) => {
    return (
        <div className={styles.wrapper}>
            <LandoltRingIcon style={{ width: size, height: size, transform: `rotate(${convertDirectionToDeg(direction)}deg)` }} />
        </div>
    );
};

const convertDirectionToDeg = (direction?: string) => {
    switch (direction) {
        case 'top':
            return 0;
        case 'right':
            return 90;
        case 'bottom':
            return 180;
        case 'left':
            return -90;
        default:
            return 0;
    }
};
