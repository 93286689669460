import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGeneralProcedure } from 'src/models/general-procedure.model';
import { RootState } from '../Store';
import { initialProceduresState, IProceduresState } from './state';

// Slice
export const proceduresSlice = createSlice({
    name: 'procedures',
    initialState: initialProceduresState,
    reducers: {
        setActiveProcedure: (state: IProceduresState, action: PayloadAction<IGeneralProcedure | null>) => {
            state.activeProcedure = action.payload;
        },
        setActiveScreenSharing: (state: IProceduresState, action: PayloadAction<boolean>) => {
            state.activeScreenSharing = { share: action.payload };
        },
        setAwaitingScreenSharingParticipant: (state: IProceduresState, action: PayloadAction<string | undefined>) => {
            state.awaitingSharingFrom = action.payload;
        },
        setActiveScreenSharingParticipant: (state: IProceduresState, action: PayloadAction<string>) => {
            state.activeScreenSharingParticipantId = action.payload;
        },
        removeActiveScreenSharingParticipant: (state: IProceduresState, action: PayloadAction<string>) => {
            if (state.activeScreenSharingParticipantId === action.payload) {
                state.activeScreenSharingParticipantId = undefined;
            }
            if (state.awaitingSharingFrom === action.payload) {
                state.awaitingSharingFrom = undefined;
            }
        },
    },
});

// Actions
export const {
    setActiveProcedure,
    setActiveScreenSharing,
    setActiveScreenSharingParticipant,
    removeActiveScreenSharingParticipant,
    setAwaitingScreenSharingParticipant,
} = proceduresSlice.actions;

// Reducer
export const proceduresReducer = proceduresSlice.reducer;

// Selectors
export const selectActiveProcedure = (state: RootState) => state.proceduresReducer.activeProcedure;
export const selectActiveScreenSharing = (state: RootState) => state.proceduresReducer.activeScreenSharing;
export const selectActiveScreenSharingParticipant = (state: RootState) => state.proceduresReducer.activeScreenSharingParticipantId;
export const selectAwaitingSharingFrom = (state: RootState) => state.proceduresReducer.awaitingSharingFrom;
