import {
    getUsers,
    GetUsersParams,
    GetUsersProfileTypesItem,
    GetUsersStatus,
    UpdateUserDTOStatus,
    UserDTO,
    UserProfileDTOProfileType,
} from '@api/userServiceAPI';
import { SearchIcon } from '@icons';
import { Col, Input, Row, Select, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType, FilterValue } from 'antd/es/table/interface';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from './HeadPhysicianStaffPage.module.scss';

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

export const HeadPhysicianStaffPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [filteredEmployeeList, setFilteredEmployeeList] = useState<UserDTO[]>([]);
    const [filterQuery, setFilterQuery] = useState<string | undefined>();

    const [filterRole, setFilterRole] = useState<string>('');
    const [rolesOptions, setRolesOptions] = useState<{ label: string; value: string | null }[]>([]);

    const [filterStatus, setFilterStatus] = useState<string>('');
    const [statusOptions, setStatusOptions] = useState<{ label: string; value: string | null }[]>([]);

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => <p>{t('adminEmployeePage.totalItems') + ' ' + total}</p>,
            position: ['bottomCenter'],
        },
    });

    useEffect(() => {
        setRolesOptions([
            ...Object.keys(UserProfileDTOProfileType)
                .filter((role) => filterByRole(role as UserProfileDTOProfileType))
                .map((role: string) => ({
                    label: t(`adminMainPage.roles.${role}`),
                    value: role,
                })),
            { label: t('adminMainPage.filter.allRolesOption'), value: '' },
        ]);

        setStatusOptions([
            { label: t('adminMainPage.employeeTableColumns.ACTIVE'), value: UpdateUserDTOStatus.ACTIVE },
            { label: t('adminMainPage.employeeTableColumns.BLOCKED'), value: UpdateUserDTOStatus.BLOCKED },
            { label: t('adminMainPage.employeeTableColumns.DRAFT'), value: UpdateUserDTOStatus.DRAFT },
            { label: t('adminMainPage.filter.allStatusesOption'), value: '' },
        ]);
    }, []);

    useEffect(() => {
        retrieveUsersToTable(0, tableParams.pagination?.pageSize || 10);
    }, [filterQuery, filterRole, filterStatus]);

    const retrieveUsersToTable = (page: number, size: number) => {
        getUsers(
            {
                page,
                size,
                search: filterQuery,
                status: filterStatus as GetUsersStatus,
                // todo sort after backend
                profileTypes: filterRole
                    ? [filterRole as GetUsersProfileTypesItem]
                    : Object.values(UserProfileDTOProfileType).filter((item) => filterByRole(item)),
            },
            {
                paramsSerializer: {
                    encode: (params) => {
                        if (params === 'profileTypes[]') {
                            return 'profileTypes';
                        } else {
                            return params;
                        }
                    },
                },
            },
        ).then((result) => {
            if (result?.data?.content) {
                const data = result.data.content.map((user) => {
                    user.userProfileDTOList = user.userProfileDTOList?.filter((profile) => filterByRole(profile.profileType));
                    return user;
                });
                setTableParams({
                    pagination: { ...tableParams.pagination, total: result.data.totalElements },
                });
                setFilteredEmployeeList(data);
            }
        });
    };

    const filterByRole = (role: UserProfileDTOProfileType | undefined): boolean => {
        return !!role && role != UserProfileDTOProfileType.End_user && role != UserProfileDTOProfileType.Patient;
    };

    const handleTableChange: TableProps<UserDTO>['onChange'] = (pagination, filters, sorter) => {
        const searchCriteria: GetUsersParams = {
            page: pagination?.current && pagination.current - 1,
            size: pagination?.pageSize,
            search: filterQuery,
            status: filterStatus as GetUsersStatus,
            // todo sort after backend
            profileTypes: filterRole
                ? [filterRole as GetUsersProfileTypesItem]
                : Object.values(UserProfileDTOProfileType).filter((item) => filterByRole(item)),
        };

        if (sorter) {
            searchCriteria.sortField = (sorter as any).field || 'created';
            searchCriteria.sortDirection = (sorter as any).order === 'ascend' ? 'ASC' : 'DESC';
        }

        getUsers(searchCriteria, {
            paramsSerializer: {
                encode: (params) => {
                    if (params === 'profileTypes[]') {
                        return 'profileTypes';
                    } else {
                        return params;
                    }
                },
            },
        }).then((result) => {
            if (result?.data?.content) {
                const data = result.data.content.map((user) => {
                    user.userProfileDTOList = user.userProfileDTOList?.filter((profile) => filterByRole(profile.profileType));
                    return user;
                });
                setTableParams({
                    pagination: { ...pagination, total: result.data.totalElements },
                });
                setFilteredEmployeeList(data);
            }
        });
    };

    const employeeTableColumns: ColumnsType<UserDTO> = [
        {
            title: t('adminMainPage.employeeTableColumns.name'),
            dataIndex: ['firstName', 'lastName', 'middleName'],
            render: (text, record) => {
                let result = '';
                if (record.lastName) {
                    result += record.lastName;
                }
                if (record.firstName) {
                    result += ' ' + record.firstName;
                }
                if (record.middleName) {
                    result += ' ' + record.middleName;
                }
                return result || '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.lastName) {
            //         return 1;
            //     }
            //     if (!b.lastName) {
            //         return -1;
            //     }
            //     if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'name',
        },
        {
            title: t('adminMainPage.employeeTableColumns.role'),
            dataIndex: 'profileType',
            render: (text, record) => {
                if (record.userProfileDTOList) {
                    return record.userProfileDTOList[0]?.profileType ? t(`adminMainPage.roles.${record.userProfileDTOList[0]?.profileType}`) : '-';
                }
                return '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.userProfileDTOList || !a.userProfileDTOList[0]?.profileType) {
            //         return 1;
            //     }
            //     if (!b.userProfileDTOList || !b.userProfileDTOList[0]?.profileType) {
            //         return -1;
            //     }
            //     if (
            //         t(`adminMainPage.roles.${a.userProfileDTOList[0]?.profileType}`) >
            //         t(`adminMainPage.roles.${b.userProfileDTOList[0]?.profileType}`)
            //     ) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'role',
        },
        {
            title: t('adminMainPage.employeeTableColumns.registered'),
            dataIndex: 'created',
            render: (created) => {
                return format(new Date(created), 'dd.MM.yyyy HH:mm');
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.created) {
            //         return 1;
            //     }
            //     if (!b.created) {
            //         return -1;
            //     }
            //     if (new Date(a.created) > new Date(b.created)) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'registered',
        },
        {
            title: t('adminMainPage.employeeTableColumns.status'),
            dataIndex: 'status',
            render: (_status, record) => {
                // TODO set correct status on the backend
                const status = record.userProfileDTOList?.some(
                    (x) =>
                        x.profileType === UserProfileDTOProfileType.Nurse ||
                        x.profileType === UserProfileDTOProfileType.Practitioner ||
                        x.profileType === UserProfileDTOProfileType.Head_physician,
                )
                    ? _status || 'DRAFT'
                    : _status || 'ACTIVE';

                return <p className={classNames(styles.status, styles[status])}>{t('adminMainPage.employeeTableColumns.' + status, '-')}</p>;
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.status || a.status == UpdateUserDTOStatus.BLOCKED) {
            //         return 1;
            //     }
            //     if (!b.status || b.status == UpdateUserDTOStatus.ACTIVE) {
            //         return -1;
            //     }
            //     return -1;
            // },
            key: 'status',
        },
    ];

    const onOpenEmployeePage = (record: UserDTO) => {
        navigate(`/staff/${record.id}`);
    };

    return (
        <div className={styles['wrapper']}>
            <p className={styles.title}>{t('adminMainPage.title')}</p>
            <div className={styles.filterBlock}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Input
                            placeholder={t('adminMainPage.filter.searchPlaceholder')}
                            prefix={<SearchIcon />}
                            onChange={(e) => setFilterQuery(e.target.value)}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            placeholder={t('adminMainPage.filter.rolesPlaceholder')}
                            value={filterRole}
                            onChange={(value) => setFilterRole(value)}
                            options={rolesOptions}
                            className="w-100"
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            placeholder={t('adminMainPage.filter.statusesPlaceholder')}
                            value={filterStatus}
                            onChange={(value) => setFilterStatus(value)}
                            options={statusOptions}
                            className="w-100"
                        />
                    </Col>
                </Row>
            </div>
            <div className="mt-4">
                <Table
                    className={styles.employeeRow}
                    rowKey={(record) => record.id!}
                    columns={employeeTableColumns}
                    dataSource={filteredEmployeeList}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    showSorterTooltip={false}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                onOpenEmployeePage(record);
                            },
                        };
                    }}
                />
            </div>
        </div>
    );
};
