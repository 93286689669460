import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TriangleRight } from '@icons';
import { selectCarePlan, selectPatientWorkspacePrepared } from '@slicePatient';
import { selectCurrentProfile } from '@sliceUser';
import { pluralLabel } from '@utils/utils';
import { Avatar, Button, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { WorkspaceSettings } from '../WorkspaceSettings/WorkspaceSettings';
import styles from './AboutOkoBlock.module.scss';

export interface IAboutOkoBlockProps {
    openDocInfo: (docId: string) => void;
    withoutWorkspace?: boolean;
}

export const AboutOkoBlock = ({ openDocInfo, withoutWorkspace = true }: IAboutOkoBlockProps) => {
    const { t } = useTranslation();

    const [sessionDuration, setSessionDuration] = useState('');

    const carePlan = useSelector(selectCarePlan);
    const currentProfile = useSelector(selectCurrentProfile);
    const patientWorkspacePrepared = useSelector(selectPatientWorkspacePrepared);

    useEffect(() => {
        if (carePlan?.sessionDurationInMinutes) {

            setSessionDuration(
                carePlan?.sessionDurationInMinutes + ' ' + t("time_format_declension." + pluralLabel(carePlan.sessionDurationInMinutes, ["minute_i", "minute_r","minute_m"])),
            );
        }
    }, [carePlan]);

    return (
        <div className={styles['border-container']}>
            <div className={styles.content}>
                <Row>
                    <Col span={12}>
                        <p className={styles.title}>{t('userMainPage.aboutCourse.title')}</p>
                        <div className="mt-5">
                            <iframe width="445" height="250" src="https://www.youtube.com/embed/JWdxRwdckb8" title="Embedded youtube" />
                        </div>
                        <p className="mt-3">{t('userMainPage.aboutCourse.duration', { duration: sessionDuration })}</p>
                        <Link className={`${styles.link} d-flex align-items-center mt-1`} to="/">
                            {t('userMainPage.aboutCourse.more')}
                            <TriangleRight />
                        </Link>
                    </Col>

                    {currentProfile?.userProfileDetailsDTO?.supervisingPractitioner?.id && (
                        <Col span={12}>
                            <p className={styles.title}>{t('userMainPage.aboutDoctor.title')}</p>
                            <div className="mt-5 d-flex align-items-center">
                                <div className={`${styles.avatar} me-4`}>
                                    <Avatar src={currentProfile?.userProfileDetailsDTO?.supervisingPractitioner?.photo} size={200} />
                                </div>
                                <div className={styles['doc-container']}>
                                    <p className={`${styles.title} mb-2`}>
                                        {currentProfile?.userProfileDetailsDTO?.supervisingPractitioner?.lastName}{' '}
                                        {currentProfile?.userProfileDetailsDTO?.supervisingPractitioner?.firstName}
                                    </p>
                                    <p className={styles.subtitle}>
                                        {t(
                                            `adminEmployeePage.profilePage.practitionerSpecialties.${currentProfile?.userProfileDetailsDTO?.supervisingPractitioner?.speciality}`,
                                        )}
                                    </p>
                                    <Button
                                        type="ghost"
                                        className={`${styles.link} d-flex align-items-center mt-1 p-0`}
                                        onClick={() => openDocInfo(currentProfile?.userProfileDetailsDTO?.supervisingPractitioner?.id || '')}
                                    >
                                        {t('userMainPage.singleConsultation.moreAboutDoctor')} <TriangleRight />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </div>

            {patientWorkspacePrepared && !withoutWorkspace && (
                <div>
                    <div className={styles['big-divider']} />
                    <WorkspaceSettings profileId={currentProfile!.fhirId!} onlyForInitialSettings={false} />
                </div>
            )}
        </div>
    );
};
