export enum ProcedureTypeEnum {
    MERIDIONAL_PATTERN = 'MERIDIONAL_PATTERN',
    CHESS_PATTERN = 'CHESS_PATTERN',
    MAKULOSTIMULATION = 'MAKULOSTIMULATION',
    PLEOPTIC_SPIRALS = 'PLEOPTIC_SPIRALS',
    PLEOPTIC_CROSS = 'PLEOPTIC_CROSS',
    FLASHES = 'FLASHES',
    BIFIXATION_RECOVERY = 'BIFIXATION_RECOVERY',
    RECOVER_CILLIAR = 'RECOVER_CILLIAR',
    DISSOCIATION = 'DISSOCIATION',
    AVETISOV_MATS = 'AVETISOV_MATS',

    DIAGNOSTIC_VISUAL_ACUITY = 'DIAGNOSTIC_VISUAL_ACUITY',
    DIAGNOSTIC_LETTERS = 'DIAGNOSTIC_LETTERS',
    DIAGNOSTIC_RINGS = 'DIAGNOSTIC_RINGS',
    DIAGNOSTIC_IMAGES = 'DIAGNOSTIC_IMAGES',
    DIAGNOSTIC_BINOCULAR = 'DIAGNOSTIC_BINOCULAR',

    LINEAR_PATTERN = 'LINEAR_PATTERN',
    STEREO_VISION_DEVELOPMENT = 'STEREO_VISION_DEVELOPMENT',

    /**
     * This is the restoration of the stability of the fusion !!!
     */
    AMBLYOPIA = 'AMBLYOPIA',

    /**
     * This is the restoration of fusion in amblyopinopia !!!
     */
    RESTORATION_OF_FUSION = 'RESTORATION_OF_FUSION',
}
