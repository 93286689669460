import { EducationDetailsDTOType } from '@api/userServiceAPI';
import { selectDocInfoModel, setDocInfoModel } from '@sliceUser';
import { capitalizeText, pluralLabel } from '@utils/utils';
import { Button, Col, Modal, Row } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './DocInfoModal.module.scss';

export const DocInfoModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [visible, setVisible] = useState(false);
    const [previewSelfMode, setPreviewSelfMode] = useState(false);

    const docProfile = useSelector(selectDocInfoModel);

    useEffect(() => {
        setVisible(!!docProfile);
    }, [docProfile]);

    const closeModal = () => {
        dispatch(setDocInfoModel());
    };

    const getWorkExperience = () => {
        const currentYear = new Date().getFullYear();
        const userProfile = docProfile;
        if (!userProfile?.practitionerProfessionalActivityStartYear) {
            return null;
        }

        const workExperience = currentYear - userProfile.practitionerProfessionalActivityStartYear;
        return workExperience + ' ' + t("date_format_declension." + pluralLabel(workExperience, ["year_i", "year_r", "year_m"]));
    };

    const getUniversityList = () => {
        return docProfile?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.university) || [];
    };
    const getInternshipList = () => {
        return docProfile?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.internship) || [];
    };
    const getCertificateList = () => {
        return docProfile?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.certificate) || [];
    };
    const getAccreditationList = () => {
        return docProfile?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.accreditation) || [];
    };
    const getPhysiciansAssociations = () => {
        return docProfile?.physiciansAssociations || [];
    };
    const getWorkExperiences = () => {
        return docProfile?.workExperiences || [];
    };

    const getDocName = () => {
        return (
            docProfile?.lastName +
            ' ' +
            (docProfile?.firstName ? docProfile?.firstName?.substring(0, 1) + '. ' : ' ') +
            (docProfile?.middleName ? docProfile?.middleName?.substring(0, 1) + '.' : '')
        );
    };

    return visible ? (
        <Modal
            className={styles.supportModal}
            width="70vw"
            title={getDocName()}
            open={visible}
            onCancel={closeModal}
            footer={[
                <Button style={{ marginLeft: 'auto' }} key="close" type="primary" onClick={() => closeModal()}>
                    {t('modals.buttons.close')}
                </Button>,
            ]}
        >
            <>
                <Row gutter={24} className={classNames(previewSelfMode && styles.selfPreviewPhoto)}>
                    <Col md={12} sm={24} xs={24}>
                        <img className={styles.photoBlock} src={docProfile?.photoLink} />
                    </Col>
                </Row>
                {!previewSelfMode && (
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <div className={styles.infoItem}>
                                <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.practitionerSpecialty')}</p>
                                <p className={styles.infoValue}>
                                    {docProfile?.practitionerSpecialties
                                        ?.map((item) => t(`adminEmployeePage.profilePage.practitionerSpecialties.${item}`))
                                        ?.join(', ')}
                                </p>
                            </div>
                        </Col>
                    </Row>
                )}
                {docProfile?.practitionerSpecialties && (
                    <Row gutter={24}>
                        <Col md={8} sm={24} xs={24}>
                            <div className={styles.infoItem}>
                                <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.academicDegree')}</p>
                                <p>
                                    {docProfile?.academicDegree
                                        ? t(`adminEmployeePage.profilePage.academicDegrees.${docProfile?.academicDegree}`)
                                        : '-'}
                                </p>
                            </div>
                            <div className={styles.infoItem}>
                                <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.qualificationCategory')}</p>
                                <p>
                                    {docProfile?.qualificationCategory
                                        ? t(`adminEmployeePage.profilePage.qualificationCategories.${docProfile?.qualificationCategory}`)
                                        : '-'}
                                </p>
                            </div>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                            <div className={styles.infoItem}>
                                <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.academicRank')}</p>
                                <p>{docProfile?.academicRank ? t(`adminEmployeePage.profilePage.academicRanks.${docProfile?.academicRank}`) : '-'}</p>
                            </div>
                            <div className={styles.infoItem}>
                                <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.workExperience')}</p>
                                <p className={styles.infoValue}>{getWorkExperience() ? getWorkExperience() : '-'}</p>
                            </div>
                        </Col>
                    </Row>
                )}
                <Row gutter={24}>
                    <Col md={24} sm={24} xs={24}>
                        <div className={styles.profileBlock}>
                            {(getUniversityList().length > 0 ||
                                getInternshipList().length > 0 ||
                                getCertificateList().length > 0 ||
                                getAccreditationList().length > 0) && <h3>{t('adminEmployeePage.profilePage.education.title')}</h3>}
                            {getUniversityList().length > 0 && (
                                <div className={styles.profileItem}>
                                    <p className={styles.title}>{t('adminEmployeePage.profilePage.education.university')}</p>
                                    {getUniversityList().map((item) => (
                                        <div className={styles.profileRecord} key={item.name}>
                                            <p className={styles.profileRecordYear}>{item.graduationYear}</p>
                                            <p className={styles.profileRecordText}>{capitalizeText(item.name || '')}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {getInternshipList().length > 0 && (
                                <div className={styles.profileItem}>
                                    <p className={styles.title}>{t('adminEmployeePage.profilePage.education.internship')}</p>
                                    {getInternshipList().map((item) => (
                                        <div className={styles.profileRecord} key={item.name}>
                                            <p className={styles.profileRecordYear}>{item.graduationYear}</p>
                                            <p className={styles.profileRecordText}>{capitalizeText(item.name || '')}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {getCertificateList().length > 0 && (
                                <div className={styles.profileItem}>
                                    <p className={styles.title}>{t('adminEmployeePage.profilePage.education.certificates')}</p>
                                    {getCertificateList().map((item) => (
                                        <div className={styles.profileRecord} key={item.name}>
                                            <p className={styles.profileRecordYear}>{item.graduationYear}</p>
                                            <p className={styles.profileRecordText}>{capitalizeText(item.name || '')}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {getAccreditationList().length > 0 && (
                                <div className={styles.profileItem}>
                                    <p className={styles.title}>{t('adminEmployeePage.profilePage.education.accreditation')}</p>
                                    {getAccreditationList().map((item) => (
                                        <div className={styles.profileRecord} key={item.name}>
                                            <p className={styles.profileRecordYear}>{item.graduationYear}</p>
                                            <p className={styles.profileRecordText}>{capitalizeText(item.name || '')}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {getPhysiciansAssociations().length > 0 && (
                            <div className={styles.profileBlock}>
                                <h3>{t('adminEmployeePage.profilePage.physiciansAssociation')}</h3>
                                <div className={styles.profileItem}>
                                    {getPhysiciansAssociations().map((item, i) => (
                                        <div className={styles.profileRecord} key={i}>
                                            <p>{capitalizeText(item)}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {getWorkExperiences().length > 0 && (
                            <div className={styles.profileBlock}>
                                <h3>{t('adminEmployeePage.profilePage.experience')}</h3>
                                <div className={styles.profileItem}>
                                    {getWorkExperiences().map((item, i) => (
                                        <div className={`${styles.profileRecord} ${styles.profileRecordExperience}`} key={i}>
                                            <p className={styles.profileRecordYear}>
                                                {item.yearStart}—{item.yearEnd || t('adminEmployeePage.profilePage.presentTime')}
                                            </p>
                                            <p className={styles.profileRecordText}>{capitalizeText(item.description || '')}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </>
        </Modal>
    ) : null;
};
