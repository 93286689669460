import {
    CreateScheduleDTOPlanIdsItem,
    CreateScheduleDTOServiceTypeItem,
    getPractitionerSlots,
    getSlotDetails,
    removeActuallyFreeSlots,
    removeSlotsInRange,
    SlotDetailsDTO,
    SlotDTO,
    updateSlot,
    UpdateSlotDTO,
} from '@api/mainServiceAPI';
import { getUsers, GetUsersStatus, UserDTO, UserProfileDTOProfileType } from '@api/userServiceAPI';
import {
    AlertIconOutlined,
    CalendarInputIcon,
    CrossIcon,
    InfoOutlinedIcon,
    ScheduleDeleteIcon,
    ScheduleEditIcon,
    SearchIcon,
    TriangleRight,
} from '@icons';
import { Paper } from '@mui/material';
import { END_DAY_TIME } from '@utils/constants';
import { dateForServer, getCoords } from '@utils/utils';
import { AutoComplete, Button, Col, message, Modal, Popover, Row } from 'antd';
import classNames from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import Scheduler from 'devextreme-react/scheduler';
import 'devextreme/dist/css/dx.light.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import styles from './ClinicAdminSchedule.module.scss';
import { SchedulerMemo } from './components/SchedulerMemo';
import { AddEventToScheduleModal } from './modals';
import { EditScheduleSlotModal } from './modals/EditScheduleSlotModal/EditScheduleSlotModal';
import { RemoveScheduleSlotModal } from './modals/RemoveScheduleSlotModal/RemoveScheduleSlotModal';

const TTIP_VERT_POSITION = 20;
const TTIP_HOR_POSITION = 40;
const TTIP_SIZE = { w: 420, h: 188 };

const filterByRole = (role: UserProfileDTOProfileType | undefined): boolean => {
    return (
        !!role &&
        role != UserProfileDTOProfileType.End_user &&
        role != UserProfileDTOProfileType.Patient &&
        role !== UserProfileDTOProfileType.Administrator &&
        role !== UserProfileDTOProfileType.Call_center_manager &&
        role !== UserProfileDTOProfileType.Clinic_administrator
    );
};

export interface ISchedulerSlot extends SlotDTO {
    name: string;
    id: string;
    startDate: Date;
    endDate: Date;
    role: string;
    patientsNumber?: number;
}

interface IClinicAdminScheduleProps {
    readonly?: boolean;
}

export const ClinicAdminSchedule = ({ readonly = false }: IClinicAdminScheduleProps) => {
    const queryParams = new URLSearchParams(window.location.search);
    const employeeId = queryParams.get('employeeId');
    const [currentQueryParameters, setSearchParams] = useSearchParams();

    const [firstLoading, setFirstLoading] = useState(true);
    const mousePositionRef = useRef<MouseEvent>();

    const { t } = useTranslation();
    const schedulerRef = useRef<Scheduler>(null);
    const [start, setStart] = useState<number>();
    const [end, setEnd] = useState<number>();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [filterQuery, setFilterQuery] = useState<string | undefined>();
    const [currentView, setCurrentView] = useState<'day' | 'week' | 'month'>('week');
    const [showAddModal, setShowAddModal] = useState(false);
    const [filteredEmployeeList, setFilteredEmployeeList] = useState<UserDTO[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<UserDTO | undefined>();
    const [events, setEvents] = useState<SlotDTO[]>([]);
    const [practitionerId, setPractitionerId] = useState<string>('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipData, setTooltipData] = useState<ISchedulerSlot>();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [legendItems, setLegendItems] = useState<any[]>([]);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [tooltipPatientData, setTooltipPatientData] = useState<SlotDetailsDTO>();
    const [showHeaderDropdown, setHeaderShowDropdown] = useState(false);
    const [headerDropdownPos, setHeaderDropdownPos] = useState({ top: 0, left: 0 });

    const [rangeErrorModalShow, setRangeErrorModalShow] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState<dayjs.Dayjs[]>([]);
    const [isEditError, setIsEditError] = useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = useState<{ value: string; label: string }[]>([]);
    const debounceRef = useRef<any>(null);

    useEffect(() => {
        handleReadyChanged();
        const mouseMoveHandler = (event: MouseEvent) => {
            mousePositionRef.current = event;
        };
        window.addEventListener('mousemove', mouseMoveHandler);
        return () => {
            window.removeEventListener('mousemove', mouseMoveHandler);
        };
    }, []);

    useEffect(() => {
        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
            getUsers(
                {
                    page: 0,
                    size: 100, // todo just for dev
                    lastName: filterQuery,
                    status: GetUsersStatus.ACTIVE,
                    profileTypes: Object.values(UserProfileDTOProfileType).filter((item) => filterByRole(item)),
                },
                {
                    paramsSerializer: {
                        encode: (params) => {
                            if (params === 'profileTypes[]') {
                                return 'profileTypes';
                            } else {
                                return params;
                            }
                        },
                    },
                },
            ).then((result) => {
                if (result?.data?.content) {
                    const data = result.data.content.map((user) => {
                        user.userProfileDTOList = user.userProfileDTOList?.filter((profile) => filterByRole(profile.profileType));
                        return user;
                    });

                    setFilteredEmployeeList(data);

                    if (firstLoading && employeeId) {
                        const targetEmployee = data.find((x) => x.id === employeeId);

                        if (targetEmployee) {
                            handleChooseEmployee(targetEmployee);
                        }
                        setFirstLoading(false);
                    }
                    setAutocompleteOptions(
                        data.length
                            ? data.map((filter) => ({
                                  value: filter?.id || '',
                                  label: `${filter?.lastName} ${filter?.firstName} ${filter?.middleName || ''}`,
                              }))
                            : filterQuery !== getQueryValue(selectedEmployee)
                            ? [{ value: '', label: t("clinic_admin_schedule.not_found") }]
                            : [],
                    );
                }
            });
        }, 300);
    }, [filterQuery]);

    const getSlotsForPractitioner = useCallback(
        (practitionerId: string, start: number, end: number, closeAddModal = true) => {
            if (!practitionerId || !start || !end) {
                return;
            }

            getPractitionerSlots(practitionerId, {
                start: dateForServer(dayjs(start).utc().toDate(), true, true)!,
                end: dateForServer(dayjs(end).toDate(), true, true)! + END_DAY_TIME,
            }).then((result) => {
                setEvents(result.data);

                if (closeAddModal) {
                    setShowAddModal(false);
                }
            });
        },
        [setShowAddModal],
    );

    useEffect(() => {
        if (currentView !== 'day') {
            getSlotsForPractitioner(practitionerId, start!, end!, false);
        }
    }, [start]);

    useEffect(() => {
        if (selectedEmployee) {
            const isNurse = selectedEmployee.userProfileDTOList?.find((user) => user.profileType === UserProfileDTOProfileType.Nurse);

            let legendResultItems = [];
            if (isNurse) {
                legendResultItems = ['FREE', 'DIAGNOSTIC', ...Object.values(CreateScheduleDTOPlanIdsItem)].map((x) => {
                    return {
                        label: (
                            <div className={styles.legendRow}>
                                <div className={classNames(styles['slot-color'], styles[`${x}-dashed`])} />
                                <div className={classNames(styles['slot-color'], styles[`${x}`])} />
                                <p>{t(`consultationSession.courseStep.courses.${x}`)}</p>
                            </div>
                        ),
                    };
                });
            } else {
                legendResultItems = Object.values(CreateScheduleDTOServiceTypeItem)
                    .filter((value) => value !== 'therapy-session')
                    .reverse()
                    .map((x) => {
                        return {
                            label: (
                                <div className={styles.legendRow}>
                                    <div className={classNames(styles['slot-color'], styles[`${x}-dashed`])} />
                                    <div className={classNames(styles['slot-color'], styles[`${x}`])} />
                                    <p>{t(`enums.serviceTypes.${x}`)}</p>
                                </div>
                            ),
                        };
                    });
            }

            setLegendItems(legendResultItems);
        }
    }, [selectedEmployee]);

    useEffect(() => {
        if (schedulerRef && schedulerRef.current && !readonly) {
            const schedulerElement = schedulerRef.current.instance.element();

            const columnHeaderCells = schedulerElement.querySelectorAll('.dx-scheduler-header-panel-cell');

            columnHeaderCells.forEach((cell) => {
                cell.addEventListener('click', handleHeaderCellClick);
            });
        }
    }, [start]);

    const generateDateArray = (start: Dayjs, end: Dayjs) => {
        const dateArray = [];
        const daysDifference = end.diff(start, 'day');

        for (let i = 0; i <= daysDifference; i++) {
            const currentDate = start.add(i, 'day');
            dateArray.push(currentDate.format('YYYY-MM-DD'));
        }

        return dateArray;
    };

    const handleHeaderCellClick = useCallback(
        (e: any) => {
            const dayIndex = e.srcElement.cellIndex;
            const day = generateDateArray(dayjs(start), dayjs(end))[dayIndex];
            setSelectedDate(dayjs(day));
            setHeaderShowDropdown(true);
            const dWidth = document.body.offsetWidth;
            const coord = { top: e.clientY + TTIP_VERT_POSITION, left: e.clientX };
            if (e.clientX > dWidth / 2) {
                coord.left = e.clientX - 150;
            } else {
                coord.left = e.clientX - 100;
            }
            setHeaderDropdownPos(coord);
        },
        [start, end, setSelectedDate, setHeaderShowDropdown, setHeaderDropdownPos],
    );

    const renderHeaderDropdown = () => {
        return (
            <div className={styles.headerDropdown} style={{ top: '90px', left: headerDropdownPos.left }}>
                <div
                    onClick={() => {
                        setShowRemoveModal(true);
                        setHeaderShowDropdown(false);
                    }}
                    className={styles['headerDropdown-item']}
                >
                    {t('clinic_admin_schedule.delete_appointments')}
                </div>
            </div>
        );
    };

    const handleReadyChanged = useCallback(() => {
        const schedulerInstance = schedulerRef.current?.instance;
        if (schedulerInstance) {
            const startViewDate = dateForServer(schedulerInstance.getStartViewDate());
            const endViewDate = dateForServer(schedulerInstance.getEndViewDate(), false);
            setCurrentDate(schedulerInstance.getStartViewDate());
            setStart(startViewDate);
            setEnd(endViewDate);
        }
    }, [schedulerRef, setCurrentDate, setStart, setEnd]);

    const handleCloseAddEventModal = () => {
        setShowAddModal(false);
    };

    const handleChooseEmployee = useCallback(
        (employee?: UserDTO) => {
            if (!employee) {
                return;
            }
            setSelectedEmployee(employee);
            const fhirId =
                employee?.userProfileDTOList?.find(
                    (profile) =>
                        profile.profileType === UserProfileDTOProfileType.Practitioner ||
                        profile.profileType === UserProfileDTOProfileType.Nurse ||
                        profile.profileType === UserProfileDTOProfileType.Head_physician,
                )?.fhirId || '';

            setPractitionerId(fhirId);
            getSlotsForPractitioner(fhirId, start!, end!);

            setFilterQuery(getQueryValue(employee));

            const newQueryParameters: URLSearchParams = new URLSearchParams();
            employee?.id && newQueryParameters.set('employeeId', employee?.id);
            setSearchParams(newQueryParameters);
        },
        [setSelectedEmployee, setPractitionerId, getSlotsForPractitioner, start, end, setFilterQuery],
    );

    const getQueryValue = (employee?: UserDTO) => {
        return `${employee?.lastName} ${employee?.firstName}${employee?.middleName ? ' ' + employee?.middleName : ''}`;
    };

    const handleCreateSlot = () => {
        getSlotsForPractitioner(practitionerId, start!, end!);
        setHeaderShowDropdown(false);
    };

    const handleChangeInput = (e: string) => {
        setFilterQuery(e);
        handleCloseEditModal();
    };

    const renderAppointmentCollectorComponent = (data: any) => {
        if (currentView === 'month') {
            return (
                <div className={classNames(styles.slotMonth, 'slot-container')}>
                    <p className={styles.monthAppointmentText}>{t("clinic_admin_schedule.yet")} {data?.data?.appointmentCount}</p>
                </div>
            );
        } else {
            return <p className={styles.monthAppointmentText}>{data?.data?.appointmentCount}</p>;
        }
    };
    const renderAppointmentCollector = (data: { appointmentData: ISchedulerSlot }) => {
        const isNurse = selectedEmployee?.userProfileDTOList?.some((user) => user.profileType === UserProfileDTOProfileType.Nurse) || false;

        return (
            <div className={classNames(styles.slotWeek)}>
                <p className={styles.weekAppointmentText}>
                    {`${dayjs(data?.appointmentData?.startDate).format('HH:mm')} - ${dayjs(data?.appointmentData?.endDate).format('HH:mm')}`}{' '}
                    {isNurse
                        ? t(`consultationSession.courseStep.courses.${data.appointmentData.serviceCategory}`)
                        : t(
                              `enums.serviceTypes.${
                                  data.appointmentData.realAppointmentServiceType?.[0] || data.appointmentData.slotServiceType?.[0]
                              }`,
                          )}
                </p>
            </div>
        );
    };

    const renderAppointment = (data: { appointmentData: ISchedulerSlot }) => {
        const isNurse = selectedEmployee?.userProfileDTOList?.some((user) => user.profileType === UserProfileDTOProfileType.Nurse) || false;
        const realServiceType = isNurse
            ? data.appointmentData.realAppointmentPlanDef
                ? data.appointmentData.realAppointmentServiceType?.[0]
                : data.appointmentData.slotServiceType?.[0]
            : data.appointmentData.realAppointmentServiceType?.[0] || data.appointmentData.slotServiceType?.[0];

        let statusClass;
        if (isNurse) {
            statusClass =
                data.appointmentData.status === 'busy' ||
                data.appointmentData.status === 'busy-tentative' ||
                (data.appointmentData.patientsNumber || 0) > 0
                    ? realServiceType !== 'therapy-session'
                        ? realServiceType!.toUpperCase()
                        : data.appointmentData.realAppointmentPlanDef || data.appointmentData.serviceCategory
                    : `${
                          data.appointmentData.realAppointmentPlanDef || data.appointmentData.serviceCategory || realServiceType!.toUpperCase()
                      }-dashed`;
        } else {
            statusClass =
                realServiceType === 'consultation'
                    ? styles.consultation
                    : realServiceType === 'diagnostics'
                    ? styles.diagnostics
                    : realServiceType === 'any'
                    ? styles.any
                    : '';
        }

        return (
            <div
                className={classNames(
                    'slot-container',
                    'dx-item-content dx-scheduler-appointment-content',

                    currentView === 'month' ? styles.slotMonth : styles.slot,
                    !isNurse && data.appointmentData.status === 'free' && styles.free,
                    isNurse ? styles[`${statusClass}`] : statusClass,
                )}
            >
                <p>{`${dayjs(data?.appointmentData?.startDate).format('HH:mm')} - ${dayjs(data?.appointmentData?.endDate).format('HH:mm')}`}</p>
                {!!data.appointmentData.patientsNumber && data.appointmentData.patientsNumber > 0 && (
                    <div className={styles.budge}>{data.appointmentData.patientsNumber}</div>
                )}
            </div>
        );
    };

    const handleDeleteSlot = (e: ISchedulerSlot) => {
        const slotIds = [];
        slotIds.push(e.id);
        removeActuallyFreeSlots(practitionerId, { slotIds: e.id as any }).then(() => {
            setShowDeleteModal(false);
            setShowTooltip(false);
            setEvents(events.filter((event) => event.id !== e.id));
        });
        setHeaderShowDropdown(false);
    };

    const handleRemoveDaySlot = (startDate: number, endDate: number) => {
        removeSlotsInRange(practitionerId, {
            rangeStart: startDate,
            rangeEnd: endDate,
        }).then(() => {
            setShowRemoveModal(false);
            getSlotsForPractitioner(practitionerId, start!, end!, false);
        });
        setHeaderShowDropdown(false);
    };

    const handleSetError = (startTime: number, endTime: number) => {
        setRangeErrorModalShow(true);
        setSelectedDateRange([dayjs(startTime), dayjs(endTime)]);
    };

    const renderAppointmentToolTip = () => {
        const container = document.getElementById('tTipContainer');
        const containerCoords = getCoords(container) || { top: 0, left: 0 };
        const pos = { left: position.left - containerCoords.left, top: position.top - containerCoords.top };
        if (pos.top + TTIP_SIZE.h > (container?.offsetHeight as number)) {
            pos.top = pos.top - TTIP_SIZE.h - TTIP_VERT_POSITION;
        }
        const isNurse = selectedEmployee!.userProfileDTOList?.find((user) => user.profileType === UserProfileDTOProfileType.Nurse);

        const isBusy = tooltipData?.status === 'busy' || tooltipData?.status === 'busy-tentative' || (tooltipData?.patientsNumber || 0) > 0;
        const isPast = dayjs(tooltipData?.endDate).isBefore(dayjs());

        const realServiceType = isNurse
            ? tooltipData?.realAppointmentPlanDef
                ? tooltipData?.realAppointmentServiceType?.[0]
                : tooltipData?.slotServiceType?.[0]
            : tooltipData?.realAppointmentServiceType?.[0] || tooltipData?.slotServiceType?.[0];

        const title =
            realServiceType === 'therapy-session'
                ? t(`consultationSession.courseStep.courses.${tooltipData?.realAppointmentPlanDef || tooltipData?.serviceCategory}`)
                : t(`enums.serviceTypes.${realServiceType}`);

        return (
            <div className={styles.tooltip} style={{ top: pos.top, left: pos.left }}>
                <div className={styles.actions}>
                    {!readonly ? (
                        <div className="d-flex align-items-center">
                            <Button
                                type="text"
                                onClick={() => setShowEditModal(true)}
                                disabled={isBusy || isPast}
                                className={classNames((isBusy || isPast) && styles.disabled, 'p-0 me-3', styles['action-icon'])}
                            >
                                <ScheduleEditIcon />
                            </Button>
                            <Button
                                type="text"
                                onClick={() => setShowDeleteModal(true)}
                                disabled={isBusy || isPast}
                                className={classNames((isBusy || isPast) && styles.disabled, 'p-0', styles['action-icon'])}
                            >
                                <ScheduleDeleteIcon />
                            </Button>
                        </div>
                    ) : tooltipPatientData?.appointmentId ? (
                        <Link
                            to={
                                tooltipData?.realAppointmentServiceType &&
                                tooltipData.realAppointmentServiceType.length > 0 &&
                                tooltipData.realAppointmentServiceType[0] === 'therapy-session'
                                    ? `/procedure/${tooltipPatientData?.appointmentId}`
                                    : `/appointments/${tooltipPatientData?.appointmentId}`
                            }
                            className="d-flex align-items-center"
                        >
                            <p className={styles['tooltip-header']}>{t("appointment_card")}</p>
                            <TriangleRight />
                        </Link>
                    ) : (
                        <div></div>
                    )}
                    <CrossIcon className={styles['action-icon']} onClick={() => setShowTooltip(false)} />
                </div>
                <p className={styles.title}>{title}</p>
                <p className={styles.subtitle}>
                    {tooltipData?.role} <span>{tooltipData?.name}</span>
                </p>
                {realServiceType !== 'therapy-session' && tooltipPatientData?.patients?.length === 1 ? (
                    <p className={styles.subtitle}>
                        {t("clinic_admin_schedule.patient")}{' '}
                        {!readonly ? (
                            <span className={classNames(styles.subtitle, styles.text)}>{tooltipPatientData.patients[0].fullName}</span>
                        ) : (
                            <Link to={'/medcard/' + tooltipPatientData.patients[0].id}>
                                <span className={styles['tooltip-header']}>{tooltipPatientData.patients[0].fullName}</span>
                            </Link>
                        )}
                    </p>
                ) : (
                    !!tooltipPatientData?.patients?.length && (
                        <div className={styles.subtitle}>
                            {t("patients.title")}{' '}
                            <span className={styles.patientInfo}>
                                {t("clinic_admin_schedule.recorded_from", {record: tooltipPatientData.patients.length, all: tooltipPatientData?.slotCapacity})}
                            </span>
                        </div>
                    )
                )}
                <p className={styles.date}>
                    {`${dayjs(tooltipData?.startDate).format('D MMMM, ddd')}`}{' '}
                    {`${dayjs(tooltipData?.startDate).format('HH:mm')} - ${dayjs(tooltipData?.endDate).format('HH:mm')}`}
                </p>
                {!readonly && isBusy && (
                    <div className={styles.warning}>
                        <AlertIconOutlined width={20} />
                        <p className={styles.warningText}>
                            {t("clinic_admin_schedule.call_manager_for_moving")}
                        </p>
                    </div>
                )}
            </div>
        );
    };

    const onAppointmentClick = useCallback((data: { appointmentData: any; cancel: boolean; event?: any }) => {
        // Sometimes there's no event in the data
        const event = data.event || mousePositionRef.current;

        setHeaderShowDropdown(false);
        setTooltipData(data.appointmentData);
        const dWidth = document.body.offsetWidth;
        const coord = { top: event.clientY + TTIP_VERT_POSITION, left: 0 };
        if (event?.clientX > dWidth / 2) {
            coord.left = event.clientX - TTIP_SIZE.w + TTIP_HOR_POSITION;
        } else {
            coord.left = event.clientX - TTIP_HOR_POSITION;
        }
        getSlotDetails(data.appointmentData.practitionerId, data.appointmentData.id).then((result) => {
            setTooltipPatientData(result.data);
            setPosition(coord);
            setShowTooltip(true);
        });

        data.cancel = true;
    }, []);

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setShowTooltip(false);
        setHeaderShowDropdown(false);
    };

    const handleEdit = (updateSlotDTO?: UpdateSlotDTO) => {
        setHeaderShowDropdown(false);
        setIsEditError(true);
        updateSlot(practitionerId, tooltipData!.id, updateSlotDTO || {})
            .then(() => {
                getSlotsForPractitioner(practitionerId, start!, end!);
                setShowEditModal(false);
            })
            .catch((e) => {
                setShowEditModal(false);
                if (e.response.data.code === 'INVALID_ARGUMENTS_EXCEPTION') {
                    setRangeErrorModalShow(true);
                } else if (e.response.data.code === 'SCHEDULE_START_IN_PAST_EXCEPTION') {
                    message.error(t("clinic_admin_schedule.cant_set_previous_date"));
                }

                setSelectedDateRange([dayjs(updateSlotDTO?.startDateTime), dayjs(updateSlotDTO?.endDateTime)]);
            })
            .finally(() => {
                setShowTooltip(false);
            });
    };

    const onCellClick = (e: any) => {
        e.cancel = true;
        setShowTooltip(false);
        setHeaderShowDropdown(false);
    };

    return (
        <div className={styles.wrapper}>
            {showRemoveModal && (
                <RemoveScheduleSlotModal
                    showModal={showRemoveModal}
                    onCancel={() => setShowRemoveModal(false)}
                    onSuccess={handleRemoveDaySlot}
                    slots={events}
                    selectedDate={selectedDate}
                />
            )}
            {showDeleteModal && (
                <Modal
                    title={t("clinic_admin_schedule.delete_event")}
                    open={showDeleteModal}
                    onCancel={() => setShowDeleteModal(false)}
                    footer={[
                        <div key="empty" />,
                        <div key="actions">
                            <Button onClick={() => setShowDeleteModal(false)}>{t("cancel_button")}</Button>
                            <Button type="primary" onClick={() => handleDeleteSlot(tooltipData!)}>
                                {t("clinic_admin_schedule.yes_delete")}
                            </Button>
                        </div>,
                    ]}
                >
                    <p>{t("clinic_admin_schedule.sure_delete_event")}</p>
                </Modal>
            )}
            {showEditModal && (
                <EditScheduleSlotModal
                    onCancel={handleCloseEditModal}
                    onSuccess={handleEdit}
                    employee={selectedEmployee!}
                    slot={tooltipData!}
                    showModal={showEditModal}
                    isNurse={selectedEmployee?.userProfileDTOList?.some((user) => user.profileType === UserProfileDTOProfileType.Nurse) || false}
                />
            )}
            {showAddModal && (
                <AddEventToScheduleModal
                    practitionerId={practitionerId}
                    onCancel={handleCloseAddEventModal}
                    onSuccess={handleCreateSlot}
                    showModal={showAddModal}
                    onError={handleSetError}
                    isNurse={selectedEmployee?.userProfileDTOList?.some((user) => user.profileType === UserProfileDTOProfileType.Nurse) || false}
                />
            )}
            {rangeErrorModalShow && (
                <Modal
                    width={720}
                    title={isEditError ? t("clinic_admin_schedule.edit_appointment") : t("clinic_admin_schedule.adding_appointments")}
                    open={rangeErrorModalShow}
                    onCancel={() => {
                        setIsEditError(false);
                        setRangeErrorModalShow(false);
                    }}
                    footer={[
                        <div key="empty" />,
                        <div key="actions">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setIsEditError(false);
                                    setRangeErrorModalShow(false);
                                }}
                            >
                                {t("clinic_admin_schedule.close")}
                            </Button>
                        </div>,
                    ]}
                >
                    <p className={styles.warningText}>
                        {isEditError ? t("clinic_admin_schedule.cant_edit_appointment") : t("clinic_admin_schedule.cant_add_appointments")}, {t("clinic_admin_schedule.because_employee")}{' '}
                        {selectedEmployee?.firstName} {selectedEmployee?.lastName} {t("clinic_admin_schedule.already_has_appointments_for_period")}{' '}
                        {selectedDateRange[0].format('DD.MM.YYYY')} – {selectedDateRange[1].format('DD.MM.YYYY')} {t("clinic_admin_schedule.from")}{' '}
                        {dayjs(selectedDateRange[0]).format('HH:mm')} {t("clinic_admin_schedule.till")} {dayjs(selectedDateRange[1]).format('HH:mm')}
                    </p>
                </Modal>
            )}
            <h1 className={styles.title}>{t("clinic_admin_schedule.schedule")}</h1>
            <div onClick={() => setHeaderShowDropdown(false)} className={styles.filterBlock}>
                <Row align="middle" gutter={24}>
                    <Col span={8} className={styles.searchInput}>
                        <SearchIcon className={styles.searchIco} />
                        <AutoComplete
                            dropdownMatchSelectWidth={false}
                            className={styles.autocompleteCtrl}
                            onChange={handleChangeInput}
                            onSelect={(e) => handleChooseEmployee(filteredEmployeeList.find((i) => i.id === e))}
                            value={filterQuery}
                            options={autocompleteOptions}
                            placeholder={t("clinic_admin_schedule.employee_last_name")}
                            filterOption={(inputValue, option) => option?.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                        />
                    </Col>
                    {selectedEmployee && (
                        <Col span={16}>
                            <Row align="middle" justify="space-between">
                                <Col span={10} className="d-flex align-items-center">
                                    <Button
                                        onClick={() => {
                                            setCurrentView('day');
                                            setHeaderShowDropdown(false);
                                        }}
                                        className={classNames(styles.tab, currentView === 'day' && styles.active_tab)}
                                        type={currentView === 'day' ? 'primary' : 'default'}
                                    >
                                        {t("afternoon")}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setCurrentView('week');
                                            setHeaderShowDropdown(false);
                                        }}
                                        className={classNames('mx-1', styles.tab, currentView === 'week' && styles.active_tab)}
                                        type={currentView === 'week' ? 'primary' : 'default'}
                                    >
                                        {t("clinic_admin_schedule.week")}
                                    </Button>
                                    <Button
                                        type={currentView === 'month' ? 'primary' : 'default'}
                                        onClick={() => {
                                            setCurrentView('month');
                                            setHeaderShowDropdown(false);
                                        }}
                                        className={classNames(styles.tab, currentView === 'month' && styles.active_tab)}
                                    >
                                        {t("clinic_admin_schedule.month")}
                                    </Button>
                                </Col>
                                <Col span={4}></Col>
                                <Col span={4}>
                                    <Popover
                                        content={
                                            <ul className={styles.legendContent}>
                                                {legendItems.map((x, i) => (
                                                    <li key={i}>{x.label}</li>
                                                ))}
                                            </ul>
                                        }
                                        placement="bottom"
                                        trigger="hover"
                                    >
                                        <div className={styles.legend}>
                                            <InfoOutlinedIcon className="me-1" /> {t("clinic_admin_schedule.legend")}
                                        </div>
                                    </Popover>
                                </Col>

                                {!readonly && (
                                    <Col span={6} className="d-flex justify-content-end">
                                        <Button onClick={() => setShowAddModal(true)} type="primary">
                                            {t("clinic_admin_schedule.add_appointments")}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    )}
                </Row>
            </div>
            <div>
                {!selectedEmployee ? (
                    <div className={styles.emptySchedule}>
                        <div className={styles['empty-description']}>
                            <CalendarInputIcon width={32} height={36} />
                            {t("clinic_admin_schedule.select_employee")}
                        </div>
                    </div>
                ) : (
                    <Paper className="position-relative" id="tTipContainer">
                        {tooltipData && showTooltip && renderAppointmentToolTip()}
                        {showHeaderDropdown && renderHeaderDropdown()}

                        <SchedulerMemo
                            schedulerRef={schedulerRef}
                            currentDate={currentDate}
                            currentView={currentView}
                            events={events}
                            handleReadyChanged={handleReadyChanged}
                            renderAppointment={renderAppointment}
                            onCellClick={onCellClick}
                            onAppointmentClick={onAppointmentClick}
                            renderAppointmentCollector={renderAppointmentCollector}
                            renderAppointmentCollectorComponent={renderAppointmentCollectorComponent}
                            selectedEmployee={selectedEmployee}
                        />
                    </Paper>
                )}
            </div>
        </div>
    );
};
