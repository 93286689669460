import { ProcedureStatus, ProcedureTypeEnum } from '@enums';
import { IGeneralProcedure } from './general-procedure.model';
import { IPatientProcedureInteraction } from './procedure-interaction.model';
import { ProcedureSettingsValueType } from './procedure-settings.models';

export interface IParticipantProcedure extends IGeneralProcedure {
    interaction?: IPatientProcedureInteraction;
    status?: ProcedureStatus;
    order?: number;
    startedOrder?: number;
    doctorComment?: string;
    doctorConfirmed?: boolean;
    targetPoints?: number;
    totalDuration?: number;
}

export class ParticipantProcedure implements IParticipantProcedure, IGeneralProcedure {
    public id?: string;
    public interaction?: IPatientProcedureInteraction;
    public order?: number;
    public startedOrder?: number;
    public doctorComment?: string;
    public doctorConfirmed?: boolean;
    public accommodationCapacity?: string;
    public type?: ProcedureTypeEnum;
    public settings?: Partial<ProcedureSettingsValueType>;
    public isInteractive?: boolean;
    public targetPoints: number;

    public finishedStepsCount = 0;
    public maxStepsCount = 0;
    public totalDuration = 0;

    public get isLastStep(): boolean {
        const isProcedureStepped = this.maxStepsCount > 0;
        return isProcedureStepped ? this.finishedStepsCount >= this.maxStepsCount : false;
    }

    private _status?: ProcedureStatus;
    public get status(): ProcedureStatus | undefined {
        return this._status;
    }
    public set status(value: ProcedureStatus | undefined) {
        if (this._status !== value) {
            this._status = value;
            this._changeProgress(value);
        }
    }

    private _progressTime: number;
    public get progressTime(): number {
        return this._progressTime;
    }
    public set progressTime(value: number) {
        this._progressTime = value;
    }

    private _progressTimer?: NodeJS.Timer;

    private _procedureEndCallback: () => void;

    constructor(model: IParticipantProcedure, procedureEndCallback: () => void) {
        this.id = model.id;
        this.interaction = model.interaction;
        this.status = model.status || ProcedureStatus.notStarted;
        this.order = model.order;
        this.startedOrder = model.startedOrder || 0;
        this.doctorComment = model.doctorComment;
        this.doctorConfirmed = model.doctorConfirmed;
        this.type = model.type;
        this.settings = model.settings;
        this.isInteractive = model.isInteractive;
        this.targetPoints = model.targetPoints || 0;
        this.progressTime = 0;
        this.totalDuration = model.totalDuration || 0;
        this._procedureEndCallback = procedureEndCallback;

        switch (model.type) {
            case ProcedureTypeEnum.BIFIXATION_RECOVERY:
                this.maxStepsCount = 3;
                break;
            case ProcedureTypeEnum.AMBLYOPIA:
                this.maxStepsCount = 6;
                break;
            case ProcedureTypeEnum.AVETISOV_MATS:
            case ProcedureTypeEnum.DISSOCIATION:
                this.maxStepsCount = 100; // technically infinite
                break;
            default:
                break;
        }
    }

    // WARNING - very thin place
    private _changeProgress(status: ProcedureStatus | undefined): void {
        const duration = +(this.settings?.duration || 0);
        if (!duration) {
            clearInterval(this._progressTimer);
            return; // todo maybe some procedures will be infinite
        }

        switch (status) {
            case ProcedureStatus.started:
                this.progressTime = 0;
                this._initProgressTimer(duration);
                break;
            case ProcedureStatus.inProgress:
            case ProcedureStatus.continued:
                this._initProgressTimer(duration);
                break;
            case ProcedureStatus.stopped:
            case ProcedureStatus.notStarted:
                this.progressTime = 0;
                this.finishedStepsCount = 0;
                this.totalDuration = 0;
                clearInterval(this._progressTimer);
                break;
            case ProcedureStatus.finished:
            case ProcedureStatus.failed:
                this.finishedStepsCount = 0;
                clearInterval(this._progressTimer);
                break;
            case ProcedureStatus.finishedButNeedConfirmation:
                this.totalDuration += this.progressTime;
                break;
            case ProcedureStatus.waitingApproveToChangeGlasses:
                // for this procedure interaction emits by doctor
                if (this.type === ProcedureTypeEnum.DISSOCIATION) {
                    this._procedureEndCallback();
                    clearInterval(this._progressTimer);
                }
                break;
            case ProcedureStatus.changeGlasses:
                // for this procedure interaction emits by doctor
                if (this.type === ProcedureTypeEnum.DISSOCIATION) {
                    this._initProgressTimer(duration);
                }
                break;
            default:
                clearInterval(this._progressTimer);
                break;
        }
    }

    private _initProgressTimer(duration: number): void {
        if (this._progressTimer) {
            clearInterval(this._progressTimer);
        }

        this._progressTimer = setInterval(() => {
            if (duration > this.progressTime!) {
                this.progressTime!++;
            } else {
                // procedure's time is over -> manage results
                this._procedureEndCallback();
                clearInterval(this._progressTimer);
                this.totalDuration += this.progressTime;
                this.progressTime = 0;
            }
        }, 1000);
    }

    public dispose(): void {
        clearInterval(this._progressTimer);
    }
}
