import {
    CourseAppointmentDetails,
    EncounterReasonDTOReasonType,
    SlotDTO,
    UpdateAppointmentDTOStatus,
    getCourseCompletelyFreeSlots,
    rescheduleAppointment,
    updateAppointmentStatus,
} from '@api/mainServiceAPI';
import { AlertIconOutlined } from '@icons';
import { Button, Col, Form, Modal, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EncounterChangeReasonEnum } from 'src/enums/encounter-change-reason.enum';
import styles from './CancelProcedureForAllModal.module.scss';

interface ICancelProcedureForAllModalProps {
    showModal: boolean;
    onSuccess: () => void;
    onCancel?: () => void;
    encounter: CourseAppointmentDetails;
}

interface FormValues {
    reason: EncounterReasonDTOReasonType;
    option: UpdateAppointmentDTOStatus;
    course: string;
}

const { Option } = Select;

export const CancelProcedureForAllModal = ({ showModal, onSuccess, onCancel, encounter }: ICancelProcedureForAllModalProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [courses, setCourses] = useState<SlotDTO[]>([]);
    const [selectedOption, setSelectedOption] = useState<UpdateAppointmentDTOStatus>('cancelled');
    const cancelOption = {
        title: t("cancel_procedure_all_modal.cancel_procedures"),
        subtitle: t("cancel_procedure_all_modal.patient_get_message"),
        value: UpdateAppointmentDTOStatus.cancelled,
    };
    const replaceOption = {
        title: t("cancel_procedure_all_modal.move_procedure"),
        subtitle: t("cancel_procedure_all_modal.patient_will_moved_message"),
        value: UpdateAppointmentDTOStatus.pending,
    };

    const initialValues = {
        reason: '',
        option: UpdateAppointmentDTOStatus.cancelled,
        course: '',
    };

    useEffect(() => {
        getCourseCompletelyFreeSlots({
            start: encounter!.appointmentDetailsDTO!.start!,
            courseDefinition: encounter.appointmentDetailsDTO!.partOf!,
        }).then((result) => {
            setCourses(result.data);
        });
    }, []);

    const onFinish = (values: FormValues) => {
        if (values.option === UpdateAppointmentDTOStatus.cancelled) {
            updateAppointmentStatus(encounter.appointmentDetailsDTO!.appointmentId!, {
                status: values.option,
                reason: {
                    encounterFhirId: encounter.appointmentDetailsDTO!.appointmentId!,
                    reasonType: values.reason,
                },
            }).then(() => {
                onSuccess();
            });
        } else {
            rescheduleAppointment(encounter.appointmentDetailsDTO!.appointmentId!, {
                newSlotId: values.course,
                reasonDTO: {
                    encounterFhirId: encounter.appointmentDetailsDTO!.appointmentId,
                    managerFhirId: encounter.okoEventDTO?.manager?.id,
                    reasonType: values.reason,
                },
            }).then(() => {
                onSuccess();
            });
        }
    };

    const changeOption = (e: RadioChangeEvent) => {
        setSelectedOption(e.target.value);
    };

    return (
        <Modal
            onCancel={onCancel}
            width={800}
            title={t("cancel_procedure_all_modal.care_plan_changing")}
            open={showModal}
            footer={[
                <div key="empty" />,
                <div key="container">
                    <Button onClick={onCancel} key="cancel">
                        {t("cancel_button")}
                    </Button>

                    <Button onClick={form.submit} type="primary" key="submit">
                        {t("cancel_procedure_all_modal.approve_changing")}
                    </Button>
                </div>,
            ]}
        >
            <div className={styles.wrapper}>
                <div className={styles.modalDescription}>
                    <Row align="top" gutter={8} className={styles.descriptionRow}>
                        <Col span={3}>
                            <p className={styles.descriptionLabel}>{t("cancel_procedure_all_modal.appointment")}</p>{' '}
                        </Col>
                        <Col span={20}>
                            <p className={styles.descriptionText}>
                                «{encounter?.appointmentDetailsDTO?.partOf && t(`enums.planDefinition.${encounter?.appointmentDetailsDTO?.partOf}`)}»{' '}
                                {encounter?.appointmentDetailsDTO?.start &&
                                    format(new Date(encounter?.appointmentDetailsDTO?.start), 'dd.MM.yyyy HH:mm')}
                            </p>
                        </Col>
                    </Row>
                    <Row gutter={8} className={styles.descriptionRow}>
                        <Col span={3}>
                            <p className={styles.descriptionLabel}>{t("cancel_procedure_all_modal.nurse")}</p>{' '}
                        </Col>
                        <Col span={20}>
                            <p className={styles.descriptionText}>{encounter.appointmentDetailsDTO?.practitionerName}</p>{' '}
                        </Col>
                    </Row>
                    <Row gutter={8} className={styles.descriptionRow}>
                        <Col span={3}>
                            <p className={styles.descriptionLabel}>{t("patients.title")}</p>{' '}
                        </Col>
                        <Col span={20}>
                            <p className={styles.descriptionText}>{encounter.patientParticipants?.length}</p>{' '}
                        </Col>
                    </Row>
                </div>
                <Form form={form} onFinish={onFinish} initialValues={initialValues}>
                    <p className={styles.label}>{t('modals.changeConsultation.reason')}</p>
                    <Form.Item name="reason">
                        <Select className="w-100">
                            {Object.keys(EncounterChangeReasonEnum).map((x) => (
                                <Option key={x} value={x}>
                                    {t('enums.encounterChangeReason.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="option">
                        <Radio.Group onChange={changeOption}>
                            <div className={styles.topContent}>
                                <div className={styles['option-wrapper']}>
                                    <Radio value={cancelOption.value}>
                                        <div>
                                            <p className={styles['option-title']}>{cancelOption.title}</p>

                                            <p className={styles['option-subtitle']}>{cancelOption.subtitle}</p>
                                        </div>
                                    </Radio>
                                </div>
                            </div>
                            <div className={styles['option-wrapper']}>
                                <Radio disabled={courses.length === 0} value={replaceOption.value}>
                                    <div>
                                        <p className={styles['option-title']}>{replaceOption.title}</p>

                                        <p className={styles['option-subtitle']}>{replaceOption.subtitle}</p>
                                    </div>
                                </Radio>
                            </div>
                        </Radio.Group>
                    </Form.Item>

                    {courses.length > 0 ? (
                        <div className={styles['reason-wrapper']}>
                            <p className={styles.label}>{t("cancel_procedure_all_modal.select_appointment")}</p>
                            <Form.Item name="course">
                                <Select disabled={selectedOption === UpdateAppointmentDTOStatus.cancelled}>
                                    {courses.map((course, i) => (
                                        <Option key={`reason-${i}`} value={course.id}>
                                            {`${course.practitionerName}, ${
                                                course.startDateTime && format(new Date(course.startDateTime), 'dd.MM.yyyy HH:mm')
                                            }, ${t('enums.planDefinition.' + encounter?.appointmentDetailsDTO?.partOf)}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    ) : (
                        <div className={styles['reason-wrapper']}>
                            <div className={styles.warningSection}>
                                <p className={styles['warning-title']}>
                                    <AlertIconOutlined className="me-2" /> {t("cancel_procedure_all_modal.cant_move")}
                                </p>
                                <p className={styles['warning-text']}>
                                    {t("cancel_procedure_all_modal.call_with_admin_message", {dateTime: encounter?.appointmentDetailsDTO?.start &&
                                            format(new Date(encounter?.appointmentDetailsDTO?.start), 'dd.MM.yyyy HH:mm')})}
                                </p>
                            </div>
                        </div>
                    )}
                </Form>
            </div>
        </Modal>
    );
};
