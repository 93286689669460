import styles from './Logo.module.scss';
import { selectLogo } from 'src/store/organization';
import { LogoOKO, LogoOKOBig } from '@icons';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

interface ILogoProps {
    big?: boolean,
    marginBottom?: boolean
}

export const Logo = ({ big, marginBottom }: ILogoProps) => {
    const customLogo = useSelector(selectLogo);
   
    return (
        <div className={classNames(styles.wrapper, marginBottom ? styles.logoMargin : '' )}>
                { 
                    customLogo ? 
                    <img 
                        src={customLogo} 
                        className={styles.customLogo} 
                    /> :
                        big ? 
                        <LogoOKOBig className="logo"/> :
                        <LogoOKO className="logo" /> 
                }
        </div>
    );
};
