import { UserDocumentDTOType } from '@api/documentServiceAPI';
import { CreateUserProfileDTOAidFamilyMemberCode, CreateUserProfileDTOGender, UserProfileDTOProfileType } from '@api/userServiceAPI';
import { UploadFile } from '@components';
import { CalendarInputIcon } from '@icons';
import { CreateUserProfileDTOExt, addNewPatient } from '@slicePatient';
import { selectAuthUser } from '@sliceUser';
import { Logger } from '@utils/logger';
import { Button, Card, Checkbox, Col, DatePicker, Form, FormInstance, Input, Row, Select, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './CreatePatientPage.module.scss';
import SnilsInput, { SNILS_PATTERN } from 'src/components/SnilsInput/SnilsInput';
import { EsiaLoginLocalStorage } from 'src/services/local-storage/EsiaLoginLocalStorage';

type StepType = 1 | 2 | 3 | 4;

export const CreatePatientPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const authUser = useSelector(selectAuthUser);

    const [step, setStep] = useState<StepType>(1);
    const [noOMS, setNoOMS] = useState(false);
    const [patientIsChild, setPatientIsChild] = useState(false);
    const [parentsApproval, setParentsApproval] = useState(false);
    const [additionalField, setAdditionalField] = useState(false);
    const [disableDuplication, setDisableDuplication] = useState(false);
    const [alreadyHasProfileAccount, setAlreadyHasProfileAccount] = useState(false);
    const [noMiddleNameFlag, setNoMiddleNameFlag] = useState(authUser?.middleName === null);
    const [relativityOptions] = useState<CreateUserProfileDTOAidFamilyMemberCode[]>(['MTH', 'FTH', 'GRMTH', 'GRFTH', 'OTHER']);
    const [genderOptions] = useState(Object.values(CreateUserProfileDTOGender));

    const genderValue = useRef(null);
    const data = useRef({});

    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const { validateForm: form2ValidateForm, isSubmitDisabled: form2SubmitDisabled } = useFormValidation(form2);
    const { validateForm: form3ValidateForm, isSubmitDisabled: form3SubmitDisabled } = useFormValidation(form3);
    const { validateForm: form4ValidateForm, isSubmitDisabled: form4SubmitDisabled } = useFormValidation(form4);

    useEffect(() => {
        if (authUser?.userProfileDTOList) {
            setAlreadyHasProfileAccount(
                !!authUser?.userProfileDTOList.find(
                    (dto) =>
                        dto.userProfileDetailsDTO?.firstName === authUser.firstName &&
                        dto.userProfileDetailsDTO?.lastName === authUser.lastName &&
                        dto.userProfileDetailsDTO?.birthDate === authUser.birthDate,
                ),
            );
        }
    }, [authUser]);

    const goToStep = (_step: StepType, _patientIsChild?: boolean) => {
        data.current = { ...data.current, ...form2.getFieldsValue(), ...form3.getFieldsValue(), ...form4.getFieldsValue() };

        if (_patientIsChild !== undefined) {
            setPatientIsChild(_patientIsChild);
            // setNoMiddleNameFlag(!_patientIsChild);
        }

        if (step === 1 && _step === 2) {
            setTimeout(() => {
                form2.resetFields();
            });
        }

        setStep(_step);
    };

    const genderChosenHandler = (values: any[]) => {
        const oldValue = genderValue.current;
        const newValues = [...values];

        if (values.length > 1) {
            newValues.splice(
                values.findIndex((val) => val === oldValue),
                1,
            );
        }

        genderValue.current = newValues[0];
        form2.setFieldValue('gender', newValues[0] ? [newValues[0]] : undefined);
    };

    const relativityHandler = (value: CreateUserProfileDTOAidFamilyMemberCode) => {
        setParentsApproval(value !== 'MTH' && value !== 'FTH');
        setAdditionalField(value === 'OTHER');
        validate();
    };

    const noMiddleNameHandler = (evt: CheckboxChangeEvent) => {
        if (form2 && evt.target.checked && form2.getFieldValue('middleName')) {
            form2.setFields([{ name: 'middleName', value: null, errors: [] }]);
        }
        setNoMiddleNameFlag(evt.target.checked);
        form2?.validateFields(['middleName']);
    };

    const savePatient = () => {
        if (authUser?.id) {
            setDisableDuplication(true);
            const { firstName, middleName, lastName, birthDate, gender } = form2.getFieldsValue();
            const { relativity, addRelationship } = form3.getFieldsValue();

            const { passportOfRepresentativeFile, parentsApprovalFile } = form3.getFieldsValue();
            const { omsFile, birthCertificate, passport, oms, snils } = form4.getFieldsValue();
            
            const patient: CreateUserProfileDTOExt = {
                firstName,
                middleName,
                lastName,
                birthDate: birthDate.format('YYYY-MM-DD'),
                profileType: UserProfileDTOProfileType.Patient,
                gender: gender[0],
                descriptionOfRepresentative: addRelationship,
                files: [],
                oms,
                omsFile,
                snils: snils
            };

            if (relativity) {
                patient.aidFamilyMemberCode = relativity;
            }

            omsFile && patient.files?.push(omsFile);
            passport && patient.files?.push(passport);
            birthCertificate && patient.files?.push(birthCertificate);
            parentsApprovalFile && patient.files?.push(parentsApprovalFile);
            passportOfRepresentativeFile && patient.files?.push(passportOfRepresentativeFile);
            
            if(authUser.esia && authUser.email) {
                EsiaLoginLocalStorage.clearDataByEmail(authUser.email);
            }

            dispatch(addNewPatient(patient));
        } else {
            setDisableDuplication(false);
            Logger.error('No AuthUser');
        }
    };

    const esiaSnils = useMemo(() => {
        return authUser?.esia && authUser?.email ? EsiaLoginLocalStorage.getDataByEmail(authUser.email)?.personRoot?.snils : undefined;
    }, [authUser]);

    const esiaGender = useMemo(() => {
        const gender = authUser?.esia && authUser?.email ? EsiaLoginLocalStorage.getDataByEmail(authUser.email)?.personRoot?.gender : undefined;
        if(gender == 'M') {
            return ['male'];
        } else if(gender == 'F') {
            return ['female'];
        } else {
            return undefined;
        }
    }, [authUser]);

    useEffect(() => {
        if(authUser?.esia) {
            validate();
        }
    }, [ step ]);

    useEffect(() => {
        validate();
    }, [additionalField, parentsApproval, noMiddleNameFlag, noOMS]);

    const checkForm4 = () => {
        const fieldsToCheck = patientIsChild ? ['birthCertificate'] : ['passport'];
        if (!noOMS) {
            fieldsToCheck.push('oms', 'omsFile');
        }
        fieldsToCheck.push('snils');
        form4ValidateForm(fieldsToCheck);
    };

    const validate = () => {
        let fieldsToCheck;

        switch (step) {
            case 2: {
                fieldsToCheck = ['firstName', 'lastName', 'birthDate', 'gender'];
                if (!noMiddleNameFlag) {
                    fieldsToCheck.push('middleName');
                }
                form2ValidateForm(fieldsToCheck);
                break;
            }
            case 3: {
                if (patientIsChild) {
                    fieldsToCheck = ['relativity', 'passportOfRepresentativeFile'];
                    if (additionalField) {
                        fieldsToCheck.push('addRelationship');
                    }
                    if (parentsApproval) {
                        fieldsToCheck.push('parentsApprovalFile');
                    }
                    form3ValidateForm(fieldsToCheck);
                } else {
                    checkForm4();
                }
                break;
            }
            case 4: {
                checkForm4();
            }
        }
    };

    const changeFileEvent = (form: FormInstance<any>, fld: string, files: string[]) => {
        form.setFieldValue(fld, files[0]);
        form.validateFields([fld]);
        validate();
    };

    return (
        <div className={styles.pageWrapper}>
            <div className={classNames(styles.cardWrapper, step === 1 ? styles.visible : styles.hidden)}>
                <Card
                    title={
                        <Space className={styles.header}>
                            <h4>{t('CreatePatientPage.title')} *</h4>
                            <span className={styles.stepNumber}>
                                {t('CreatePatientPage.step')} {step}
                            </span>
                        </Space>
                    }
                    className={styles.step}
                >
                    <p className={styles.remark}>{t('CreatePatientPage.step1.remark')}</p>
                    <p className={styles.description}>
                        {authUser?.firstName}
                        {alreadyHasProfileAccount ? t('CreatePatientPage.step1.disabled') : t('CreatePatientPage.step1.description')}
                    </p>
                    <Row gutter={24}>
                        <Col sm={12} xs={24}>
                            <Button disabled={alreadyHasProfileAccount} onClick={() => goToStep(2, false)} className="w-100 mt-4">
                                {t('CreatePatientPage.labels.yesImAPatient')}
                            </Button>
                        </Col>
                        <Col sm={12} xs={24}>
                            <Button onClick={() => goToStep(2, true)} className="w-100 mt-4">
                                {t('CreatePatientPage.labels.noAnotherPerson')}
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </div>
            <div className={classNames(styles.cardWrapper, step === 2 ? styles.visible : styles.hidden)}>
                <Card
                    title={
                        <Space className={styles.header}>
                            <h4>{t('CreatePatientPage.title')}</h4>
                            <span className={styles.stepNumber}>
                                {t('CreatePatientPage.step')} {step} {t('CreatePatientPage.from')} {patientIsChild ? 4 : 3}
                            </span>
                        </Space>
                    }
                    className={styles.step}
                >
                    <p className={styles.description}>{t('CreatePatientPage.step2.description')}</p>
                    <Form
                        onChange={validate}
                        form={form2}
                        name="patientData"
                        wrapperCol={{ span: 24 }}
                        initialValues={
                            patientIsChild
                                ? {
                                      firstName: '',
                                      lastName: '',
                                      middleName: '',
                                      birthDate: null,
                                      gender: undefined,
                                  }
                                : {
                                      firstName: authUser?.firstName,
                                      lastName: authUser?.lastName,
                                      middleName: authUser?.middleName,
                                      birthDate: authUser?.birthDate ? dayjs(authUser?.birthDate) : null,
                                      gender: esiaGender,
                                  }
                        }
                        autoComplete="off"
                        layout="vertical"
                        requiredMark={false}
                        onFinish={() => goToStep(3)}
                    >
                        <Form.Item
                            name="lastName"
                            className="w-100"
                            rules={[{ required: true, message: t('CreatePatientPage.validation.lastNameIsRequired') }]}
                            label={t('CreatePatientPage.labels.lastName')}
                        >
                            <Input autoFocus disabled={!patientIsChild} />
                        </Form.Item>
                        <Form.Item
                            name="firstName"
                            className="w-100"
                            rules={[{ required: true, message: t('CreatePatientPage.validation.firstNameIsRequired') }]}
                            label={t('CreatePatientPage.labels.firstName')}
                        >
                            <Input disabled={!patientIsChild} />
                        </Form.Item>
                        <Form.Item
                            name="middleName"
                            className={classNames('w-100', styles.labelW100)}
                            rules={[{ required: !noMiddleNameFlag, message: t('CreatePatientPage.validation.middleNameIsRequired') }]}
                            label={
                                <Row className="w-100" justify="space-between">
                                    <Col>{t('CreatePatientPage.labels.middleName')}</Col>
                                    <Col>
                                        <Checkbox
                                            className={styles.smallCheckBox}
                                            onChange={noMiddleNameHandler}
                                            checked={noMiddleNameFlag}
                                            disabled={!patientIsChild}
                                        >
                                            {t('CreatePatientPage.labels.noMiddleName')}
                                        </Checkbox>
                                    </Col>
                                </Row>
                            }
                        >
                            <Input disabled={(!patientIsChild && !!authUser?.middleName) || noMiddleNameFlag} />
                        </Form.Item>
                        <Row gutter={24} align="middle" justify="space-between">
                            <Col md={12} xs={24}>
                                <Form.Item
                                    name="birthDate"
                                    label={t('CreatePatientPage.labels.birthDate')}
                                    rules={[{ required: true, message: t('CreatePatientPage.validation.birthDateIsRequired') }]}
                                >
                                    <DatePicker
                                        onChange={validate}
                                        placeholder=""
                                        className="w-100"
                                        suffixIcon={<CalendarInputIcon />}
                                        disabledDate={(e) => e > dayjs()}
                                        disabled={!patientIsChild && !!(authUser as any).birthDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24}>
                                <div className={classNames('w-100', styles.genderLabel)}>
                                    <Row gutter={24} justify="end" align="top">
                                        <Col className={styles.genderCel}>{t('CreatePatientPage.labels.gender')}</Col>
                                        <Col>
                                            <Form.Item
                                                name="gender"
                                                rules={[{ required: true, message: t('CreatePatientPage.validation.genderIsRequired') }]}
                                            >
                                                <Checkbox.Group
                                                    onChange={genderChosenHandler}
                                                    className={styles.gender}
                                                    options={genderOptions.map((x) => ({ label: t('dictionaries.genders.short.' + x), value: x }))}
                                                ></Checkbox.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col sm={12} xs={24}>
                                <Button htmlType="button" onClick={() => goToStep(1)} className="w-100 mt-4">
                                    {t('CreatePatientPage.labels.back')}
                                </Button>
                            </Col>
                            <Col sm={12} xs={24}>
                                <Button type="primary" htmlType="submit" className="w-100 mt-4" disabled={form2SubmitDisabled}>
                                    {t('CreatePatientPage.labels.forward')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
            <div
                className={classNames(
                    styles.cardWrapper,
                    (step === 3 && !patientIsChild) || (step === 4 && patientIsChild) ? styles.visible : styles.hidden,
                )}
            >
                <Card
                    title={
                        <Space className={styles.header}>
                            <h4>{t('CreatePatientPage.title')}</h4>
                            <span className={styles.stepNumber}>
                                {t('CreatePatientPage.step')} {step} {t('CreatePatientPage.from')} {patientIsChild ? 4 : 3}
                            </span>
                        </Space>
                    }
                    className={styles.step}
                >
                    <p className={styles.description}>{t('CreatePatientPage.step4.description')}</p>
                    <Form
                        onChange={validate}
                        form={form4}
                        name="documents"
                        wrapperCol={{ span: 24 }}
                        initialValues={{ oms: '', omsFile: null, birthCertificate: null, passport: null, snils: esiaSnils }}
                        autoComplete="off"
                        layout="vertical"
                        requiredMark={false}
                        onFinish={savePatient}
                    >
                        {!noOMS ? (
                            <>
                                <Form.Item
                                    name="oms"
                                    className="w-100 mb-4"
                                    rules={[
                                        { required: true, message: t('CreatePatientPage.validation.omsIsRequired') },
                                        { max: 16, message: t('CreatePatientPage.validation.omsLength') },
                                        { min: 16, message: t('CreatePatientPage.validation.omsLength') },
                                    ]}
                                    label={t('CreatePatientPage.labels.oms')}
                                >
                                    <Input type="number" autoFocus placeholder={t("create_patient_page.polis_number")} />
                                </Form.Item>
                                <Form.Item
                                    name="omsFile"
                                    className="w-100 mb-4"
                                    rules={[{ required: true, message: t('CreatePatientPage.validation.omsFileIsRequired') }]}
                                >
                                    <UploadFile
                                        docType={UserDocumentDTOType.OMS}
                                        title={t('CreatePatientPage.labels.oms')}
                                        subtitle={t('CreatePatientPage.labels.uploadFileFormat')}
                                        changeEvent={(files) => changeFileEvent(form4, 'omsFile', files as string[])}
                                        maxSizeMb={5}
                                    />
                                </Form.Item>
                            </>
                        ) : (
                            <p>
                                <label>{t('CreatePatientPage.labels.oms')}</label>
                            </p>
                        )}
                        <Checkbox className={classNames('mb-4', noOMS && 'mt-4')} onChange={(evt) => setNoOMS(evt.target.checked)}>
                            {t('CreatePatientPage.labels.noOMS')}
                        </Checkbox>
                        {patientIsChild ? (
                            <Form.Item
                                name="birthCertificate"
                                className="w-100 mb-4"
                                rules={[{ required: true, message: t('CreatePatientPage.validation.birthCertificateFileIsRequired') }]}
                                label={t('CreatePatientPage.labels.birthCertificateOfPatient')}
                            >
                                <UploadFile
                                    docType={UserDocumentDTOType.BIRTH_CERTIFICATE}
                                    title={t('CreatePatientPage.labels.birthCertificate')}
                                    subtitle={t('CreatePatientPage.labels.uploadFileFormat')}
                                    changeEvent={(files) => changeFileEvent(form4, 'birthCertificate', files as string[])}
                                    maxSizeMb={5}
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name="passport"
                                className="w-100 mb-4"
                                rules={[{ required: true, message: t('CreatePatientPage.validation.passportFileIsRequired') }]}
                                label={t('CreatePatientPage.labels.passport')}
                            >
                                <UploadFile
                                    docType={UserDocumentDTOType.PASSPORT}
                                    title={t('CreatePatientPage.labels.passportPages')}
                                    subtitle={t('CreatePatientPage.labels.uploadFileFormat')}
                                    changeEvent={(files) => changeFileEvent(form4, 'passport', files as string[])}
                                    maxSizeMb={5}
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            className={styles.bottomSection}
                            name="snils"
                            rules={[
                                { required: true, message: t('adminMainPage.validation.snilsIsRequired') },
                                {
                                    pattern: SNILS_PATTERN,
                                    message: t('adminMainPage.validation.snilsWrongFormat'),
                                },
                            ]}
                            label={t('CreatePatientPage.labels.snils')}
                        >
                            <SnilsInput
                                value={form4.getFieldValue('snils')}
                                onChange={e => form4.setFieldsValue({ snils: e.target.value })}
                                disabled={authUser?.esia}
                            />
                        </Form.Item>

                        <Row gutter={24}>
                            <Col sm={12} xs={24}>
                                <Button onClick={() => goToStep(patientIsChild ? 3 : 2)} className="w-100 mt-4">
                                    {t('CreatePatientPage.labels.back')}
                                </Button>
                            </Col>
                            <Col sm={12} xs={24}>
                                <Button type="primary" htmlType="submit" className="w-100 mt-4" disabled={form4SubmitDisabled || disableDuplication}>
                                    {t('CreatePatientPage.labels.createCard')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
            <div className={classNames(styles.cardWrapper, step === 3 && patientIsChild ? styles.visible : styles.hidden)}>
                <Card
                    title={
                        <Space className={styles.header}>
                            <h4>{t('CreatePatientPage.title')}</h4>
                            <span className={styles.stepNumber}>
                                {t('CreatePatientPage.step')} {step} {t('CreatePatientPage.from')} {patientIsChild ? 4 : 3}
                            </span>
                        </Space>
                    }
                    className={styles.step}
                >
                    <p className={styles.description}>{t('CreatePatientPage.step3.description')}</p>
                    <Form
                        onChange={validate}
                        form={form3}
                        name="relatives"
                        wrapperCol={{ span: 24 }}
                        initialValues={{ relativity: null, addRelationship: '', passportOfRepresentativeFile: null }}
                        autoComplete="off"
                        layout="vertical"
                        requiredMark={false}
                        onFinish={() => goToStep(4)}
                    >
                        <Form.Item
                            name="relativity"
                            className={classNames('w-100', additionalField && 'mb-2')}
                            rules={[{ required: true, message: t('CreatePatientPage.validation.relativityIsRequired') }]}
                            label={t('CreatePatientPage.labels.relativity')}
                        >
                            <Select
                                autoFocus
                                className="w-100"
                                placeholder={t('CreatePatientPage.labels.choose')}
                                onChange={relativityHandler}
                                options={relativityOptions.map((x) => ({ value: x, label: t('dictionaries.relativity.' + x) }))}
                            />
                        </Form.Item>
                        {additionalField && (
                            <Form.Item
                                name="addRelationship"
                                className="w-100"
                                rules={[{ required: true, message: t('CreatePatientPage.validation.addRelationshipIsRequired') }]}
                            >
                                <Input placeholder={t('CreatePatientPage.labels.addRelationship')} />
                            </Form.Item>
                        )}
                        <Form.Item
                            name="passportOfRepresentativeFile"
                            className="w-100 mb-3"
                            label={t('CreatePatientPage.labels.passportOfRepresentativeFile')}
                            rules={[{ required: true, message: t('CreatePatientPage.validation.passportOfRepresentativeFileIsRequired') }]}
                        >
                            <UploadFile
                                docType={UserDocumentDTOType.TRUSTEE_PASSPORT}
                                title={t('CreatePatientPage.labels.passportPages')}
                                subtitle={t('CreatePatientPage.labels.uploadFileFormat')}
                                changeEvent={(files) => changeFileEvent(form3, 'passportOfRepresentativeFile', files as string[])}
                                maxSizeMb={5}
                            />
                        </Form.Item>

                        {parentsApproval && (
                            <>
                                <p className={classNames(styles.description, 'mb-1')}>{t('CreatePatientPage.step3.description2')}</p>
                                <p className={classNames(styles.description, styles.smaller, 'mb-3')}>
                                    <a href="./files/parental_consent.rtf" target="_blank">
                                        {t('CreatePatientPage.step3.link')}
                                    </a>
                                </p>
                                <Form.Item
                                    name="parentsApprovalFile"
                                    className="w-100 mb-4"
                                    rules={[{ required: true, message: t('CreatePatientPage.validation.parentsApprovalFileIsRequired') }]}
                                >
                                    <UploadFile
                                        docType={UserDocumentDTOType.PARENTAL_CONSENT}
                                        title={t('CreatePatientPage.labels.parentsApproval')}
                                        subtitle={t('CreatePatientPage.labels.uploadFileFormat')}
                                        changeEvent={(files) => changeFileEvent(form3, 'parentsApprovalFile', files as string[])}
                                        maxSizeMb={5}
                                    />
                                </Form.Item>
                            </>
                        )}
                        <Row gutter={24}>
                            <Col sm={12} xs={24}>
                                <Button onClick={() => goToStep(2, true)} className="w-100 mt-4">
                                    {t('CreatePatientPage.labels.back')}
                                </Button>
                            </Col>
                            <Col sm={12} xs={24}>
                                <Button type="primary" htmlType="submit" className="w-100 mt-4" disabled={form3SubmitDisabled}>
                                    {t('CreatePatientPage.labels.forward')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </div>
    );
};
