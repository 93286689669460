import {createListenerMiddleware} from "@reduxjs/toolkit";
import {
    getRequisite,
    setSaveDcouments,
    setRequisite,
    updateRequisite,
    getDocuments,
    setDocuments,
    setInitSettings,
    updateConsultationPrices,
    getConsultationPrices,
    setConsultationPrices,
    updateCoursePrices,
    getCoursePrices,
    setCoursePrices,
    updateStyles,
    getStyles,
    setLogo,
    updateLogo,
    getLogo,
    setTheme,
    getPaymentServiceActive,
    setPaymentServiceActive,
    setProcessedStep,
    setDeliveryAddressActive,
    getSignatureFilesActive,
    setSignatureFilesActive,
    getDeliveryAddressActive
} from "./slice";
import {
    getCarePlanProductPrices,
    getOrganization,
    getPractitionerProductPrices,
    getStyleSettings,
    getUrlLogotype,
    saveStyles,
    updateCarePlanProductPrices,
    updateOrganization,
    updatePractitionerProductPrices
} from "@api/mainServiceAPI";
import {ORGANIZATION_ID} from "@utils/constants";
import {
    FileSafeDTOBucketType,
    getDocumentLinksByOrganization,
    safeDocuments,
    saveLogotype
} from "@api/documentServiceAPI";
import {CustomThemeNames} from "src/models/custom-themes";
import {SettingsStepType} from "src/enums/admin-settings-type.enum";

export const organizationListenerMiddleware = createListenerMiddleware();

organizationListenerMiddleware.startListening({
    actionCreator: updateRequisite,
    effect: ({payload}, listenerApi) => {
        updateOrganization(ORGANIZATION_ID, payload, {}).then((res) => {
            if (res.data) {
                listenerApi.dispatch(setRequisite(res.data));
                listenerApi.dispatch(setProcessedStep(SettingsStepType.REQUISITE));
            }
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: getRequisite,
    effect: ({payload}, listenerApi) => {
        getOrganization(ORGANIZATION_ID).then((res) => {
            if (res.data) {
                listenerApi.dispatch(setRequisite(res.data));
                listenerApi.dispatch(setProcessedStep(SettingsStepType.REQUISITE));
            }
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: getPaymentServiceActive,
    effect: ({payload}, listenerApi) => {
        getOrganization(ORGANIZATION_ID).then((res) => {
            listenerApi.dispatch(setPaymentServiceActive(res.data && res.data.paymentServiceActive!));
        }).catch(() => {
            setPaymentServiceActive(true);
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: getDeliveryAddressActive,
    effect: ({payload}, listenerApi) => {
        getOrganization(ORGANIZATION_ID).then((res) => {
            listenerApi.dispatch(setDeliveryAddressActive(res.data && res.data.deliveryAddressActive!));
        }).catch(() => {
            setDeliveryAddressActive(false);
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: getSignatureFilesActive,
    effect: ({payload}, listenerApi) => {
        getOrganization(ORGANIZATION_ID).then((res) => {
            listenerApi.dispatch(setSignatureFilesActive(res.data && res.data.signatureFilesActive!));
        }).catch(() => {
            setSignatureFilesActive(true);
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: setSaveDcouments,
    effect: ({payload}, listenerApi) => {
        safeDocuments({
            bucketType: FileSafeDTOBucketType.OPEN_DOCUMENT,
            fileIds: payload,
            organizationId: ORGANIZATION_ID
        }).then(() => {
            listenerApi.dispatch(getDocuments());
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: getDocuments,
    effect: ({payload}, listenerApi) => {
        getDocumentLinksByOrganization().then((res) => {
            if (res.data && res.data.length > 0) {
                listenerApi.dispatch(setDocuments(res.data));
                listenerApi.dispatch(setProcessedStep(SettingsStepType.DOCUMENTS));
            }
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: updateConsultationPrices,
    effect: ({payload}, listenerApi) => {
        updatePractitionerProductPrices({items: payload}).then(() => {
            listenerApi.dispatch(getConsultationPrices());
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: getConsultationPrices,
    effect: ({payload}, listenerApi) => {
        getPractitionerProductPrices().then((res) => {
            if (res.data) {
                listenerApi.dispatch(setConsultationPrices(res.data));
                listenerApi.dispatch(setProcessedStep(SettingsStepType.COST_CONSULTATIONS));
            }
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: updateCoursePrices,
    effect: ({payload}, listenerApi) => {
        updateCarePlanProductPrices({items: payload}).then(() => {
            listenerApi.dispatch(getCoursePrices());
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: getCoursePrices,
    effect: ({payload}, listenerApi) => {
        getCarePlanProductPrices().then((res) => {
            if (res.data) {
                listenerApi.dispatch(setCoursePrices(res.data));
                listenerApi.dispatch(setProcessedStep(SettingsStepType.COST_COURSES));
            }
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: updateStyles,
    effect: ({payload}, listenerApi) => {
        saveStyles(payload);
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: getStyles,
    effect: ({payload}, listenerApi) => {
        getStyleSettings().then((res) => {
            if (res.data && res.data['theme']) {
                const themeFromData = res.data['theme'].toString();
                const customTheme: CustomThemeNames = themeFromData as CustomThemeNames;
                listenerApi.dispatch(setTheme(customTheme));
                listenerApi.dispatch(setProcessedStep(SettingsStepType.PREFERENCES));
            }
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: updateLogo,
    effect: ({payload}, listenerApi) => {
        saveLogotype({
            bucketType: FileSafeDTOBucketType.LOGOTYPE,
            fileIds: [payload],
            organizationId: ORGANIZATION_ID
        }).then(() => {
            listenerApi.dispatch(getLogo());
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: getLogo,
    effect: ({payload}, listenerApi) => {
        getUrlLogotype().then((res) => {
            if (res.data) {
                listenerApi.dispatch(setLogo(res.data));
            }
        });
    },
});

organizationListenerMiddleware.startListening({
    actionCreator: setInitSettings,
    effect: ({payload}, listenerApi) => {
        listenerApi.dispatch(getRequisite());
        listenerApi.dispatch(getDocuments());
        listenerApi.dispatch(getConsultationPrices());
        listenerApi.dispatch(getCoursePrices());
        listenerApi.dispatch(getStyles());
        listenerApi.dispatch(getLogo());
    },
});