import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const useConfirmExit = (confirmExit: () => void, res: { result: boolean }, isActive: boolean) => {
    const { navigator } = useContext(NavigationContext);
    const [navArgs, setNavArgs] = useState<Parameters<typeof navigator.push>>();
    const pushRef = useRef(navigator.push);

    const navigatorHandler = (...args: Parameters<typeof navigator.push>) => {
        confirmExit();
        setNavArgs(args);
    };

    useEffect(() => {
        pushRef.current = navigator.push;

        navigator.push = navigatorHandler;
        if (res.result !== false && navArgs) {
            pushRef.current(...navArgs);
        }

        return () => {
            navigator.push = pushRef.current;
        };
    }, [confirmExit, res, navigator]);
};

export const usePrompt = (message: string, isActive: boolean) => {
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [result, setResult] = useState<{ result: boolean }>({ result: false });

    const handleOk = () => {
        setShowWarningModal(false);
        setResult({ result: true });
    };

    const handleCancel = () => {
        setShowWarningModal(false);
        setResult({ result: false });
    };

    useEffect(() => {
        const unloadHandler = (evt: BeforeUnloadEvent) => {
            if (isActive) {
                evt.preventDefault();
                evt.returnValue = message;
            }
        };
        window.addEventListener('beforeunload', unloadHandler);

        return () => {
            window.removeEventListener('beforeunload', unloadHandler);
        };
    }, [message, isActive]);

    const confirmExit = useCallback(() => {
        if (isActive) {
            setShowWarningModal(true);
        } else {
            setResult({ result: true });
        }
    }, [message, isActive]);
    useConfirmExit(confirmExit, result, isActive);
    return { showWarningModal, handleOk, handleCancel };
};
