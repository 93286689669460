import Icon from '@ant-design/icons';
import { uploadFile, UserDocumentDTOType } from '@api/documentServiceAPI';
import { CloseIcon, Preloader, SuccessFile } from '@icons';
import { FilesUploadModal } from '@modals';
import { selectCurrentProfile } from '@sliceUser';
import { Logger } from '@utils/logger';
import { separateFileNameAndExtension } from '@utils/utils';
import { Button, Checkbox, Col, Input, Row, Space } from 'antd';
import { RcFile } from 'antd/es/upload';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UploadFileExt } from 'src/modals/FilesUploadModal/FilesUploadModal';
import { IStepProps } from '../../ConsultationSignUpPage';
import styles from './ReasonStep.module.scss';

const { TextArea } = Input;

interface IContractStep {
    onChangeStep: (step: number) => void;
    isContractSigned: boolean;
}

export const ReasonStep = ({ onChangeStep, setData, data, isContractSigned }: IStepProps & IContractStep) => {
    const { t } = useTranslation();
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
    const [filesUpload, setFilesUpload] = useState(false);
    const profile = useSelector(selectCurrentProfile);
    const [reasonData, setReasonData] = useState<{
        reasons: boolean[] | undefined;
        filesToUpload: UploadFileExt[] | undefined;
        customReason: string | undefined;
    }>();

    useEffect(() => {
        setReasonData({ reasons: data?.reasons, filesToUpload: data?.filesToUpload, customReason: data?.customReason });
    }, [data]);

    useEffect(() => {
        setDisableSubmit(
            !(reasonData?.reasons?.includes(true) || !!reasonData?.customReason || reasonData?.filesToUpload?.length) || isContractSigned,
        );
        if (reasonData?.filesToUpload?.length) {
            reasonData.filesToUpload.forEach((f, i) => {
                if (f.done || f.loading) {
                    return;
                }
                f.loading = true;

                uploadFile(
                    {
                        fhirId: profile?.fhirId,
                        type: f.docType,
                        file: f as RcFile,
                        typeDescription: f.otherType,
                        ...separateFileNameAndExtension(f.name),
                    },
                    { headers: { 'Content-Type': 'multipart/form-data' } },
                )
                    .then((res: any) => {
                        f.done = true;
                        f.loading = false;
                        f.uuid = res.data.uuid;
                    })
                    .catch((err) => {
                        Logger.error(err);
                    });
            });
        }
    }, [reasonData]);

    const changeReason = (val: number) => {
        const reasonsNew = [...(reasonData?.reasons || [])];
        reasonsNew[val] = !reasonsNew[val];
        setData({ ...data, reasons: reasonsNew });
        setReasonData((data) => ({ reasons: reasonsNew, filesToUpload: data?.filesToUpload, customReason: data?.customReason }));
    };

    const changeCustomReason = (val: string) => {
        setData({ ...data, customReason: val });
        setReasonData((data) => ({ reasons: data?.reasons, filesToUpload: data?.filesToUpload, customReason: val }));
    };

    const removeFile = (val: number) => {
        const filesNew = [...(data?.filesToUpload || [])];
        filesNew.splice(val, 1);
        setData({ ...data, filesToUpload: filesNew });
        setReasonData((data) => ({ reasons: data?.reasons, filesToUpload: filesNew, customReason: data?.customReason }));
    };

    const changeFiles = (files: UploadFileExt[]) => {
        setData({ ...data, filesToUpload: files });
        setReasonData((data) => ({ reasons: data?.reasons, filesToUpload: files, customReason: data?.customReason }));
    };

    const submit = () => {
        setData({ ...data, reasons: reasonData?.reasons, filesToUpload: reasonData?.filesToUpload, customReason: reasonData?.customReason }, 3);
        onChangeStep(3);
    };

    return (
        <div className={styles.wrapper}>
            <h5 className={styles.title}>{t('consultation.ReasonStep.title')}</h5>
            <p className={styles.description}>{t('consultation.ReasonStep.description')}</p>
            <div className="mb-2">
                <Checkbox disabled={isContractSigned} checked={reasonData?.reasons?.[0]} onChange={() => changeReason(0)} className="m-0 me-3">
                    <span className={styles.checkbox}>{t('consultation.ReasonStep.reasons.pureSight')}</span>
                </Checkbox>
            </div>
            <div className="mb-2">
                <Checkbox disabled={isContractSigned} checked={reasonData?.reasons?.[1]} onChange={() => changeReason(1)} className="m-0 me-3">
                    <span className={styles.checkbox}>{t('consultation.ReasonStep.reasons.shortSight')}</span>
                </Checkbox>
            </div>
            <div className="mb-2">
                <Checkbox disabled={isContractSigned} checked={reasonData?.reasons?.[2]} onChange={() => changeReason(2)} className="m-0 me-3">
                    <span className={styles.checkbox}>{t('consultation.ReasonStep.reasons.longSight')}</span>
                </Checkbox>
            </div>
            <div className="mb-2">
                <Checkbox disabled={isContractSigned} checked={reasonData?.reasons?.[3]} onChange={() => changeReason(3)} className="m-0 me-3">
                    <span className={styles.checkbox}>{t('consultation.ReasonStep.reasons.exotropia')}</span>
                </Checkbox>
            </div>
            <div className="mb-4">
                <Checkbox disabled={isContractSigned} checked={reasonData?.reasons?.[4]} onChange={() => changeReason(4)} className="m-0 me-3">
                    <span className={styles.checkbox}>{t('consultation.ReasonStep.reasons.strabismus')}</span>
                </Checkbox>
            </div>
            <div className="mb-4">
                <TextArea
                    disabled={isContractSigned}
                    className={styles.textArea}
                    value={reasonData?.customReason}
                    placeholder={`${t('consultation.ReasonStep.reasons.describeYourProblem')}`}
                    onChange={(evt) => changeCustomReason(evt.target.value)}
                />
            </div>
            <p className={styles.description}>{t('consultation.ReasonStep.uploadYourDocuments')}</p>
            <div className="mb-4 mt-4 w-100">
                {reasonData?.filesToUpload?.map((file, i) => {
                    return (
                        <Row key={i} align="middle" className={classNames(styles.document, 'w-100')} wrap={false}>
                            <Col>
                                <SuccessFile />
                            </Col>
                            <Col className={styles.fileContent}>
                                <strong>
                                    {file.docType === UserDocumentDTOType.OTHER
                                        ? file.otherType
                                        : t("appointment.documentTypes." + (file.docType as UserDocumentDTOType))}
                                </strong>
                                <div>{file.name}</div>
                            </Col>
                            <Col>
                                {file.name ? (
                                    <Button disabled={isContractSigned} className={styles.closeBtn} type="text" onClick={() => removeFile(i)}>
                                        <CloseIcon />
                                    </Button>
                                ) : (
                                    <Icon className={styles.spinner} component={Preloader} spin />
                                )}
                            </Col>
                        </Row>
                    );
                })}
            </div>
            <Space size={24}>
                <Button disabled={isContractSigned} type="default" onClick={() => setFilesUpload(true)}>
                    {t('consultation.ReasonStep.uploadDocuments')}
                </Button>
                <span className={styles.fileFormats}>{t('consultation.ReasonStep.fileFormats')}</span>
            </Space>

            <div className={styles.divider} />
            <Button disabled={disableSubmit} onClick={submit} type="primary" size="large" className="mb-4">
                {t('consultation.ReasonStep.done')}
            </Button>

            <FilesUploadModal
                onCancel={() => setFilesUpload(false)}
                onSuccess={(files) => {
                    changeFiles(files);
                    setFilesUpload(false);
                }}
                files={reasonData?.filesToUpload || []}
                showModal={filesUpload}
            />
        </div>
    );
};
