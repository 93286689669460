import { FileSafeDTOBucketType, getContent, getPresignedURLWithPreNaming } from '@api/documentServiceAPI';
import { CloseBigBtn } from '@icons';
import { selectFileViewer } from '@sliceCore';
import { convertBlobToBase64 } from '@utils/base64';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './FileViewer.module.scss';

// do it better, add preview modal, etc - if necessary
export const FileViewer = () => {
    const currentFile = useSelector(selectFileViewer);

    const [displayPreview, setDisplayPreview] = useState<boolean>(false);
    const [fileUrl, setFileUrl] = useState<string>();
    const [contentType, setContentType] = useState<string>();

    useEffect(() => {
        if (currentFile) {
            if (currentFile.bucketType === 'VIDEO') {
                getPresignedURLWithPreNaming(currentFile.bucketType!, currentFile.s3Key, currentFile.fileName, currentFile.extension!).then((res) => {
                    const link = document.createElement('a');
                    link.href = res.data;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            } else {
                getContent(currentFile.bucketType as FileSafeDTOBucketType, currentFile.s3Key).then((res) => {
                    const contentType = getContentType(currentFile.fileName, currentFile.extension);

                    if (contentType && !currentFile.forceDownload) {
                        setContentType(contentType);
                        convertBlobToBase64(res.data).then((r) => {
                            setFileUrl(r);
                            setDisplayPreview(true);
                        });
                    } else {
                        const base64Url = window.URL.createObjectURL(res.data);
                        const link = document.createElement('a');
                        const blobURL = base64Url;
                        link.href = blobURL;
                        const fileName = currentFile.fileName;
                        link.download = fileName;
                        link.click();
                        window.URL.revokeObjectURL(blobURL);
                    }
                });
            }
        }
    }, [currentFile]);

    const getContentType = (fileName: string, extension?: string): string | undefined => {
        const ext = extension || fileName.split('.').pop();
        return (
            ext &&
            {
                png: 'image/png',
                pdf: 'application/pdf',
                jpeg: 'image/jpeg',
                jpg: 'image/jpeg',
                gif: 'image/gif',
            }[ext]
        );
    };

    return (
        <>
            {displayPreview && (
                <div className={styles.previewMask} onClick={() => setDisplayPreview(false)}>
                    {contentType === 'application/pdf' ? (
                        <iframe src={`data:${contentType};base64,${fileUrl}`} />
                    ) : (
                        <img alt={currentFile?.bucketType} src={`data:${contentType};base64,${fileUrl}`} />
                    )}
                    <CloseBigBtn className={styles.closePreviewBtn} />
                </div>
            )}
        </>
    );
};
