/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint no-use-before-define: 2 */

import { base64ToBlob } from './base64';

const CADESCOM_CADES_BES = 1;
const CAPICOM_CURRENT_USER_STORE = 2;
const CAPICOM_MY_STORE = 'My';
const CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
const CADESCOM_BASE64_TO_BINARY = 1;

import { SignatureAboutRequestDTO } from '@api/documentServiceAPI';
import {t} from "i18next";

declare let cadesplugin: any;

export const isEdge = navigator.userAgent.match(/Edge\/./i);

export interface CertificateItem {
    info: string;
    value: any;
    certificateData: SignatureAboutRequestDTO;
}

function print2Digit(digit: number) {
    return digit < 10 ? '0' + digit : digit;
}

function getCertDate(certDate: Date) {
    return `${print2Digit(certDate.getUTCDate())}.${print2Digit(certDate.getMonth() + 1)}.${certDate.getFullYear()} ${print2Digit(
        certDate.getUTCHours(),
    )}:${print2Digit(certDate.getUTCMinutes())}:${print2Digit(certDate.getUTCSeconds())}`;
}

function extractCertInfo(from: string, what: string) {
    let certName = '';
    const begin = from.indexOf(what);

    if (begin >= 0) {
        const end = from.indexOf(', ', begin);
        certName = end < 0 ? from.substr(begin) : from.substr(begin, end - begin);
    }

    return certName;
}

function getCertInfoString(certificateName: string, certFromDate: Date) {
    const released = t("signature_helper.released")
    return `${certificateName}; ${released} ${getCertDate(certFromDate)}`;
}

export function getCertificates(): Promise<CertificateItem[]> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            const oStore = await cadesplugin.CreateObjectAsync('CAdESCOM.Store');
            await oStore.Open();

            const allCertificatesPromise = await oStore.Certificates;
            const certificatesPromise = await allCertificatesPromise.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_TIME_VALID);
            const certCount = await certificatesPromise.Count;

            const certificates: CertificateItem[] = [];

            for (let i = 1; i <= certCount; i++) {
                const certificate = await certificatesPromise.Item(i);

                const validFrom = await certificate.ValidFromDate;
                const validFromDate = new Date(validFrom);
                const validTo = await certificate.ValidToDate;
                const serialNumber = await certificate.SerialNumber;
                const subjectName = await certificate.SubjectName;

                const certificateName = extractCertInfo(subjectName, 'CN=').substring(3);
                const certificateSurname = extractCertInfo(subjectName, 'SN=').substring(3);
                const certificateGivenName = extractCertInfo(subjectName, 'G=').substring(2);

                const certificateInfo = getCertInfoString(certificateName, validFromDate);

                certificates.push({
                    certificateData: {
                        validFrom: validFrom,
                        validTo: validTo,
                        // subjectName: certificateName,
                        subjectSurname: certificateSurname,
                        subjectGivenName: certificateGivenName,
                        serialNumber,
                    },
                    info: certificateInfo,
                    value: certificate,
                });
            }

            await oStore.Close();
            resolve(certificates);
        } catch (e) {
            reject();
        }
    });
}

const SignCreate = (oCertificate: any, dataInBase64: string) => {
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(
            function* (args: ((arg0: string) => any)[]): Generator<any, any, any> {
                const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
                yield oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE, CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

                const oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
                yield oSigner.propset_Certificate(oCertificate);
                yield oSigner.propset_CheckCertificate(true);

                const oSignedData = yield cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
                yield oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
                yield oSignedData.propset_Content(dataInBase64);

                let sSignedMessage = '';
                try {
                    sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES, true);
                } catch (err) {
                    const e = cadesplugin.getLastError(err);
                    console.error('Failed to create signature. Error: ' + e);
                    return args[1](e);
                }

                yield oStore.Close();
                return args[0](sSignedMessage);
            },
            resolve,
            reject,
        );
    });
};

function Verify(sSignedMessage: unknown, dataInBase64: string) {
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(
            function* (args: ((arg?: any) => any)[]): Generator<any, any, any> {
                const oSignedData = yield cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
                try {
                    yield oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
                    yield oSignedData.propset_Content(dataInBase64);
                    yield oSignedData.VerifyCades(sSignedMessage, CADESCOM_CADES_BES, true);
                } catch (err) {
                    const e = cadesplugin.getLastError(err);
                    console.error('Failed to verify signature. Error: ' + e);
                    return args[1](e);
                }
                return args[0]();
            },
            resolve,
            reject,
        );
    });
}

export const sign = (sCert: any, dataInBase64: string) => {
    return SignCreate(sCert, dataInBase64).then(
        (signedMessage) => {
            return Verify(signedMessage, dataInBase64).then(
                async function () {
                    console.log('Signature verified');
                    const sigFile = await base64ToBlob(signedMessage as string, 'application/octet-stream');
                    return sigFile;
                },
                function (err) {
                    console.error('>>> signature error', err);
                },
            );
        },
        function (err) {
            console.error('>>> signature error', err);
        },
    );
};

//TODO The code below was left here in case we need to create an attached signature. For the detached one see above: sign function etc
export function signFile(certificate: any, dataToSign: string, signType: number, documentName: string): Promise<string> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            cadesplugin.async_spawn(function* (): any {
                let signature;
                let oSigner;
                try {
                    oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
                } catch (error: any) {
                    throw 'Failed to create CAdESCOM.CPSigner: ' + error.number;
                }
                const oSigningTimeAttr = yield cadesplugin.CreateObjectAsync('CADESCOM.CPAttribute');

                yield oSigningTimeAttr.propset_Name(cadesplugin.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME);
                const oTimeNow = new Date();
                yield oSigningTimeAttr.propset_Value(oTimeNow);
                const attr = yield oSigner.AuthenticatedAttributes2;
                yield attr.Add(oSigningTimeAttr);

                const oDocumentNameAttr = yield cadesplugin.CreateObjectAsync('CADESCOM.CPAttribute');
                yield oDocumentNameAttr.propset_Name(cadesplugin.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME);
                yield oDocumentNameAttr.propset_Value(documentName);
                yield attr.Add(oDocumentNameAttr);

                if (oSigner) {
                    yield oSigner.propset_Certificate(certificate);
                } else {
                    throw 'Failed to create CAdESCOM.CPSigner';
                }

                const oSignedData = yield cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');

                if (dataToSign) {
                    yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
                    yield oSignedData.propset_Content(dataToSign);
                    yield oSigner.propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);

                    try {
                        signature = yield oSignedData.SignCades(oSigner, signType);
                    } catch (error) {
                        throw cadesplugin.getLastError(error);
                    }
                }

                resolve(signature);
            }); //cadesplugin.async_spawn
        } catch (error) {
            reject(error);
        }
    });
}

export async function subscribe(dataToSign: string, certificate: any) {
    const signType = cadesplugin.CADESCOM_CADES_BES; // was cadesplugin.CADESCOM_CADES_T
    const result = await signFile(certificate, dataToSign, signType, t("signature_helper.file_signature"));
    return result;
}
