export enum DiagnosisEnum {
    MYOPIA = 'MYOPIA',
    HYPERMETROPIA = 'HYPERMETROPIA',
    ASTIGMATISM = 'ASTIGMATISM',
    AMBLYOPIA = 'AMBLYOPIA',
    NYSTAGMUS = 'NYSTAGMUS',
    PATHOLOGY_OF_THE_LACRIMAL_DUCTS = 'PATHOLOGY_OF_THE_LACRIMAL_DUCTS',
    CATARACT = 'CATARACT',
    GLAUCOMA = 'GLAUCOMA',
    TAPETO_RETINAL_DYSTROPHY = 'TAPETO_RETINAL_DYSTROPHY',
    OPTIC_NEURITIS = 'OPTIC_NEURITIS',
    OPTIC_NERVE_ATROPHY = 'OPTIC_NERVE_ATROPHY',
    CHORIORETINITIS = 'CHORIORETINITIS',
    CONJUNCTIVITIS = 'CONJUNCTIVITIS',
    KERATITIS = 'KERATITIS',
    HEMOPHTHALMOS = 'HEMOPHTHALMOS',
    HALAZION = 'HALAZION',
    BARLEY = 'BARLEY',
    FURUNCLE = 'FURUNCLE',
    ABSCESS = 'ABSCESS',
    PHLEGMON = 'PHLEGMON',
    BLEPHARITIS = 'BLEPHARITIS',
    BLUNT_TRAUMA = 'BLUNT_TRAUMA',
    STRABISMUS = 'STRABISMUS',
    OTHER = 'OTHER'
}

export enum DiagnosisPropsEnum {
    ISOMETROPIC = 'ISOMETROPIC',
    EARLY_ACQUIRED = 'EARLY_ACQUIRED',
    STABLE = 'STABLE',
    SLOWLY_PROGRESSIVE = 'SLOWLY_PROGRESSIVE',
    RAPIDLY_PROGRESSING = 'RAPIDLY_PROGRESSING',
    UNCOMPLICATED = 'UNCOMPLICATED',
    COMPLICATED = 'COMPLICATED',
    LOW_DEGREE = 'LOW_DEGREE',
    MEDIUM_DEGREE = 'MEDIUM_DEGREE',
    HIGH_DEGREE = 'HIGH_DEGREE',
    VERY_HIGH_DEGREE = 'VERY_HIGH_DEGREE',
    SIMPLE_MYOPIC = 'SIMPLE_MYOPIC',
    COMPLEX_MYOPIC = 'COMPLEX_MYOPIC',
    SIMPLE_HYPEROPIC = 'SIMPLE_HYPEROPIC',
    COMPLEX_HYPEROPIC = 'COMPLEX_HYPEROPIC',
    MIXED = 'MIXED',
    DIRECT_TYPE = 'DIRECT_TYPE',
    REVERSE_TYPE = 'REVERSE_TYPE',
    WITH_OBLIQUE_AXLES = 'WITH_OBLIQUE_AXLES',
    REFRACTIVE = 'REFRACTIVE',
    MERIDIONAL = 'MERIDIONAL',
    OBSCURATIVE = 'OBSCURATIVE',
    DYSBINOCULAR = 'DYSBINOCULAR',
    CONGENITAL = 'CONGENITAL',
    ACQUIRED = 'ACQUIRED',
    HORIZONTAL = 'HORIZONTAL',
    VERTICAL = 'VERTICAL',
    ROTARY = 'ROTARY',
    JERKY = 'JERKY',
    PENDULUM = 'PENDULUM',
    OBSTRUCTION_OF_THE_LACRIMAL_CANAL = 'OBSTRUCTION_OF_THE_LACRIMAL_CANAL',
    LACRIMAL_DUCT_OBSTRUCTION_WITH_LACRIMAL_FISTULA = 'LACRIMAL_DUCT_OBSTRUCTION_WITH_LACRIMAL_FISTULA',
    INITIAL = 'INITIAL',
    MATURE = 'MATURE',
    SENILE = 'SENILE',
    TRAUMATIC = 'TRAUMATIC',
    JUVENILE = 'JUVENILE',
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    CENTRAL = 'CENTRAL',
    PERIPHERAL = 'PERIPHERAL',
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
    COMPLETE = 'COMPLETE',
    PARTIAL = 'PARTIAL',
    INFLAMMATORY = 'INFLAMMATORY',
    DYSTROPHIC = 'DYSTROPHIC',
    VIRAL = 'VIRAL',
    ALLERGIC = 'ALLERGIC',
    BACTERIAL = 'BACTERIAL',
    ADENOVIRUS = 'ADENOVIRUS',
    HERPETIC = 'HERPETIC',
    RAY = 'RAY',
    CORNEAL_THORN = 'CORNEAL_THORN',
    YES = 'YES',
    NO = 'NO',
    MEIBOMIAN = 'MEIBOMIAN',
    DEMODECTIC = 'DEMODECTIC',
    ULCERATIVE = 'ULCERATIVE',
    SIMPLE = 'SIMPLE',
    SCALY = 'SCALY',
    FRIENDLY = 'FRIENDLY',
    UNFRIENDLY = 'UNFRIENDLY',
    CONVERGING = 'CONVERGING',
    DIVERGENT = 'DIVERGENT',
    ALTERNATING = 'ALTERNATING',
    MONOLATERAL_OS = 'MONOLATERAL_OS',
    MONOLATERAL_OD = 'MONOLATERAL_OD',
    NON_ACCOMMODATIVE = 'NON_ACCOMMODATIVE',
    PARTIALLY_ACCOMMODATIVE = 'PARTIALLY_ACCOMMODATIVE',
    ACCOMMODATIVE = 'ACCOMMODATIVE',
    PERMANENT = 'PERMANENT',
    NON_PERMANENT = 'NON_PERMANENT',
    OPERATED = 'OPERATED',
    WITH_VERTICAL_COMPONENT = 'WITH_VERTICAL_COMPONENT',
}

export const DiagnosisOptions: { [key in DiagnosisEnum]: DiagnosisPropsEnum[][] } = {
    MYOPIA: [
        [DiagnosisPropsEnum.ISOMETROPIC],
        [DiagnosisPropsEnum.CONGENITAL, DiagnosisPropsEnum.ACQUIRED, DiagnosisPropsEnum.EARLY_ACQUIRED],
        [DiagnosisPropsEnum.STABLE, DiagnosisPropsEnum.SLOWLY_PROGRESSIVE, DiagnosisPropsEnum.RAPIDLY_PROGRESSING],
        [DiagnosisPropsEnum.UNCOMPLICATED, DiagnosisPropsEnum.COMPLICATED],
        [DiagnosisPropsEnum.LOW_DEGREE, DiagnosisPropsEnum.MEDIUM_DEGREE, DiagnosisPropsEnum.HIGH_DEGREE, DiagnosisPropsEnum.VERY_HIGH_DEGREE],
    ],
    HYPERMETROPIA: [[DiagnosisPropsEnum.LOW_DEGREE, DiagnosisPropsEnum.MEDIUM_DEGREE, DiagnosisPropsEnum.HIGH_DEGREE]],
    ASTIGMATISM: [
        [
            DiagnosisPropsEnum.SIMPLE_MYOPIC,
            DiagnosisPropsEnum.COMPLEX_MYOPIC,
            DiagnosisPropsEnum.SIMPLE_HYPEROPIC,
            DiagnosisPropsEnum.COMPLEX_HYPEROPIC,
            DiagnosisPropsEnum.MIXED,
        ],
        [DiagnosisPropsEnum.DIRECT_TYPE, DiagnosisPropsEnum.REVERSE_TYPE, DiagnosisPropsEnum.WITH_OBLIQUE_AXLES],
    ],
    AMBLYOPIA: [
        [DiagnosisPropsEnum.REFRACTIVE, DiagnosisPropsEnum.MERIDIONAL, DiagnosisPropsEnum.OBSCURATIVE, DiagnosisPropsEnum.DYSBINOCULAR],
        [DiagnosisPropsEnum.LOW_DEGREE, DiagnosisPropsEnum.MEDIUM_DEGREE, DiagnosisPropsEnum.HIGH_DEGREE, DiagnosisPropsEnum.VERY_HIGH_DEGREE],
    ],
    NYSTAGMUS: [
        [DiagnosisPropsEnum.CONGENITAL, DiagnosisPropsEnum.ACQUIRED],
        [DiagnosisPropsEnum.HORIZONTAL, DiagnosisPropsEnum.VERTICAL, DiagnosisPropsEnum.ROTARY],
        [DiagnosisPropsEnum.JERKY, DiagnosisPropsEnum.PENDULUM, DiagnosisPropsEnum.MIXED],
    ],
    PATHOLOGY_OF_THE_LACRIMAL_DUCTS: [
        [DiagnosisPropsEnum.OBSTRUCTION_OF_THE_LACRIMAL_CANAL, DiagnosisPropsEnum.LACRIMAL_DUCT_OBSTRUCTION_WITH_LACRIMAL_FISTULA],
    ],
    CATARACT: [
        [
            DiagnosisPropsEnum.CONGENITAL,
            DiagnosisPropsEnum.INITIAL,
            DiagnosisPropsEnum.MATURE,
            DiagnosisPropsEnum.SENILE,
            DiagnosisPropsEnum.TRAUMATIC,
        ],
    ],
    GLAUCOMA: [[DiagnosisPropsEnum.CONGENITAL, DiagnosisPropsEnum.JUVENILE, DiagnosisPropsEnum.PRIMARY, DiagnosisPropsEnum.SECONDARY]],
    TAPETO_RETINAL_DYSTROPHY: [[DiagnosisPropsEnum.CENTRAL, DiagnosisPropsEnum.PERIPHERAL]],
    OPTIC_NEURITIS: [[DiagnosisPropsEnum.ASCENDING, DiagnosisPropsEnum.DESCENDING]],
    OPTIC_NERVE_ATROPHY: [[DiagnosisPropsEnum.COMPLETE, DiagnosisPropsEnum.PARTIAL]],
    CHORIORETINITIS: [[DiagnosisPropsEnum.INFLAMMATORY, DiagnosisPropsEnum.DYSTROPHIC, DiagnosisPropsEnum.TRAUMATIC]],
    CONJUNCTIVITIS: [[DiagnosisPropsEnum.VIRAL, DiagnosisPropsEnum.ALLERGIC, DiagnosisPropsEnum.BACTERIAL, DiagnosisPropsEnum.MIXED]],
    KERATITIS: [
        [
            DiagnosisPropsEnum.ADENOVIRUS,
            DiagnosisPropsEnum.HERPETIC,
            DiagnosisPropsEnum.TRAUMATIC,
            DiagnosisPropsEnum.RAY,
            DiagnosisPropsEnum.CORNEAL_THORN,
        ],
    ],
    HEMOPHTHALMOS: [[DiagnosisPropsEnum.YES]],
    HALAZION: [[DiagnosisPropsEnum.YES]],
    BARLEY: [[DiagnosisPropsEnum.YES]],
    FURUNCLE: [[DiagnosisPropsEnum.YES]],
    ABSCESS: [[DiagnosisPropsEnum.YES]],
    PHLEGMON: [[DiagnosisPropsEnum.YES]],
    BLEPHARITIS: [
        [
            DiagnosisPropsEnum.MEIBOMIAN,
            DiagnosisPropsEnum.DEMODECTIC,
            DiagnosisPropsEnum.ULCERATIVE,
            DiagnosisPropsEnum.SIMPLE,
            DiagnosisPropsEnum.SCALY,
        ],
    ],
    BLUNT_TRAUMA: [[DiagnosisPropsEnum.YES]],
    STRABISMUS: [
        [DiagnosisPropsEnum.FRIENDLY, DiagnosisPropsEnum.UNFRIENDLY],
        [DiagnosisPropsEnum.CONVERGING, DiagnosisPropsEnum.DIVERGENT, DiagnosisPropsEnum.VERTICAL],
        [DiagnosisPropsEnum.ALTERNATING, DiagnosisPropsEnum.MONOLATERAL_OS, DiagnosisPropsEnum.MONOLATERAL_OD],
        [DiagnosisPropsEnum.NON_ACCOMMODATIVE, DiagnosisPropsEnum.PARTIALLY_ACCOMMODATIVE, DiagnosisPropsEnum.ACCOMMODATIVE],
        [DiagnosisPropsEnum.PERMANENT, DiagnosisPropsEnum.NON_PERMANENT],
        [DiagnosisPropsEnum.OPERATED],
        [DiagnosisPropsEnum.WITH_VERTICAL_COMPONENT],
    ],
    OTHER: []
};
