import { ProcedureTypeEnum } from '@enums';
import { ProcedureParamType, ProcedureSettingsValueType } from '@models';
import i18n from '@utils/i18n';
import { Logger } from '@utils/logger';
import { PROCEDURE_SETTINGS_TYPES, PROCEDURE_SETTING_PROPS_DICTIONARY, VISUAL_ACUITY_RELATION } from '../PROCEDURES_CONFIG';

/**
 * Check that area with the target color was clicked
 * @param canvas Canvas
 * @param ctx Canvas context
 * @param event Mouse click event
 * @param targetColor Target color in RGB, example: 'rgb(0,0,0)'
 * @returns boolean
 */
export function targetColorClicked(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, event: MouseEvent, targetColor: string): boolean {
    const mousePos = getMousePositionInCanvas(canvas, event);

    // get pixel under cursor
    const pixel = ctx.getImageData(mousePos.x, mousePos.y, 1, 1).data;

    // create rgb color for that pixel
    const color = `rgb(${pixel[0]},${pixel[1]},${pixel[2]})`;

    return color === targetColor;
}

/**
 * Get mouse position in the canvas
 * @param canvas Canvas
 * @param event Mouse click event
 * @returns {x, y} position
 */
export function getMousePositionInCanvas(canvas: HTMLCanvasElement, event: MouseEvent): { x: number; y: number } {
    // canvas position
    const { x, y } = canvas.getBoundingClientRect();

    return {
        x: event.clientX - x,
        y: event.clientY - y,
    };
}

/**
 * Check that the mouse cursor in the target rect
 * @param canvas Canvas
 * @param event Mouse click event
 * @param rectX Rectangle position X
 * @param rectY Rectangle position Y
 * @param rectW Rectangle width
 * @param rectH Rectangle height
 * @returns boolean
 */
export function cursorInRect(canvas: HTMLCanvasElement, event: MouseEvent, rectX: number, rectY: number, rectW: number, rectH: number): boolean {
    const { x, y } = getMousePositionInCanvas(canvas, event);

    const inX = x > rectX && x < rectX + rectW;
    const inY = y > rectY && y < rectY + rectH;

    return inX && inY;
}

/**
 * Get random X,Y positions in the canvas
 * @param canvas Canvas
 * @param offset Padding of the clickable area
 * @returns { x,y } position
 */
export function getRandomPosition(canvas: HTMLCanvasElement, offset: number, step?: number): { x: number; y: number } {
    const x = getRandomNumberWithStep(0 + offset, canvas.width - offset, step || 1);
    const y = getRandomNumberWithStep(0 + offset, canvas.height - offset, step || 1);

    return { x, y };
}

/**
 * Get random int number from the range
 * @param min Start of the range
 * @param max End of the range
 * @returns number
 */
export function getRandomNumber(min: number, max: number) {
    return Math.round(Math.random() * (max - min) + min);
}

/**
 * Get random int number from the range with step
 * @param min Start of the range
 * @param max End of the range
 * @param step Some step
 * @returns number
 */
export function getRandomNumberWithStep(min: number, max: number, step: number) {
    const range = (max - min) / step;
    const rand = Math.floor(Math.random() * range);
    return min + rand * step;
}

/**
 * Draw a common chess board
 * @param canvas Canvas
 * @param ctx Canvas context
 * @param color1 First color
 * @param color2 Second color
 * @param cellSize Size of one square
 * @returns Number of rows and columns
 */
export function drawChessBoard(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    color1: string,
    color2: string,
    cellSize: number,
): { rows: number; columns: number } {
    let containerWidth = 0;
    let containerHeight = 0;
    let rows = 0;
    let columns = 0;

    for (let i = 0; containerWidth < canvas.width; i++) {
        for (let j = 0; containerHeight < canvas.height; j++) {
            drawChessBoardCell(ctx, i, j, cellSize, (i + j) % 2 == 0 ? color1 : color2);

            containerHeight = (j + 1) * cellSize;
            columns = j > columns ? j : columns;
        }

        containerWidth = (i + 1) * cellSize;
        containerHeight = 0;
        rows = i > rows ? i : rows;
    }

    return { rows, columns };
}

/**
 * Draw a chess board cell
 * @param ctx Canvas context
 * @param cellNumberX Cell number by X
 * @param cellNumberY Cell number by Y
 * @param cellSize Size of the cell
 * @param fillColor Fill color of the cell
 * @returns void
 */
export function drawChessBoardCell(
    ctx: CanvasRenderingContext2D,
    cellNumberX: number,
    cellNumberY: number,
    cellSize: number,
    fillColor: string,
): void {
    const xOffset = cellNumberX * cellSize;
    const yOffset = cellNumberY * cellSize;

    ctx.fillStyle = fillColor;
    ctx.fillRect(xOffset, yOffset, cellSize, cellSize);
}

/**
 * Draw a circle sector
 * @param ctx Canvas context
 * @param centerX Sector center by X
 * @param centerY Sector center by Y
 * @param radius Radius
 * @param startAngle Start angle (in radians)
 * @param endAngle End angle (in radians)
 * @param fillColor Fill color of the sector
 * @returns void
 */
export function drawCircleSector(
    ctx: CanvasRenderingContext2D,
    centerX: number,
    centerY: number,
    radius: number,
    startAngle: number,
    endAngle: number,
    fillColor: string,
): void {
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.closePath();
}

/**
 * Get value in mm which depends on visual acuity
 * @param visualAcuity number
 * @returns number
 */
export function getMmFromVisualAcuity(visualAcuity: number): number {
    const result = VISUAL_ACUITY_RELATION.get(visualAcuity);
    if (!result) {
        Logger.error('Wrong value of visual acuity: ' + visualAcuity);
    }

    return result || 0;
}

/**
 * Get value - how many pixels in 1 mm, depends on DPI
 * @param dpi number
 * @returns number
 */
export function getPxInMmFromDpi(dpi: number): number {
    return dpi / 25.4 / window.devicePixelRatio; // 1 inch = 25.4 mm
}

/**
 * Convert frequency (Hz) to interval (ms)
 * @param frequencyHz Frequency in Hz
 * @returns Interval in ms
 */
export function convertFrequencyToInterval(frequencyHz: number): number {
    return (1 / frequencyHz) * 1000;
}

/**
 * Convert VisualAcuity to the size in pixels
 * @param visualAcuity number
 * @param dpi number
 * @param distance number
 * @returns number
 */
export function convertVisualAcuityToPx(visualAcuity: number, dpi: number, distance = 1): number {
    const result = Math.floor(getMmFromVisualAcuity(visualAcuity) * getPxInMmFromDpi(dpi) * distance);
    return result;
}

/**
 * Get all default values for settings of the procedure
 * @param procedureType ProcedureTypeEnum
 * @returns Partial<{ [key in ProcedureParamType]: string | number }>
 */
export const getDefaultProcedureSettingsValues = (procedureType: ProcedureTypeEnum) => {
    const properties = PROCEDURE_SETTINGS_TYPES[procedureType];

    const formValues: Partial<{ [key in ProcedureParamType]: string | number }> = {};
    properties.forEach((x) => {
        formValues[x] = PROCEDURE_SETTING_PROPS_DICTIONARY(procedureType)[x].values[0];
    });

    return formValues;
};

/**
 * Convert all settings values to the readable string
 * @param values Partial<ProcedureSettingsValueType>
 * @returns Readable string
 */
export const getProcedureSettingsAsString = (values?: Partial<ProcedureSettingsValueType>, ignoredProps?: string[]) => {
    if (!values || (values as any) === '{}') {
        return '';
    }

    const stringParts = Object.entries(values)
        .filter(([key, value]) => value !== undefined && !ignoredProps?.includes(key))
        .map(([key, value]) => {
            const keyLabel = i18n.t(`procedureSettingsWizard.${key}.shortLabel`);
            const valueLabel = i18n.t(`procedureSettingsWizard.${key}.values.${value}`, { defaultValue: value });

            return `${keyLabel}: ${valueLabel}`;
        });

    return stringParts.join(', ');
};

export const toNumber = (input: string): number | string => {
    const parsedNumber = parseFloat(input);
    return isNaN(parsedNumber) ? input : parsedNumber;
};

export const formatSnils = (input: string): string => {
    if (!/^\d+$/.test(input)) {
        throw new Error("Input should contain only digits.");
    }

    return input.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3-$4');
}

function checkSnils(snils: string): boolean {
    const cleanedSnils = snils.replace(/[^\d]/g, "");
    
    if (cleanedSnils.length !== 11) { return false; }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cleanedSnils.charAt(i), 10) * (9 - i);
    }

    const expectedCheckDigit = parseInt(cleanedSnils.substring(9), 10);

    if (sum < 100 ? expectedCheckDigit === sum : expectedCheckDigit === sum % 101) {
        return true;
    } else {
        return expectedCheckDigit === 0;
    }
}