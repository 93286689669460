import { EncounterDetailDTOPaymentStatus, EncounterDetailDTOStatus } from '@api/mainServiceAPI';
import { AppointmentStatus } from '@enums';

export enum EncounterStatusFilter {
    all = 'all',
    created = 'created',
    pending = 'pending',
    confirmed = 'confirmed',
    completed = 'completed',
    cancelled = 'cancelled',
}

export const statusOptionsList = Object.keys(EncounterStatusFilter);

export enum EncounterEventStatusFilter {
    all = 'all',
    pending = 'pending',
    confirmed = 'confirmed',
    completed = 'completed',
    cancelled = 'cancelled',
}
export const eventStatusOptionsList = Object.keys(EncounterEventStatusFilter);

export const getEncounterStatus = (
    appointmentStatus?: EncounterDetailDTOStatus,
    paymentStatus?: EncounterDetailDTOPaymentStatus,
): AppointmentStatus => {
    switch (true) {
        case appointmentStatus === EncounterDetailDTOStatus.fulfilled && paymentStatus === EncounterDetailDTOPaymentStatus.completed:
            return AppointmentStatus.completed;

        case appointmentStatus === EncounterDetailDTOStatus.cancelled:
            return AppointmentStatus.cancelled;

        case paymentStatus === EncounterDetailDTOPaymentStatus.failed:
            return AppointmentStatus.paymentFailed;

        case appointmentStatus === EncounterDetailDTOStatus.pending && paymentStatus !== EncounterDetailDTOPaymentStatus.completed:
            return AppointmentStatus.created;

        case appointmentStatus === EncounterDetailDTOStatus.pending && paymentStatus === EncounterDetailDTOPaymentStatus.completed:
            return AppointmentStatus.pending;

        case (appointmentStatus === EncounterDetailDTOStatus.booked || appointmentStatus === EncounterDetailDTOStatus['checked-in']) &&
            paymentStatus === EncounterDetailDTOPaymentStatus.completed:
            return AppointmentStatus.confirmed;

        case (appointmentStatus === EncounterDetailDTOStatus.booked || appointmentStatus === EncounterDetailDTOStatus['checked-in']) &&
            paymentStatus === EncounterDetailDTOPaymentStatus.pending:
            return AppointmentStatus.created;

        case appointmentStatus === EncounterDetailDTOStatus.arrived:
            return AppointmentStatus.confirmed;

        case appointmentStatus === EncounterDetailDTOStatus.fulfilled:
            return AppointmentStatus.completed;

        default:
            return AppointmentStatus.none;
    }
};

export const getEncounterStatusesFromAppointmentStatus = (
    status: EncounterStatusFilter,
): { status?: EncounterDetailDTOStatus; paymentStatus?: EncounterDetailDTOPaymentStatus } => {
    switch (status) {
        case EncounterStatusFilter.created:
            return { status: EncounterDetailDTOStatus.pending, paymentStatus: EncounterDetailDTOPaymentStatus.pending };
        case EncounterStatusFilter.pending:
            return { status: EncounterDetailDTOStatus.pending, paymentStatus: EncounterDetailDTOPaymentStatus.completed };
        case EncounterStatusFilter.confirmed:
            return { status: EncounterDetailDTOStatus.booked, paymentStatus: EncounterDetailDTOPaymentStatus.completed };
        case EncounterStatusFilter.completed:
            return { status: EncounterDetailDTOStatus.fulfilled, paymentStatus: EncounterDetailDTOPaymentStatus.completed };
        case EncounterStatusFilter.cancelled:
            return { status: EncounterDetailDTOStatus.cancelled, paymentStatus: EncounterDetailDTOPaymentStatus.completed };
        default:
            return {};
    }
};
