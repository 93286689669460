import { ProcedureParamType, ProcedureSettingsType } from '@models';
import { ProcedureTypeEnum } from './enums/procedure-type.enum';

export const SUCCESS_TRIES_IN_30_SEC = 4;

export const PROCEDURES_COLORS = ['#ff0000', '#00ff00', '#0000ff'];
export const PROCEDURES_IMAGES = ['dino', 'sun', 'star'];
export const DISTANCE_LIST = [1, 2, 3, 4, 5];
export const DURATION_LIST_SECONDS = [30, 60, 90, 120, 150, 180, 360];
export const ANGLES_180_5 = Array.from({ length: 180 / 5 + 1 }, (_, i) => i * 5);
export const FREQUENCY_4HZ = Array.from({ length: 4 / 0.5 }, (_, i) => (i + 1) / 2);
export const VISUAL_ACUITY_LIST = [0.05, 0.1, 0.15, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.5, 2.0];
export const VISUAL_ACUITY_RELATION = new Map([
    [0.05, 28],
    [0.1, 14],
    [0.15, 9.3],
    [0.2, 7],
    [0.3, 4.67],
    [0.4, 3.5],
    [0.5, 2.8],
    [0.6, 2.3],
    [0.7, 2.0],
    [0.8, 1.75],
    [0.9, 1.55],
    [1.0, 1.4],
    [1.5, 1.17],
    [2.0, 0.7],
]);

export const OPTOTYPE_LETTERS = ['Б', 'И', 'К', 'М', 'Н', 'Ы', 'Ш'];
export const OPTOTYPE_LETTERS_LATIN = ['E', 'F', 'P', 'T', 'O', 'Z', 'L', 'P', 'D'];
export const OPTOTYPE_RINGS = ['left', 'right', 'top', 'bottom'];
export const OPTOTYPE_IMAGES = ['fish', 'butterfly', 'rabbit', 'plane'];

export type AVETISOV_TEXT_TYPE = 'arbuz' | 'tiranozavr';
export const AVETISOV_TEXTS_SET: AVETISOV_TEXT_TYPE[] = ['arbuz', 'tiranozavr'];

export const getProcedureTargetPoints = (duration: number, procedureType: ProcedureTypeEnum): number => {
    return procedureType === ProcedureTypeEnum.AMBLYOPIA || procedureType === ProcedureTypeEnum.RESTORATION_OF_FUSION
        ? 2
        : (duration / 30) * SUCCESS_TRIES_IN_30_SEC;
};

const getProcedureVisualAcuityDictionary = (procedureType: ProcedureTypeEnum) => {
    switch (procedureType) {
        case ProcedureTypeEnum.PLEOPTIC_CROSS:
            return [0.1, 0.2, 0.3, 0.4, 0.5, 0.6];
        case ProcedureTypeEnum.MERIDIONAL_PATTERN:
        case ProcedureTypeEnum.CHESS_PATTERN:
            return [0.1, 0.2, 0.3, 0.4, 0.5, 0.6];
        default:
            return VISUAL_ACUITY_LIST;
    }
};

const getProcedureFrequencyDictionary = (procedureType: ProcedureTypeEnum) => {
    switch (procedureType) {
        case ProcedureTypeEnum.MERIDIONAL_PATTERN:
        case ProcedureTypeEnum.CHESS_PATTERN:
        case ProcedureTypeEnum.FLASHES:
            return [1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3];
        default:
            return FREQUENCY_4HZ;
    }
};

const getProcedureAngleDictionary = (procedureType: ProcedureTypeEnum) => {
    switch (procedureType) {
        case ProcedureTypeEnum.MERIDIONAL_PATTERN:
        case ProcedureTypeEnum.CHESS_PATTERN:
            return [
                0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150,
                155, 160, 165, 170, 175,
            ];
        default:
            return ANGLES_180_5;
    }
};

const getProcedureDurationDictionary = (procedureType: ProcedureTypeEnum) => {
    switch (procedureType) {
        case ProcedureTypeEnum.MERIDIONAL_PATTERN:
        case ProcedureTypeEnum.CHESS_PATTERN:
        case ProcedureTypeEnum.PLEOPTIC_SPIRALS:
        case ProcedureTypeEnum.PLEOPTIC_CROSS:
        case ProcedureTypeEnum.MAKULOSTIMULATION:
        case ProcedureTypeEnum.FLASHES:
        case ProcedureTypeEnum.RECOVER_CILLIAR:
            return [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360];
        case ProcedureTypeEnum.AVETISOV_MATS:
            return [30, 60, 90, 120];
        case ProcedureTypeEnum.BIFIXATION_RECOVERY:
            return [180];
        case ProcedureTypeEnum.AMBLYOPIA:
            return [30, 60];
        case ProcedureTypeEnum.RESTORATION_OF_FUSION:
            return [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360];
        default:
            return DURATION_LIST_SECONDS;
    }
};

const getProcedureColorDictionary = (procedureType: ProcedureTypeEnum) => {
    switch (procedureType) {
        case ProcedureTypeEnum.MAKULOSTIMULATION:
            return ['#0000ff', '#ff0000'];
        case ProcedureTypeEnum.FLASHES:
            return ['#ffffff', '#00ff00', '#ff0000'];
        default:
            return PROCEDURES_COLORS;
    }
};

export const PROCEDURE_SETTING_PROPS_DICTIONARY = (procedureType: ProcedureTypeEnum): ProcedureSettingsType => {
    return {
        visualAcuity: {
            values: getProcedureVisualAcuityDictionary(procedureType),
            valuesLocalized: false,
        },
        frequency: {
            values: getProcedureFrequencyDictionary(procedureType),
            valuesLocalized: false,
        },
        duration: {
            values: getProcedureDurationDictionary(procedureType),
            valuesLocalized: true,
        },
        image: {
            values: PROCEDURES_IMAGES,
            valuesLocalized: true,
        },
        angle: {
            values: getProcedureAngleDictionary(procedureType),
            valuesLocalized: false,
        },
        color: {
            values: getProcedureColorDictionary(procedureType),
            valuesLocalized: true,
        },
        amblyopia: {
            values: ['yes', 'no'],
            valuesLocalized: true,
        },
        // internal
        occlusionEye: {
            values: ['od', 'os'],
            valuesLocalized: true,
        },
        fontSize: {
            values: [10, 11, 12],
            valuesLocalized: false,
        },
        textType: {
            values: ['arbuz', 'tiranozavr'] as AVETISOV_TEXT_TYPE[],
            valuesLocalized: true,
        },
        letter: {
            values: OPTOTYPE_LETTERS,
            valuesLocalized: false,
        },
        direction: {
            values: OPTOTYPE_RINGS,
            valuesLocalized: true,
        },
        optotypeImage: {
            values: OPTOTYPE_IMAGES,
            valuesLocalized: true,
        },
        accuracy: {
            values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            valuesLocalized: true,
        },
        lensFrom: {
            values: ['-2.0', '-1.0', '0', '+1.0', '+2.0', '+3.0', '+4.0'],
            valuesLocalized: false,
        },
        lensTo: {
            values: [ '-2.0', '-1.0', '0', '+1.0', '+2.0', '+3.0', '+4.0'],
            valuesLocalized: false,
        },
    };
};

export const PROCEDURE_SETTINGS_TYPES: { [key in ProcedureTypeEnum]: ProcedureParamType[] } = {
    MERIDIONAL_PATTERN: ['visualAcuity', 'frequency', 'duration', 'angle'],
    CHESS_PATTERN: ['visualAcuity', 'frequency', 'duration'],
    MAKULOSTIMULATION: ['duration', 'color'],
    FLASHES: ['duration', 'frequency', 'color'],
    PLEOPTIC_SPIRALS: ['duration'],
    PLEOPTIC_CROSS: ['visualAcuity', 'duration'],
    BIFIXATION_RECOVERY: ['occlusionEye', 'duration'],
    RECOVER_CILLIAR: ['duration'],
    DISSOCIATION: ['lensFrom', 'lensTo'],

    DIAGNOSTIC_LETTERS: ['visualAcuity', 'letter'],
    DIAGNOSTIC_RINGS: ['visualAcuity', 'direction'],
    DIAGNOSTIC_IMAGES: ['visualAcuity', 'optotypeImage'],
    AVETISOV_MATS: ['fontSize', 'textType', 'duration'],

    DIAGNOSTIC_BINOCULAR: [],
    DIAGNOSTIC_VISUAL_ACUITY: [],
    LINEAR_PATTERN: [],
    STEREO_VISION_DEVELOPMENT: [],

    AMBLYOPIA: ['duration', 'lensFrom', 'lensTo', 'accuracy'],
    RESTORATION_OF_FUSION: ['duration', 'amblyopia', 'accuracy'],
};

export const INTERACTIVE_PROCEDURES = [
    ProcedureTypeEnum.CHESS_PATTERN,
    ProcedureTypeEnum.MERIDIONAL_PATTERN,
    ProcedureTypeEnum.PLEOPTIC_CROSS,
    ProcedureTypeEnum.PLEOPTIC_SPIRALS,
    ProcedureTypeEnum.AMBLYOPIA,
    ProcedureTypeEnum.RESTORATION_OF_FUSION,
];
