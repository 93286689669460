import { setGlobalLoading } from '@sliceCore';
import { CompatClient, Stomp } from '@stomp/stompjs';
import { Logger } from '@utils/logger';
import { PropsWithChildren, createContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import SockJS from 'sockjs-client';

const RECONNECT_DELAY = 1000;
const RECONNECT_MAX_TRIES = 5;

interface IWebsocketContextProps {
    wsClient: CompatClient | null;
    wsReconnected: boolean;
    send: (path: string, replyTo: string, body?: unknown) => void;
    connect: (userFhirId: string) => void;
    disconnect: () => void;
}

export const WebsocketContext = createContext<IWebsocketContextProps>({
    wsClient: null,
    wsReconnected: false,
    send: () => ({}),
    connect: () => ({}),
    disconnect: () => ({}),
});

export const WebsocketProvider = ({ children }: PropsWithChildren) => {
    const [wsClient, setWsClient] = useState<CompatClient | null>(null);
    const [wsReconnected, setWsReconnected] = useState(false);
    const [currentConnectionUserId, setCurrentConnectionUserId] = useState<string | null>();

    const reconnectionAttempts = useRef(1);

    const dispatch = useDispatch();

    const connect = (profileOrUserFhirId: string) => {
        if (profileOrUserFhirId === currentConnectionUserId) {
            return;
        } else {
            if (wsClient) {
                disconnect();
            }
        }

        setCurrentConnectionUserId(profileOrUserFhirId);

        dispatch(setGlobalLoading(true));

        const socket = new SockJS(`${process.env.REACT_APP_WS_URL}?userId=${profileOrUserFhirId}`);
        const stompClient = Stomp.over(socket);
        stompClient.debug = () => ({});

        stompClient.connect({}, () => {
            console.log('WS Connected');
            setWsClient(stompClient);
            dispatch(setGlobalLoading(false));

            setWsReconnected(reconnectionAttempts.current > 1);
            reconnectionAttempts.current = 1;
        });

        stompClient.onWebSocketClose = () => {
            Logger.error('WS Closed');

            setWsClient(null);
            dispatch(setGlobalLoading(false));

            setTimeout(() => {
                const attempts = reconnectionAttempts.current + 1;
                reconnectionAttempts.current = attempts;

                Logger.error('WS Reconnection....', reconnectionAttempts);

                if (attempts <= RECONNECT_MAX_TRIES) {
                    connect(profileOrUserFhirId);
                }
            }, RECONNECT_DELAY);
        };
    };

    const disconnect = () => {
        if (wsClient) {
            wsClient.onWebSocketClose = () => {};
            wsClient.disconnect();
            setWsClient(null);
            setCurrentConnectionUserId(null);
            console.log('WS Disconnected finally');
        }
    };

    const send = (path: string, replyTo: string, body?: unknown) => {
        if (!wsClient) {
            Logger.error('WebSocket not initialized yet');
        }
        wsClient?.send(path, { replyTo, 'content-type': 'application/json; charset=UTF-8' }, JSON.stringify(body));
    };

    return (
        <WebsocketContext.Provider
            value={{
                wsClient,
                send,
                connect,
                disconnect,
                wsReconnected: wsReconnected,
            }}
        >
            {children}
        </WebsocketContext.Provider>
    );
};
