export const downloadBase64File = (base64Data: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = `data:application/octet-stream;base64,${base64Data}`;
    link.download = fileName;
    link.click();
};

export const base64ToBlob = (base64: string, mimeType: string): Blob => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
};
export const convertBlobToBase64 = async (blob: Blob): Promise<string> => {
    const reader = new FileReader();
    return new Promise<string>((resolve, reject) => {
        reader.onerror = () => {
            reader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };
        reader.onload = () => {
            const base64String = reader.result as string;
            const base64Data = base64String.split(',')[1];
            resolve(base64Data);
        };
        reader.readAsDataURL(blob);
    });
};
