import { Logger } from '@utils/logger';
import { cursorInRect, drawChessBoard, drawChessBoardCell, drawCircleSector, getRandomNumber } from './helpers';
import { IProcedureBase, ProcedureBase } from './procedureBase.abstract';
import {t} from "i18next";

export interface IPleopticCrossConfig {
    squareSize: number;
    accuracy: number; // in pixels. If 0 - cross and circle centers should be in the same pixel
}

export class PleopticCrossProcedure extends ProcedureBase implements IProcedureBase {
    // Procedure's global configuration
    private _squareColor1 = '#FF755D';
    private _squareColor2 = '#00DE23';

    private _config: IPleopticCrossConfig;
    private _firstRender = true;

    // board props
    private _xCellsCount = 0;
    private _yCellsCount = 0;

    // cross props
    private _firstCrossCellNumberX = 0;
    private _firstCrossCellNumberY = 0;
    private _crossCenterX = 0;
    private _crossCenterY = 0;
    private _crossColor1 = '';
    private _crossColor2 = '';

    // circle props
    private _firstCircleCellNumberX = 0;
    private _firstCircleCellNumberY = 0;
    private _circleCenterX = 0;
    private _circleCenterY = 0;
    private _circleColor1 = '';
    private _circleColor2 = '';
    private _circleRadius;
    private _circleIsDragging = false;
    private _circleDragOffsetX = 0;
    private _circleDragOffsetY = 0;

    constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, config: IPleopticCrossConfig) {
        super(canvas, ctx, config);

        this._config = config;
        this._circleRadius = config.squareSize;
    }

    public run(): void {
        // interactive
        this._setEventListeners();

        // draw canvas (animation)
        this._drawProcedure();
    }

    private _drawProcedure(): void {
        if (!this.paused) {
            this._drawBoard();
            this._drawCross();
            this._drawCircle();

            if (this._firstRender) {
                this._setShapesRandomPosition();
                this._firstRender = false;
            }
        }

        if (this._animationNeeded) {
            // reduce FPS
            this.animationTimeout = setTimeout(() => {
                requestAnimationFrame(() => this._drawProcedure());
            }, 1000 / this._fps);
        }
    }

    private _setEventListeners(): void {
        const mousedownHandler = (e: MouseEvent) => {
            const circleClicked = cursorInRect(
                this._canvas,
                e,
                this._circleCenterX - this._circleRadius,
                this._circleCenterY - this._circleRadius,
                this._circleRadius * 2,
                this._circleRadius * 2,
            );

            if (circleClicked) {
                this._circleIsDragging = true;
                this._circleDragOffsetX = e.offsetX - this._circleCenterX;
                this._circleDragOffsetY = e.offsetY - this._circleCenterY;
            }
        };

        const mouseupHandler = () => {
            const sameCenterByX = Math.abs(this._circleCenterX - this._crossCenterX) <= this._config.accuracy;
            const sameCenterByY = Math.abs(this._circleCenterY - this._crossCenterY) <= this._config.accuracy;

            // success => next round
            if (sameCenterByX && sameCenterByY) {
                this._successTries++;

                this._setShapesRandomPosition();
            } else {
                this._failedTries++;

                this._setShapesRandomPosition();
            }
            this._totalTries++;

            this.interact();

            this._circleIsDragging = false;
        };

        const mousemoveHandler = (e: MouseEvent) => {
            if (!this._circleIsDragging) {
                return;
            }

            this._circleCenterX = e.offsetX - this._circleDragOffsetX;
            this._circleCenterY = e.offsetY - this._circleDragOffsetY;
        };

        this.mousedownHandler = mousedownHandler.bind(this);
        this.mouseupHandler = mouseupHandler.bind(this);
        this.mousemoveHandler = mousemoveHandler.bind(this);
        this._canvas.addEventListener('mousedown', this.mousedownHandler);
        this._canvas.addEventListener('mouseup', this.mouseupHandler);
        this._canvas.addEventListener('mousemove', this.mousemoveHandler);
    }

    private _setShapesRandomPosition(): void {
        let crossCellX = 0;
        let crossCellY = 0;
        let circleCellX = 0;
        let circleCellY = 0;

        let counter = 0;

        // to avoid very close distance between shapes
        while ((Math.abs(circleCellX - crossCellX) < 4 || Math.abs(circleCellY - crossCellY) < 4) && counter < 100) {
            crossCellX = getRandomNumber(1, this._xCellsCount - 4); // 4 - is a padding of board for cross
            crossCellY = getRandomNumber(1, this._yCellsCount - 4);
            circleCellX = getRandomNumber(1, this._xCellsCount - 3); // 3 - is a padding of board for circle
            circleCellY = getRandomNumber(1, this._yCellsCount - 3);
            counter++;
        }

        if (counter === 100) {
            alert(t("pleoptic_cross_procedure.too_low_screen_message"));
            Logger.error('Memory leaked here');
        }

        // set cross position
        this._firstCrossCellNumberX = crossCellX;
        this._firstCrossCellNumberY = crossCellY;
        this._crossCenterX = this._firstCrossCellNumberX * this._config.squareSize + this._config.squareSize;
        this._crossCenterY = this._firstCrossCellNumberY * this._config.squareSize + this._config.squareSize;
        // set cross colors
        const crossCellIsEven = (this._firstCrossCellNumberX + this._firstCrossCellNumberY) % 2 == 0;
        this._crossColor1 = crossCellIsEven ? this._squareColor2 : this._squareColor1;
        this._crossColor2 = crossCellIsEven ? this._squareColor1 : this._squareColor2;

        // set circle position
        this._firstCircleCellNumberX = circleCellX;
        this._firstCircleCellNumberY = circleCellY;
        this._circleCenterX = this._firstCircleCellNumberX * this._config.squareSize;
        this._circleCenterY = this._firstCircleCellNumberY * this._config.squareSize;
        // set circle colors
        const circleCellIsEven = (this._firstCircleCellNumberX + this._firstCircleCellNumberY) % 2 == 0;
        this._circleColor1 = circleCellIsEven ? this._squareColor2 : this._squareColor1;
        this._circleColor2 = circleCellIsEven ? this._squareColor1 : this._squareColor2;
    }

    // ========================================================================
    // Just drawing logic below
    // ========================================================================
    private _drawBoard(): void {
        const { rows, columns } = drawChessBoard(this._canvas, this._ctx, this._squareColor1, this._squareColor2, this._config.squareSize);

        this._xCellsCount = rows;
        this._yCellsCount = columns;
    }

    private _drawCross(): void {
        // there is an optical illusion here
        // technically - it's not a cross, it's just a square

        drawChessBoardCell(this._ctx, this._firstCrossCellNumberX, this._firstCrossCellNumberY, this._config.squareSize, this._crossColor1);
        drawChessBoardCell(this._ctx, this._firstCrossCellNumberX + 1, this._firstCrossCellNumberY, this._config.squareSize, this._crossColor2);
        drawChessBoardCell(this._ctx, this._firstCrossCellNumberX, this._firstCrossCellNumberY + 1, this._config.squareSize, this._crossColor2);
        drawChessBoardCell(this._ctx, this._firstCrossCellNumberX + 1, this._firstCrossCellNumberY + 1, this._config.squareSize, this._crossColor1);
    }

    private _drawCircle(): void {
        // there is an optical illusion here
        // technically - it's not a circle, it's a 4 sectors with different background color

        drawCircleSector(this._ctx, this._circleCenterX, this._circleCenterY, this._circleRadius, 0, Math.PI / 2, this._circleColor1);
        drawCircleSector(this._ctx, this._circleCenterX, this._circleCenterY, this._circleRadius, Math.PI / 2, Math.PI, this._circleColor2);
        drawCircleSector(this._ctx, this._circleCenterX, this._circleCenterY, this._circleRadius, Math.PI, (Math.PI * 3) / 2, this._circleColor1);
        drawCircleSector(this._ctx, this._circleCenterX, this._circleCenterY, this._circleRadius, (Math.PI * 3) / 2, 0, this._circleColor2);
    }
}
