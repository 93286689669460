import { createListenerMiddleware } from '@reduxjs/toolkit';

export const proceduresListenerMiddleware = createListenerMiddleware();

// proceduresListenerMiddleware.startListening({
//     actionCreator: setActiveProcedure,
//     effect: async () => {
//         // just test effect
//         // do api-request here
//         // listenerApi.dispatch(setActiveProcedure('test'));
//     },
// });

// Another effect - example
// proceduresListenerMiddleware.startListening({
//     actionCreator: setActiveProcedure,
//     effect: async ({ payload }, listenerApi) => {
//         // just test effect
//         // listenerApi.dispatch(setActiveProcedure('test'));
//     },
// });
