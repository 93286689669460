import { IProcedureBase, ProcedureBase } from './procedureBase.abstract';

export interface IBifixationRecoveryConfig {
    backgroundColor: string;
    gapColor: string;
    duration?: number; // in seconds
    gapWidth: number;
    gapHeight: number;
}

export class BifixationRecoveryProcedure extends ProcedureBase implements IProcedureBase {
    private _config: IBifixationRecoveryConfig;

    constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, config: IBifixationRecoveryConfig) {
        super(canvas, ctx, config);

        this._config = config;
    }

    public run(): void {
        // draw canvas (without animation)
        this._drawProcedure();
    }

    private _drawProcedure(): void {
        // draw background
        this._ctx.fillStyle = this._config.backgroundColor;
        this._ctx.fillRect(0, 0, this._canvas.width, this._canvas.height);

        // draw gap
        this._ctx.fillStyle = this._config.gapColor;
        this._ctx.fillRect(
            this._canvas.width / 2 - this._config.gapWidth / 2,
            this._canvas.height / 2 - this._config.gapHeight / 2,
            this._config.gapWidth,
            this._config.gapHeight,
        );
    }
}
