import { Button } from 'antd';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDiagonalProps } from '../ScreenSettingsModal/ScreenSettingsModal';
import styles from './ScreenHeightWidthModal.module.scss';

export const ScreenHeightWidthModal = ({ onHandleChangeDiagonal, diagonal }: IDiagonalProps) => {
    const { t } = useTranslation();
    const [monitorHeight, setMonitorHeight] = useState<string>('');
    const [monitorWidth, setMonitorWidth] = useState<string>('');
    const [monitorDiagonal, setMonitorDiagonal] = useState<string>(diagonal);
    const [isDiagonalValid, setIsDiagonalValid] = useState<boolean>(true);

    const handleChangeMonitorParameters = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = Math.round(parseFloat(e.target.value)) || 0;

        if (name === 'width') {
            setMonitorWidth(value.toString());
        } else {
            setMonitorHeight(value.toString());
        }

        if (value < 100) {
            setIsDiagonalValid(false);
        } else {
            setIsDiagonalValid(true);
        }
    };

    const calculateDiagonal = () => {
        if (monitorHeight && monitorWidth) {
            setMonitorDiagonal(
                Math.sqrt(Math.pow(+monitorWidth, 2) + Math.pow(+monitorHeight, 2))
                    .toFixed(0)
                    .toString(),
            );
            onHandleChangeDiagonal(
                Math.sqrt(Math.pow(+monitorWidth, 2) + Math.pow(+monitorHeight, 2))
                    .toFixed(0)
                    .toString(),
            );
        }
    };

    return (
        <div>
            <div className={styles.content}>
                <p className="mb-5">{t('modals.screenSettings.categories.widthHeight.description')}</p>
                <div className="d-flex align-items-start justify-content-center mb-4">
                    <img className="col-6 me-4" width={100} src="/icons/modals/height-width-description-screen.png" alt="monitor" />
                    <div className="col-6">
                        <div className={`${styles['recommendation-block']} mb-3`}>
                            <p className="col-6">{t('modals.screenSettings.categories.widthHeight.width')}</p>
                            <input
                                type="number"
                                value={monitorWidth}
                                name="width"
                                onChange={handleChangeMonitorParameters}
                                className={styles.input}
                            />
                            <p style={{ marginLeft: '8px', color: 'red' }}>{t('modals.screenSettings.categories.widthHeight.si')}</p>
                        </div>
                        <div className={styles['recommendation-block']}>
                            <p className="col-6">{t('modals.screenSettings.categories.widthHeight.height')}</p>
                            <input
                                type="number"
                                value={monitorHeight}
                                name="height"
                                onChange={handleChangeMonitorParameters}
                                className={styles.input}
                            />
                            <p style={{ marginLeft: '8px', color: 'red' }}>{t('modals.screenSettings.categories.widthHeight.si')}</p>
                        </div>
                        {!isDiagonalValid && <p style={{ textAlign: 'end', color: 'red', marginTop: '15px' }}>{t('modals.screenSettings.categories.widthHeight.more_than_100_mm')}</p>}
                        <Button disabled={!monitorHeight && !monitorWidth} onClick={calculateDiagonal} className="w-100 my-3">
                            {t('modals.screenSettings.categories.widthHeight.calculateButton')}
                        </Button>
                        {monitorDiagonal && (
                            <div className={styles['recommendation-block']}>
                                <p className="col-6">{t('modals.screenSettings.categories.widthHeight.diagonal')}</p>
                                <input type="number" value={monitorDiagonal} disabled className={styles.input} />
                                <p style={{ marginLeft: '8px', color: 'red' }}>{t('modals.screenSettings.categories.widthHeight.si')}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
