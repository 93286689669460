import { REACT_APP_SUPPORT_PHONE } from '@utils/constants';
import { Button, Modal } from 'antd';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import styles from './PatientSupportModal.module.scss';

export interface IPatientSupportModalProps {
    visible: boolean;
    onClose: () => void;
}

export const PatientSupportModal = ({ visible, onClose }: IPatientSupportModalProps) => {
    const navigate = useNavigate();

    const goToAppointment = () => {
        navigate('/appointments');
        onClose();
    };

    return visible ? (
        <Modal
            className={styles.supportModal}
            width={592}
            title={t('userMainPage.supportModal.title')}
            open={visible}
            onCancel={onClose}
            footer={[
                <Button onClick={onClose} key="support">
                    {t('modals.buttons.close')}
                </Button>,
                <Button onClick={goToAppointment} key="submit" type="primary">
                    {t('userMainPage.supportModal.goToAppointments')}
                </Button>,
            ]}
        >
            <p className="mb-3">{t('userMainPage.supportModal.description')}</p>
            <img className={styles.menuShot} src="/img/support/menu-shot.png" alt="" />
            <p className="mt-4">{t('userMainPage.supportModal.phoneDescr')}</p>
            <p className={styles.phone}> {REACT_APP_SUPPORT_PHONE}</p>
        </Modal>
    ) : null;
};
