import { SettingsHelpBlock } from '@components';
import { Button, Modal } from 'antd';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ScreenDistanceModal.module.scss';

interface IScreenDistanceModalProps {
    showModal: boolean;
    onCancel: () => void;
    distance: string;
    onChangeDistance: (e: ChangeEvent<HTMLInputElement>) => void;
    onSuccess: () => void;
    isFirst: boolean;
}

export const ScreenDistanceModal = ({ showModal, onCancel, distance, onChangeDistance, onSuccess, isFirst }: IScreenDistanceModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            width={1000}
            title={t('modals.screenDistance.title')}
            open={showModal}
            onCancel={onCancel}
            footer={[
                // <Button key="support" onClick={techSupport}>
                //     <ChatWithDotsIcon className="me-2" /> {t('modals.buttons.techSupport')}
                // </Button>,
                <SettingsHelpBlock key="support" />,
                <Button onClick={onSuccess} key="submit" type="primary">
                    {isFirst ? t('modals.buttons.next') : t('modals.buttons.ready')}
                </Button>,
            ]}
        >
            <div className={styles.content}>
                <p className="mb-5">{t('modals.screenDistance.description')}</p>
                <img src="/icons/modals/distance.png" />
                <div className={styles['recommendation-block']}>
                    <p className="me-3">{t('modals.screenDistance.distance')}</p>
                    <input type="number" value={distance} onChange={onChangeDistance} className={styles.input} />
                    <p className="ms-3">{t('modals.screenDistance.si')}</p>
                </div>
                <p className={styles.description}>{t('modals.screenDistance.recommendation')}</p>
            </div>
        </Modal>
    );
};
