import { ChatWith3DotsIcon, MessageSentIcon, MessageViewedIcon, Send } from '@icons';
import { IChatMessage } from '@models';
import { Input } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { createRef, useEffect, useRef, useState } from 'react';
import styles from './Chat.module.scss';
import {useTranslation} from "react-i18next";

interface IChatProps {
    haveAccess?: boolean;
    placeholder?: string;
    noMessagesLabel?: string;
    bottomTooltip?: string;
    messages?: IChatMessage[];
    currentUserId?: string;
    chatId?: string;
    isHeadPhysician?: boolean;
    sendMessage?: (text: string) => void;
    markAsRead?: (messageId: string) => void;
}

export const Chat = ({
    haveAccess = true,
    noMessagesLabel,
    bottomTooltip,
    messages,
    currentUserId,
    chatId,
    isHeadPhysician = false,
    sendMessage,
    markAsRead,
}: IChatProps) => {
    const messagesBlockRef = createRef<HTMLUListElement>();
    const {t} = useTranslation();
    const [chatMessageInput, setChatMessageInput] = useState('');
    const [initialScroll, setInitialScroll] = useState(true);

    const intersectionRoot = useRef<HTMLDivElement>(null);
    const observer = useRef(
        new IntersectionObserver(
            (entries) => {
                let i = 0;
                const intersectingEntries = entries.filter((e) => e.isIntersecting);
                for (let i = 0, iMax = intersectingEntries.length; i < iMax; i++) {
                    // Timeouts to prevent missed ws messages
                    setTimeout(() => {
                        const entry = intersectingEntries[i];

                        if (entry) {
                            const messageElement = entry.target;
                            const messageId = messageElement.getAttribute('data-id');
                            if (messageId) {
                                markAsRead?.(messageId);
                                observer.current.unobserve(messageElement);
                            }
                        }
                    }, i * 100);
                }
            },
            {
                root: intersectionRoot.current,
                rootMargin: '0px',
                threshold: 1.0,
            },
        ),
    );

    useEffect(() => {
        if (messages?.length) {
            if (initialScroll) {
                scrollToTheEnd();
                setInitialScroll(false);
            }

            if (haveAccess) {
                const messageBlocks = Array.from(messagesBlockRef.current?.children || []);
                messageBlocks.forEach((message) => {
                    const notRead = message.getAttribute('data-notread');
                    if (notRead === 'true') {
                        observer.current.observe(message);
                    }
                });

                // if last message is mine - scroll to it
                if (messages.at(-1)?.senderId === currentUserId) {
                    scrollToTheEnd(true);
                }

                return () => {
                    messageBlocks.forEach((message) => {
                        observer.current.unobserve(message);
                    });
                };
            }
        }
    }, [messages, haveAccess, initialScroll]);

    useEffect(() => {
        if (chatId) {
            scrollToTheEnd();
        }
    }, [chatId]);

    const scrollToTheEnd = (smooth = false) => {
        // after it will be rendered
        setTimeout(() => {
            messagesBlockRef.current?.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto', block: 'end' });
        });
    };

    const send = (text: string) => {
        if (!!text) {
            sendMessage?.(text);
            setChatMessageInput('');
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.chatBody} ref={intersectionRoot}>
                <ul className={styles.messages} ref={messagesBlockRef}>
                    {messages?.length ? (
                        <>
                            {messages.map((message) => {
                                const isMine = message.senderId === currentUserId;

                                return (
                                    <li
                                        key={message.id}
                                        data-id={message.id}
                                        data-notread={!isMine && !message.viewed}
                                        className={classNames(styles.message, isMine && styles.mine)}
                                        onClick={() => {
                                            !isMine && !message.viewed && markAsRead?.(message.id!);
                                        }}
                                    >
                                        <p className={styles.text}>{message.text}</p>
                                        <div className={styles.dateBlock}>
                                            <span className={styles.date}>
                                                {isHeadPhysician && message.sender?.name} {dayjs(message.createdDate).format('HH:mm')}
                                            </span>

                                            {isMine && <>{message.sent && message.viewed ? <MessageViewedIcon /> : <MessageSentIcon />}</>}
                                        </div>
                                    </li>
                                );
                            })}

                            {bottomTooltip && <li className={styles.description}>{bottomTooltip}</li>}
                        </>
                    ) : (
                        <li className={styles.description}>
                            <ChatWith3DotsIcon className={styles.chatIcon} />
                            {noMessagesLabel}
                        </li>
                    )}
                </ul>
            </div>

            {haveAccess && (
                <div className={classNames(styles.chatFooter, 'w-100')}>
                    <Input
                        placeholder={t("appointmentPage.chat.your_message")}
                        suffix={<Send onClick={() => send(chatMessageInput)} />}
                        value={chatMessageInput}
                        onChange={(e) => setChatMessageInput(e.currentTarget.value)}
                        onPressEnter={(e) => send(e.currentTarget.value)}
                    />
                </div>
            )}
        </div>
    );
};
