import { ScreenDisabledIcon, SettingsIcon } from '@icons';
import { ScreenSettingsModal } from '@modals';
import { WsProcedureTopicType } from '@utils/websocket.topics';
import { Button, Col, Row, Space } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { WebsocketContext } from 'src/contexts/ws.context';
import { SessionParticipant } from 'src/models/session-participant';
import { SelectedParticipantTabType } from 'src/pages/_shared/DoctorAppointmentPage/DoctorAppointmentPage';
import { VideoParticipant } from '../../../../../components/VideoParticipant/VideoParticipant';
import styles from './TherapySession.module.scss';
import {useTranslation} from "react-i18next";

interface ITherapySessionProps {
    participants: SessionParticipant[];
    activeScreenSharingParticipant?: SessionParticipant;
    participantDiagonal?: string;

    onParticipantSelect?: (selectedType: SelectedParticipantTabType, participant: SessionParticipant) => void;
    onParticipantKickOut?: (participant: SessionParticipant) => void;
    onApproveFromLobby?: (ids: string[]) => void;
    onRequestScreenSharing?: (state: boolean, participant: SessionParticipant) => void;
}

export const TherapySession = ({
    participants,
    activeScreenSharingParticipant,
    participantDiagonal,
    onParticipantSelect,
    onParticipantKickOut,
    onApproveFromLobby,
    onRequestScreenSharing,
}: ITherapySessionProps) => {
    const screenSharingWrapperRef = useRef<HTMLDivElement | null>(null);
    const { send } = useContext(WebsocketContext);
    const {t} = useTranslation();

    const [showEditPatientDiagonalModal, setShowEditPatientDiagonalModal] = useState(false);
    const [patientDiagonal, setPatientDiagonal] = useState<string>('');

    useEffect(() => {
        const videoElement = activeScreenSharingParticipant?.kurentoParticipant?.screenSharingVideo;

        if (screenSharingWrapperRef.current && videoElement) {
            screenSharingWrapperRef.current.appendChild(videoElement);
        }

        return () => {
            if (screenSharingWrapperRef.current && videoElement) {
                screenSharingWrapperRef.current.removeChild(videoElement);
            }
        };
    }, [activeScreenSharingParticipant]);

    useEffect(() => {
        if (participantDiagonal) {
            setPatientDiagonal(participantDiagonal);
        }
    }, [participantDiagonal]);

    const renderParticipantPlaceholder = (participant: SessionParticipant, compact = false) => {
        return (
            <div
                className={classNames(
                    styles.participant_placeholder,
                    participant.inLobby && styles.in_lobby,
                    participant.selected && styles.selected,
                    compact && styles.compact,
                )}
                onClick={() => onParticipantSelect?.('info', participant)}
            >
                <p className={styles.placeholder_title}>
                    {participant.patient?.familyName} {participant.patient?.givenName}
                </p>
                <div
                    className={
                        (participant.carePlanDTO?.currentProgress || 0 + 1) !== 1 ? styles.placeholder_subtitle : styles.placeholder_subtitle_first
                    }
                >
                    <span className={styles.placeholder_age}>{dayjs(participant.birthDate).fromNow(true)}</span>
                    <span className={styles.placeholder_course}>
                        {participant.carePlanDTO?.currentProgress || 0 + 1} / {participant.carePlanDTO?.durationInDays}
                    </span>
                </div>

                {participant.inLobby ? (
                    <Button type="primary" onClick={() => onApproveFromLobby?.([participant.fhirId!])}>
                        {t("connect_button")}
                    </Button>
                ) : (
                    <p className={styles.placeholder_waiting}>{t("waiting")}</p>
                )}
            </div>
        );
    };

    const handleChangeDiagonal = (value: string) => {
        setPatientDiagonal(value);
    };

    const handleDistanceSuccess = () => {
        setShowEditPatientDiagonalModal(false);

        send('/msg/gateway-ws/message', '/procedure/' + activeScreenSharingParticipant?.fhirId, {
            type: WsProcedureTopicType.screenSettingsChange,
            body: { diagonal: patientDiagonal },
        });
    };

    return (
        <div className={classNames(styles.wrapper, activeScreenSharingParticipant && styles.screenSharing)}>
            {activeScreenSharingParticipant ? (
                <>
                    <div className={styles.screenVideoWrapper}>
                        <div className={styles.participant_screenSharing} ref={screenSharingWrapperRef}></div>
                        <Button className={styles.disableScreenBtn} onClick={() => onRequestScreenSharing?.(false, activeScreenSharingParticipant)}>
                            <ScreenDisabledIcon />
                        </Button>
                        <Button className={styles.openScreenSettingsBtn} onClick={() => setShowEditPatientDiagonalModal(true)}>
                            <SettingsIcon />
                        </Button>
                    </div>
                    <div className={styles.scrollX}>
                        <Space size={8} className={styles.listWrapper}>
                            {participants.map((participant, i) =>
                                participant.kurentoParticipant ? (
                                    <VideoParticipant
                                        key={i}
                                        compact={true}
                                        participant={participant}
                                        manageable={false}
                                        selectEvent={(e) => onParticipantSelect?.(e, participant)}
                                        serviceType={'therapy-session'}
                                    />
                                ) : (
                                    <Fragment key={i}>{renderParticipantPlaceholder(participant, true)}</Fragment>
                                ),
                            )}
                        </Space>
                    </div>

                    {showEditPatientDiagonalModal && (
                        <ScreenSettingsModal
                            title={t("therapy_session.set_patient_screen_button")}
                            diagonal={patientDiagonal}
                            onHandleChangeDiagonal={handleChangeDiagonal}
                            onCancel={() => setShowEditPatientDiagonalModal(false)}
                            onSuccess={handleDistanceSuccess}
                            isFirst={false}
                            showModal={true}
                        />
                    )}
                </>
            ) : (
                <Row style={{ padding: 16 }} className={styles.participants}>
                    {participants?.map((participant) => {
                        return participant.kurentoParticipant ? (
                            <Col style={{ padding: 8 }} sm={24} lg={12} xl={8} xxl={6} key={participant.fhirId}>
                                <VideoParticipant
                                    participant={participant}
                                    manageable={true}
                                    selectEvent={(e) => onParticipantSelect?.(e, participant)}
                                    kickOut={(e) => onParticipantKickOut?.(e)}
                                    requestSharing={(state) => onRequestScreenSharing?.(state, participant)}
                                    serviceType={'therapy-session'}
                                />
                            </Col>
                        ) : (
                            <Col style={{ padding: 8 }} sm={24} lg={12} xl={8} xxl={6} key={participant.fhirId}>
                                {renderParticipantPlaceholder(participant)}
                            </Col>
                        );
                    })}
                </Row>
            )}
        </div>
    );
};
