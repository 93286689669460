import styles from './DiagnosticLetters.module.scss';

interface IDiagnosticLettersProps {
    size?: number;
    letter?: string;
}

export const DiagnosticLetters = ({ size = 14, letter }: IDiagnosticLettersProps) => {
    // size / 4 - it's an internal padding of the letter/font

    return (
        <div className={styles.wrapper}>
            <span className="letter" style={{ fontSize: size + size / 4 + 'px' }}>
                {letter}
            </span>
        </div>
    );
};
