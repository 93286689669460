/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Document service API
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
export type DeleteDocumentParams = {
    fhirId: string;
    uuid: string;
};

export type GetMkbDataParams = {
    includeChildren?: boolean;
};

export type SaveSignatureBody = {
    encounterId: string;
    file: Blob;
};

export type GetMkbByFilterParams = {
    mkbCode?: string;
    includeChildren?: boolean;
};

export type GetDocumentPatternParams = {
    code: string;
};

export type GetAgentReportParams = {
    fromPeriod: number;
    toPeriod: number;
};

export type DetachEncounterDocumentsParams = {
    documentFhirIds: string[];
};

export type UserDirectDocumentDTOType = (typeof UserDirectDocumentDTOType)[keyof typeof UserDirectDocumentDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDirectDocumentDTOType = {
    OMS: 'OMS',
    PASSPORT: 'PASSPORT',
    BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
    PARENTAL_CONSENT: 'PARENTAL_CONSENT',
    TRUSTEE_PASSPORT: 'TRUSTEE_PASSPORT',
    DISCHARGE_SUMMARY: 'DISCHARGE_SUMMARY',
    CONSULTATION: 'CONSULTATION',
    PERSONAL_DATA_STATEMENT: 'PERSONAL_DATA_STATEMENT',
    CERTIFIED_OPHTHALMOLOGISTS_REPORT: 'CERTIFIED_OPHTHALMOLOGISTS_REPORT',
    ANALYZES: 'ANALYZES',
    SNAPSHOTS: 'SNAPSHOTS',
    AVATAR: 'AVATAR',
    LOGO: 'LOGO',
    OTHER: 'OTHER',
    SIGNATURE: 'SIGNATURE',
    CONCLUSION: 'CONCLUSION',
    VIDEO: 'VIDEO',
    MEDICAL_SERVICE_CONTRACT: 'MEDICAL_SERVICE_CONTRACT',
    MEDICAL_SERVICE_CONSENT: 'MEDICAL_SERVICE_CONSENT',
    PERSONAL_DATA_CONSENT: 'PERSONAL_DATA_CONSENT',
    USER_AGREEMENT_PATIENTS: 'USER_AGREEMENT_PATIENTS',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
} as const;

export interface UserDirectDocumentDTO {
    documentFhirId?: string;
    downloadUrl?: string;
    encounterFhirId?: string;
    extension?: string;
    name?: string;
    number?: string;
    organizationFhirId?: string;
    s3key?: string;
    type?: UserDirectDocumentDTOType;
    typeDescription?: string;
    userFhirId?: string;
}

export type UserDocumentDTOType = (typeof UserDocumentDTOType)[keyof typeof UserDocumentDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDocumentDTOType = {
    OMS: 'OMS',
    PASSPORT: 'PASSPORT',
    BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
    PARENTAL_CONSENT: 'PARENTAL_CONSENT',
    TRUSTEE_PASSPORT: 'TRUSTEE_PASSPORT',
    DISCHARGE_SUMMARY: 'DISCHARGE_SUMMARY',
    CONSULTATION: 'CONSULTATION',
    PERSONAL_DATA_STATEMENT: 'PERSONAL_DATA_STATEMENT',
    CERTIFIED_OPHTHALMOLOGISTS_REPORT: 'CERTIFIED_OPHTHALMOLOGISTS_REPORT',
    ANALYZES: 'ANALYZES',
    SNAPSHOTS: 'SNAPSHOTS',
    AVATAR: 'AVATAR',
    LOGO: 'LOGO',
    OTHER: 'OTHER',
    SIGNATURE: 'SIGNATURE',
    CONCLUSION: 'CONCLUSION',
    VIDEO: 'VIDEO',
    MEDICAL_SERVICE_CONTRACT: 'MEDICAL_SERVICE_CONTRACT',
    MEDICAL_SERVICE_CONSENT: 'MEDICAL_SERVICE_CONSENT',
    PERSONAL_DATA_CONSENT: 'PERSONAL_DATA_CONSENT',
    USER_AGREEMENT_PATIENTS: 'USER_AGREEMENT_PATIENTS',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
} as const;

export interface UserDocumentDTO {
    documentFhirId?: string;
    encounterFhirId?: string;
    extension?: string;
    name?: string;
    number?: string;
    organizationFhirId?: string;
    s3key?: string;
    type?: UserDocumentDTOType;
    typeDescription?: string;
    userFhirId?: string;
}

export type ExceptionDefinitionHttpResponseCode = (typeof ExceptionDefinitionHttpResponseCode)[keyof typeof ExceptionDefinitionHttpResponseCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExceptionDefinitionHttpResponseCode = {
    '100_CONTINUE': '100 CONTINUE',
    '101_SWITCHING_PROTOCOLS': '101 SWITCHING_PROTOCOLS',
    '102_PROCESSING': '102 PROCESSING',
    '103_CHECKPOINT': '103 CHECKPOINT',
    '200_OK': '200 OK',
    '201_CREATED': '201 CREATED',
    '202_ACCEPTED': '202 ACCEPTED',
    '203_NON_AUTHORITATIVE_INFORMATION': '203 NON_AUTHORITATIVE_INFORMATION',
    '204_NO_CONTENT': '204 NO_CONTENT',
    '205_RESET_CONTENT': '205 RESET_CONTENT',
    '206_PARTIAL_CONTENT': '206 PARTIAL_CONTENT',
    '207_MULTI_STATUS': '207 MULTI_STATUS',
    '208_ALREADY_REPORTED': '208 ALREADY_REPORTED',
    '226_IM_USED': '226 IM_USED',
    '300_MULTIPLE_CHOICES': '300 MULTIPLE_CHOICES',
    '301_MOVED_PERMANENTLY': '301 MOVED_PERMANENTLY',
    '302_FOUND': '302 FOUND',
    '302_MOVED_TEMPORARILY': '302 MOVED_TEMPORARILY',
    '303_SEE_OTHER': '303 SEE_OTHER',
    '304_NOT_MODIFIED': '304 NOT_MODIFIED',
    '305_USE_PROXY': '305 USE_PROXY',
    '307_TEMPORARY_REDIRECT': '307 TEMPORARY_REDIRECT',
    '308_PERMANENT_REDIRECT': '308 PERMANENT_REDIRECT',
    '400_BAD_REQUEST': '400 BAD_REQUEST',
    '401_UNAUTHORIZED': '401 UNAUTHORIZED',
    '402_PAYMENT_REQUIRED': '402 PAYMENT_REQUIRED',
    '403_FORBIDDEN': '403 FORBIDDEN',
    '404_NOT_FOUND': '404 NOT_FOUND',
    '405_METHOD_NOT_ALLOWED': '405 METHOD_NOT_ALLOWED',
    '406_NOT_ACCEPTABLE': '406 NOT_ACCEPTABLE',
    '407_PROXY_AUTHENTICATION_REQUIRED': '407 PROXY_AUTHENTICATION_REQUIRED',
    '408_REQUEST_TIMEOUT': '408 REQUEST_TIMEOUT',
    '409_CONFLICT': '409 CONFLICT',
    '410_GONE': '410 GONE',
    '411_LENGTH_REQUIRED': '411 LENGTH_REQUIRED',
    '412_PRECONDITION_FAILED': '412 PRECONDITION_FAILED',
    '413_PAYLOAD_TOO_LARGE': '413 PAYLOAD_TOO_LARGE',
    '413_REQUEST_ENTITY_TOO_LARGE': '413 REQUEST_ENTITY_TOO_LARGE',
    '414_URI_TOO_LONG': '414 URI_TOO_LONG',
    '414_REQUEST_URI_TOO_LONG': '414 REQUEST_URI_TOO_LONG',
    '415_UNSUPPORTED_MEDIA_TYPE': '415 UNSUPPORTED_MEDIA_TYPE',
    '416_REQUESTED_RANGE_NOT_SATISFIABLE': '416 REQUESTED_RANGE_NOT_SATISFIABLE',
    '417_EXPECTATION_FAILED': '417 EXPECTATION_FAILED',
    '418_I_AM_A_TEAPOT': '418 I_AM_A_TEAPOT',
    '419_INSUFFICIENT_SPACE_ON_RESOURCE': '419 INSUFFICIENT_SPACE_ON_RESOURCE',
    '420_METHOD_FAILURE': '420 METHOD_FAILURE',
    '421_DESTINATION_LOCKED': '421 DESTINATION_LOCKED',
    '422_UNPROCESSABLE_ENTITY': '422 UNPROCESSABLE_ENTITY',
    '423_LOCKED': '423 LOCKED',
    '424_FAILED_DEPENDENCY': '424 FAILED_DEPENDENCY',
    '425_TOO_EARLY': '425 TOO_EARLY',
    '426_UPGRADE_REQUIRED': '426 UPGRADE_REQUIRED',
    '428_PRECONDITION_REQUIRED': '428 PRECONDITION_REQUIRED',
    '429_TOO_MANY_REQUESTS': '429 TOO_MANY_REQUESTS',
    '431_REQUEST_HEADER_FIELDS_TOO_LARGE': '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
    '451_UNAVAILABLE_FOR_LEGAL_REASONS': '451 UNAVAILABLE_FOR_LEGAL_REASONS',
    '500_INTERNAL_SERVER_ERROR': '500 INTERNAL_SERVER_ERROR',
    '501_NOT_IMPLEMENTED': '501 NOT_IMPLEMENTED',
    '502_BAD_GATEWAY': '502 BAD_GATEWAY',
    '503_SERVICE_UNAVAILABLE': '503 SERVICE_UNAVAILABLE',
    '504_GATEWAY_TIMEOUT': '504 GATEWAY_TIMEOUT',
    '505_HTTP_VERSION_NOT_SUPPORTED': '505 HTTP_VERSION_NOT_SUPPORTED',
    '506_VARIANT_ALSO_NEGOTIATES': '506 VARIANT_ALSO_NEGOTIATES',
    '507_INSUFFICIENT_STORAGE': '507 INSUFFICIENT_STORAGE',
    '508_LOOP_DETECTED': '508 LOOP_DETECTED',
    '509_BANDWIDTH_LIMIT_EXCEEDED': '509 BANDWIDTH_LIMIT_EXCEEDED',
    '510_NOT_EXTENDED': '510 NOT_EXTENDED',
    '511_NETWORK_AUTHENTICATION_REQUIRED': '511 NETWORK_AUTHENTICATION_REQUIRED',
} as const;

export interface ExceptionDefinition {
    code?: string;
    defaultMessage?: string;
    httpResponseCode?: ExceptionDefinitionHttpResponseCode;
    shared?: boolean;
}

export interface MkbDTO {
    ACTUAL?: number;
    ID?: number;
    ID_PARENT?: string;
    MKB_CODE?: string;
    MKB_NAME?: string;
    REC_CODE?: string;
}

export interface AgentReportDTO {
    orderNumber?: number;
    patientFullName?: string;
    paymentDate?: number;
    pricePerUnit?: string;
    quantity?: string;
    serviceType?: string;
    totalPrice?: string;
}

export interface SignatureAboutRequestDTO {
    serialNumber?: string;
    subjectGivenName?: string;
    subjectName?: string;
    subjectSurname?: string;
    validFrom?: string;
    validTo?: string;
}

export type CreateDocumentRequestDTOValues = { [key: string]: any };

export interface CreateDocumentRequestDTO {
    encounterId?: string;
    signatureAbout?: SignatureAboutRequestDTO;
    values?: CreateDocumentRequestDTOValues;
}

export type FileUpdateDTOType = (typeof FileUpdateDTOType)[keyof typeof FileUpdateDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileUpdateDTOType = {
    OMS: 'OMS',
    PASSPORT: 'PASSPORT',
    BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
    PARENTAL_CONSENT: 'PARENTAL_CONSENT',
    TRUSTEE_PASSPORT: 'TRUSTEE_PASSPORT',
    DISCHARGE_SUMMARY: 'DISCHARGE_SUMMARY',
    CONSULTATION: 'CONSULTATION',
    PERSONAL_DATA_STATEMENT: 'PERSONAL_DATA_STATEMENT',
    CERTIFIED_OPHTHALMOLOGISTS_REPORT: 'CERTIFIED_OPHTHALMOLOGISTS_REPORT',
    ANALYZES: 'ANALYZES',
    SNAPSHOTS: 'SNAPSHOTS',
    AVATAR: 'AVATAR',
    LOGO: 'LOGO',
    OTHER: 'OTHER',
    SIGNATURE: 'SIGNATURE',
    CONCLUSION: 'CONCLUSION',
    VIDEO: 'VIDEO',
    MEDICAL_SERVICE_CONTRACT: 'MEDICAL_SERVICE_CONTRACT',
    MEDICAL_SERVICE_CONSENT: 'MEDICAL_SERVICE_CONSENT',
    PERSONAL_DATA_CONSENT: 'PERSONAL_DATA_CONSENT',
    USER_AGREEMENT_PATIENTS: 'USER_AGREEMENT_PATIENTS',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
} as const;

export interface FileUpdateDTO {
    extension?: string;
    fhirId?: string;
    name?: string;
    number?: string;
    type?: FileUpdateDTOType;
    typeDescription?: string;
    uuid?: string;
}

export interface FineUploaderDTO {
    success?: boolean;
    uuid?: string;
}

export type FileUploadDTOType = (typeof FileUploadDTOType)[keyof typeof FileUploadDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileUploadDTOType = {
    OMS: 'OMS',
    PASSPORT: 'PASSPORT',
    BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
    PARENTAL_CONSENT: 'PARENTAL_CONSENT',
    TRUSTEE_PASSPORT: 'TRUSTEE_PASSPORT',
    DISCHARGE_SUMMARY: 'DISCHARGE_SUMMARY',
    CONSULTATION: 'CONSULTATION',
    PERSONAL_DATA_STATEMENT: 'PERSONAL_DATA_STATEMENT',
    CERTIFIED_OPHTHALMOLOGISTS_REPORT: 'CERTIFIED_OPHTHALMOLOGISTS_REPORT',
    ANALYZES: 'ANALYZES',
    SNAPSHOTS: 'SNAPSHOTS',
    AVATAR: 'AVATAR',
    LOGO: 'LOGO',
    OTHER: 'OTHER',
    SIGNATURE: 'SIGNATURE',
    CONCLUSION: 'CONCLUSION',
    VIDEO: 'VIDEO',
    MEDICAL_SERVICE_CONTRACT: 'MEDICAL_SERVICE_CONTRACT',
    MEDICAL_SERVICE_CONSENT: 'MEDICAL_SERVICE_CONSENT',
    PERSONAL_DATA_CONSENT: 'PERSONAL_DATA_CONSENT',
    USER_AGREEMENT_PATIENTS: 'USER_AGREEMENT_PATIENTS',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
} as const;

export interface FileUploadDTO {
    extension?: string;
    fhirId?: string;
    file?: Blob;
    name?: string;
    number?: string;
    partindex?: number;
    totalparts?: number;
    type?: FileUploadDTOType;
    typeDescription?: string;
}

export type FileSafeDTOBucketType = (typeof FileSafeDTOBucketType)[keyof typeof FileSafeDTOBucketType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileSafeDTOBucketType = {
    DOCUMENT: 'DOCUMENT',
    OPEN_DOCUMENT: 'OPEN_DOCUMENT',
    AVATAR: 'AVATAR',
    VIDEO: 'VIDEO',
    LOGOTYPE: 'LOGOTYPE',
} as const;

export interface FileSafeDTO {
    bucketType?: FileSafeDTOBucketType;
    encounterId?: string;
    fileIds?: string[];
    organizationId?: string;
    userFhirId?: string;
    userRoleFhirId?: string;
    userType?: string;
}

export interface AidReference {
    display?: string;
    id?: string;
    resourceType?: string;
}

export interface RepositoryItemDto {
    encounter?: AidReference[];
    fileName?: string;
    patient?: AidReference;
    url?: string;
}

export interface DocumentPatternDto {
    code?: string;
    context?: string;
    prefix?: string;
}

export const uploadFileFromKurento = <TData = AxiosResponse<void>>(
    repositoryItemDto: RepositoryItemDto,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/document-service/internal/files/download-kurento-video`, repositoryItemDto, options);
};

export const getDocumentLinksByOrganization = <TData = AxiosResponse<UserDirectDocumentDTO[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/document-service/files/organization/links`, options);
};

export const detachEncounterDocuments = <TData = AxiosResponse<void>>(
    params: DetachEncounterDocumentsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/document-service/files/encounter/detach`, undefined, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const updateInfo = <TData = AxiosResponse<FineUploaderDTO>>(fileUpdateDTO: FileUpdateDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/document-service/files-uploader/updateInfo`, fileUpdateDTO, options);
};

export const getAgentReport = <TData = AxiosResponse<AgentReportDTO[]>>(
    params: GetAgentReportParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/document-service/report/agent-report`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getExceptionClientCodes = <TData = AxiosResponse<ExceptionDefinition[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/document-service/global/definitions/exceptions`, options);
};

export const getDocumentLinksByUserAndFileType = <TData = AxiosResponse<UserDocumentDTO[]>>(
    userRoleFhirId: string,
    fileType:
        | 'OMS'
        | 'PASSPORT'
        | 'BIRTH_CERTIFICATE'
        | 'PARENTAL_CONSENT'
        | 'TRUSTEE_PASSPORT'
        | 'DISCHARGE_SUMMARY'
        | 'CONSULTATION'
        | 'PERSONAL_DATA_STATEMENT'
        | 'CERTIFIED_OPHTHALMOLOGISTS_REPORT'
        | 'ANALYZES'
        | 'SNAPSHOTS'
        | 'AVATAR'
        | 'LOGO'
        | 'OTHER'
        | 'SIGNATURE'
        | 'CONCLUSION'
        | 'VIDEO'
        | 'MEDICAL_SERVICE_CONTRACT'
        | 'MEDICAL_SERVICE_CONSENT'
        | 'PERSONAL_DATA_CONSENT'
        | 'USER_AGREEMENT_PATIENTS'
        | 'PRIVACY_POLICY',
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/document-service/files/user/${userRoleFhirId}/${fileType}`, options);
};

export const getDocumentLinksByUser = <TData = AxiosResponse<UserDocumentDTO[]>>(
    userRoleFhirId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/document-service/files/user/${userRoleFhirId}/links`, options);
};

export const getContent = <TData = AxiosResponse<Blob>>(
    bucketType: 'DOCUMENT' | 'OPEN_DOCUMENT' | 'AVATAR' | 'VIDEO' | 'LOGOTYPE',
    s3Key: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/document-service/files/download/${bucketType}/${s3Key}`, {
        responseType: 'blob',
        ...options,
    });
};

export const uploadFile = <TData = AxiosResponse<FineUploaderDTO>>(fileUploadDTO: FileUploadDTO, options?: AxiosRequestConfig): Promise<TData> => {
    const formData = new FormData();
    if (fileUploadDTO.fhirId !== undefined) {
        formData.append('fhirId', fileUploadDTO.fhirId);
    }
    if (fileUploadDTO.file !== undefined) {
        formData.append('file', fileUploadDTO.file);
    }
    if (fileUploadDTO.name !== undefined) {
        formData.append('name', fileUploadDTO.name);
    }
    if (fileUploadDTO.type !== undefined) {
        formData.append('type', fileUploadDTO.type);
    }
    if (fileUploadDTO.extension !== undefined) {
        formData.append('extension', fileUploadDTO.extension);
    }
    if (fileUploadDTO.typeDescription !== undefined) {
        formData.append('typeDescription', fileUploadDTO.typeDescription);
    }
    if (fileUploadDTO.number !== undefined) {
        formData.append('number', fileUploadDTO.number);
    }
    if (fileUploadDTO.totalparts !== undefined) {
        formData.append('totalparts', fileUploadDTO.totalparts.toString());
    }
    if (fileUploadDTO.partindex !== undefined) {
        formData.append('partindex', fileUploadDTO.partindex.toString());
    }

    return axios.post(`/document-service/files-uploader/upload`, formData, options);
};

export const getDocumentPattern = <TData = AxiosResponse<DocumentPatternDto>>(
    params: GetDocumentPatternParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/document-service/document-pattern`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const updateDocumentPattern = <TData = AxiosResponse<void>>(
    documentPatternDto: DocumentPatternDto,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/document-service/document-pattern`, documentPatternDto, options);
};

export const getMkbByFilter = <TData = AxiosResponse<MkbDTO[]>>(params?: GetMkbByFilterParams, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/document-service/mkb`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getOpenContent = <TData = AxiosResponse<Blob>>(
    bucketType: 'DOCUMENT' | 'OPEN_DOCUMENT' | 'AVATAR' | 'VIDEO' | 'LOGOTYPE',
    s3Key: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/document-service/files/download/open/${bucketType}/${s3Key}`, {
        responseType: 'blob',
        ...options,
    });
};

export const getDocumentLinksByEncounter = <TData = AxiosResponse<UserDocumentDTO[]>>(
    encounterFhirId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/document-service/files/encounter/${encounterFhirId}/links`, options);
};

export const getContentInternal = <TData = AxiosResponse<string[]>>(
    bucketType: 'DOCUMENT' | 'OPEN_DOCUMENT' | 'AVATAR' | 'VIDEO' | 'LOGOTYPE',
    s3Key: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/document-service/internal/files/download/${bucketType}/${s3Key}`, options);
};

export const getPresignedURLWithPreNaming = <TData = AxiosResponse<string>>(
    bucketType: 'DOCUMENT' | 'OPEN_DOCUMENT' | 'AVATAR' | 'VIDEO' | 'LOGOTYPE',
    s3Key: string,
    filename: string,
    extension: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/document-service/files/download/presign-url/${bucketType}/${s3Key}/as/${filename}/${extension}`, options);
};

export const safeDocuments = <TData = AxiosResponse<void>>(fileSafeDTO: FileSafeDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/document-service/files/save`, fileSafeDTO, options);
};

export const saveLogotype = <TData = AxiosResponse<void>>(fileSafeDTO: FileSafeDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/document-service/files/logotype`, fileSafeDTO, options);
};

export const savePhoto = <TData = AxiosResponse<void>>(fileSafeDTO: FileSafeDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/document-service/files/avatars`, fileSafeDTO, options);
};

export const saveSignature = <TData = AxiosResponse<void>>(saveSignatureBody: SaveSignatureBody, options?: AxiosRequestConfig): Promise<TData> => {
    const formData = new FormData();
    formData.append('encounterId', saveSignatureBody.encounterId);
    formData.append('file', saveSignatureBody.file);

    return axios.post(`/document-service/document-signature`, formData, options);
};

export const getMkbData = <TData = AxiosResponse<MkbDTO[]>>(params?: GetMkbDataParams, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/document-service/mkb/data`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const deleteDocument = <TData = AxiosResponse<void>>(params: DeleteDocumentParams, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.delete(`/document-service/files-uploader/delete`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const uploadFileFromKurento1 = <TData = AxiosResponse<void>>(
    repositoryItemDto: RepositoryItemDto,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/document-service/files/download-kurento-video`, repositoryItemDto, options);
};

export const getConsultationDischarge = <TData = AxiosResponse<string[]>>(
    createDocumentRequestDTO: CreateDocumentRequestDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/document-service/document-creator/medical-report`, createDocumentRequestDTO, options);
};

export const getPresignedURL = <TData = AxiosResponse<string>>(
    bucketType: 'DOCUMENT' | 'OPEN_DOCUMENT' | 'AVATAR' | 'VIDEO' | 'LOGOTYPE',
    s3Key: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/document-service/files/download/presign-url/${bucketType}/${s3Key}`, options);
};

export const mergeFiles = <TData = AxiosResponse<FineUploaderDTO>>(fileUploadDTO: FileUploadDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/document-service/files-uploader/merge-files`, fileUploadDTO, options);
};

export type UploadFileFromKurentoResult = AxiosResponse<void>;
export type GetDocumentLinksByOrganizationResult = AxiosResponse<UserDirectDocumentDTO[]>;
export type DetachEncounterDocumentsResult = AxiosResponse<void>;
export type UpdateInfoResult = AxiosResponse<FineUploaderDTO>;
export type GetAgentReportResult = AxiosResponse<AgentReportDTO[]>;
export type GetExceptionClientCodesResult = AxiosResponse<ExceptionDefinition[]>;
export type GetDocumentLinksByUserAndFileTypeResult = AxiosResponse<UserDocumentDTO[]>;
export type GetDocumentLinksByUserResult = AxiosResponse<UserDocumentDTO[]>;
export type GetContentResult = AxiosResponse<Blob>;
export type UploadFileResult = AxiosResponse<FineUploaderDTO>;
export type GetDocumentPatternResult = AxiosResponse<DocumentPatternDto>;
export type UpdateDocumentPatternResult = AxiosResponse<void>;
export type GetMkbByFilterResult = AxiosResponse<MkbDTO[]>;
export type GetOpenContentResult = AxiosResponse<Blob>;
export type GetDocumentLinksByEncounterResult = AxiosResponse<UserDocumentDTO[]>;
export type GetContentInternalResult = AxiosResponse<string[]>;
export type GetPresignedURLWithPreNamingResult = AxiosResponse<string>;
export type SafeDocumentsResult = AxiosResponse<void>;
export type SaveLogotypeResult = AxiosResponse<void>;
export type SavePhotoResult = AxiosResponse<void>;
export type SaveSignatureResult = AxiosResponse<void>;
export type GetMkbDataResult = AxiosResponse<MkbDTO[]>;
export type DeleteDocumentResult = AxiosResponse<void>;
export type UploadFileFromKurento1Result = AxiosResponse<void>;
export type GetConsultationDischargeResult = AxiosResponse<string[]>;
export type GetPresignedURLResult = AxiosResponse<string>;
export type MergeFilesResult = AxiosResponse<FineUploaderDTO>;
