import { PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IProtectedRouteProps {
    isAllowed: boolean;
    redirectTo?: string;
}

export const ProtectedRoute = ({ isAllowed: allow, redirectTo: redirectUrl = '/login', children }: PropsWithChildren<IProtectedRouteProps>) => {
    if (!allow) {
        return <Navigate to={redirectUrl} replace />;
    }
    return children ? <>{children}</> : <Outlet />;
};
