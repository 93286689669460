import { ColorControllerBoard, SettingsHelpBlock } from '@components';
import { Button, Modal } from 'antd';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dissociation } from 'src/procedures/Dissociation/Dissociation';
import styles from './ColorSettingsModal.module.scss';

interface IColorSettingProps {
    showModal: boolean;
    onCancel: () => void;
    onSuccess: (value: { red: number; blue: number; green: number }) => void;
    isFirst: boolean;
    colors: { red: number; blue: number; green: number };
}

// const targetProcedure: IGeneralProcedure = {
//     type: ProcedureTypeEnum.BinocularDiagnostic,
//     settings: {
//         visualAcuity: 0.1,
//         image: 'dino',
//         angle: 0,
//         frequency: 1,
//     },
// };

export const ColorSettingsModal = ({ showModal, onCancel, onSuccess, isFirst, colors }: IColorSettingProps) => {
    const { t } = useTranslation();

    const [colorPicker, setColorPicker] = useState<{
        red: number;
        blue: number;
        green: number;
    }>(colors);

    const handleChangeColor = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setColorPicker({ ...colorPicker, [name]: +value });
    };

    const decreaseColorBar = (name: 'red' | 'blue' | 'green') => {
        setColorPicker({ ...colorPicker, [name]: colorPicker[name] - 1 });
    };

    const increaseColorBar = (name: 'red' | 'blue' | 'green') => {
        setColorPicker({ ...colorPicker, [name]: colorPicker[name] + 1 });
    };

    const success = () => {
        onSuccess(colorPicker);
    };

    return (
        <Modal
            width={1000}
            title={t("screen_settings.screen_calibration")}
            open={showModal}
            onCancel={onCancel}
            footer={[
                <SettingsHelpBlock key="support" />,
                <Button onClick={success} key="submit" type="primary">
                    {isFirst ? t('modals.buttons.next') : t('modals.buttons.ready')}
                </Button>,
            ]}
        >
            <div className={`${styles.content} mb-2`}>
                <div className="col-8">
                    <Dissociation colors={colorPicker} />
                </div>
                <div className="col-4">
                    <div className={styles.infoBlock}>
                        <p className={styles.tooltip}>{t('modals.colorSettings.tooltip')}</p>

                        <ul className="mb-5">
                            <li className={styles.list}>{t('modals.colorSettings.rules.first')}</li>
                            <li className={styles.list}>{t('modals.colorSettings.rules.second')}</li>
                            <li className={styles.list}>{t('modals.colorSettings.rules.third')}</li>
                        </ul>
                        <div className="px-2 mt-5">
                            <ColorControllerBoard
                                increaseColorBar={increaseColorBar}
                                decreaseColorBar={decreaseColorBar}
                                handleChangeColor={handleChangeColor}
                                colorPicker={colorPicker}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
