import { IGeneralProcedure } from 'src/models/general-procedure.model';

export const CoursePackage = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type CoursePackageType = typeof CoursePackage[keyof typeof CoursePackage];

export interface IProceduresState {
    activeProcedure: IGeneralProcedure | null;
    activeScreenSharing: { share: boolean };
    awaitingSharingFrom: string | undefined;
    activeScreenSharingParticipantId: string | undefined;
}

export const initialProceduresState: IProceduresState = {
    activeProcedure: null,
    activeScreenSharing: { share: false },
    awaitingSharingFrom: undefined,
    activeScreenSharingParticipantId: undefined,
};
