import {Button, Col, Form, Input, Modal, Row} from "antd";
import styles from './DeliveryAddressModal.module.scss';
import {useTranslation} from "react-i18next";
import {ICurrentProfile, selectAuthUser} from "@sliceUser";
import {useSelector} from "react-redux";
import {CustomPhoneInput} from "@components";
import {GadgetDeliveryAddressDTO, TreatmentKitDTO} from "@api/mainServiceAPI";
import {useEffect} from "react";

export interface IDeliveryAddressModalProps {
    visible: boolean;
    items: TreatmentKitDTO[] | undefined;
    item: TreatmentKitDTO | undefined;
    onClose: () => void;
    onSave: (deliveryAddress: GadgetDeliveryAddressDTO) => void;
}

export const DeliveryAddressModal = ({visible, items, item, onClose, onSave}: IDeliveryAddressModalProps) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();

    const authUser = useSelector(selectAuthUser);

    useEffect(() => {
        if (!visible || !item) {
            return;
        }
        let address = item.gadgetDeliveryAddress;
        if(!address && items) {
           const findPreviousFilledAddress = items.filter(f => f.gadgetDeliveryAddress);
           if(findPreviousFilledAddress && findPreviousFilledAddress.length > 0) {
               address = findPreviousFilledAddress[findPreviousFilledAddress.length - 1].gadgetDeliveryAddress;
           }
        }
        if (address) {
            address.addressComment && form.setFieldValue('addressComment', address.addressComment);
            address.region && form.setFieldValue('region', address.region);
            address.block && form.setFieldValue('block', address.block);
            address.city && form.setFieldValue('city', address.city);
            address.consumerPhone && form.setFieldValue('consumerPhone', address.consumerPhone);
            address.building && form.setFieldValue('building', address.building);
            address.postalCode && form.setFieldValue('postalCode', address.postalCode);
            address.houseNumber && form.setFieldValue('houseNumber', address.houseNumber);
            address.apartmentNumber && form.setFieldValue('apartmentNumber', address.apartmentNumber);
            address.street && form.setFieldValue('street', address.street);
            address.consumerFullName && form.setFieldValue('consumerFullName', address.consumerFullName);
        } else {
            form.setFieldValue('consumerFullName', authUser?.lastName + ' ' + authUser?.firstName + ' ' + authUser?.middleName);
            form.setFieldValue('consumerPhone', authUser?.mobile);
        }
    }, [visible]);

    const clickSave = (e: any) => {
        onSave(e);
        visible = false;
    }

    return visible ? (
        <Modal
            className={styles.supportModal}
            width={592}
            title={t("CreatePatientPage.labels.deliveryAddress")}
            open={visible}
            onCancel={onClose}
            footer={[
                <Button type="default" className="w-100" onClick={onClose} key="0">
                    {t('modals.buttons.cancel')}
                </Button>,
                <Button type="primary" htmlType="submit" onClick={form.submit} className="w-100" key="1">
                    {t('modals.buttons.save')}
                </Button>,
            ]}
        >
            <Form
                form={form}
                onFinish={clickSave}
                name="deliveryAddressModal"
                layout="vertical"
                wrapperCol={{span: 24}}
                initialValues={{
                    addressComment: '',
                    apartmentNumber: '',
                    block: '',
                    building: '',
                    city: '',
                    consumerFullName: '',
                    consumerPhone: '',
                    houseNumber: '',
                    postalCode: '',
                    region: '',
                    street: '',
                }}
                autoComplete="off"
            >
                <Row gutter={24}>
                    <Col md={8} sm={8} xs={8}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="postalCode"
                            rules={[
                                {required: true, message: t('deliveryAddressModal.postalCodeRequired')},
                                {min: 6, message: t('deliveryAddressModal.postalCodeInvalid')},
                                {max: 6, message: t('deliveryAddressModal.postalCodeInvalid')},
                            ]}
                            label={t('deliveryAddressModal.postalCode')}
                        >
                            <Input
                                type="number"
                                maxLength={6}
                                value={form.getFieldValue('postalCode')}
                                onChange={e => form.setFieldsValue({postalCode: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={16} sm={16} xs={16}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="city"
                            rules={[
                                {required: true, message: t('deliveryAddressModal.cityRequired')},
                            ]}
                            label={t('deliveryAddressModal.city')}
                        >
                            <Input
                                value={form.getFieldValue('city')}
                                onChange={e => form.setFieldsValue({city: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="region"
                            rules={[
                                {required: true, message: t('deliveryAddressModal.regionRequired')},
                            ]}
                            label={t('deliveryAddressModal.region')}
                        >
                            <Input
                                value={form.getFieldValue('region')}
                                onChange={e => form.setFieldsValue({region: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={16} sm={16} xs={16}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="street"
                            rules={[
                                {required: true, message: t('deliveryAddressModal.streetRequired')},
                            ]}
                            label={t('deliveryAddressModal.street')}
                        >
                            <Input
                                value={form.getFieldValue('street')}
                                onChange={e => form.setFieldsValue({street: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={8} xs={8}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="houseNumber"
                            rules={[
                                {required: true, message: t('deliveryAddressModal.houseNumberRequired')},
                            ]}
                            label={t('deliveryAddressModal.houseNumber')}
                        >
                            <Input
                                value={form.getFieldValue('houseNumber')}
                                onChange={e => form.setFieldsValue({houseNumber: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={8} sm={8} xs={8}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="building"
                            rules={[
                                //{ required: true, message: t('deliveryAddressModal.buildingRequired') },
                            ]}
                            label={t('deliveryAddressModal.building')}
                        >
                            <Input
                                value={form.getFieldValue('building')}
                                onChange={e => form.setFieldsValue({building: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={8} xs={8}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="block"
                            rules={[
                                //{ required: true, message: t('deliveryAddressModal.blockRequired') },
                            ]}
                            label={t('deliveryAddressModal.block')}
                        >
                            <Input
                                value={form.getFieldValue('block')}
                                onChange={e => form.setFieldsValue({block: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={8} xs={8}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="apartmentNumber"
                            rules={[
                                //{required: true, message: t('deliveryAddressModal.apartmentNumberRequired')},
                            ]}
                            label={t('deliveryAddressModal.apartmentNumber')}
                        >
                            <Input
                                type="number"
                                value={form.getFieldValue('apartmentNumber')}
                                onChange={e => form.setFieldsValue({apartmentNumber: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="addressComment"
                            rules={[
                                //{ required: true, message: t('deliveryAddressModal.commentRequired') },
                            ]}
                            label={t('deliveryAddressModal.addressComment')}
                        >
                            <Input
                                value={form.getFieldValue('addressComment')}
                                onChange={e => form.setFieldsValue({addressComment: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="consumerFullName"
                            rules={[
                                {required: true, message: t('deliveryAddressModal.consumerFullNameRequired')},
                            ]}
                            label={t('deliveryAddressModal.consumerFullName')}
                        >
                            <Input
                                value={form.getFieldValue('consumerFullName')}
                                onChange={e => form.setFieldsValue({consumerFullName: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col md={16} sm={16} xs={16}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="consumerPhone"
                            rules={[
                                {required: true, message: t('deliveryAddressModal.consumerPhoneRequired')},
                            ]}
                            label={t('deliveryAddressModal.consumerPhone')}
                        >
                            <CustomPhoneInput value={form.getFieldValue('consumerPhone')}
                                              onChange={e => form.setFieldsValue({consumerPhone: e.valueOf()})}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={24} sm={24} xs={24}>
                        <span style={{fontSize: '16px'}}>После получения комплекта, пожалуйста,  подтвердите его наличие кнопкой «Подтвердить наличие»</span>
                    </Col>
                </Row>
            </Form>
        </Modal>
    ) : null;
};
