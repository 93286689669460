export enum DynamicsRecommendationsEnum {
    ON_THE_BACKGROUND_OF_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_THE_STOCK_OF_RELATIVE_ACCOMMODATION = 'ON_THE_BACKGROUND_OF_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_THE_STOCK_OF_RELATIVE_ACCOMMODATION',
    ON_THE_BACKGROUND_OF_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_THE_STOCK_OF_RELATIVE_ACCOMMODATION_AND_VISUAL_ACUITY = 'ON_THE_BACKGROUND_OF_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_THE_STOCK_OF_RELATIVE_ACCOMMODATION_AND_VISUAL_ACUITY',
    DURING_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_VISUAL_ACUITY = 'DURING_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_VISUAL_ACUITY',
    DURING_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_OCULOMOTOR_FUNCTIONS = 'DURING_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_OCULOMOTOR_FUNCTIONS',
    DURING_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_OCULOMOTOR_FUNCTIONS_AND_VISUAL_ACUITY = 'DURING_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_OCULOMOTOR_FUNCTIONS_AND_VISUAL_ACUITY',
    DURING_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_BINOCULAR_FUNCTIONS = 'DURING_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_BINOCULAR_FUNCTIONS',
    DURING_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_BINOCULAR_FUNCTIONS_AND_VISUAL_ACUITY = 'DURING_TREATMENT_THERE_IS_A_POSITIVE_TREND_IN_BINOCULAR_FUNCTIONS_AND_VISUAL_ACUITY',
}

export enum LifestyleRecommendationsEnum {
    COMPLIANCE_WITH_THE_VISUAL_REGIMEN_DYNAMIC_OBSERVATION_AFTER_A_YEAR_IN_THE_ABSENCE_OF_COMPLAINTS = 'COMPLIANCE_WITH_THE_VISUAL_REGIMEN_DYNAMIC_OBSERVATION_AFTER_A_YEAR_IN_THE_ABSENCE_OF_COMPLAINTS',
    COMPLIANCE_WITH_THE_REGIME_OF_VISUAL_LOADS = 'COMPLIANCE_WITH_THE_REGIME_OF_VISUAL_LOADS',
    OBSERVANCE_OF_THE_OCCLUSION_MODE = 'OBSERVANCE_OF_THE_OCCLUSION_MODE',
    PERMANENT_SPECTACLE_CORRECTION = 'PERMANENT_SPECTACLE_CORRECTION',
    WEARING_SOFT_CONTACT_CORRECTION = 'WEARING_SOFT_CONTACT_CORRECTION',
}

export enum DynamicObservationEnum {
    DYNAMIC_OBSERVATION_IN_A_YEAR_IN_THE_ABSENCE_OF_COMPLAINTS = 'DYNAMIC_OBSERVATION_IN_A_YEAR_IN_THE_ABSENCE_OF_COMPLAINTS',
    DYNAMIC_OBSERVATION_AFTER_SIX_MONTHS_IN_THE_ABSENCE_OF_COMPLAINTS = 'DYNAMIC_OBSERVATION_AFTER_SIX_MONTHS_IN_THE_ABSENCE_OF_COMPLAINTS',
    DYNAMIC_SURVEILLANCE = 'DYNAMIC_SURVEILLANCE',
}

export enum AdditionalExaminationsEnum {
    RETINAL_OCT = 'RETINAL_OCT',
    EFI_STUDY = 'EFI_STUDY',
    VIZ_STUDY = 'VIZ_STUDY',
    EEG = 'EEG',
    NEUROLOGIST_CONSULTATION = 'NEUROLOGIST_CONSULTATION',
    GOLDMAN_LENS = 'GOLDMAN_LENS',
    A_SCAN = 'A_SCAN',
    B_SCAN = 'B_SCAN',
    COMPUTER_PERIMETRY = 'COMPUTER_PERIMETRY',
    KERATOTOPOGRAPHY = 'KERATOTOPOGRAPHY',
    STUDY_OF_STEREOSCOPIC_VISION = 'STUDY_OF_STEREOSCOPIC_VISION',
    DEPTH_VISION_TEST = 'DEPTH_VISION_TEST',
    STUDY_OF_THE_FUNCTIONAL_STATE_OF_THE_CILIARY_MUSCLE = 'STUDY_OF_THE_FUNCTIONAL_STATE_OF_THE_CILIARY_MUSCLE',
    CHECKING_VISUAL_ACUITY_ACCORDING_TO_THE_ETDRS_TABLE = 'CHECKING_VISUAL_ACUITY_ACCORDING_TO_THE_ETDRS_TABLE',
    ADVANCED_STUDY_OF_BINOCULAR_FUNCTIONS_AND_CYCLOTROPY = 'ADVANCED_STUDY_OF_BINOCULAR_FUNCTIONS_AND_CYCLOTROPY',
    STUDY_OF_OCULOMOTOR_FUNCTIONS_USING_GAZELAB_TECHNOLOGY = 'STUDY_OF_OCULOMOTOR_FUNCTIONS_USING_GAZELAB_TECHNOLOGY',
    GAZELAB_NYSTAGMOGRAPHY = 'GAZELAB_NYSTAGMOGRAPHY',
}

export enum RecommendationsForConsultingAdditionalSpecialistsEnum {
    NEUROLOGIST_CONSULTATION = 'NEUROLOGIST_CONSULTATION',
    CONSULTATION_OF_AZNAURYAN_IE = 'CONSULTATION_OF_AZNAURYAN_IE',
    CONSULTATION_OF_BALASANYAN_VO = 'CONSULTATION_OF_BALASANYAN_VO',
    CONSULTATION_OF_YASNY_VZOR = 'CONSULTATION_OF_YASNY_VZOR',
}
