import { getUser, UpdateUserDTOStatus, UserDTO, UserProfileDTOProfileType } from '@api/userServiceAPI';
import { CalendarAccent, ChevronLeft } from '@icons';
import { Button, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { AccountInfo, ProfileDetails } from './components';
import styles from './HeadPhysicianEmployeePage.module.scss';

export const HeadPhysicianEmployeePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const [openProfile, setOpenProfile] = useState<boolean>(false);
    const [blocked, setBlocked] = useState<boolean>();
    const [selectedEmployee, setSelectedEmployee] = useState<UserDTO>();

    useEffect(() => {
        getUser(params.userId!).then((result) => {
            if (result?.data) {
                setSelectedEmployee(result.data);
                setBlocked(result.data.status == UpdateUserDTOStatus.BLOCKED);
            }
        });
    }, []);

    const hasSchedule = () => {
        switch (getUserProfile()?.profileType) {
            case UserProfileDTOProfileType.Head_physician:
                return true;
            case UserProfileDTOProfileType.Practitioner:
                return true;
            case UserProfileDTOProfileType.Nurse:
                return true;

            default:
                return false;
        }
    };

    const hasProfile = () => {
        switch (getUserProfile()?.profileType) {
            case UserProfileDTOProfileType.Head_physician:
                return true;
            case UserProfileDTOProfileType.Practitioner:
                return true;
            case UserProfileDTOProfileType.Nurse:
                return true;

            default:
                return false;
        }
    };

    const getUserProfile = () => {
        return selectedEmployee?.userProfileDTOList?.filter((profile) => profile.profileType != UserProfileDTOProfileType.End_user)[0];
    };

    const getFullName = () => {
        return selectedEmployee?.lastName + ' ' + selectedEmployee?.firstName + ' ' + (selectedEmployee?.middleName || '');
    };

    const onBackToMainPage = () => {
        navigate(-1);
    };

    const onOpenSchedule = () => {
        navigate('/schedule?employeeId=' + selectedEmployee?.id);
    };

    const onOpenAccountInfo = () => {
        setOpenProfile(false);
    };

    const onOpenProfileDetails = () => {
        setOpenProfile(true);
    };

    return (
        <div className={styles['wrapper']}>
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <Button onClick={() => onBackToMainPage()} className="backBtn">
                        <ChevronLeft className="transparent" />
                    </Button>
                    <p className={styles.title}>{getFullName()}</p>
                </div>
                {hasSchedule() && (
                    <Button onClick={() => onOpenSchedule()}>
                        <CalendarAccent className={styles.calendarIcon} />
                        {t('adminEmployeePage.schedule')}
                    </Button>
                )}
            </div>
            <Row gutter={24}>
                {hasProfile() && (
                    <Col md={6} sm={24} xs={24}>
                        <div className={styles.navigationButtons}>
                            <Button onClick={() => onOpenAccountInfo()} type="ghost" className={!openProfile ? styles.selectedTab : ''}>
                                {t('adminEmployeePage.account')}
                            </Button>
                            <Button onClick={() => onOpenProfileDetails()} type="ghost" className={openProfile ? styles.selectedTab : ''}>
                                {getUserProfile()?.profileType === UserProfileDTOProfileType.Nurse
                                    ? t('adminEmployeePage.nurseProfile')
                                    : t('adminEmployeePage.doctorProfile')}
                            </Button>
                        </div>
                    </Col>
                )}
                {!openProfile ? (
                    <Col md={12} sm={24} xs={24}>
                        <div className={styles.accountBlock}>
                            <AccountInfo selectedEmployee={selectedEmployee} blocked={blocked} />
                        </div>
                    </Col>
                ) : (
                    <Col md={18} sm={24} xs={24}>
                        <div className={styles.accountBlock}>
                            <ProfileDetails employee={selectedEmployee} profile={getUserProfile()} profileType={getUserProfile()?.profileType} />
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
};
