
import styles from './RequisitesStep.module.scss';
import classNames from "classnames";
import { Button, Col, DatePicker, Form, Input, List, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getRequisite, selectRequisite, updateRequisite } from 'src/store/organization';
import { AdminSettingsState,  } from 'src/enums/admin-settings-state.enum';
import { OrganizationCUDTO } from '@api/mainServiceAPI';
import { CustomPhoneInput } from '@components';
import { PHONE_VALIDATION } from '@utils/constants';
import { CalendarInputIcon } from '@icons';
import dayjs from 'dayjs';
import { ISettingsStep } from '../../ClinicAdminSettingsPage';
import { ReadonlyWarning } from '../ReadonlyWarning/ReadonlyWarning';
import {useTranslation} from "react-i18next";

interface ListItem {
    label: string;
    text: string;
}

export const RequisitesStep = ({ isReadonly }: ISettingsStep) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [currentDisplayState, setCurrentDisplayState] = useState<AdminSettingsState>(AdminSettingsState.EMPTY);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const data: OrganizationCUDTO | null = useSelector(selectRequisite);

    useEffect(() => {
        if(data || isReadonly) {
            setCurrentDisplayState(AdminSettingsState.FILL);
        }
    }, [data, isReadonly]);

    const onSubmit = (formData: OrganizationCUDTO) => {
        const dateString = dayjs(formData.licence.date).format('YYYY-MM-DD');
        formData.licence.date = dateString;
        dispatch(updateRequisite(formData));
    }

    const onEdit = () => {
        setCurrentDisplayState(AdminSettingsState.EDIT);
    }

    const onCancel = () => {
        setCurrentDisplayState(AdminSettingsState.FILL);
    }

    const listData: ListItem[] = useMemo(() => {
        if(data) {
            return [
                { label: "clinic_admin_settings_page.requisites_step.full_name", text: data.fullName },
                { label: "clinic_admin_settings_page.requisites_step.short_name", text: data.name },
                { label: "clinic_admin_settings_page.requisites_step.inn", text: data.inn },
                { label: "clinic_admin_settings_page.requisites_step.kpp", text: data.kpp ?? '' },
                { label: "clinic_admin_settings_page.requisites_step.ogrn", text: data.ogrn ?? '' },
                { label: "clinic_admin_settings_page.requisites_step.email", text: data.emails?.length ? data.emails[0] : '' },
                { label: "clinic_admin_settings_page.requisites_step.phone", text: data.phones?.length ? data.phones[0] : '' },
                { label: "clinic_admin_settings_page.requisites_step.legal_address", text: data.address },
                { label: "clinic_admin_settings_page.requisites_step.license_number", text: data.licence?.number ?? '' },
                { label: "clinic_admin_settings_page.requisites_step.license_from", text: data.licence?.date?.toString() ?? '' },
            ];
        }
        return [];
    }, [data])

    const validateLength = (_: any, value: string) => {
        if (value && (value.length === 10 || value.length === 12)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t("clinic_admin_settings_page.requisites_step.10_12_characters")));
    };
    
    const handleFieldsChange = () => {
        const fieldsError = form.getFieldsError();
        const hasErrors = fieldsError.some(field => field.errors.length > 0);
        const allFieldsFilled = form.getFieldsValue();
        const isEmptyField = Object.values(allFieldsFilled).some(value => !value);
    
        setIsFormValid(!hasErrors && !isEmptyField);
    };


    useEffect(() => {
        if (data) {
          const initialValues = {
            fullName: data.fullName || '',
            name: data.name || '',
            inn: data.inn || '',
            kpp: data.kpp || '',
            ogrn: data.ogrn || '',
            phones: data.phones || [],
            emails: data.emails ||  [],
            address: data.address || '',
            licence: {
              number: data.licence?.number || '',
              date: data.licence?.date ? dayjs(data.licence.date) : null,
            },
          };
    
          form.setFieldsValue(initialValues);
        }
    }, [data, form]);

    return (
        <div className={classNames(styles.wrapper)}>
            <h2 className="d-flex gap-3">
                {t("clinic_admin_settings_page.requisites_step.requisites")}
                {
                    currentDisplayState === AdminSettingsState.FILL && !isReadonly ? 
                    <EditOutlined onClick={onEdit} /> : 
                    null
                }
            </h2>
            <div className={styles.content}>
            {
                currentDisplayState === AdminSettingsState.FILL ?
                    <List
                    itemLayout="vertical"
                    dataSource={listData}
                    split={false}
                    renderItem={(item) => (
                        <List.Item>
                            <span className={styles.recordLabel}>{t(item.label)}</span>
                            <p>
                                {item.text}
                            </p>
                        </List.Item>
                    )}
                /> :
                <Form
                    form={form}
                    className={styles.requsitesForm}
                    layout="vertical"
                    onFinish={(value) => onSubmit(value)}
                    onFieldsChange={handleFieldsChange}
                >
                        <Form.Item
                            label={t("clinic_admin_settings_page.requisites_step.full_name_in_reports")}
                            name="fullName"
                            rules={[{ required: true, message: t("required_field") }]}
                        >
                            <TextArea />
                        </Form.Item> 
                        <Form.Item
                            label={t("clinic_admin_settings_page.requisites_step.short_name_on_website")}
                            name="name"
                            rules={[{ required: true, message: t("required_field") }]}
                        >
                            <Input />
                        </Form.Item> 


                        <Row gutter={32}>
                            <Col span={8}>
                                <Form.Item
                                    label={t("clinic_admin_settings_page.requisites_step.inn")}
                                    name="inn"
                                    rules={[
                                        { required: true, message: t("required_field") },
                                        { validator: validateLength }
                                    ]}
                                >
                                    <Input type="number" />
                                </Form.Item> 
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={t("clinic_admin_settings_page.requisites_step.kpp")}
                                    name="kpp"
                                    rules={[
                                        { required: true, message: t("required_field") },
                                        { len: 9, message: t("clinic_admin_settings_page.requisites_step.9_characters") }
                                    ]}
                                >
                                    <Input type="number" />
                                </Form.Item> 
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={t("clinic_admin_settings_page.requisites_step.ogrn")}
                                    name="ogrn"
                                    rules={[
                                        { required: true, message: t("required_field") },
                                        { len: 13, message: t("clinic_admin_settings_page.requisites_step.13_characters") }
                                    ]}
                                >
                                    <Input type="number" />
                                </Form.Item> 
                            </Col>
                        </Row>

                        <Row gutter={32}>
                            <Col span={12}>
                                <Form.Item
                                    name={['phones', 0]}
                                    label={t("clinic_admin_settings_page.requisites_step.phone")}
                                    rules={[
                                        { required: true, message: t("required_field") },
                                        {
                                            pattern: new RegExp(PHONE_VALIDATION),
                                            message: t("clinic_admin_settings_page.requisites_step.invalid_phone_format"),
                                        },
                                    ]}
                                >
                                    <CustomPhoneInput />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['emails', 0]}
                                    label={t("clinic_admin_settings_page.requisites_step.email")}
                                    rules={[
                                        { required: true, message: t("required_field") },
                                        { type: 'email', message: t("clinic_admin_settings_page.requisites_step.invalid_email_format") }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            label={t("clinic_admin_settings_page.requisites_step.legal_address")}
                            name="address"
                            rules={[{ required: true, message: t("required_field") }]}
                        >
                            <Input />
                        </Form.Item> 

                        <Row gutter={32}>
                            <Col span={16}>
                                <Form.Item
                                    label={t("clinic_admin_settings_page.requisites_step.license_number")}
                                    name={['licence', 'number']}
                                    rules={[{ required: true, message: t("required_field") }]}
                                >
                                    <Input />
                                </Form.Item> 
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={t("clinic_admin_settings_page.requisites_step.license_from")}
                                    name={['licence', 'date']}
                                    rules={[{ required: true, message: t("required_field") }]}
                                >
                                    <DatePicker
                                        placeholder={t("clinic_admin_settings_page.requisites_step.date_format")}
                                        suffixIcon={<CalendarInputIcon />}
                                    />
                                </Form.Item> 
                            </Col>
                        </Row>
                        <div className={classNames(styles.actions, 'pt-4')}>
                            <Row justify="space-between" align="middle">
                                <Col className='pt-3 pb-3 pr-3'>
                                    <span>{t("clinic_admin_settings_page.requisites_step.all_fields_required")}</span>
                                </Col>
                                <Col className="d-flex gap-3">
                                    {currentDisplayState === AdminSettingsState.EDIT ? 
                                        <Button type="primary" onClick={onCancel}>
                                            {t("cancel_button")}
                                        </Button> 
                                    : null}
                                    <Button type="primary" htmlType="submit" disabled={!isFormValid}>
                                        {t("save")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                </Form> 
            }
                <div>
                    { 
                        isReadonly ? 
                        <ReadonlyWarning text={t("clinic_admin_settings_page.requisites_step.contact_admin_to_change")} /> :
                        null
                    }
                </div>
            </div>
        </div>
    );
};
