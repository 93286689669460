import { AlertIconOutlined, BellIcon } from '@icons';
import { selectCurrentProfile } from '@sliceUser';
import { StompSubscription } from '@stomp/stompjs';
import { pluralLabel } from '@utils/utils';
import { WsTopic } from '@utils/websocket.topics';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { WebsocketContext } from 'src/contexts/ws.context';
import styles from './ClosestNotification.module.scss';

export const ClosestNotification = () => {
    const { t } = useTranslation();
    const { wsClient } = useContext(WebsocketContext);

    const currentProfile = useSelector(selectCurrentProfile);

    const [closestVisible, setClosestVisible] = useState(true);
    const [countdownLabel, setCountdownLabel] = useState<string>();
    const [nurseMissedVisible, setNurseMissedVisible] = useState(true);
    const [nurseMissedLabel, setNurseMissedLabel] = useState<string>();

    useEffect(() => {
        if (wsClient && !!currentProfile?.fhirId) {
            const subscription: StompSubscription = wsClient.subscribe(WsTopic.notificationsTopic + currentProfile?.fhirId, (message: any) => {
                if (!!message?.body) {
                    const msg = JSON.parse(message.body);
                    console.log(msg);

                    // to HeadPhysician
                    if (msg.type === 'NOT_STARTED_BEFORE_5') {
                        setNurseMissedLabel(t('closestNotification.notStarted.' + msg.payload.serviceType, { name: msg.payload.fullName }));
                        return;
                    }

                    if (msg.type === 'FIVE_MINUTES_BEFORE_START') {
                        setCountdownLabel(
                            t('closestNotification.closest.' + msg.payload.serviceType, { time: 5 }) +
                                ' ' +
                                t("time_format_declension." + pluralLabel(5, ["minute_i", "minute_r","minute_m"])),
                        );
                        return;
                    }
                }
            });

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [wsClient, currentProfile]);

    return (
        <div className={styles.wrapper}>
            {!!countdownLabel && closestVisible ? (
                <div className={styles.notification} onClick={() => setClosestVisible(false)}>
                    <BellIcon />
                    <span>{countdownLabel}</span>
                </div>
            ) : null}

            {!!nurseMissedLabel && nurseMissedVisible ? (
                <div className={styles.notification} onClick={() => setNurseMissedVisible(false)}>
                    <AlertIconOutlined />
                    <span>{nurseMissedLabel}</span>
                </div>
            ) : null}
        </div>
    );
};
