import { secondsAsTime } from '@utils/utils';
import { PropsWithChildren, useEffect, useState } from 'react';

interface ICountdownTimerProps {
    timeX?: Date;

    /**
     * Time in seconds, how many time before alert
     */
    before?: number;
    /**
     * In seconds
     */
    step?: number;

    /**
     * Keep rendering block after time X
     */
    keepAfterX?: boolean;
    render: (timeLabel: string) => void;
    renderAfter?: () => void; // competes with keepAfterX
}

export const CountdownTimer = ({ timeX, step = 1, before, keepAfterX = false, render, renderAfter }: PropsWithChildren<ICountdownTimerProps>) => {
    const [timeLabel, setTimeLabel] = useState('');
    const [timeIsOver, setTimeIsOver] = useState(false);

    useEffect(() => {
        if (!timeX) {
            return;
        }

        const timerHandler = () => {
            const diff = Math.round((timeX.getTime() - new Date().getTime()) / 1000);
            const needToStart = !!before && before > 0 && diff <= before;

            if (needToStart) {
                setTimeLabel(secondsAsTime(diff));
                setTimeIsOver(needToStart);
            }

            if (diff < 0) {
                clearInterval(timer);
                setTimeLabel('');
                setTimeIsOver(keepAfterX || false);
            }
        };

        const timer = setInterval(() => {
            timerHandler();
        }, step * 1000);

        timerHandler(); // for first rendering

        return () => {
            clearInterval(timer);
        };
    }, [timeX]);

    return <>{timeIsOver ? (render ? render(timeLabel) : timeLabel) : !!renderAfter ? renderAfter() : null}</>;
};
