import { ParticipantDTO, UserDocumentDTO, UserDocumentDTOType } from '@api/mainServiceAPI';
import { ProcedureToggleIcon } from '@icons';
import { Collapse } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { PatientDocuments } from 'src/components/PatientDocuments/PatientDocuments';
import { PatientForms } from 'src/components/PatientForms/PatientForms';
import styles from './PatientDocumentsTab.module.scss';
import {useTranslation} from "react-i18next";

interface IPatientDocumentsTabProps {
    patient: ParticipantDTO;
}

const { Panel } = Collapse;

export const PatientDocumentsTab = ({ patient }: IPatientDocumentsTabProps) => {
    const {t} = useTranslation();
    const hasDocuments = useCallback(() => {
        return patient.documents?.some((x) => x.documents?.filter((item) => isDocumentFromPatients(item))?.length);
    }, [patient.documents]);

    const isDocumentFromPatients = (document: UserDocumentDTO): boolean => {
        return (
            document.type === UserDocumentDTOType.DISCHARGE_SUMMARY ||
            document.type === UserDocumentDTOType.CONSULTATION ||
            document.type === UserDocumentDTOType.PERSONAL_DATA_STATEMENT ||
            document.type === UserDocumentDTOType.CERTIFIED_OPHTHALMOLOGISTS_REPORT ||
            document.type === UserDocumentDTOType.ANALYZES ||
            document.type === UserDocumentDTOType.SNAPSHOTS ||
            document.type === UserDocumentDTOType.OTHER
        );
    };

    return (
        <div className={styles.wrapper}>
            {!patient.forms?.length && !hasDocuments() ? (
                <>{t("patient_documents_tab.no_documents")}</>
            ) : (
                <Collapse
                    bordered={false}
                    expandIconPosition="end"
                    defaultActiveKey={['1', '2']}
                    expandIcon={({ isActive }) => (
                        <ProcedureToggleIcon className={classNames(isActive ? styles.active : styles.nonActive)} rotate={isActive ? 0 : 90} />
                    )}
                    className={styles.collapse}
                >
                    {!!patient.forms?.length && (
                        <Panel header={<p className={styles.title}>{t("patient_documents_tab.forms")}</p>} key="1" className={styles.panel}>
                            <PatientForms forms={patient.forms} />
                        </Panel>
                    )}

                    {hasDocuments() && (
                        <Panel className="mt-3" key="2" header={<p className={styles.title}>{t("patient_documents_tab.loaded_documents")}</p>}>
                            <div className={styles.listWrapper}>
                                {patient.documents?.map((encounter, i) => {
                                    if (encounter.documents?.filter((item) => isDocumentFromPatients(item)).length) {
                                        return (
                                            <div key={'encounter' + i}>
                                                <span className={styles.uploadLabel}>
                                                    {t("patient_documents_tab.loaded_for_appointment")} {dayjs(encounter.encounterDateTime).format('DD.MM.YYYY')}
                                                </span>
                                                <PatientDocuments documents={encounter.documents} />
                                            </div>
                                        );
                                    } else {
                                        null;
                                    }
                                })}
                            </div>
                        </Panel>
                    )}
                </Collapse>
            )}
        </div>
    );
};
