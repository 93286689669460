import { ChangeEvent } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import 'react-phone-input-2/lib/bootstrap.css';

interface ICustomPhoneInputProps {
    value?: string;
    disabled?: boolean;
    onChange?: (value: string, data: object | CountryData, event: ChangeEvent<HTMLInputElement>, formattedValue: string) => void;
}

export const CustomPhoneInput = ({ value, disabled, onChange }: ICustomPhoneInputProps) => {
    return <PhoneInput country="ru" onlyCountries={['ru', 'kz', 'by', 'am']} localization={ru} value={value} onChange={onChange} disabled={disabled} />;
};
