import { GetPatientsParams, PatientBaseDTO, PatientDetailsDTO, getPatients } from '@api/mainServiceAPI';
import { SearchIcon } from '@icons';
import { selectCurrentProfile } from '@sliceUser';
import {calculateAge, pluralLabel} from '@utils/utils';
import { Checkbox, Col, Input, Row, Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { FilterValue } from 'antd/es/table/interface';
import classNames from 'classnames';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './HeadPhysicianPatientsPage.module.scss';

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

export const HeadPhysicianPatientsPage = () => {
    const navigate = useNavigate();
    const currentProfile = useSelector(selectCurrentProfile);

    const [filterQuery, setFilterQuery] = useState<string | undefined>();
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => <p>{t("head_physician_patients.total") + ' ' + total}</p>,
            position: ['bottomCenter'],
        },
    });

    const [filteredPatientList, setFilteredPatientList] = useState<PatientDetailsDTO[]>();

    const [filterOnlyMine, setFilterOnlyMine] = useState<boolean>(false);
    const [filterFirstEvents, setFilterFirstEvents] = useState<boolean>(false);

    useEffect(() => {
        getPatients({
            page: tableParams.pagination?.current && tableParams.pagination?.current - 1,
            size: tableParams.pagination?.pageSize,
            lastName: filterQuery === '' ? undefined : filterQuery,
            supervisingPractitionerId: filterOnlyMine ? currentProfile?.fhirId : undefined,
            sortByEvent: filterFirstEvents,
        }).then((result) => {
            const patients = result.data.content;
            if (patients) {
                setFilteredPatientList(patients);
                setTableParams({
                    pagination: { ...tableParams.pagination, total: result.data.totalElements },
                });
            }
        });
    }, [filterQuery, filterOnlyMine, filterFirstEvents]);

    const patientsTableColumns: ColumnsType<PatientBaseDTO> = [
        {
            title: t('patients.table.patient'),
            dataIndex: ['firstName', 'lastName', 'middleName'],
            render: (text, record) => {
                let result = '';
                if (record.lastName) {
                    result += record.lastName;
                }
                if (record.firstName) {
                    result += ' ' + record.firstName;
                }
                if (record.middleName) {
                    result += ' ' + record.middleName;
                }
                return (
                    <Row align="middle" gutter={16}>
                        <Col span={3}>
                            <div className={classNames(record.event ? styles.red : styles.default)} />
                        </Col>
                        <Col span={21}>{result || '-'}</Col>
                    </Row>
                );
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.lastName) {
            //         return 1;
            //     }
            //     if (!b.lastName) {
            //         return -1;
            //     }
            //     if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'name',
            width: 300,
        },
        {
            title: t('patients.table.age'),
            dataIndex: 'birthDate',
            render: (text, record) => {
                if (record.birthDate) {
                    const age = calculateAge(record.birthDate);
                    const result = age + ' ' + t("date_format_declension." + pluralLabel(age, ["year_i", "year_r", "year_m"]));
                    return (
                        <div className="d-flex justify-content-between align-items-center">
                            <p className={styles.birthDay}>{result}</p>
                        </div>
                    );
                }
                return '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (a.birthDate) {
            //         return 1;
            //     }
            //     if (b.birthDate) {
            //         return -1;
            //     }

            //     return -1;
            // },
            key: 'age',
        },
        {
            title: t('patients.table.practitioner'),
            dataIndex: 'doctorName',
            render: (text, record) => {
                if (record.doctorName) {
                    return (
                        <div className="d-flex justify-content-between align-items-center">
                            <p className={styles.birthDay}>{record.doctorName}</p>
                        </div>
                    );
                }
                return '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.doctorName) {
            //         return -1;
            //     }
            //     if (!b.doctorName) {
            //         return 1;
            //     }

            //     return a.doctorName.toLowerCase() > b.doctorName.toLowerCase() ? 1 : -1;
            // },
            key: 'practitioner',
        },
    ];

    const handleTableChange: TableProps<PatientBaseDTO>['onChange'] = (pagination, filters, sorter) => {
        const searchCriteria: GetPatientsParams = {
            page: pagination?.current && pagination?.current - 1,
            size: pagination?.pageSize,
            lastName: filterQuery,
            supervisingPractitionerId: filterOnlyMine ? currentProfile?.fhirId : undefined,
            sortByEvent: filterFirstEvents,
        };

        if (sorter) {
            searchCriteria.sortField = (sorter as any).field;
            searchCriteria.sortDirection = (sorter as any).order === 'ascend' ? 'ASC' : 'DESC';
        }

        getPatients(searchCriteria).then((result) => {
            const patients = result.data.content;
            if (patients) {
                setFilteredPatientList(patients);
                setTableParams({
                    pagination: { ...pagination, total: result.data.totalElements },
                });
            }
        });
    };

    const openPatientPage = (id: string) => {
        navigate(`/medcard/${id}`);
    };

    return (
        <div className={styles.wrapper}>
            <h1>{t("patients.title")}</h1>
            <div className={styles.filterBlock}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Input
                            value={filterQuery}
                            placeholder={t("head_physician_patients.search_by_lastname")}
                            prefix={<SearchIcon />}
                            onChange={(e) => setFilterQuery(e.target.value)}
                        />
                    </Col>
                    <Col span={8} className="d-flex align-items-center">
                        <Checkbox type="checkbox" className="m-0 me-3" onChange={(e) => setFilterOnlyMine(e.target.checked)}>
                            <p className={styles['filter-label']}>{t('patients.filters.onlyMine')}</p>
                        </Checkbox>
                        <Checkbox type="checkbox" className="m-0 me-3" onChange={(e) => setFilterFirstEvents(e.target.checked)}>
                            <p className={styles['filter-label']}>{t('patients.filters.firstEvents')}</p>
                        </Checkbox>
                    </Col>
                </Row>
                <div className="mt-4">
                    <Table
                        className={styles.patientRow}
                        rowKey={(record) => record.id!}
                        columns={patientsTableColumns}
                        dataSource={filteredPatientList}
                        pagination={tableParams.pagination}
                        onChange={handleTableChange}
                        showSorterTooltip={false}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    openPatientPage(record.id!);
                                },
                            };
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
