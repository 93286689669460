import { FileSafeDTOBucketType } from '@api/documentServiceAPI';
import { UserDocumentDTO } from '@api/mainServiceAPI';
import { FileIcon } from '@icons';
import { setFileViewer } from '@sliceCore';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import styles from './PatientDocuments.module.scss';

interface IPatientDocumentsProps {
    documents?: UserDocumentDTO[];
    unchecked?: boolean;
}

export const PatientDocuments = ({ documents, unchecked: checked }: IPatientDocumentsProps) => {
    const dispatch = useDispatch();

    // TODO workaround to remove later:
    const openSomeDocument = (doc: UserDocumentDTO & { s3Key?: string }, force = false, defaultFileName?: string) => {
        const key = doc.s3key || doc.s3Key;
        const fName = doc.name || `${defaultFileName ? defaultFileName + '-' : ''}${dayjs().format('DD-MM-YYYY')}`;

        if (key) {
            dispatch(
                setFileViewer({
                    s3Key: key,
                    bucketType: doc.type === FileSafeDTOBucketType.VIDEO ? FileSafeDTOBucketType.VIDEO : FileSafeDTOBucketType.DOCUMENT,
                    fileName: doc.extension ? `${fName}.${doc.extension}` : fName,
                    extension: doc.extension,
                    forceDownload: force,
                }),
            );
        }
    };

    return (
        <>
            {documents?.map((doc, i) => (
                <div
                    key={'document' + i}
                    className={classNames(styles['document-container'], checked && styles['bg-blue'])}
                    title={doc.name}
                    onClick={() => openSomeDocument(doc, false)}
                >
                    <FileIcon />
                    <p className={styles['document-name']}>{t('appointment.documentTypes.' + doc.type)}</p>
                </div>
            ))}
        </>
    );
};
