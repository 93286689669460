import { DURATION_LIST_SECONDS } from '@configProcedures';
import { ProcedureTypeEnum } from '@enums';
import { IParticipantProcedure } from '@models';
import { FREQUENCY_4HZ } from '../../../PROCEDURES_CONFIG';

export const ParticipantMockProcedures: IParticipantProcedure[] = [
    {
        id: 'test65',
        order: 4,
        type: ProcedureTypeEnum.AVETISOV_MATS,
        settings: {
            fontSize: 12,
            textType: 'arbuz',
            duration: DURATION_LIST_SECONDS[0],
        },
    },

    {
        id: 'test1',
        order: 5,
        type: ProcedureTypeEnum.MERIDIONAL_PATTERN,
        settings: {
            visualAcuity: 0.1,
            // image: 'dino',
            frequency: 1,
            duration: DURATION_LIST_SECONDS[0],
            angle: 0,
        },
    },
    {
        id: 'test2',
        order: 6,
        type: ProcedureTypeEnum.CHESS_PATTERN,
        settings: {
            visualAcuity: 0.1,
            // image: 'dino',
            frequency: 1,
            duration: DURATION_LIST_SECONDS[0],
        },
    },
    {
        id: 'test3',
        order: 7,
        type: ProcedureTypeEnum.MAKULOSTIMULATION,
        settings: {
            color: '#ff0000',
            duration: DURATION_LIST_SECONDS[0],
        },
    },
    {
        id: 'test4',
        order: 8,
        type: ProcedureTypeEnum.PLEOPTIC_SPIRALS,
        settings: {
            duration: DURATION_LIST_SECONDS[0],
        },
    },
    {
        id: 'test5',
        order: 9,
        type: ProcedureTypeEnum.PLEOPTIC_CROSS,
        settings: {
            visualAcuity: 0.2,
            duration: DURATION_LIST_SECONDS[0],
        },
    },
    {
        id: 'test6',
        order: 10,
        type: ProcedureTypeEnum.BIFIXATION_RECOVERY,
        settings: {
            duration: 3,
        },
    },
    {
        id: 'test7',
        order: 11,
        type: ProcedureTypeEnum.FLASHES,
        settings: {
            frequency: FREQUENCY_4HZ[1],
            duration: DURATION_LIST_SECONDS[0],
            color: '#ff0000',
        },
    },
    {
        id: 'test8',
        order: 12,
        type: ProcedureTypeEnum.AMBLYOPIA,
        settings: {
            amblyopia: 'left',
            duration: DURATION_LIST_SECONDS[3],
        },
    },
    {
        id: 'test9',
        order: 13,
        type: ProcedureTypeEnum.DISSOCIATION,
        settings: {
            duration: DURATION_LIST_SECONDS[3],
        },
    },
];
