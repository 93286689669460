import { SlotDTO } from '@api/mainServiceAPI';
import { dateForServer } from '@utils/utils';
import { Button, DatePicker, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import styles from './RemoveScheduleSlotModal.module.scss';
import {useTranslation} from "react-i18next";

interface IRemoveScheduleModalProps {
    showModal: boolean;
    onSuccess: (startDate: number, endDate: number) => void;
    onCancel?: () => void;
    slots: SlotDTO[];
    selectedDate: Dayjs | null;
}

const { RangePicker } = DatePicker;

const dateFormat = 'DD.MM.YYYY';

export const RemoveScheduleSlotModal = ({ showModal, onSuccess, onCancel, slots, selectedDate }: IRemoveScheduleModalProps) => {
    const {t} = useTranslation();
    const [chooseDate, setChooseDate] = useState<Dayjs | null>();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [canRemoveSlot, setCanRemoveSlot] = useState<boolean>(true);
    const [startDateRangeDate, setStartRangeDate] = useState<number>();
    const [endDateRangeDate, setEndRangeDate] = useState<number>();

    useEffect(() => {
        if (dayjs(selectedDate!).isAfter(dayjs()) || dayjs(dayjs(selectedDate!).date()).isSame(dayjs(dayjs().date()))) {
            const filterSlots = slots.filter((slot) => {
                if (dayjs(dayjs(selectedDate!).date()).isSame(dayjs(dayjs().date()))) {
                    return (
                        dayjs(slot.startDateTime).format('YYYY.MM.DD') === dayjs(selectedDate).format('YYYY.MM.DD') &&
                        dayjs(slot.startDateTime).isAfter(dayjs())
                    );
                } else {
                    return dayjs(slot.startDateTime).format('YYYY.MM.DD') === dayjs(selectedDate).format('YYYY.MM.DD');
                }
            });
            if (filterSlots.length > 0) {
                setStartRangeDate(filterSlots[0].startDateTime);
                setEndRangeDate(filterSlots[filterSlots.length - 1].endDateTime);
            }

            setIsEmpty(filterSlots.length === 0);
            setChooseDate(selectedDate);
            setIsBusy(filterSlots.some((slot) => slot.status === 'busy' || slot.status === 'busy-tentative'));
            setCanRemoveSlot(true);
        } else {
            setCanRemoveSlot(false);
        }
    }, [selectedDate]);

    const handleDateChange = (e: any) => {
        const startDate = parseDateTimes(e[0], dayjs());
        setStartRangeDate(e[0]);
        setEndRangeDate(e[1]);
        const endDate = parseDateTimes(e[1], dayjs(), true);
        setStartRangeDate(dateForServer(startDate, true, true));
        setEndRangeDate(dateForServer(endDate, false, true));
        if (e[0].isAfter(dayjs()) || dayjs(e[0].date()).isSame(dayjs(dayjs().date()))) {
            setCanRemoveSlot(true);
        } else {
            setCanRemoveSlot(false);
        }
    };

    const handleRemoveSlots = () => {
        onSuccess(startDateRangeDate!, endDateRangeDate!);
    };

    return (
        <Modal
            width={800}
            title={t("remove_schedule_slot_modal.remove_appointments")}
            open={showModal}
            onCancel={onCancel}
            footer={[
                <div className="d-flex flex-column align-items-start" key="empty">
                    <p className={styles.callCenter}>{t("remove_schedule_slot_modal.call_center_phone")}</p>
                    <p className={styles.phoneNumber}>{t("remove_schedule_slot_modal.call_center_number")}</p>
                </div>,
                <div className="d-flex" key="actions">
                    <Button onClick={onCancel}>{t("cancel_button")}</Button>
                    <Button
                        disabled={isEmpty}
                        type="primary"
                        onClick={() => {
                            if (canRemoveSlot) {
                                handleRemoveSlots();
                            } else {
                                null;
                            }
                        }}
                    >
                        {t("remove_schedule_slot_modal.remove_appointments_without_records")}
                    </Button>
                </div>,
            ]}
        >
            <div className="mb-5">
                <p className={styles.label}>{t("remove_schedule_slot_modal.period")}</p>
                <RangePicker defaultValue={[selectedDate, selectedDate]} format={dateFormat} onChange={handleDateChange} popupClassName="topIndex" />
            </div>
            <p className={styles.description}>{t("remove_schedule_slot_modal.confirmation_message")}:</p>
            <p className={styles.date}>
                {startDateRangeDate
                    ? `${dayjs(startDateRangeDate).format('DD.MM.YYYY')} - ${dayjs(endDateRangeDate).format('DD.MM.YYYY')}`
                    : dayjs(chooseDate).format('DD.MM.YYYY')}
            </p>
            {!canRemoveSlot && <p className={styles.warning}>{t("remove_schedule_slot_modal.cannot_delete_past_appointments")}</p>}
            {canRemoveSlot && isBusy && (
                <p className={styles.warning}>
                    {t("remove_schedule_slot_modal.some_appointments_have_patients")}
                </p>
            )}
            {canRemoveSlot && isEmpty && <p className={styles.warning}>{t("remove_schedule_slot_modal.empty_events")}</p>}
        </Modal>
    );
};

const parseDateTimes = (date: Dayjs, time: Dayjs, isEnd?: boolean) => {
    if (isEnd) {
        return date.hour(23).minute(59).toDate();
    } else {
        return date.hour(0).minute(0).toDate();
    }
};
