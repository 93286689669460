import { Dino5 } from '@icons';
import { Button, Col, Row } from 'antd';
import classNames from 'classnames';
import styles from './PatientNotificationBanner.module.scss';

export interface IPatientNotificationBannerProps {
    title: string;
    description: string;
    btnLabel?: string;
    btnHandler?: () => void;
}

export const PatientNotificationBanner = ({ title, description, btnLabel, btnHandler }: IPatientNotificationBannerProps) => {
    return (
        <div className={classNames(styles.consultationBlock, styles.yellowBlock)}>
            <Dino5 className={styles.dino5} />
            <Row justify="space-between" align="middle" gutter={40}>
                <Col lg={16} md={14} sm={24}>
                    <h4 className="mb-3">{title}</h4>
                    <p>{description}</p>
                </Col>
                <Col lg={8} md={10} sm={24} onClick={btnHandler}>
                    <Button type="primary" className="mb-4 mt-4 w-100">
                        {btnLabel}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
