import { ThemeConfig } from 'antd/es/config-provider/context';

const COLOR_PRIMARY = '#235ABE';
const COLOR_BORDER = '#C3CCD6';
const COLOR_TEXT = '#505862';
const COLOR_ERROR = '#F64D4D';
const COLOR_BG_BASE = '#EFF2F5';

export const theme: ThemeConfig = {
    token: {
        colorPrimary: COLOR_PRIMARY,
        borderRadius: 8,
        colorBorder: COLOR_BORDER,
        fontFamily:
            "Ubuntu, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', -apple-system, BlinkMacSystemFont,",
        colorText: COLOR_TEXT,
        colorError: COLOR_ERROR,
    },
    components: {
        Input: {
            fontSize: 16,
            controlHeight: 48,
        },
        DatePicker: {
            fontSize: 16,
            controlHeight: 48,
        },
        InputNumber: {
            fontSize: 16,
            controlHeight: 48,
        },
        Select: {
            fontSize: 16,
            controlHeight: 48,
        },
        Button: {
            fontSize: 16,
            controlHeight: 48,
            colorText: COLOR_PRIMARY,
            colorBgContainer: COLOR_BG_BASE,
            colorBorder: 'none',
            fontSizeLG: 24,
            controlHeightLG: 64,
        },
    },
};
