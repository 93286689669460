import { CarePlanDTO, ConsultationAppointmentDetailsDTO, PatientEncounterDTO } from '@api/mainServiceAPI';
import { CreateUserProfileDTO } from '@api/userServiceAPI';
import { OrderTypes } from '@utils/constants';
import { LSScreenSettingsPatientSettings } from 'src/services';

export interface CreateUserProfileDTOExt extends CreateUserProfileDTO {
    oms?: string;
    omsFile?: string;
}

export interface IEntityToCheckPayment {
    id?: string | null;
    orderType: OrderTypes;
}

export interface IPatientState {
    carePlan: CarePlanDTO | null;
    carePlanEncounters: PatientEncounterDTO[] | null;
    activeConsultations: ConsultationAppointmentDetailsDTO[] | null;
    workspacePrepared: boolean;
    workspaceSettings: LSScreenSettingsPatientSettings | null;
    entityToCheckPayment: IEntityToCheckPayment | null;
}

export const initialPatientState: IPatientState = {
    carePlan: null,
    carePlanEncounters: null,
    activeConsultations: null,
    workspacePrepared: false,
    workspaceSettings: null,
    entityToCheckPayment: null,
};
