import { Button, Col, Form, Input, Row } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminSettingsState } from "src/enums/admin-settings-state.enum";
import { EditOutlined } from '@ant-design/icons';
import { selectConsultationPrices, updateConsultationPrices } from "src/store/organization";
import styles from './ConsultationsStep.module.scss';
import { PractitionerProductPriceDTO, PractitionerProductPriceDTOAppointmentType, PractitionerProductPriceDTOPriceCategoryType } from "@api/mainServiceAPI";
import { ISettingsStep } from "../../ClinicAdminSettingsPage";
import { ReadonlyWarning } from "../ReadonlyWarning/ReadonlyWarning";
import {useTranslation} from "react-i18next";

const CONSULTATION_OPTIONS = [
    {
        text: 'clinic_admin_settings_page.consultations_step.first',
        category: PractitionerProductPriceDTOPriceCategoryType.FIRST_CATEGORY
    },
    {
        text: 'clinic_admin_settings_page.consultations_step.second',
        category: PractitionerProductPriceDTOPriceCategoryType.SECOND_CATEGORY
    },
    {
        text: 'clinic_admin_settings_page.consultations_step.third',
        category: PractitionerProductPriceDTOPriceCategoryType.THIRD_CATEGORY
    },
    {
        text: 'clinic_admin_settings_page.consultations_step.fourth',
        category: PractitionerProductPriceDTOPriceCategoryType.FOURTH_CATEGORY
    },
    {
        text: 'clinic_admin_settings_page.consultations_step.fifth',
        category: PractitionerProductPriceDTOPriceCategoryType.FIFTH_CATEGORY
    }
];

export const ConsultationsStep = ({ isReadonly }: ISettingsStep) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [currentDisplayState, setCurrentDisplayState] = useState<AdminSettingsState>(AdminSettingsState.EMPTY);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const consultations = useSelector(selectConsultationPrices);

    useEffect(() => {
        if(consultations.length || isReadonly) {
            setCurrentDisplayState(AdminSettingsState.FILL);
        }
    }, [consultations, isReadonly]);

    const onSubmit = (values: any) => {
        const resultArr: PractitionerProductPriceDTO[] = [];
        values.items.forEach((el: any) => {
            resultArr.push({
                priceCategoryType: el.priceCategoryType,
                appointmentType: PractitionerProductPriceDTOAppointmentType.initial_consultation,
                price: parseInt(el[PractitionerProductPriceDTOAppointmentType.initial_consultation]),
                currency:"RUB"
            });
            resultArr.push({
                priceCategoryType: el.priceCategoryType,
                appointmentType: PractitionerProductPriceDTOAppointmentType.repeated_consultation,
                price: parseInt(el[PractitionerProductPriceDTOAppointmentType.repeated_consultation]),
                currency:"RUB"
            });
        });

        dispatch(updateConsultationPrices(resultArr));
    }

    const onEdit = () => {
        setCurrentDisplayState(AdminSettingsState.EDIT);
    }

    const onCancel = () => {
        setCurrentDisplayState(AdminSettingsState.FILL);
    }

    const handleFieldsChange = () => {
        const fieldsError = form.getFieldsError();
        const hasErrors = fieldsError.some(field => field.errors.length > 0);
        const allFieldsFilled = form.getFieldsValue();
        const isEmptyField = Object.values(allFieldsFilled).some(value => !value);

        setIsFormValid(!hasErrors && !isEmptyField);
    };

    const initValues = useMemo(() => {
        return CONSULTATION_OPTIONS.map((option) => {
            const foundElementsByType = consultations.filter((cons) => cons.priceCategoryType === option.category);
            const initialPrice = foundElementsByType.find((el) => el.appointmentType === PractitionerProductPriceDTOAppointmentType.initial_consultation)?.price;
            const repeatedPrice = foundElementsByType.find((el) => el.appointmentType === PractitionerProductPriceDTOAppointmentType.repeated_consultation)?.price;

            return {
                priceCategoryType: option.category,
                'initial_consultation': initialPrice,
                'repeated_consultation' : repeatedPrice
            }
        });
    }, [consultations]);

    const isFillMode = currentDisplayState === AdminSettingsState.FILL;

    return (
        <div className={classNames(styles.wrapper)}>
            <h2 className="d-flex gap-3">
                {t("clinic_admin_settings_page.consultations_step.consultation_costs")}
                {
                    currentDisplayState === AdminSettingsState.FILL && !isReadonly ? 
                    <EditOutlined onClick={onEdit} /> : 
                    null
                }
            </h2>
            <div className={styles.content}>
                {
                    <Form
                        form={form}
                        className={classNames(styles.consultationsForm, isFillMode ? 'filled' : '')}
                        layout="horizontal"
                        onFinish={(value) => onSubmit(value)}
                        onFieldsChange={handleFieldsChange}
                        initialValues={{items: initValues}}
                    > 
                        <Row gutter={32} className={styles.consultationRow} style={{ border: 'none'}}>
                            <Col>
                                <div className={styles.categoryColumn}>
                                    <span>{t("clinic_admin_settings_page.consultations_step.category")}</span>
                                </div>
                            </Col>
                            <Col>
                                <div className={styles.pricesWrapper}>
                                    <div className={styles.priceColumn} style={!isFillMode ? {textAlign: 'unset'}: {}}>
                                        <span >{t("clinic_admin_settings_page.consultations_step.initial_appointment")}</span>
                                    </div>
                                    <div className={styles.priceColumn} style={!isFillMode ? {textAlign: 'unset'}: {}}>
                                        <span >{t("clinic_admin_settings_page.consultations_step.follow_up_appointment")}</span>
                                    </div>

                                </div>
                            </Col>
                        </Row>

                        {
                            CONSULTATION_OPTIONS.map((option, index) => 
                                <Row key={option.category} gutter={40} className={styles.consultationRow}>
                                    <Col>
                                        <div style={isFillMode ? {width: '192px'} : { width: '200px'}}>
                                            <span className={styles.consultationTitle} >{t(option.text)}</span>
                                        </div>    
                                        <Form.Item
                                            className={styles.priceField}
                                            name={['items', index, 'priceCategoryType']}
                                            initialValue={option.category}
                                            hidden
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <div className={styles.pricesWrapper}>
                                            <Form.Item 
                                                className={styles.priceField} 
                                                name={['items', index, PractitionerProductPriceDTOAppointmentType.initial_consultation]}
                                                rules={[{ required: true, message: t("required_field") }]}
                                            >
                                                    {isFillMode ? 
                                                    <div className={styles.filledPrice}>
                                                        <span>{form.getFieldValue(['items', index, PractitionerProductPriceDTOAppointmentType.initial_consultation])} ₽</span>
                                                    </div>
                                                    :
                                                    <Input
                                                        type={'number'}
                                                        min={1}
                                                        step={0.01}
                                                        max={9999999}
                                                        suffix={<span className={styles.rubIcon}>₽</span>}
                                                    />
                                                    }
                                            </Form.Item>
                                            <Form.Item 
                                                className={styles.priceField} 
                                                name={['items', index, PractitionerProductPriceDTOAppointmentType.repeated_consultation]}
                                                rules={[{ required: true, message: t("required_field") }]}
                                            >
                                                    {isFillMode ?
                                                    <div className={styles.filledPrice}>
                                                        <span>{form.getFieldValue(['items', index, PractitionerProductPriceDTOAppointmentType.repeated_consultation])} ₽</span>
                                                    </div> :
                                                    <Input
                                                        type={'number'}
                                                        min={1}
                                                        step={0.01}
                                                        max={9999999}
                                                        suffix={<span className={styles.rubIcon}>₽</span>}
                                                    />
                                                    }
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        }
                        {
                            !isFillMode && <div className={classNames(styles.actions, 'pt-4')}>
                                <Row justify="space-between" align="middle">
                                    <Col className={classNames(styles.footerLabel, 'pt-3', 'pb-3', 'pr-3')}>
                                        <span>{t("clinic_admin_settings_page.consultations_step.all_consultation_costs_required")}</span>
                                    </Col>
                                    <Col className="d-flex gap-3">
                                        {currentDisplayState === AdminSettingsState.EDIT ? 
                                            <Button type="primary" onClick={onCancel}>
                                                {t("cancel_button")}
                                            </Button> 
                                        : null}
                                        <Button type="primary" htmlType="submit" disabled={!isFormValid}>
                                            {t("save")}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Form>  
                }
                <div>
                    { 
                        isReadonly ? 
                        <ReadonlyWarning text={t("clinic_admin_settings_page.consultations_step.costs_defined_by_clinic")} /> :
                        null
                    }
                </div>
            </div>
        </div>
    );
};