import { Button, Col, Row } from 'antd';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ColorControllerBoard.module.scss';

interface IColorControllerBoardProps {
    handleChangeColor: (e: ChangeEvent<HTMLInputElement>) => void;
    increaseColorBar: (name: 'red' | 'blue' | 'green') => void;
    decreaseColorBar: (name: 'red' | 'blue' | 'green') => void;
    colorPicker?: {
        red: number;
        blue: number;
        green: number;
    };
    onSaveColor?: (colorsPicker?: { red: number; blue: number; green: number }) => void;
}

export const ColorControllerBoard = ({
    handleChangeColor,
    increaseColorBar,
    decreaseColorBar,
    colorPicker,
    onSaveColor,
}: IColorControllerBoardProps) => {
    const { t } = useTranslation();
    return (
        <div className="w-100">
            <Row gutter={12} justify="space-between" className="mb-3">
                <Col span={7}>
                    <p className={styles['color-label']}>{t('dictionaries.colors.red')}</p>
                </Col>
                <Col span={17}>
                    <Row justify="space-between" align="middle">
                        <Col span={3}>
                            <button className={styles['control-button']} onClick={() => decreaseColorBar('red')}>
                                -
                            </button>
                        </Col>
                        <Col span={18}>
                            <input
                                className={styles.redInput}
                                max={255}
                                min={0}
                                step={1}
                                name="red"
                                value={colorPicker?.red}
                                onChange={handleChangeColor}
                                type="range"
                            />
                        </Col>
                        <Col span={3}>
                            <button className={styles['control-button']} onClick={() => increaseColorBar('red')}>
                                +
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={12} justify="space-between" className="mb-3">
                <Col span={7}>
                    <p className={styles['color-label']}>{t('dictionaries.colors.green')}</p>
                </Col>
                <Col span={17}>
                    <Row justify="space-between" align="middle">
                        <Col span={3}>
                            <button className={styles['control-button']} onClick={() => decreaseColorBar('green')}>
                                -
                            </button>
                        </Col>
                        <Col span={18}>
                            <input
                                className={styles.greenInput}
                                max={255}
                                min={0}
                                step={1}
                                name="green"
                                value={colorPicker?.green}
                                onChange={handleChangeColor}
                                type="range"
                            />
                        </Col>
                        <Col span={3}>
                            <button className={styles['control-button']} onClick={() => increaseColorBar('green')}>
                                +
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={12} justify="space-between">
                <Col span={7}>
                    <p className={styles['color-label']}>{t('dictionaries.colors.blue')}</p>
                </Col>
                <Col span={17}>
                    <Row justify="space-between" align="middle">
                        <Col span={3}>
                            <button className={styles['control-button']} onClick={() => decreaseColorBar('blue')}>
                                -
                            </button>
                        </Col>
                        <Col span={18}>
                            <input
                                className={styles.blueInput}
                                max={255}
                                min={0}
                                step={1}
                                name="blue"
                                value={colorPicker?.blue}
                                onChange={handleChangeColor}
                                type="range"
                            />
                        </Col>
                        <Col span={3}>
                            <button className={styles['control-button']} onClick={() => increaseColorBar('blue')}>
                                +
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {onSaveColor && (
                <Button className={styles.readyButton} onClick={() => onSaveColor(colorPicker)} type="primary">
                    {t("finish_button")}
                </Button>
            )}
        </div>
    );
};
