import {
    CourseDTO,
    CourseDTOId,
    CreateScheduleDTOPlanIdsItem,
    CreateScheduleDTOServiceTypeItem,
    CreateScheduleDTOWeekDaysItem,
    createSchedule,
    getCourses,
} from '@api/mainServiceAPI';
import { dateForServer } from '@utils/utils';
import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, Row, Select, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AddEventToScheduleModal.module.scss';

interface IAddEventToScheduleModalProps {
    showModal: boolean;
    onSuccess: () => void;
    onCancel?: () => void;
    onError?: (startTime: number, endTime: number) => void;
    practitionerId: string;
    isNurse: boolean;
}

interface FormValues {
    dateRange: dayjs.Dayjs[];
    dayType: 'everyday' | 'evenOdd' | 'week';
    evenOdd: string;
    selectedWeekDays?: CreateScheduleDTOWeekDaysItem[];
    startTime: string;
    endTime: string;
    intervalInMinutes: number;
    eventType: CreateScheduleDTOPlanIdsItem;
    selectedPractitionerEventType: CreateScheduleDTOServiceTypeItem;
    course: string;
    serviceType: string;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

const dateFormat = 'DD.MM.YYYY';

export const AddEventToScheduleModal = ({ showModal, onSuccess, onCancel, practitionerId, isNurse, onError }: IAddEventToScheduleModalProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [dayType, setDayType] = useState<'everyday' | 'evenOdd' | 'week'>('everyday');
    const [timesOptions, setTimesOptions] = useState<{ label: string; value: string }[]>();
    const [endTimesOptions, setEndTimesOptions] = useState<{ label: string; value: string }[]>();
    const [courses, setCourses] = useState<CourseDTO[]>([]);
    const [serviceType, setServiceType] = useState<CreateScheduleDTOServiceTypeItem>('diagnostics');
    const [isPast, setIsPast] = useState<boolean>(false);

    const allWeekDays = Object.values(CreateScheduleDTOWeekDaysItem);
    const evenOddOptionsList = [
        {
            id: 'even',
            value: allWeekDays.filter((value, index) => index % 2 !== 0),
            title: t("add_event_to_schedule_modal.even_days"),
        },
        {
            id: 'odd',
            value: allWeekDays.filter((value, index) => index % 2 === 0),
            title: t("add_event_to_schedule_modal.odd_days"),
        },
    ];
    const initialValues = {
        dateRange: [dayjs(), dayjs()],
        evenOdd: evenOddOptionsList[0].id,
        dayType: 'everyday',
        selectedWeekDays: [],
        eventType: CreateScheduleDTOPlanIdsItem.MYOPIA_TREATMENT_PACKAGE,
        selectedPractitionerEventType: CreateScheduleDTOServiceTypeItem.any,
        intervalInMinutes: 30,
        startTime: '',
        endTime: '',
        course: '',
        serviceType: null,
    };

    const weekOptionsList = allWeekDays.map((value) => ({
        value,
        label: t(`week.${value}`),
    }));

    useEffect(() => {
        setStartTimesOption(30);
        setTimesOption(form.getFieldValue('intervalInMinutes'));
        if (isNurse) {
            getCourses().then((result) => {
                if (result.data.courseDTOList && result.data.courseDTOList.length > 0) {
                    setCourses(result.data.courseDTOList.filter((course) => course.id));
                    form.setFieldValue('course', result.data.courseDTOList.find((course) => course.id)?.id);
                }
            });
        } else {
            form.setFieldValue('course', 'any');
        }
    }, []);

    const setStartTimesOption = (interval: number) => {
        const times = [];

        let currentTime = dayjs('00:00', 'HH:mm');
        while (currentTime.isBefore(dayjs('24:00', 'HH:mm'))) {
            times.push(currentTime.format('HH:mm'));
            currentTime = currentTime.add(interval, 'minute');
        }
        if (times[times.length - 1] !== '24:00') {
            times.push('24:00');
        }

        setTimesOptions(times.map((value) => ({ value, label: value })));
        setEndTimesOptions(times.map((value) => ({ value, label: value })));
        form.setFieldsValue({ timesOptions: times.map((value) => ({ value, label: value })) });
    };

    const setTimesOption = (interval: number) => {
        const times = [];

        let currentTime = dayjs(form.getFieldValue('startTime'), 'HH:mm');
        while (currentTime.isBefore(dayjs('24:00', 'HH:mm'))) {
            times.push(currentTime.format('HH:mm'));
            currentTime = currentTime.add(interval, 'minute');
        }
        if (times[times.length - 1] !== '24:00') {
            times.push('24:00');
        }
        setEndTimesOptions(times.map((value) => ({ value, label: value })));
    };

    const handleChangeDayType = (day: 'everyday' | 'evenOdd' | 'week') => {
        form.setFieldValue('dayType', day);
        setDayType(day);
    };

    const onFinish = (values: FormValues) => {
        const startTime = dateForServer(parseDateTimes(values.dateRange[0], values.startTime), false, true);
        const endTime = dateForServer(parseDateTimes(values.dateRange[1], values.endTime), false, true);
        createSchedule(practitionerId, {
            startTimeStamp: startTime,
            endTimeStamp: endTime,
            weekDays:
                values.dayType === 'everyday'
                    ? allWeekDays
                    : values.dayType === 'evenOdd'
                    ? evenOddOptionsList.find((e) => e.id === values.evenOdd)?.value
                    : values.selectedWeekDays,
            intervalInMinutes: values.intervalInMinutes,
            planIds: isNurse ? (values.course ? [values.course] : ([] as any)) : [],
            serviceType: [values?.serviceType] as CreateScheduleDTOServiceTypeItem[],
        })
            .then((result) => {
                onSuccess();
            })
            .catch((e) => {
                if (e.response.data.code === 'INVALID_ARGUMENTS_EXCEPTION') {
                    onCancel?.();
                    onError?.(startTime, endTime);
                } else if (e.response.data.code === 'SCHEDULE_START_IN_PAST_EXCEPTION') {
                    message.error(t("add_event_to_schedule_modal.cant_set_previous_date"));
                }
            });
    };

    const handleChangeCourse = (course: CourseDTOId) => {
        if (isNurse) {
            form.setFieldValue('intervalInMinutes', courses.find((c) => c.id === course)?.sessionDurationInMinutes);
            setTimesOption(form.getFieldValue('intervalInMinutes'));
        }
    };

    const handleChangePlanId = (serviceType: CreateScheduleDTOServiceTypeItem) => {
        setServiceType(serviceType);
    };

    const handleIntervalChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value) {
            setTimesOption(+value);
        }
    };

    const convertTimeStringToMinutes = (time: string): number => {
        const values = time.split(':');
        const seconds: number = +values[0] * 60 + +values[1];
        return seconds;
    };

    const setStartTime = (value: string) => {
        setTimesOption(form.getFieldValue('intervalInMinutes'));
    };

    const startTimeValue = useWatch('startTime', form);
    const endTimeValue = useWatch('endTime', form);

    const handleDateChange = (e: any) => {
        if (dayjs(e[0].date()).isSame(dayjs(dayjs().date()))) {
            setIsPast(false);
        } else {
            setIsPast(e[0].isBefore(dayjs()));
        }
    };

    const handleChangeOptions = (e: any) => {
        form.setFieldValue('evenOdd', e.target.value);
    };

    return (
        <Modal
            onCancel={onCancel}
            width={720}
            title={t("add_event_to_schedule_modal.adding_appointments")}
            open={showModal}
            footer={[
                <div key="empty" />,
                <div key="container">
                    <Button onClick={onCancel} key="cancel">
                        {t("cancel_button")}
                    </Button>

                    <Button disabled={isPast} onClick={form.submit} type="primary" key="submit">
                        {t("add_event_to_schedule_modal.add")}
                    </Button>
                </div>,
            ]}
        >
            <Form form={form} onFinish={onFinish} initialValues={initialValues}>
                <div className={styles.wrapper}>
                    <div className="mb-5">
                        <p className={styles.label}>{t("add_event_to_schedule_modal.working_period")}</p>
                        <Row gutter={20}>
                            <Col span={14}>
                                <Form.Item name="dateRange">
                                    <RangePicker onChange={handleDateChange} format={dateFormat} popupClassName="topIndex" />
                                </Form.Item>
                            </Col>
                            <Col span={10}>{isPast && <p className={styles.warning}>{t("add_event_to_schedule_modal.cant_add_to_previous_date")}</p>}</Col>
                        </Row>
                    </div>
                    <Form.Item name="dayType">
                        <Row align="middle" gutter={4}>
                            <Col span={8}>
                                <Button
                                    onClick={() => handleChangeDayType('everyday')}
                                    className={classNames(styles.tab, dayType === 'everyday' && styles.active_tab)}
                                >
                                    {t("add_event_to_schedule_modal.every_day")}
                                </Button>
                            </Col>
                            <Col span={8}>
                                <Button
                                    type={'default'}
                                    onClick={() => handleChangeDayType('evenOdd')}
                                    className={classNames(styles.tab, dayType === 'evenOdd' && styles.active_tab)}
                                >
                                    {t("add_event_to_schedule_modal.even_odd")}
                                </Button>
                            </Col>
                            <Col span={8}>
                                <Button
                                    type={'default'}
                                    onClick={() => handleChangeDayType('week')}
                                    className={classNames(styles.tab, dayType === 'week' && styles.active_tab)}
                                >
                                    {t("add_event_to_schedule_modal.week")}
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                    {dayType === 'evenOdd' && (
                        <div className={styles['eve-odd-container']}>
                            <Form.Item name="evenOdd">
                                <Radio.Group onChange={handleChangeOptions}>
                                    {evenOddOptionsList.map((option, i) => (
                                        <Radio value={option.id} key={`event-odds-${i}`}>
                                            <p className={styles['even-odd-title']}>{option.title}</p>
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    )}
                    {dayType === 'week' && (
                        <div className={styles['week-container']}>
                            <Form.Item name="selectedWeekDays">
                                <Checkbox.Group options={weekOptionsList} />
                            </Form.Item>
                        </div>
                    )}
                    <div className={styles['event-container']}>
                        <p className={styles.label}>{t("add_event_to_schedule_modal.event_type")}</p>
                        <Form.Item name="serviceType">
                            <Select className="w-100 mt-1" onChange={handleChangePlanId}>
                                {isNurse
                                    ? Object.keys(CreateScheduleDTOServiceTypeItem)
                                          .filter((x) => x !== 'consultation')
                                          .map((x) => (
                                              <Option key={x} value={x}>
                                                  {t('enums.serviceTypes.' + x)}
                                              </Option>
                                          ))
                                    : Object.keys(CreateScheduleDTOServiceTypeItem)
                                          .filter((x) => x !== 'therapy-session')
                                          .map((x) => (
                                              <Option key={x} value={x}>
                                                  {t('enums.serviceTypes.' + x)}
                                              </Option>
                                          ))}
                            </Select>
                        </Form.Item>
                    </div>
                    {isNurse && serviceType === CreateScheduleDTOServiceTypeItem['therapy-session'] && (
                        <div className={styles['event-container']}>
                            <p className={styles.label}>{t("add_event_to_schedule_modal.course")}</p>
                            <Form.Item name="course">
                                <Select className="w-100 mt-1" onChange={handleChangeCourse}>
                                    {isNurse
                                        ? courses
                                              .filter((x) => !!x.id)
                                              .map((x) => (
                                                  <Option key={x.id} value={x.id}>
                                                      {t('enums.planDefinition.' + x.id)}
                                                  </Option>
                                              ))
                                        : Object.keys(CreateScheduleDTOServiceTypeItem)
                                              .filter((x) => x !== 'therapy-session')
                                              .map((x) => (
                                                  <Option key={x} value={x}>
                                                      {t('enums.serviceTypes.' + x)}
                                                  </Option>
                                              ))}
                                </Select>
                            </Form.Item>
                        </div>
                    )}
                    <Row className={styles.time} gutter={32}>
                        <Col span={8}>
                            <p className={styles.label}>{t("add_event_to_schedule_modal.duration")}</p>
                            <Form.Item name="intervalInMinutes">
                                <Input
                                    onChange={handleIntervalChange}
                                    className="w-100 mt-1"
                                    disabled={form.getFieldValue('serviceType') === CreateScheduleDTOServiceTypeItem['therapy-session']}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <p className={styles.label}>{t("add_event_to_schedule_modal.time_from")}</p>
                            <Form.Item name="startTime">
                                <Select onChange={setStartTime} className="w-100 mt-1">
                                    {timesOptions?.map((item) => (
                                        <Option
                                            disabled={
                                                endTimeValue && convertTimeStringToMinutes(item.value) >= convertTimeStringToMinutes(endTimeValue)
                                            }
                                            key={item.value}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <p className={styles.label}>{t("add_event_to_schedule_modal.time_till")}</p>
                            <Form.Item name="endTime">
                                <Select className="w-100 mt-1">
                                    {endTimesOptions?.map((item) => (
                                        <Option
                                            disabled={
                                                startTimeValue && convertTimeStringToMinutes(item.value) <= convertTimeStringToMinutes(startTimeValue)
                                            }
                                            key={item.value}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal>
    );
};

const parseDateTimes = (date: Dayjs, time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return date.hour(hours).minute(minutes).toDate();
};
