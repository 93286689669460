import { AppointmentDTOServiceType, AppointmentDTOStatus, CarePlanDTO, PatientEncounterDTO } from '@api/mainServiceAPI';
import { Bubble, CourseProgressIcon, Dino6 } from '@icons';
import { DEBUG } from '@utils/utils';
import { Progress } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import styles from './CourseProgressBar.module.scss';

interface ICourseChip {
    dateTime: Date;
    weekDay?: string;
    dayLabel?: string;
    status?: AppointmentDTOStatus;
    isToday?: boolean;
    appointments?: {
        encounterId: string;
        appointmentId?: string;
        timeLabel?: string;
        serviceType?: AppointmentDTOServiceType;
        cancelled?: boolean;
    }[];
}

export interface ICourseProgressBarProps {
    carePlan: CarePlanDTO;
    encounters: PatientEncounterDTO[];
}

export const CourseProgressBar = ({ carePlan, encounters }: ICourseProgressBarProps) => {
    const { t } = useTranslation();

    const [chips, setChips] = useState<ICourseChip[]>([]);

    useEffect(() => {
        const mappedData: ICourseChip[] = [];

        encounters.forEach((x) => {
            const dayJsDate = dayjs(x.dateTime);
            const dayLabel = dayJsDate.format('D MMMM');

            // group by day
            const dayAlreadyExistIndex = mappedData.findIndex((x) => x.dayLabel === dayLabel);
            if (dayAlreadyExistIndex !== -1) {
                mappedData[dayAlreadyExistIndex].appointments?.push({
                    encounterId: x.encounterId!,
                    appointmentId: x.appointmentId,
                    timeLabel: dayJsDate.format('HH:mm'),
                    serviceType: x.serviceType,
                    cancelled: x.appointmentStatus === 'cancelled' && x.needsReschedule,
                });
            } else {
                mappedData.push({
                    isToday: dayJsDate.isSame(dayjs(), 'day'),
                    dayLabel,
                    dateTime: new Date(x.dateTime!),
                    weekDay: dayJsDate.format('dddd').charAt(0).toUpperCase() + dayJsDate.format('dddd').slice(1),
                    appointments: [
                        {
                            timeLabel: dayJsDate.format('HH:mm'),
                            encounterId: x.encounterId!,
                            appointmentId: x.appointmentId,
                            serviceType: x.serviceType,
                            cancelled: x.appointmentStatus === 'cancelled' && x.needsReschedule,
                        },
                    ],
                });
            }
        });

        setChips(mappedData);
    }, [encounters]);

    return !!carePlan ? (
        <div className={styles.wrapper}>
            <div className={styles.dinoBlock}>
                <Dino6 />
                <div className={styles.bubble}>
                    <p className={styles.title} style={{marginLeft: "-25px"}}>
                        {t("course_progress_bar.remain_days", {remainDays: ((carePlan.durationInDays || 0) - (carePlan.currentProgress || 0))})} <br /> {t("course_progress_bar.good_luck")}
                    </p>
                    <Bubble />
                </div>
            </div>

            <div className={styles.progressWrapper}>
                <div className={styles.iconWrapper}>
                    <CourseProgressIcon />
                </div>
                <div>
                    <p className={styles.proceduresTitle}>{t('userMainPage.courseRecommended.' + carePlan.planDefinitionID)} {carePlan.extended && t("course_progress_bar.extended")}</p>
                    <Progress
                        className={styles.progress}
                        showInfo={false}
                        percent={(carePlan.currentProgress || 0) * (100 / (carePlan.durationInDays || 1))}
                        steps={carePlan.durationInDays}
                    />
                </div>
            </div>
            <div className={styles.slider}>
                <Slider className="w-100" {...sliderSettings}>
                    {chips.map((chip, i) => (
                        <div
                            className={classNames(
                                styles.chip,
                                chip.isToday && styles.today,
                                chip.appointments?.some((x) => x.cancelled) && styles.cancelled,
                            )}
                            key={i}
                        >
                            <div className="d-flex align-items-center gap-1">
                                <span className={styles.subtitle}>{chip.weekDay},</span>
                                <span className={styles.date}>{chip.dayLabel}</span>
                            </div>
                            <div className={styles.list}>
                                {chip.appointments?.map((x) => (
                                    <div key={x.encounterId} onDoubleClick={() => DEBUG({ url: 'appointment?appointmentId=' + x.appointmentId })}>
                                        <p className={styles.title}>{t('enums.serviceTypes.' + x.serviceType)}</p>
                                        <p>{x.timeLabel}</p>

                                        <Link className={styles['link-hover']} to={'/appointments/' + x.encounterId}>
                                             {t("details_link")}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    ) : null;
};

const sliderSettings = {
    dots: false,
    infinite: false,
    centerPadding: '60px',
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 1,
            },
        },
    ],
};
