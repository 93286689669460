import { AppointmentParticipantDetailsDTOServiceType } from '@api/mainServiceAPI';
import { UserProfileDTOProfileType } from '@api/userServiceAPI';
import { AlertIconOutlined, ChatIcon, ChevronLeft, ChevronRight, ProcedureToggleIcon, SettingsIcon } from '@icons';
import { getProcedureSettingsAsString } from '@procedures';
import { ICurrentProfile } from '@sliceUser';
import { Button, Col, Collapse, Input, RadioChangeEvent, Row } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseSettingsModal } from 'src/components/CourseSettingsModal/CourseSettingsModal';
import { PatientForms } from 'src/components/PatientForms/PatientForms';
import { SessionParticipant } from 'src/models/session-participant';
import { IProcedureFormItem } from '../../../../sessions/ConsultationSession/steps/CourseStep/CourseStep';
import styles from './PatientInfoTab.module.scss';

const { TextArea } = Input;

interface IPatientInfoTabProps {
    patient: SessionParticipant;
    currentProfile: ICurrentProfile | null;
    serviceType?: AppointmentParticipantDetailsDTOServiceType;

    saveComment?: (comment: string) => void;
}

const { Panel } = Collapse;

export const PatientInfoTab = ({ patient, currentProfile, serviceType, saveComment }: IPatientInfoTabProps) => {
    const { t } = useTranslation();

    const [showComment, setShowComment] = useState(false);
    const [showMainContent, setShowMainContent] = useState(true);
    const [editRepresentative, setEditRepresentative] = useState(false);
    const [selectedRepresentative, setSelectedRepresentative] = useState(1);
    const [selectedRelationship, setSelectedRelationship] = useState('father');
    const [showCourseSettingsModal, setShowCourseSettingsModal] = useState(false);

    const [name, setName] = useState('');
    const [comment, setComment] = useState(patient.therapySessionResultDTO?.result?.comment || '');

    const changeRepresentative = (e: RadioChangeEvent) => {
        setSelectedRepresentative(e.target.value);
    };

    const selectRelationship = (value: string) => {
        setSelectedRelationship(value);
    };

    const onSaveComment = () => {
        setShowComment(false);
        setShowMainContent(true);

        if (comment) {
            saveComment?.(comment);
        }
    };

    const getModifierFullName = useCallback(() => {
        if (!patient?.carePlanDTO?.modifier) {
            return '';
        }

        let practFullName = `${patient?.carePlanDTO?.modifier?.lastName} ${patient?.carePlanDTO?.modifier?.firstName?.[0]}.`;

        if (patient?.carePlanDTO?.modifier?.middleName) {
            practFullName += `${patient?.carePlanDTO?.modifier?.middleName?.[0]}.`;
        }

        return practFullName;
    }, [patient?.carePlanDTO?.modifier]);

    return (
        <div className={styles.wrapper}>
            {showMainContent && (
                <>
                    <p className={styles.title}>
                        {patient.patient?.familyName} {patient.patient?.givenName}
                    </p>
                    <div className={styles.paragraph}>
                        <Row className="mt-3">
                            <Col span={12}>
                                <p className={styles.subtitle}>{t('patient_info_tab.birthdate')}</p>
                                <p>
                                    {dayjs(patient?.birthDate).format('DD.MM.YYYY')} ({dayjs(patient?.birthDate).fromNow(true)})
                                </p>
                            </Col>
                            <Col span={12}>
                                <p className={styles.subtitle}>{t('patient_info_tab.gender')}</p>
                                <p>{t(`dictionaries.genders.long.${patient?.gender}`)}</p>
                            </Col>
                        </Row>
                    </div>

                    {/* stupid check - that it's not me */}
                    {patient.familyMember &&
                        patient.familyMember.familyName !== patient.patient?.familyName &&
                        patient.familyMember.givenName !== patient.patient?.givenName && (
                            <div className={styles.paragraph}>
                                <div className="d-flex">
                                    <div>
                                        <p className={styles.subtitle}>{t('patient_info_tab.family_member')}</p>
                                        <p>
                                            {patient.familyMember?.familyName} {patient.familyMember?.givenName} {patient.familyMember?.middleName}
                                        </p>
                                    </div>
                                    {/* <ProcedureEditIcon
                                className={styles.touchable}
                                onClick={() => {
                                    setEditRepresentative(true);
                                    setShowMainContent(false);
                                }}
                            /> */}
                                </div>
                            </div>
                        )}
                    {patient.supervisingPractitionerFullName && (
                        <div className={styles.paragraph}>
                            <div className="d-flex">
                                <div>
                                    <p className={styles.subtitle}>{t('patient_info_tab.supervising_practitioner')}</p>
                                    <p>{patient.supervisingPractitionerFullName}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {currentProfile?.profileType === UserProfileDTOProfileType.Nurse &&
                        serviceType === AppointmentParticipantDetailsDTOServiceType['therapy-session'] && (
                            <Collapse
                                bordered={false}
                                expandIconPosition="end"
                                defaultActiveKey={['1', '2']}
                                expandIcon={({ isActive }) => (
                                    <ProcedureToggleIcon
                                        className={classNames(isActive ? styles.active : styles.nonActive)}
                                        rotate={isActive ? 0 : 90}
                                    />
                                )}
                                className={styles.collapse}
                            >
                                {patient.carePlanDTO && (
                                    <Panel
                                        header={
                                            <div className={styles.paragraph}>
                                                <div className="d-flex">
                                                    <div>
                                                        <p className={styles.subtitle}>{t('patient_info_tab.procedures')}</p>
                                                        <p>
                                                            {t(`enums.planDefinition.${patient.carePlanDTO?.planDefinitionID}`)}{' '}
                                                            {patient.carePlanDTO?.extended && t('patient_info_tab.extended')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        key="1"
                                        className={styles.panel}
                                    >
                                        <div className="d-flex align-items-start flex-column">
                                            <div className={styles.duration}>
                                                {t('host_sidebar.day')} {(patient.carePlanDTO.currentProgress || 0) + 1} {t('host_sidebar.from')}{' '}
                                                {patient?.carePlanDTO?.durationInDays}
                                                {patient.currentSessionOcclusionEye && (
                                                    <>
                                                        {' '}
                                                        ({t('enums.targetEye.' + patient.currentSessionOcclusionEye)}{' '}
                                                        {t('patient_info_tab.occlusion')})
                                                    </>
                                                )}
                                            </div>
                                            <ol>
                                                {patient?.carePlanDTO?.procedureSettings?.map((x: IProcedureFormItem, i: number) => {
                                                    const settingsString = getProcedureSettingsAsString(
                                                        x[patient.currentSessionOcclusionEye || patient.carePlanDTO?.targetEye || 'ou'],
                                                    );

                                                    return (
                                                        <li key={`procedure-${i}`}>
                                                            {!!settingsString ? (
                                                                <>
                                                                    {t('procedures.list.' + x.procedure)} - {settingsString}
                                                                </>
                                                            ) : (
                                                                t('procedures.list.' + x.procedure)
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ol>

                                            {!!patient.carePlanDTO.modifiedDateTime && (
                                                <div className={styles.warning}>
                                                    <AlertIconOutlined />

                                                    <div className={styles.textBlock}>
                                                        <span className={styles.warn}>{t('patient_info_tab.changed')}</span>
                                                        <span className={styles.info}>
                                                            {dayjs(patient.carePlanDTO.modifiedDateTime).format('DD.MM.YYYY')}
                                                        </span>
                                                        <span className={styles.main}>{getModifierFullName()}</span>
                                                    </div>
                                                </div>
                                            )}

                                            <div
                                                className={classNames(styles.bubble, 'w-100 my-4')}
                                                onClick={() => {
                                                    setShowComment(true);
                                                    setShowMainContent(false);
                                                }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div className="position-relative">
                                                        <ChatIcon />
                                                        {comment && <div className={styles.badge} />}
                                                    </div>
                                                    <div className={styles['comment-icon-space']}>
                                                        <p className={styles.commentTitle}>{t('patient_info_tab.comment_to_practitioner')}</p>
                                                        <p className={styles.commentSubtitle}>{t('patient_info_tab.add_comment')}</p>
                                                    </div>
                                                </div>
                                                <ChevronRight />
                                            </div>
                                            {patient?.treatmentKit && patient?.treatmentKit?.length > 0 && (
                                                <div>
                                                    <p className={styles.subtitle}>{t('patient_info_tab.complex_oko')} </p>
                                                    <ul>
                                                        {patient.treatmentKit?.map((kit, i) => (
                                                            <li key={`kit-${i}`}>{t(`consultationSession.courseStep.devices.${kit}`)}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </Panel>
                                )}
                                {!!patient.forms?.length && (
                                    <Panel
                                        header={
                                            <div className={styles.paragraph}>
                                                <p className={styles.title}>{t('patient_info_tab.forms')}</p>
                                            </div>
                                        }
                                        key="2"
                                        className={styles.panel}
                                    >
                                        <PatientForms forms={patient.forms} />
                                    </Panel>
                                )}
                            </Collapse>
                        )}

                    {serviceType !== AppointmentParticipantDetailsDTOServiceType['therapy-session'] && (
                        <>
                            <div className={styles.paragraph}>
                                {patient.carePlanDTO?.planDefinitionID && (
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <p className={styles.subtitle}>{t('patient_info_tab.procedures')}</p>
                                            <p>
                                                {t(`enums.planDefinition.${patient.carePlanDTO?.planDefinitionID}`)}{' '}
                                                {patient.carePlanDTO?.extended && t('patient_info_tab.extended')}
                                            </p>
                                        </div>
                                        <SettingsIcon className="pointer" onClick={() => setShowCourseSettingsModal(true)} />
                                    </div>
                                )}
                            </div>
                            {patient.treatmentKit && patient.treatmentKit?.length > 0 && (
                                <div className={styles.paragraph}>
                                    <p className={styles.subtitle}>{t('patient_info_tab.complex_oko')} </p>
                                    <ul>
                                        {patient.treatmentKit?.map((kit, i) => (
                                            <li key={`kit-${i}`}>{t(`consultationSession.courseStep.devices.${kit}`)}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}

            {showComment && (
                <div>
                    <div className="d-flex align-items-center mb-4">
                        <ChevronLeft
                            onClick={() => {
                                setShowComment(false);
                                setShowMainContent(true);
                            }}
                            className={`me-2 ${styles.touchable}`}
                        />
                        <p className={styles.title}>{t('patient_info_tab.comment')}</p>
                    </div>
                    <div>
                        <div className={styles.edit}>
                            <TextArea rows={8} value={comment} onChange={(e) => setComment(e.target.value)} />
                            <div className="d-flex align-items-center justify-content-between">
                                <Button
                                    onClick={() => {
                                        setShowComment(false);
                                        setShowMainContent(true);
                                    }}
                                    className="w-50 me-2"
                                >
                                    {t('patient_info_tab.cancel_button')}
                                </Button>
                                <Button onClick={() => onSaveComment()} className="w-50 ms-2" type="primary">
                                    {t('patient_info_tab.save_button')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <CourseSettingsModal
                editable={false} // todo ?
                carePlan={patient.carePlanDTO}
                visible={showCourseSettingsModal}
                onClose={() => setShowCourseSettingsModal(false)}
            />
        </div>
    );
};
