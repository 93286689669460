/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    HostScreenSharingStreamConstraints,
    RtcPeerConnectionConfiguration,
    ScreenSharingStreamConstraints,
    VideoStreamConstraintsEconomic,
    VideoStreamConstraintsFull,
} from '@configVideoConference';
import {
    removeActiveScreenSharingParticipant,
    selectActiveScreenSharingParticipant,
    selectAwaitingSharingFrom,
    setActiveScreenSharing,
    setActiveScreenSharingParticipant,
} from '@sliceProcedures';
import { StompSubscription } from '@stomp/stompjs';
import { Logger } from '@utils/logger';
import { exitFullscreen } from '@utils/utils';
import { WsKurentoIncomingTopicType, WsKurentoOutgoingTopicType, WsProcedureTopicType, WsTopic } from '@utils/websocket.topics';
import { Button, Modal, message } from 'antd';
import * as kurentoUtils from 'kurento-utils';
import { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IParticipantPerson, KurentoParticipant } from 'src/models/kurento-participant';
import { WebsocketContext } from './ws.context';
import {useTranslation} from "react-i18next";

export interface ILobbyParticipant {
    id: string;
}

export interface IKurentoWsMessage {
    type: WsKurentoOutgoingTopicType | WsKurentoIncomingTopicType;
    appointmentId?: string;
    payload?: any;
}

interface IKurentoContextProps {
    participants: KurentoParticipant[];
    lobbyParticipants: ILobbyParticipant[];
    host: KurentoParticipant | null;
    meAsParticipant: KurentoParticipant | null;
    iAmHost: boolean;
    kurentoConnected: boolean;
    connectToAppointment: (fhirId: string, appointmentId: string, isHost?: boolean, isEconomic?: boolean) => void;
    closeSession: (finished?: boolean) => void;
    approveFromLobby: (participantsIds: string[]) => void;
}

export const KurentoContext = createContext<IKurentoContextProps>({
    participants: [],
    lobbyParticipants: [],
    host: null,
    meAsParticipant: null,
    iAmHost: false,
    kurentoConnected: false,
    connectToAppointment: () => ({}),
    closeSession: () => ({}),
    approveFromLobby: () => ({}),
});

const DEBUG = true;
export const KurentoProvider = ({ children }: PropsWithChildren) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { wsClient, send } = useContext(WebsocketContext);
    const participantsRef = useRef<{ [key: string]: KurentoParticipant }>({}); // warning: it's a mutated object (by link)
    const currentClientRef = useRef<KurentoParticipant | null>(null);
    const hostRef = useRef<KurentoParticipant | null>(null);
    const isEconomicVideo = useRef<boolean>(false);
    const currentRoomRef = useRef('');

    const [lobbyParticipants, setLobbyParticipants] = useState<ILobbyParticipant[]>([]);

    const awaitingScreenSharingFrom = useSelector(selectAwaitingSharingFrom);
    const activeScreenSharingParticipant = useSelector(selectActiveScreenSharingParticipant);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [currentClientId, setCurrentClientId] = useState('');

    // Output Params
    const [iAmHost, setIAmHost] = useState(false);
    const [kurentoConnected, setKurentoConnected] = useState(false);
    const [host, setHost] = useState<KurentoParticipant | null>(null);
    const [meAsParticipant, setMeAsParticipant] = useState<KurentoParticipant | null>(null);
    const [participants, setParticipants] = useState<KurentoParticipant[]>([]);

    // Set Output Params
    useEffect(() => {
        setHost(hostRef.current);
    }, [hostRef.current]);

    useEffect(() => {
        let wsSubscription: StompSubscription;
        if (currentClientId) {
            wsSubscription = wsClient!.subscribe(WsTopic.kurentoTopic + currentClientId, (messageObj: any) => {
                const message = JSON.parse(messageObj.body) as IKurentoWsMessage;

                messageHandler(message);
            });

            sendMessage({
                type: WsKurentoOutgoingTopicType.JOIN_LOBBY,
                payload: {
                    isHost: iAmHost,
                },
            });

            window.addEventListener('beforeunload', () => closeSession());
            setKurentoConnected(true); // todo ?
        }

        return () => {
            setKurentoConnected(false);
            wsSubscription?.unsubscribe();
            window.removeEventListener('beforeunload', () => closeSession());
        };
    }, [currentClientId]);

    useEffect(() => {
        // if we were on the appointment session, and then - left it (not a standard way)
        if (location.pathname !== '/appointment' && currentClientRef.current) {
            closeSession();
            resetContextState();
        }
    }, [location]);

    useEffect(() => {
        if (activeScreenSharingParticipant) {
            const participant = createOrUpdateParticipant(activeScreenSharingParticipant);
            if (awaitingScreenSharingFrom === activeScreenSharingParticipant) {
                participant.screenSharingActive = true;
                receiveVideoScreenFrom(participant);
                if (currentClientRef.current?.isHost) {
                    message.info(`"${participant.person?.firstName}" ` + t("media_session.messages.start_demonstration"));
                }
            } else {
                // Closing stream if we are not waiting for it
                send('/msg/gateway-ws/message', '/procedure/' + activeScreenSharingParticipant, {
                    type: WsProcedureTopicType.requestScreenSharing,
                    state: false,
                });
                // dispatch(removeActiveScreenSharingParticipant(activeScreenSharingParticipant));
            }
        }
    }, [awaitingScreenSharingFrom, activeScreenSharingParticipant]);

    const connectToAppointment = (fhirId: string, appointmentId: string, isHost = false, economicVideo = false) => {
        if (!appointmentId) {
            Logger.error('Appointment ID is required');
            return;
        }

        isEconomicVideo.current = economicVideo;

        currentRoomRef.current = appointmentId;
        setCurrentClientId(fhirId);
        setIAmHost(isHost);
    };

    const closeSession = (finished = false) => {
        sendMessage({ type: WsKurentoOutgoingTopicType.LEAVE_LOBBY, payload: { targetId: currentClientId } });

        // if host is here
        if (hostRef.current) {
            if (hostRef.current?.id === currentClientId) {
                sendMessage({
                    type: finished ? WsKurentoOutgoingTopicType.CLOSE_SESSION : WsKurentoOutgoingTopicType.LEAVE_SESSION,
                    payload: { targetId: currentClientId },
                });
            } else {
                sendMessage({
                    type: WsKurentoOutgoingTopicType.KICK_PARTICIPANT,
                    payload: { targetId: currentClientId },
                });
            }
        }

        onParticipantLeft(currentClientId, finished);
    };

    const approveFromLobby = (participantsIds: string[]) => {
        sendMessage({
            type: WsKurentoOutgoingTopicType.JOIN_SESSION_APPROVE,
            payload: {
                targetId: participantsIds,
            },
        });
    };

    const messageHandler = (messageWs: IKurentoWsMessage) => {
        let payload: any;
        try {
            payload = JSON.parse(messageWs.payload);
        } catch (error) {
            Logger.error('WRONG JSON', message);
        }

        if (
            messageWs.type === WsKurentoIncomingTopicType.ERROR ||
            (DEBUG &&
                !(messageWs.type === WsKurentoIncomingTopicType.ICE_CANDIDATE || messageWs.type === WsKurentoIncomingTopicType.ICE_CANDIDATE_SHARE))
        ) {
            console.log('kurentoTopic TO ME', { ...messageWs, payload });
        }

        switch (messageWs.type) {
            // create session handler (only for doctor)
            case WsKurentoIncomingTopicType.SESSION_CREATED:
                sendMessage({
                    type: WsKurentoOutgoingTopicType.JOIN_SESSION,
                });
                break;

            // get participants in the current room
            case WsKurentoIncomingTopicType.SESSION_STARTED:
                {
                    if (!currentClientId) {
                        Logger.error('Current client not found');
                    }
                    const hostId = payload.fhirId;
                    const iAmHost = hostId === currentClientId;

                    if (!hostId) {
                        Logger.error('Host ID is empty');
                    }

                    const participant = createOrUpdateParticipant(currentClientId, iAmHost ? payload.host : payload.participant, iAmHost);
                    currentClientRef.current = participant;
                    setMeAsParticipant(participant);

                    if (!iAmHost) {
                        const host = createOrUpdateParticipant(hostId, payload.host, true);
                        hostRef.current = host;

                        participant.screenSharingCallback = () => screenSharingCallback(participant, hostId);
                    }
                    runRtcPeer(participant, hostId, undefined, participant.video);

                    if (iAmHost) {
                        participant.screenSharingCallback = () => screenSharingCallback(participant, hostId);
                        participant.toggleScreenSharing();
                    }
                }
                break;

            // participant entered to the waitingRoom
            case WsKurentoIncomingTopicType.PARTICIPANT_JOINED_LOBBY:
                if (lobbyParticipants.findIndex((x) => x.id === payload.fhirId) === -1) {
                    setLobbyParticipants((prev) => [...prev, { id: payload.fhirId }]);
                }
                break;

            // connect participant from the waitingRoom
            case WsKurentoIncomingTopicType.PARTICIPANT_APPROVED:
                sendMessage({
                    type: WsKurentoOutgoingTopicType.JOIN_SESSION,
                });
                break;

            // participant left the waitingRoom
            case WsKurentoIncomingTopicType.PARTICIPANT_LEFT_LOBBY:
                setLobbyParticipants((prev) => [...prev.filter((x) => x.id !== payload.fhirId)]);
                break;

            // get video from new participant
            case WsKurentoIncomingTopicType.PARTICIPANT_ARRIVED:
                {
                    const participant = createOrUpdateParticipant(payload.fhirId, payload.participant);
                    receiveVideoFrom(participant);

                    if (hostRef.current) {
                        hostRef.current.inputAudioEnabledForAll = true;
                        hostRef.current.videoEnabledForAll = true;
                    }

                    setLobbyParticipants((prev) => prev.filter((x) => x.id !== payload.fhirId));
                }
                break;

            // get video from the webcam for the user
            case WsKurentoIncomingTopicType.RECEIVE_VIDEO_ANSWER:
                participantsRef.current[payload.fhirId].rtcPeer.processAnswer(payload.sdpAnswer, (error: any) => {
                    if (error) {
                        Logger.error(error);
                    }
                });
                break;

            // get screen-sharing video from the user
            case WsKurentoIncomingTopicType.RECEIVE_VIDEO_SCREEN_ANSWER:
                participantsRef.current[payload.fhirId].rtcPeerScreen.processAnswer(payload.sdpAnswer, (error: any) => {
                    if (error) {
                        Logger.error(error);
                    }
                });
                break;

            // get screen-sharing video from new participant
            case WsKurentoIncomingTopicType.PARTICIPANT_STARTED_SHARE:
                {
                    const participant = createOrUpdateParticipant(payload.fhirId);
                    if (!participant.isHost) {
                        dispatch(setActiveScreenSharingParticipant(participant.id));
                    }
                }
                break;

            // stop screen-sharing video from the participant
            case WsKurentoIncomingTopicType.PARTICIPANT_STOPPED_SHARE:
                {
                    const participant = createOrUpdateParticipant(payload.fhirId);
                    participant.screenSharingActive = false;

                    if (!participant.isHost) {
                        dispatch(removeActiveScreenSharingParticipant(participant.id));
                    }

                    if (currentClientRef.current?.isHost) {
                        if (participant.isHost) {
                            approveScreenSharing(participant);
                        }
                    }
                }
                break;

            // changed MICROPHONE audio of the host
            case WsKurentoIncomingTopicType.HOST_AUDIO_CHANGED:
                {
                    const participant = participantsRef.current[payload.targetId];

                    if (participant) {
                        participant.inputAudioEnabled = payload.value;
                    }

                    if (currentClientRef.current?.isHost) {
                        const newParticipants = Object.values(participantsRef.current).filter((x) => !x.isHost);

                        if (newParticipants.length) {
                            currentClientRef.current.inputAudioEnabledForAll = newParticipants.some((x) => x.inputAudioEnabled);
                        } else {
                            currentClientRef.current.inputAudioEnabledForAll = payload.value;
                        }
                    }
                }
                break;

            // changed VIDEO of the host
            case WsKurentoIncomingTopicType.HOST_VIDEO_CHANGED:
                {
                    const participant = participantsRef.current[payload.targetId];

                    if (participant) {
                        participant.videoEnabled = payload.value;
                    }

                    if (currentClientRef.current?.isHost) {
                        const newParticipants = Object.values(participantsRef.current).filter((x) => !x.isHost);

                        if (newParticipants.length) {
                            currentClientRef.current.videoEnabledForAll = newParticipants.some((x) => x.videoEnabled);
                        } else {
                            currentClientRef.current.videoEnabledForAll = payload.value;
                        }
                    }
                }
                break;

            // changed MICROPHONE audio of the participant
            case WsKurentoIncomingTopicType.PARTICIPANT_AUDIO_CHANGED:
                {
                    const participant = participantsRef.current[payload.targetId];

                    if (participant) {
                        participant.outputAudioEnabled = payload.value;
                    }

                    if (currentClientRef.current?.isHost) {
                        const newParticipants = Object.values(participantsRef.current).filter((x) => !x.isHost);

                        if (newParticipants.length) {
                            currentClientRef.current.outputAudioEnabledForAll = newParticipants.some((x) => x.outputAudioEnabled);
                        } else {
                            currentClientRef.current.outputAudioEnabledForAll = payload.value;
                        }
                    }
                }
                break;

            // ICE of video-streaming
            case WsKurentoIncomingTopicType.ICE_CANDIDATE:
                {
                    const participant = participantsRef.current[payload.fhirId];
                    if (!participant) {
                        Logger.error('Participant not found', { participants: participantsRef, fhirId: payload.fhirId });
                        return;
                    }

                    participant.rtcPeer.addIceCandidate(payload.candidate, (error: any) => {
                        if (error) {
                            Logger.error('Error adding candidate for web: ' + error);
                        }
                    });
                }
                break;

            // ICE of screen-streaming
            case WsKurentoIncomingTopicType.ICE_CANDIDATE_SHARE:
                {
                    const participant = participantsRef.current[payload.fhirId];
                    if (!participant) {
                        Logger.error('Participant not found', { participants: participantsRef, fhirId: payload.fhirId });
                        return;
                    }

                    participant.rtcPeerScreen.addIceCandidate(payload.candidate, (error: any) => {
                        if (error) {
                            Logger.error('Error adding candidate for share: ' + error);
                            return;
                        }
                    });
                }
                break;

            // participant left the room
            case WsKurentoIncomingTopicType.PARTICIPANT_LEFT:
                onParticipantLeft(payload.fhirId);
                break;

            // participant was kicked from room
            case WsKurentoIncomingTopicType.PARTICIPANT_KICKED:
                onParticipantLeft(payload.fhirId, true);
                break;

            // session closed
            case WsKurentoIncomingTopicType.SESSION_CLOSED:
                onParticipantLeft(payload.fhirId, true);
                break;

            // error
            case WsKurentoIncomingTopicType.ERROR:
                if (payload.reason.includes('is not participant')) {
                    message.warning(t("media_session.messages.not_our_appointment"));
                    navigate('/');
                }
                break;
            default:
                break;
        }
    };

    const approveScreenSharing = (participant: KurentoParticipant) => {
        const confirmationPrompt = Modal.error({
            content: <p className="confirmPopup_text">{t("media_session.messages.screen_record_notation")}</p>,
            footer: [
                <Button
                    type="primary"
                    key="close"
                    className="confirmPopup_button w-100"
                    onClick={() => {
                        confirmationPrompt.destroy();
                        participant.toggleScreenSharing();
                    }}
                >
                    {t("media_session.messages.ok")}
                </Button>,
            ],
        });
    };

    const sendMessage = (messageObj: IKurentoWsMessage): void => {
        messageObj.appointmentId = currentRoomRef.current;

        if (DEBUG && messageObj.type !== WsKurentoOutgoingTopicType.ON_ICE_CANDIDATE) {
            console.log('kurentoTopic FROM ME', messageObj);
        }

        send('/msg/kurento/message', '/kurento/' + currentClientId, messageObj);
    };

    const runRtcPeer = (participant: KurentoParticipant, hostId: string, screenStream: any, videoElement: any) => {
        const isVideoStream = screenStream === undefined;

        const options = {
            localVideo: videoElement,
            videoStream: isVideoStream ? undefined : screenStream,
            mediaConstraints: isVideoStream ? (isEconomicVideo.current ? VideoStreamConstraintsEconomic : VideoStreamConstraintsFull) : null, // constraints for screenSharing sets from getDisplayMedia
            onicecandidate: isVideoStream ? participant.onIceCandidate.bind(participant) : participant.onIceCandidateScreen.bind(participant),
            configuration: RtcPeerConnectionConfiguration,
        };

        if (isVideoStream) {
            participant.rtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(options, (error: any) => {
                if (error) {
                    return Logger.error(error);
                }
                participant.rtcPeer.generateOffer(participant.offerToReceiveVideo.bind(participant));

                if (participant.id !== hostId) {
                    const host = createOrUpdateParticipant(hostId);
                    receiveVideoFrom(host);
                }
            });
        } else {
            participant.rtcPeerScreen = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, (error: any) => {
                if (error) {
                    return Logger.error(error);
                }
                participant.rtcPeerScreen.generateOffer(participant.offerToReceiveVideoScreen.bind(participant));
            });
        }
    };

    const receiveVideoFrom = (participant: KurentoParticipant) => {
        const video = participant.video;

        const options = {
            remoteVideo: video,
            onicecandidate: participant.onIceCandidate.bind(participant),
            configuration: RtcPeerConnectionConfiguration,
        };

        participant.rtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function (error: any) {
            if (error) {
                return Logger.error(error);
            }
            participant.rtcPeer.generateOffer(participant.offerToReceiveVideo.bind(participant));
        });
    };

    const receiveVideoScreenFrom = (participant: KurentoParticipant) => {
        const videoScreen = participant.screenSharingVideo;

        const options = {
            remoteVideo: videoScreen,
            onicecandidate: participant.onIceCandidateScreen.bind(participant),
            configuration: RtcPeerConnectionConfiguration,
        };

        participant.rtcPeerScreen = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, (error: any) => {
            if (error) {
                return Logger.error(error);
            }
            participant.rtcPeerScreen.generateOffer(participant.offerToReceiveVideoScreen.bind(participant));
        });
    };

    const screenSharingCallback = (participant: KurentoParticipant, hostId: string) => {
        if (participant.screenSharingRequested) {
            navigator.mediaDevices.getDisplayMedia(participant.isHost ? HostScreenSharingStreamConstraints : ScreenSharingStreamConstraints).then(
                (stream) => {
                    runRtcPeer(participant, hostId, stream, participant.screenSharingVideo);
                    participant.screenSharingActive = true;

                    stream.getVideoTracks()[0].addEventListener('ended', () => {
                        if (participant.isHost) {
                            sendMessage({
                                type: WsKurentoOutgoingTopicType.STOP_SCREEN_SHARE,
                                payload: {
                                    name: currentClientId,
                                    room: currentRoomRef.current,
                                },
                            });
                        }

                        participant.screenSharingActive = false;
                        dispatch(setActiveScreenSharing(false));
                    });

                    if (!participant.isHost) {
                        sendMessage({
                            type: WsKurentoOutgoingTopicType.START_SCREEN_SHARE,
                            payload: {
                                name: currentClientId,
                                room: currentRoomRef.current,
                            },
                        });
                    }
                },
                (error) => {
                    console.log(`getDisplayMedia error: ${error.name}`, error);

                    sendMessage({
                        type: WsKurentoOutgoingTopicType.STOP_SCREEN_SHARE,
                        payload: {
                            name: currentClientId,
                            room: currentRoomRef.current,
                        },
                    });

                    if (error.message === 'The request is not allowed by the user agent or the platform in the current context.') {
                        alert(t("media_session.messages.screen_demonstration_action_required"));
                    }
                },
            );
        } else {
            if (participant?.rtcPeerScreen) {
                const tracks = (participant.rtcPeerScreen as any)?.localVideo?.srcObject?.getTracks() || [];
                for (let i = 0; i < tracks.length; i++) {
                    tracks[i].stop();
                }

                sendMessage({
                    type: WsKurentoOutgoingTopicType.STOP_SCREEN_SHARE,
                    payload: {
                        name: currentClientId,
                        room: currentRoomRef.current,
                    },
                });
            }
        }
    };

    const createOrUpdateParticipant = (clientId: string, person?: IParticipantPerson, isHost?: boolean): KurentoParticipant => {
        let participant = participantsRef.current[clientId];
        if (participant === undefined) {
            if (!person) {
                Logger.error("Participant doesn't have 'person' field");

                // todo hardcode - this field must be always! It's here, just for local development
                person = {
                    firstName: 'Unknown',
                    role: 'Patient',
                };
            }

            participant = new KurentoParticipant(clientId, person!, isHost, (messageObj: IKurentoWsMessage) => sendMessage(messageObj));

            participantsRef.current[clientId] = participant;

            if (participant.isHost) {
                hostRef.current = participant;
                setIAmHost(participant.id === currentClientId);
            }

            console.log('New Participant', participant);

            setParticipants(Object.values(participantsRef.current).filter((x) => !x.isHost));
        }

        return participant;
    };

    const onParticipantLeft = (participantId: string, wasKicked = false) => {
        const participant = participantsRef.current[participantId];

        if (participant) {
            participant.screenSharingActive = false;
            participant.toggleScreenSharing(false);
            participant.dispose();

            delete participantsRef.current[participantId];
        }

        if (wasKicked) {
            if (participantId === currentClientId) {
                console.log(`Participant ${participantId} left room ${currentRoomRef.current}`);

                resetContextState();

                if (iAmHost) {
                    navigate('/');
                } else {
                    navigate({
                        pathname: '/dashboard',
                        search: `?refresh`,
                    });
                }

                exitFullscreen();
            }
        } else {
            // host left the session
            hostRef.current = null;
        }

        setParticipants(Object.values(participantsRef.current).filter((x) => !x.isHost));
    };

    const resetContextState = () => {
        participantsRef.current = {};
        setCurrentClientId('');
        setKurentoConnected(false);
        setIAmHost(false);
        setLobbyParticipants([]);
    };

    return (
        <KurentoContext.Provider
            value={{
                participants,
                lobbyParticipants,
                iAmHost,
                host,
                meAsParticipant,
                kurentoConnected,
                connectToAppointment,
                closeSession,
                approveFromLobby,
            }}
        >
            {children}
        </KurentoContext.Provider>
    );
};
