import { CrossRedIcon, DropdownArrowIcon, PlusCircle } from '@icons';
import { Button, Checkbox, Form, Select } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DiagnosisStep.module.scss';
import { DiagnosisEnum, DiagnosisOptions, DiagnosisPropsEnum } from './dictionary';
const { Option } = Select;

interface IDiagnosisItem {
    diagnosis?: DiagnosisEnum;
    props?: DiagnosisPropsEnum[];
}

interface IDiagnosisEyeBlock {
    items?: IDiagnosisItem[];
    comment?: string;
}

export interface IDiagnosisForm {
    patientIsHealthy?: boolean;
    anisometropia?: boolean;

    targetEye?: ('ou' | 'od' | 'os')[];
    ou?: IDiagnosisEyeBlock;
    od?: IDiagnosisEyeBlock;
    os?: IDiagnosisEyeBlock;
}

export const targetEyeOptionsFormArray = ['ou', 'od', 'os'];

export const diagnosesOptions = Object.keys(DiagnosisEnum);

interface IDiagnosisStepProps {
    initialValues?: IDiagnosisForm;
    disabled?: boolean;

    onSubmit: (formValue: IDiagnosisForm) => void;
    onFormChange: (formValue: IDiagnosisForm) => void;
}

export const DiagnosisStep = ({ onSubmit, onFormChange, initialValues, disabled }: IDiagnosisStepProps) => {
    const { t } = useTranslation();
    const [form] = useForm<IDiagnosisForm>();

    const ouValue = useWatch<IDiagnosisEyeBlock>('ou', form);
    const odValue = useWatch<IDiagnosisEyeBlock>('od', form);
    const osValue = useWatch<IDiagnosisEyeBlock>('os', form);

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const formChangeHandler = (values: Partial<IDiagnosisForm>, allValues: IDiagnosisForm) => {
        // const changedDiagnosis = values.ou || values.od || values.os;
        // if (changedDiagnosis) {
        //     const changedDiagnosisItself = changedDiagnosis.items?.some((x) => Object.keys(x || {}).includes('diagnosis'));

        //     if (changedDiagnosisItself) {
        //         const index = changedDiagnosis.items?.findIndex(Boolean);

        //         const newValue = { ...changedDiagnosis };
        //         newValue.items![index!].props = [];

        //         form.setFieldValue(Object.keys(values)[0], newValue);
        //     }
        // }

        onFormChange(allValues);
    };

    const getTargetEyeValue = (eye: 'ou' | 'od' | 'os') => {
        switch (eye) {
            case 'ou':
                return ouValue;
            case 'od':
                return odValue;
            case 'os':
                return osValue;
            default:
                return null;
        }
    };

    // Ant doesn't rerender the whole form  if some field changes (even with shouldUpdate)
    // https://nanxiaobei.medium.com/watch-a-more-elegant-way-to-monitor-antd-form-field-changes-7c9b12457d67
    const patientIsHealthyValue = useWatch<boolean>('patientIsHealthy', form);
    const targetEyeValue = useWatch<('ou' | 'od' | 'os')[]>('targetEye', form);

    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>{t('consultationSession.diagnosisStep.title')}</h2>

            <Form
                form={form}
                autoComplete="off"
                initialValues={{
                    ou: initialValues?.ou || { items: [{ diagnosis: undefined, props: [] }] },
                    od: initialValues?.od || { items: [{ diagnosis: undefined, props: [] }] },
                    os: initialValues?.os || { items: [{ diagnosis: undefined, props: [] }] },
                }}
                onFinish={onSubmit}
                onValuesChange={formChangeHandler}
                layout="vertical"
                disabled={disabled}
            >
                <div className={styles.checkboxGroup}>
                    <Form.Item name="patientIsHealthy" valuePropName="checked" className={styles.checkboxGroup}>
                        <Checkbox>{t("diagnosis_step.patient_healthy")}</Checkbox>
                    </Form.Item>
                </div>

                <div className={styles.checkboxGroup}>
                    <Form.Item name="anisometropia" valuePropName="checked" className={styles.checkboxGroup}>
                        <Checkbox>{t("diagnosis_step.anisometropia")}</Checkbox>
                    </Form.Item>
                </div>

                {!patientIsHealthyValue && (
                    <>
                        <p className={styles.groupLabel}>{t("diagnosis_step.diagnostic_hypothesis")}</p>
                        <div className={styles.targetEyeSelector}>
                            <span>{t("diagnosis_step.for_eyes")}:</span>
                            <Form.Item
                                name="targetEye"
                                className={classNames(styles.checkboxGroup, 'mb-0')}
                                rules={[{ required: true, message: t("diagnosis_step.selection_required") }]}
                            >
                                <Checkbox.Group>
                                    {targetEyeOptionsFormArray.map((x) => (
                                        <Checkbox key={x} value={x}>
                                            {t('consultationSession.diagnosisStep.eyes.' + x)}
                                        </Checkbox>
                                    ))}
                                </Checkbox.Group>
                            </Form.Item>
                        </div>

                        {targetEyeValue?.map((eye, i) => {
                            return (
                                <div key={i}>
                                    <p className={styles.groupLabel}>{t('consultationSession.diagnosisStep.eyes.' + eye)}:</p>

                                    <Form.List name={[eye, 'items']}>
                                        {(fields, { add, remove }) => {
                                            return (
                                                <>
                                                    {fields.map((field, index) => {
                                                        const targetEyeValue = getTargetEyeValue(eye)?.items;
                                                        const diagnosisItem = targetEyeValue?.[field.name];
                                                        const alreadySelectedDiagnoses = (targetEyeValue || [])
                                                            ?.filter((x) => x?.diagnosis)
                                                            .map((x) => x.diagnosis);

                                                        return (
                                                            <div className={styles.propsBlock} key={field.key}>
                                                                <div className={styles.propsGrid}>
                                                                    <Form.Item
                                                                        name={[field.name, 'diagnosis']}
                                                                        rules={[{ required: true, message: t("required_field") }]}
                                                                    >
                                                                        <Select
                                                                            placeholder={t("diagnosis_step.select_diagnostic_hypothesis")}
                                                                            suffixIcon={<DropdownArrowIcon />}
                                                                            onChange={() => {
                                                                                // reset selected props, if diagnosis changed
                                                                                setTimeout(() => {
                                                                                    form.setFieldValue([eye, 'items', field.name, 'props'], []);
                                                                                });
                                                                            }}
                                                                        >
                                                                            {diagnosesOptions.map((option) => (
                                                                                <Option
                                                                                    key={option}
                                                                                    value={option}
                                                                                    disabled={
                                                                                        alreadySelectedDiagnoses.indexOf(option as DiagnosisEnum) !==
                                                                                        -1
                                                                                    }
                                                                                >
                                                                                    {t('enums.DiagnosisEnum.' + option)}
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>

                                                                    {diagnosisItem?.diagnosis &&
                                                                        DiagnosisOptions[diagnosisItem.diagnosis].map((options, i) => {
                                                                            return (
                                                                                <Form.Item key={i} name={[field.name, 'props', i]}>
                                                                                    <Select
                                                                                        suffixIcon={<DropdownArrowIcon />}
                                                                                        placeholder={!disabled ? i + 1 + t("diagnosis_step.n_cell") : ''}
                                                                                        allowClear
                                                                                    >
                                                                                        {options.map((x) => (
                                                                                            <Option key={x} value={x}>
                                                                                                {t('enums.DiagnosisPropsEnum.' + x)}
                                                                                            </Option>
                                                                                        ))}
                                                                                    </Select>
                                                                                </Form.Item>
                                                                            );
                                                                        })}
                                                                    {
                                                                        diagnosisItem?.diagnosis === DiagnosisEnum.OTHER ? 
                                                                            <Form.Item 
                                                                                name={[field.name, 'comment']} 
                                                                                className={classNames(styles.formGroup, styles.additionalField, 'mt-2')}
                                                                            >
                                                                                <TextArea placeholder={t("diagnosis_step.additionally")} />
                                                                            </Form.Item>
                                                                        : null
                                                                    }
                                                                </div>

                                                                {fields.length > 1 && (
                                                                    <Button
                                                                        type="ghost"
                                                                        className={styles.removeButton}
                                                                        onClick={() => remove(index)}
                                                                        disabled={disabled}
                                                                    >
                                                                        <CrossRedIcon />
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        );
                                                    })}

                                                    {!disabled && (
                                                        <Button className={styles.addButton} type="ghost" onClick={() => add()}>
                                                            <PlusCircle />
                                                            {t("diagnosis_step.add_hypothesis_for")} {t('consultationSession.diagnosisStep.eyes.' + eye)}
                                                        </Button>
                                                    )}
                                                </>
                                            );
                                        }}
                                    </Form.List>

                                    <Form.Item name={[eye, 'comment']} className={classNames(styles.formGroup, 'mt-4')}>
                                        <TextArea placeholder={t("diagnosis_step.additionally")} />
                                    </Form.Item>
                                </div>
                            );
                        })}

                        {/* <Form.Item name="detailsComment" className={classNames(styles.formGroup, 'mt-5')}>
                            <TextArea placeholder={t("placeholder_comment")} rows={8} autoSize={{ minRows: 7 }} />
                        </Form.Item> */}
                    </>
                )}

                {!disabled && (
                    <Button className="w-100 mt-3" type="primary" htmlType="submit">
                        {t("next_button")}
                    </Button>
                )}
            </Form>
        </div>
    );
};
