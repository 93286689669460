import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobalLoading } from 'src/store/core';
import styles from './GlobalLoader.module.scss';

const LOADER_DEBOUNCE_TIME = 300;

export const GlobalLoader = () => {
    const isLoading = useSelector(selectGlobalLoading);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handler = setTimeout(() => {
            setVisible(isLoading);
        }, LOADER_DEBOUNCE_TIME);

        return () => {
            clearTimeout(handler);
        };
    }, [isLoading]);

    return (
        <>
            {visible && (
                <div className={styles.wrapper}>
                    <div className={styles.loader}>
                        <div className={`${styles.inner} ${styles.one}`}></div>
                        <div className={`${styles.inner} ${styles.two}`}></div>
                        <div className={`${styles.inner} ${styles.three}`}></div>
                    </div>
                </div>
            )}
        </>
    );
};
