import {
    AboutPractitionerDTORoleCode,
    AppointmentDTOAppointmentType,
    AppointmentDTOPartOf,
    AppointmentDTOServiceType,
    AppointmentDTOStatus,
    EncounterDetailDTOStatus,
    getPatientEncounters,
    PatientEncounterDTOEncounterStatus,
    PatientEncounterDTOPaymentStatus,
} from '@api/mainServiceAPI';
import { AppointmentStatus } from '@enums';
import { Bubble, CalendarNo, ChatWith3DotsIcon, ConsultationIcon, DiagnosticIcon2, Dino2_1, Dino7, Procedure } from '@icons';
import { selectCurrentProfile } from '@sliceUser';
import { Button, Col, Row } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EncounterStatusBadge } from 'src/components/EncounterStatusBadge/EncounterStatusBadge';
import styles from './PatientAppointmentsPage.module.scss';

type AppointmentFilterType = AppointmentDTOServiceType | 'all';

interface IPatientAppointmentChip {
    id: string;
    dateTime: Date;
    dateLabel?: string;
    serviceType?: AppointmentDTOServiceType;
    appointmentType?: AppointmentDTOAppointmentType;
    status?: AppointmentDTOStatus;
    paymentStatus?: PatientEncounterDTOPaymentStatus;
    encounterStatus?: PatientEncounterDTOEncounterStatus;
    practitionerName?: string;
    practitionerRole?: AboutPractitionerDTORoleCode;
    partOf?: AppointmentDTOPartOf;
    hasActiveMessages?: boolean;
}

export const getEncounterStatusForPatient = (encounterStatus?: EncounterDetailDTOStatus): AppointmentStatus => {
    if (encounterStatus === EncounterDetailDTOStatus.pending) {
        return AppointmentStatus.pending;
    }

    if (encounterStatus === EncounterDetailDTOStatus.booked || encounterStatus === EncounterDetailDTOStatus['checked-in']) {
        return AppointmentStatus.confirmed;
    }

    if (encounterStatus === EncounterDetailDTOStatus.fulfilled) {
        return AppointmentStatus.completed;
    }

    if (encounterStatus === EncounterDetailDTOStatus.cancelled) {
        return AppointmentStatus.cancelled;
    }

    return AppointmentStatus.none;
};

export const PatientAppointmentsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentProfile = useSelector(selectCurrentProfile);

    const [selectedFiltering, setSelectedFiltering] = useState<AppointmentFilterType>('all');
    const [filteringCount, setFilteringCount] = useState<{ all: number; consultation: number; diagnostics: number; procedures: number }>({
        all: 0,
        consultation: 0,
        diagnostics: 0,
        procedures: 0,
    });

    const [appointmentsListOrigin, setAppointmentsListOrigin] = useState<IPatientAppointmentChip[] | null>(null);
    const [appointmentsList, setAppointmentsList] = useState<IPatientAppointmentChip[] | null>(null);
    const [firstWithMessage, setFirstWithMessage] = useState<IPatientAppointmentChip | undefined>();

    useEffect(() => {
        if (currentProfile) {
            getPatientEncounters(currentProfile.fhirId!).then((res) => {
                const mappedData: IPatientAppointmentChip[] =
                    res.data?.content?.map((x) => {
                        const dayJsDate = dayjs(x.dateTime);

                        return {
                            id: x.encounterId!,
                            hasActiveMessages: x.hasActiveMessages,
                            dateTime: new Date(x.dateTime!),
                            dateLabel: dayJsDate.format('D MMMM, dd HH:mm'),
                            serviceType: x.serviceType,
                            appointmentType: x.appointmentType,
                            practitionerName: x.practitioner?.fullName || `${x.practitioner?.firstName} ${x.practitioner?.lastName}`,
                            practitionerRole: x.practitioner?.roleCode,
                            partOf: x.partOf,
                            status: x.appointmentStatus,
                            encounterStatus: x.encounterStatus,
                            paymentStatus: x.paymentStatus,
                        };
                    }) || [];

                mappedData.sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime());
                setFirstWithMessage(mappedData.find((app) => app.hasActiveMessages));

                setFilteringCount({
                    all: mappedData.length,
                    consultation: mappedData.filter((x) => x.serviceType === 'consultation').length,
                    diagnostics: mappedData.filter((x) => x.serviceType === 'diagnostics').length,
                    procedures: mappedData.filter((x) => x.serviceType === 'therapy-session').length,
                });

                setAppointmentsListOrigin(mappedData);
            });
        }
    }, [currentProfile]);

    useEffect(() => {
        if (appointmentsListOrigin) {
            switch (selectedFiltering) {
                case 'all':
                    setAppointmentsList([...appointmentsListOrigin].reverse());
                    break;
                default:
                    setAppointmentsList(appointmentsListOrigin.filter((x) => x.serviceType === selectedFiltering).reverse());
                    break;
            }
        }
    }, [appointmentsListOrigin, selectedFiltering]);

    const openAppointmentPage = (encounterId: string) => {
        navigate('/appointments/' + encounterId);
    };

    const handleConsultation = () => {
        navigate('/consultation');
    };

    return (
        <div className={styles.wrapper}>
            <h1>{t("patient_appointments.title")}</h1>

            {!!appointmentsListOrigin && !!appointmentsList && (
                <>
                    <Row align="middle" justify="space-between" className={styles.filters}>
                        {!!appointmentsListOrigin.length && (
                            <Col span={18} className="d-flex align-items-center gap-2">
                                <label className={classNames(styles['filter-btn'], selectedFiltering === 'all' && styles.active)}>
                                    {t("patient_appointments.all")} ({filteringCount.all})
                                    <input
                                        className="d-none"
                                        type="radio"
                                        name="filter-group"
                                        checked={selectedFiltering === 'all'}
                                        onChange={() => setSelectedFiltering('all')}
                                    />
                                </label>
                                <label className={classNames(styles['filter-btn'], selectedFiltering === 'consultation' && styles.active)}>
                                    {t("patient_appointments.consultations")} ({filteringCount.consultation})
                                    <input
                                        className="d-none"
                                        type="radio"
                                        name="filter-group"
                                        checked={selectedFiltering === 'consultation'}
                                        onChange={() => setSelectedFiltering('consultation')}
                                    />
                                </label>
                                <label className={classNames(styles['filter-btn'], selectedFiltering === 'diagnostics' && styles.active)}>
                                    {t("patient_appointments.diagnostics")} ({filteringCount.diagnostics})
                                    <input
                                        className="d-none"
                                        type="radio"
                                        name="filter-group"
                                        checked={selectedFiltering === 'diagnostics'}
                                        onChange={() => setSelectedFiltering('diagnostics')}
                                    />
                                </label>
                                <label className={classNames(styles['filter-btn'], selectedFiltering === 'therapy-session' && styles.active)}>
                                    {t("patient_appointments.care_plans")} ({filteringCount.procedures})
                                    <input
                                        className="d-none"
                                        type="radio"
                                        name="filter-group"
                                        checked={selectedFiltering === 'therapy-session'}
                                        onChange={() => setSelectedFiltering('therapy-session')}
                                    />
                                </label>
                            </Col>
                        )}
                    </Row>

                    <Row justify="space-between" align="top" gutter={32}>
                        <Col span={!!(appointmentsList.length && appointmentsListOrigin.length) ? 18 : 24}>
                            {!!appointmentsList.length ? (
                                appointmentsList.map((appo, i) => {
                                    return (
                                        <Row justify="space-between" align="middle" key={i} className={classNames(styles.schedule)}>
                                            <Col lg={18} md={15} sm={14} className="d-flex align-items-center">
                                                {appo.serviceType === AppointmentDTOServiceType.diagnostics && (
                                                    <div className={styles.icon}>
                                                        <DiagnosticIcon2 />
                                                    </div>
                                                )}
                                                {appo.serviceType === AppointmentDTOServiceType['therapy-session'] && (
                                                    <div className={styles.icon}>
                                                        <Procedure />
                                                    </div>
                                                )}
                                                {appo.serviceType === AppointmentDTOServiceType.consultation && (
                                                    <div className={styles.icon}>
                                                        <ConsultationIcon />
                                                    </div>
                                                )}

                                                <div className="flex-grow-1">
                                                    <p className={styles['schedule-title']}>
                                                        {appo.serviceType === AppointmentDTOServiceType['therapy-session']
                                                            ? t('enums.planDefinition.' + appo.partOf, t("patient_appointments.care_plan"))
                                                            : appo.serviceType === AppointmentDTOServiceType.diagnostics
                                                            ? t('enums.diagnosticPlanDefinition.' + appo.partOf, t("patient_appointments.diagnostic"))
                                                            : t('enums.appointmentTypes.' + appo.appointmentType)}
                                                    </p>
                                                    <div className="d-flex align-items-center">
                                                        <div className={styles.time}>{appo.dateLabel}</div>
                                                        {!!appo.practitionerName && (
                                                            <div className="d-flex">
                                                                <p className={styles.doctorLabel}>
                                                                    {t('appointment.practitionerRoles.' + appo.practitionerRole)}:
                                                                </p>
                                                                <p className={styles.docName}>{appo.practitionerName}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="d-flex justify-content-end" span={4}>
                                                {/* <div className={classNames(styles.status, styles[getEncounterStatusForPatient(appo.status)])}>
                                                    {t('patientAppointmentsPage.statuses.' + getEncounterStatusForPatient(appo.status))}
                                                </div> */}

                                                <EncounterStatusBadge encounterStatus={appo?.status} paymentStatus={appo?.paymentStatus} />
                                            </Col>
                                            <Col className="d-flex justify-content-end" span={2}>
                                                <Button className="p-0" type="link" onClick={() => openAppointmentPage(appo.id)}>
                                                    <ChatWith3DotsIcon className={classNames(appo.hasActiveMessages && styles.hasMsgs)} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    );
                                })
                            ) : (
                                <div>
                                    <div className={styles.noAppointmentsWrapper}>
                                        {!!appointmentsListOrigin.length ? (
                                            <>
                                                <CalendarNo />
                                                <p className={styles.noAppointments}>{t("no_appointments")}</p>
                                            </>
                                        ) : (
                                            <div className={styles.noAppointmentDino}>
                                                <Dino2_1 />
                                                <div className={styles.dinoBubble}>
                                                    <Bubble />
                                                    <p>{t("patient_appointments.lets_start")}</p>
                                                </div>
                                                <p className={styles.noAppointments}>{t("patient_appointments.no_appointments")}</p>
                                                <Button type="primary" size="large" onClick={handleConsultation} className={styles.consultationBtn}>
                                                    {t("patient_appointments.create_appointment")}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Col>
                        {firstWithMessage && (
                            <Col span={6}>
                                <div className="position-relative">
                                    <div className={styles.dino1}>
                                        <div className="w-100">
                                            <div className={classNames(styles.bubble, styles.hasMsgDino)}>
                                                <p>{t("patient_appointments.new_support_message")} </p>
                                                <Bubble />
                                            </div>
                                        </div>
                                        <Dino7 />
                                    </div>
                                    <p className={styles.rightLabel}>
                                        <ChatWith3DotsIcon
                                            onClick={() => openAppointmentPage(firstWithMessage.id)}
                                            className={classNames(classNames(styles.hasMsgs, styles.centered))}
                                        />
                                        {t("patient_appointments.appointment_card_hint_1")}
                                    </p>
                                </div>
                            </Col>
                        )}
                        {!!(!firstWithMessage && appointmentsList?.length && appointmentsListOrigin.length) && (
                            <Col span={6}>
                                <div className="position-relative">
                                    <div className={styles.dino1}>
                                        <div className="w-100">
                                            <div className={styles.bubble}>
                                                <p>{t("patient_appointments.appointment_card_hint_3")} </p>
                                                <Bubble />
                                            </div>
                                        </div>
                                        <Dino7 />
                                    </div>
                                    <p className={styles.rightLabel}>
                                        {t("patient_appointments.appointment_card_hint_2")}
                                    </p>
                                </div>
                            </Col>
                        )}
                    </Row>
                </>
            )}
        </div>
    );
};
