import {getConsultationDischarge, saveSignature} from '@api/documentServiceAPI';
import {CertificateItem} from '@utils/crypto-helpers';
import {useTranslation} from 'react-i18next';
import {FileSigning} from '../ReportStep/FileSigning/FileSigning';
import styles from './ReportStep.module.scss';
import {useSelector} from "react-redux";
import {selectDeliveryAddressActive, selectSignatureFilesActive} from "../../../../../../../store/organization";

interface IReportStepProps {
    encounterId?: string;
    disabled?: boolean;
    patientWasHere?: boolean;
    conclusionFileNameLabel: string;

    onSubmit: (justCloseRoom: boolean) => void;
}

export const ReportStep = ({ onSubmit, encounterId, disabled, patientWasHere, conclusionFileNameLabel }: IReportStepProps) => {
    const { t } = useTranslation();
    const signatureFilesActive = useSelector(selectSignatureFilesActive);

    const requestFile = (certificate: CertificateItem): Promise<any> => {
        if (!encounterId) {
            return Promise.reject();
        }


        return getConsultationDischarge(
            {
                encounterId,
                signatureAbout: signatureFilesActive ? certificate.certificateData : {
                    serialNumber: '',
                    subjectGivenName: '',
                    subjectName: '',
                    subjectSurname: '',
                    validFrom: '',
                    validTo: '',
                },
            },
            { responseType: 'arraybuffer' },
        );
    };

    const sendSignature = (file: Blob): Promise<any> => {
        if (!encounterId) {
            return Promise.reject();
        }

        if (signatureFilesActive && !patientWasHere) {
            alert(t("consultationSession.reportStep.patient_absent_cannot_end_session"));
            return Promise.reject();
        }

        return saveSignature({ encounterId, file });
    };

    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>{t('consultationSession.reportStep.title')}</h2>

            <FileSigning
                ok={() => onSubmit(false)}
                close={() => onSubmit(true)}
                requestFile={requestFile}
                sendSignature={sendSignature}
                disabled={disabled}
                fileNameLabel={conclusionFileNameLabel}
            />
        </div>
    );
};
