import { FileSafeDTOBucketType, UserDocumentDTOType } from '@api/documentServiceAPI';
import {
    AppointmentDTOAppointmentType,
    CarePlanDTOStatus,
    CarePlanResultsDTO,
    ChatDTO,
    EncounterDTODetails,
    EncounterDetailDTOPaymentStatus,
    EncounterDetailDTOServiceType,
    EncounterDetailDTOStatus,
    UpdateCarePlanDTO,
    UserDocumentDTO,
    obtainEncounterDetails,
    obtainEncounterDetailsByAppointmentId,
    updateCarePlan,
} from '@api/mainServiceAPI';
import {getCashReceipt, getCashReceipt1, getPaymentInfo, PaymentInfoResponseDTO} from '@api/paymentServiceAPI';
import { CountdownTimer } from '@components';
import { AppointmentStatus } from '@enums';
import {
    AlertIconOutlined,
    CalendarIcon,
    CameraIconGrey,
    CheckCircle48,
    ChevronLeft,
    DocumentIcon,
    DownloadIcon,
    MoreIcon,
    Procedure, ProcedureFailedIcon, ProcedureTimerIcon1,
    SettingsIcon,
    UserIcon,
} from '@icons';
import { IChatMessageDto } from '@models';
import { setFileViewer } from '@sliceCore';
import { UserRole, selectCurrentProfile } from '@sliceUser';
import { StompSubscription } from '@stomp/stompjs';
import { capitalizeText } from '@utils/utils';
import { WsTopic } from '@utils/websocket.topics';
import { Button, Col, Dropdown, MenuProps, Row, Tag, message } from 'antd';
import classNames from 'classnames';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { MED_TIME_BEFORE_START_OF_SESSION } from 'src/ADMINISTRATIVE_CONFIG';
import { getOcclusionByDays } from 'src/COURSES_CONFIG';
import { Chats } from 'src/components/Chats/Chats';
import { CourseSettingsModal } from 'src/components/CourseSettingsModal/CourseSettingsModal';
import { EncounterStatusBadge } from 'src/components/EncounterStatusBadge/EncounterStatusBadge';
import { PatientForms } from 'src/components/PatientForms/PatientForms';
import { WebsocketContext } from 'src/contexts/ws.context';
import { CheckNotExistModal } from 'src/modals/CheckNotExistModal/CheckNotExistModal';
import { getEncounterStatus } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/dictionary';
import { CourseResultsModal } from 'src/pages/PatientMedcard/modal/CourseResultsModal/CourseResultsModal';
import { ICourseForm } from 'src/pages/_shared/DoctorAppointmentPage/sessions/ConsultationSession/steps/CourseStep/CourseStep';
import styles from './HeadPhysicianAppointmentItemPage.module.scss';
import {selectPaymentServiceActive} from "../../../store/organization";

interface IHeadPhysicianAppointmentItemPageProps {
    isProcedureCourse: boolean;
}

export const HeadPhysicianAppointmentItemPage = ({ isProcedureCourse }: IHeadPhysicianAppointmentItemPageProps) => {
    const { wsClient, send } = useContext(WebsocketContext);
    const paymentServiceActive = useSelector(selectPaymentServiceActive);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const currentProfile = useSelector(selectCurrentProfile);
    const [supportChat, setSupportChat] = useState<ChatDTO>();
    const [doctorChat, setDoctorChat] = useState<ChatDTO>();
    const [currentChat, setCurrentChat] = useState<ChatDTO>();
    const [showCourseResultsModal, setShowCourseResultsModal] = useState(false);
    const [showCourseSettingsModal, setShowCourseSettingsModal] = useState(false);
    const [selectedCarePlanResults, setSelectedCarePlanResults] = useState<CarePlanResultsDTO>();
    const [showCheckNotExistModal, setShowCheckNotExistModal] = useState(false);

    const [encounter, setEncounter] = useState<EncounterDTODetails>();
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfoResponseDTO>();


    useEffect(() => {
        retrieveEncounterDetails();
    }, []);

    useEffect(() => {
        if (supportChat) {
            let subscription: StompSubscription;

            if (wsClient) {
                subscription = wsClient.subscribe(WsTopic.chatTopic + supportChat.chat?.id, (message: any) => {
                    if (!!message.body) {
                        const msg = JSON.parse(message.body);
                        console.log('chatTopic', msg);
                    }
                });
            }

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [supportChat, wsClient]);

    useEffect(() => {
        if (doctorChat) {
            let subscription: StompSubscription;

            if (wsClient) {
                subscription = wsClient.subscribe(WsTopic.chatTopic + doctorChat.chat?.id, (message: any) => {
                    const msg = JSON.parse(message.body);
                    console.log('chatTopic', msg);
                });
            }

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [doctorChat, wsClient]);

    const retrieveEncounterDetails = () => {
        if (params.appointmentId) {
            if (isProcedureCourse) {
                obtainEncounterDetails(params.appointmentId).then((result) => {
                    if (result.data) {
                        setEncounter(result.data);

                        const myChats = result.data?.chats?.filter((x) => x.chat?.participants?.some((y) => y.profile === 'Patient'));
                        const supportChat = myChats?.find((x) => x.chat?.participants?.some((y) => y.profile === 'Call_center_manager'));
                        const doctorChat = myChats?.find((x) => x.chat?.participants?.some((y) => y.profile === 'Practitioner'));

                        setSupportChat(supportChat);
                        setDoctorChat(doctorChat);
                        setCurrentChat(supportChat);
                        result.data.encounterDetailDTO?.paymentStatus === 'completed' && retrievePaymentInfo(result.data.encounterDetailDTO.encounterFhirId!);
                    }
                });
            } else {
                obtainEncounterDetailsByAppointmentId(params.appointmentId).then((result) => {
                    if (result.data) {
                        setEncounter(result.data);

                        const myChats = result.data?.chats?.filter((x) => x.chat?.participants?.some((y) => y.profile === 'Patient'));
                        const supportChat = myChats?.find((x) => x.chat?.participants?.some((y) => y.profile === 'Call_center_manager'));
                        const doctorChat = myChats?.find((x) => x.chat?.participants?.some((y) => y.profile === 'Practitioner'));

                        setSupportChat(supportChat);
                        setDoctorChat(doctorChat);
                        setCurrentChat(supportChat);
                        result.data.encounterDetailDTO?.paymentStatus === 'completed' && retrievePaymentInfo(result.data.encounterDetailDTO.encounterFhirId!);
                    }
                });
            }
        }
    };

    const isMine = () => {
        return encounter?.encounterDetailDTO?.practitionerFhirId === currentProfile?.fhirId;
    };

    const isConsultation = () => {
        return encounter?.encounterDetailDTO?.serviceType === EncounterDetailDTOServiceType.consultation;
    };

    const isDiagnostic = () => {
        return encounter?.encounterDetailDTO?.serviceType === EncounterDetailDTOServiceType.diagnostics;
    };

    const isCourse = () => {
        return encounter?.encounterDetailDTO?.appointmentType === AppointmentDTOAppointmentType.course;
    };

    const onBack = () => {
        navigate(-1);
    };

    const retrievePaymentInfo = (encounterId: string) => {
        getPaymentInfo({
            encounterId,
        }).then((res) => {
            setPaymentInfo(res.data);
        });
    }

    // todo payment logic after backend
    const items: MenuProps['items'] = [
        {
            label: <a onClick={() => console.log('Copy payment link')}>{t('appointment.paymentOptions.copyLink')}</a>,
            key: '0',
        },
        {
            label: <a onClick={() => console.log('Send payment on email')}>{t('appointment.paymentOptions.sendEmail')}</a>,
            key: '1',
        },
    ];

    const onPatientDetails = (patientId?: string) => {
        navigate('/medcard/' + patientId);
    };

    const onDoctorDetails = (practitionerUserId?: string) => {
        navigate('/staff/' + practitionerUserId);
    };

    // todo connect to consultation
    const onConnectToConsultation = () => {
        navigate('/appointment?appointmentId=' + encounter?.encounterDetailDTO?.appointmentFhirId);
    };

    const onSendMessage = (textMessage: string) => {
        if (currentChat) {
            const messageDto: IChatMessageDto = {
                type: 'NEW_MSG',
                conversationId: currentChat.chat!.id,
                payload: textMessage,
                sender: currentProfile!.fhirId! as any,
            };

            console.log(messageDto);
            send('/msg/chat/message', '/chat/' + currentChat.chat!.id!, messageDto);
        }
    };

    const isDocumentFromPatients = (document: UserDocumentDTO): boolean => {
        return (
            document.type === UserDocumentDTOType.DISCHARGE_SUMMARY ||
            document.type === UserDocumentDTOType.CONSULTATION ||
            document.type === UserDocumentDTOType.PERSONAL_DATA_STATEMENT ||
            document.type === UserDocumentDTOType.CERTIFIED_OPHTHALMOLOGISTS_REPORT ||
            document.type === UserDocumentDTOType.ANALYZES ||
            document.type === UserDocumentDTOType.SNAPSHOTS ||
            document.type === UserDocumentDTOType.OTHER
        );
    };

    const isDocumentFromDoctor = (document: UserDocumentDTO): boolean => {
        return (
            document.type === UserDocumentDTOType.DISCHARGE_SUMMARY ||
            // document.type === UserDocumentDTOType.CERTIFIED_OPHTHALMOLOGISTS_REPORT ||
            document.type === UserDocumentDTOType.CONCLUSION ||
            document.type === UserDocumentDTOType.SIGNATURE
        );
    };

    const isDocumentVideo = (document: UserDocumentDTO): boolean => {
        return document.type === UserDocumentDTOType.VIDEO;
    };

    const openDocument = (doc: UserDocumentDTO & { s3Key?: string }) => {
        const key = doc.s3Key || doc.s3key;

        if (key) {
            dispatch(
                setFileViewer({
                    s3Key: key,
                    bucketType: doc.type === FileSafeDTOBucketType.VIDEO ? FileSafeDTOBucketType.VIDEO : FileSafeDTOBucketType.DOCUMENT,
                    fileName: doc.extension ? `${doc.name}.${doc.extension}` : (doc.name as string),
                    extension: doc.extension,
                }),
            );
        }
    };

    const onThreatmentResultsDetails = (patientId?: string) => {
        console.log('Open results.');
    };

    const encounterStatus = getEncounterStatus(encounter?.encounterDetailDTO?.status, encounter?.encounterDetailDTO?.paymentStatus);

    const getReceipt = () => {
        getCashReceipt({
            encounterId: encounter?.encounterDetailDTO?.encounterFhirId,
        }).then((res) => {
            if (res.data) {
                window.open(res.data[0]);
            }
        });
    };

    const openCashReceipt = () => {
        window.open(paymentInfo?.receiptUrl![0]);
    }

    const handleShowResultsModal = (carePlanResults: CarePlanResultsDTO) => {
        setShowCourseResultsModal(true);
        setSelectedCarePlanResults(carePlanResults);
    };

    const submitCourseSettings = (formValue: ICourseForm) => {
        if (formValue) {
            const {
                planDefinitionID,
                durationInDays,
                needGlasses,
                occlusionType,
                procedures,
                targetEye,
                glassesWithoutDyoSize,
                needGlassesWithoutDyo,
                occlusionSettings,
                deviceDefinitionIDs,
            } = formValue;

            const mappedOcclusionDays = occlusionType === 'manually' ? occlusionSettings : getOcclusionByDays(durationInDays || 0, occlusionType);

            const baseBody = {
                needGlassesWithoutDyo,
                glassesWithoutDyoSize,
                targetEye,
                needGlasses,
                durationInDays,
                occlusionType,
                planDefinitionID,
                deviceDefinitionIDs,

                occlusionSettings: mappedOcclusionDays,
                procedureSettings: procedures
                    ?.filter((x) => x.selected)
                    .map((x) => {
                        if (targetEye === 'ou') {
                            if (occlusionType === 'without') {
                                delete x.od;
                                delete x.os;
                            } else {
                                delete x.ou;
                            }
                        } else {
                            delete x.ou;
                        }

                        return x;
                    }),
            };

            const bodyUpdate: UpdateCarePlanDTO = {
                ...baseBody,
                notRecommended: false,
            };

            // todo for the current project version - we can have only 1 carePlan
            const carePlan = encounter?.carePlanResults?.carePlan;

            updateCarePlan(carePlan!.fhirId!, bodyUpdate).then((res) => {
                setShowCourseSettingsModal(false);
                message.success(t("head_physician_appointment.procedure_settings_changed"));
                retrieveEncounterDetails();
            });
        }
    };

    return encounter ? (
        <div className={styles.wrapper}>
            <div className="d-flex justify-content-between">
                <div className={styles.titleContainer}>
                    <Button onClick={() => onBack()} className="backBtn">
                        <ChevronLeft className="transparent" />
                    </Button>
                    <p className={styles.title}>
                        {encounter?.encounterDetailDTO?.appointmentType && encounter?.encounterDetailDTO?.appointmentType === 'diagnostic'
                            ? t(`patientAppointmentItemPage.title.diagnosticPlanDefinition.${encounter?.partOf}`)
                            : encounter?.encounterDetailDTO?.appointmentType && encounter?.encounterDetailDTO?.appointmentType === 'course'
                            ? t(`patientAppointmentItemPage.title.coursePlanDefinition.${encounter?.partOf}`)
                            : t(`patientAppointmentItemPage.title.${encounter?.encounterDetailDTO?.appointmentType}`)}
                    </p>
                </div>
            </div>

            <Row gutter={24}>
                <Col md={16} sm={24} xs={24}>
                    <Col md={24} sm={24} xs={24}>
                        <div className={styles.eventBottomBlock}>
                            {/* Encounter Field */}
                            <div className={styles.infoField}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div>
                                            <CalendarIcon />
                                        </div>
                                        <div>
                                            <p>{t(`appointment.dateTime`)}</p>
                                            <div className="d-flex">
                                                <p className={styles.dateText}>
                                                    {encounter?.encounterDetailDTO?.dateTime &&
                                                        format(new Date(encounter?.encounterDetailDTO?.dateTime), 'dd.MM.yyyy HH:mm')}
                                                </p>

                                                <div className={styles.statusField}>
                                                    <EncounterStatusBadge
                                                        encounterStatus={encounter?.encounterDetailDTO?.status}
                                                        paymentStatus={encounter?.okoEventDTO?.paymentStatus}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {encounter?.encounterDetailDTO?.dateTime &&
                                        encounterStatus === AppointmentStatus.confirmed &&
                                        encounter?.encounterDetailDTO?.practitionerFhirId === currentProfile?.fhirId && (
                                            <CountdownTimer
                                                timeX={new Date(1)}
                                                before={MED_TIME_BEFORE_START_OF_SESSION}
                                                keepAfterX={true}
                                                render={(timeLabel: string) => (
                                                    <div className="d-flex">
                                                        {timeLabel && (
                                                            <Tag className={styles.startsOver}>
                                                                {t('docMainPage.startsOver')} <strong className="h6">{timeLabel}</strong>
                                                            </Tag>
                                                        )}
                                                        <Button type="primary" onClick={() => onConnectToConsultation()}>
                                                            {t('appointment.connect')}
                                                        </Button>
                                                    </div>
                                                )}
                                            ></CountdownTimer>
                                        )}
                                </div>

                                {encounter?.encounterDetailDTO?.status == EncounterDetailDTOStatus.cancelled && (
                                    <div className={styles.cancelReasonBlock}>
                                        <AlertIconOutlined />
                                        <p>{t('appointment.cancelReason')}</p>
                                        <p>
                                            {t(
                                                'enums.encounterChangeReason.' + encounter?.encounterDetailDTO.changeReason?.at(-1)?.type,
                                                t("not_specified_1"),
                                            )}
                                        </p>
                                    </div>
                                )}
                            </div>

                            {/* Payment Field */}
                            {paymentServiceActive && paymentInfo && encounter?.encounterDetailDTO?.paymentStatus === EncounterDetailDTOPaymentStatus.completed && (
                                <div className={styles.infoField}>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex">
                                            <div className={styles.circleGreen}>
                                                <CheckCircle48 />
                                            </div>
                                            <div>
                                                <p>
                                                    {capitalizeText(
                                                        t(`callCenterManager.paymentStatuses.${encounter?.encounterDetailDTO?.paymentStatus}`),
                                                    )}
                                                </p>
                                                <div className="d-flex">
                                                    <div className={styles.statusField}>
                                                        <p className={styles.dateTextSmall}>
                                                            <p>Дата/время оплаты: <span
                                                                className={styles.blackColor}>{paymentInfo?.approvedIn &&
                                                                dayjs(paymentInfo?.approvedIn).format('DD.MM.YYYY HH:mm:ss')}</span>
                                                            </p>
                                                        </p>
                                                        <p className={styles.dateTextSmall}>
                                                            <p>Способ оплаты: <span className={styles.blackColor}>{
                                                                t("appointment.paymentMethod." + (paymentInfo.paymentMethod ? paymentInfo.paymentMethod : 'NO_DATA'))}</span>
                                                            </p>
                                                        </p>
                                                        <p className={styles.dateTextSmall}>
                                                            <p>Сумма оплаты: <span
                                                                className={styles.blackColor}>{paymentInfo?.amount &&
                                                                paymentInfo?.amount}₽</span></p>
                                                        </p>
                                                        {paymentInfo?.receiptUrl ?
                                                            <NavLink className={styles.checkButton} to=""
                                                                     onClick={() => openCashReceipt()}>
                                                                {t('appointment.seeCheck')}
                                                            </NavLink> :
                                                            paymentInfo?.paymentMethod !== 'SBERBANK' ?
                                                                <span className={styles.noCheck}>
                                                                <ProcedureFailedIcon
                                                                    className={styles.failIcon}></ProcedureFailedIcon>
                                                                    {t("appointment.noCheck")}
                                                        </span> :
                                                                <>
                                                            <span className={styles.waitCheckDisplayInfo}>
                                                                <ProcedureTimerIcon1
                                                                    className={styles.progressIcon}></ProcedureTimerIcon1>
                                                                {t("appointment.waitCheck")}
                                                            </span>
                                                                    {paymentInfo?.paymentMethod === 'SBERBANK' && paymentInfo?.approvedIn && ((new Date().getTime() - paymentInfo?.approvedIn) > 86400000) && <div className={styles['notice']}>
                                                                        <AlertIconOutlined className={styles.failIcon} />
                                                                        {t("appointment.checkNotation") + ' ' + t("appointment.paymentMethod." + paymentInfo.paymentMethod)}
                                                                    </div>}
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {encounter?.encounterDetailDTO?.status == EncounterDetailDTOStatus.cancelled && (
                                            <p className={styles.returnMoneyText}>{t('appointment.returnMoneyText')}</p>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Practitioner Field if not mine */}
                            {!isMine() && (
                                <div className={styles.infoField}>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex">
                                            <div className={styles.avatar}>
                                                {encounter?.encounterDetailDTO?.practitionerPhoto ? (
                                                    <img src={encounter?.encounterDetailDTO?.practitionerPhoto} />
                                                ) : (
                                                    <UserIcon />
                                                )}
                                            </div>
                                            <div>
                                                <p
                                                    onClick={() => onDoctorDetails(encounter?.encounterDetailDTO?.practitionerId)}
                                                    className={styles.nameText}
                                                >
                                                    {encounter?.encounterDetailDTO?.practitionerName}
                                                </p>
                                                <div className="d-flex">
                                                    <p>
                                                        {encounter?.encounterDetailDTO?.practitionerRoleCode === 'nurse'
                                                            ? t(`appointment.practitionerRoles.nurse`)
                                                            : t(`enums.speciality.${encounter?.encounterDetailDTO?.practitionerSpecialty}`)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Patient Field */}

                            <div className={styles.infoField}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex">
                                        <div className={styles.avatar}>
                                            {encounter?.encounterDetailDTO?.patientPhoto ? (
                                                <img src={encounter?.encounterDetailDTO?.patientPhoto} />
                                            ) : (
                                                <UserIcon />
                                            )}
                                        </div>
                                        <div>
                                            <p
                                                onClick={() => onPatientDetails(encounter?.encounterDetailDTO?.patientFhirId)}
                                                className={styles.nameText}
                                            >
                                                {encounter?.encounterDetailDTO?.patientName}
                                            </p>
                                            <div className="d-flex">
                                                <p> {dayjs(encounter?.encounterDetailDTO?.patientBirthDay).fromNow(true)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    {/* Consultation Block Completed */}
                    {isConsultation() && (
                        <Col md={24} sm={24} xs={24}>
                            <div className={styles.infoBlock}>
                                {encounterStatus === AppointmentStatus.completed ? (
                                    <>
                                        <h3>{t('appointment.results')}</h3>
                                        {encounter.forms && encounter.forms.length > 0 && (
                                            <>
                                                <div className={styles.infoField}>
                                                    <p className={styles.infoLabel}>{t("head_physician_appointment.form")}</p>
                                                </div>
                                                <PatientForms
                                                    forms={encounter.forms}
                                                    practitionerFhirId={encounter.encounterDetailDTO?.practitionerFhirId}
                                                    onCloseDiagnosticModal={() => retrieveEncounterDetails()}
                                                />
                                            </>
                                        )}

                                        {encounter?.documents && encounter.documents.filter((item) => isDocumentFromPatients(item)).length > 0 && (
                                            <div className={styles.infoField}>
                                                <p className={styles.infoLabel}>{t("head_physician_appointment.documents_by_patient")}</p>
                                                {encounter.documents
                                                    .filter((item) => isDocumentFromPatients(item))
                                                    .map((item) => (
                                                        <div className={styles.infoItem} key={item.documentFhirId} onClick={() => openDocument(item)}>
                                                            <DocumentIcon /> {t('appointment.documentTypes.' + item.type)}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}

                                        {encounter?.documents && encounter.documents.filter((item) => isDocumentFromDoctor(item)).length > 0 && (
                                            <div className={styles.infoField}>
                                                <p className={styles.infoLabel}>{t("head_physician_appointment.report")}</p>
                                                {encounter.documents
                                                    .filter((item) => isDocumentFromDoctor(item))
                                                    .map((item) => (
                                                        <div className={styles.infoItem} key={item.documentFhirId} onClick={() => openDocument(item)}>
                                                            <DocumentIcon /> {t('appointment.documentTypes.' + item.type)}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    // Consultation Block Not completed
                                    <>
                                        <h3>{t('appointment.initialInfo')}</h3>
                                        <div style={{ marginTop: '24px' }}>
                                            <p className={styles.infoTitle}>{t("head_physician_appointment.preliminary_diagnosis")}</p>
                                            <p className={styles.infoDescription}>
                                                {encounter?.encounterDetailDTO?.diagnosis
                                                    ?.map((x) => t('enums.preliminaryDiagnosis.' + x))
                                                    .join(', ') || <span className={styles.notFilled}>{t("head_physician_appointment.empty_data")}</span>}
                                            </p>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>
                                            <p className={styles.infoTitle}>{t("head_physician_appointment.complaints")}</p>
                                            {!!encounter?.encounterDetailDTO?.reasonCodes?.length ? (
                                                <ul>
                                                    {encounter?.encounterDetailDTO.reasonCodes.map((x, i) => (
                                                        <li key={i}>{t('consultationSession.patientInfoStep.reasonCodes.' + x)}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <span className={styles.notFilled}>{t("head_physician_appointment.empty_data")}</span>
                                            )}
                                            <p>{encounter?.encounterDetailDTO?.comment}</p>
                                        </div>

                                        {encounter?.documents && encounter.documents?.filter((item) => isDocumentFromPatients(item)).length > 0 && (
                                            <div style={{ marginTop: '36px' }} className={styles.infoField}>
                                                <p className={styles.infoTitle}>{t("head_physician_appointment.uploaded_documents")}</p>
                                                {encounter.documents
                                                    .filter((item) => isDocumentFromPatients(item))
                                                    .map((item) => (
                                                        <div
                                                            onClick={() => openDocument(item)}
                                                            className={classNames(styles.infoItem, styles.document)}
                                                            key={item.documentFhirId}
                                                        >
                                                            <DocumentIcon /> {t('appointment.documentTypes.' + item.type)}{' '}
                                                            {item.typeDescription && <>({item.typeDescription})</>}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </>
                                )}

                                {encounter?.documents && encounter.documents.filter((item) => isDocumentVideo(item)).length > 0 && (
                                    <div className={styles.infoField}>
                                        <p className={styles.infoLabel}>{t("head_physician_appointment.video")}</p>
                                        {encounter.documents
                                            .filter((item) => isDocumentVideo(item))
                                            .map((item) => (
                                                <div onClick={() => openDocument(item)} className={styles.infoItem} key={item.documentFhirId}>
                                                    <div className="d-flex justify-content-between" style={{ margin: 0 }}>
                                                        <div>
                                                            <CameraIconGrey /> {item.name}
                                                        </div>
                                                        <Button type="ghost" className={styles.downloadButton}>
                                                            <DownloadIcon />
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                        </Col>
                    )}

                    {/* Diagnostic Block Completed */}
                    {isDiagnostic() &&
                        (encounterStatus === AppointmentStatus.completed && encounter?.documents && encounter.documents.length > 0 ? (
                            <Col md={24} sm={24} xs={24}>
                                <div className={styles.infoBlock}>
                                    <h3>{t('appointment.results')}</h3>
                                    <div className={styles.infoField}>
                                        <p className={styles.infoLabel}>{t("head_physician_appointment.form")}</p>
                                        <div className={styles.infoItem}>
                                            <p className={styles.itemType}>
                                                {t(`enums.appointmentTypes.${encounter.encounterDetailDTO?.appointmentType}`)}
                                            </p>
                                            <p className={styles.itemDescription}>
                                                {dayjs(encounter.encounterDetailDTO?.dateTime).format('DD.MM.YYYY HH:mm')}{' '}
                                                {encounter.encounterDetailDTO?.practitionerName}
                                            </p>
                                        </div>
                                    </div>
                                    {encounter.documents.filter((item) => isDocumentFromDoctor(item)).length > 0 && (
                                        <div className={styles.infoField}>
                                            <p className={styles.infoLabel}>{t("head_physician_appointment.documents_by_patient")}</p>
                                            {encounter.documents
                                                .filter((item) => isDocumentFromPatients(item))
                                                .map((item) => (
                                                    <div className={styles.infoItem} key={item.documentFhirId} onClick={() => openDocument(item)}>
                                                        <DocumentIcon /> {t('appointment.documentTypes.' + item.type)}
                                                    </div>
                                                ))}
                                        </div>
                                    )}

                                    {encounter.documents.filter((item) => isDocumentFromDoctor(item)).length > 0 && (
                                        <div className={styles.infoField}>
                                            <p className={styles.infoLabel}>{t("head_physician_appointment.report")}</p>
                                            {encounter.documents
                                                .filter((item) => isDocumentFromDoctor(item))
                                                .map((item) => (
                                                    <div className={styles.infoItem} key={item.documentFhirId} onClick={() => openDocument(item)}>
                                                        <DocumentIcon /> {t('appointment.documentTypes.' + item.type)}
                                                    </div>
                                                ))}
                                        </div>
                                    )}

                                    {encounter.documents.filter((item) => isDocumentVideo(item)).length > 0 && (
                                        <div className={styles.infoField}>
                                            <p className={styles.infoLabel}>{t("head_physician_appointment.video")}</p>
                                            {encounter.documents
                                                .filter((item) => isDocumentVideo(item))
                                                .map((item) => (
                                                    <div onClick={() => openDocument(item)} className={styles.infoItem} key={item.documentFhirId}>
                                                        <div className="d-flex justify-content-between" style={{ margin: 0 }}>
                                                            <div>
                                                                <CameraIconGrey /> {item.name}
                                                            </div>
                                                            <Button type="ghost" className={styles.downloadButton}>
                                                                <DownloadIcon />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        ) : (
                            // Diagnostic Block Not completed
                            <div></div>
                        ))}

                    {/* Course Block Completed */}
                    {isCourse() &&
                        (encounterStatus === AppointmentStatus.completed ? (
                            <Col md={24} sm={24} xs={24}>
                                <div className={styles.infoBlock}>
                                    <h3>{t('appointment.results')}</h3>
                                    {encounter?.documents && encounter.documents.length > 0 && (
                                        <>
                                            {encounter.documents.filter((item) => isDocumentFromDoctor(item)).length > 0 && (
                                                <div className={styles.infoField}>
                                                    <p className={styles.infoLabel}>{t("head_physician_appointment.documents_by_patient")}</p>
                                                    {encounter.documents
                                                        .filter((item) => isDocumentFromPatients(item))
                                                        .map((item) => (
                                                            <div
                                                                className={styles.infoItem}
                                                                key={item.documentFhirId}
                                                                onClick={() => openDocument(item)}
                                                            >
                                                                <DocumentIcon /> {t('appointment.documentTypes.' + item.type)}
                                                            </div>
                                                        ))}
                                                </div>
                                            )}

                                            {encounter.documents.filter((item) => isDocumentFromDoctor(item)).length > 0 && (
                                                <div className={styles.infoField}>
                                                    <p className={styles.infoLabel}>{t("head_physician_appointment.report")}</p>
                                                    {encounter.documents
                                                        .filter((item) => isDocumentFromDoctor(item))
                                                        .map((item) => (
                                                            <div
                                                                className={styles.infoItem}
                                                                key={item.documentFhirId}
                                                                onClick={() => openDocument(item)}
                                                            >
                                                                <DocumentIcon /> {t('appointment.documentTypes.' + item.type)}
                                                            </div>
                                                        ))}
                                                </div>
                                            )}

                                            {encounter.documents.filter((item) => isDocumentVideo(item)).length > 0 && (
                                                <div className={styles.infoField}>
                                                    <p className={styles.infoLabel}>{t("head_physician_appointment.video")}</p>
                                                    {encounter.documents
                                                        .filter((item) => isDocumentVideo(item))
                                                        .map((item) => (
                                                            <div
                                                                onClick={() => openDocument(item)}
                                                                className={styles.infoItem}
                                                                key={item.documentFhirId}
                                                            >
                                                                <div className="d-flex justify-content-between" style={{ margin: 0 }}>
                                                                    <div>
                                                                        <CameraIconGrey /> {item.name}
                                                                    </div>
                                                                    <Button type="ghost" className={styles.downloadButton}>
                                                                        <DownloadIcon />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Col>
                        ) : (
                            // Course Block Not completed
                            <div></div>
                        ))}
                    <Col span={24}>
                        {encounter?.encounterDetailDTO?.appointmentType === 'course' &&
                            encounter.carePlanResults &&
                            encounter.carePlanResults.carePlan && (
                                <div className={styles['procedure-container']}>
                                    <p className={styles.title}>{t("head_physician_appointment.care_plan")}</p>
                                    <div
                                        className={classNames(
                                            styles['procedure-card'],
                                            encounter.carePlanResults.carePlan?.status === CarePlanDTOStatus.completed && styles.filtered,
                                        )}
                                    >
                                        <Row align="top" gutter={24}>
                                            <Col span={3}>
                                                <Procedure />
                                            </Col>
                                            <Col span={18}>
                                                <div className="d-flex flex-column align-items-start">
                                                    <p className={styles['procedure-card-description']}>
                                                        {t(`enums.planDefinition.${encounter.carePlanResults.carePlan?.planDefinitionID}`)}{' '}
                                                        {encounter.carePlanResults.carePlan?.extended && t("head_physician_appointment.extended")}
                                                    </p>
                                                    <div className="d-flex align-items-center">
                                                        <div className={classNames(styles.status, styles.success)}>
                                                            {t("head_physician_appointment.course_progress", {currentProgress: encounter.carePlanResults.carePlan?.currentProgress, total: encounter.carePlanResults.carePlan?.durationInDays})}
                                                        </div>
                                                        <Button onClick={() => handleShowResultsModal(encounter!.carePlanResults!)} type="text">
                                                            {t("head_physician_appointment.results")}
                                                            {encounter.carePlanResults?.results?.some((day) =>
                                                                day.procedureResults?.some((y) => y.result?.unSuccess),
                                                            ) && <AlertIconOutlined className="ms-2" />}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={3}>
                                                <Button onClick={() => setShowCourseSettingsModal(true)}>
                                                    <SettingsIcon />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            )}
                    </Col>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Chats
                        currentUserRole={
                            encounter?.encounterDetailDTO?.practitionerFhirId === currentProfile?.fhirId
                                ? UserRole.Practitioner
                                : UserRole.Head_physician
                        }
                        currentUserId={currentProfile?.fhirId}
                        chats={encounter?.chats}
                        haveAccessForEdit={encounter?.encounterDetailDTO?.practitionerFhirId === currentProfile?.fhirId}
                        noDoctorPatientMessagesLabel={
                            dayjs(encounter.encounterDetailDTO?.dateTime) < dayjs()
                                ? t("chat.chat_practitioner_not_allowed")
                                : t("chat.chat_practitioner_not_allowed")
                        }
                    />
                </Col>
            </Row>

            {selectedCarePlanResults && (
                <CourseResultsModal
                    carePlanResults={selectedCarePlanResults}
                    showModal={showCourseResultsModal}
                    onCancel={() => setShowCourseResultsModal(false)}
                    onSuccess={() => setShowCourseResultsModal(false)}
                />
            )}

            <CourseSettingsModal
                editable={
                    encounter?.carePlanResults?.carePlan?.status !== CarePlanDTOStatus.completed &&
                    encounter?.encounterDetailDTO?.practitionerFhirId === currentProfile?.fhirId
                }
                carePlan={encounter?.carePlanResults?.carePlan} // todo for the current project version - we can have only 1 carePlan
                visible={showCourseSettingsModal}
                onClose={() => {
                    setShowCourseSettingsModal(false);
                    retrieveEncounterDetails();
                }}
            />
            {showCheckNotExistModal && <CheckNotExistModal visible={showCheckNotExistModal} onClose={() => setShowCheckNotExistModal(false)} />}
        </div>
    ) : null;
};
