import { FileUploadDTO, FileUploadDTOType, uploadFile } from '@api/documentServiceAPI';
import {
    EducationDetailsDTO,
    EducationDetailsDTOType,
    UpdateProfileDTO,
    UpdateProfileDTOAcademicDegree,
    UpdateProfileDTOAcademicRank,
    UpdateProfileDTOPractitionerChiefComplaintCodesItem,
    UpdateProfileDTOPractitionerSpecialtiesItem,
    UpdateProfileDTOQualificationCategory,
    UpdateUserDTO,
    UpdateUserDTOStatus,
    UserDTO,
    UserProfileDTO,
    UserProfileDetailsDTOAcademicDegree,
    UserProfileDetailsDTOAcademicRank,
    UserProfileDetailsDTOPractitionerChiefComplaintCodesItem,
    UserProfileDetailsDTOPractitionerSpecialtiesItem,
    UserProfileDetailsDTOPriceCategory,
    UserProfileDetailsDTOQualificationCategory,
    WorkExperienceDTO,
    updateProfile,
    updateUser,
} from '@api/userServiceAPI';
import { AlertIconOutlined, CheckCircled, CrossIcon, PlusCircle } from '@icons';
import { capitalizeText, separateFileNameAndExtension } from '@utils/utils';
import { Button, Col, DatePicker, Form, Row, Select, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './DoctorProfileEdit.module.scss';

interface DoctorProfileEditProps {
    employee: UserDTO | undefined;
    profile: UserProfileDTO | undefined;
    onSaveProfile: () => void;
    onCloseEditProfile: () => void;
}

interface IEditDoctorProfileForm {
    photoLink: string;
    practitionerSpecialties: UpdateProfileDTOPractitionerSpecialtiesItem[];
    academicDegree: UpdateProfileDTOAcademicDegree;
    academicRank: UpdateProfileDTOAcademicRank;
    qualificationCategory: UpdateProfileDTOQualificationCategory;
    practitionerProfessionalActivityStartYear: Dayjs;
    priceCategory: UserProfileDetailsDTOPriceCategory;
    practitionerChiefComplaintCodes: UpdateProfileDTOPractitionerChiefComplaintCodesItem[];
    educationUniversityList: {
        graduationYear: Dayjs;
        name?: string;
    }[];
    educationInternshipList: {
        graduationYear: Dayjs;
        name?: string;
    }[];
    educationCertificateList: {
        graduationYear: Dayjs;
        name?: string;
    }[];
    educationAccreditationList: {
        graduationYear?: Dayjs;
        name?: string;
    }[];
    physiciansAssociations: string[];
    workExperienceList: {
        yearStart: Dayjs;
        yearEnd: Dayjs;
        description: string;
    }[];
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

// validation image by size & type
// const beforeUpload = (file: RcFile) => {
//     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//     if (!isJpgOrPng) {
//         message.error('You can only upload JPG/PNG file!');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//         message.error('Image must smaller than 2MB!');
//     }
//     return isJpgOrPng && isLt2M;
// };

export const DoctorProfileEdit = ({ employee, profile, onSaveProfile, onCloseEditProfile }: DoctorProfileEditProps) => {
    const [editDoctorProfileForm] = Form.useForm();
    const { validateForm } = useFormValidation(editDoctorProfileForm);
    const [isValidForm, setIsValidForm] = useState<boolean>();

    const [photoUrl, setPhotoUrl] = useState<string>();
    const [photoId, setPhotoId] = useState<string>();
    const [specialtyOptions, setSpecialtyOptions] = useState<{ label: string; value: string | null }[]>([]);
    const [degreeOptions, setDegreeOptions] = useState<{ label: string; value: string | null }[]>([]);
    const [academicRankOptions, setAcademicRankOptions] = useState<{ label: string; value: string | null }[]>([]);
    const [qualificationCategoriesOptions, setQualificationCategoriesOptions] = useState<{ label: string; value: string | null }[]>([]);
    const [priceCategoriesOptions, setPriceCategoriesOptions] = useState<{ label: string; value: string | null }[]>([]);
    const [chiefComplaintsOptions, setChiefComplaintsOptions] = useState<{ label: string; value: string | null }[]>([]);

    useEffect(() => {
        setPhotoUrl(profile?.userProfileDetailsDTO?.photoLink);

        setSpecialtyOptions([
            ...Object.keys(UserProfileDetailsDTOPractitionerSpecialtiesItem).map((item) => ({
                label: t(`adminEmployeePage.profilePage.practitionerSpecialties.${item}`),
                value: item,
            })),
        ]);

        setDegreeOptions([
            ...Object.keys(UserProfileDetailsDTOAcademicDegree).map((item) => ({
                label: t(`adminEmployeePage.profilePage.academicDegrees.${item}`),
                value: item,
            })),
            { label: t('adminEmployeePage.profilePage.academicDegrees.none'), value: '' },
        ]);

        setAcademicRankOptions([
            ...Object.keys(UserProfileDetailsDTOAcademicRank).map((item) => ({
                label: t(`adminEmployeePage.profilePage.academicRanks.${item}`),
                value: item,
            })),
            { label: t('adminEmployeePage.profilePage.academicRanks.none'), value: '' },
        ]);

        setQualificationCategoriesOptions([
            ...Object.keys(UserProfileDetailsDTOQualificationCategory).map((item) => ({
                label: t(`adminEmployeePage.profilePage.qualificationCategories.${item}`),
                value: item,
            })),
            { label: t('adminEmployeePage.profilePage.qualificationCategories.none'), value: null },
        ]);

        setPriceCategoriesOptions([
            ...Object.keys(UserProfileDetailsDTOPriceCategory).map((item) => ({
                label: t(`adminEmployeePage.profilePage.serviceInformation.priceCategory.${item}`),
                value: item,
            })),
        ]);

        setChiefComplaintsOptions([
            ...Object.keys(UserProfileDetailsDTOPractitionerChiefComplaintCodesItem).map((item) => ({
                label: t(`adminEmployeePage.profilePage.serviceInformation.chiefComplaints.${item}`),
                value: item,
            })),
        ]);

        validate();
    }, []);

    const onUploadPhoto = async (options: any) => {
        const file = options.file;

        const data: FileUploadDTO = {
            fhirId: profile?.fhirId,
            file: file,
            type: FileUploadDTOType.AVATAR,
            ...separateFileNameAndExtension(file.name),
        };

        uploadFile(data).then((result) => {
            if (result.data) {
                setPhotoId(result.data.uuid);
                getBase64(file as RcFile, (url) => {
                    setPhotoUrl(url);
                });
            }
        });
    };

    const getUniversityList = () => {
        return profile?.userProfileDetailsDTO?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.university) || [];
    };
    const getInternshipList = () => {
        return profile?.userProfileDetailsDTO?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.internship) || [];
    };
    const getCertificateList = () => {
        return profile?.userProfileDetailsDTO?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.certificate) || [];
    };
    const getAccreditationList = () => {
        return profile?.userProfileDetailsDTO?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.accreditation) || [];
    };
    const getPhysiciansAssociations = () => {
        return profile?.userProfileDetailsDTO?.physiciansAssociations || [];
    };
    const getWorkExperiences = () => {
        return profile?.userProfileDetailsDTO?.workExperiences || [];
    };

    const validate = () => {
        setIsValidForm(!editDoctorProfileForm.getFieldsError().some((item) => item.errors.length > 0));

        const fieldsToCheck = [
            'practitionerSpecialties',
            'academicDegree',
            'academicRank',
            'qualificationCategory',
            'practitionerProfessionalActivityStartYear',
            'priceCategory',
            'practitionerChiefComplaintCodes',
            'educationUniversityList',
            'educationInternshipList',
            'educationCertificateList',
            'educationAccreditationList',
            'physiciansAssociations',
            'workExperienceList',
        ];
        validateForm(fieldsToCheck);
    };

    const saveProfile = (formData: IEditDoctorProfileForm) => {
        if (!employee || !profile || !editDoctorProfileForm) {
            return;
        }

        if (profile?.fhirId) {
            const editedProfileData: UpdateProfileDTO = {
                practitionerSpecialties: formData.practitionerSpecialties,
                academicDegree: formData.academicDegree,
                academicRank: formData.academicRank,
                avatarId: photoId,
                qualificationCategory: formData.qualificationCategory,
                practitionerProfessionalActivityStartYear: getYearFromDayjs(formData.practitionerProfessionalActivityStartYear),
                priceCategory: formData.priceCategory,
                practitionerChiefComplaintCodes: formData.practitionerChiefComplaintCodes,
                educationDetailsDTO: mapEducationDetailsList(formData),
                physiciansAssociations: formData.physiciansAssociations,
                workExperienceDTOList: mapWorkExperienceList(formData),
            };
            updateProfile(employee.id!, profile.fhirId, editedProfileData).then((result) => {
                if (result && employee.id) {
                    const editedUser: UpdateUserDTO = {
                        status: UpdateUserDTOStatus.ACTIVE,
                    };
                    updateUser(employee.id, editedUser).then((result) => {
                        if (result) {
                            onSaveProfile();
                        }
                    });
                }
            });
        }
    };

    const getYearFromDayjs = (activityStartDate: Dayjs | undefined): number => {
        return activityStartDate?.toDate().getFullYear() || 0;
    };

    const getWorkExperienceYear = () => {
        const workExperience = profile?.userProfileDetailsDTO?.practitionerProfessionalActivityStartYear;
        if (workExperience) {
            return dayjs().year(workExperience);
        }
        return null;
    };

    const mapEducationDetailsList = (formData: IEditDoctorProfileForm): EducationDetailsDTO[] => {
        let result: EducationDetailsDTO[] = [];

        const universityProfile = formData.educationUniversityList?.map((record) => ({
            graduationYear: getYearFromDayjs(record.graduationYear),
            name: record.name,
            type: EducationDetailsDTOType.university,
        }));
        result = result?.concat(universityProfile || []);

        const internshipProfile = formData.educationInternshipList?.map((record) => ({
            graduationYear: getYearFromDayjs(record.graduationYear),
            name: record.name,
            type: EducationDetailsDTOType.internship,
        }));
        result = result?.concat(internshipProfile || []);

        const certificatesProfile = formData.educationCertificateList?.map((record) => ({
            graduationYear: getYearFromDayjs(record.graduationYear),
            name: record.name,
            type: EducationDetailsDTOType.certificate,
        }));
        result = result?.concat(certificatesProfile || []);

        const accreditationProfile = formData.educationAccreditationList?.map((record) => ({
            graduationYear: getYearFromDayjs(record.graduationYear),
            name: record.name,
            type: EducationDetailsDTOType.accreditation,
        }));
        result = result?.concat(accreditationProfile || []);

        return result;
    };

    const mapWorkExperienceList = (formData: IEditDoctorProfileForm): WorkExperienceDTO[] => {
        const result: WorkExperienceDTO[] = formData.workExperienceList?.map((record) => ({
            yearStart: getYearFromDayjs(record.yearStart),
            yearEnd: getYearFromDayjs(record.yearEnd),
            description: record.description,
        }));

        return result;
    };

    return (
        <div className={styles.formContainer}>
            <Form
                form={editDoctorProfileForm}
                onFinish={saveProfile}
                name="editDoctorProfileForm"
                layout="vertical"
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                requiredMark={false}
                onFieldsChange={validate}
                initialValues={{
                    educationUniversityList: [
                        ...getUniversityList().map((item) => ({
                            graduationYear: dayjs(item.graduationYear?.toString(), 'YYYY'),
                            name: capitalizeText(item.name || ''),
                        })),
                    ],
                    educationInternshipList: [
                        ...getInternshipList().map((item) => ({
                            graduationYear: dayjs(item.graduationYear?.toString(), 'YYYY'),
                            name: capitalizeText(item.name || ''),
                        })),
                    ],
                    educationCertificateList: [
                        ...getCertificateList().map((item) => ({
                            graduationYear: dayjs(item.graduationYear?.toString(), 'YYYY'),
                            name: capitalizeText(item.name || ''),
                        })),
                    ],
                    educationAccreditationList: [
                        ...getAccreditationList().map((item) => ({
                            graduationYear: dayjs(item.graduationYear?.toString(), 'YYYY'),
                            name: capitalizeText(item.name || ''),
                        })),
                    ],
                    physiciansAssociations: [...getPhysiciansAssociations()],
                    workExperienceList: [
                        ...getWorkExperiences().map((item) => ({
                            yearStart: dayjs(item.yearStart?.toString(), 'YYYY'),
                            yearEnd: item.yearEnd ? dayjs(item.yearEnd?.toString(), 'YYYY') : undefined,
                            description: item.description,
                        })),
                    ],
                }}
            >
                <Row gutter={24}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            initialValue={photoUrl}
                            className={styles.bottomSection}
                            name="photoLink"
                            valuePropName="avatar"
                            rules={[{ required: false, message: '' }]}
                        >
                            <Upload
                                customRequest={onUploadPhoto}
                                listType="picture"
                                className="avatar-uploader"
                                showUploadList={false}
                                // beforeUpload={beforeUpload}
                            >
                                <div className={styles.photoBlock}>
                                    <div className={styles.photoUpload}>
                                        <img src={photoUrl} />
                                        <p>{t('adminEmployeePage.upload')}</p>
                                    </div>
                                    <p>{t('adminEmployeePage.uploadPhotoDesc')}</p>
                                </div>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className="mt-4">
                    <Col md={22} sm={24} xs={24}>
                        <Form.Item
                            initialValue={profile?.userProfileDetailsDTO?.practitionerSpecialties}
                            className={styles.bottomSection}
                            name="practitionerSpecialties"
                            label={t('adminEmployeePage.profilePage.practitionerSpecialty')}
                            rules={[{ required: true, message: '' }]}
                        >
                            <Select mode="multiple" options={specialtyOptions} dropdownStyle={{ zIndex: 99999 }} className="w-100" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className="mt-4">
                    <Col md={11} sm={24} xs={24} style={{ paddingRight: '8px' }}>
                        <Form.Item
                            initialValue={profile?.userProfileDetailsDTO?.academicDegree}
                            className={styles.bottomSection}
                            name="academicDegree"
                            label={t('adminEmployeePage.profilePage.academicDegree')}
                            rules={[{ message: '' }]}
                        >
                            <Select options={degreeOptions} dropdownStyle={{ zIndex: 99999 }} className="w-100" />
                        </Form.Item>
                    </Col>
                    <Col md={11} sm={24} xs={24} style={{ paddingLeft: '8px' }}>
                        <Form.Item
                            initialValue={profile?.userProfileDetailsDTO?.academicRank}
                            className={styles.bottomSection}
                            name="academicRank"
                            label={t('adminEmployeePage.profilePage.academicRank')}
                            rules={[{ message: '' }]}
                        >
                            <Select options={academicRankOptions} dropdownStyle={{ zIndex: 99999 }} className="w-100" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className="mt-4">
                    <Col md={11} sm={24} xs={24} style={{ paddingRight: '8px' }}>
                        <Form.Item
                            initialValue={profile?.userProfileDetailsDTO?.qualificationCategory}
                            className={styles.bottomSection}
                            name="qualificationCategory"
                            label={t('adminEmployeePage.profilePage.qualificationCategory')}
                            rules={[{ message: '' }]}
                        >
                            <Select options={qualificationCategoriesOptions} dropdownStyle={{ zIndex: 99999 }} className="w-100" />
                        </Form.Item>
                    </Col>
                    <Col md={11} sm={24} xs={24} style={{ paddingLeft: '8px' }}>
                        <Form.Item
                            initialValue={getWorkExperienceYear()}
                            className={styles.bottomSection}
                            name="practitionerProfessionalActivityStartYear"
                            label={t('adminEmployeePage.profilePage.workExperienceFrom')}
                            rules={[{ required: true, message: '' }]}
                        >
                            <DatePicker picker="year" placeholder="" style={{ width: '100%' }} disabledDate={(e) => e > dayjs()} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className="mt-4">
                    <Col md={22} sm={24} xs={24}>
                        <div className={styles.serviceInfoBlock}>
                            <h3>{t('adminEmployeePage.profilePage.serviceInformation.title')}</h3>
                            <div>
                                <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.serviceInformation.priceCategory.title')}</p>
                                <Form.Item
                                    initialValue={profile?.userProfileDetailsDTO?.priceCategory}
                                    className={styles.bottomSection}
                                    name="priceCategory"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select options={priceCategoriesOptions} dropdownStyle={{ zIndex: 99999 }} className="w-100" />
                                </Form.Item>
                            </div>
                            <div className={styles.infoItem}>
                                <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.serviceInformation.chiefComplaint')}</p>
                                <Form.Item
                                    initialValue={profile?.userProfileDetailsDTO?.practitionerChiefComplaintCodes}
                                    className={styles.bottomSection}
                                    name="practitionerChiefComplaintCodes"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select mode="multiple" options={chiefComplaintsOptions} dropdownStyle={{ zIndex: 99999 }} className="w-100" />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col md={22} sm={24} xs={24}>
                        <div className={styles.profileBlock}>
                            <h3>{t('adminEmployeePage.profilePage.education.title')}</h3>
                            <div className={styles.profileItem}>
                                <p className={styles.title}>{t('adminEmployeePage.profilePage.education.university')}</p>
                                <Form.List name="educationUniversityList">
                                    {(educationUniversityList, { add, remove }) => (
                                        <>
                                            {educationUniversityList.map((university, index) => (
                                                <div className={styles.profileRecord} key={university.key}>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'graduationYear']}
                                                        style={{ minWidth: '176px' }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <DatePicker
                                                            picker="year"
                                                            placeholder={t('adminEmployeePage.profilePage.editProfile.educationYearEnd')}
                                                            style={{ minWidth: '176px', maxHeight: '48px' }}
                                                            disabledDate={(e) => e > dayjs()}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'name']}
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <TextArea
                                                            placeholder={t('adminEmployeePage.profilePage.editProfile.educationUniversityPlaceholder')}
                                                            rows={2}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item style={{ alignSelf: 'center' }}>
                                                        <Button type="ghost" className={styles.removeButton} onClick={() => remove(university.name)}>
                                                            <CrossIcon />
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            ))}

                                            <Form.Item>
                                                <Button className={styles.addButton} type="ghost" onClick={() => add()}>
                                                    <PlusCircle />
                                                    {t('adminEmployeePage.profilePage.editProfile.addFieldButton')}
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </div>
                            <div className={styles.profileItem}>
                                <p className={styles.title}>{t('adminEmployeePage.profilePage.education.internship')}</p>
                                <Form.List name="educationInternshipList">
                                    {(educationInternshipList, { add, remove }) => (
                                        <>
                                            {educationInternshipList.map((internship, index) => (
                                                <div className={styles.profileRecord} key={internship.key}>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'graduationYear']}
                                                        style={{ minWidth: '176px' }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <DatePicker
                                                            picker="year"
                                                            placeholder={t('adminEmployeePage.profilePage.editProfile.educationYearEnd')}
                                                            style={{ minWidth: '176px', maxHeight: '48px' }}
                                                            disabledDate={(e) => e > dayjs()}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'name']}
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <TextArea
                                                            placeholder={t(
                                                                'adminEmployeePage.profilePage.editProfile.educationUniversityPlaceholder',
                                                            )}
                                                            rows={2}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item style={{ alignSelf: 'center' }}>
                                                        <Button type="ghost" className={styles.removeButton} onClick={() => remove(internship.name)}>
                                                            <CrossIcon />
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            ))}

                                            <Form.Item>
                                                <Button className={styles.addButton} type="ghost" onClick={() => add()}>
                                                    <PlusCircle />
                                                    {t('adminEmployeePage.profilePage.editProfile.addFieldButton')}
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </div>
                            <div className={styles.profileItem}>
                                <p className={styles.title}>{t('adminEmployeePage.profilePage.education.certificates')}</p>
                                <Form.List name="educationCertificateList">
                                    {(educationCertificateList, { add, remove }) => (
                                        <>
                                            {educationCertificateList.map((certificate, index) => (
                                                <div className={styles.profileRecord} key={certificate.key}>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'graduationYear']}
                                                        style={{ minWidth: '176px' }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <DatePicker
                                                            picker="year"
                                                            placeholder={t('adminEmployeePage.profilePage.editProfile.educationYearEnd')}
                                                            style={{ minWidth: '176px', maxHeight: '48px' }}
                                                            disabledDate={(e) => e > dayjs()}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'name']}
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <TextArea
                                                            placeholder={t(
                                                                'adminEmployeePage.profilePage.editProfile.educationCertificatesPlaceholder',
                                                            )}
                                                            rows={2}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item style={{ alignSelf: 'center' }}>
                                                        <Button type="ghost" className={styles.removeButton} onClick={() => remove(certificate.name)}>
                                                            <CrossIcon />
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            ))}

                                            <Form.Item>
                                                <Button className={styles.addButton} type="ghost" onClick={() => add()}>
                                                    <PlusCircle />
                                                    {t('adminEmployeePage.profilePage.editProfile.addFieldButton')}
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </div>
                            <div className={styles.profileItem}>
                                <p className={styles.title}>{t('adminEmployeePage.profilePage.education.accreditation')}</p>
                                <Form.List name="educationAccreditationList">
                                    {(educationAccreditationList, { add, remove }) => (
                                        <>
                                            {educationAccreditationList.map((accreditation, index) => (
                                                <div className={styles.profileRecord} key={accreditation.key}>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'graduationYear']}
                                                        style={{ minWidth: '176px' }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <DatePicker
                                                            picker="year"
                                                            placeholder={t('adminEmployeePage.profilePage.editProfile.educationYearEnd')}
                                                            style={{ minWidth: '176px', maxHeight: '48px' }}
                                                            disabledDate={(e) => e > dayjs()}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'name']}
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <TextArea
                                                            placeholder={t(
                                                                'adminEmployeePage.profilePage.editProfile.educationAccreditationPlaceholder',
                                                            )}
                                                            rows={2}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item style={{ alignSelf: 'center' }}>
                                                        <Button
                                                            type="ghost"
                                                            className={styles.removeButton}
                                                            onClick={() => remove(accreditation.name)}
                                                        >
                                                            <CrossIcon />
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            ))}

                                            <Form.Item>
                                                <Button className={styles.addButton} type="ghost" onClick={() => add()}>
                                                    <PlusCircle />
                                                    {t('adminEmployeePage.profilePage.editProfile.addFieldButton')}
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </div>
                        </div>
                        <div className={styles.profileBlock}>
                            <h3>{t('adminEmployeePage.profilePage.physiciansAssociation')}</h3>
                            <div className={styles.profileItem}>
                                <Form.List name="physiciansAssociations">
                                    {(physiciansAssociations, { add, remove }) => (
                                        <>
                                            {physiciansAssociations.map((association, index) => (
                                                <div className={styles.profileRecord} key={association.key}>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index]}
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <TextArea
                                                            placeholder={t(
                                                                'adminEmployeePage.profilePage.editProfile.educationAssociationPlaceholder',
                                                            )}
                                                            rows={2}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item style={{ alignSelf: 'center' }}>
                                                        <Button type="ghost" className={styles.removeButton} onClick={() => remove(association.name)}>
                                                            <CrossIcon />
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            ))}

                                            <Form.Item>
                                                <Button className={styles.addButton} type="ghost" onClick={() => add()}>
                                                    <PlusCircle />
                                                    {t('adminEmployeePage.profilePage.editProfile.addFieldButton')}
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </div>
                        </div>
                        <div className={styles.profileBlock}>
                            <h3>{t('adminEmployeePage.profilePage.experience')}</h3>
                            <div className={styles.profileItem}>
                                <Form.List name="workExperienceList">
                                    {(workExperienceList, { add, remove }) => (
                                        <>
                                            {workExperienceList.map((experience, index) => (
                                                <div className={styles.profileRecord} key={experience.key}>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'yearStart']}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <DatePicker
                                                            picker="year"
                                                            placeholder={t('adminEmployeePage.profilePage.timeStart')}
                                                            style={{ width: '160px', maxHeight: '48px' }}
                                                            disabledDate={(e) => e > dayjs()}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'yearEnd']}
                                                        rules={[{ required: false, message: '' }]}
                                                    >
                                                        <DatePicker
                                                            picker="year"
                                                            placeholder={t('adminEmployeePage.profilePage.timeEnd')}
                                                            style={{ width: '160px', maxHeight: '48px' }}
                                                            disabledDate={(e) => e > dayjs()}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.bottomSection}
                                                        name={[index, 'description']}
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: true, message: '' }]}
                                                    >
                                                        <TextArea
                                                            placeholder={t(
                                                                'adminEmployeePage.profilePage.editProfile.workExperiencePlaceholder',
                                                            )}
                                                            rows={2}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item style={{ alignSelf: 'center' }}>
                                                        <Button type="ghost" className={styles.removeButton} onClick={() => remove(experience.name)}>
                                                            <CrossIcon />
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            ))}

                                            <Form.Item>
                                                <Button className={styles.addButton} type="ghost" onClick={() => add()}>
                                                    <PlusCircle />
                                                    {t('adminEmployeePage.profilePage.editProfile.addFieldButton')}
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col md={22} sm={24} xs={24} className="d-flex justify-content-end">
                        <hr />
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col md={16} sm={24} xs={24} className="d-flex justify-content-start">
                        {isValidForm ? (
                            <div className={styles.validationText}>
                                <CheckCircled />
                                <div>
                                    <p>{t('adminEmployeePage.profilePage.editProfile.validationFieldsSuccess')}</p>
                                    <p>{t('adminEmployeePage.profilePage.editProfile.validationProfileSuccess')}</p>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.validationText}>
                                <AlertIconOutlined />
                                <div>
                                    <p>{t('adminEmployeePage.profilePage.editProfile.validationFieldsFail')}</p>
                                    <p>{t('adminEmployeePage.profilePage.editProfile.validationProfileFail')}</p>
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col md={6} sm={24} xs={24} className="d-flex justify-content-end">
                        <Button onClick={() => onCloseEditProfile()} className={styles.cancelButton}>
                            {t("cancel_button")}
                        </Button>
                        <Button onClick={() => editDoctorProfileForm.submit()} type="primary">
                            {t("save")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
