/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setGlobalLoading } from 'src/store/core';

export const HttpInterceptor = () => {
    const [requestCount, setRequestCount] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const ignorePages403 = [
            '/login',
            '/register',
            '/restore-password',
            '/account',
            '/confirm-email',
            '/confirm-email-restore',
            '/confirm-mobile',
            '/account-success',
        ];

        const ignoreErrors = ['INVALID_ARGUMENTS_EXCEPTION', 'SCHEDULE_START_IN_PAST_EXCEPTION'];

        axios.interceptors.request.use((config) => {
            setRequestCount((prev) => prev + 1);

            config.withCredentials = true;
            config.baseURL = process.env.REACT_APP_API_URL;

            return config;
        });

        axios.interceptors.response.use(
            (response) => {
                setRequestCount((prev) => prev - 1);

                return response;
            },
            (error) => {
                setRequestCount((prev) => prev - 1);

                const code = error.response?.status;
                const errorCode = error.response?.data.code;

                // todo it's a wrong on the server side - I should get 401 if I'm not authenticated, but not 403
                if (
                    (code === 401 && location.pathname !== '/login' && location.pathname !== '/account') ||
                    (code === 403 && !ignorePages403.includes(location.pathname))
                ) {
                    navigate('/login');
                } else {
                    const messageString = error.response?.data.code;

                    if (messageString && !ignoreErrors.includes(errorCode)) {
                        message.error(t('apiErrors.' + messageString, `${error.response.data.message || t("unknown_error")}}`));
                    }
                }

                return Promise.reject(error);
            },
        );
    }, []);

    useEffect(() => {
        dispatch(setGlobalLoading(requestCount !== 0));
    }, [requestCount]);

    return null;
};
