import { UserDTO, UserProfileDTO, UserProfileDTOProfileType } from '@api/userServiceAPI';
import { t } from 'i18next';
import { DoctorProfile, NurseProfile } from '..';
import styles from './ProfileDetails.module.scss';

interface ProfileDetailsProps {
    employee: UserDTO | undefined;
    profile: UserProfileDTO | undefined;
    profileType: UserProfileDTOProfileType | undefined;
}

export const ProfileDetails = ({ employee, profileType, profile }: ProfileDetailsProps) => {
    return (
        <>
            {profileType === UserProfileDTOProfileType.Head_physician || profileType === UserProfileDTOProfileType.Practitioner ? (
                <div>
                    <div className={styles.profileTitle}>
                        <h4>{t('adminEmployeePage.doctorProfile')}</h4>
                    </div>
                    <DoctorProfile employee={employee} profile={profile} />
                </div>
            ) : profileType === UserProfileDTOProfileType.Nurse ? (
                <div>
                    <div className={styles.profileTitle}>
                        <h4>{t('adminEmployeePage.nurseProfile')}</h4>
                    </div>
                    <NurseProfile employee={employee} profile={profile} />
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
