import { EducationDetailsDTOType, UserDTO, UserDTOStatus, UserProfileDTO } from '@api/userServiceAPI';
import { AlertIconOutlined, UserIcon } from '@icons';
import { capitalizeText, pluralLabel } from '@utils/utils';
import { Col, Row, Tag } from 'antd';
import classNames from 'classnames';
import { t } from 'i18next';
import styles from './DoctorProfile.module.scss';

interface DoctorProfileProps {
    employee: UserDTO | undefined;
    profile: UserProfileDTO | undefined;
    previewSelfMode?: boolean;
}

export const DoctorProfile = ({ employee, profile, previewSelfMode }: DoctorProfileProps) => {
    const getWorkExperience = () => {
        const currentYear = new Date().getFullYear();
        if (!profile?.userProfileDetailsDTO?.practitionerProfessionalActivityStartYear) {
            return null;
        }

        const workExperience = currentYear - profile.userProfileDetailsDTO.practitionerProfessionalActivityStartYear;
        return workExperience + ' ' + t("date_format_declension." + pluralLabel(workExperience, ["year_i", "year_r", "year_m"]));
    };

    const getUniversityList = () => {
        return profile?.userProfileDetailsDTO?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.university) || [];
    };
    const getInternshipList = () => {
        return profile?.userProfileDetailsDTO?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.internship) || [];
    };
    const getCertificateList = () => {
        return profile?.userProfileDetailsDTO?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.certificate) || [];
    };
    const getAccreditationList = () => {
        return profile?.userProfileDetailsDTO?.educationsDetails?.filter((details) => details.type == EducationDetailsDTOType.accreditation) || [];
    };
    const getPhysiciansAssociations = () => {
        return profile?.userProfileDetailsDTO?.physiciansAssociations || [];
    };
    const getWorkExperiences = () => {
        return profile?.userProfileDetailsDTO?.workExperiences || [];
    };

    return (
        <div>
            {employee?.status === UserDTOStatus.ACTIVE ? (
                <>
                    <Row gutter={24} className={classNames(previewSelfMode && styles.selfPreviewPhoto)}>
                        <Col md={12} sm={24} xs={24}>
                            <img className={styles.photoBlock} src={profile?.userProfileDetailsDTO?.photoLink} />
                        </Col>
                    </Row>
                    {!previewSelfMode && (
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <div className={styles.infoItem}>
                                    <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.practitionerSpecialty')}</p>
                                    <p className={styles.infoValue}>
                                        {profile?.userProfileDetailsDTO?.practitionerSpecialties &&
                                        profile?.userProfileDetailsDTO?.practitionerSpecialties.length > 0
                                            ? t(
                                                  `adminEmployeePage.profilePage.practitionerSpecialties.${profile?.userProfileDetailsDTO?.practitionerSpecialties}`,
                                              )
                                            : '-'}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    )}
                    {profile?.userProfileDetailsDTO?.practitionerSpecialties && (
                        <Row gutter={24}>
                            <Col md={8} sm={24} xs={24}>
                                <div className={styles.infoItem}>
                                    <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.academicDegree')}</p>
                                    <p>
                                        {profile?.userProfileDetailsDTO?.academicDegree
                                            ? t(`adminEmployeePage.profilePage.academicDegrees.${profile?.userProfileDetailsDTO?.academicDegree}`)
                                            : '-'}
                                    </p>
                                </div>
                                <div className={styles.infoItem}>
                                    <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.qualificationCategory')}</p>
                                    <p>
                                        {profile?.userProfileDetailsDTO?.qualificationCategory
                                            ? t(
                                                  `adminEmployeePage.profilePage.qualificationCategories.${profile?.userProfileDetailsDTO?.qualificationCategory}`,
                                              )
                                            : '-'}
                                    </p>
                                </div>
                            </Col>
                            <Col md={8} sm={24} xs={24}>
                                <div className={styles.infoItem}>
                                    <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.academicRank')}</p>
                                    <p>
                                        {profile?.userProfileDetailsDTO?.academicRank
                                            ? t(`adminEmployeePage.profilePage.academicRanks.${profile?.userProfileDetailsDTO?.academicRank}`)
                                            : '-'}
                                    </p>
                                </div>
                                <div className={styles.infoItem}>
                                    <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.workExperience')}</p>
                                    <p className={styles.infoValue}>{getWorkExperience() ? getWorkExperience() : '-'}</p>
                                </div>
                            </Col>
                        </Row>
                    )}
                    {!previewSelfMode && (
                        <Row gutter={24}>
                            <Col md={22} sm={24} xs={24}>
                                <div className={styles.serviceInfoBlock}>
                                    <h3>{t('adminEmployeePage.profilePage.serviceInformation.title')}</h3>
                                    <div>
                                        <p className={styles.infoTitle}>
                                            {t('adminEmployeePage.profilePage.serviceInformation.priceCategory.title')}
                                        </p>
                                        <p className={styles.infoValue}>
                                            {profile?.userProfileDetailsDTO?.priceCategory
                                                ? t(
                                                      `adminEmployeePage.profilePage.serviceInformation.priceCategory.${profile?.userProfileDetailsDTO?.priceCategory}`,
                                                  )
                                                : '-'}
                                        </p>
                                    </div>
                                    <div className={styles.infoItem}>
                                        <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.serviceInformation.chiefComplaint')}</p>
                                        <div className={styles.chiefComplaints}>
                                            {profile?.userProfileDetailsDTO?.practitionerChiefComplaintCodes &&
                                            profile?.userProfileDetailsDTO?.practitionerChiefComplaintCodes.length > 0
                                                ? profile?.userProfileDetailsDTO?.practitionerChiefComplaintCodes?.map((item) => (
                                                      <Tag color="#dee4eb" key={item}>
                                                          {t(`adminEmployeePage.profilePage.serviceInformation.chiefComplaints.${item}`)}
                                                      </Tag>
                                                  ))
                                                : '-'}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <div className={styles.profileBlock}>
                                {(getUniversityList().length > 0 ||
                                    getInternshipList().length > 0 ||
                                    getCertificateList().length > 0 ||
                                    getAccreditationList().length > 0) && <h3>{t('adminEmployeePage.profilePage.education.title')}</h3>}
                                {getUniversityList().length > 0 && (
                                    <div className={styles.profileItem}>
                                        <p className={styles.title}>{t('adminEmployeePage.profilePage.education.university')}</p>
                                        {getUniversityList().map((item) => (
                                            <div className={styles.profileRecord} key={item.name}>
                                                <p className={styles.profileRecordYear}>{item.graduationYear}</p>
                                                <p className={styles.profileRecordText}>{capitalizeText(item.name || '')}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {getInternshipList().length > 0 && (
                                    <div className={styles.profileItem}>
                                        <p className={styles.title}>{t('adminEmployeePage.profilePage.education.internship')}</p>
                                        {getInternshipList().map((item) => (
                                            <div className={styles.profileRecord} key={item.name}>
                                                <p className={styles.profileRecordYear}>{item.graduationYear}</p>
                                                <p className={styles.profileRecordText}>{capitalizeText(item.name || '')}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {getCertificateList().length > 0 && (
                                    <div className={styles.profileItem}>
                                        <p className={styles.title}>{t('adminEmployeePage.profilePage.education.certificates')}</p>
                                        {getCertificateList().map((item) => (
                                            <div className={styles.profileRecord} key={item.name}>
                                                <p className={styles.profileRecordYear}>{item.graduationYear}</p>
                                                <p className={styles.profileRecordText}>{capitalizeText(item.name || '')}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {getAccreditationList().length > 0 && (
                                    <div className={styles.profileItem}>
                                        <p className={styles.title}>{t('adminEmployeePage.profilePage.education.accreditation')}</p>
                                        {getAccreditationList().map((item) => (
                                            <div className={styles.profileRecord} key={item.name}>
                                                <p className={styles.profileRecordYear}>{item.graduationYear}</p>
                                                <p className={styles.profileRecordText}>{capitalizeText(item.name || '')}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {getPhysiciansAssociations().length > 0 && (
                                <div className={styles.profileBlock}>
                                    <h3>{t('adminEmployeePage.profilePage.physiciansAssociation')}</h3>
                                    <div className={styles.profileItem}>
                                        {getPhysiciansAssociations().map((item, i) => (
                                            <div className={styles.profileRecord} key={i}>
                                                <p>{capitalizeText(item)}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {getWorkExperiences().length > 0 && (
                                <div className={styles.profileBlock}>
                                    <h3>{t('adminEmployeePage.profilePage.experience')}</h3>
                                    <div className={styles.profileItem}>
                                        {getWorkExperiences().map((item, i) => (
                                            <div className={`${styles.profileRecord} ${styles.profileRecordExperience}`} key={i}>
                                                <p className={styles.profileRecordYear}>
                                                    {item.yearStart}—{item.yearEnd || t('adminEmployeePage.profilePage.presentTime')}
                                                </p>
                                                <p className={styles.profileRecordText}>{capitalizeText(item.description || '')}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <div className={styles.infoBlocked}>
                        <AlertIconOutlined />
                        <p className={styles.infoValue}>{t('adminEmployeePage.profilePage.notActivated')}</p>
                    </div>
                    <Row gutter={24}>
                        <Col md={12} sm={24} xs={24}>
                            <div className={styles.nonAvatarBlock}>
                                <UserIcon />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <div className={styles.infoItem}>
                                <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.practitionerSpecialty')}</p>
                                <p className={styles.infoValue}>
                                    {profile?.userProfileDetailsDTO?.practitionerSpecialties &&
                                    profile?.userProfileDetailsDTO?.practitionerSpecialties.length > 0
                                        ? t(
                                              `adminEmployeePage.profilePage.practitionerSpecialties.${profile?.userProfileDetailsDTO?.practitionerSpecialties}`,
                                          )
                                        : '-'}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={22} sm={24} xs={24}>
                            <div className={styles.serviceInfoBlock}>
                                <h3>{t('adminEmployeePage.profilePage.serviceInformation.title')}</h3>
                                <div>
                                    <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.serviceInformation.priceCategory.title')}</p>
                                    <p className={styles.infoValue}>
                                        {profile?.userProfileDetailsDTO?.priceCategory
                                            ? t(
                                                  `adminEmployeePage.profilePage.serviceInformation.priceCategory.${profile?.userProfileDetailsDTO?.priceCategory}`,
                                              )
                                            : '-'}
                                    </p>
                                </div>
                                <div className={styles.infoItem}>
                                    <p className={styles.infoTitle}>{t('adminEmployeePage.profilePage.serviceInformation.chiefComplaint')}</p>
                                    <div className={styles.chiefComplaints}>
                                        {profile?.userProfileDetailsDTO?.practitionerChiefComplaintCodes &&
                                        profile?.userProfileDetailsDTO?.practitionerChiefComplaintCodes.length > 0
                                            ? profile?.userProfileDetailsDTO?.practitionerChiefComplaintCodes?.map((item) => (
                                                  <Tag color="#dee4eb" key={item}>
                                                      {t(`adminEmployeePage.profilePage.serviceInformation.chiefComplaints.${item}`)}
                                                  </Tag>
                                              ))
                                            : '-'}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <div className={styles.profileBlock}>
                                <h3>{t('adminEmployeePage.profilePage.education.title')}</h3>
                                <div className={styles.profileItem}>
                                    <p className={styles.title}>{t('adminEmployeePage.profilePage.education.university')}</p>
                                    <div className={styles.profileRecord}>
                                        <p className={styles.profileRecordYear}>-</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};
