import { configureStore } from '@reduxjs/toolkit';
import { consultationListenerMiddleware } from './consultation/effects';
import { consultationReducer } from './consultation/slice';
import { coreReducer } from './core';
import { patientListenerMiddleware, patientReducer } from './patient';
import { proceduresListenerMiddleware } from './procedures/effects';
import { proceduresReducer } from './procedures/slice';
import { userListenerMiddleware } from './user/effects';
import { userReducer } from './user/slice';
import { organizationListenerMiddleware } from './organization/effects';
import { organizationReducer } from './organization/slice';

export function makeStore() {
    return configureStore({
        reducer: {
            coreReducer,
            userReducer,
            patientReducer,
            proceduresReducer,
            consultationReducer,
            organizationReducer
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().prepend(
                proceduresListenerMiddleware.middleware,
                userListenerMiddleware.middleware,
                patientListenerMiddleware.middleware,
                consultationListenerMiddleware.middleware,
                organizationListenerMiddleware.middleware
            ),
    });
}

const store = makeStore();

export type RootState = ReturnType<typeof store.getState>;

export default store;
