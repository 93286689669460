import {
    CourseDTO,
    CourseDTOId,
    CreateScheduleDTOPlanIdsItem,
    CreateScheduleDTOServiceTypeItem,
    SlotDTO,
    UpdateSlotDTO,
    getCourses,
} from '@api/mainServiceAPI';
import { UserDTO, UserProfileDTOProfileType } from '@api/userServiceAPI';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EditScheduleSlotModal.module.scss';

interface IEditScheduleSlotModalProps {
    showModal: boolean;
    onSuccess: (updateSlotDTO?: UpdateSlotDTO) => void;
    onCancel?: () => void;
    employee: UserDTO;
    slot: SlotDTO;
    isNurse: boolean;
}

interface FormValues {
    name: string;
    role: string;
    startTime: string;
    endTime: string;
    intervalInMinutes: number;
    eventType: CreateScheduleDTOPlanIdsItem;
    serviceType: CreateScheduleDTOServiceTypeItem;
    course: string;
}

const { Option } = Select;

export const EditScheduleSlotModal = ({ showModal, onSuccess, onCancel, employee, slot, isNurse }: IEditScheduleSlotModalProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [timesOptions, setTimesOptions] = useState<{ label: string; value: string }[]>();
    const [endTimesOptions, setEndTimesOptions] = useState<{ label: string; value: string }[]>();
    const [courses, setCourses] = useState<CourseDTO[]>([]);
    const [serviceType, setServiceType] = useState<CreateScheduleDTOServiceTypeItem>();

    const [initialValues, setInitialValues] = useState<FormValues>();

    useEffect(() => {
        if (!!slot && !!employee) {
            setStartTimesOption((slot.endDateTime! - slot!.startDateTime!) / (1000 * 60));
            setTimesOption((slot.endDateTime! - slot!.startDateTime!) / (1000 * 60));
            setInitialValues({
                eventType: slot.serviceCategory!,
                serviceType: slot.slotServiceType![0]!,
                intervalInMinutes: (slot.endDateTime! - slot!.startDateTime!) / (1000 * 60),
                startTime: dayjs(slot.startDateTime).format('HH:mm'),
                endTime: dayjs(slot.endDateTime).format('HH:mm'),
                name: `${employee?.firstName} ${employee?.lastName}`,
                role: t(`adminMainPage.roles.${employee.userProfileDTOList?.[0].profileType}`),
                course: slot.serviceCategory!,
            });
            setServiceType(slot.slotServiceType![0]);

            if (isNurse) {
                getCourses().then((result) => {
                    if (result.data.courseDTOList && result.data.courseDTOList.length > 0) {
                        setCourses(result.data.courseDTOList.filter((course) => course.id));
                    }
                });
            } else {
                form.setFieldValue('course', 'any');
            }
        }
    }, [slot, employee]);

    const handleChangeCourse = (course: CourseDTOId) => {
        if (isNurse) {
            form.setFieldValue('intervalInMinutes', courses.find((c) => c.id === course)?.sessionDurationInMinutes);
            setTimesOption(form.getFieldValue('intervalInMinutes'));
            form.setFieldValue(
                'endTime',
                dayjs(form.getFieldValue('startTime'), 'HH:mm').add(form.getFieldValue('intervalInMinutes'), 'minute').format('HH:mm'),
            );
        }
    };

    const handleChangePlanId = (serviceType: CreateScheduleDTOServiceTypeItem) => {
        setServiceType(serviceType);
        form.setFieldValue('serviceType', serviceType);
        if (serviceType === 'therapy-session') {
            form.setFieldValue('intervalInMinutes', courses.find((c) => c.id === form.getFieldValue('course'))?.sessionDurationInMinutes);
        }
    };

    const handleIntervalChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value) {
            setStartTimesOption(+value);
            const newEndTime = dayjs(form.getFieldValue('startTime'), 'HH:mm').add(+value, 'minute');
            form.setFieldValue('endTime', dayjs(newEndTime, 'HH:MM').format('HH:mm'));
            setTimesOption(+value);
        }
    };

    const setStartTimesOption = (interval: number) => {
        const times = [];
        let currentTime = form.getFieldValue('startTime')
            ? dayjs(form.getFieldValue('startTime'), 'HH:mm')
            : dayjs(dayjs(dayjs(slot.startDateTime).format('HH:mm'), 'HH:mm'));

        while (currentTime.isBefore(dayjs('24:00', 'HH:mm'))) {
            times.push(currentTime.format('HH:mm'));
            currentTime = currentTime.add(interval, 'minute');
        }
        if (times[times.length - 1] !== '24:00') {
            times.push('24:00');
        }
        setTimesOptions(times.map((value) => ({ value, label: value })));
    };

    const setTimesOption = (interval: number) => {
        const times = [];

        let currentTime = form.getFieldValue('endTime')
            ? dayjs(form.getFieldValue('endTime'), 'HH:mm')
            : dayjs(dayjs(slot.endDateTime).format('HH:mm'), 'HH:mm');
        while (currentTime.isBefore(dayjs('24:00', 'HH:mm'))) {
            times.push(currentTime.format('HH:mm'));
            currentTime = currentTime.add(interval, 'minute');
        }
        if (times[times.length - 1] !== '24:00') {
            times.push('24:00');
        }
        setEndTimesOptions(times.map((value) => ({ value, label: value })));
    };

    const onFinish = (values: FormValues) => {
        onSuccess({
            planDefinitionID:
                employee.userProfileDTOList?.find((profile) => profile.profileType === UserProfileDTOProfileType.Nurse) && (values!.course as any),
            appointmentType: values!.serviceType as any,
            startDateTime:
                dayjs(slot.startDateTime).format('HH:mm') === values.startTime
                    ? undefined
                    : parseDateTimes(dayjs(slot?.startDateTime || 0), values.startTime).getTime(),
            endDateTime:
                dayjs(slot.endDateTime).format('HH:mm') === values.endTime
                    ? undefined
                    : parseDateTimes(dayjs(slot?.endDateTime || 0), values.endTime).getTime(),
        });
    };

    const handleFormValuesChange = (changedValues: any) => {
        const formFieldName = Object.keys(changedValues)[0];
        if (formFieldName === 'startTime') {
            const interval = form.getFieldValue('intervalInMinutes');
            form.setFieldValue('endTime', dayjs(changedValues.startTime, 'HH:mm').add(interval, 'minute').format('HH:mm'));
        }
    };

    const startTimeValue = useWatch('startTime', form);
    const endTimeValue = useWatch('endTime', form);

    const convertTimeStringToMinutes = (time: string): number => {
        const values = time.split(':');
        const seconds: number = +values[0] * 60 + +values[1];
        return seconds;
    };

    return !!initialValues ? (
        <Modal
            width={720}
            open={showModal}
            onCancel={onCancel}
            footer={[
                <div key="empty" />,
                <div key="pare">
                    <Button onClick={onCancel} key="cancel">
                        {t("cancel_button")}
                    </Button>
                    <Button onClick={form.submit} key="save" type="primary">
                        {t("save")}
                    </Button>
                </div>,
            ]}
            title={t("edit_schedule_slot_modal.edit_event")}
        >
            <Form form={form} onFinish={onFinish} initialValues={initialValues} onValuesChange={handleFormValuesChange}>
                <div className={styles.wrapper}>
                    <Row gutter={32}>
                        <Col span={12}>
                            <p className={styles.label}>{t("edit_schedule_slot_modal.performer")}</p>
                            <Form.Item name="name">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <p className={styles.label}>{t("edit_schedule_slot_modal.position")}</p>
                            <Form.Item name="role">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className={styles['event-container']}>
                        <p className={styles.label}>{t("edit_schedule_slot_modal.event_type")}</p>
                        <Form.Item name="serviceType">
                            <Select
                                className="w-100 mt-1"
                                defaultValue={form.getFieldValue('selectedPractitionerEventType')}
                                onChange={handleChangePlanId}
                            >
                                {isNurse
                                    ? Object.keys(CreateScheduleDTOServiceTypeItem)
                                          .filter((x) => x !== 'consultation')
                                          .map((x) => (
                                              <Option key={x} value={x}>
                                                  {t('enums.serviceTypes.' + x)}
                                              </Option>
                                          ))
                                    : Object.keys(CreateScheduleDTOServiceTypeItem)
                                          .filter((x) => x !== 'therapy-session')
                                          .map((x) => (
                                              <Option key={x} value={x}>
                                                  {t('enums.serviceTypes.' + x)}
                                              </Option>
                                          ))}
                            </Select>
                        </Form.Item>
                    </div>
                    {isNurse && form.getFieldValue('serviceType') === CreateScheduleDTOServiceTypeItem['therapy-session'] && (
                        <div className={styles['event-container']}>
                            <p className={styles.label}>{t("edit_schedule_slot_modal.course")}</p>
                            <Form.Item name="course">
                                <Select className="w-100 mt-1" onChange={handleChangeCourse}>
                                    {isNurse
                                        ? courses
                                              .filter((x) => !!x.id)
                                              .map((x) => (
                                                  <Option key={x.id} value={x.id}>
                                                      {t('enums.planDefinition.' + x.id)}
                                                  </Option>
                                              ))
                                        : Object.keys(CreateScheduleDTOServiceTypeItem)
                                              .filter((x) => x !== 'therapy-session')
                                              .map((x) => (
                                                  <Option key={x} value={x}>
                                                      {t('enums.serviceTypes.' + x)}
                                                  </Option>
                                              ))}
                                </Select>
                            </Form.Item>
                        </div>
                    )}
                    <Row className={styles.time} gutter={32}>
                        <Col span={8}>
                            <p className={styles.label}>{t("edit_schedule_slot_modal.duration")}</p>
                            <Form.Item name="intervalInMinutes">
                                <Input
                                    onChange={handleIntervalChange}
                                    className="w-100 mt-1"
                                    value={form.getFieldValue('intervalInMinutes')}
                                    disabled={isNurse && form.getFieldValue('serviceType') === 'therapy-session'}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <p className={styles.label}>{t("edit_schedule_slot_modal.time_from")}</p>
                            <Form.Item name="startTime">
                                <Select className="w-100 mt-1">
                                    {timesOptions?.map((item) => (
                                        <Option
                                            key={item.value}
                                            value={item.value}
                                            disabled={
                                                endTimeValue && convertTimeStringToMinutes(item.value) >= convertTimeStringToMinutes(endTimeValue)
                                            }
                                        >
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <p className={styles.label}>{t("edit_schedule_slot_modal.time_to")}</p>
                            <Form.Item name="endTime">
                                <Select className="w-100 mt-1" disabled={isNurse && form.getFieldValue('serviceType') === 'therapy-session'}>
                                    {endTimesOptions?.map((item) => (
                                        <Option
                                            disabled={
                                                startTimeValue && convertTimeStringToMinutes(item.value) <= convertTimeStringToMinutes(startTimeValue)
                                            }
                                            key={item.value}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal>
    ) : null;
};

const parseDateTimes = (date: Dayjs, time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return date.hour(hours).minute(minutes).second(0).toDate();
};
