import { PatientBaseDTO, PatientDetailsDTO } from '@api/mainServiceAPI';
import { GetUsersParams, UpdateUserDTOStatus, UserDTO, UserProfileDTOProfileType, getUsers } from '@api/userServiceAPI';
import { SearchIcon } from '@icons';
import { selectCurrentProfile } from '@sliceUser';
import { Col, Input, Row, Select, Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './ClinicAdminPatientsPage.module.scss';

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

export const ClinicAdminPatientsPage = () => {
    const navigate = useNavigate();
    const currentProfile = useSelector(selectCurrentProfile);

    const [filterQuery, setFilterQuery] = useState<string | undefined>();
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => <p>{t("clinic_admin.total") + ' ' + total}</p>,
            position: ['bottomCenter'],
        },
    });
    const [filterStatus, setFilterStatus] = useState<string>('');
    const [statusOptions, setStatusOptions] = useState<{ label: string; value: string | null }[]>([]);

    const [filteredPatientList, setFilteredPatientList] = useState<PatientDetailsDTO[]>();

    useEffect(() => {
        getUsers(
            {
                page: tableParams.pagination?.current && tableParams.pagination?.current - 1,
                size: tableParams.pagination?.pageSize,
                profileTypes: Object.values(UserProfileDTOProfileType).filter((item) => filterByRole(item)),
                search: filterQuery,
                status: filterStatus as UpdateUserDTOStatus,
            },
            {
                paramsSerializer: {
                    encode: (params) => {
                        if (params === 'profileTypes[]') {
                            return 'profileTypes';
                        } else {
                            return params;
                        }
                    },
                },
            },
        ).then((result) => {
            const patients = result.data.content;
            if (patients) {
                setFilteredPatientList(patients);
                setTableParams({
                    pagination: { ...tableParams.pagination, total: result.data.totalElements },
                });
            }
        });
        setStatusOptions([
            { label: t('adminMainPage.employeeTableColumns.activeStatus'), value: UpdateUserDTOStatus.ACTIVE },
            { label: t('adminMainPage.employeeTableColumns.nonActiveStatus'), value: UpdateUserDTOStatus.BLOCKED },
            { label: t('adminMainPage.employeeTableColumns.DRAFT'), value: UpdateUserDTOStatus.DRAFT },
            { label: t('adminMainPage.filter.allStatusesOption'), value: '' },
        ]);
    }, [filterQuery, filterStatus]);

    const filterByRole = (role: UserProfileDTOProfileType | undefined): boolean => {
        return !!role && role !== UserProfileDTOProfileType.End_user && role === UserProfileDTOProfileType.Patient;
    };

    const patientsTableColumns: ColumnsType<UserDTO> = [
        {
            title: t('patients.table.trustee'),
            dataIndex: ['firstName', 'lastName', 'middleName'],
            render: (text, record) => {
                let result = '';
                if (record.lastName) {
                    result += record.lastName;
                }
                if (record.firstName) {
                    result += ' ' + record.firstName;
                }
                if (record.middleName) {
                    result += ' ' + record.middleName;
                }
                return result || '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.lastName) {
            //         return 1;
            //     }
            //     if (!b.lastName) {
            //         return -1;
            //     }
            //     if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'name',
            width: 300,
        },
        {
            title: t("clinic_admin.registered"),
            dataIndex: 'created',
            render: (text, record) => {
                if (record.created) {
                    return (
                        <div className="d-flex justify-content-between align-items-center">
                            <p className={styles.birthDay}>{dayjs(record.created).format('DD.MM.YYYY HH:mm')}</p>
                        </div>
                    );
                }
                return '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (a.created) {
            //         return 1;
            //     }
            //     if (b.created) {
            //         return -1;
            //     }

            //     return -1;
            // },
            key: 'created',
        },
        {
            title: t('adminMainPage.employeeTableColumns.status'),
            dataIndex: 'status',
            render: (text, record) => {
                if (record.status) {
                    return (
                        <div className="d-flex justify-content-between align-items-center">
                            <p className={styles.birthDay}>{t('adminMainPage.employeeTableColumns.' + record.status, '-')}</p>
                        </div>
                    );
                }
                return '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.status) {
            //         return -1;
            //     }
            //     if (!b.status) {
            //         return 1;
            //     }

            //     return a.status.toLowerCase() > b.status.toLowerCase() ? 1 : -1;
            // },
            key: 'status',
        },
    ];

    const handleTableChange: TableProps<PatientBaseDTO>['onChange'] = (pagination, filters, sorter) => {
        const searchCriteria: GetUsersParams = {
            page: pagination?.current && pagination?.current - 1,
            size: pagination?.pageSize,
            search: filterQuery,
            profileTypes: Object.values(UserProfileDTOProfileType).filter((item) => filterByRole(item)),
        };

        if (sorter) {
            searchCriteria.sortField = (sorter as any).field || 'created';
            searchCriteria.sortDirection = (sorter as any).order === 'ascend' ? 'ASC' : 'DESC';
        }

        getUsers(searchCriteria, {
            paramsSerializer: {
                encode: (params) => {
                    if (params === 'profileTypes[]') {
                        return 'profileTypes';
                    } else {
                        return params;
                    }
                },
            },
        }).then((result) => {
            const patients = result.data.content;
            if (patients) {
                setFilteredPatientList(patients);
                setTableParams({
                    pagination: { ...pagination, total: result.data.totalElements },
                });
            }
        });
    };

    const openPatientPage = (id: string) => {
        navigate(`/patient/${id}`);
    };

    const handleSelectStatus = (value: string) => {
        setFilterStatus(value);
    };

    return (
        <div className={styles.wrapper}>
            <h1>{t("users")}</h1>
            <div className={styles.filterBlock}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Input
                            value={filterQuery}
                            placeholder={t('adminMainPage.filter.searchPlaceholder')}
                            prefix={<SearchIcon />}
                            onChange={(e) => setFilterQuery(e.target.value)}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            placeholder={t('adminMainPage.filter.statusesPlaceholder')}
                            value={filterStatus}
                            onChange={handleSelectStatus}
                            options={statusOptions}
                            className="w-100"
                        />
                    </Col>
                </Row>
                <div className="mt-4">
                    <Table
                        className={styles.patientRow}
                        rowKey={(record) => record.id!}
                        columns={patientsTableColumns}
                        dataSource={filteredPatientList}
                        pagination={tableParams.pagination}
                        onChange={handleTableChange}
                        showSorterTooltip={false}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    openPatientPage(record.id!);
                                },
                            };
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
