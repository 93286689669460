import {
    AidUserName,
    CarePlanDTO,
    ParticipantDTO,
    ParticipantDTOEncounterStatus,
    ParticipantDTOGender,
    ParticipantDTOPrepresentativeFamilyMember,
    ParticipantDTOProfileType,
    ParticipantDTOTreatmentKitItem,
    PatientFormDTO,
    ProcedureResultDTO,
    ProvidedDocumentDTO,
    TherapySessionResultDTO,
    UserDocumentDTO,
} from '@api/mainServiceAPI';
import { ProcedureTypeEnum } from '@enums';
import i18n from '@utils/i18n';
import { Logger } from '@utils/logger';
import { IOcclusionDay } from 'src/pages/_shared/DoctorAppointmentPage/sessions/ConsultationSession/steps/CourseStep/CourseStep';
import { IChatMessage } from './chat-message.model';
import { KurentoParticipant, ParticipantSelectedType } from './kurento-participant';
import { ParticipantProcedure } from './participant-procedure.model';

export class SessionParticipant {
    public kurentoParticipant?: KurentoParticipant;

    public fhirId?: string;
    public profileType?: ParticipantDTOProfileType;
    public birthDate?: string;
    public patient?: AidUserName;
    public familyMember?: AidUserName;
    public practitioner?: AidUserName;
    public representativeFullName?: string;
    public prepresentativeFamilyMember?: ParticipantDTOPrepresentativeFamilyMember;
    public prepresentativeFamilyMemberValue?: string;
    public supervisingPractitionerFullName?: string;
    public carePlanDTO?: CarePlanDTO;
    public treatmentKit?: ParticipantDTOTreatmentKitItem[];
    public therapySessionResultDTO?: TherapySessionResultDTO;
    public procedureResults?: ProcedureResultDTO[];
    public gender?: ParticipantDTOGender;
    public forms?: PatientFormDTO[];
    public documents?: ProvidedDocumentDTO[];
    public conclusions?: UserDocumentDTO[];
    public encounterFhirId?: string;
    public encounterStatus?: ParticipantDTOEncounterStatus;
    public chatId?: string;

    public inLobby?: boolean;

    public therapySessionResultId?: string;
    public get currentSessionDay() {
        return (this.carePlanDTO?.currentProgress || 0) + 1;
    }
    public get currentSessionTargetEye(): 'ou' | 'od' | 'os' {
        if (!this.carePlanDTO) {
            Logger.error("Patient doesn't have a care plan!");
            return 'ou';
        }

        let result;
        if (this.carePlanDTO?.occlusionType === 'without' || !this.carePlanDTO?.occlusionType) {
            result = this.carePlanDTO!.targetEye!;
        } else {
            result = (this.carePlanDTO!.occlusionSettings as IOcclusionDay[]).find((x) => +x.day! === +this.currentSessionDay)?.type;
        }

        if (!result) {
            Logger.error('currentSessionDayOcclusionType not set');
            return 'ou';
        } else {
            return result;
        }
    }
    public get currentSessionOcclusionEye(): 'od' | 'os' | null {
        if (!this.carePlanDTO) {
            Logger.error("Patient doesn't have a care plan!");
            return null;
        }

        let result;
        if (this.carePlanDTO?.occlusionType === 'without' || !this.carePlanDTO?.occlusionType) {
            result = null;
        } else {
            result = (this.carePlanDTO!.occlusionSettings as IOcclusionDay[]).find((x) => +(x.day || 0) === this.currentSessionDay)?.type;
        }

        return result || null;
    }

    private _procedures: ParticipantProcedure[] = [];
    public get procedures() {
        return this._procedures;
    }
    public set procedures(value: ParticipantProcedure[]) {
        this._procedures = value;
    }

    public relevantProcedureType?: ProcedureTypeEnum;

    public get relevantProcedure() {
        return this.procedures.find((x) => x.type === this.relevantProcedureType);
    }

    private _chatHistory: IChatMessage[] = [];
    public get chatHistory() {
        return this._chatHistory;
    }
    public set chatHistory(value: IChatMessage[]) {
        this._chatHistory = value;
    }

    public get title(): string | undefined {
        return this.kurentoParticipant?.isHost
            ? this.kurentoParticipant?.person?.firstName + ' ' + this.kurentoParticipant?.person?.middleName
            : this.patient?.givenName;
    }
    public get subtitle(): string | undefined {
        return this.kurentoParticipant?.isHost
            ? i18n.t('appointment.practitionerRoles.' + this.kurentoParticipant?.person?.role)
            : this.familyMember?.givenName +
                  ' ' +
                  (this.familyMember?.middleName || '') +
                  (this.prepresentativeFamilyMember ? '(' + i18n.t('dictionaries.relativity.' + this.prepresentativeFamilyMember) + ')' : '');
    }
    public get fullName(): string | undefined {
        return `${this.patient?.familyName} ${this.patient?.givenName} ${this.patient?.middleName}`;
    }
    public get trusteeFullName(): string | undefined {
        return (
            this.familyMember?.familyName +
            ' ' +
            this.familyMember?.givenName +
            ' ' +
            this.familyMember?.middleName +
            ' ' +
            (this.prepresentativeFamilyMember ? '(' + i18n.t('dictionaries.relativity.' + this.prepresentativeFamilyMember) + ')' : '')
        );
    }

    private _selected: ParticipantSelectedType;
    public get selected() {
        return this._selected;
    }

    constructor(patientParticipant?: ParticipantDTO) {
        if (patientParticipant) {
            this.fhirId = patientParticipant.fhirId;
            this.profileType = patientParticipant.profileType;
            this.birthDate = patientParticipant.birthDate;
            this.patient = patientParticipant.patient;
            this.familyMember = patientParticipant.familyMember;
            this.practitioner = patientParticipant.practitioner;
            this.representativeFullName = patientParticipant.representativeFullName;
            this.prepresentativeFamilyMember = patientParticipant.prepresentativeFamilyMember;
            this.prepresentativeFamilyMemberValue = patientParticipant.prepresentativeFamilyMemberValue;
            this.supervisingPractitionerFullName = patientParticipant.supervisingPractitionerFullName;
            this.carePlanDTO = patientParticipant.carePlanDTO;
            this.treatmentKit = patientParticipant.treatmentKit;
            this.therapySessionResultDTO = patientParticipant.therapySessionResultDTO;
            this.procedureResults = patientParticipant.procedureResults;
            this.gender = patientParticipant.gender;
            this.forms = patientParticipant.forms;
            this.documents = patientParticipant.documents;
            this.conclusions = patientParticipant.conclusions;
            this.encounterFhirId = patientParticipant.encounterFhirId;
            this.encounterStatus = patientParticipant.encounterStatus;
            this.chatId = patientParticipant.patientPractitionerChat?.chat?.id;
            this.chatHistory =
                patientParticipant.patientPractitionerChat?.messages?.map((x) => ({
                    id: x.id,
                    text: x.text,
                    createdDate: new Date(x.dateTime!),
                    senderId: x.sender?.id,
                    sent: x.read, // todo for now - we don't distinguish these options
                    viewed: x.read, // todo for now - we don't distinguish these options
                })) || [];
        }
    }

    public select(type: ParticipantSelectedType): void {
        this._selected = type;
    }
}
