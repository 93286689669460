import { CreateUserProfileDTOAidFamilyMemberCode, UserProfileDTO } from '@api/userServiceAPI';
import { selectAuthUser } from '@sliceUser';
import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './UserProfilePage.module.scss';

export const UserProfilePage = () => {
    const authUser = useSelector(selectAuthUser);
    const [fullName, setFullName] = useState<string>('');
    const [birthDate, setBirthDate] = useState<string>('');
    const [profiles, setProfiles] = useState<UserProfileDTO[]>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        setFullName(getFullName(authUser));
        setBirthDate(getAge(authUser));
        setProfiles((authUser?.userProfileDTOList || []).filter((x) => x.profileType !== 'End_user'));
    }, [authUser]);

    const getFullName = (dto?: { lastName?: string; firstName?: string; middleName?: string } | null): string => {
        return `${dto?.lastName || ''} ${dto?.firstName || ''} ${dto?.middleName || ''}`.trim();
    };

    const getAge = (dto?: { birthDate?: string } | null, appendAge = false): string => {
        const bDate = dayjs(dto?.birthDate);
        const now = dayjs();
        const ageString = `${getYearString(now.diff(bDate, 'year'))}`
        return `${bDate.format('DD.MM.YYYY')} ${appendAge ? '(' + ageString + ')' : ''}`;
    };

    const getYearString = (years: number) => {
        if (years % 10 === 1) {
            return `${years} ${t("date_format_declension.year_i")}`;
        } else if ([2, 3, 4].includes(years % 10) && ![12, 13, 14].includes(years % 100)) {
            return `${years} ${t("date_format_declension.year_r")}`;
        } else {
            return `${years} ${t("date_format_declension.year_m")}`;
        }
    };


    const createPatientHandler = () => {
        navigate('/create-patient');
    };

    const getRelativity = (code?: CreateUserProfileDTOAidFamilyMemberCode, description?: string): string => {
        return code ? (code === CreateUserProfileDTOAidFamilyMemberCode.OTHER ? description || '' : t(`dictionaries.relativity.${code}`)) : '';
    };

    return (
        <div className={styles.wrapper}>
            <h1>{t("user_profile.title")}</h1>
            <Row>
                <Col span={8}>
                    <h4 className={styles.profileTitle}>{t("user_profile.personal_info")}</h4>

                    <div className={styles.infoItem}>
                        <p className={styles.infoTitle}>{t("user_profile.full_name")}</p>
                        <p className={styles.infoValue}>{fullName}</p>
                    </div>
                    <div className={styles.infoItem}>
                        <p className={styles.infoTitle}>{t("user_profile.birth_date")}</p>
                        <p className={styles.infoValue}>{birthDate}</p>
                    </div>
                    <div className={styles.infoItem}>
                        <p className={styles.infoTitle}>{t('adminEmployeePage.mobile')}</p>
                        <p className={styles.infoValue}>{authUser?.mobile}</p>
                    </div>
                    <div className={styles.infoItem}>
                        <p className={styles.infoTitle}>{t('adminEmployeePage.email')}</p>
                        <p className={styles.infoValue}>{authUser?.email}</p>
                    </div>
                </Col>
                <Col span={16}>
                    <h4 className={styles.profileTitle}>{t("user_profile.my_patients")}</h4>
                    <>
                        {profiles.map((p, i) => {
                            const data = p.userProfileDetailsDTO;

                            return (
                                <div className={styles.card} key={p.fhirId}>
                                    <Row gutter={10}>
                                        <Col span={18}>
                                            <h5 className="mb-4">{getFullName(data)}</h5>
                                        </Col>
                                        <Col span={6} className={styles.confirmation}>
                                            {data?.patientApproved && <div className={styles.confirmed}>{t("user_profile.approved")}</div>}
                                        </Col>
                                        <Col span={6}>
                                            <div className={styles.infoItem}>
                                                <p className={styles.infoTitle}>{t("user_profile.birth_date")}</p>
                                                <p className={styles.infoValue}>{getAge(data, true)}</p>
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            <div className={styles.infoItem}>
                                                <p className={styles.infoTitle}>{t(`user_profile.gender`)}</p>
                                                <p className={styles.infoValue}>{t(`dictionaries.genders.long.${data?.gender}`)}</p>
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <div className={styles.infoItem}>
                                                <p className={styles.infoTitle}>{t("user_profile.patient_oms_number")}</p>
                                                <p className={styles.infoValue}>{data?.patientOmsNumber || t("user_profile.no_oms")}</p>
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            <div className={styles.infoItem}>
                                                <p className={styles.infoTitle}>{t("user_profile.snils")}</p>
                                                <p className={styles.infoValue}>{data?.snils || '-'}</p>
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            {data?.representativeMemberDescription && (
                                                <div className={styles.infoItem}>
                                                    <p className={styles.infoTitle}>{t("user_profile.representative_member")}</p>
                                                    <p className={styles.infoValue}>
                                                        {getRelativity(
                                                            data?.patientRepresentativeFamilyMemberCode,
                                                            data?.representativeMemberDescription,
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })}
                    </>
                    <Button onClick={createPatientHandler}>{t("user_profile.add_patient_button")}</Button>
                </Col>
            </Row>
        </div>
    );
};
