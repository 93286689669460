import { IProcedureBase, ProcedureBase } from './procedureBase.abstract';

export interface IMakulostimulationConfig {
    spiralColor: string;
    stripeWidth: number;
    speed: number;
}

export class MakulostimulationProcedure extends ProcedureBase implements IProcedureBase {
    // Procedure's global configuration
    private _backgroundColor = 'black';
    private _smoothness = 60; // more -> smoother (60 is okay)
    private _coilsCount = 20;
    private _startRadius = 0;

    private _config: IMakulostimulationConfig;

    private _stepSize: number;
    private _centerX: number;
    private _centerY: number;

    constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, config: IMakulostimulationConfig) {
        super(canvas, ctx, config);

        this._config = config;
        this._centerX = canvas.width / 2;
        this._centerY = canvas.height / 2;
        this._stepSize = (2 * Math.PI) / this._smoothness;
    }

    public run(): void {
        // draw canvas (animation)
        this._drawProcedure();
    }

    private _drawProcedure(rotationAngle = 0): void {
        let newRotationAngle = rotationAngle;
        if (!this.paused) {
            newRotationAngle = this._drawSpirals(rotationAngle);
        }

        if (this._animationNeeded) {
            // reduce FPS
            this.animationTimeout = setTimeout(() => {
                requestAnimationFrame(() => {
                    this._drawProcedure(newRotationAngle);
                });
            }, 1000 / this._fps);
        }
    }

    // ========================================================================
    // Just drawing logic below
    // ========================================================================
    private _drawSpirals(rotationAngle: number): number {
        // first stripe color (just background)
        this._ctx.fillStyle = this._config.spiralColor;
        this._ctx.fillRect(0, 0, this._canvas.width, this._canvas.height);

        this._ctx.beginPath();
        let endAngle = 2 * Math.PI * this._coilsCount;
        let finished = false;
        let coilAngle = rotationAngle;

        for (let angle = 0; !finished; angle += this._stepSize) {
            if (angle > endAngle) {
                angle = endAngle;
                finished = true;
            }
            const scalar = this._startRadius + this._config.stripeWidth * angle * angle;
            const rotatedAngle = angle - coilAngle;
            const x = this._centerX + scalar * Math.cos(rotatedAngle);
            const y = this._centerY - scalar * Math.sin(rotatedAngle);

            this._ctx.lineTo(x, y);
        }

        finished = false;
        coilAngle = coilAngle + Math.PI;
        endAngle = 2 * Math.PI * this._coilsCount;

        for (let angle = endAngle + 1; !finished; angle -= this._stepSize) {
            if (angle < 0) {
                angle = 0;
                finished = true;
            }
            const scalar = this._startRadius + this._config.stripeWidth * angle * angle;
            const rotatedAngle = angle - coilAngle;
            const x = this._centerX + scalar * Math.cos(rotatedAngle);
            const y = this._centerY - scalar * Math.sin(rotatedAngle);

            this._ctx.lineTo(x, y);
        }

        // second stripe color
        this._ctx.fillStyle = this._backgroundColor;
        this._ctx.fill();

        let newRotationAngle = rotationAngle;
        if (newRotationAngle >= 2 * Math.PI) {
            newRotationAngle = 0;
        }
        newRotationAngle = newRotationAngle + (Math.PI / 180) * this._config.speed;

        return newRotationAngle;
    }
}
