import { cursorInRect, getRandomNumber } from './helpers';
import { IProcedureBase, ProcedureBase } from './procedureBase.abstract';

export interface IPleopticSpiralsConfig {
    draggableSpiralCenterOffset: number;
    spiralStrokeWidth: number;
    spiralStep: number;
    accuracy: number; // in pixels. If 0 - spirals centers should be in the same pixel
}

// there is an optical illusion here
// technically - it's not spirals, it's concentric circles
export class PleopticSpiralsProcedure extends ProcedureBase implements IProcedureBase {
    // Procedure's global configuration
    private _staticSpiralColor = '#3FE67A';
    private _draggableSpiralColor = '#FFA94F';
    private _mainBackgroundColor = '#0DAAFF';
    private _spiralsBackgroundColor = 'black';

    private _config: IPleopticSpiralsConfig;

    // static spiral props
    private _staticSpiralCenterX;
    private _staticSpiralCenterY;
    private _staticSpiralRadius;

    // draggable spiral props
    private _spiralIsDragging = false;
    private _draggableSpiralCenterX: number;
    private _draggableSpiralCenterY: number;
    private _spiralDragOffsetX = 0;
    private _spiralDragOffsetY = 0;

    constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, config: IPleopticSpiralsConfig) {
        super(canvas, ctx, config);

        this._config = config;
        this._staticSpiralCenterX = canvas.width / 2;
        this._staticSpiralCenterY = canvas.height / 2;
        this._staticSpiralRadius = canvas.height / 2;

        this._setDraggableSpiralRandom();
    }

    public run(): void {
        // interactive
        this._setEventListeners();

        // draw canvas (animation)
        this._drawProcedure();
    }

    private _drawProcedure(): void {
        // there is an optical illusion here
        // visually - we have 2 spirals (e.g. red and green) and background (e.g black)
        // but technically - we have 2 spirals: red and black, and background: green

        // order is important
        if (!this.paused) {
            this._drawSpiralsBackground();
            this._drawDraggableSpiral();
            this._drawStaticSpiral();
            this._drawMainBackground();
        }

        if (this._animationNeeded) {
            // reduce FPS
            this.animationTimeout = setTimeout(() => {
                requestAnimationFrame(() => this._drawProcedure());
            }, 1000 / this._fps);
        }
    }

    private _setEventListeners(): void {
        const mousedownHandler = (e: MouseEvent) => {
            const spiralClicked = cursorInRect(
                this._canvas,
                e,
                this._draggableSpiralCenterX - this._staticSpiralRadius,
                this._draggableSpiralCenterY - this._staticSpiralRadius,
                this._staticSpiralRadius * 2,
                this._staticSpiralRadius * 2,
            );

            if (spiralClicked) {
                this._spiralIsDragging = true;
                this._spiralDragOffsetX = e.offsetX - this._draggableSpiralCenterX;
                this._spiralDragOffsetY = e.offsetY - this._draggableSpiralCenterY;
            }
        };

        const mouseupHandler = () => {
            const sameCenterByX = Math.abs(this._draggableSpiralCenterX - this._staticSpiralCenterX) <= this._config.accuracy;
            const sameCenterByY = Math.abs(this._draggableSpiralCenterY - this._staticSpiralCenterY) <= this._config.accuracy;

            // success => next round
            if (sameCenterByX && sameCenterByY) {
                this._successTries++;
                // this._failsInRow = 0;    // according to new requirements - we need total fails, not in row

                this._setDraggableSpiralRandom();
            } else {
                this._failedTries++;

                this._setDraggableSpiralRandom();
            }
            this._totalTries++;

            this.interact();

            this._spiralIsDragging = false;
        };

        const mousemoveHandler = (e: MouseEvent) => {
            if (!this._spiralIsDragging) {
                return;
            }

            const posX = e.offsetX - this._spiralDragOffsetX;
            const posY = e.offsetY - this._spiralDragOffsetY;

            const inCircle =
                Math.sqrt(
                    (posX - this._staticSpiralCenterX) * (posX - this._staticSpiralCenterX) +
                        (posY - this._staticSpiralCenterY) * (posY - this._staticSpiralCenterY),
                ) < this._staticSpiralRadius;

            if (inCircle) {
                this._draggableSpiralCenterX = posX;
                this._draggableSpiralCenterY = posY;
            }
        };

        this.mousedownHandler = mousedownHandler.bind(this);
        this.mouseupHandler = mouseupHandler.bind(this);
        this.mousemoveHandler = mousemoveHandler.bind(this);
        this._canvas.addEventListener('mousedown', this.mousedownHandler);
        this._canvas.addEventListener('mouseup', this.mouseupHandler);
        this._canvas.addEventListener('mousemove', this.mousemoveHandler);
    }

    private _setDraggableSpiralRandom() {
        // set random position for draggable spiral
        let posX;
        let posY;

        do {
            posX = getRandomNumber(this._staticSpiralCenterX - this._staticSpiralRadius, this._staticSpiralCenterX + this._staticSpiralRadius);
            posY = getRandomNumber(this._staticSpiralCenterY - this._staticSpiralRadius, this._staticSpiralCenterY + this._staticSpiralRadius);
        } while (
            Math.sqrt(
                (posX - this._staticSpiralCenterX) * (posX - this._staticSpiralCenterX) +
                    (posY - this._staticSpiralCenterY) * (posY - this._staticSpiralCenterY),
            ) > this._staticSpiralRadius
        );

        this._draggableSpiralCenterX = posX;
        this._draggableSpiralCenterY = posY;
    }

    // ========================================================================
    // Just drawing logic below
    // ========================================================================
    private _drawSpiralsBackground(): void {
        // draw spirals background
        this._ctx.beginPath();
        this._ctx.arc(this._canvas.width / 2, this._canvas.height / 2, this._staticSpiralRadius, 0, Math.PI * 2);
        this._ctx.fillStyle = this._staticSpiralColor; // be aware of, illusion
        this._ctx.fill();
        this._ctx.closePath();
    }

    private _drawDraggableSpiral(): void {
        const spiralRadius = this._staticSpiralRadius - (this._staticSpiralRadius * this._config.spiralStrokeWidth) / 2 + 2;

        for (let radius = spiralRadius; radius >= 1; radius -= radius * this._config.spiralStep) {
            this._ctx.beginPath();
            this._ctx.arc(this._draggableSpiralCenterX, this._draggableSpiralCenterY, radius, 0, Math.PI * 2);
            // this._ctx.arc(this._staticSpiralCenterX, this._staticSpiralCenterY, radius, 0, Math.PI * 2);
            // this._ctx.lineWidth = (radius * 1) / 5; // todo ! just tailoring
            this._ctx.lineWidth = radius * this._config.spiralStrokeWidth;
            this._ctx.strokeStyle = this._draggableSpiralColor;
            this._ctx.stroke();
            this._ctx.closePath();
        }
    }

    private _drawStaticSpiral(): void {
        // 2 - it's a default stroke width
        const spiralRadius =
            this._staticSpiralRadius -
            (this._staticSpiralRadius * this._config.spiralStrokeWidth) / 2 +
            2 -
            this._staticSpiralRadius * this._config.spiralStrokeWidth;

        for (let radius = spiralRadius; radius >= 1; radius -= radius * this._config.spiralStep) {
            this._ctx.beginPath();
            this._ctx.arc(this._staticSpiralCenterX, this._staticSpiralCenterY, radius, 0, Math.PI * 2);
            this._ctx.lineWidth = radius * this._config.spiralStrokeWidth;
            this._ctx.strokeStyle = this._spiralsBackgroundColor; // be aware of, illusion
            this._ctx.stroke();
            this._ctx.closePath();
        }
    }

    private _drawMainBackground(): void {
        // there is an optical illusion here
        // it's not a rect, it's a ring with huge (whole screen width) thickness
        const backgroundStrokeWidth = this._canvas.width;

        this._ctx.beginPath();
        this._ctx.arc(this._staticSpiralCenterX, this._staticSpiralCenterY, this._staticSpiralRadius + backgroundStrokeWidth / 2, 0, Math.PI * 2);
        this._ctx.lineWidth = backgroundStrokeWidth;
        this._ctx.strokeStyle = this._mainBackgroundColor;
        this._ctx.stroke();
        this._ctx.closePath();
    }
}
