/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AboutPractitionerDTO, ConsultationSlotDTO, GetPractitionersForScheduleWithSlotsParams, SlotStatusDto } from '@api/mainServiceAPI';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import { IPractitionerState, initialPractitionerState } from './state';

// Slice
export const consultationSlice = createSlice({
    name: 'consultation',
    initialState: initialPractitionerState,
    reducers: {
        getPractitioners: (state: IPractitionerState, action: PayloadAction<GetPractitionersForScheduleWithSlotsParams & { userId: string }>) => {},
        setPractitioners: (state: IPractitionerState, action: PayloadAction<AboutPractitionerDTO[] | undefined>) => {
            state.practitioners = action.payload || [];
        },
        sendPatientInConsultationSlot: (state: IPractitionerState, action: PayloadAction<ConsultationSlotDTO>) => {},
        setPatientInConsultationSlot: (state: IPractitionerState, action: PayloadAction<SlotStatusDto>) => {
            state.slot = action.payload;
        },
    },
});

// Actions
export const {
    getPractitioners,
    setPractitioners,

    sendPatientInConsultationSlot,
    setPatientInConsultationSlot,
} = consultationSlice.actions;

// Reducer
export const consultationReducer = consultationSlice.reducer;

// Selectors
export const selectPractitioners = (state: RootState) => state.consultationReducer.practitioners;
export const selectSlot = (state: RootState): SlotStatusDto | undefined => state.consultationReducer.slot;
