import {
    CarePlanDTO,
    CarePlanDTOStatus,
    getPatient,
    getPatientCarePlans,
    getPatientEncountersByParams,
    PatientDetailsDTO,
    PatientEncounterDTO
} from '@api/mainServiceAPI';
import { UserProfileDTOProfileType } from '@api/userServiceAPI';
import { selectCarePlan, selectCarePlanEncounters } from '@slicePatient';
import { selectAuthUser, selectCurrentProfile } from '@sliceUser';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface IPatientSchedule {
    patientId?: string
}

export function usePatientScheduleHook({ patientId }: IPatientSchedule): [string | undefined, string | undefined, PatientEncounterDTO[] | null, CarePlanDTO | null, PatientDetailsDTO | null] {
    const authUser = useSelector(selectAuthUser);
    const profile = useSelector(selectCurrentProfile);
    const appointments = useSelector(selectCarePlanEncounters);
    const plan = useSelector(selectCarePlan);
    
    const [patientFhirId, setPatientFhirId] = useState<string>();
    const [supervisingPractitionerId, setSupervisingPractitionerId] = useState<string>();
    const [carePlan, setCarePlan] = useState<CarePlanDTO | null>(null);
    const [patientAppointments, setPatientAppointments] = useState<PatientEncounterDTO[] | null>(null);
    const [patient, setPatient] = useState<PatientDetailsDTO| null>(null);

    const fetchPatientData = useCallback(async (cPatientId: string) => {
        if(!cPatientId) {
            return;
        }
        
        const patientResponse = await getPatient(cPatientId);
        const carePlanResponse = await getPatientCarePlans(cPatientId);
        const cPlan = carePlanResponse.data?.find((cp) => cp.status !== CarePlanDTOStatus.completed) || carePlanResponse.data?.[0];
        
        setCarePlan(cPlan);
        setSupervisingPractitionerId(patientResponse?.data?.supervisingPractitionerId);
        setPatientFhirId(cPatientId);
        setPatient(patientResponse?.data);
        if(cPlan?.fhirId) {
            const appointmentsResult = await getPatientEncountersByParams(cPatientId, {
                carePlanId: cPlan.fhirId
            });
            
            setPatientAppointments(appointmentsResult.data);
        }
    }, []);

    useEffect(() => {
        if(patientId && authUser?.role === UserProfileDTOProfileType.Call_center_manager) {
            fetchPatientData(patientId);
        } else {
            if(profile?.fhirId) {
                setPatientFhirId(profile.fhirId);

                if(profile.userProfileDetailsDTO?.supervisingPractitioner) {
                    setSupervisingPractitionerId(profile.userProfileDetailsDTO.supervisingPractitioner.id);
                }
            }
            
            setPatientAppointments(appointments);
            setCarePlan(plan);
        }
    }, [authUser?.role])


    return [patientFhirId, supervisingPractitionerId, patientAppointments, carePlan, patient];
}
