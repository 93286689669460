import { UserDTO } from '@api/userServiceAPI';
import { InfoIcon } from '@icons';
import { selectAuthUser, selectCurrentProfile } from '@sliceUser';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AccountInfo } from 'src/pages/ClinicAdmin/ClinicAdminEmployeePage/components';
import styles from './AccountInfoPage.module.scss';

export const AccountInfoPage = () => {
    const user = useSelector(selectAuthUser);
    const profile = useSelector(selectCurrentProfile);
    const [fullName, setFullName] = useState<string>('');
    const { t } = useTranslation();

    useEffect(() => {
        setFullName(`${user?.lastName} ${user?.firstName} ${user?.middleName || ''}`);
    }, [user, profile]);

    return (
        <div className={styles.wrapper}>
            <h1>{t('AccountInfo.title')}</h1>

            <div className={styles.infoItem}>
                <p className={styles.infoTitle}>{t('AccountInfo.fullName')}</p>
                <p className={styles.infoValue}>{fullName}</p>
            </div>
            <AccountInfo selectedEmployee={user as UserDTO} blocked={false} previewSelfMode={true} />
            <Space size={12} className={styles.info}>
                <InfoIcon />
                <p className={styles.infoValue}>{t('AccountInfo.askAdminForContacts')}</p>
            </Space>
        </div>
    );
};
