import { EsiaContactDTOType, EsiaPersonFullDTO } from "@api/userServiceAPI";

interface IEsiaLocalStorageElement {
    [key: string]: EsiaPersonFullDTO
}

export class EsiaLoginLocalStorage {
    private static readonly key = 'esia-logins-data';

    public static getData() {
        const data = localStorage.getItem(this.key);
        if (data) {
            return JSON.parse(data) as IEsiaLocalStorageElement;
        }
    }

    public static clear() {
        localStorage.removeItem(this.key);
    }

    public static setData(data: EsiaPersonFullDTO) {
        let storedData = this.getData();
        if(!storedData) {
            storedData = {};
        }
        const cEmail = data.contacts?.find((c) => c.type === EsiaContactDTOType.EML)?.value;

        if(cEmail) {
            storedData[cEmail] = data;
            localStorage.setItem(this.key, JSON.stringify(storedData));
        }
    }

    public static getDataByEmail(email: string) {
        const data = this.getData();
        if(data) {
            return data[email];
        }
    }

    public static clearDataByEmail(email: string) {
        const data = this.getData();
        if(data && data[email]) {
            delete data[email];
            localStorage.setItem(this.key, JSON.stringify(data));
        }
    }
}