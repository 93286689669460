import { ConsultationAppointmentDetailsDTO, ConsultationAppointmentDetailsDTOEncounterStatus } from '@api/mainServiceAPI';
import { PaymentType } from '@enums';
import { selectPatientWorkspacePrepared } from '@slicePatient';
import { selectCurrentProfile } from '@sliceUser';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConsultationBlock } from '../ConsultationBlock/ConsultationBlock';
import { CourseRecommendedBlock } from '../CourseRecommendedBlock/CourseRecommendedBlock';
import { SessionConnectionButton } from '../SessionConnectionButton/SessionConnectionButton';
import { WorkspaceSettings } from '../WorkspaceSettings/WorkspaceSettings';
import styles from './InitialConsultationBlock.module.scss';

export interface IInitialConsultationBlockProps {
    consultation: ConsultationAppointmentDetailsDTO;
    openDocInfo: (docId: string) => void;
    pay: (appointmentFhirId: string, paymentType: PaymentType) => void;
}

export const InitialConsultationBlock = ({ consultation, openDocInfo, pay }: IInitialConsultationBlockProps) => {
    const currentProfile = useSelector(selectCurrentProfile);
    const patientWorkspacePrepared = useSelector(selectPatientWorkspacePrepared);
    const { t } = useTranslation();
    return (
        <>
            {consultation.status !== 'fulfilled' ? (
                <>
                    <ConsultationBlock consultation={consultation} openDocInfo={openDocInfo} pay={pay} />

                    {consultation.paymentStatus === 'completed' && (consultation.status === 'booked' || consultation.status === 'arrived') && (
                        <>
                            {consultation.encounterStatus !== ConsultationAppointmentDetailsDTOEncounterStatus.onleave && (
                                <SessionConnectionButton
                                    appointmentId={consultation.appointmentFhirId!}
                                    appointmentType={'consultation'}
                                    timestampX={consultation.dateTime!}
                                />
                            )}

                            <div className={styles['border-container']}>
                                <div className={styles.content}>
                                    <Row align="middle" justify="space-between">
                                        <Col span={10}>
                                            <p className={styles.title}>{t('initial_consultation_block.title')}</p>
                                            <div className="mt-5">
                                                <p className={styles.description}>{t('initial_consultation_block.p1')}</p>
                                                <p className={`${styles.description} mt-3`}>{t('initial_consultation_block.p2')}</p>
                                                {/*<Link className={`${styles.link} d-flex align-items-center mt-4`} to="/">*/}
                                                {/*    {t("initial_consultation_block.details")}*/}
                                                {/*    <TriangleRight />*/}
                                                {/*</Link>*/}
                                            </div>
                                        </Col>
                                        <Col span={12} className="d-flex justify-content-end">
                                            <iframe
                                                width="445"
                                                height="250"
                                                src="https://www.youtube.com/embed/JWdxRwdckb8"
                                                title="Embedded youtube"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                {patientWorkspacePrepared && (
                                    <div>
                                        <div className={styles['big-divider']} />
                                        <WorkspaceSettings onlyForInitialSettings={false} profileId={currentProfile!.fhirId!} />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <CourseRecommendedBlock />
                    {/* <AboutOkoBlock openDocInfo={openDocInfo} /> */}
                </>
            )}
        </>
    );
};
