import { Button, Col, Form, Modal, Row } from "antd";
import styles from './AddSnilsModal.module.scss';
import { useTranslation } from "react-i18next";
import SnilsInput, { SNILS_PATTERN } from "src/components/SnilsInput/SnilsInput";
import { updatePatient } from "@api/mainServiceAPI";
import { ICurrentProfile, setCurrentProfile } from "@sliceUser";
import { useDispatch } from "react-redux";

export interface IAddSnilsModalProps {
    visible: boolean;
    userName: string;
    profile: ICurrentProfile | null;
    onClose: () => void;
}

export const AddSnilsModal = ({ visible, userName, profile, onClose }: IAddSnilsModalProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onSave = (e: any) => {
        if(profile?.fhirId) {
            updatePatient(profile.fhirId, {
                birthDate: profile.userProfileDetailsDTO?.birthDate,
                firstName: profile.userProfileDetailsDTO?.firstName,
                gender: profile.userProfileDetailsDTO?.gender,
                lastName: profile.userProfileDetailsDTO?.lastName,
                middleName: profile.userProfileDetailsDTO?.middleName,
                snils: e.snils
            });
            dispatch(setCurrentProfile({...profile, userProfileDetailsDTO: {
                ...profile.userProfileDetailsDTO,
                snils: e.snils
            }}))
        }

        onClose();
    }

    return visible ? (
        <Modal
            className={styles.supportModal}
            width={592}
            title={t("CreatePatientPage.labels.addingSnils")}
            open={visible}
            onCancel={onClose}
            footer={[
                <Button type="default" className="w-100" onClick={onClose} key="0">
                    {t('modals.buttons.cancel')}
                </Button>,
                <Button type="primary" htmlType="submit" onClick={form.submit} className="w-100" key="1">
                    {t('modals.buttons.upload')}
                </Button>,
            ]}
        >
            <Form
                form={form}
                onFinish={onSave}
                name="addSnilsForm"
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{
                    snils: '',
                }}
                autoComplete="off"
            >
                <Row gutter={24}>
                    <Col md={24} sm={24} xs={24}>
                        <div className="mb-4">
                            {t("snils.add_snils_patient")} {userName}
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            className={styles.bottomSection}
                            name="snils"
                            rules={[
                                { required: true, message: t('adminMainPage.validation.snilsIsRequired') },
                                {
                                    pattern: SNILS_PATTERN,
                                    message: t('adminMainPage.validation.snilsWrongFormat'),
                                },
                            ]}
                            label={t('CreatePatientPage.labels.snils')}
                        >
                                <SnilsInput
                                    value={form.getFieldValue('snils')}
                                    onChange={e => form.setFieldsValue({ snils: e.target.value })}
                                />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    ) : null;
};
