import { AppointmentScheduleDTO, EncounterDTODetails, UpdateAppointmentDTOStatus, getAvailableSlots } from '@api/mainServiceAPI';
import { AlertIconOutlined } from '@icons';
import { dateForServer } from '@utils/utils';
import { Button, Col, Form, Modal, Radio, RadioChangeEvent, Row, Select } from 'antd';
import classNames from 'classnames';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EncounterChangeReasonEnum } from 'src/enums/encounter-change-reason.enum';
import styles from './CancelProcedureForOneModal.module.scss';

interface ICancelProcedureForOneModalProps {
    showModal: boolean;
    onSuccess: (reason: EncounterChangeReasonEnum) => void;
    onCancel?: () => void;
    encounter: EncounterDTODetails;
    patientFullName: string;
    onChangeProcedure: (slot: AppointmentScheduleDTO, reasonType: EncounterChangeReasonEnum, reasonDescription: string) => void;
}

interface FormValues {
    reason: EncounterChangeReasonEnum;
    option: UpdateAppointmentDTOStatus;
    course: any;
}

const { Option } = Select;

export const CancelProcedureForOneModal = ({
    showModal,
    onSuccess,
    onCancel,
    encounter,
    patientFullName,
    onChangeProcedure,
}: ICancelProcedureForOneModalProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [availableSlots, setAvailableSlots] = useState<AppointmentScheduleDTO[]>([]);
    const [morning, setMorning] = useState<AppointmentScheduleDTO[]>();
    const [day, setDay] = useState<AppointmentScheduleDTO[]>();
    const [evening, setEvening] = useState<AppointmentScheduleDTO[]>();
    const [selectedSlot, setSelectedSlot] = useState<AppointmentScheduleDTO | undefined>();
    const [selectedOption, setSelectedOption] = useState<UpdateAppointmentDTOStatus>('cancelled');

    const cancelOption = {
        title: t("cancel_procedure_one_modal.cancel_procedures"),
        subtitle: t("cancel_procedure_one_modal.patient_get_message"),
        value: UpdateAppointmentDTOStatus.cancelled,
    };
    const replaceOption = {
        title: t("cancel_procedure_one_modal.move_procedure"),
        subtitle: t("cancel_procedure_one_modal.patient_will_moved_message"),
        value: UpdateAppointmentDTOStatus.pending,
    };

    const initialValues = {
        reason: '',
        option: UpdateAppointmentDTOStatus.cancelled,
        course: '',
    };

    useEffect(() => {
        const timeInRange = (dateTime: number, start: string, end: string) => {
            const time = dayjs(dateTime).format('HH:mm');
            return time > start && time < end;
        };
        getAvailableSlots([
            {
                startDate: dateForServer(
                    dayjs(encounter!.encounterDetailDTO?.dateTime).toDate(),
                    dayjs(encounter!.encounterDetailDTO?.dateTime).date() !== dayjs().date(),
                    true,
                ),
                endDate: dateForServer(dayjs(encounter!.encounterDetailDTO?.dateTime).utc().hour(23).minute(59).second(59).toDate(), false, true),
                serviceType: encounter?.encounterDetailDTO?.serviceType,
                roleCode: encounter?.encounterDetailDTO?.practitionerRoleCode ? [encounter?.encounterDetailDTO?.practitionerRoleCode] : [],
                serviceCategory: encounter?.partOf,
            },
        ]).then((result) => {
            if (result.data) {
                setAvailableSlots(result.data);
                setMorning(result.data.filter((x) => timeInRange(x.dateTime!, '00:00', '12:00')));
                setDay(result.data.filter((x) => timeInRange(x.dateTime!, '12:00', '18:00')));
                setEvening(result.data.filter((x) => timeInRange(x.dateTime!, '18:00', '24:00')));
            }
        });
    }, []);

    const onFinish = (values: FormValues) => {
        if (values.option === UpdateAppointmentDTOStatus.cancelled) {
            onSuccess(values.reason);
        } else {
            if (selectedSlot) {
                onChangeProcedure(selectedSlot, values.reason, '');
            }
        }
    };

    const handleSelectSlot = (slot: AppointmentScheduleDTO) => {
        setSelectedSlot(slot);
    };

    const changeOption = (e: RadioChangeEvent) => {
        setSelectedOption(e.target.value);
    };

    return (
        <Modal
            onCancel={onCancel}
            width={800}
            title={t("cancel_procedure_one_modal.care_plan_changing")}
            open={showModal}
            footer={[
                <div key="empty" />,
                <div key="container">
                    <Button onClick={onCancel} key="cancel">
                        {t("cancel_button")}
                    </Button>

                    <Button onClick={form.submit} type="primary" key="submit">
                        {t("cancel_procedure_one_modal.approve_changing")}
                    </Button>
                </div>,
            ]}
        >
            <div className={styles.wrapper}>
                <div className={styles.modalDescription}>
                    <Row align="top" gutter={8} className={styles.descriptionRow}>
                        <Col span={3}>
                            <p className={styles.descriptionLabel}>{t("cancel_procedure_one_modal.appointment")}</p>{' '}
                        </Col>
                        <Col span={20}>
                            <p className={styles.descriptionText}>
                                «{encounter?.partOf && t(`enums.planDefinition.${encounter?.partOf}`)}»{' '}
                                {encounter?.encounterDetailDTO?.dateTime &&
                                    format(new Date(encounter?.encounterDetailDTO?.dateTime), 'dd.MM.yyyy HH:mm')}
                            </p>
                        </Col>
                    </Row>
                    <Row gutter={8} className={styles.descriptionRow}>
                        <Col span={3}>
                            <p className={styles.descriptionLabel}>{t("cancel_procedure_one_modal.nurse")}</p>{' '}
                        </Col>
                        <Col span={20}>
                            <p className={styles.descriptionText}>{encounter?.encounterDetailDTO?.practitionerName}</p>{' '}
                        </Col>
                    </Row>
                    <Row gutter={8} className={styles.descriptionRow}>
                        <Col span={3}>
                            <p className={styles.descriptionLabel}>{t("patients.title")}</p>{' '}
                        </Col>
                        <Col span={20}>
                            <p className={styles.descriptionText}>{patientFullName}</p>
                        </Col>
                    </Row>
                </div>
                <Form form={form} onFinish={onFinish} initialValues={initialValues}>
                    <p className={styles.label}>{t("cancel_procedure_one_modal.cancel_reason_message")}</p>
                    <Form.Item name="reason">
                        <Select className="w-100">
                            {Object.keys(EncounterChangeReasonEnum).map((x) => (
                                <Option key={x} value={x}>
                                    {t('enums.encounterChangeReason.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="option">
                        <Radio.Group onChange={changeOption}>
                            <div className={styles.topContent}>
                                <div className={styles['option-wrapper']}>
                                    <Radio value={cancelOption.value}>
                                        <div>
                                            <p className={styles['option-title']}>{cancelOption.title}</p>

                                            <p className={styles['option-subtitle']}>{cancelOption.subtitle}</p>
                                        </div>
                                    </Radio>
                                </div>
                            </div>
                            <div className={styles['option-wrapper']}>
                                <Radio disabled={availableSlots.length === 0} value={replaceOption.value}>
                                    <div>
                                        <p className={styles['option-title']}>{replaceOption.title}</p>

                                        <p className={styles['option-subtitle']}>{replaceOption.subtitle}</p>
                                    </div>
                                </Radio>
                                <div className={styles['reason-wrapper']}>
                                    {availableSlots.length > 0 ? (
                                        <Col md={12} sm={24} xs={24}>
                                            {!!morning?.length && (
                                                <div className="mb-4">
                                                    <p className={styles.day}>{t("morning")}</p>
                                                    <div className={styles.slotContainer}>
                                                        {morning.map((x, i) => (
                                                            <div
                                                                onClick={() => {
                                                                    if (selectedOption === UpdateAppointmentDTOStatus.cancelled) {
                                                                        null;
                                                                    } else {
                                                                        handleSelectSlot(x);
                                                                    }
                                                                }}
                                                                key={`${x.slotId} - ${i}`}
                                                                className={classNames(
                                                                    selectedOption === UpdateAppointmentDTOStatus.cancelled && styles.disabled,
                                                                    selectedSlot?.slotId === x.slotId ? styles['selected-time'] : styles.time,
                                                                )}
                                                            >
                                                                {dayjs(x.dateTime).format('HH:mm')}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {!!day?.length && (
                                                <div className="mb-4">
                                                    <p className={styles.day}>{t("afternoon")}</p>
                                                    <div className={styles.slotContainer}>
                                                        {day.map((x, i) => (
                                                            <div
                                                                onClick={() => {
                                                                    if (selectedOption === UpdateAppointmentDTOStatus.cancelled) {
                                                                        null;
                                                                    } else {
                                                                        handleSelectSlot(x);
                                                                    }
                                                                }}
                                                                key={`${x.slotId} - ${i}`}
                                                                className={classNames(
                                                                    selectedOption === UpdateAppointmentDTOStatus.cancelled && styles.disabled,
                                                                    selectedSlot?.slotId === x.slotId ? styles['selected-time'] : styles.time,
                                                                )}
                                                            >
                                                                {dayjs(x.dateTime).format('HH:mm')}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {!!evening?.length && (
                                                <div className="mb-4">
                                                    <p className={styles.day}>{t("evening")}</p>
                                                    <div className={styles.slotContainer}>
                                                        {evening.map((x, i) => (
                                                            <div
                                                                onClick={() => {
                                                                    if (selectedOption === UpdateAppointmentDTOStatus.cancelled) {
                                                                        null;
                                                                    } else {
                                                                        handleSelectSlot(x);
                                                                    }
                                                                }}
                                                                key={`${x.slotId} - ${i}`}
                                                                className={classNames(
                                                                    selectedOption === UpdateAppointmentDTOStatus.cancelled && styles.disabled,
                                                                    selectedSlot?.slotId === x.slotId ? styles['selected-time'] : styles.time,
                                                                )}
                                                            >
                                                                {dayjs(x.dateTime).format('HH:mm')}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    ) : (
                                        <div className={styles.warningSection}>
                                            <p className={styles['warning-title']}>
                                                <AlertIconOutlined className="me-2" /> {t("cancel_procedure_one_modal.no_slots")}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
