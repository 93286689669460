import classNames from 'classnames';

import styles from './Dissociation.module.scss';

interface IDissociationProps {
    colors: { red: number; blue: number; green: number };
    sizes?: { cross: number; square: number; circle: number };
    distanceFromCenter?: number;
}

export const Dissociation = ({
    colors,
    sizes = {
        cross: 100,
        square: 100,
        circle: 100,
    },
    distanceFromCenter = 100,
}: IDissociationProps) => {
    return (
        <div className={styles.wrapper}>
            <div
                className={styles.square}
                style={{
                    backgroundColor: `rgb(${colors.red}, 0, ${colors.blue})`,
                    width: sizes.square,
                    height: sizes.square,
                    marginTop: -distanceFromCenter - sizes.square / 2,
                }}
            ></div>
            <div
                className={classNames(styles.cross, styles.left)}
                style={{
                    marginLeft: -distanceFromCenter - sizes.cross / 2,
                }}
            >
                <div
                    className={styles.crossX}
                    style={{ backgroundColor: `rgb(0, ${colors.green}, ${colors.blue})`, width: sizes.cross, height: sizes.cross / 5 }}
                ></div>
                <div
                    className={styles.crossY}
                    style={{ backgroundColor: `rgb(0, ${colors.green}, ${colors.blue})`, width: sizes.cross / 5, height: sizes.cross }}
                ></div>
            </div>
            <div
                className={classNames(styles.cross, styles.right)}
                style={{
                    marginLeft: distanceFromCenter + sizes.cross / 2,
                }}
            >
                <div
                    className={styles.crossX}
                    style={{ backgroundColor: `rgb(0, ${colors.green}, ${colors.blue})`, width: sizes.cross, height: sizes.cross / 5 }}
                ></div>
                <div
                    className={styles.crossY}
                    style={{ backgroundColor: `rgb(0, ${colors.green}, ${colors.blue})`, width: sizes.cross / 5, height: sizes.cross }}
                ></div>
            </div>
            <div
                className={styles.circle}
                style={{ width: sizes.circle, height: sizes.circle, marginTop: distanceFromCenter + sizes.circle / 2 }}
            ></div>
        </div>
    );
};
