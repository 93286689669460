import { appendGeneralPractitioner } from '@api/mainServiceAPI';
import { GetUsersParams, GetUsersStatus, UserDTO, UserProfileDTOProfileType, getUsers } from '@api/userServiceAPI';
import { SearchIcon } from '@icons';
import { Button, Input, Modal, Table, TablePaginationConfig, TableProps, message } from 'antd';
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ChangePractitionerModal.module.scss';

interface IChangePractitionerModalProps {
    onSuccess: () => void;
    onCancel?: () => void;
    showModal: boolean;
    selectedPractitioner: string;
    patientId: string;
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

export const ChangePractitionerModal = ({ onSuccess, onCancel, showModal, selectedPractitioner, patientId }: IChangePractitionerModalProps) => {
    const { t } = useTranslation();

    const [selectedNewPractitioner, setNewSelectedPractitioner] = useState<UserDTO>();
    const [filteredEmployeeList, setFilteredEmployeeList] = useState<UserDTO[]>([]);
    const [filterQuery, setFilterQuery] = useState<string | undefined>();
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => <p>{t('adminEmployeePage.totalItems') + ' ' + total}</p>,
            position: ['bottomCenter'],
        },
    });

    useEffect(() => {
        retrieveUsersToTable(0, tableParams.pagination?.pageSize || 10);
    }, [filterQuery]);

    const filterByRole = (role: UserProfileDTOProfileType | undefined): boolean => {
        return !!role && (role === UserProfileDTOProfileType.Practitioner || role === UserProfileDTOProfileType.Head_physician);
    };

    const retrieveUsersToTable = (page: number, size: number, sorter?: SorterResult<UserDTO>) => {
        const searchCriteria: GetUsersParams = {
            page,
            size,
            search: filterQuery,
            status: GetUsersStatus.ACTIVE,
            // todo sort after backend
            profileTypes: Object.values(UserProfileDTOProfileType).filter((item) => filterByRole(item)),
        };

        if (sorter) {
            searchCriteria.sortField = (sorter as any).field || 'created';
            searchCriteria.sortDirection = (sorter as any).order === 'ascend' ? 'ASC' : 'DESC';
        }

        getUsers(searchCriteria, {
            paramsSerializer: {
                encode: (params) => {
                    if (params === 'profileTypes[]') {
                        return 'profileTypes';
                    } else {
                        return params;
                    }
                },
            },
        }).then((result) => {
            if (result?.data?.content) {
                const data = result.data.content.map((user) => {
                    user.userProfileDTOList = user.userProfileDTOList?.filter((profile) => filterByRole(profile.profileType));
                    return user;
                });
                setTableParams({
                    pagination: { ...tableParams.pagination, pageSize: size, current: page + 1, total: result.data.totalElements },
                });
                setFilteredEmployeeList(data);
            }
        });
    };

    const handleChangeFilterQuery = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFilterQuery(value);
        retrieveUsersToTable(0, 10);
    };

    const handleTableChange: TableProps<UserDTO>['onChange'] = (pagination, filters, sorter) => {
        retrieveUsersToTable((pagination.current || 1) - 1, pagination?.pageSize || 10, sorter as SorterResult<UserDTO>);
    };

    const employeeTableColumns: ColumnsType<UserDTO> = [
        {
            title: t('adminMainPage.employeeTableColumns.name'),
            dataIndex: ['firstName', 'lastName', 'middleName'],
            render: (text, record) => {
                let result = '';
                if (record.lastName) {
                    result += record.lastName;
                }
                if (record.firstName) {
                    result += ' ' + record.firstName;
                }
                if (record.middleName) {
                    result += ' ' + record.middleName;
                }
                return result || '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.lastName) {
            //         return 1;
            //     }
            //     if (!b.lastName) {
            //         return -1;
            //     }
            //     if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'name',
        },

        {
            title: t('adminMainPage.employeeTableColumns.status'),
            dataIndex: 'status',
            render: (_status, record) => {
                // TODO set correct status on the backend
                const status = record.userProfileDTOList?.some(
                    (x) => x.profileType === UserProfileDTOProfileType.Practitioner || x.profileType === UserProfileDTOProfileType.Head_physician,
                )
                    ? _status || 'DRAFT'
                    : _status || 'ACTIVE';

                return <p className={classNames(styles.status, styles[status])}>{t('adminMainPage.employeeTableColumns.' + status, '-')}</p>;
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.status || a.status == UpdateUserDTOStatus.BLOCKED) {
            //         return 1;
            //     }
            //     if (!b.status || b.status == UpdateUserDTOStatus.ACTIVE) {
            //         return -1;
            //     }
            //     return -1;
            // },
            key: 'status',
        },
    ];

    const changePractitioner = () => {
        const practitionerId = selectedNewPractitioner!.userProfileDTOList!.find(
            (profile) =>
                profile.profileType === UserProfileDTOProfileType.Practitioner || profile.profileType === UserProfileDTOProfileType.Head_physician,
        )?.fhirId;

        appendGeneralPractitioner(patientId, practitionerId!).then(() => {
            message.success(t("change_practitioner.practitioner_replaced"));
            onSuccess();
        });
    };

    return (
        <Modal
            width={800}
            open={showModal}
            title={t("change_practitioner.replace_practitioner")}
            onCancel={onCancel}
            footer={[
                <div className="d-flex flex-column align-items-start" key="practitioner">
                    <p className={styles.label}>{t("change_practitioner.selected")}:</p>
                    <p className={styles.fullName}>
                        {selectedNewPractitioner ? (
                            <>
                                {selectedNewPractitioner?.lastName} {selectedNewPractitioner?.firstName} {selectedNewPractitioner?.middleName}
                            </>
                        ) : (
                            selectedPractitioner
                        )}
                    </p>
                </div>,
                <div key="actions">
                    <Button onClick={onCancel}>{t('Account.labels.cancel')}</Button>
                    <Button disabled={!selectedNewPractitioner} type="primary" onClick={changePractitioner}>
                        {t('Account.labels.save')}
                    </Button>
                </div>,
            ]}
        >
            <Input
                className="w-50"
                placeholder={t('adminMainPage.filter.searchPlaceholder')}
                prefix={<SearchIcon />}
                onChange={handleChangeFilterQuery}
            />
            <div className="mt-3">
                <Table
                    className={styles.employeeRow}
                    rowKey={(record) => record.id!}
                    columns={employeeTableColumns}
                    dataSource={filteredEmployeeList}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    showSorterTooltip={false}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                setNewSelectedPractitioner(record);
                            },
                        };
                    }}
                />
            </div>
        </Modal>
    );
};
