import {
    EncounterDTO,
    EncounterDetailDTO,
    EncounterDetailDTOAppointmentType,
    ObtainAllEncounterEventForManagerAppointmentType,
    ObtainAllEncounterForManagerParams,
    getAllPractitioners,
    obtainAllEncounterForManager,
} from '@api/mainServiceAPI';
import { SearchIcon } from '@icons';
import { END_DAY_TIME } from '@utils/constants';
import { dateForServer } from '@utils/utils';
import { Col, DatePicker, Input, Row, Select, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface';
import { format } from 'date-fns';
import type { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { EncounterStatusBadge } from 'src/components/EncounterStatusBadge/EncounterStatusBadge';
import {
    EncounterStatusFilter,
    eventStatusOptionsList,
    getEncounterStatusesFromAppointmentStatus,
} from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/dictionary';
import styles from './HeadPhysicianAppointmentsPage.module.scss';
const { Option } = Select;

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

export const HeadPhysicianAppointmentsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [filteredEncounterList, setFilteredEncounterList] = useState<EncounterDetailDTO[]>([]);
    const [activeCount, setActiveCount] = useState<number>();

    const [filterQuery, setFilterQuery] = useState<string | undefined>();

    const [filterType, setFilterType] = useState<string>('');
    const [typeOptions, setTypeOptions] = useState<{ label: string; value: string | null }[]>([]);

    const [filterStatus, setFilterStatus] = useState<EncounterStatusFilter>(EncounterStatusFilter.all);

    const [filterDoctor, setFilterDoctor] = useState<string>('');
    const [doctorOptions, setDoctorOptions] = useState<{ label: string; value: string | null }[]>([]);

    const [filterFromDate, setFilterFromDate] = useState<Dayjs | null>();
    const [filterToDate, setFilterToDate] = useState<Dayjs | null>();

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => <p>{t('adminEmployeePage.totalItems') + ' ' + total}</p>,
            position: ['bottomCenter'],
        },
    });

    useEffect(() => {
        setTypeOptions([
            { label: t('callCenterManager.encounterTypes.all'), value: '' },
            ...Object.keys(EncounterDetailDTOAppointmentType)
                .filter((x) => x !== 'middle_consultation')
                .map((type: string) => ({
                    label: t(`callCenterManager.encounterTypes.${type}`),
                    value: type,
                })),
        ]);

        getAllPractitioners({
            page: 0,
            size: 100,
        }).then((result) => {
            const data = result.data.content;
            if (data) {
                setDoctorOptions([
                    { label: t('callCenterManager.allDoctorsOption'), value: '' },
                    ...data
                        .filter((item) => item.fullName)
                        .map((item) => {
                            return {
                                label: item.fullName || '',
                                value: item.id || '',
                            };
                        }),
                ]);
            }
        });
    }, []);

    useEffect(() => {
        retrieveEncountersToTable(0, tableParams.pagination?.pageSize || 10);
    }, [filterQuery, filterType, filterStatus, filterFromDate, filterToDate, filterDoctor]);

    const retrieveEncountersToTable = (page: number, size: number, sorter?: SorterResult<EncounterDetailDTO>) => {
        const { status, paymentStatus } = getEncounterStatusesFromAppointmentStatus(filterStatus);

        const searchCriteria: ObtainAllEncounterForManagerParams = {
            page,
            size,
            searchByName: filterQuery,
            practitionerFhirId: filterDoctor || undefined,
            appointmentType: (filterType as ObtainAllEncounterEventForManagerAppointmentType) || undefined,
            status,
            paymentStatus,
            startDate: filterFromDate?.toDate() ? dateForServer(filterFromDate?.toDate()) : undefined,
            endDate: filterToDate?.toDate() ? dateForServer(filterToDate?.toDate()) + END_DAY_TIME : undefined,
        };

        if (sorter && Object.keys(sorter).length) {
            searchCriteria.sortField = (sorter.field as string) || 'createdDate';
            searchCriteria.sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        }

        obtainAllEncounterForManager(searchCriteria, {
            paramsSerializer: {
                encode: (params) => {
                    if (params === 'profileTypes[]') {
                        return 'profileTypes';
                    } else {
                        return params;
                    }
                },
            },
        }).then((result) => {
            if (result?.data?.content) {
                const data = result.data.content;
                setFilteredEncounterList(data);
                setTableParams({
                    pagination: { ...tableParams.pagination, pageSize: size, current: page + 1, total: result.data?.totalElements },
                });
            }
        });
    };

    const tableColumns: ColumnsType<EncounterDetailDTO> = [
        {
            title: t('callCenterManager.allAppointments.table.dateColumn'),
            dataIndex: 'dateTime',
            render: (text, record) => {
                if (record.dateTime) {
                    return <p className={styles.dateText}>{format(record.dateTime, 'dd.MM.yyyy HH:mm')}</p>;
                }
                return '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.dateTime) {
            //         return 1;
            //     }
            //     if (!b.dateTime) {
            //         return -1;
            //     }
            //     if (a.dateTime > b.dateTime) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'dateTime',
        },
        {
            title: t('callCenterManager.allAppointments.table.appointmentColumn'),
            dataIndex: 'appointmentType',
            render: (text, record) => {
                if (record.appointmentType) {
                    return t(`callCenterManager.encounterTypes.${record.appointmentType}`);
                }
                return '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.appointmentType) {
            //         return 1;
            //     }
            //     if (!b.appointmentType) {
            //         return -1;
            //     }
            //     if (a.appointmentType.toLowerCase() > b.appointmentType.toLowerCase()) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'appointmentType',
        },
        {
            title: t('callCenterManager.allAppointments.table.statusColumn'),
            dataIndex: 'status',
            render: (text, record) => {
                if (record.status) {
                    return <EncounterStatusBadge encounterStatus={record.status} paymentStatus={record.paymentStatus} />;
                }
                return '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.status) {
            //         return 1;
            //     }
            //     if (!b.status) {
            //         return -1;
            //     }
            //     if (a.status.toLowerCase() > b.status.toLowerCase()) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'status',
        },
        {
            title: t('callCenterManager.allAppointments.table.paymentColumn'),
            dataIndex: 'paymentStatus',
            render: (text, record) => {
                return t(`callCenterManager.paymentStatuses.${record.paymentStatus}`, '-');
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.paymentStatus) {
            //         return 1;
            //     }
            //     if (!b.paymentStatus) {
            //         return -1;
            //     }
            //     if (a.paymentStatus.toLowerCase() > b.paymentStatus.toLowerCase()) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'paymentStatus',
        },
        {
            title: t('callCenterManager.allAppointments.table.patientColumn'),
            dataIndex: 'patientName',
            render: (text, record) => {
                if (record.appointmentType === 'course') {
                    return `${t("head_physician_appointment.patients_count")}${record.patientsNumber}`;
                }
                if (record.patientName) {
                    return record.patientName;
                }
                return '-';
            },
            // sorter: (a, b) => {
            //     if (!a.patientName) {
            //         return 1;
            //     }
            //     if (!b.patientName) {
            //         return -1;
            //     }
            //     if (a.patientName.toLowerCase() > b.patientName.toLowerCase()) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'patientName',
        },
        {
            title: t('callCenterManager.allAppointments.table.employeeColumn'),
            dataIndex: 'practitionerName',
            render: (text, record) => {
                if (record.practitionerName) {
                    return record.practitionerName;
                }
                return '-';
            },
            sorter: true,
            // sorter: (a, b) => {
            //     if (!a.practitionerName) {
            //         return 1;
            //     }
            //     if (!b.practitionerName) {
            //         return -1;
            //     }
            //     if (a.practitionerName.toLowerCase() > b.practitionerName.toLowerCase()) {
            //         return 1;
            //     }
            //     return -1;
            // },
            key: 'practitionerName',
        },
    ];

    const onOpenEncounter = (record: EncounterDetailDTO) => {
        if (record.appointmentType === 'course') {
            navigate(`/procedure/${record.appointmentFhirId}`);
        } else {
            navigate(`/appointments/${record.appointmentFhirId}`);
        }
    };

    const handleTableChange: TableProps<EncounterDetailDTO>['onChange'] = (pagination, filters, sorter) => {
        retrieveEncountersToTable((pagination.current || 1) - 1, pagination?.pageSize || 10, sorter as SorterResult<EncounterDetailDTO>);
    };

    return (
        <div className={styles.wrapper}>
            <div className="d-flex justify-content-between">
                <p className={styles.title}>{t('callCenterManager.encounters')}</p>
            </div>
            <div className={styles.filterBlock}>
                <Row gutter={16}>
                    <Col span={5}>
                        <Input
                            placeholder={t('callCenterManager.filters.searchPlaceholder')}
                            prefix={<SearchIcon />}
                            onChange={(e) => setFilterQuery(e.target.value)}
                        />
                    </Col>
                    <Col span={4}>
                        <Select value={filterType} onChange={(value) => setFilterType(value)} options={typeOptions} className="w-100" />
                    </Col>
                    <Col span={4}>
                        <Select value={filterStatus} onChange={(value) => setFilterStatus(value)} className="w-100">
                            {eventStatusOptionsList.map((x) => (
                                <Option key={x} value={x}>
                                    {t('callCenterManager.appointmentStatuses.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={4}>
                        <Select value={filterDoctor} onChange={(value) => setFilterDoctor(value)} options={doctorOptions} className="w-100" />
                    </Col>
                    <Col span={7}>
                        <div className={styles.dateFilters}>
                            <span>{t('callCenterManager.filters.from')}</span>
                            <DatePicker
                                placeholder={t('callCenterManager.filters.date')}
                                style={{ width: '100%' }}
                                onChange={(date) => setFilterFromDate(date)}
                                disabledDate={(e) => (filterToDate ? e >= filterToDate : false)}
                            />

                            <span>{t('callCenterManager.filters.to')}</span>
                            <DatePicker
                                placeholder={t('callCenterManager.filters.date')}
                                style={{ width: '100%' }}
                                onChange={(date) => setFilterToDate(date)}
                                disabledDate={(e) => (filterFromDate ? e <= filterFromDate : false)}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">
                <Table
                    className={styles.employeeRow}
                    rowKey={(record) => record.encounterFhirId!}
                    columns={tableColumns}
                    dataSource={filteredEncounterList}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    showSorterTooltip={false}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                onOpenEncounter(record);
                            },
                        };
                    }}
                />
            </div>
        </div>
    );
};
