import { selectAuthUser } from '@sliceUser';
import { REACT_APP_SUPPORT_PHONE } from '@utils/constants';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './AnonymousLayout.module.scss';

export const AnonymousLayout = () => {
    const { t } = useTranslation();

    const authUser = useSelector(selectAuthUser);
    const navigate = useNavigate();

    useEffect(() => {
        if (authUser) {
            navigate('/');
        }
    }, [authUser]);

    return (
        <section className={styles.wrapper}>
            <Outlet />

            <span className={styles.supportBlock}>
                {t('anonymousLayout.needHelp')} <strong>{REACT_APP_SUPPORT_PHONE}</strong>
            </span>
        </section>
    );
};
