import { Button, Col, Form, Row } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminSettingsState } from "src/enums/admin-settings-state.enum";
import { EditOutlined } from '@ant-design/icons';
import styles from './DocumentsStep.module.scss';
import { UploadFile } from "@components";
import { FileSafeDTOBucketType, UserDirectDocumentDTO, UserDirectDocumentDTOType, getContent } from "@api/documentServiceAPI";
import { selectDocuments, setSaveDcouments } from "src/store/organization";
import { FileToSignIcon } from "@icons";
import { ORGANIZATION_ID } from "@utils/constants";
import { ReadonlyWarning } from "../ReadonlyWarning/ReadonlyWarning";
import { ISettingsStep } from "../../ClinicAdminSettingsPage";
import {useTranslation} from "react-i18next";

interface IDocumentItem {
    field: string,
    type: UserDirectDocumentDTOType,
    title: string,
}

const DOCUMENT_OPTIONS: IDocumentItem[] = [
    {
        field: 'contractFile',
        type: UserDirectDocumentDTOType.MEDICAL_SERVICE_CONTRACT,
        title: 'clinic_admin_settings_page.documents_step.medical_services_contract',
    },
    {
        field: 'consentFile',
        type: UserDirectDocumentDTOType.MEDICAL_SERVICE_CONSENT,
        title: 'clinic_admin_settings_page.documents_step.medical_services_consent',
    },
    {
        field: 'pesonalDataFile',
        type: UserDirectDocumentDTOType.PERSONAL_DATA_CONSENT,
        title: 'clinic_admin_settings_page.documents_step.personal_data_processing_consent',
    },
    {
        field: 'userAgreementFile',
        type: UserDirectDocumentDTOType.USER_AGREEMENT_PATIENTS,
        title: 'clinic_admin_settings_page.documents_step.user_agreement_with_patients',
    },
    {
        field: 'privacyPolicyFile',
        type: UserDirectDocumentDTOType.PRIVACY_POLICY,
        title: 'clinic_admin_settings_page.documents_step.privacy_policy',
    }
]

export const DocumentsStep = ({ isReadonly }: ISettingsStep) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [currentDisplayState, setCurrentDisplayState] = useState<AdminSettingsState>(AdminSettingsState.EMPTY);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const documents = useSelector(selectDocuments);

    useEffect(() => {
        if(documents.length || isReadonly) {
            setCurrentDisplayState(AdminSettingsState.FILL);
        }
    }, [documents, isReadonly])

    const onSubmit = (val: any) => {
        dispatch(setSaveDcouments(Object.values(val)));
    }

    const onEdit = () => {
        setCurrentDisplayState(AdminSettingsState.EDIT);
    }

    const onCancel = () => {
        setCurrentDisplayState(AdminSettingsState.FILL);
    }

    const handleFieldsChange = () => {
        const fieldsError = form.getFieldsError();
        const hasErrors = fieldsError.some(field => field.errors.length > 0);
        const allFieldsFilled = form.getFieldsValue();
        const isEmptyField = Object.values(allFieldsFilled).some(value => !value);

        setIsFormValid(!hasErrors && !isEmptyField);
    };

    const changeFileEvent = (fld: string, files: string[]) => {
        form.setFieldValue(fld, files[0]);
        form.validateFields([fld]);
    };

    const getFileList = (docType: UserDirectDocumentDTOType) => {
        const foundDocument = documents.find((doc) => doc.type === docType);
        if(foundDocument) {
            const fileName = `${foundDocument.name}.${foundDocument.extension}`;
            const file = new File(["content"], fileName, {
                type: "application/pdf",
              });
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            return dataTransfer.files;
        }
    };

    const initialValues = documents.reduce((acc, item) => {
        const foundDocOption = DOCUMENT_OPTIONS.find((docOption) => docOption.type === item.type);
        if(foundDocOption && foundDocOption.field) {
            acc[foundDocOption.field] = item.documentFhirId ?? '';
        }
        return acc;
      }, {} as Record<string, string>);

    const downloadFile = (doc: UserDirectDocumentDTO) => {
        if(doc.s3key) {
            getContent(FileSafeDTOBucketType.OPEN_DOCUMENT, doc.s3key).then((res) => {
                const base64Url = window.URL.createObjectURL(res.data);
                const link = document.createElement('a');
                const blobURL = base64Url;
                link.href = blobURL;
                const fileName = `${doc.name}.${doc.extension}`;
                link.download = fileName;
                link.click();
            });
        }
    }

    return (
        <div className={classNames(styles.wrapper)}>
            <h2 className="d-flex gap-3">
                {t("clinic_admin_settings_page.documents_step.documents")}
                {
                    currentDisplayState === AdminSettingsState.FILL && !isReadonly ? 
                    <EditOutlined onClick={onEdit} /> : 
                    null
                }
            </h2>
            <div className={styles.content}>
                {
                    currentDisplayState === AdminSettingsState.FILL ?
                        <div className={styles.uploadedDocuments}>
                            {
                                documents.map((doc) =>         
                                    <Row align="middle" gutter={24} className={styles.document} key={doc.documentFhirId}>
                                        <Col span={2}>
                                            <FileToSignIcon />
                                        </Col>
                                        <Col span={19}>{t(DOCUMENT_OPTIONS.find((d) => d.type === doc.type)?.title as string)}</Col>
                                        <Col className="d-flex justify-content-end" span={3}>
                                            <a className={styles.link} target="_blank" onClick={() => downloadFile(doc)}>
                                                {t("clinic_admin_settings_page.documents_step.download")}
                                            </a>
                                        </Col>
                                    </Row>
                                )
                            }
                        </div>
                    :
                    <Form
                        form={form}
                        className={styles.documentsForm}
                        layout="vertical"
                        onFinish={(value) => onSubmit(value)}
                        onFieldsChange={handleFieldsChange}
                        initialValues={initialValues}
                    >
                            {
                                DOCUMENT_OPTIONS.map((fileOption) => 
                                    <Form.Item
                                        key={fileOption.type}
                                        className="mb-2"
                                        name={fileOption.field}
                                        rules={[{ required: true, message: t("required_field") }]}
                                    >
                                        <UploadFile
                                            docType={fileOption.type}
                                            title={t(fileOption.title)}
                                            maxSizeMb={10} 
                                            subtitle={t("clinic_admin_settings_page.documents_step.1_pdf_document")}
                                            changeEvent={(files) => changeFileEvent(fileOption.field, files as string[])}
                                            fhirId={ORGANIZATION_ID}
                                            extensions='application/pdf'
                                            fileList={getFileList(fileOption.type)}

                                        />
                                    </Form.Item>
                                )
                            }
                            <div className={classNames(styles.actions, 'pt-4')}>
                                <Row justify="space-between" align="middle">
                                    <Col className='pt-3 pb-3 pr-3'>
                                        <span>{t("clinic_admin_settings_page.documents_step.all_5_documents_required")}</span>
                                    </Col>
                                    <Col className="d-flex gap-3">
                                        {currentDisplayState === AdminSettingsState.EDIT ? 
                                            <Button type="primary" onClick={onCancel}>
                                                {t("cancel_button")}
                                            </Button> 
                                        : null}
                                        <Button type="primary" htmlType="submit" disabled={!isFormValid}>
                                            {t("save")}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                    </Form>  
                }
                <div className={styles.readonlyWarningMsg} >
                    { 
                        isReadonly ? 
                        <ReadonlyWarning text={t("clinic_admin_settings_page.documents_step.changes_after_agreement")} /> :
                        null
                    }
                </div>
            </div>
        </div>
    );
};