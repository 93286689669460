import { ChatDTO } from '@api/mainServiceAPI';
import { IChatMessageDto } from '@models';
import { getUnhandledMsgs, UserRole } from '@sliceUser';
import { StompSubscription } from '@stomp/stompjs';
import { WsTopic } from '@utils/websocket.topics';
import { Button } from 'antd';
import classNames from 'classnames';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { WebsocketContext } from 'src/contexts/ws.context';
import { IChat } from 'src/models/chat.model';
import { Chat } from '../Chat/Chat';
import styles from './Chats.module.scss';

interface IChatsProps {
    currentUserId?: string;
    currentUserRole?: (typeof UserRole)[keyof typeof UserRole];
    chats?: ChatDTO[];
    haveAccessForEdit?: boolean;
    headerInfo?: string;
    isProcedure?: boolean;
    noDoctorPatientMessagesLabel?: string;
}

const UPDATE_UNREAD_MSG_DEBOUNCE_TIME = 2000;

export const Chats = ({
    currentUserId,
    currentUserRole,
    chats,
    haveAccessForEdit = true,
    headerInfo,
    isProcedure = false,
    noDoctorPatientMessagesLabel,
}: IChatsProps) => {
    const dispatch = useDispatch();
    const debounceTimer = useRef<number | undefined>();
    const { t } = useTranslation();
    const { wsClient, send } = useContext(WebsocketContext);

    const [chatsList, setChatsList] = useState<{ id?: string; label?: string }[]>([]);

    const [patientChat, setPatientChat] = useState<IChat | null>();
    const [doctorChat, setDoctorChat] = useState<IChat | null>();
    const [supportChat, setSupportChat] = useState<IChat | null>();
    const [patientPractitionerChat, setPatientPractitionerChat] = useState<IChat | null>();
    const [practitionerManagerChat, setPractitionerManagerChat] = useState<IChat | null>();
    const [patientManagerChat, setPatientManagerChat] = useState<IChat | null>();
    const [currentChatId, setCurrentChatId] = useState<string>();
    const [isMyChats, setIsMyChats] = useState(false);
    const [chatDoctorPatientId, setChatDoctorPatientId] = useState<string | null>();
    const [latestReadMessageId, setLatestReadMessageId] = useState<string | null>(null);

    useEffect(() => {
        if (chats) {
            // todo ! It should be working well from the server
            const myChats = chats?.filter((x) =>
                x.chat?.participants?.some((y) => {
                    if (currentUserRole === 'Head_physician') {
                        return y.id === currentUserId;
                    } else {
                        return y.profile === currentUserRole;
                    }
                }),
            );
            setIsMyChats(myChats.length > 0 || isProcedure);
            const chatWithPatient = myChats?.find((x) => x.chat?.participants?.some((y) => y.profile === 'Patient'));
            const chatWithDoctor = myChats?.find((x) => x.chat?.participants?.some((y) => y.profile === 'Practitioner' || y.profile === 'Nurse'));
            const chatWithSupport = myChats?.find((x) => x.chat?.participants?.some((y) => y.profile === 'Call_center_manager'));

            switch (currentUserRole) {
                case UserRole.Patient:
                    setSupportChat(convertApiChatToLocal(chatWithSupport));
                    setDoctorChat(convertApiChatToLocal(chatWithDoctor));

                    setChatsList([
                        { id: chatWithSupport?.chat?.id, label: t('appointment.chatWithSupport') },
                        { id: chatWithDoctor?.chat?.id, label: t('appointment.chatWithDoctor') },
                    ]);

                    setChatDoctorPatientId(chatWithDoctor?.chat?.id);
                    break;
                case UserRole.Practitioner:
                    setSupportChat(convertApiChatToLocal(chatWithSupport));
                    setPatientChat(convertApiChatToLocal(chatWithPatient));
                    setChatsList([
                        { id: chatWithSupport?.chat?.id, label: t('appointment.chatWithSupport') },
                        { id: chatWithPatient?.chat?.id, label: t('appointment.chatWithPatient') },
                    ]);
                    setChatDoctorPatientId(chatWithPatient?.chat?.id);
                    break;
                case UserRole.Nurse:
                    {
                        const chatsList = [];

                        if (chatWithSupport) {
                            setSupportChat(convertApiChatToLocal(chatWithSupport));
                            chatsList.push({ id: chatWithSupport?.chat?.id, label: t('appointment.chatWithSupport') });
                        }

                        if (chatWithPatient) {
                            setPatientChat(convertApiChatToLocal(chatWithPatient));
                            chatsList.push({ id: chatWithPatient?.chat?.id, label: t('appointment.chatWithPatient') });
                            setChatDoctorPatientId(chatWithPatient?.chat?.id);
                        }

                        setChatsList(chatsList);
                    }
                    break;
                case UserRole.Call_center_manager:
                    {
                        setPatientChat(convertApiChatToLocal(chatWithPatient));
                        setDoctorChat(convertApiChatToLocal(chatWithDoctor));
                        const resultChats = [];
                        if (chatWithPatient) {
                            resultChats.push({ id: chatWithPatient?.chat?.id, label: t('appointment.chatWithPatient') });
                        }
                        if (chatWithDoctor) {
                            resultChats.push({
                                id: chatWithDoctor?.chat?.id,
                                label: isProcedure ? t('appointment.chatWithNurse') : t('appointment.chatWithDoctor'),
                            });
                        }

                        setChatsList(resultChats);
                    }
                    break;
                case UserRole.Head_physician:
                    {
                        const chatPractitionerAndPatient = chats?.find(
                            (x) =>
                                x.chat?.participants?.find((y) => y.profile === 'Patient') &&
                                x.chat?.participants?.find((y) => y.profile === 'Practitioner' || y.profile === 'Nurse'),
                        );
                        const chatPractitionerAndManager = chats?.find(
                            (x) =>
                                x.chat?.participants?.find((y) => y.profile === 'Call_center_manager') &&
                                x.chat?.participants?.find((y) => y.profile === 'Practitioner' || y.profile === 'Nurse'),
                        );
                        const chatPatientAndManager = chats?.find(
                            (x) =>
                                x.chat?.participants?.find((y) => y.profile === 'Call_center_manager') &&
                                x.chat?.participants?.find((y) => y.profile === 'Patient'),
                        );

                        if (myChats.length > 0) {
                            setSupportChat(convertApiChatToLocal(chatWithSupport));
                            setPatientChat(convertApiChatToLocal(chatWithPatient));

                            setChatsList([
                                { id: chatWithSupport?.chat?.id, label: t('appointment.chatWithSupport') },
                                { id: chatWithPatient?.chat?.id, label: t('appointment.chatWithPatient') },
                            ]);
                            setChatDoctorPatientId(chatWithPatient?.chat?.id);
                        } else {
                            setPatientPractitionerChat(convertApiChatToLocal(chatPractitionerAndPatient));
                            setPractitionerManagerChat(convertApiChatToLocal(chatPractitionerAndManager));
                            setPatientManagerChat(convertApiChatToLocal(chatPatientAndManager));
                            const resultChats = [];
                            if (chatPractitionerAndPatient) {
                                resultChats.push({ id: chatPractitionerAndPatient?.chat?.id, label: t('appointment.chatBetweenPatientDoctor') });
                            }
                            if (chatPatientAndManager) {
                                resultChats.push({ id: chatPatientAndManager?.chat?.id, label: t('appointment.chatBetweenPatientSupport') });
                            }
                            if (chatPractitionerAndManager) {
                                resultChats.push({
                                    id: chatPractitionerAndManager?.chat?.id,
                                    label: isProcedure ? t('appointment.chatBetweenNurseSupport') : t('appointment.chatBetweenDoctorSupport'),
                                });
                            }

                            setChatsList(resultChats);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }, [chats, currentUserRole]);

    useEffect(() => {
        if (patientChat && wsClient) {
            const subscription: StompSubscription = wsClient.subscribe(WsTopic.chatTopic + patientChat.id, (message: any) => {
                if (!!message.body) {
                    messageHandler(message.body, setPatientChat);
                }
            });

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [patientChat, wsClient]);

    useEffect(() => {
        if (doctorChat && wsClient) {
            const subscription: StompSubscription = wsClient.subscribe(WsTopic.chatTopic + doctorChat.id, (message: any) => {
                if (!!message.body) {
                    messageHandler(message.body, setDoctorChat);
                }
            });

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [doctorChat, wsClient]);

    useEffect(() => {
        if (supportChat && wsClient) {
            const subscription: StompSubscription = wsClient.subscribe(WsTopic.chatTopic + supportChat.id, (message: any) => {
                if (!!message.body) {
                    messageHandler(message.body, setSupportChat);
                }
            });

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [supportChat, wsClient]);

    useEffect(() => {
        if (patientManagerChat && wsClient) {
            const subscription: StompSubscription = wsClient.subscribe(WsTopic.chatTopic + patientManagerChat.id, (message: any) => {
                if (!!message.body) {
                    messageHandler(message.body, setPatientManagerChat);
                }
            });

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [patientManagerChat, wsClient]);

    useEffect(() => {
        if (patientPractitionerChat && wsClient) {
            const subscription: StompSubscription = wsClient.subscribe(WsTopic.chatTopic + patientPractitionerChat.id, (message: any) => {
                if (!!message.body) {
                    messageHandler(message.body, setPatientPractitionerChat);
                }
            });

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [patientPractitionerChat, wsClient]);

    useEffect(() => {
        if (practitionerManagerChat && wsClient) {
            const subscription: StompSubscription = wsClient.subscribe(WsTopic.chatTopic + practitionerManagerChat.id, (message: any) => {
                if (!!message.body) {
                    messageHandler(message.body, setPractitionerManagerChat);
                }
            });

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [practitionerManagerChat, wsClient]);

    useEffect(() => {
        clearTimeout(debounceTimer.current);
        debounceTimer.current = window.setTimeout(() => {
            dispatch(getUnhandledMsgs());
        }, UPDATE_UNREAD_MSG_DEBOUNCE_TIME);
        return () => {
            clearTimeout(debounceTimer.current);
        };
    }, [latestReadMessageId]);

    const messageHandler = (messageBody: string, setChat: Dispatch<SetStateAction<IChat | null | undefined>>) => {
        const message: IChatMessageDto = JSON.parse(messageBody);
        console.log('messageHandler', message);

        if (message.type === 'NEW_MSG') {
            setChat((prev) => ({
                ...prev,
                messages: [
                    ...(prev?.messages || []),
                    {
                        id: message.messageId,
                        text: message.payload,
                        createdDate: new Date(message.dateTime!),
                        senderId: message.sender?.id,
                        sent: message.read,
                        viewed: message.read,
                    },
                ],
            }));
        }

        if (message.type === 'MSG_SET_READ') {
            setChat((prev) => {
                const newArray = [...(prev?.messages || [])];
                const targetMsg = newArray.find((x) => x.id === message.messageId);
                if (targetMsg) {
                    targetMsg.sent = message.read;
                    targetMsg.viewed = message.read;
                }

                return { ...prev, messages: newArray };
            });
        }
    };

    const markAsRead = (messageId: string) => {
        // it's a dirty hack, due to useRef/useState/IntersectionObserver - not working together properly, and the state here - is undefined
        setCurrentChatId((prev) => {
            send('/msg/chat/message', '/chat/' + prev, {
                type: 'MSG_SET_READ',
                conversationId: prev,
                messageId,
                sender: currentUserId as any,
                read: true,
            });

            return prev;
        });
        setLatestReadMessageId(messageId);
    };

    const convertApiChatToLocal = (chatDto?: ChatDTO): IChat | null => {
        if (chatDto) {
            return {
                id: chatDto.chat?.id,
                messages: chatDto?.messages?.map((x) => ({
                    id: x.id,
                    text: x.text,
                    createdDate: new Date(x.dateTime!),
                    senderId: x.sender?.id,
                    sent: x.read, // todo for now - we don't distinguish these options
                    viewed: x.read, // todo for now - we don't distinguish these options
                    sender: x.sender,
                })),
            };
        } else {
            return null;
        }
    };

    const onSendMessage = (textMessage: string) => {
        if (currentChatId) {
            const messageDto: IChatMessageDto = {
                type: 'NEW_MSG',
                conversationId: currentChatId,
                payload: textMessage,
                sender: currentUserId as any,
            };

            send('/msg/chat/message', '/chat/' + currentChatId, messageDto);
        }
    };

    const getChatPlaceholder = useCallback(() => {
        switch (true) {
            case currentChatId === doctorChat?.id:
                return t("chat.message_practitioner");
            case currentChatId === patientChat?.id:
                return t("chat.message_patient");
            case currentChatId === supportChat?.id:
                return t("chat.write_to_support");
            default:
                return '';
        }
    }, [currentChatId, doctorChat, patientChat, supportChat]);

    const getNoMessagesLabel = useCallback(() => {
        if (!currentChatId) {
            return t("chat.select_chat");
        } else {
            return currentChatId === supportChat?.id
                ? t("chat.write_to_support")
                : currentChatId === chatDoctorPatientId
                ? noDoctorPatientMessagesLabel
                : t("chat.no_messages");
        }
    }, [currentChatId, chatDoctorPatientId, supportChat]);

    const getChatBottomTooltip = useCallback(() => {
        if (currentChatId === chatDoctorPatientId) {
            return currentUserRole === 'Practitioner'
                ? t("chat.chat_patient_not_allowed")
                : t("chat.chat_practitioner_not_allowed");
        }

        if (
            currentChatId === supportChat?.id &&
            currentUserRole === 'Patient' &&
            supportChat?.messages?.[supportChat?.messages?.length - 1]?.senderId === currentUserId
        ) {
            return t("chat.please_wait_answer");
        }

        return;
    }, [currentChatId, chatDoctorPatientId, supportChat, currentUserRole, currentUserId]);

    const getChatMessages = useCallback(() => {
        return currentChatId === doctorChat?.id
            ? doctorChat?.messages
            : currentChatId === patientChat?.id
            ? patientChat?.messages
            : currentChatId === supportChat?.id
            ? supportChat?.messages
            : currentChatId === patientManagerChat?.id
            ? patientManagerChat?.messages
            : currentChatId === patientPractitionerChat?.id
            ? patientPractitionerChat?.messages
            : currentChatId === practitionerManagerChat?.id
            ? practitionerManagerChat?.messages
            : [];
    }, [currentChatId, doctorChat, patientChat, supportChat, patientManagerChat, patientPractitionerChat, practitionerManagerChat]);

    const getChatNewMessagesAlert = (chatId: string) => {
        return chatId === doctorChat?.id
            ? doctorChat?.messages?.filter((x) => !x.viewed).some((x) => x.senderId !== currentUserId)
            : chatId === patientChat?.id
            ? patientChat?.messages?.filter((x) => !x.viewed).some((x) => x.senderId !== currentUserId)
            : chatId === supportChat?.id
            ? supportChat?.messages?.filter((x) => !x.viewed).some((x) => x.senderId !== currentUserId)
            : chatId === patientManagerChat?.id
            ? patientManagerChat?.messages?.filter((x) => !x.viewed).some((x) => x.senderId !== currentUserId)
            : chatId === practitionerManagerChat?.id
            ? practitionerManagerChat?.messages?.filter((x) => !x.viewed).some((x) => x.senderId !== currentUserId)
            : chatId === patientPractitionerChat?.id
            ? patientPractitionerChat?.messages?.filter((x) => !x.viewed).some((x) => x.senderId !== currentUserId)
            : [];
    };

    return (
        <div className={styles.chatBlock}>
            {headerInfo && <div className={styles.headerInfo}>{headerInfo}</div>}

            <div className={styles.chatHeader}>
                {chatsList.map((x, i) => {
                    return (
                        <Button
                            key={i}
                            className={classNames(!isMyChats ? styles.small : styles.chatHeaderButton, currentChatId === x.id && styles.active)}
                            onClick={() => setCurrentChatId(x.id)}
                        >
                            {x.label}
                            {getChatNewMessagesAlert(x.id!) && <span className={styles.alertCircle}></span>}
                        </Button>
                    );
                })}
            </div>

            <div className={styles.chatWrapper}>
                <Chat
                    chatId={currentChatId}
                    haveAccess={!!currentChatId && haveAccessForEdit && chatDoctorPatientId !== currentChatId}
                    noMessagesLabel={getNoMessagesLabel()}
                    placeholder={getChatPlaceholder()}
                    currentUserId={currentUserId}
                    sendMessage={onSendMessage}
                    messages={getChatMessages()}
                    markAsRead={markAsRead}
                    isHeadPhysician={currentUserRole === 'Head_physician'}
                    bottomTooltip={getChatBottomTooltip()}
                />
            </div>
        </div>
    );
};
