import { IProcedureBase, ProcedureBase } from './procedureBase.abstract';

export interface IFlashesConfig {
    animationInterval: number;
    duration?: number; // in seconds
    color?: string;
}

export class FlashesProcedure extends ProcedureBase implements IProcedureBase {
    // Procedure's global configuration
    private _flashColor1 = 'white';
    private _flashColor2 = 'black';

    private _config: IFlashesConfig;

    private _currentColor: string;

    constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, config: IFlashesConfig) {
        super(canvas, ctx, config);

        this._config = config;
        this._fps = 4; // max frequency - 4Hz

        if (config.color) {
            this._flashColor1 = config.color;
        }

        this._currentColor = this._flashColor1;
    }

    public run(): void {
        // animation
        this.animationInterval = setInterval(() => {
            this._currentColor = this._currentColor === this._flashColor1 ? this._flashColor2 : this._flashColor1;
        }, this._config.animationInterval);

        // draw canvas (animation)
        this._drawProcedure();
    }

    private _drawProcedure(): void {
        if (!this.paused) {
            this._ctx.fillStyle = this._currentColor;
            this._ctx.fillRect(0, 0, this._canvas.width, this._canvas.height);
        }

        if (this._animationNeeded) {
            // reduce FPS
            this.animationTimeout = setTimeout(() => {
                requestAnimationFrame(() => this._drawProcedure());
            }, 1000 / this._fps);
        }
    }
}
