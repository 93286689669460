import {GadgetDeliveryAddressDTO} from "@api/mainServiceAPI";
import {TFunction} from "i18next";

export const createDeliveryAddressString = (t: TFunction<"translation", undefined, "translation">, deliveryAddressDTO: GadgetDeliveryAddressDTO) => {
    return deliveryAddressDTO.postalCode + ', ' +
        deliveryAddressDTO.city + ', ' +
        deliveryAddressDTO.region + ', ' +
        t('userMainPage.courseRecommended.delivery_address_prefix_street') + ' ' + deliveryAddressDTO.street + ', ' +
        t('userMainPage.courseRecommended.delivery_address_prefix_house') + ' ' + deliveryAddressDTO.houseNumber + (deliveryAddressDTO.building || deliveryAddressDTO.block || deliveryAddressDTO.apartmentNumber ? ', ' : '') +
        (deliveryAddressDTO.building ? t('userMainPage.courseRecommended.delivery_address_prefix_building') + ' ' + deliveryAddressDTO.building + (deliveryAddressDTO.block || deliveryAddressDTO.apartmentNumber ? ', ' : '') : '') +
        (deliveryAddressDTO.block ? t('userMainPage.courseRecommended.delivery_address_prefix_block') + ' ' + deliveryAddressDTO.block + (deliveryAddressDTO.apartmentNumber ? ', ' : '') : '') +
        (deliveryAddressDTO.apartmentNumber ? t('userMainPage.courseRecommended.delivery_address_prefix_apartment') + ' ' + deliveryAddressDTO.apartmentNumber : ' ') +
        (deliveryAddressDTO.addressComment ? ' (' + deliveryAddressDTO.addressComment + '), ' : ', ') +
        deliveryAddressDTO.consumerFullName + ', ' +
        deliveryAddressDTO.consumerPhone;
}
