import { FileSafeDTOBucketType, uploadFile, UserDocumentDTOType } from '@api/documentServiceAPI';
import {
    AppointmentDTOAppointmentType,
    AppointmentScheduleDTO,
    EncounterDetailDTOPaymentStatus,
    EncounterDetailDTOStatus,
    EncounterDTODetails,
    EncounterUpdateDTOReasonsItem,
    obtainEncounterDetails,
    updateEncounter,
    updatePatientInSlot,
    updateReasonEncounter,
    updateStatusEncounter,
    UserDocumentDTO,
} from '@api/mainServiceAPI';
import {getCashReceipt, getCashReceipt1, getPaymentInfo, PaymentInfoResponseDTO} from '@api/paymentServiceAPI';
import { CountdownTimer } from '@components';
import { AppointmentStatus } from '@enums';
import {
    AlertIconOutlined,
    CalendarIcon,
    CheckCircle48,
    ChevronLeft,
    CrossIcon,
    DiagnosisIcon,
    EditIcon,
    EyeIcon,
    FileAddedIcon,
    MoreIcon,
    PdfFile, ProcedureFailedIcon, ProcedureTimerIcon1,
    TriangleRight,
    UserIcon,
} from '@icons';
import { FilesUploadModal } from '@modals';
import { setFileViewer } from '@sliceCore';
import { getPatientActiveConsultations, getPatientEncountersById, selectCarePlan, selectPatientWorkspacePrepared } from '@slicePatient';
import { getDocInfoModel, selectCurrentProfile, UserRole } from '@sliceUser';
import { Logger } from '@utils/logger';
import { capitalizeText, separateFileNameAndExtension } from '@utils/utils';
import { Button, Checkbox, Col, Dropdown, Form, Input, MenuProps, message, Modal, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { MED_TIME_BEFORE_START_OF_SESSION } from 'src/ADMINISTRATIVE_CONFIG';
import { Chats } from 'src/components/Chats/Chats';
import { EncounterStatusBadge } from 'src/components/EncounterStatusBadge/EncounterStatusBadge';
import { EncounterChangeReasonEnum } from 'src/enums/encounter-change-reason.enum';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import { AppointmentChangeConsultationModal } from 'src/modals/AppointmentChangeConsultationModal/AppointmentChangeConsultationModal';
import { CheckNotExistModal } from 'src/modals/CheckNotExistModal/CheckNotExistModal';
import { UploadFileExt } from 'src/modals/FilesUploadModal/FilesUploadModal';
import { getEncounterStatus } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/dictionary';
import styles from './PatientAppointmentItemPage.module.scss';
import {getPaymentServiceActive, selectPaymentServiceActive} from "../../../store/organization";

interface IChangeReasonForm {
    comment: string;
    reasons: EncounterUpdateDTOReasonsItem[];
}

export const PatientAppointmentItemPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const patientWorkspacePrepared = useSelector(selectPatientWorkspacePrepared);
    const paymentServiceActive = useSelector(selectPaymentServiceActive);

    const currentProfile = useSelector(selectCurrentProfile);
    const carePlan = useSelector(selectCarePlan);

    const [encounter, setEncounter] = useState<EncounterDTODetails>();
    const [showChangeDateModal, setShowChangeDateModal] = useState(false);
    const [showChangeCourseDateModal, setShowChangeCourseDateModal] = useState(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [showEditReason, setShowEditReason] = useState(false);
    const [showCheckNotExistModal, setShowCheckNotExistModal] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfoResponseDTO>();

    const [changeReasonForm] = Form.useForm();
    const { validateForm } = useFormValidation(changeReasonForm);

    useEffect(() => {
        retrieveEncounterDetails();
    }, []);

    const retrieveEncounterDetails = () => {
        if (params.appointmentId) {
            obtainEncounterDetails(params.appointmentId).then((result) => {
                if (result.data) {
                    setEncounter(result.data);
                    result.data.encounterDetailDTO?.paymentStatus === 'completed' && retrievePaymentInfo(result.data.encounterDetailDTO.encounterFhirId!);
                }
            });
        }
    };

    const onBackToAllAppointments = () => {
        navigate('/appointments');
    };

    // todo payment logic after backend
    const items: MenuProps['items'] = [
        {
            label: <a onClick={() => console.log('Copy payment link')}>{t('appointment.paymentOptions.copyLink')}</a>,
            key: '0',
        },
        {
            label: <a onClick={() => console.log('Send payment on email')}>{t('appointment.paymentOptions.sendEmail')}</a>,
            key: '1',
        },
    ];

    // todo after payment
    const onPaymentDetails = () => {
        navigate('/');
    };

    const onDoctorDetails = (docId: string) => {
        dispatch(getDocInfoModel(docId));
    };

    // todo open course for patient
    const onCourseDetails = () => {
    };

    //TODO remove redundant field
    const openDocument = (doc: UserDocumentDTO & { s3Key?: string }) => {
        const key = doc.s3Key || doc.s3key;

        if (key) {
            dispatch(
                setFileViewer({
                    s3Key: key,
                    bucketType: doc.type === FileSafeDTOBucketType.VIDEO ? FileSafeDTOBucketType.VIDEO : FileSafeDTOBucketType.DOCUMENT,
                    fileName: doc.extension ? `${doc.name}.${doc.extension}` : (doc.name as string),
                    extension: doc.extension,
                    forceDownload: true,
                }),
            );
        }
    };

    // todo upload documents modal?
    const onUploadDocuments = () => {
        setShowFileUploadModal(true);
    };

    const onChangeConsultation = (slot: AppointmentScheduleDTO, reasonType: EncounterChangeReasonEnum, reasonDescription: string) => {
        updatePatientInSlot({
            patientId: encounter?.encounterDetailDTO?.patientFhirId,
            slots: [
                {
                    slotId: encounter?.encounterDetailDTO?.slotFhirId,
                    newSlotId: slot.slotId,
                    serviceType: encounter?.encounterDetailDTO?.serviceType,
                    appointmentType: encounter?.encounterDetailDTO?.appointmentType,
                },
            ],
        }).then(() => {
            updateReasonEncounter({
                encounterFhirId: encounter?.encounterDetailDTO?.encounterFhirId,
                reasonType: reasonType,
                description: reasonDescription,
            }).then(() => {
                navigate('/appointments');
                message.success(t("patientAppointmentItemPage.appointment_rescheduled_successfully"));

                retrievePatientData();
            });
        });
    };

    const onCancelConsultation = (reason: EncounterChangeReasonEnum) => {
        if (encounter?.encounterDetailDTO?.encounterFhirId) {
            updateStatusEncounter(encounter?.encounterDetailDTO?.encounterFhirId, { aidEncounterStatus: 'cancelled' }).then((result) => {
                if (result) {
                    updateReasonEncounter({
                        encounterFhirId: encounter?.encounterDetailDTO?.encounterFhirId,
                        reasonType: reason,
                        description: '',
                    }).then((res) => {
                        navigate('/appointments');
                        message.success(t("patientAppointmentItemPage.appointment_cancelled_successfully"));

                        retrievePatientData();
                    });
                }
            });
        }
    };

    const uploadDocuments = (files: UploadFileExt[]) => {
        if (files.length) {
            files.forEach((f, i) => {
                if (f.done || f.loading) {
                    return;
                }
                f.loading = true;

                uploadFile(
                    {
                        fhirId: encounter?.encounterDetailDTO?.patientFhirId,
                        type: f.docType,
                        file: f as RcFile,
                        typeDescription: f.otherType,
                        ...separateFileNameAndExtension(f.name),
                    },
                    { headers: { 'Content-Type': 'multipart/form-data' } },
                )
                    .then((res: any) => {
                        f.done = true;
                        f.loading = false;
                        f.uuid = res.data.uuid;

                        updateEncounter(encounter!.encounterDetailDTO!.encounterFhirId!, {
                            attachDocumentsUuid: [res.data.uuid],
                        }).then(() => {
                            retrieveEncounterDetails();
                            message.success(t("patientAppointmentItemPage.file_uploaded_successfully"));
                        });
                    })
                    .catch((err) => {
                        Logger.error(err);
                    });
            });
        }
    };

    const onChangeAppointment = () => {
        if (encounter?.encounterDetailDTO?.serviceType === 'consultation' && encounter.encounterDetailDTO.appointmentType !== 'final_consultation') {
            setShowChangeDateModal(true);
        } else {
            setShowChangeCourseDateModal(true);
        }
    };

    const retrievePaymentInfo = (encounterId: string) => {
        getPaymentInfo({
            encounterId,
        }).then((res) => {
            setPaymentInfo(res.data);
        });
    }

    const onEditDiagnosis = () => {
        setShowEditReason(true);
    };

    const onDeleteDocument = (fhirId: string) => {
        updateEncounter(encounter!.encounterDetailDTO!.encounterFhirId!, {
            detachDocumentsFhirId: [fhirId],
        }).then(() => {
            retrieveEncounterDetails();
            message.success(t("patientAppointmentItemPage.file_deleted_successfully"));
        });
    };

    const validate = () => {
        const fieldsToCheck = ['lastName', 'firstName', 'mobile', 'email', 'role'];
        validateForm(fieldsToCheck);
    };

    const changeReason = (formData: IChangeReasonForm) => {
        if (!changeReasonForm) {
            return;
        }

        changeReasonForm.validateFields();
        setTimeout(() => {
            if (!changeReasonForm.getFieldsError().find(({ errors }: { errors: string[] }) => errors.length > 0)) {
                updateEncounter(encounter!.encounterDetailDTO!.encounterFhirId!, {
                    reasons: formData.reasons,
                    comment: formData.comment,
                }).then(() => {
                    retrieveEncounterDetails();
                    message.success(t("patientAppointmentItemPage.reason_for_visit_changed_successfully"));
                });
            }
        });
    };

    const getReceipt = () => {
        getCashReceipt({
            encounterId: encounter?.encounterDetailDTO?.encounterFhirId,
        }).then((res) => {
            if (res.data) {
                window.open(res.data[0]);
            }
        });

        // setShowCheckNotExistModal(true);
    };

    const openCashReceipt = () => {
        window.open(paymentInfo?.receiptUrl![0]);
    }

    const retrievePatientData = () => {
        if (currentProfile) {
            dispatch(
                getPatientActiveConsultations({
                    patientFhirId: currentProfile.fhirId!,
                }),
            );

            if (carePlan) {
                dispatch(
                    getPatientEncountersById({
                        patientFhirId: currentProfile!.fhirId!,
                        params: {
                            carePlanId: carePlan.fhirId!,
                        },
                    }),
                );
            }
        }
    };

    const encounterStatus = getEncounterStatus(encounter?.encounterDetailDTO?.status, encounter?.encounterDetailDTO?.paymentStatus);

    const onConnectToConsultation = () => {
        if (patientWorkspacePrepared) {
            navigate('/appointment?appointmentId=' + encounter?.encounterDetailDTO?.appointmentFhirId);
        } else {
            alert(t("patientAppointmentItemPage.patient_settings_not_calibrated"));
        }
    };

    return encounter ? (
        <div className={styles.wrapper}>
            <div className="d-flex justify-content-between">
                <div className={styles.titleContainer}>
                    <Button onClick={() => onBackToAllAppointments()} className="backBtn">
                        <ChevronLeft className="transparent" />
                    </Button>
                    <h1 className={styles.title}>
                        {encounter?.encounterDetailDTO?.appointmentType && encounter?.encounterDetailDTO?.appointmentType === 'diagnostic'
                            ? t(`patientAppointmentItemPage.title.diagnosticPlanDefinition.${encounter?.partOf}`)
                            : encounter?.encounterDetailDTO?.appointmentType && encounter?.encounterDetailDTO?.appointmentType === 'course'
                            ? t(`patientAppointmentItemPage.title.coursePlanDefinition.${encounter?.partOf}`)
                            : t(`patientAppointmentItemPage.title.${encounter?.encounterDetailDTO?.appointmentType}`)}
                    </h1>
                </div>
            </div>

            <Row gutter={24}>
                <Col md={16} sm={24} xs={24}>
                    <Col md={24} sm={24} xs={24}>
                        <div className={styles.eventBottomBlock}>
                            {/* Encounter Field */}
                            <div className={styles.infoField}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div>
                                            <CalendarIcon />
                                        </div>
                                        <div>
                                            <p>{t(`appointment.dateTime`)}</p>
                                            <div className="d-flex">
                                                <p className={styles.dateText}>
                                                    {encounter?.encounterDetailDTO?.dateTime &&
                                                        format(new Date(encounter?.encounterDetailDTO?.dateTime), 'dd.MM.yyyy HH:mm')}
                                                </p>
                                                <div className={styles.statusField}>
                                                    <EncounterStatusBadge
                                                        encounterStatus={encounter?.encounterDetailDTO?.status}
                                                        paymentStatus={encounter?.encounterDetailDTO?.paymentStatus}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {encounter?.encounterDetailDTO?.dateTime && encounterStatus === AppointmentStatus.confirmed && (
                                        <CountdownTimer
                                            timeX={new Date(encounter?.encounterDetailDTO?.dateTime)}
                                            before={MED_TIME_BEFORE_START_OF_SESSION}
                                            keepAfterX={true}
                                            render={() => (
                                                <div className="d-flex">
                                                    <Button type="primary" onClick={() => onConnectToConsultation()}>
                                                        {t('appointment.connect')}
                                                    </Button>
                                                </div>
                                            )}
                                        ></CountdownTimer>
                                    )}
                                    {encounterStatus !== AppointmentStatus.cancelled && encounterStatus !== AppointmentStatus.completed && (
                                        <Button onClick={onChangeAppointment}>{t('appointment.change')}</Button>
                                    )}
                                </div>

                                {encounter?.encounterDetailDTO?.status == EncounterDetailDTOStatus.cancelled && (
                                    <div className={styles.cancelReasonBlock}>
                                        <AlertIconOutlined />
                                        <p>{t('appointment.cancelReason')}</p>
                                        <p>
                                            {t(
                                                'enums.patientEncounterChangeReason.' + encounter?.encounterDetailDTO.changeReason?.at(-1)?.type,
                                                t("not_specified_1"),
                                            )}
                                        </p>
                                    </div>
                                )}
                            </div>

                            {/* Payment Field */}
                            {paymentServiceActive && paymentInfo && encounter?.encounterDetailDTO?.paymentStatus === EncounterDetailDTOPaymentStatus.completed && (
                                <div className={styles.infoField}>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex">
                                            <div className={styles.circleGreen}>
                                                <CheckCircle48 />
                                            </div>
                                            <div>
                                                <p>
                                                    {capitalizeText(
                                                        t(`callCenterManager.paymentStatuses.${encounter?.encounterDetailDTO?.paymentStatus}`),
                                                    )}
                                                </p>
                                                <div className="d-flex">
                                                    <div className={styles.statusField}>
                                                        <p className={styles.dateTextSmall}>
                                                            <p>Дата/время оплаты: <span
                                                                className={styles.blackColor}>{paymentInfo?.approvedIn &&
                                                                dayjs(paymentInfo?.approvedIn).format('DD.MM.YYYY HH:mm:ss')}</span>
                                                            </p>
                                                        </p>
                                                        <p className={styles.dateTextSmall}>
                                                            <p>Способ оплаты: <span className={styles.blackColor}>{
                                                                t("appointment.paymentMethod." + (paymentInfo.paymentMethod ? paymentInfo.paymentMethod : 'NO_DATA'))}</span>
                                                            </p>
                                                        </p>
                                                        <p className={styles.dateTextSmall}>
                                                            <p>Сумма оплаты: <span
                                                                className={styles.blackColor}>{paymentInfo?.amount &&
                                                                paymentInfo?.amount}₽</span></p>
                                                        </p>
                                                        {paymentInfo?.receiptUrl ?
                                                            <NavLink className={styles.checkButton} to=""
                                                                     onClick={() => openCashReceipt()}>
                                                                {t('appointment.seeCheck')}
                                                            </NavLink> :
                                                            paymentInfo?.paymentMethod !== 'SBERBANK' ?
                                                                <span className={styles.noCheck}>
                                                                <ProcedureFailedIcon
                                                                    className={styles.failIcon}></ProcedureFailedIcon>
                                                                    {t("appointment.noCheck")}
                                                        </span> :
                                                            <span className={styles.waitCheckDisplayInfo}>
                                                                <ProcedureTimerIcon1
                                                                    className={styles.progressIcon}></ProcedureTimerIcon1>
                                                                {t("appointment.waitCheck")}
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {encounter?.encounterDetailDTO?.status == EncounterDetailDTOStatus.cancelled && (
                                            <p className={styles.returnMoneyText}>{t('appointment.returnMoneyText')}</p>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Practitioner Field */}
                            <div className={styles.infoField}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div className={styles.avatar}>
                                            {encounter?.encounterDetailDTO?.practitionerPhoto ? (
                                                <img src={encounter?.encounterDetailDTO?.practitionerPhoto} />
                                            ) : (
                                                <UserIcon />
                                            )}
                                        </div>
                                        <div>
                                            <p className={styles.nameText}>{encounter?.encounterDetailDTO?.practitionerName}</p>
                                            <div className="d-flex">
                                                <p>
                                                    {encounter?.encounterDetailDTO?.practitionerRoleCode === 'nurse'
                                                        ? t(`appointment.practitionerRoles.nurse`)
                                                        : t(`enums.speciality.${encounter?.encounterDetailDTO?.practitionerSpecialty}`)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {encounter?.encounterDetailDTO?.practitionerFhirId && (
                                        <Button
                                            type="ghost"
                                            onClick={() => onDoctorDetails(encounter!.encounterDetailDTO!.practitionerFhirId!)}
                                            className={styles.doctorDetailsButton}
                                        >
                                            {t(`appointment.${encounter!.encounterDetailDTO!.practitionerRoleCode}DetailsButton`)}
                                            <TriangleRight />
                                        </Button>
                                    )}
                                </div>
                            </div>

                            {!!encounter?.partOf &&
                                (encounter?.encounterDetailDTO?.serviceType === 'therapy-session' ||
                                    encounter?.encounterDetailDTO?.serviceType === 'diagnostics') && (
                                    <div className={styles.courseBlockCompleted}>
                                        <div className={styles.infoField}>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex" style={{ alignItems: 'center' }}>
                                                    <div>
                                                        <EyeIcon />
                                                    </div>
                                                    <div>
                                                        <h5 className={styles.documentLabel}>
                                                            {t(`patientAppointmentItemPage.title.coursePlanDefinition.${encounter?.partOf}`)}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <Button style={{display: "none"}} type="ghost" onClick={() => onCourseDetails()} className={styles.doctorDetailsButton}>
                                                    {t('appointment.courseDetails')}
                                                    <TriangleRight />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </Col>

                    {/* Change reasons/complaints - First Consultation Block */}
                    {(encounterStatus === AppointmentStatus.created ||
                        encounterStatus === AppointmentStatus.confirmed ||
                        encounterStatus === AppointmentStatus.pending) &&
                        encounter?.encounterDetailDTO?.appointmentType == AppointmentDTOAppointmentType.initial_consultation && (
                            <Col md={24} sm={24} xs={24}>
                                <h3 className={styles.reasonText}>{t('appointment.reason')}</h3>
                                {showEditReason ? (
                                    <div className={styles.changeReasonBlock}>
                                        <Form
                                            form={changeReasonForm}
                                            onFinish={changeReason}
                                            name="changeReasonForm"
                                            layout="vertical"
                                            wrapperCol={{ span: 24 }}
                                            initialValues={{
                                                comment: encounter.encounterDetailDTO.comment,
                                                reasons: encounter.encounterDetailDTO.reasonCodes || [],
                                            }}
                                            autoComplete="off"
                                            requiredMark={false}
                                            onChange={validate}
                                        >
                                            <Row gutter={24}>
                                                <Col md={24} sm={24} xs={24}>
                                                    <p className={styles.fieldLabel}>{t('appointment.diagnosis')}</p>
                                                </Col>
                                                <Col md={24} sm={24} xs={24} className="mt-1">
                                                    <Input
                                                        disabled
                                                        value={
                                                            encounter?.encounterDetailDTO?.diagnosis &&
                                                            encounter?.encounterDetailDTO?.diagnosis
                                                                .map((item) => t(`consultationSession.patientInfoStep.preliminaryDiagnosis.${item}`))
                                                                ?.join(', ')
                                                        }
                                                    />
                                                </Col>
                                                <Col md={24} sm={24} xs={24} className="mt-4">
                                                    <p className={styles.fieldLabel}>{t('appointment.complaints.title')}</p>
                                                </Col>

                                                <Col md={24} sm={24} xs={24} className="mt-4">
                                                    <Form.Item name="reasons">
                                                        <Checkbox.Group className="d-flex flex-column">
                                                            {Object.keys(EncounterUpdateDTOReasonsItem).map((x) => (
                                                                <Checkbox style={{ marginInlineStart: 0 }} key={x} value={x}>
                                                                    {t(`consultationSession.patientInfoStep.reasonCodes.${x}`)}
                                                                </Checkbox>
                                                            ))}
                                                        </Checkbox.Group>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={24}>
                                                <Col md={24} sm={24} xs={24}>
                                                    <Form.Item name="comment" className={styles.descriptionField}>
                                                        <TextArea />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <div key="block" className="d-flex justify-content-end">
                                                <Button onClick={() => setShowEditReason(false)} key="back">
                                                    {t('appointment.cancel')}
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        changeReasonForm.submit();
                                                        setShowEditReason(false);
                                                    }}
                                                    key="cancel"
                                                    type="primary"
                                                    style={{ marginLeft: '16px' }}
                                                >
                                                    {t('appointment.saveChanges')}
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                ) : (
                                    <div className={styles.reasonBlock}>
                                        <div className={styles.infoField}>
                                            <div className="d-flex position-relative me-0">
                                                <div>
                                                    <DiagnosisIcon />
                                                </div>
                                                <div>
                                                    <EditIcon className={styles.editIcon} onClick={() => setShowEditReason(true)} />

                                                    <p>
                                                        {t("diagnosis")}:{' '}
                                                        {encounter.encounterDetailDTO.diagnosis
                                                            ?.map((x) => t('enums.preliminaryDiagnosis.' + x))
                                                            .join(', ') || t("patientAppointmentItemPage.does_not_know")}
                                                    </p>

                                                    {!!encounter?.encounterDetailDTO.reasonCodes?.length && (
                                                        <ul className="mb-0">
                                                            {encounter?.encounterDetailDTO.reasonCodes.map((x, i) => (
                                                                <li key={i}>{t('consultationSession.patientInfoStep.reasonCodes.' + x)}</li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    <p>{encounter.encounterDetailDTO?.comment}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        )}

                    {/* Completed: Conclusion */}
                    {encounterStatus === AppointmentStatus.completed && encounter?.documents && encounter?.documents?.length > 0 && (
                        <>
                            {encounter.documents.filter((item) => item.type === UserDocumentDTOType.CONCLUSION).length > 0 && (
                                <Col md={24} sm={24} xs={24}>
                                    <h3 className={styles.loadedDocumentsText}>{t('appointment.discharge')}</h3>
                                    <div className={styles.documentsBlockCompleted}>
                                        {encounter.documents
                                            .filter((item) => item.type === UserDocumentDTOType.CONCLUSION)
                                            .map((item) => (
                                                <div className={styles.infoField} key={item.documentFhirId}>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex">
                                                            <div>
                                                                <PdfFile />
                                                            </div>
                                                            <div>
                                                                <h5 className={styles.documentLabel}>
                                                                    {/* {t(`appointment.documentTypes.${item.type}`)} */}
                                                                    {item.name}.{item.extension}
                                                                </h5>
                                                                <span className={styles.documentSubtitle}>
                                                                    {encounter.encounterDetailDTO?.practitionerName},{' '}
                                                                    {t(`enums.speciality.${encounter?.encounterDetailDTO?.practitionerSpecialty}`)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <Button type="ghost" onClick={() => openDocument(item)} className={styles.documentButton}>
                                                            {t('appointment.download')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </Col>
                            )}

                            {encounter.recommendedCourse && (
                                <Col md={24} sm={24} xs={24}>
                                    <h3 className={styles.loadedDocumentsText}>{t('appointment.recommendation')}</h3>
                                    <div className={styles.recommendationBlockCompleted}>
                                        <div className={styles.infoField}>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex" style={{ alignItems: 'center' }}>
                                                    <div>
                                                        <EyeIcon />
                                                    </div>
                                                    <div>
                                                        <h5 className={styles.documentLabel}>
                                                            {t('enums.planDefinition.' + encounter.recommendedCourse)}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <Button style={{display: "none"}} type="ghost" onClick={() => onCourseDetails()} className={styles.doctorDetailsButton}>
                                                    {t('appointment.courseDetails')}
                                                    <TriangleRight />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </>
                    )}

                    {/* NotCompleted: Documents */}
                    {encounter.encounterDetailDTO?.serviceType === 'consultation' &&
                        (encounterStatus === AppointmentStatus.created ||
                            encounterStatus === AppointmentStatus.confirmed ||
                            encounterStatus === AppointmentStatus.pending) && (
                            <Col md={24} sm={24} xs={24}>
                                <h3 className={styles.loadedDocumentsText}>{t('appointment.loadedDocuments')}</h3>
                                {encounter?.documents && encounter?.documents?.length > 0 && (
                                    <div className={styles.documentsBlock}>
                                        {encounter.documents.map((item) => (
                                            <div className={styles.documentField} key={item.documentFhirId}>
                                                <FileAddedIcon />
                                                <div className={styles.content}>
                                                    <h5 className={styles.documentLabel}>{t(`appointment.documentTypes.${item.type}`)}</h5>
                                                    <p className={styles.documentSubtitle}>
                                                        {item.name}.{item.extension}
                                                    </p>
                                                </div>
                                                <CrossIcon className="pointer" onClick={() => onDeleteDocument(item.documentFhirId!)} />
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <Button style={{ marginTop: '16px' }} onClick={() => onUploadDocuments()}>
                                    {t('appointment.loadDocuments')}
                                </Button>
                            </Col>
                        )}
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Chats
                        currentUserRole={UserRole.Patient}
                        currentUserId={currentProfile?.fhirId}
                        chats={encounter?.chats}
                        headerInfo={t("chat.any_questions")}
                        noDoctorPatientMessagesLabel={
                            dayjs(encounter.encounterDetailDTO?.dateTime) < dayjs()
                                ? t("chat.chat_practitioner_not_allowed")
                                : t("chat.chat_practitioner_not_allowed")
                        }
                    />
                </Col>
            </Row>

            {showChangeDateModal && (
                <AppointmentChangeConsultationModal
                    onClose={() => setShowChangeDateModal(false)}
                    onCancelConsultation={onCancelConsultation}
                    onChangeConsultation={onChangeConsultation}
                    showModal={showChangeDateModal}
                    encounter={encounter}
                    isPatient
                />
            )}

            {showChangeCourseDateModal && (
                <Modal
                    width={800}
                    title={t("patientAppointmentItemPage.procedure_course_change")}
                    open={showChangeCourseDateModal}
                    onCancel={() => setShowChangeCourseDateModal(false)}
                    footer={[
                        <>
                            <Button onClick={() => setShowChangeCourseDateModal(false)} key="back">
                                {t('modals.changeConsultation.return')}
                            </Button>
                            <Button onClick={() => navigate('/create-schedule')} type="primary">
                                {t("patientAppointmentItemPage.edit_schedule")}
                            </Button>
                        </>,
                    ]}
                >
                    <div className={styles.changeModalInfo}>
                        <p>
                            {t("patientAppointmentItemPage.appointment_part_of_procedure_course")}{' '}
                            <strong>{t("patientAppointmentItemPage.edit_course_schedule")}</strong>.
                        </p>
                        <p>
                            {t("patientAppointmentItemPage.changing_date_resets_following_appointments")}
                        </p>
                        <p>{t("patientAppointmentItemPage.remember_procedures_daily")}</p>
                    </div>
                </Modal>
            )}

            {showFileUploadModal && (
                <FilesUploadModal
                    onCancel={() => setShowFileUploadModal(false)}
                    onSuccess={(files) => {
                        // setData({ ...data, filesToUpload: files });
                        uploadDocuments(files);
                        setShowFileUploadModal(false);
                    }}
                    files={[]}
                    showModal={showFileUploadModal}
                />
            )}

            {showCheckNotExistModal && <CheckNotExistModal visible={showCheckNotExistModal} onClose={() => setShowCheckNotExistModal(false)} />}
        </div>
    ) : null;
};
