import { FileSafeDTOBucketType, UserDirectDocumentDTO, UserDirectDocumentDTOType, getOpenContent } from '@api/documentServiceAPI';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments, selectDocuments } from 'src/store/organization';

function useOpenFile() {
    const orgDocuments = useSelector(selectDocuments);
    const dispatch = useDispatch();

    useEffect(() => {
        if (orgDocuments.length === 0) {
            dispatch(getDocuments());
        }
    }, [orgDocuments, dispatch]);

    const openFile = useCallback(async (doctType: UserDirectDocumentDTOType, save?: boolean) => {
        const foundDocument = orgDocuments.find((doc: UserDirectDocumentDTO) => doc.type === doctType);
        if (foundDocument && foundDocument.s3key) {
            try {
                const response = await getOpenContent(FileSafeDTOBucketType.OPEN_DOCUMENT, foundDocument.s3key);

                if(save) {
                    const base64Url = window.URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    const blobURL = base64Url;
                    link.href = blobURL;
                    const fileName = `${foundDocument.name}.${foundDocument.extension}`;
                    link.download = fileName;
                    link.click();
                } else {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const blobUrl = window.URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
                }
            } catch (error) {
                console.error('Failed to open file:', error);
            }
        }
    }, [orgDocuments]);

    return openFile;
}

export default useOpenFile;