import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from './CallCenterManagerAppointmentsPage.module.scss';

export const CallCenterManagerAppointmentsPage = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <Outlet />
        </div>
    );
};
