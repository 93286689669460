import { PROCEDURE_SETTINGS_TYPES, PROCEDURE_SETTING_PROPS_DICTIONARY } from '@configProcedures';
import { ProcedureTypeEnum } from '@enums';
import { ChevronRight } from '@icons';
import { ProcedureParamType, ProcedureSettingsValueType } from '@models';
import { getDefaultProcedureSettingsValues, getProcedureSettingsAsString } from '@procedures';
import { Button, Col, Form, Row, Select } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProcedureSettingsWizard.module.scss';

interface IProcedureSettingsWizardProps {
    procedureType: ProcedureTypeEnum;
    values?: Partial<ProcedureSettingsValueType>;
    submitEvent: (result?: Partial<ProcedureSettingsValueType>) => void;
}

export const ProcedureSettingsWizard = ({ submitEvent, values, procedureType }: IProcedureSettingsWizardProps) => {
    const { t } = useTranslation();

    const [settingProps, setSettingsProps] = useState<ProcedureParamType[]>([]);
    const [settingsString, setSettingsString] = useState('');
    const [formInitialValues, setFormInitialValues] = useState<Partial<ProcedureSettingsValueType>>(
        values || getDefaultProcedureSettingsValues(procedureType),
    );

    useEffect(() => {
        setSettingsProps(PROCEDURE_SETTINGS_TYPES[procedureType].filter((x) => x !== 'amblyopia')); // amblyopia cannot be changed by nurse
    }, [procedureType]);

    useEffect(() => {
        if (procedureType !== ProcedureTypeEnum.DISSOCIATION) {
            setSettingsString(getProcedureSettingsAsString(formInitialValues));
        }
    }, [formInitialValues]);

    const formChangeHandler = (_changedValue: Partial<ProcedureSettingsValueType>, allValues: Partial<ProcedureSettingsValueType>) => {
        if (allValues) {
            setSettingsString(getProcedureSettingsAsString(allValues));
        }
    };

    return (
        <div className={styles.blockWrapper}>
            <h6 className={styles.backBtn}>
                <ChevronRight onClick={() => submitEvent()} />
                <span>{t('procedureSettingsWizard.settings')}</span>
            </h6>
            <div className={styles.caption}>{t('procedures.list.' + procedureType)}</div>
            <div className={styles.subCaption}>{settingsString}</div>
            <div>
                {settingProps.length ? (
                    <Form
                        initialValues={formInitialValues}
                        onValuesChange={formChangeHandler}
                        onFinish={(value) => submitEvent(value)}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <Row gutter={16}>
                            {settingProps.map((prop, ip) => {
                                const settingProp = PROCEDURE_SETTING_PROPS_DICTIONARY(procedureType)[prop];
                                const propValues = settingProp.values;

                                return (
                                    <Col key={ip} span={12} className="mb-4">
                                        <Form.Item
                                            name={prop}
                                            label={t(`procedureSettingsWizard.${prop}.formLabel`)}
                                            className={classNames('w-100', 'mb-1', styles.settingsItem)}
                                        >
                                            <Select
                                                suffixIcon={<ChevronRight className={styles.downArrow} />}
                                                options={propValues.map((value) => ({
                                                    value,
                                                    label: settingProp.valuesLocalized
                                                        ? t(`procedureSettingsWizard.${prop}.values.${value}`, { defaultValue: value })
                                                        : value,
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                );
                            })}
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Button className={classNames('w-100', styles.formButton)} htmlType="button" onClick={() => submitEvent()}>
                                    {t('procedureSettingsWizard.cancel')}
                                </Button>
                            </Col>

                            <Col span={12}>
                                <Button className={classNames('w-100', styles.formButton)} type="primary" htmlType="submit">
                                    {t('procedureSettingsWizard.save')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <div className={styles.notFound}>{t('procedureSettingsWizard.notFound')}</div>
                )}
            </div>
        </div>
    );
};
