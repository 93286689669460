import { LSScreenSettings, LSScreenSettingsPatientSettings } from 'src/services/types/ls-screen-settings';

export class ScreenSettingsLocalStorage {
    private static readonly key = 'screen-settings';

    public static getScreenSettings(profileId?: string): LSScreenSettingsPatientSettings | undefined {
        const settingsStr = localStorage.getItem(this.key);
        if (settingsStr) {
            const settingsObj = JSON.parse(settingsStr) as LSScreenSettings[];
            return settingsObj.find((item) => item.profileId === profileId)?.settings;
        }
    }

    public static setScreenSettings(profileId: string | undefined, settings: LSScreenSettingsPatientSettings) {
        const settingsStr = localStorage.getItem(this.key);

        if (settingsStr) {
            const settingsObj = JSON.parse(settingsStr) as LSScreenSettings[];
            const patientSettingsIndex = settingsObj.findIndex((x) => x.profileId === profileId);

            if (patientSettingsIndex !== -1) {
                settingsObj[patientSettingsIndex] = { profileId: profileId!, settings };
            } else {
                settingsObj.push({ profileId: profileId!, settings });
            }

            localStorage.setItem(this.key, JSON.stringify(settingsObj));
        } else {
            localStorage.setItem(this.key, JSON.stringify([{ profileId: profileId, settings }]));
        }
    }

    public static clear() {
        localStorage.removeItem(this.key);
    }
}
