import { UserDTO, UserProfileDetailsDTO, UserRegisterDTO } from '@api/userServiceAPI';

export const UserRole = {
    Call_center_manager: 'Call_center_manager',
    Administrator: 'Administrator',
    Clinic_administrator: 'Clinic_administrator',
    Patient: 'Patient',
    Practitioner: 'Practitioner',
    Head_physician: 'Head_physician',
    Nurse: 'Nurse',
    End_user: 'End_user',
} as const;

export interface IAuthUser extends UserDTO {
    role?: (typeof UserRole)[keyof typeof UserRole];
    gender?: string;
    birthDate?: string;
    age?: number;
}

export interface ICurrentProfile {
    profileType?: (typeof UserRole)[keyof typeof UserRole];
    fhirId?: string;
    userProfileDetailsDTO?: UserProfileDetailsDTO;
}

export interface IUserState {
    // IAuthUser - we authorized, null - we're not authorized, undefined - we've not tried to authorize
    authUser: IAuthUser | null | undefined;
    switchEndUserRedirect: string | null;

    currentProfile: ICurrentProfile | null;
    registeredSuccessfully: boolean;
    email: string | undefined;
    emailTokenAfter: string | undefined;
    phoneTokenAfter: string | undefined;
    accountFormData: IUserRegisterDTO | null;
    phoneFormData: string | undefined;
    sessionIdFirst: string | undefined;
    sessionIdSecond: string | undefined;
    resendAfterFirst: string | undefined;
    resendAfterSecond: string | undefined;
    expirationDateEmail: string | undefined;
    expirationDatePhone: string | undefined;
    expirationDateRecoverEmail: string | undefined;
    attemptsEmail: number | undefined;
    attemptsSMS: number | undefined;
    codeLengthEmail: number | undefined;
    codeLengthSMS: number | undefined;
    remoteValidation: { [key: string]: string | undefined };
    emailRecover: string | undefined;
    sessionRecoverId: string | undefined;
    resendRecoverAfterFirst: string | undefined;
    attemptsRecoverEmail: number | undefined;
    codeRecoverLengthEmail: number | undefined;
    emailRecoverToken: string | undefined;
    passwordRecoveredSuccessfully: boolean;

    docInfoModel?: UserProfileDetailsDTO | undefined;
    unhandledMsgs: number;

    switchedToEndUser: boolean;
    linkEsia?: string;
    registrationUserEsia?: boolean
}

export type IUserRegisterDTO = Omit<UserRegisterDTO, 'emailTokenAfter' | 'phoneTokenAfter'>;

export const initialUserState: IUserState = {
    authUser: undefined,
    switchEndUserRedirect: null,

    currentProfile: null,
    registeredSuccessfully: false,
    email: undefined,
    emailTokenAfter: undefined,
    phoneTokenAfter: undefined,
    accountFormData: null,
    phoneFormData: undefined,
    sessionIdFirst: undefined,
    sessionIdSecond: undefined,
    resendAfterFirst: undefined,
    resendAfterSecond: undefined,
    expirationDateEmail: undefined,
    expirationDatePhone: undefined,
    expirationDateRecoverEmail: undefined,
    attemptsEmail: undefined,
    attemptsSMS: undefined,
    codeLengthEmail: undefined,
    codeLengthSMS: undefined,
    remoteValidation: {},
    emailRecover: undefined,
    sessionRecoverId: undefined,
    resendRecoverAfterFirst: undefined,
    attemptsRecoverEmail: undefined,
    codeRecoverLengthEmail: undefined,
    emailRecoverToken: undefined,
    passwordRecoveredSuccessfully: false,

    docInfoModel: undefined,
    unhandledMsgs: 0,

    switchedToEndUser: false,
    linkEsia: undefined,
    registrationUserEsia: undefined
};
