import { REACT_APP_SUPPORT_PHONE } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import { MEDICAL_SYSTEM_VERSION } from 'src/ADMINISTRATIVE_CONFIG';
import styles from './Footer.module.scss';
import useOpenFile from 'src/hooks/open-file.hook';
import { Button } from 'antd';
import { UserDirectDocumentDTOType } from '@api/documentServiceAPI';

interface IFooterProps {
    showSupportBlock?: boolean;
}

export const Footer = ({ showSupportBlock }: IFooterProps) => {
    const { t } = useTranslation();
    const openFile = useOpenFile();

    return (
        <>
            {showSupportBlock && (
                <p className={styles.supportBlock}>
                    {t('footer.support')} <strong>{REACT_APP_SUPPORT_PHONE}</strong>
                </p>
            )}

            <footer className={styles.footer}>
                <div className="container d-flex align-items-center justify-content-between">
                    <span className={styles.copyright}>{t("footer.firstYear")}-{new Date().getFullYear().toString()} © {t("footer.systemName")} {t("footer.versionLabel")} {MEDICAL_SYSTEM_VERSION}</span>
                    <div className="d-flex align-items-center">
                        <Button
                                type='link' 
                                className={styles.link} 
                                onClick={() => openFile(UserDirectDocumentDTOType.USER_AGREEMENT_PATIENTS)}
                            >
                                {t('footer.agreement')}
                        </Button>
                        {/* <div className={styles.divider} /> */}
                    </div>
                </div>
            </footer>
        </>
    );
};
