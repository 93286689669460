import { UserDTO, UserDTOStatus, UserProfileDTOProfileType, UserProfileDetailsDTOGender } from '@api/userServiceAPI';
import { InfoIcon, UserIcon } from '@icons';
import { selectAuthUser, selectCurrentProfile } from '@sliceUser';
import { Space } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DoctorProfile, NurseProfile } from 'src/pages/ClinicAdmin/ClinicAdminEmployeePage/components';
import styles from './ProfilePage.module.scss';

export const ProfilePage = () => {
    const user = useSelector(selectAuthUser);
    const profile = useSelector(selectCurrentProfile);
    const [fullName, setFullName] = useState<string>('');
    const [specialties, setSpecialties] = useState<string>('');
    const { t } = useTranslation();

    useEffect(() => {
        let fullName = `${user?.lastName} ${user?.firstName}`;
        if (user?.middleName) {
            fullName += ` ${user?.middleName}`;
        }
        setFullName(fullName);

        const firstSpeciality = profile?.profileType === UserProfileDTOProfileType.Head_physician ? ['headPhysician'] : [];
        setSpecialties(
            profile?.profileType === UserProfileDTOProfileType.Nurse
                ? user?.gender === UserProfileDetailsDTOGender.male
                    ? t('adminEmployeePage.profilePage.practitionerSpecialties.nurseMale')
                    : t('adminEmployeePage.profilePage.practitionerSpecialties.nurseFemale')
                : [...firstSpeciality, ...(profile?.userProfileDetailsDTO?.practitionerSpecialties || [])]
                      .map((s) => t(`adminEmployeePage.profilePage.practitionerSpecialties.${s}`))
                      .join('/'),
        );
    }, [user, profile]);

    return (
        <div className={styles.wrapper}>
            <h1>{t('adminEmployeePage.profilePage.profile')}</h1>

            <div className={styles.content}>
                {user?.status !== UserDTOStatus.ACTIVE && (
                    <div className={styles.nonAvatarBlock}>
                        <UserIcon />
                    </div>
                )}
                <h4 className={styles.profileTitle}>{fullName}</h4>
                {profile?.profileType === UserProfileDTOProfileType.Practitioner ||
                profile?.profileType === UserProfileDTOProfileType.Head_physician ? (
                    <>
                        <p className={styles.infoValue}>{specialties}</p>
                        {user?.status === UserDTOStatus.ACTIVE && (
                            <DoctorProfile employee={user as UserDTO} profile={profile} previewSelfMode={true} />
                        )}
                    </>
                ) : profile?.profileType === UserProfileDTOProfileType.Nurse ? (
                    <>
                        <p className={classNames(styles.infoValue, styles.nurse)}>{specialties}</p>
                        {user?.status === UserDTOStatus.ACTIVE && (
                            <NurseProfile employee={user as UserDTO} profile={profile} previewSelfMode={true} />
                        )}
                    </>
                ) : (
                    <></>
                )}
                <Space size={12} className={styles.info}>
                    <InfoIcon />
                    <p className={styles.infoValue}>{t('adminEmployeePage.profilePage.askAdminForProfile')}</p>
                </Space>
            </div>
        </div>
    );
};
