import { UpdateUserDTOStatus, UserDTO, getUser, updateUser } from '@api/userServiceAPI';
import { ChevronLeft } from '@icons';
import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from './ClinicAdminPatientPage.module.scss';

export const ClinicAdminPatientPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const [blockUserModalOpen, setBlockUserModalOpen] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState<UserDTO>();

    useEffect(() => {
        if (params.userId) {
            getUser(params.userId).then((result) => {
                setSelectedPatient(result.data);
            });
        }
    }, []);

    const getFullName = () => {
        if (selectedPatient?.firstName && selectedPatient.lastName) {
            return selectedPatient?.lastName + ' ' + selectedPatient?.firstName + ' ' + (selectedPatient?.middleName || '');
        }
    };

    const onBackToMainPage = () => {
        navigate(-1);
    };
    const onConfirmBlockUser = () => {
        updateUser(selectedPatient!.id!, { status: UpdateUserDTOStatus.BLOCKED }).then((result) => {
            if (result.data) {
                setBlockUserModalOpen(false);
            }
        });
    };

    return (
        <div className={styles['wrapper']}>
            {blockUserModalOpen && (
                <Modal
                    width={592}
                    title={t('adminEmployeePage.modals.blockUser.title')}
                    okText={t('adminEmployeePage.modals.blockUser.ok')}
                    cancelText={t('adminEmployeePage.modals.blockUser.cancel')}
                    open={blockUserModalOpen}
                    onOk={() => onConfirmBlockUser()}
                    onCancel={() => setBlockUserModalOpen(false)}
                    forceRender
                >
                    <p>{`${t('adminEmployeePage.modals.blockUser.action')} ${getFullName()}.`}</p>
                    <br />
                    <p>{t('adminEmployeePage.modals.blockUser.effect')}</p>
                </Modal>
            )}
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <Button onClick={() => onBackToMainPage()} className="backBtn">
                        <ChevronLeft className="transparent" />
                    </Button>
                    <p className={styles.title}>{getFullName()}</p>
                </div>
            </div>
            <div className={styles.accountBlock}>
                <p className={styles.account}>{t('adminEmployeePage.account')}</p>
                <div className={styles.accountInfo}>
                    <p className={styles.label}>{t('adminEmployeePage.mobile')}</p>
                    <p className={styles.text}>{selectedPatient?.mobile}</p>
                    <p className={styles.label}>{t('adminEmployeePage.email')}</p>
                    <p className={styles.text}>{selectedPatient?.email}</p>
                </div>
                <p onClick={() => setBlockUserModalOpen(true)} className={styles.blockButton}>
                    {t("account_info.lock_account")}{' '}
                </p>
            </div>
        </div>
    );
};
