export type CustomThemeNames = 'default' | 'purple' | 'orange' | 'green';

export type CustomTheme = {
    [key in CustomThemeNames]: {
        bgColor: string;
        primaryBtnBg: string;
        primaryBtnHoverBg: string;
        primaryBtnHoverColor: string;
        defaultBtnColor: string;
        defaultBtnHoverBg: string;
        defaultBtnHoverColor: string;
    };
}

export const CustomThemes: CustomTheme = {
    default: {
        bgColor: '#fafbff',
        primaryBtnBg: '#235abe',
        primaryBtnHoverBg: '#4c83e5',
        defaultBtnColor: '#235abe',
        primaryBtnHoverColor: '#ffffff',
        defaultBtnHoverBg: '#caddff',
        defaultBtnHoverColor: '#c83e5',
    },
    purple: {
        bgColor: '#FBF9FE',
        primaryBtnBg: '#731982',
        primaryBtnHoverBg: '#932BA4',
        primaryBtnHoverColor: '#1b1d21',
        defaultBtnColor: '#1b1d21',
        defaultBtnHoverBg: '#F9D3FF',
        defaultBtnHoverColor: '#1b1d21',
    },
    orange: {
        bgColor: '#FAFAFA',
        primaryBtnBg: '#F98321',
        primaryBtnHoverBg: '#D76A10',
        primaryBtnHoverColor: '#1b1d21',
        defaultBtnColor: '#1b1d21',
        defaultBtnHoverBg: '#FFD8B7',
        defaultBtnHoverColor: '#1b1d21',
    },
    green: {
        bgColor: '#F8F8F4',
        primaryBtnBg: '#00B4AB',
        primaryBtnHoverBg: '#15DDD3',
        primaryBtnHoverColor: '#1b1d21',
        defaultBtnColor: '#1b1d21',
        defaultBtnHoverBg: '#A0FFFA',
        defaultBtnHoverColor: '#1b1d21',
    }
} 