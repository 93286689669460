import { PatientFormDTO, setDiagnosticChecked } from '@api/mainServiceAPI';
import { selectCurrentProfile } from '@sliceUser';
import { Button, Modal } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DiagnosticTypeOfCourse } from 'src/COURSES_CONFIG';
import { CourseDiagnosticEnum } from 'src/enums/course-diagnostic.enum';
import { ConsultationSession } from 'src/pages/_shared/DoctorAppointmentPage/sessions/ConsultationSession/ConsultationSession';
import { DiagnosticMyopia } from 'src/pages/_shared/DoctorAppointmentPage/sessions/DiagnosticSession/diagnostics/DiagnosticMyopia/DiagnosticMyopia';
import { DiagnosticStrabismus } from 'src/pages/_shared/DoctorAppointmentPage/sessions/DiagnosticSession/diagnostics/DiagnosticStrabismus/DiagnosticStrabismus';
import styles from './PatientForms.module.scss';

interface IPatientFormProps {
    forms?: PatientFormDTO[];
    practitionerFhirId?: string;
    onCloseDiagnosticModal?: () => void;
}

export const PatientForms = ({ forms, practitionerFhirId, onCloseDiagnosticModal }: IPatientFormProps) => {
    const [currentDiagnosticForm, setCurrentDiagnosticForm] = useState<PatientFormDTO | null>(null);

    const [showInitialConsultationResultModal, setShowInitialConsultationResultModal] = useState(false);
    const [dataInitialConsultationResultModal, setDataInitialConsultationResultModal] = useState<PatientFormDTO | null>(null);

    const [showDiagnosticResultModal, setShowDiagnosticResultModal] = useState(false);

    const currentProfile = useSelector(selectCurrentProfile);

    const openSomeForm = (form: PatientFormDTO) => {
        switch (form.observationCode) {
            case 'consultation_report':
                if (!form.result || !Object.keys(form.result).length) {
                    alert(t("no_data"));
                } else {
                    setShowInitialConsultationResultModal(true);
                    setDataInitialConsultationResultModal(form);
                }
                break;

            case 'diagnostic_report':
                if (!form.result || !Object.keys(form.result).length) {
                    alert(t("no_data"));
                } else {
                    setShowDiagnosticResultModal(true);
                    setCurrentDiagnosticForm(form);

                    if (currentProfile?.fhirId === practitionerFhirId && form.id && form.flag) {
                        setDiagnosticChecked(form.id);
                    }
                }
                break;
            default:
                break;
        }
    };

    const renderTargetDiagnostic = () => {
        if (!currentDiagnosticForm?.partOf) {
            return;
        }

        const diagnosticType = DiagnosticTypeOfCourse[currentDiagnosticForm.partOf];

        switch (diagnosticType) {
            case CourseDiagnosticEnum.MYOPIA:
                return <DiagnosticMyopia disabled={true} initialValues={currentDiagnosticForm.result} />;
            case CourseDiagnosticEnum.STRABISMUS:
                return <DiagnosticStrabismus disabled={true} initialValues={currentDiagnosticForm.result} />;
            default:
                return null;
        }
    };

    return (
        <>
            {forms?.map((form) => (
                <div
                    key={form.dateTime}
                    className={classNames(
                        'pointer',
                        styles['form-container'],
                        styles.chosen,
                        currentProfile?.fhirId === practitionerFhirId && form.flag && styles['form-new'],
                    )}
                    onClick={() => openSomeForm(form)}
                >
                    <p className={styles['form-name']}>{t('enums.appointmentTypes.' + form.appointmentType)}</p>
                    <p className={styles['form-subtitle']}>
                        {/* todo not full name but abbr */}
                        {dayjs(form.dateTime).format('DD.MM.YYYY HH:mm')} {form?.practitionerFullName}
                    </p>
                </div>
            ))}

            {showInitialConsultationResultModal && (
                <Modal
                    className={styles.supportModal}
                    width="960px"
                    title={t('enums.appointmentTypes.' + dataInitialConsultationResultModal?.appointmentType)}
                    open={showInitialConsultationResultModal}
                    onCancel={() => setShowInitialConsultationResultModal(false)}
                    footer={[
                        <Button
                            style={{ marginLeft: 'auto' }}
                            key="close"
                            type="primary"
                            onClick={() => setShowInitialConsultationResultModal(false)}
                        >
                            {t('modals.buttons.close')}
                        </Button>,
                    ]}
                >
                    <ConsultationSession
                        viewOnlyData={dataInitialConsultationResultModal?.result}
                        courseType={dataInitialConsultationResultModal?.appointmentType}
                    />
                </Modal>
            )}

            {showDiagnosticResultModal && (
                <Modal
                    className={styles.supportModal}
                    width="960px"
                    title={t("appointmentPage.titles.diagnostics")}
                    open={showDiagnosticResultModal}
                    onCancel={() => {
                        setShowDiagnosticResultModal(false);
                        if (onCloseDiagnosticModal) {
                            onCloseDiagnosticModal();
                        }
                    }}
                    footer={[
                        <Button style={{ marginLeft: 'auto' }} key="close" type="primary" onClick={() => setShowDiagnosticResultModal(false)}>
                            {t('modals.buttons.close')}
                        </Button>,
                    ]}
                >
                    {renderTargetDiagnostic()}
                </Modal>
            )}
        </>
    );
};
