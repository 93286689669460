import { updateProfile, updateUser, UpdateUserDTO, UserDTO, UserProfileDTOProfileType } from '@api/userServiceAPI';
import { CustomPhoneInput } from '@components';
import { PHONE_VALIDATION } from '@utils/constants';
import { customRequired } from '@utils/utils';
import { Button, Checkbox, Col, Form, Input, message, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import classNames from 'classnames';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './EditAccountInfo.module.scss';

interface EditAccountInfoProps {
    selectedEmployee: UserDTO | undefined;
    blocked: boolean | undefined;
    onCloseEditAccount: () => void;
    onUnBlockEmployeeAccount: () => void;
    onBlockEmployeeAccount: () => void;
}

interface IEditAccountInfoForm {
    firstName: string;
    middleName: string;
    lastName: string;
    mobile: string;
    email: string;
}
export const EditAccountInfo = ({ selectedEmployee, onCloseEditAccount }: EditAccountInfoProps) => {
    const [editAccountInfoForm] = Form.useForm();
    const { validateForm } = useFormValidation(editAccountInfoForm);
    const [noMiddleNameFlag, setNoMiddleNameFlag] = useState(false);

    useEffect(() => {
        editAccountInfoForm.setFieldValue('firstName', selectedEmployee?.firstName);
        editAccountInfoForm.setFieldValue('middleName', selectedEmployee?.middleName);
        editAccountInfoForm.setFieldValue('lastName', selectedEmployee?.lastName);
        editAccountInfoForm.setFieldValue('mobile', selectedEmployee?.mobile);
        editAccountInfoForm.setFieldValue('email', selectedEmployee?.email);

        if (!selectedEmployee?.middleName) {
            setNoMiddleNameFlag(true);
        }
    }, []);

    useEffect(() => {
        validate();
    }, [noMiddleNameFlag]);

    const saveAccountInfo = (formData: IEditAccountInfoForm) => {
        if (!editAccountInfoForm || !selectedEmployee) {
            return;
        }
        editAccountInfoForm.validateFields();

        mobileChangeHandler();

        setTimeout(() => {
            if (!editAccountInfoForm.getFieldsError().find(({ errors }: { errors: string[] }) => errors.length > 0)) {
                const editedUser: UpdateUserDTO = {
                    firstName: formData.firstName.trim(),
                    middleName: noMiddleNameFlag ? undefined : formData.middleName.trim(),
                    lastName: formData.lastName.trim(),
                    phone: formData.mobile,
                    email: formData.email,
                    noMiddleName: noMiddleNameFlag,
                };

                updateUser(selectedEmployee.id!, editedUser).then((result) => {
                    if (result.data) {
                        const profile = result.data.userProfileDTOList?.filter(
                            (profile) => profile.profileType != UserProfileDTOProfileType.End_user,
                        )[0];

                        if (profile?.fhirId) {
                            updateProfile(selectedEmployee.id!, profile.fhirId, editedUser).then((result) => {
                                if (result) {
                                    onCloseEditAccount();

                                    message.success(t("data_saved"));
                                }
                            });
                        }
                    }
                });
                // editAccountInfoForm.resetFields();
            }
        });
    };

    const noMiddleNameHandler = (evt: CheckboxChangeEvent) => {
        if (editAccountInfoForm && evt.target.checked && editAccountInfoForm.getFieldValue('middleName')) {
            editAccountInfoForm.setFields([{ name: 'middleName', value: null, errors: [] }]);
        }
        setNoMiddleNameFlag(evt.target.checked);
        editAccountInfoForm?.validateFields(['middleName']);
    };

    const validate = () => {
        const fieldsToCheck = ['lastName', 'firstName', 'mobile', 'email'];
        if (!noMiddleNameFlag) {
            fieldsToCheck.push('middleName');
        }
        validateForm(fieldsToCheck);
    };

    // ?
    const mobileChangeHandler = () => {
        if (editAccountInfoForm) {
            setTimeout(() => {
                // dispatch(remoteValidationError({ field: 'mobile' }));
            });
        }
    };

    const getUserRole = () => {
        if (selectedEmployee?.userProfileDTOList) {
            return selectedEmployee?.userProfileDTOList[0] ? selectedEmployee?.userProfileDTOList[0].profileType : 'End_user';
        }
        return 'End_user';
    };

    return (
        <div>
            <h4>{t('adminEmployeePage.accountEdit')}</h4>
            <Form
                form={editAccountInfoForm}
                onFinish={saveAccountInfo}
                name="editAccountInfoForm"
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{
                    lastName: '',
                    firstName: '',
                    middleName: '',
                    mobile: '',
                    email: '',
                    role: t(`adminMainPage.roles.${getUserRole()}`),
                }}
                autoComplete="off"
                requiredMark={false}
                onChange={validate}
            >
                <Row gutter={24}>
                    <Col md={18} sm={24} xs={24}>
                        <Form.Item
                            label={t('adminEmployeePage.firstName')}
                            name="firstName"
                            rules={[customRequired(t('adminEmployeePage.validation.firstNameIsRequired'))]}
                        >
                            <Input autoFocus />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={18} sm={24} xs={24}>
                        <Form.Item
                            label={t('adminEmployeePage.lastName')}
                            name="lastName"
                            rules={[customRequired(t('adminEmployeePage.validation.lastNameIsRequired'))]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={18} sm={24} xs={24}>
                        <Form.Item
                            className={styles.labelW100}
                            label={
                                <Row className="w-100" justify="space-between">
                                    <Col>{t('adminEmployeePage.middleName')}</Col>
                                    <Col>
                                        <Checkbox
                                            className={classNames(styles.smallCheckBox, styles.checkboxItem)}
                                            onChange={noMiddleNameHandler}
                                            checked={noMiddleNameFlag}
                                        >
                                            {t('adminMainPage.modals.addEmployee.noMiddleName')}
                                        </Checkbox>
                                    </Col>
                                </Row>
                            }
                            name="middleName"
                            rules={[customRequired(t('adminEmployeePage.validation.middleNameIsRequired'), !noMiddleNameFlag)]}
                        >
                            <Input disabled={noMiddleNameFlag} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={18} sm={24} xs={24}>
                        <Form.Item
                            name="mobile"
                            label={t('adminEmployeePage.mobile')}
                            rules={[
                                { required: true, message: t('adminEmployeePage.validation.mobileIsRequired') },
                                {
                                    pattern: new RegExp(PHONE_VALIDATION),
                                    message: t('adminEmployeePage.validation.mobileFormat'),
                                },
                            ]}
                        >
                            <CustomPhoneInput onChange={mobileChangeHandler} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={18} sm={24} xs={24}>
                        <Form.Item
                            label={t('adminEmployeePage.email')}
                            name="email"
                            validateTrigger={'onBlur'}
                            rules={[
                                { type: 'email', message: t('adminEmployeePage.validation.wrongEmail') },
                                { required: true, message: t('adminEmployeePage.validation.emailIsRequired') },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={18} sm={24} xs={24}>
                        <Form.Item className={styles.bottomSection} name="role" label={t('adminEmployeePage.role')}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={18} sm={24} xs={24} className="d-flex justify-content-end">
                        <hr className="w-100 mb-4" />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={18} sm={24} xs={24} className="d-flex justify-content-end">
                        <Button onClick={() => onCloseEditAccount()} className={styles.cancelButton}>
                            {t("cancel_button")}
                        </Button>
                        <Button onClick={() => editAccountInfoForm.submit()} type="primary">
                            {t("save")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
