import { LSAudioVideoSettings } from 'src/services/types/ls-doc-audio-video-settings';

export class DocAudioVideoSettingsLocalStorage {
    private static readonly key = 'audio-video-settings';

    public static getSettings() {
        const settingsStr = localStorage.getItem(this.key);
        if (settingsStr) {
            return JSON.parse(settingsStr) as LSAudioVideoSettings;
        }
    }

    public static clear() {
        localStorage.removeItem(this.key);
    }

    public static setSettings(audioVideoSettings: LSAudioVideoSettings) {
        localStorage.setItem(this.key, JSON.stringify(audioVideoSettings));
    }
}
