import { AvetisovMatsResult, PatientFormDTO } from '@api/mainServiceAPI';
import { CalendarInputIcon, TriangleRight } from '@icons';
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDiagnosticBinocularResult, IDiagnosticVisualAcuityResult } from 'src/components/diagnostics';
import { VisualAcuityDiagnosticOptions } from '../dictionary';
import styles from './DiagnosticMyopia.module.scss';

const { Option } = Select;

export interface IDiagnosticMyopiaForm {
    diagnosticVisualAcuityMode?: 'auto' | 'manually' | 'byPatient';
    diagnosticVisualAcuity?: IDiagnosticVisualAcuityResult;

    diagnosticBinocularMode?: 'auto' | 'manually' | 'byPatient';
    diagnosticBinocular?: IDiagnosticBinocularResult;

    od_sph?: number;
    od_cyl?: number;
    od_ax?: number;
    os_sph?: number;
    os_cyl?: number;
    os_ax?: number;
    [key: string]: any;
}

interface IDiagnosticMyopiaProps {
    diagnosticVisualAcuityResult?: IDiagnosticVisualAcuityResult;
    disabled?: boolean;
    disabledForNurse?: boolean;
    lastConsultationResult?: PatientFormDTO;
    accommodationCapacityHistory?: AvetisovMatsResult[];
    initialValues?: IDiagnosticMyopiaForm;

    onSubmit?: (formValue: IDiagnosticMyopiaForm) => void;
}

export const DiagnosticMyopia = ({
    diagnosticVisualAcuityResult,
    disabled,
    disabledForNurse,
    lastConsultationResult,
    accommodationCapacityHistory = [],
    initialValues,

    onSubmit,
}: IDiagnosticMyopiaProps) => {
    const { t } = useTranslation();
    const [form] = useForm<IDiagnosticMyopiaForm>();

    const [accommodationCapacityHistoryExpanded, setAccommodationCapacityHistoryExpanded] = useState(false);

    useEffect(() => {
        if (diagnosticVisualAcuityResult) {
            form.setFieldsValue({
                diagnosticVisualAcuity: diagnosticVisualAcuityResult,
            });
        }
    }, [diagnosticVisualAcuityResult]);

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    useEffect(() => {
        const resultValues = lastConsultationResult?.result?.statusOculorumStep;

        if (resultValues) {
            const { od_sph, od_cyl, od_ax, os_sph, os_cyl, os_ax } = resultValues;

            form.setFieldsValue({
                od_sph,
                od_cyl,
                od_ax,
                os_sph,
                os_cyl,
                os_ax,
            });
        }
    }, [lastConsultationResult]);

    useEffect(() => {
        if (diagnosticVisualAcuityModeValue && diagnosticVisualAcuityModeValue !== 'auto') {
            const currentTestValue = form.getFieldValue('diagnosticVisualAcuity');
            form.setFieldsValue({
                diagnosticVisualAcuity: {
                    ...currentTestValue,
                    vis_od_glasses_off: isNaN(Number(form.getFieldValue(['diagnosticVisualAcuity', 'vis_od_glasses_off'])))
                        ? undefined
                        : form.getFieldValue(['diagnosticVisualAcuity', 'vis_od_glasses_off']),
                    vis_os_glasses_off: isNaN(Number(form.getFieldValue(['diagnosticVisualAcuity', 'vis_os_glasses_off'])))
                        ? undefined
                        : form.getFieldValue(['diagnosticVisualAcuity', 'vis_os_glasses_off']),
                    vis_od_glasses_on: isNaN(Number(form.getFieldValue(['diagnosticVisualAcuity', 'vis_od_glasses_on'])))
                        ? undefined
                        : form.getFieldValue(['diagnosticVisualAcuity', 'vis_od_glasses_on']),
                    vis_os_glasses_on: isNaN(Number(form.getFieldValue(['diagnosticVisualAcuity', 'vis_os_glasses_on'])))
                        ? undefined
                        : form.getFieldValue(['diagnosticVisualAcuity', 'vis_os_glasses_on']),
                },
            });
        }
    }, [form.getFieldValue('diagnosticVisualAcuityMode')]);

    // Ant doesn't rerender the whole form  if some field changes (even with shouldUpdate)
    // https://nanxiaobei.medium.com/watch-a-more-elegant-way-to-monitor-antd-form-field-changes-7c9b12457d67
    const diagnosticVisualAcuityModeValue = useWatch('diagnosticVisualAcuityMode', form);
    const ownGlassesDataValue = useWatch('ownGlassesData', form);
    const vis_od_glasses_off = useWatch(['diagnosticVisualAcuity', 'vis_od_glasses_off'], form);
    const vis_os_glasses_off = useWatch(['diagnosticVisualAcuity', 'vis_os_glasses_off'], form);
    const vis_od_glasses_on = useWatch(['diagnosticVisualAcuity', 'vis_od_glasses_on'], form);
    const vis_os_glasses_on = useWatch(['diagnosticVisualAcuity', 'vis_os_glasses_on'], form);

    const handleFinish = (values: IDiagnosticMyopiaForm) => {
        const fieldsToUpdate = [
            'od_sph',
            'od_cyl',
            'od_ax',
            'os_sph',
            'os_cyl',
            'os_ax',
        ];
        
        fieldsToUpdate.forEach(field => {
            if (values[field] > 0) {
                values[field] = `+${values[field]}`;
            }
        });
    
        onSubmit && onSubmit(values);
    }

    return (
        <Form
            form={form}
            initialValues={{
                diagnosticVisualAcuityMode: 'auto',
            }}
            autoComplete="off"
            onFinish={handleFinish}
            disabled={disabled}
        >
            <p className={styles.groupLabel}>
                {t("diagnostic_miopia.autorephractometria")}
                {lastConsultationResult?.dateTime && (
                    <span className={styles.historyLabel}>
                        {/* todo not full name but abbr */}
                        {dayjs(lastConsultationResult?.dateTime).format('DD.MM.YYYY')} {lastConsultationResult?.practitionerFullName}
                    </span>
                )}
            </p>
            <div className={styles.glassesGrid}>
                <p className={styles.groupLabel}>OD</p>
                <span className="ant-form-text">sph</span>
                <Form.Item
                    name="od_sph"
                    className="mb-0"
                    rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                >
                    <InputNumber step={0.1} disabled={disabled || disabledForNurse} />
                </Form.Item>
                <span className="ant-form-text">cyl</span>
                <Form.Item
                    name="od_cyl"
                    className="mb-0"
                    rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                >
                    <InputNumber step={0.1}  disabled={disabled || disabledForNurse} />
                </Form.Item>
                <span className="ant-form-text">ax</span>
                <Form.Item
                    name="od_ax"
                    className="mb-0"
                    rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                >
                    <InputNumber step={0.1}  disabled={disabled || disabledForNurse} />
                </Form.Item>

                <p className={styles.groupLabel}>OS</p>
                <span className="ant-form-text">sph</span>
                <Form.Item
                    name="os_sph"
                    className="mb-0"
                    rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                >
                    <InputNumber step={0.1}  disabled={disabled || disabledForNurse} />
                </Form.Item>
                <span className="ant-form-text">cyl</span>
                <Form.Item
                    name="os_cyl"
                    className="mb-0"
                    rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                >
                    <InputNumber step={0.1}  disabled={disabled || disabledForNurse} />
                </Form.Item>
                <span className="ant-form-text">ax</span>
                <Form.Item
                    name="os_ax"
                    className="mb-0"
                    rules={[{ required: !!ownGlassesDataValue && ownGlassesDataValue !== 'noData', message: t("required_field") }]}
                >
                    <InputNumber step={0.1}  disabled={disabled || disabledForNurse} />
                </Form.Item>
            </div>

            {!!accommodationCapacityHistory.length && (
                <>
                    <p className={classNames(styles.groupLabel, 'd-flex justify-content-between align-items-center')}>
                        <span>{t("diagnostic_miopia.changing_accum_accommodation")}</span>

                        {accommodationCapacityHistory.length > 1 && (
                            <span className={styles.linkBtn} onClick={() => setAccommodationCapacityHistoryExpanded((prev) => !prev)}>
                                {t("diagnostic_miopia.show_all")}
                                <TriangleRight className={classNames(styles.arrow, accommodationCapacityHistoryExpanded && styles.expanded)} />
                            </span>
                        )}
                    </p>
                    <div className={styles.accommodations}>
                        {accommodationCapacityHistoryExpanded ? (
                            <>
                                {accommodationCapacityHistory.map((x, i) => {
                                    return (
                                        <div key={'ac_' + i} className={styles.item}>
                                            <InputNumber disabled value={x.result?.accommodationCapacity} />
                                            <span className={styles.text}>{dayjs(x.dateTime).format('DD.MM.YYYY')}</span>
                                            <span className={styles.text}>{x.nurseFullName}</span>
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div className={styles.item}>
                                <InputNumber disabled value={accommodationCapacityHistory[0].result?.accommodationCapacity} />
                                <span className={styles.text}>{dayjs(accommodationCapacityHistory[0].dateTime).format('DD.MM.YYYY')}</span>
                                <span className={styles.text}>{accommodationCapacityHistory[0].nurseFullName}</span>
                            </div>
                        )}
                    </div>
                </>
            )}

            <div className={classNames(styles.diagnosticResults, 'mt-5')}>
                <p className={styles.groupLabel}>{t("diagnostic_miopia.vision")}</p>
                <div className={styles.formGroup}>
                    <Form.Item name="diagnosticVisualAcuityMode" className={classNames(styles.selector, 'mb-2')}>
                        <Select>
                            {VisualAcuityDiagnosticOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {t('consultationSession.statusOculorumStep.visualAcuityDiagnosticOptions.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {diagnosticVisualAcuityModeValue === 'byPatient' && (
                        <div className={styles.formGrid22}>
                            <div className="datepicker" style={{ width: '220px' }}>
                                <Form.Item name="ownGlassesDataDate" rules={[{ required: true, message: t("required_field") }]}>
                                    <DatePicker
                                        placeholder={t("placeholder_select_date")}
                                        className="w-100 topIndex"
                                        suffixIcon={<CalendarInputIcon />}
                                        popupClassName="topIndex"
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item name="ownGlassesDataSource" rules={[{ required: true, message: t("required_field") }]}>
                                <Input placeholder={t("diagnostic_miopia.source_required")} />
                            </Form.Item>
                        </div>
                    )}
                </div>
                <Form.Item
                    name={['diagnosticVisualAcuity', 'vis_od_glasses_off']}
                    label={t("diagnostic_miopia.vis_od_without_glasses")}
                    rules={[{ required: true, message: t("required_field") }]}
                >
                    {diagnosticVisualAcuityModeValue === 'auto' && isNaN(Number(vis_od_glasses_off)) ? (
                        <>
                            <Input
                                disabled={true}
                                value={
                                    !vis_od_glasses_off
                                        ? undefined
                                        : t('diagnosticSession.visualAcuityValues.' + vis_od_glasses_off, { defaultValue: vis_od_glasses_off })
                                }
                            />
                        </>
                    ) : (
                        <Input disabled={diagnosticVisualAcuityModeValue === 'auto' || disabled} />
                    )}
                </Form.Item>
                <Form.Item
                    name={['diagnosticVisualAcuity', 'vis_os_glasses_off']}
                    label={t("diagnostic_miopia.vis_os_without_glasses")}
                    rules={[{ required: true, message: t("required_field") }]}
                >
                    {diagnosticVisualAcuityModeValue === 'auto' && isNaN(Number(vis_os_glasses_off)) ? (
                        <>
                            <Input
                                disabled={true}
                                value={
                                    !vis_os_glasses_off
                                        ? undefined
                                        : t('diagnosticSession.visualAcuityValues.' + vis_os_glasses_off, { defaultValue: vis_os_glasses_off })
                                }
                            />
                        </>
                    ) : (
                        <Input disabled={diagnosticVisualAcuityModeValue === 'auto' || disabled} />
                    )}
                </Form.Item>
                <Form.Item
                    name={['diagnosticVisualAcuity', 'vis_od_glasses_on']}
                    label={t("diagnostic_miopia.vis_od_with_glasses")}
                    rules={[
                        {
                            required: diagnosticVisualAcuityResult?.withGlasses,
                            message: t("required_field"),
                        },
                    ]}
                >
                    {diagnosticVisualAcuityModeValue === 'auto' && isNaN(Number(vis_od_glasses_on)) ? (
                        <>
                            <Input
                                disabled={true}
                                value={
                                    !vis_od_glasses_on
                                        ? undefined
                                        : t('diagnosticSession.visualAcuityValues.' + vis_od_glasses_on, { defaultValue: vis_od_glasses_on })
                                }
                            />
                        </>
                    ) : (
                        <Input disabled={diagnosticVisualAcuityModeValue === 'auto' || disabled} />
                    )}
                </Form.Item>
                <Form.Item
                    name={['diagnosticVisualAcuity', 'vis_os_glasses_on']}
                    label={t("diagnostic_miopia.vis_os_with_glasses")}
                    rules={[
                        {
                            required: diagnosticVisualAcuityResult?.withGlasses,
                            message: t("required_field"),
                        },
                    ]}
                >
                    {diagnosticVisualAcuityModeValue === 'auto' && isNaN(Number(vis_os_glasses_on)) ? (
                        <>
                            <Input
                                disabled={true}
                                value={
                                    !vis_os_glasses_on
                                        ? undefined
                                        : t('diagnosticSession.visualAcuityValues.' + vis_os_glasses_on, { defaultValue: vis_os_glasses_on })
                                }
                            />
                        </>
                    ) : (
                        <Input disabled={diagnosticVisualAcuityModeValue === 'auto' || disabled} />
                    )}
                </Form.Item>
            </div>

            {!disabled && (
                <Button type="primary" className="w-100 mt-4" htmlType="submit">
                    {t("file_signing.save_data_and_end_appointment")}
                </Button>
            )}
        </Form>
    );
};
