import { Bubble, Dino2_4, TriangleRight } from '@icons';
import { Button, Col, Row } from 'antd';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PaymentDirectlyModal } from 'src/modals/PaymentDirectlyModal/PaymentDirectlyModal';
import styles from './WelcomeBlock.module.scss';
import {useTranslation} from "react-i18next";

export interface IWelcomeBlockProps {
    patientName?: string;
}

export const WelcomeBlock = ({ patientName }: IWelcomeBlockProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [showPaymentDirectlyModal, setShowPaymentDirectlyModal] = useState(false);

    return (
        <>
            <Row gutter={40} className={styles.welcome}>
                <Col span={12}>
                    <p className={styles.title}>{t("initial_consultation_block.hello")}, {patientName}!</p>
                    <p className="mb-3">
                        {t("initial_consultation_block.welcome_1")} <br />
                        {t("initial_consultation_block.welcome_2")}
                    </p>
                    <p>{t("initial_consultation_block.create_appointment")}</p>
                    <Button type="primary" onClick={() => navigate('/consultation')} className={styles['consultation-button']} size="large">
                        {t("initial_consultation_block.create_appointment_button")}
                    </Button>
                    <p className={styles['payment-directly-text']}>
                        {t("initial_consultation_block.payment_about_1")} <span onClick={() => setShowPaymentDirectlyModal(true)}>{t("initial_consultation_block.payment_about_2")}</span>
                    </p>
                </Col>
                <Col span={12}>
                    <div className={styles.dino2_4}>
                        <Dino2_4 />
                        <p className={styles.title}>{t("initial_consultation_block.hello")}!</p>
                        <div className={styles.bubble}>
                            <Bubble />
                        </div>
                    </div>
                </Col>
            </Row>

            <div className={styles['border-container']}>
                <div className={styles.content}>
                    <Row align="middle" justify="space-between">
                        <Col span={10}>
                            <p className={styles.title}>{t("initial_consultation_block.about")}</p>
                            <div className="mt-5">
                                <p className={styles.description}>
                                    {t("initial_consultation_block.p1")}
                                </p>
                                <p className={`${styles.description} mt-3`}>
                                    {t("initial_consultation_block.p2")}
                                </p>
                                <Link className={`${styles.link} d-flex align-items-center mt-4`} to="/">
                                    {t("initial_consultation_block.project_details_button")}
                                    <TriangleRight />
                                </Link>
                            </div>
                        </Col>
                        <Col span={12} className="d-flex justify-content-end">
                            <iframe width="445" height="250" src="https://www.youtube.com/embed/JWdxRwdckb8" title="Embedded youtube" />
                        </Col>
                    </Row>
                </div>
            </div>

            <PaymentDirectlyModal
                visible={showPaymentDirectlyModal}
                onClose={() => {
                    setShowPaymentDirectlyModal(false);
                }}
            />
        </>
    );
};
