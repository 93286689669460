import { UserDocumentDTOType } from '@api/documentServiceAPI';
import { CrossIcon, FileIcon, SmallAddFileIcon } from '@icons';
import { Button, Input, Modal, Select, Upload, UploadFile, UploadProps, message } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FilesUploadModal.module.scss';

interface IFilesUploadProps {
    showModal: boolean;
    onCancel: () => void;
    onSuccess: (files: UploadFileExt[]) => void;
    files: UploadFileExt[];
}

export type UploadFileExt = UploadFile & { docType?: UserDocumentDTOType; otherType?: string; done?: boolean; loading?: boolean; uuid?: string };

const { Dragger } = Upload;

export const FilesUploadModal = ({ showModal, onCancel, onSuccess, files }: IFilesUploadProps) => {
    const { t } = useTranslation();
    const [fileList, setFileList] = useState<UploadFileExt[]>([]);
    const [disableUpload, setDisableUpload] = useState<boolean>(true);

    useEffect(() => {
        setFileList(files);
    }, [files]);

    useEffect(() => {
        setDisableUpload(!fileList?.length || !!fileList.find((f) => !f.docType || (f.docType === UserDocumentDTOType.OTHER && !f.otherType)));
    });

    const handleUpload = () => {
        onSuccess(fileList);
    };

    const removeFile = (file: UploadFileExt) => {
        const index = fileList.findIndex((f) => f.uid === file.uid);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
    };

    const props: UploadProps = {
        onRemove: removeFile,
        multiple: true,
        beforeUpload: (file: UploadFileExt) => {
            if (!(file.type === 'application/pdf' || file.type?.startsWith('image/'))) {
                message.error(t('modals.filesUpload.fileTypeError'));
                return;
            }

            setFileList((prevFileList) => [...prevFileList, file]);
            return false;
        },
    };

    const changeFileType = (file: UploadFileExt, val: UserDocumentDTOType) => {
        file.docType = val;
        file.otherType = val === UserDocumentDTOType.OTHER ? file.otherType : undefined;
        setFileList([...fileList]);
    };

    const changeOtherDocType = (file: UploadFileExt, evt: ChangeEvent<HTMLInputElement>) => {
        file.otherType = evt.target.value;
        setFileList([...fileList]);
    };

    return (
        <Modal
            className={styles.modal}
            width={592}
            title={t('modals.filesUpload.title')}
            open={showModal}
            onCancel={onCancel}
            footer={[
                <Button type="default" className="w-100" onClick={onCancel} key="0">
                    {t('modals.buttons.cancel')}
                </Button>,
                <Button disabled={disableUpload} type="primary" className="w-100" onClick={handleUpload} key="1">
                    {t('modals.buttons.upload')}
                </Button>,
            ]}
        >
            <div className={`${styles.content} mb-2`}>
                {/* <p>{t('modals.filesUpload.description')}</p> */}

                <Dragger className={styles.dragger} {...props} showUploadList={false} accept="image/gif, image/jpeg, image/png, .pdf">
                    <SmallAddFileIcon />
                    <p className={styles['dragger-description']}>{t("document_uploader.move_document")}</p>
                </Dragger>

                {fileList
                    .map((f) => (
                        <div className={styles.item} key={f.uid}>
                            <div className={styles.itemHead}>
                                <FileIcon />
                                <div className={styles.itemLabel}>{f.name}</div>
                                <CrossIcon className={styles.closeIcon} onClick={() => removeFile(f)} />
                            </div>
                            <Select
                                placeholder={t('modals.filesUpload.docType')}
                                dropdownStyle={{ zIndex: 99999 }}
                                onChange={(val) => changeFileType(f, val)}
                                className="w-100"
                                value={f.docType}
                                options={[
                                    {
                                        label: t("appointment.documentTypes.DISCHARGE_SUMMARY"),
                                        value: UserDocumentDTOType.DISCHARGE_SUMMARY,
                                    },
                                    { label: t("appointment.documentTypes.CONSULTATION"), value: UserDocumentDTOType.CONSULTATION },
                                    {
                                        label: t("appointment.documentTypes.PERSONAL_DATA_STATEMENT"),
                                        value: UserDocumentDTOType.PERSONAL_DATA_STATEMENT,
                                    },
                                    {
                                        label: t("appointment.documentTypes.CERTIFIED_OPHTHALMOLOGISTS_REPORT"),
                                        value: UserDocumentDTOType.CERTIFIED_OPHTHALMOLOGISTS_REPORT,
                                    },
                                    {
                                        label: t("appointment.documentTypes.ANALYZES"),
                                        value: UserDocumentDTOType.ANALYZES
                                    },
                                    {
                                        label:t("appointment.documentTypes.SNAPSHOTS"),
                                        value: UserDocumentDTOType.SNAPSHOTS
                                    },
                                    {
                                        label: t("appointment.documentTypes.OTHER"),
                                        value: UserDocumentDTOType.OTHER
                                    },
                                ]}
                            />
                            {f.docType === UserDocumentDTOType.OTHER && (
                                <Input
                                    value={f.otherType}
                                    onChange={(evt) => changeOtherDocType(f, evt)}
                                    // placeholder={t('modals.filesUpload.otherDiagnosis')}
                                    className="w-100 mt-2"
                                />
                            )}
                        </div>
                    ))
                    .reverse()}
                {/* <Upload {...props} className={styles.upload} showUploadList={false} accept='image/gif, image/jpeg, image/png, .pdf'>
                    <Button className="w-100">{t('modals.buttons.selectFile')}</Button>
                </Upload> */}
            </div>
        </Modal>
    );
};
