import { FileSafeDTOBucketType } from '@api/documentServiceAPI';
import {
    PatientMedicalCardDTO,
    TreatmentKitDTO,
    TreatmentKitDTOId,
    TreatmentKitDTOStatus,
    UserDocumentDTO,
    approvePatientGadget,
    getPatientMedicalCard, GadgetDeliveryAddressDTO, queryPatientGadget,
} from '@api/mainServiceAPI';
import { CheckIconGreen, DocsIcon, GreyGlassesIcon, InfoIcon, PdfFile, TriangleRight } from '@icons';
import { setFileViewer } from '@sliceCore';
import { getPatientCarePlan, selectCarePlan } from '@slicePatient';
import { selectCurrentProfile } from '@sliceUser';
import {Button, Col, Row, Tooltip} from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './PatientMedicalRecord.module.scss';
import {CustomThemes} from "../../../models/custom-themes";
import {selectCustomTheme, selectDeliveryAddressActive} from "../../../store/organization";
import {DeliveryAddressModal} from "../../../modals/DeliveryAddressModal/DeliveryAddressModal";
import classNames from "classnames";
import {createDeliveryAddressString} from "@utils/addressFormatter";

import miopiaTreatmentKitImg from "../../../styles/images/treatment-kit/miopia.png";
import strabismusTreatmentKitImg from "../../../styles/images/treatment-kit/strabismus.png";
import ambliopiaHibermetropiaStrabismusTreatmentKitImg from "../../../styles/images/treatment-kit/ambliopia_hibermetropia_htrabismus.png";


export const PatientMedicalRecordPage = () => {
    const dispatch = useDispatch();

    const currentProfile = useSelector(selectCurrentProfile);
    const carePlan = useSelector(selectCarePlan);
    const deliveryAddressActive = useSelector(selectDeliveryAddressActive);
    const customTheme = useSelector(selectCustomTheme);

    const [medcard, setMedcard] = useState<PatientMedicalCardDTO>();
    const [devicesConfirmed, setDevicesConfirmed] = useState<TreatmentKitDTO[]>();
    const [gadget, setGadget] = useState<TreatmentKitDTO>();
    const [showDeliveryAddressModal, setShowDeliveryAddressModal] = useState(false);


    useEffect(() => {
        if (currentProfile?.fhirId) {
            getPatientMedicalCard(currentProfile.fhirId).then((result) => {
                if (result.data) {
                    setMedcard(result.data);
                }
            });
        }
    }, [currentProfile]);

    useEffect(() => {
        if (carePlan) {
            setDevicesConfirmed(carePlan?.treatmentKits);
        }
    }, [carePlan]);

    const onDownloadDocument = (doc: UserDocumentDTO, fileName?: string) => {
        if (doc.s3key) {
            dispatch(
                setFileViewer({
                    s3Key: doc.s3key,
                    bucketType: doc.type === FileSafeDTOBucketType.VIDEO ? FileSafeDTOBucketType.VIDEO : FileSafeDTOBucketType.DOCUMENT,
                    fileName: doc.extension ? `${fileName || doc.name || t("patient_medical_record.file")}.${doc.extension}` : (doc.name as string),
                    extension: doc.extension,
                    forceDownload: true,
                }),
            );
        }
    };

    const onConfirmGadget = (gadgetId: TreatmentKitDTOId) => {
        if (currentProfile?.fhirId && carePlan?.fhirId) {
            approvePatientGadget(currentProfile?.fhirId, carePlan?.fhirId, gadgetId).then((result) => {
                if (result) {
                    dispatch(
                        getPatientCarePlan({
                            patientFhirId: currentProfile.fhirId!,
                        }),
                    );
                }
            });
        }
    };

    const handleDeliveryAddressModal = (deliveryAddress: GadgetDeliveryAddressDTO) => {
        if (currentProfile?.fhirId && gadget?.id) {
            queryPatientGadget(currentProfile?.fhirId, gadget?.id, deliveryAddress).then(() => {
                let devices: TreatmentKitDTO[] | undefined = [...devicesConfirmed!];
                if (devices) {
                    devices = devices.map(m => {
                        return m.id === gadget?.id ? {
                            ...m,
                            gadgetDeliveryAddress: deliveryAddress,
                            deliveryStatus: 'CREATED'
                        } : m;
                    });
                    setDevicesConfirmed(devices);
                    setGadget(undefined);
                    setShowDeliveryAddressModal(false);
                }
            });
        }
    }

    const openDeliveryAddressModal = (item: TreatmentKitDTO) => {
        setGadget(item);
        setShowDeliveryAddressModal(true);
    };

    const onOpenGadgetStore = () => {
        console.log('Open gadget store.');
    };

    return (
        <>
        <div className={styles.wrapper}>
            <h1>{t('patient.medcard.label')}</h1>
            <div className={styles.content}>
                <p className={styles.subtitle}>{t('patient.medcard.discharge')}</p>
                {!!medcard?.conclusionsWithSignatures?.length ? (
                    <>
                        <div className={styles['files-wrapper']}>
                            {medcard.conclusionsWithSignatures?.map((item) => (
                                <Row gutter={24} align="middle" className={styles['file-container']} key={item.conclusion?.documentFhirId}>
                                    <Col span={2}>
                                        <PdfFile />
                                    </Col>
                                    <Col span={20}>
                                        <p className={styles['file-name']}>
                                            {item.conclusion?.name}.{item.conclusion?.extension}
                                            {!!item.signature && (
                                                <a
                                                    className={styles.link}
                                                    onClick={() => onDownloadDocument(item.signature!, item.conclusion?.name + t("patient_medical_record.signature_e"))}
                                                >
                                                    {' '}
                                                    {t("patient_medical_record.signature")}
                                                </a>
                                            )}
                                        </p>
                                    </Col>
                                    <Col span={2}>
                                        <a className={styles.link} onClick={() => onDownloadDocument(item.conclusion!)}>
                                            {t('patient.medcard.download')}
                                        </a>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className={styles.empty}>
                        <DocsIcon className={styles.icon} />
                        {t("patient_medical_record.about_report_message")}
                    </div>
                )}
            </div>
            <p className={styles.subtitle}>{t("patient_medical_record.oko_complex")}</p>
            {!!devicesConfirmed?.length ? (
                <div className={styles['files-wrapper']}>
                    {devicesConfirmed?.map((item) => (
                        <div key={item.id} className={styles.deviceBlock}>
                            <div className="d-flex align-items-start">
                                {item.id === "MYOPIA_TREATMENT_KIT" && <img className={styles.treatmentKitImage} src={miopiaTreatmentKitImg} alt={'Miopia treatment kit'}/>}
                                {item.id === "STRABISMUS_TREATMENT_KIT" && <img className={styles.treatmentKitImage} src={strabismusTreatmentKitImg} alt={'Strabismus treatment kit'}/>}
                                {item.id === "AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT" && <img className={styles.treatmentKitImage} src={ambliopiaHibermetropiaStrabismusTreatmentKitImg} alt={'Miopia treatment kit'}/>}
                                <div style={{marginLeft: '24px'}}>
                                    <h3>
                                        {t(`consultationSession.courseStep.devices.${item.id}`)}
                                    </h3>
                                    {<p className={styles.treatmentKitDescription}>{t(`consultationSession.courseStep.devices.description.${item.id}`)}</p>}
                                    {(item.gadgetDeliveryAddress || (deliveryAddressActive && carePlan?.extended && item.status === 'PROPOSED')) && (<h6 className={styles.deliveryAddress}>{t("userMainPage.courseRecommended.delivery_address")}</h6>)}
                                    {deliveryAddressActive && carePlan?.extended && !item.gadgetDeliveryAddress && item.status === 'PROPOSED' && (<p className={styles.addressDefinition}>{t("userMainPage.courseRecommended.delivery_address_not_defined")}</p>)}
                                    {item.gadgetDeliveryAddress && (<p className={styles.addressDefinition}>{createDeliveryAddressString(t, item.gadgetDeliveryAddress)}</p>)}
                                </div>
                            </div>
                            <div className={styles.confirmBlock}>
                                {item.status === TreatmentKitDTOStatus.PROPOSED ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Tooltip color={CustomThemes[customTheme].primaryBtnHoverBg} placement={'top'}
                                                 title={item.deliveryStatus === 'CREATED' ? t('userMainPage.courseRecommended.treatment_kit_is_not_sent_yet') : ''}>
                                            <Button
                                                disabled={deliveryAddressActive && (item.deliveryStatus === 'CREATED' || item.deliveryStatus === 'DELIVERED')}
                                                type="primary"
                                                onClick={() => !deliveryAddressActive || !carePlan?.extended || item.deliveryStatus !== null ? onConfirmGadget(item.id!) : openDeliveryAddressModal(item)}>
                                                {!deliveryAddressActive || !carePlan?.extended || item.deliveryStatus !== null ? t('userMainPage.courseRecommended.approve_availability') : t('userMainPage.courseRecommended.set_delivery_address')}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    item.status === TreatmentKitDTOStatus.APPROVED && (
                                        <p className={classNames(styles.deviceTitle, 'd-flex align-items-center')}>
                                            {t('userMainPage.courseRecommended.availability_approved')} <CheckIconGreen
                                            className="ms-2"/>
                                        </p>
                                    )
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.empty}>
                    <GreyGlassesIcon className={styles.icon} />
                    {t("patient_medical_record.gadgets_title")}
                </div>
            )}
            {!!devicesConfirmed?.length && (
                <div className="d-flex align-items-center">
                    <InfoIcon className="me-2" />
                    <p className={styles.info}>{t('patient.medcard.ifLostOrBrokenGadget')}</p>
                </div>
            )}
        </div>
            <DeliveryAddressModal
                visible={showDeliveryAddressModal}
                items={devicesConfirmed}
                item={gadget}
                onClose={() => {
                    setShowDeliveryAddressModal(false);
                }}
                onSave={(deliveryAddress: GadgetDeliveryAddressDTO) => {
                    handleDeliveryAddressModal(deliveryAddress)
                }}
            />
        </>
    );
};
