import {
    initRegisterAction,
    resetData,
    restorePassword,
    restorePasswordConfirmCode,
    selectAttemptsEmail,
    selectAttemptsRecoverEmail,
    selectCodeLengthEmail,
    selectCodeRecoverLengthEmail,
    selectEmail,
    selectEmailRecover,
    selectEmailRecoverToken,
    selectEmailTokenAfter,
    selectExpirationDateEmail,
    selectExpirationDateRecoverEmail,
    selectRemoteValidationMessages,
    selectResendAfterFirst,
    selectResendRecoverAfterFirst,
    selectSessionIdFirst,
    selectSessionRecoverId,
    sendEmail,
} from '@sliceUser';
import { pluralLabel } from '@utils/utils';
import { Button, Card, Col, Form, Input, InputRef, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { ClipboardEvent, createRef, FocusEvent, FormEvent, KeyboardEvent, MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './EmailConfirmationPage.module.scss';
import { Logo } from 'src/components/Logo/Logo';

interface IEmailConfirmationPageProps {
    restore?: boolean;
}

export const EmailConfirmationPage = ({ restore }: IEmailConfirmationPageProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [canResend, setCanResend] = useState<boolean>(false);
    const [backwardDirection, setBackwardDirection] = useState<boolean>(false);
    const [fromNow, setFromNow] = useState<number>();
    const [validFor, setValidFor] = useState<string>('');

    const [wrongCodeError, setWrongCodeError] = useState<string | null>(null);
    const [latestInput, setLatestInput] = useState<{ elm: any }>({ elm: null });

    const sessionId = useSelector(restore ? selectSessionRecoverId : selectSessionIdFirst);
    const emailTokenAfter = useSelector(selectEmailTokenAfter);
    const emailRecoverTokenAfter = useSelector(selectEmailRecoverToken);
    const remoteValidationMessages = useSelector(selectRemoteValidationMessages);
    const resendAfter = useSelector(restore ? selectResendRecoverAfterFirst : selectResendAfterFirst);
    const expirationDateEmail = useSelector(restore ? selectExpirationDateRecoverEmail : selectExpirationDateEmail);
    const email = useSelector(restore ? selectEmailRecover : selectEmail);
    const attempts = useSelector(restore ? selectAttemptsRecoverEmail : selectAttemptsEmail);
    const codeLength = useSelector(restore ? selectCodeRecoverLengthEmail : selectCodeLengthEmail);

    const [form] = useForm();
    const { validateForm, isSubmitDisabled } = useFormValidation(form);

    const btnRef = useRef<any>(null);
    const [inputRefs, setElRefs] = useState([]);

    useEffect(() => {
        setElRefs((inputRefs) =>
            Array(codeLength)
                .fill(null)
                .map((_, i) => inputRefs[i] || createRef()),
        );
    }, [codeLength]);

    useEffect(() => {
        if (!email) {
            navigate('/');
        }
    }, [email]);

    useEffect(() => {
        setTimeout(() => {
            if (latestInput.elm) {
                latestInput.elm.focus();
            }
        });
    }, [latestInput]);

    useEffect(() => {
        let timer: number;
        let seconds: number;
        if (resendAfter) {
            const diffMilliseconds = dayjs(resendAfter).utc().diff(dayjs().utc());
            seconds = window.setInterval(() => {
                const diff = dayjs(resendAfter).utc().diff(dayjs().utc());
                setFromNow(Math.ceil(diff / 1000));
            }, 1000);
            timer = window.setTimeout(() => {
                setCanResend(true);
                clearInterval(seconds);
                setFromNow(undefined);
            }, diffMilliseconds);
        } else {
            setCanResend(false);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
                clearInterval(seconds);
            }
        };
    }, [resendAfter]);

    useEffect(() => {
        if (expirationDateEmail) {
            setValidFor(dayjs(expirationDateEmail).utc().fromNow(true));
        }
    }, [expirationDateEmail]);

    useEffect(() => {
        if (remoteValidationMessages.emailCode) {
            setWrongCodeError(t(remoteValidationMessages.emailCode, { attemptsLeft: attempts }));
        }
    }, [remoteValidationMessages, attempts]);

    useEffect(() => {
        if (emailTokenAfter) {
            navigate('/account');
        }
    }, [emailTokenAfter]);

    useEffect(() => {
        if (emailRecoverTokenAfter) {
            navigate('/change-password');
        }
    }, [emailRecoverTokenAfter]);

    const submit = (formValue: { [part: string]: number }) => {
        if (!sessionId) {
            navigate('/register');
            return;
        }
        if (restore) {
            dispatch(restorePasswordConfirmCode({ code: Object.values(formValue).join(''), sessionId }));
        } else {
            dispatch(initRegisterAction({ code: Object.values(formValue).join(''), sessionId }));
        }
    };

    const next = (evt: FormEvent<HTMLInputElement>, i: number) => {
        const target = evt.target as HTMLInputElement;
        if (/[^0-9]/g.test(target.value)) {
            target.value = target.defaultValue;
            target.select();
            return;
        }
        if (backwardDirection) {
            return;
        }
        const nextInputRef: RefObject<InputRef> = inputRefs[i + 1] || btnRef;

        if (nextInputRef.current) {
            setLatestInput({ elm: nextInputRef.current });
        }
    };

    const selectThis = (evt: FocusEvent<HTMLInputElement>) => {
        evt.target.defaultValue = evt.target.value;
        evt.target.select();
    };

    const validate = () => {
        setWrongCodeError(null);
        validateForm(new Array(codeLength).fill(null).map((_, i) => `part${i + 1}`));
    };

    const sendEmailAgain = () => {
        if (email) {
            setCanResend(false);
            setWrongCodeError(null);
            form.resetFields();
            if (restore) {
                dispatch(restorePassword({ email }));
            } else {
                dispatch(sendEmail({ email }));
            }
        }
    };

    const resetRegisterData = (e: MouseEvent) => {
        e.preventDefault();

        dispatch(resetData());
        navigate('/register');
    };

    const pasteCode = (evt: ClipboardEvent) => {
        const paste = evt.clipboardData.getData('text');
        const fldsToFill = new Array(codeLength).fill(null).map((_, i) => `part${i + 1}`);
        const targetIndex = +(evt.target as HTMLElement).id.split('part')[1];
        const values: { [name: string]: string } = {};
        let i = targetIndex - 1;
        for (let imax = fldsToFill.length, jmax = paste.length, j = 0; i < imax && j < jmax; i++, j++) {
            values[fldsToFill[i]] = paste[j];
        }
        form.setFieldsValue(values);
        validate();
        setTimeout(() => {
            setLatestInput({ elm: form.getFieldInstance(fldsToFill[i])?.input || btnRef.current });
        });
    };

    const setDirection = (evt: KeyboardEvent, i: number) => {
        if (evt.key === 'Backspace') {
            setBackwardDirection(true);
            const prevRef: RefObject<InputRef> = inputRefs[i - 1] || inputRefs[0];
            if (prevRef.current) {
                setLatestInput({ elm: prevRef.current });
            }
        } else {
            setBackwardDirection(false);
        }
    };

    return (
        <>
            <div className={styles.wrapper}>
                <Card className={styles.contentLayout}>    
                    <Logo marginBottom />

                    <Form
                        name="confirmMobile"
                        wrapperCol={{ span: 24 }}
                        initialValues={inputRefs.reduce((obj, key, i) => Object.assign(obj, { [`part${i + 1}`]: '' }), {})}
                        autoComplete="off"
                        onFinish={submit}
                        onChange={validate}
                        form={form}
                    >
                        <h4 className="text-center">{restore ? t('EmailConfirmation.restore') : t('EmailConfirmation.checkMail')}</h4>
                        <p className="mb-3">
                            {t('EmailConfirmation.checkMailDescription', { codeLength: t(`EmailConfirmation.codeLengthDefinition.${codeLength}`) })}{' '}
                            <strong>{email}</strong>
                        </p>
                        {validFor && <p>{t('EmailConfirmation.codeIsValidFor', { minutes: validFor })}</p>}
                        <Row gutter={8}>
                            {codeLength &&
                                inputRefs.map((ref, i) => (
                                    <Col span={Math.floor(24 / codeLength)} key={i}>
                                        <Form.Item name={`part${i + 1}`} rules={[{ required: true, message: '' }]}>
                                            <Input
                                                onPaste={pasteCode}
                                                onFocus={selectThis}
                                                className={classNames('text-center', wrongCodeError && styles.error)}
                                                onInput={(evt) => next(evt, i)}
                                                maxLength={1}
                                                autoFocus={i === 0}
                                                ref={ref}
                                                onKeyDown={(evt) => {
                                                    setDirection(evt, i);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                ))}
                        </Row>
                        {wrongCodeError && (
                            <div className={styles.commonErrorWrapper}>
                                <div className={styles.commonError}>{wrongCodeError}</div>
                            </div>
                        )}
                        <Button ref={btnRef} className="w-100" type="primary" htmlType="submit" disabled={isSubmitDisabled || !attempts}>
                            {t('EmailConfirmation.labels.continue')}
                        </Button>
                        {!attempts ? (
                            <div className={classNames('mt-4', styles.txt)}>{t('EmailConfirmation.labels.attemptsExceeded')}</div>
                        ) : (
                            <>
                                {canResend ? (
                                    <>
                                        <Button type="link" className={classNames('mt-3', styles.txt, styles.txtlink)} onClick={sendEmailAgain}>
                                            {t('EmailConfirmation.labels.sendAgain')}
                                        </Button>
                                        <p className={classNames(styles.checkSpam, 'mb-3')}>{t('EmailConfirmation.checkSpam')}</p>
                                    </>
                                ) : (
                                    <div className={classNames('mt-4', styles.txt)}>
                                        {t('EmailConfirmation.labels.sendAfterAWhile')} {fromNow}{' '}
                                        {pluralLabel(fromNow || 0, [
                                            t('EmailConfirmation.labels.sec1'),
                                            t('EmailConfirmation.labels.sec2'),
                                            t('EmailConfirmation.labels.sec5'),
                                        ])}
                                        <br />
                                        <span>{t('EmailConfirmation.labels.checkSpam')}</span>
                                    </div>
                                )}
                            </>
                        )}
                    </Form>
                </Card>
                {restore ? (
                    <Space align="center" className={styles.bottomTxt}>
                        {t('Register.labels.hasAccount')}
                        <Link to="/login">{t('Register.labels.login')}</Link>
                    </Space>
                ) : (
                    <Space align="center" className={classNames(styles.txtlink, styles.bottomTxt)}>
                        <a href="" onClick={resetRegisterData}>
                            {t('EmailConfirmation.labels.changeEmail')}
                        </a>
                    </Space>
                )}
            </div>
        </>
    );
};
