import { OPTOTYPE_IMAGES, OPTOTYPE_LETTERS, OPTOTYPE_LETTERS_LATIN, OPTOTYPE_RINGS, VISUAL_ACUITY_LIST } from '@configProcedures';
import { ProcedureTypeEnum } from '@enums';
import { CheckIconGreen } from '@icons';
import { Button, Col, Input, Row, Select } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DiagnosticImages } from 'src/procedures/DiagnosticImages/DiagnosticImages';
import { DiagnosticLetters } from 'src/procedures/DiagnosticLetters/DiagnosticLetters';
import { DiagnosticRings } from 'src/procedures/DiagnosticRings/DiagnosticRings';
import styles from './DiagnosticVisualAcuity.module.scss';

const { TextArea } = Input;

type VisType = 'vis_od_glasses_off' | 'vis_os_glasses_off' | 'vis_od_glasses_on' | 'vis_os_glasses_on';

interface ISelectProps {
    value: string;
    label: string;
    key: VisType;
}

export interface IDiagnosticVisualAcuityResult {
    distance: number;
    comment: string;
    vis_od_glasses_off: number;
    vis_os_glasses_off: number;
    vis_od_glasses_on: number;
    vis_os_glasses_on: number;
    withGlasses: boolean;
}

export interface IDiagnosticVisualAcuityLastResult {
    vis_od_glasses_off: number;
    vis_os_glasses_off: number;
    vis_od_glasses_on: number;
    vis_os_glasses_on: number;
    dateTime: number;
}

interface IDiagnosticVisualAcuityProps {
    lastResult?: IDiagnosticVisualAcuityLastResult;

    diagnosticChange?: (start: boolean, type?: ProcedureTypeEnum, value?: string, visualAcuity?: number, distance?: number) => void;
    onSaveResults?: (results?: IDiagnosticVisualAcuityResult) => void;
}

export const DiagnosticVisualAcuity = ({ lastResult, diagnosticChange, onSaveResults }: IDiagnosticVisualAcuityProps) => {
    const { t } = useTranslation();

    const [distance, setDistance] = useState('1');
    const [withGlasses, setWithGlasses] = useState(true);
    const [optotypes, setOptotypes] = useState<'letters' | 'lettersLatin' | 'images' | 'rings'>('letters');
    const [regimesResults, setRegimesResults] = useState<{
        vis_od_glasses_off: { value: string; isSelected: boolean };
        vis_os_glasses_off: { value: string; isSelected: boolean };
        vis_od_glasses_on: { value: string; isSelected: boolean };
        vis_os_glasses_on: { value: string; isSelected: boolean };
    }>({
        vis_od_glasses_off: { value: '', isSelected: false },
        vis_os_glasses_off: { value: '', isSelected: false },
        vis_od_glasses_on: { value: '', isSelected: false },
        vis_os_glasses_on: { value: '', isSelected: false },
    });
    const [comment, setComment] = useState('');
    const [errorQty, setErrorQty] = useState(0);
    const [successQty, setSuccessQty] = useState(0);
    const [diagnosticLevel, setDiagnosticLevel] = useState(0);
    const [isStarted, setIsStarted] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    const [currentLetter, setCurrentLetter] = useState(OPTOTYPE_LETTERS[0]);
    const [currentLetterLatin, setCurrentLetterLatin] = useState(OPTOTYPE_LETTERS_LATIN[0]);
    const [currentRing, setCurrentRing] = useState(OPTOTYPE_RINGS[0]);
    const [currentImage, setCurrentImage] = useState(OPTOTYPE_IMAGES[0]);
    const [commonErrors, setCommonErrors] = useState<number>(0);

    const regimesOptions = [
        {
            value: '1',
            label: t("visual_acuity_diagnostic.vis_od_without_glasses"),
            key: 'vis_od_glasses_off',
        },
        {
            value: '2',
            label: t("visual_acuity_diagnostic.vis_os_without_glasses"),
            key: 'vis_os_glasses_off',
        },
        {
            value: '3',
            label: t("visual_acuity_diagnostic.vis_od_with_glasses"),
            key: 'vis_od_glasses_on',
        },
        {
            value: '4',
            label: t("visual_acuity_diagnostic.vis_os_with_glasses"),
            key: 'vis_os_glasses_on',
        },
    ] as ISelectProps[];
    const [selectedRegime, setSelectedRegime] = useState<ISelectProps>(regimesOptions[0]);

    const optotypesOptions = [
        { label: t("visual_acuity_diagnostic.russian_symbols"), value: 'letters' },
        { label: t("visual_acuity_diagnostic.english_symbols"), value: 'lettersLatin' },
        { label: t("visual_acuity_diagnostic.rings"), value: 'rings' },
        { label: t("visual_acuity_diagnostic.images"), value: 'images' },
    ];

    const handleStartDiagnostic = () => {
        setIsStarted(true);
        setErrorQty(0);
        setSuccessQty(0);

        let newVisualAcuity =
            regimesResults[selectedRegime.key].value === 'no_see' || regimesResults[selectedRegime.key].value === 'no_understand'
                ? VISUAL_ACUITY_LIST[0]
                : regimesResults[selectedRegime.key].value || VISUAL_ACUITY_LIST[diagnosticLevel];

        // reset results and try one more time
        if (newVisualAcuity === 2) {
            newVisualAcuity = VISUAL_ACUITY_LIST[0];
            setDiagnosticLevel(0);
        }

        const newRegimes = {
            ...regimesResults,
            [selectedRegime.key]: {
                ...regimesResults[selectedRegime.key],
                value: +newVisualAcuity,
                isSelected: false,
            },
        };

        setRegimesResults(newRegimes);

        runNextRound(optotypes, +newVisualAcuity);
    };

    const handleStopDiagnostic = () => {
        setIsStarted(false);
        setErrorQty(0);
        setSuccessQty(0);

        diagnosticChange?.(false);

        setIsCompleted(isAllChecksCompleted());
    };

    const successClick = () => {
        const qtyOfClicks = successQty + 1;
        setSuccessQty(successQty + 1);

        if (errorQty === 0 ? qtyOfClicks === 3 : successQty + 1 + errorQty === 5) {
            setDiagnosticLevel(diagnosticLevel + 1);

            const newVisualAcuity = VISUAL_ACUITY_LIST[diagnosticLevel + 1];

            if (!newVisualAcuity) {
                diagnosticChange?.(false);
                setIsStarted(false);
                return;
            }

            const newRegimes = {
                ...regimesResults,
                [selectedRegime.key]: { ...regimesResults[selectedRegime.key], value: newVisualAcuity },
            };

            setCommonErrors(errorQty);
            setRegimesResults(newRegimes);
            setSuccessQty(0);
            setErrorQty(0);

            runNextRound(optotypes, newVisualAcuity);
        } else {
            runNextRound(optotypes, VISUAL_ACUITY_LIST[diagnosticLevel]);
        }

        setIsCompleted(isAllChecksCompleted());
    };

    const errorClick = () => {
        const qtyOfClicks = errorQty + 1;
        setErrorQty(qtyOfClicks);

        if (qtyOfClicks === 3) {
            setIsStarted(false);

            if (diagnosticLevel > 0) {
                setDiagnosticLevel(diagnosticLevel - 1);
                if (commonErrors !== 0) {
                    if (commonErrors === 1) {
                        const newRegimes = {
                            ...regimesResults,
                            [selectedRegime.key]: {
                                isSelected: true,
                                value: `${VISUAL_ACUITY_LIST[diagnosticLevel - 1]} ${t("visual_acuity_diagnostic.b_1")}`,
                                label: selectedRegime.label,
                            },
                        };
                        setRegimesResults(newRegimes);
                    } else {
                        const newRegimes = {
                            ...regimesResults,
                            [selectedRegime.key]: {
                                isSelected: true,
                                value: `${VISUAL_ACUITY_LIST[diagnosticLevel - 1]} ${t("visual_acuity_diagnostic.b_2")}`,
                                label: selectedRegime.label,
                            },
                        };
                        setRegimesResults(newRegimes);
                    }
                } else {
                    const newRegimes = {
                        ...regimesResults,
                        [selectedRegime.key]: { isSelected: true, value: VISUAL_ACUITY_LIST[diagnosticLevel - 1], label: selectedRegime.label },
                    };

                    setRegimesResults(newRegimes);
                }
            } else {
                setDiagnosticLevel(0);

                const newRegimes = {
                    ...regimesResults,
                    [selectedRegime.key]: { isSelected: true, value: VISUAL_ACUITY_LIST[0], label: selectedRegime.label },
                };
                setRegimesResults(newRegimes);
            }

            diagnosticChange?.(false);
        } else {
            runNextRound(optotypes, VISUAL_ACUITY_LIST[diagnosticLevel]);
        }

        setIsCompleted(isAllChecksCompleted());
    };

    const runNextRound = (optotypesValue: string, visualAcuityValue: number) => {
        let nextSymbol;
        let currentOptotype;

        switch (optotypesValue) {
            case 'letters':
                nextSymbol = getRandomOptotypeSymbol(currentLetter, OPTOTYPE_LETTERS);
                currentOptotype = ProcedureTypeEnum.DIAGNOSTIC_LETTERS;
                setCurrentLetter(nextSymbol);
                break;
            case 'lettersLatin':
                nextSymbol = getRandomOptotypeSymbol(currentLetterLatin, OPTOTYPE_LETTERS_LATIN);
                currentOptotype = ProcedureTypeEnum.DIAGNOSTIC_LETTERS;
                setCurrentLetterLatin(nextSymbol);
                break;
            case 'rings':
                nextSymbol = getRandomOptotypeSymbol(currentRing, OPTOTYPE_RINGS);
                currentOptotype = ProcedureTypeEnum.DIAGNOSTIC_RINGS;
                setCurrentRing(nextSymbol);
                break;
            case 'images':
                nextSymbol = getRandomOptotypeSymbol(currentImage, OPTOTYPE_IMAGES);
                currentOptotype = ProcedureTypeEnum.DIAGNOSTIC_IMAGES;
                setCurrentImage(nextSymbol);
                break;
            default:
                break;
        }

        if (currentOptotype && nextSymbol) {
            diagnosticChange?.(true, currentOptotype, nextSymbol, visualAcuityValue, +distance);
        }
    };

    const handleRegimeChange = (value: { value: string; label: string; key: VisType }) => {
        setSelectedRegime(value);
        setDiagnosticLevel(0);
    };

    const handleChangeResults = (value: string) => {
        if (value === 'system') {
            setRegimesResults({
                ...regimesResults,
                [selectedRegime.key]: {
                    ...regimesResults[selectedRegime.key],
                    value: VISUAL_ACUITY_LIST[diagnosticLevel],
                    label: selectedRegime.label,
                },
            });
        } else {
            setRegimesResults({
                ...regimesResults,
                [selectedRegime.key]: { ...regimesResults[selectedRegime.key], isSelected: true, value: value, label: selectedRegime.label },
            });
            setIsStarted(false);
            diagnosticChange?.(false);

            setIsCompleted(isAllChecksCompleted());
        }
    };

    const isAllChecksCompleted = (): boolean => {
        return withGlasses
            ? Object.keys(regimesResults).every((x) => !!(regimesResults as any)[x].value)
            : Object.keys(regimesResults)
                  .filter((key) => key !== 'vis_od_glasses_on' && key !== 'vis_os_glasses_on')
                  .every((x) => !!(regimesResults as any)[x].value);
    };

    const getRandomOptotypeSymbol = (current: string, allValues: string[]) => {
        let newSymbol = current;
        while (newSymbol === current) {
            newSymbol = allValues[Math.floor(Math.random() * allValues.length)];
        }

        return newSymbol!;
    };

    const changeOptotypesHandler = (value: string) => {
        setOptotypes(value as any);

        if (isStarted) {
            runNextRound(value, VISUAL_ACUITY_LIST[diagnosticLevel]);
        }
    };

    const saveResults = () => {
        const results: any = {};

        Object.keys(regimesResults).forEach((x) => {
            results[x] = (regimesResults as any)[x].value || null;
        });

        const body = {
            distance: +(distance || 0),
            comment,
            withGlasses,
            ...results,
        };

        onSaveResults?.(body);
    };

    const cancelDiagnostic = () => {
        onSaveResults?.();
        diagnosticChange?.(false);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <p className={styles.title}>{t("visual_acuity_diagnostic.title")}</p>
                <Row>
                    <Col className={styles.left} span={10}>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <p className={styles.label}>{t("visual_acuity_diagnostic.screen_distance")}</p>
                            <Select
                                value={distance}
                                defaultValue="1"
                                className="w-50"
                                disabled={isStarted}
                                onChange={(value) => setDistance(value)}
                                options={[
                                    { label: '1m', value: '1' },
                                    { label: '1.5m', value: '1.5' },
                                    { label: '2m', value: '2' },
                                    { label: '5m', value: '5' },
                                ]}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <p className={styles.label}>{t("visual_acuity_diagnostic.has_glasses")}</p>
                            <Select
                                value={withGlasses}
                                defaultValue={true}
                                className="w-50"
                                disabled={isStarted}
                                onChange={(value) => {
                                    setIsCompleted(!isAllChecksCompleted());
                                    setWithGlasses(value);
                                }}
                                options={[
                                    { label: t("visual_acuity_diagnostic.yes"), value: true },
                                    { label: t("visual_acuity_diagnostic.no"), value: false },
                                ]}
                            />
                        </div>
                        <div className="mb-4">
                            <p className={styles.label}>{t("visual_acuity_diagnostic.optotypes")}</p>
                            <Select
                                value={optotypes}
                                defaultValue="letters"
                                className="w-100 mt-1"
                                onChange={changeOptotypesHandler}
                                options={optotypesOptions}
                            />
                        </div>
                        <div className="mt-2 mb-4">
                            <p className={styles.label}>{t("visual_acuity_diagnostic.mode")}</p>
                            <Select
                                labelInValue
                                value={selectedRegime}
                                defaultValue={regimesOptions[0]}
                                className="w-100 mt-1"
                                onChange={handleRegimeChange}
                                options={
                                    withGlasses ? regimesOptions : regimesOptions.filter((regime) => regime.value !== '3' && regime.value !== '4')
                                }
                            />
                        </div>
                        <Button onClick={isStarted ? handleStopDiagnostic : handleStartDiagnostic} className="w-100 mt-2" type="primary">
                            {isStarted ? t("visual_acuity_diagnostic.stop_button") : t("visual_acuity_diagnostic.start_button")}
                        </Button>
                    </Col>
                    <Col span={1}>
                        <div className={styles.divider} />
                    </Col>
                    <Col span={13}>
                        <div className={styles['diagnostic-wrapper']}>
                            <div className={styles['diagnostic-configuration']}>
                                <div className={classNames(styles.diagnostic, isStarted && styles.active)}>
                                    {isStarted ? (
                                        optotypes === 'letters' ? (
                                            <DiagnosticLetters letter={currentLetter} size={60} />
                                        ) : optotypes === 'lettersLatin' ? (
                                            <DiagnosticLetters letter={currentLetterLatin} size={60} />
                                        ) : optotypes === 'rings' ? (
                                            <DiagnosticRings direction={currentRing} size={60} />
                                        ) : optotypes === 'images' ? (
                                            <DiagnosticImages size={60} image={currentImage} />
                                        ) : null
                                    ) : !isAllChecksCompleted() ? (
                                        <>{t("visual_acuity_diagnostic.not_started")}</>
                                    ) : (
                                        <div className="d-flex flex-column align-items-center">
                                            <CheckIconGreen />
                                            <p className={styles.diagnosticResultText}>{t("visual_acuity_diagnostic.finished")}</p>
                                        </div>
                                    )}
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <Button onClick={errorClick} disabled={!isStarted} className={styles.wrongBtn}>
                                        {t("visual_acuity_diagnostic.incorrect")}
                                    </Button>
                                    <Button onClick={successClick} disabled={!isStarted} className={styles.successBtn}>
                                        {t("visual_acuity_diagnostic.correct")}
                                    </Button>
                                </div>
                                {isStarted &&
                                    regimesResults[selectedRegime.key].value !== 'no_see' &&
                                    regimesResults[selectedRegime.key].value !== 'no_understand' && (
                                        <div className={styles.indicator}>{regimesResults[selectedRegime.key].value}</div>
                                    )}
                            </div>
                            <div className={styles['selectors-content']}>
                                <Row gutter={16} align="middle" className="mb-3">
                                    <Col span={5}>
                                        <p className={styles['left-label']}>{t("visual_acuity_diagnostic.vis_od_without_glasses")}</p>
                                    </Col>
                                    <Col span={!!lastResult ? 14 : 19}>
                                        <Select
                                            disabled={!isStarted || selectedRegime.key !== 'vis_od_glasses_off'}
                                            value={regimesResults.vis_od_glasses_off.value || undefined}
                                            placeholder={t("visual_acuity_diagnostic.wait_diagnostic")}
                                            className={regimesResults.vis_od_glasses_off.isSelected ? styles.success : 'w-100'}
                                            onChange={handleChangeResults}
                                            options={[
                                                { label: t("visual_acuity_diagnostic.system_def_value"), value: 'system' },
                                                { label: t("visual_acuity_diagnostic.no_see"), value: 'no_see' },
                                                { label: t("visual_acuity_diagnostic.no_understand"), value: 'no_understand' },
                                            ]}
                                        />
                                    </Col>
                                    {!!lastResult && lastResult.vis_od_glasses_off && (
                                        <Col span={5}>
                                            <div className={styles['right-label']}>
                                                {dayjs(lastResult?.dateTime).format('DD.MM.YYYY')}
                                                <p className={styles.bold}>
                                                    {t('diagnosticSession.visualAcuityValues.' + lastResult.vis_od_glasses_off, {
                                                        defaultValue: lastResult.vis_od_glasses_off,
                                                    })}
                                                </p>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row gutter={16} align="middle" className="mb-3">
                                    <Col span={5}>
                                        <p className={styles['left-label']}>{t("visual_acuity_diagnostic.vis_os_without_glasses")}</p>
                                    </Col>
                                    <Col span={!!lastResult ? 14 : 19}>
                                        <Select
                                            disabled={!isStarted || selectedRegime.key !== 'vis_os_glasses_off'}
                                            value={regimesResults.vis_os_glasses_off.value || undefined}
                                            placeholder={t("visual_acuity_diagnostic.wait_diagnostic")}
                                            className={regimesResults.vis_os_glasses_off.isSelected ? styles.success : 'w-100'}
                                            onChange={handleChangeResults}
                                            options={[
                                                { label: t("visual_acuity_diagnostic.system_def_value"), value: 'system' },
                                                { label: t("visual_acuity_diagnostic.no_see"), value: 'no_see' },
                                                { label: t("visual_acuity_diagnostic.no_understand"), value: 'no_understand' },
                                            ]}
                                        />
                                    </Col>
                                    {!!lastResult && lastResult.vis_os_glasses_off && (
                                        <Col span={5}>
                                            <div className={styles['right-label']}>
                                                {dayjs(lastResult?.dateTime).format('DD.MM.YYYY')}
                                                <p className={styles.bold}>
                                                    {t('diagnosticSession.visualAcuityValues.' + lastResult.vis_os_glasses_off, {
                                                        defaultValue: lastResult.vis_os_glasses_off,
                                                    })}
                                                </p>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row gutter={16} align="middle" className="mb-3">
                                    <Col span={5}>
                                        <p className={styles['left-label']}>{t("visual_acuity_diagnostic.vis_od_with_glasses")}</p>
                                    </Col>
                                    <Col span={!!lastResult ? 14 : 19}>
                                        <Select
                                            disabled={!isStarted || selectedRegime.key !== 'vis_od_glasses_on'}
                                            value={regimesResults.vis_od_glasses_on.value || undefined}
                                            placeholder={t("visual_acuity_diagnostic.wait_diagnostic")}
                                            className={regimesResults.vis_od_glasses_on.isSelected ? styles.success : 'w-100'}
                                            onChange={handleChangeResults}
                                            options={[
                                                { label: t("visual_acuity_diagnostic.system_def_value"), value: 'system' },
                                                { label: t("visual_acuity_diagnostic.no_see"), value: 'no_see' },
                                                { label: t("visual_acuity_diagnostic.no_understand"), value: 'no_understand' },
                                            ]}
                                        />
                                    </Col>
                                    {!!lastResult && lastResult.vis_od_glasses_on && (
                                        <Col span={5}>
                                            <div className={styles['right-label']}>
                                                {dayjs(lastResult?.dateTime).format('DD.MM.YYYY')}
                                                <p className={styles.bold}>
                                                    {t('diagnosticSession.visualAcuityValues.' + lastResult.vis_od_glasses_on, {
                                                        defaultValue: lastResult.vis_od_glasses_on,
                                                    })}
                                                </p>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row gutter={16} align="middle" className="mb-3">
                                    <Col span={5}>
                                        <p className={styles['left-label']}>{t("visual_acuity_diagnostic.vis_os_with_glasses")}</p>
                                    </Col>
                                    <Col span={!!lastResult ? 14 : 19}>
                                        <Select
                                            disabled={!isStarted || selectedRegime.key !== 'vis_os_glasses_on'}
                                            value={regimesResults.vis_os_glasses_on.value || undefined}
                                            placeholder={t("visual_acuity_diagnostic.wait_diagnostic")}
                                            className={regimesResults.vis_os_glasses_on.isSelected ? styles.success : 'w-100'}
                                            onChange={handleChangeResults}
                                            options={[
                                                { label: t("visual_acuity_diagnostic.system_def_value"), value: 'system' },
                                                { label: t("visual_acuity_diagnostic.no_see"), value: 'no_see' },
                                                { label: t("visual_acuity_diagnostic.no_understand"), value: 'no_understand' },
                                            ]}
                                        />
                                    </Col>
                                    {!!lastResult && lastResult.vis_os_glasses_on && (
                                        <Col span={5}>
                                            <div className={styles['right-label']}>
                                                {dayjs(lastResult?.dateTime).format('DD.MM.YYYY')}
                                                <p className={styles.bold}>
                                                    {t('diagnosticSession.visualAcuityValues.' + lastResult.vis_os_glasses_on, {
                                                        defaultValue: lastResult.vis_os_glasses_on,
                                                    })}
                                                </p>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                            <div className="mb-4">
                                <p className={styles.label}>{t("visual_acuity_diagnostic.comment")}</p>
                                <TextArea value={comment} onChange={(e) => setComment(e.target.value)} className="mt-1" rows={3} />
                            </div>
                            <Row gutter={32}>
                                <Col span={12}>
                                    {/* not for the first iteration */}
                                    <Button onClick={cancelDiagnostic} className="w-100">
                                        {t("visual_acuity_diagnostic.cancel_button")}
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button onClick={saveResults} disabled={isStarted || !isCompleted} type="primary" className="w-100">
                                        {t("visual_acuity_diagnostic.save_results_button")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
