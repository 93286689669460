import { REACT_APP_SUPPORT_PHONE } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import styles from './SettingsHelpBlock.module.scss';

export const SettingsHelpBlock = () => {
    const { t } = useTranslation();

    return (
        <p className={styles.supportBlock}>
            {t('modals.needHelp')} <strong>{REACT_APP_SUPPORT_PHONE}</strong>
        </p>
    );
};
