
import styles from './PreferencesStep.module.scss';
import classNames from "classnames";
import { Button, Col, Radio, RadioChangeEvent, Row, Space, Upload, theme } from 'antd';
import { useRef } from 'react';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomTheme, selectLogo, selectPreferencesEditing, selectRequisite, selectTempLogo, setPreferencesEditing, setTempLogo, setTheme, updateLogo, updateRequisite, updateStyles } from 'src/store/organization';
import { LogoOKO } from '@icons';
import { CustomThemes } from 'src/models/custom-themes';
import { FileUploadDTO, FileUploadDTOType, uploadFile } from '@api/documentServiceAPI';
import { ORGANIZATION_ID } from '@utils/constants';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { ISettingsStep } from '../../ClinicAdminSettingsPage';
import {useTranslation} from "react-i18next";

export const PreferencesStep = ({ isReadonly }: ISettingsStep) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const customTheme = useSelector(selectCustomTheme);
    const logo = useSelector(selectLogo);
    const tempLogo = useSelector(selectTempLogo);
    const preferencesEditing = useSelector(selectPreferencesEditing);

    const themeRef = useRef<HTMLDivElement>(null);
    const logoRefId = useRef<string>();

    const onSave = () => {
        if(logoRefId?.current) {
            dispatch(updateLogo(logoRefId.current));
        }

        const selectedRadio = themeRef?.current?.querySelector('input[type="radio"]:checked');
        if (selectedRadio) {
          const value = (selectedRadio as HTMLInputElement).value;
          const updatedStyles: { [key: string]: any; } = { theme: value }; 
          dispatch(updateStyles(updatedStyles));
        }

        dispatch(setPreferencesEditing(false));
    }

    const onEdit = () => {
        dispatch(setPreferencesEditing(true));
    }

    const onCancel = () => {
        dispatch(setPreferencesEditing(false));
    }

    const onThemeChange = (e: RadioChangeEvent) => {
        dispatch(setTheme(e.target.value));
    }

    const onUploadLogo = async (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.status !== 'uploading') {
            const file = info.file.originFileObj;

            const fileName = info.file.name;
            const extension = fileName.split('.').pop();

            const data: FileUploadDTO = {
                fhirId: ORGANIZATION_ID,
                file: file,
                type: FileUploadDTOType.LOGO,
                name: file?.name ?? 'logo',
                extension: extension
            };

            const reader = new FileReader();
            reader.onload = () => {
                dispatch(setTempLogo(reader.result as string));
            };
            const fileBlob = info.file.originFileObj as Blob;
            reader.readAsDataURL(fileBlob);
            
            uploadFile(data).then((result) => {

                if (result.data) {
                    const uuid = result.data.uuid?.toString();
                    logoRefId.current = uuid;
                }
            });
        }
    };

    return (
        <div className={classNames(styles.wrapper)}>
            <h2 className="d-flex gap-3">
                {t("clinic_admin_settings_page.preference_settings.logo_and_colors")}
                {
                    !preferencesEditing && !isReadonly ? <EditOutlined onClick={onEdit} /> : null
                }
            </h2>
            {
                <>
                    <div>
                        <div className='d-flex flex-column gap-4'>
                            <h3>{t("clinic_admin_settings_page.preference_settings.clinic_logo")}</h3>
                            {preferencesEditing ?
                                <ul className={classNames(styles.logoRequiremenets, styles.greyText)}>
                                    <li>{t("clinic_admin_settings_page.preference_settings.max_volume")} 5 {t("clinic_admin_settings_page.preference_settings.mb")}</li>
                                    <li>*.svg ({t("clinic_admin_settings_page.preference_settings.preferably")});*.jpg; *.pjpeg; *.jpeg; *.png</li>
                                    <li>{t("logo_will_fit_rectangle")} 40x240 {t("clinic_admin_settings_page.preference_settings.logo_will_display_top_corner")}</li>
                                </ul>
                            : null
                            }

                            <Row gutter={64} className='align-items-center'>
                                {preferencesEditing ?
                                <Col>
                                    <Upload
                                        accept=".jpg,.png,.pjpeg,.jpeg,.svg"
                                        onChange={onUploadLogo}
                                        showUploadList={false}
                                        multiple={false}
                                    >
                                        <Button
                                            type='default'
                                            icon={<PlusCircleOutlined />}
                                        >{t("clinic_admin_settings_page.preference_settings.upload_logo")}</Button>
                                    </Upload>
                                </Col>
                                : null
                                }
                                <Col flex={1} >
                                    <div className={styles.logoContainer}>
                                        <div className={styles.logo}>
                                            {
                                                tempLogo || logo ? 
                                                <img src={tempLogo ? tempLogo : logo} className={styles.customLogo} /> :
                                                <LogoOKO />
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.theme}>
                            <h3 className='mb-4'>{t("clinic_admin_settings_page.preference_settings.color_themes")}</h3>
                            {preferencesEditing ?
                            <span className={styles.greyText}>
                                {t("clinic_admin_settings_page.preference_settings.choose_color_scheme")}
                            </span>
                            : null 
                            }
                            <Row gutter={64} className='mt-4'>
                                {preferencesEditing ?
                                <Col>
                                    <Radio.Group 
                                        ref={themeRef}
                                        className={styles.options} 
                                        onChange={onThemeChange}
                                        defaultValue={customTheme}>
                                            <Space direction="vertical">
                                                <Radio value={'default'} className='mb-3'>{t("clinic_admin_settings_page.preference_settings.classic")}</Radio>
                                                <Radio value={'purple'} className='mb-3'>{t("clinic_admin_settings_page.preference_settings.purple")}</Radio>
                                                <Radio value={'orange'} className='mb-3'>{t("clinic_admin_settings_page.preference_settings.orange")}</Radio>
                                                <Radio value={'green'} className='mb-3'>{t("clinic_admin_settings_page.preference_settings.green")}</Radio>
                                            </Space>
                                    </Radio.Group>
                                </Col>
                                : null
                                }
                                <Col flex={1}>
                                    <div className={classNames(styles.themeButtonsContainer, 'h-100', 'p-3')}>
                                        <div 
                                        className={classNames(styles.themeButtons, 'h-100', !preferencesEditing ? styles.filledBtns : '')} 
                                        style={{ backgroundColor: CustomThemes[customTheme].bgColor }}>
                                            <div>
                                                <span>{t("clinic_admin_settings_page.preference_settings.classic")}</span>
                                            </div>
                                            <div className='d-flex gap-4'>
                                                <Button type='primary'>{t("clinic_admin_settings_page.preference_settings.button_1")}</Button>
                                                <Button type='default'>{t("clinic_admin_settings_page.preference_settings.button_2")}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {preferencesEditing ?
                    <div className={classNames(styles.actions, 'pt-4')}>
                        <Row justify="space-between" align="middle">
                            <Col className='pt-3 pb-3 pr-3'>
                                <span>{t("clinic_admin_settings_page.preference_settings.upload_logo_required")}</span>
                            </Col>
                            <Col className="d-flex gap-3">
                                {preferencesEditing ? 
                                        <Button type="primary" onClick={onCancel}>
                                            {t("cancel_button")}
                                        </Button> 
                                : null}
                                <Button type="primary" htmlType="submit" onClick={onSave}>
                                    {t("save")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    : null
                    }
                </>
            }
        </div>
    );
};
