import { FileSafeDTOBucketType } from '@api/documentServiceAPI';

export interface IFileViewer {
    s3Key: string;
    bucketType?: FileSafeDTOBucketType;
    fileName: string;
    extension?: string;
    forceDownload?: boolean;
}

export interface ICoreState {
    currentLanguage: string;
    globalLoading: boolean;
    fileViewer: IFileViewer | undefined;
}

export const initialCoreState: ICoreState = {
    currentLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE,
    globalLoading: false,
    fileViewer: undefined,
};
