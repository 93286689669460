import { UserDTO } from '@api/userServiceAPI';
import { AlertIconOutlined } from '@icons';
import { t } from 'i18next';
import styles from './AccountInfo.module.scss';

interface AccountInfoProps {
    selectedEmployee: UserDTO | undefined;
    blocked: boolean | undefined;
    onUnBlockEmployeeAccount?: () => void;
    onBlockEmployeeAccount?: () => void;
    previewSelfMode?: boolean;
}

export const AccountInfo = ({ selectedEmployee, blocked, previewSelfMode }: AccountInfoProps) => {
    const getUserRole = () => {
        if (selectedEmployee?.userProfileDTOList) {
            return selectedEmployee?.userProfileDTOList[0] ? selectedEmployee?.userProfileDTOList[0].profileType : 'End_user';
        }
        return 'End_user';
    };

    return (
        <div>
            {!previewSelfMode && (
                <div className={styles.accountTitle}>
                    <h4>{t('adminEmployeePage.account')}</h4>
                </div>
            )}
            <div className={styles.infoContainer}>
                {blocked && (
                    <div className={styles.infoBlocked}>
                        <AlertIconOutlined />
                        <p className={styles.infoValue}>{t('adminEmployeePage.userBlocked')}</p>
                    </div>
                )}
                <div className={styles.infoItem}>
                    <p className={styles.infoTitle}>{t('adminEmployeePage.mobile')}</p>
                    <p className={styles.infoValue}>{selectedEmployee?.mobile}</p>
                </div>
                <div className={styles.infoItem}>
                    <p className={styles.infoTitle}>{t('adminEmployeePage.email')}</p>
                    <p className={styles.infoValue}>{selectedEmployee?.email}</p>
                </div>
                <div className={styles.infoItem}>
                    <p className={styles.infoTitle}>{t('adminEmployeePage.role')}</p>
                    <p className={styles.infoValue}>{t(`adminMainPage.roles.${getUserRole()}`)}</p>
                </div>
            </div>
        </div>
    );
};
