import { TreatmentKitDTO } from '@api/mainServiceAPI';
import { SettingButton } from '@components';
import { Bubble3, ChevronRight, Dino2_2, GlassesIcon, MonitorCalibration, MonitorDiagonal, MonitorDistance, SoundVideo } from '@icons';
import { AudioVideoSettingsModal, ColorSettingsModal, ScreenDistanceModal, ScreenSettingsModal } from '@modals';
import {
    selectCarePlan,
    selectPatientWorkspacePrepared,
    selectPatientWorkspaceSettings,
    setPatientWorkspacePrepared,
    setPatientWorkspaceSettings,
} from '@slicePatient';
import { defaultScreenSettings } from '@utils/constants';
import { Button, Col, Row } from 'antd';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LSScreenSettingsPatientSettings, ScreenSettingsLocalStorage } from 'src/services';
import styles from './WorkspaceSettings.module.scss';

export interface IWorkspaceSettingsProps {
    profileId: string;
    onlyForInitialSettings: boolean;
    withDevices?: boolean;
}

export const WorkspaceSettings = ({ profileId, onlyForInitialSettings = false, withDevices = false }: IWorkspaceSettingsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const patientWorkspacePrepared = useSelector(selectPatientWorkspacePrepared);
    const patientWorkspaceSettings = useSelector(selectPatientWorkspaceSettings);
    const carePlan = useSelector(selectCarePlan);

    const [devicesConfirmed, setDevicesConfirmed] = useState<TreatmentKitDTO[]>();
    const [showDistanceModal, setShowDistanceModal] = useState(false);
    const [showDiagonalModal, setShowDiagonalModal] = useState(false);
    const [showColorModal, setShowColorModal] = useState(false);
    const [showSoundVideoModal, setShowSoundVideoModal] = useState(false);

    const [profileSettings, setProfileSettings] = useState<LSScreenSettingsPatientSettings>(patientWorkspaceSettings || defaultScreenSettings);

    useEffect(() => {
        if (carePlan) {
            setDevicesConfirmed(carePlan?.treatmentKits);
        }
    }, [carePlan]);

    const handleDistanceSuccess = () => {
        ScreenSettingsLocalStorage.setScreenSettings(profileId, profileSettings);
        if (!patientWorkspacePrepared) {
            setShowDistanceModal(false);
            setShowDiagonalModal(true);
        } else {
            setShowDistanceModal(false);
            dispatch(setPatientWorkspaceSettings(ScreenSettingsLocalStorage.getScreenSettings(profileId)!));
        }
    };

    const handleDiagonalSuccess = () => {
        ScreenSettingsLocalStorage.setScreenSettings(profileId, profileSettings);
        if (!patientWorkspacePrepared) {
            setShowDiagonalModal(false);
            setShowColorModal(true);
        } else {
            setShowDiagonalModal(false);
            dispatch(setPatientWorkspaceSettings(ScreenSettingsLocalStorage.getScreenSettings(profileId)!));
        }
    };

    const handleColorSuccess = (value: { red: number; blue: number; green: number }) => {
        setProfileSettings({ ...profileSettings, colors: value });
        ScreenSettingsLocalStorage.setScreenSettings(profileId, { ...profileSettings, colors: value });

        if (!patientWorkspacePrepared) {
            setShowColorModal(false);
            setShowSoundVideoModal(true);
        } else {
            setShowColorModal(false);
            dispatch(setPatientWorkspaceSettings(ScreenSettingsLocalStorage.getScreenSettings(profileId)!));
        }
    };

    const handleAudioVideoSuccess = (value: {
        audioInput: MediaDeviceInfo | undefined;
        audioOutput: MediaDeviceInfo | undefined;
        video: MediaDeviceInfo | undefined;
    }) => {
        setProfileSettings({ ...profileSettings, videoAudio: value });
        ScreenSettingsLocalStorage.setScreenSettings(profileId, { ...profileSettings, videoAudio: value });
        setShowSoundVideoModal(false);

        dispatch(setPatientWorkspaceSettings(ScreenSettingsLocalStorage.getScreenSettings(profileId)!));
        dispatch(setPatientWorkspacePrepared(true));
    };

    const handleChangeDistance = (e: ChangeEvent<HTMLInputElement>) => {
        setProfileSettings({ ...profileSettings, distance: e.target.value });
    };

    const handleChangeDiagonal = (value: string) => {
        setProfileSettings({ ...profileSettings, diagonal: value });
    };

    const startPreparation = () => {
        setShowDistanceModal(true);
    };

    return (
        <div className={styles.wrapper}>
            {/* for first setup */}
            {!patientWorkspacePrepared && onlyForInitialSettings && (
                <Row align="middle">
                    <Col span={10}>
                        <p className={styles.settingTitle}>{t('userMainPage.setting.title')}</p>
                        <p className={styles.subtitle}>{t('userMainPage.setting.subtitle')}</p>
                        <Button onClick={startPreparation} className="mt-4" type="primary" size="large">
                            {t('userMainPage.setting.startButton')}
                        </Button>
                    </Col>
                    <Col span={14}>
                        <Row align="middle" justify="space-between">
                            <Col span={4}>
                                <SettingButton
                                    title={t('modals.screenDistance.title')}
                                    onClick={() => setShowDistanceModal(true)}
                                    icon={<MonitorDistance />}
                                />
                            </Col>
                            <ChevronRight />
                            <Col span={4}>
                                <SettingButton
                                    title={t('modals.screenSettings.title')}
                                    onClick={() => setShowDiagonalModal(true)}
                                    icon={<MonitorDiagonal />}
                                />
                            </Col>
                            <ChevronRight />
                            <Col span={4}>
                                <SettingButton
                                    title={t('userMainPage.setting.calibration.title')}
                                    onClick={() => setShowColorModal(true)}
                                    icon={<MonitorCalibration />}
                                />
                            </Col>
                            <ChevronRight />
                            <Col span={4}>
                                <SettingButton
                                    title={t('userMainPage.setting.videoSound.title')}
                                    onClick={() => setShowSoundVideoModal(true)}
                                    icon={<SoundVideo />}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}

            {/* for change existed settings */}
            {patientWorkspacePrepared && !onlyForInitialSettings && (
                <div className="p-4 ">
                    <p className={classNames(styles.title, 'mb-4')}>{t('userMainPage.setting.workPlace')}</p>
                    <Row justify="space-between" align="middle">
                        <Col span={6}>
                            <SettingButton
                                title={t('userMainPage.setting.distance')}
                                isHorizontal
                                variable={`${profileSettings.distance} ${t("userMainPage.setting.cm")}`}
                                onClick={() => setShowDistanceModal(true)}
                                icon={<MonitorDistance />}
                            />
                        </Col>
                        <Col span={6}>
                            <SettingButton
                                title={t('userMainPage.setting.diagonal')}
                                isHorizontal
                                variable={`${profileSettings.diagonal} ${t("userMainPage.setting.mm")}`}
                                onClick={() => setShowDiagonalModal(true)}
                                icon={<MonitorDiagonal />}
                            />
                        </Col>
                        <Col span={6}>
                            <SettingButton
                                title={t('userMainPage.setting.calibration.shortTitle')}
                                isHorizontal
                                onClick={() => setShowColorModal(true)}
                                icon={<MonitorCalibration />}
                            />
                        </Col>
                        <Col span={6}>
                            <SettingButton
                                title={t('userMainPage.setting.videoSound.title')}
                                isHorizontal
                                onClick={() => setShowSoundVideoModal(true)}
                                icon={<SoundVideo />}
                            />
                        </Col>
                    </Row>
                </div>
            )}

            {withDevices && (
                <>
                    <div className={classNames(styles.divider, 'mt-5')} />
                    <Row className="mt-5 d-flex position-relative">
                        <Col span={12} className="d-flex align-items-center">
                            <GlassesIcon className="me-4" />
                            <div className={styles['glasses-container']}>
                                <p className={styles.title}>
                                    {devicesConfirmed?.map((x) => t(`consultationSession.courseStep.devices.${x.id}`)).join(', ')}
                                </p>
                                <p className={styles.subtitle}>{t('userMainPage.setting.glasses.subtitle')}</p>
                            </div>
                        </Col>
                        <div className={styles.dino2}>
                            <Dino2_2 />
                            <div className={styles.bubble}>
                                <p className={styles.title} style={{textAlign: "center", marginLeft: "-35px"}}>
                                    {t("userMainPage.setting.annotation")}
                                </p>
                                <Bubble3 />
                            </div>
                        </div>
                    </Row>
                </>
            )}
            {showDistanceModal && (
                <ScreenDistanceModal
                    onCancel={() => setShowDistanceModal(false)}
                    onSuccess={handleDistanceSuccess}
                    isFirst={!patientWorkspacePrepared}
                    showModal={showDistanceModal}
                    distance={profileSettings.distance}
                    onChangeDistance={handleChangeDistance}
                />
            )}

            {showDiagonalModal && (
                <ScreenSettingsModal
                    diagonal={profileSettings.diagonal}
                    onHandleChangeDiagonal={handleChangeDiagonal}
                    onCancel={() => setShowDiagonalModal(false)}
                    onSuccess={handleDiagonalSuccess}
                    isFirst={!patientWorkspacePrepared}
                    showModal={showDiagonalModal}
                />
            )}

            {showColorModal && (
                <ColorSettingsModal
                    colors={profileSettings.colors}
                    onCancel={() => setShowColorModal(false)}
                    onSuccess={handleColorSuccess}
                    isFirst={!patientWorkspacePrepared}
                    showModal={showColorModal}
                />
            )}

            {showSoundVideoModal && (
                <AudioVideoSettingsModal
                    audioInput={profileSettings.videoAudio.audioInput}
                    audioOutput={profileSettings.videoAudio.audioOutput}
                    videoInput={profileSettings.videoAudio.video}
                    onCancel={() => setShowSoundVideoModal(false)}
                    onSuccess={handleAudioVideoSuccess}
                    showModal={showSoundVideoModal}
                    isPatient
                    patientId={profileId}
                />
            )}
        </div>
    );
};
