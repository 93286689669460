import { IPatientProcedureInteraction, IProcedureInteractiveConfigBase } from '@models';

export interface IProcedureBase {
    run: () => void;
    destroy: () => void;
    interactionHandler?: (interaction: IPatientProcedureInteraction) => void;
    togglePause: (isPaused: boolean) => void;
}

export abstract class ProcedureBase {
    public interactionHandler: (interaction: IPatientProcedureInteraction) => void;
    private _interactiveConfig?: Partial<IProcedureInteractiveConfigBase>;

    private __successTries = 0;
    private __failedTries = 0;
    protected _totalTries = 0;
    protected _currentTime = 0;

    protected _canvas: HTMLCanvasElement;
    protected _ctx: CanvasRenderingContext2D;
    protected _fps = 60;
    protected _animationNeeded = true;

    // event listeners
    protected mouseclickHandler: (e: MouseEvent) => void;
    protected mousedownHandler: (e: MouseEvent) => void;
    protected mouseupHandler: (e: MouseEvent) => void;
    protected mousemoveHandler: (e: MouseEvent) => void;

    protected set _successTries(value: number) {
        if (value > this.__successTries) {
            this._successAudio.currentTime = 0;
            this._successAudio.play();
        }
        this.__successTries = value;
    }

    protected get _successTries(): number {
        return this.__successTries;
    }

    protected set _failedTries(value: number) {
        if (value > this.__failedTries) {
            this._failAudio.currentTime = 0;
            this._failAudio.play();
        }
        this.__failedTries = value;
    }

    protected get _failedTries(): number {
        return this.__failedTries;
    }

    protected animationInterval: NodeJS.Timer | undefined;
    protected animationTimeout: NodeJS.Timeout | undefined;

    protected paused = false;

    protected _successAudio: HTMLAudioElement;
    protected _failAudio: HTMLAudioElement;

    constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, config?: any) {
        this._canvas = canvas;
        this._ctx = ctx;

        if (config) {
            this._interactiveConfig = config;
        }

        this._successAudio = new Audio('/audio/dartok.mp3');
        this._failAudio = new Audio('/audio/dartfail.mp3');
    }

    public interact(): void {
        if (this._interactiveConfig) {
            const interaction: IPatientProcedureInteraction = {
                successTries: this._successTries,
                failedTries: this._failedTries,
                totalTries: this._totalTries,
            };
            this.interactionHandler(interaction);
        }
    }

    public togglePause(isPaused: boolean): void {
        this.paused = isPaused;
    }

    public destroy(): void {
        this._canvas.removeEventListener('click', this.mouseclickHandler);
        this._canvas.removeEventListener('mousedown', this.mousedownHandler);
        this._canvas.removeEventListener('mouseup', this.mouseupHandler);
        this._canvas.removeEventListener('mousemove', this.mousemoveHandler);

        clearInterval(this.animationInterval);
        this.animationInterval = undefined;

        clearTimeout(this.animationTimeout);
        this.animationTimeout = undefined;

        this._animationNeeded = false;
        this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
    }
}
