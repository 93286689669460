import { Bubble, Dino5 } from '@icons';
import { selectCurrentProfile } from '@sliceUser';
import { Button, Col, Row } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './CourseCompletedBlock.module.scss';

export const CourseCompletedBlock = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentProfile = useSelector(selectCurrentProfile);

    return (
        <div className={classNames(styles.consultationBlock, styles.grayBlock)}>
            <Row justify="space-between" align="top" gutter={64}>
                <Col lg={12} md={24} sm={24}>
                    <h4 className="mb-4">
                        {t("initial_consultation_block.hello")}, {currentProfile?.userProfileDetailsDTO?.firstName}
                        {/* {t('userMainPage.notification2.title')} */}
                    </h4>
                    <p className="mb-3">
                        {t("initial_consultation_block.welcome_1")} <br />
                        {t("initial_consultation_block.welcome_2")}
                    </p>
                    <p>{t("initial_consultation_block.create_appointment")}</p>
                    <Button type="primary" size="large" className="mt-3 ps-4 pe-4" onClick={() => navigate('/consultation')}>
                        {t('userMainPage.notification2.confirmSignUp')}
                    </Button>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24} className={styles.dino5r}>
                    <div className={styles.bubble}>
                        <p>{t('userMainPage.notification2.awaitingForYou')}</p>
                        <Bubble />
                    </div>
                    <Dino5 className={styles.dino} />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="97"
                        height="21"
                        viewBox="0 0 97 21"
                        fill="none"
                        style={{ position: 'absolute', right: 50, bottom: -40 }}
                    >
                        <ellipse opacity="0.1" cx="48.9604" cy="10.0923" rx="48.0395" ry="10.0923" fill="#1B1D21" />
                    </svg>
                </Col>
            </Row>
        </div>
    );
};
