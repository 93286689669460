import { AppointmentDTOServiceType } from '@api/mainServiceAPI';
import { Bubble, Dino2_1 } from '@icons';
import { selectPatientWorkspacePrepared } from '@slicePatient';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATIENT_TIME_AFTER_START_OF_SESSION, PATIENT_TIME_BEFORE_START_OF_SESSION } from 'src/ADMINISTRATIVE_CONFIG';
import styles from './SessionConnectionButton.module.scss';

export interface ISessionConnectionButtonProps {
    appointmentId: string;
    appointmentType: AppointmentDTOServiceType;
    timestampX: number;
    iWasConnected?: boolean;
}

export const SessionConnectionButton = ({ appointmentId, appointmentType, timestampX, iWasConnected = false }: ISessionConnectionButtonProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const patientWorkspacePrepared = useSelector(selectPatientWorkspacePrepared);

    const [canConnect, setCanConnect] = useState(false);
    const [notActual, setNotActual] = useState(false);
    const [title, setTitle] = useState('');
    const [btnLabel, setBtnLabel] = useState('');

    useEffect(() => {
        const targetBtnLabel = canConnect
            ? t(`userMainPage.${appointmentType + 'Start'}.connectButton`)
            : t(`userMainPage.${appointmentType + 'Start'}.connectButtonCountdown`, { minutes: PATIENT_TIME_BEFORE_START_OF_SESSION / 60 });

        setBtnLabel(targetBtnLabel);
    }, [appointmentType, canConnect]);

    useEffect(() => {
        if (timestampX) {
            const dayJsDate = dayjs(timestampX);

            const targetTitle = t(`userMainPage.${appointmentType + 'Start'}.title`, {
                minutes: dayjs(dayJsDate).format(`D MMMM ${t('userMainPage.at')} HH:mm`),
            });
            setTitle(targetTitle);

            const timeToAppointment = dayJsDate.diff(dayjs()) / 1000 / 60; // in minutes
            setCanConnect(
                timeToAppointment <= PATIENT_TIME_BEFORE_START_OF_SESSION / 60 && timeToAppointment >= -PATIENT_TIME_AFTER_START_OF_SESSION / 60,
            );

            if (timeToAppointment > 0) {
                // if less than 1 hour -> update timer every minute
                if (timeToAppointment < 60) {
                    const timer = setInterval(() => {
                        const timeToAppointment = dayJsDate.diff(dayjs()) / 1000 / 60; // in minutes
                        setCanConnect(
                            timeToAppointment <= PATIENT_TIME_BEFORE_START_OF_SESSION / 60 &&
                                timeToAppointment >= -PATIENT_TIME_AFTER_START_OF_SESSION / 60,
                        );

                        const targetTitle = t(`userMainPage.${appointmentType + 'Start'}.title`, {
                            minutes: dayjs(dayJsDate).format(`D MMMM ${t('userMainPage.at')} HH:mm`),
                        });
                        setTitle(targetTitle);
                    }, 1000 * 60);

                    return () => {
                        clearInterval(timer);
                    };
                } else {
                    const targetTitle = t(`userMainPage.${appointmentType + 'Start'}.title`, {
                        minutes: dayjs(dayJsDate).format(`D MMMM ${t('userMainPage.at')} HH:mm`),
                    });
                    setTitle(targetTitle);
                }
            } else {
                if (timeToAppointment >= -PATIENT_TIME_AFTER_START_OF_SESSION / 60) {
                    setTitle(t(`userMainPage.${appointmentType + 'Start'}.alreadyStarted`));
                } else {
                    if (!iWasConnected) {
                        setNotActual(true);
                    }
                }
            }
        }
    }, [timestampX]);

    const connectToAppointment = () => {
        if (patientWorkspacePrepared) {
            navigate('/appointment?appointmentId=' + appointmentId);
        } else {
            alert(t("patientAppointmentItemPage.patient_settings_not_calibrated"));
        }
    };

    return !notActual ? (
        <div className="text-center">
            {canConnect && (
                <div className={styles.dinoReady}>
                    <div className={styles.bubble}>
                        <p>{t('userMainPage.ready')}</p>
                        <Bubble />
                    </div>
                    <Dino2_1 id="dinoReady" />
                </div>
            )}
            <p className={styles['procedure-title']}>{title}</p>
            <Button disabled={!canConnect} type={'primary'} className="mt-4" onClick={() => connectToAppointment()} size={'large'}>
                {btnLabel}
            </Button>
        </div>
    ) : null;
};
