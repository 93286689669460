import { CheckSuccess } from '@icons';
import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './AccountSuccessPage.module.scss';
import { Logo } from 'src/components/Logo/Logo';

interface IAccountSuccessPageProps {
    restore?: boolean;
}

export const AccountSuccessPage = ({ restore }: IAccountSuccessPageProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const enterHandler = () => {
        navigate('/');
    };

    return (
        <div className={styles.wrapper}>
            <Card>
                <div className={styles.contentLayout}>
                    <Logo marginBottom />
                    <CheckSuccess className={styles.check} />
                    <h4 className="mb-3">{t('AccountSuccess.title')}</h4>
                    <p className="mb-5">{restore ? t('AccountSuccess.passwordChanged') : t('AccountSuccess.accountCreated')}</p>
                    <Button className="w-100" type="primary" htmlType="submit" onClick={enterHandler}>
                        {t('AccountSuccess.labels.enter')}
                    </Button>
                </div>
            </Card>
        </div>
    );
};
