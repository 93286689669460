export enum ProcedureStatus {
    'notStarted' = 'notStarted',
    'started' = 'started',
    'inProgress' = 'inProgress',

    'paused' = 'paused',
    'continued' = 'continued',
    'stopped' = 'stopped',

    'finished' = 'finished',
    'failed' = 'failed',
    // 'timeOver' = 'timeOver',

    'finishedButNeedConfirmation' = 'finishedButNeedConfirmation',
    'waitingApproveToChangeGlasses' = 'waitingApproveToChangeGlasses',
    'changeGlasses' = 'changeGlasses',
}
