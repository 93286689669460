import { OptotypeButterflyIcon, OptotypeFishIcon, OptotypePlaneIcon, OptotypeRabbitIcon } from '@icons';
import styles from './DiagnosticImages.module.scss';

interface IDiagnosticImagesProps {
    size?: number;
    image?: string;
}

export const DiagnosticImages = ({ size = 14, image }: IDiagnosticImagesProps) => {
    const getTargetIcon = () => {
        switch (image) {
            case 'butterfly':
                return <OptotypeButterflyIcon style={{ width: size, height: size }} />;
            case 'fish':
                return <OptotypeFishIcon style={{ width: size, height: size }} />;
            case 'plane':
                return <OptotypePlaneIcon style={{ width: size, height: size }} />;
            case 'rabbit':
                return <OptotypeRabbitIcon style={{ width: size, height: size }} />;
            default:
                return null;
        }
    };

    return <div className={styles.wrapper}>{getTargetIcon()}</div>;
};
