import {
    AppointmentScheduleDTO,
    AvailableSlotDTOServiceType,
    GetAllPractitionersChiefComplaintCodesItem,
    getAvailableSlots,
} from '@api/mainServiceAPI';
import { LocalizationProvider, PickersDay } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { dateForServer, groupBy } from '@utils/utils';
import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';

import { IScheduleDate } from '@sliceConsultation';
import { END_DAY_TIME } from '@utils/constants';
import { useEffect, useState } from 'react';
import { IStepProps, ITimeSlot } from '../../ConsultationSignUpPage';
import styles from './DateTimeStep.module.scss';
import {useTranslation} from "react-i18next";

interface IDateTimeStepProps extends IStepProps {
    isDoctorFirst?: boolean;
    isContractSigned: boolean;
    diagnosis: GetAllPractitionersChiefComplaintCodesItem[];
}

const adapter = new AdapterDayjs();

export const DateTimeStep = ({ data, setData, onChangeStep, isDoctorFirst, isContractSigned, diagnosis = [] }: IDateTimeStepProps) => {
    const [availableSlots, setAvailableSlots] = useState<IScheduleDate[]>([]);
    const {t} = useTranslation();
    const [isDoctorsAvailable, setIsDoctorAvailable] = useState(false);
    const [morning, setMorning] = useState<ITimeSlot[]>();
    const [day, setDay] = useState<ITimeSlot[]>();
    const [evening, setEvening] = useState<ITimeSlot[]>();
    const [timeData, setTimeData] = useState<{ date: dayjs.Dayjs | undefined; time: ITimeSlot | undefined }>();

    useEffect(() => {
        setTimeData({ date: data?.date, time: data?.time });

        retrieveSlotsForMonth(
            dateForServer((data?.date || dayjs()).startOf('month').toDate(), true, true),
            dateForServer((data?.date || dayjs()).endOf('month').toDate(), true, true) + END_DAY_TIME,
            data?.date || dayjs(),
            data?.time,
        );
    }, [data]);

    const retrieveSlotsForMonth = (startDate: number, endDate: number, targetDate?: dayjs.Dayjs, targetTime?: ITimeSlot) => {
        getAvailableSlots([
            {
                startDate,
                endDate,
                practitionerId: isDoctorFirst ? (data?.doctor ? data.doctor.id : undefined) : undefined,
                serviceType: AvailableSlotDTOServiceType.consultation,
                roleCode: ['doctor'],
                diagnosis: diagnosis.length === 0 ? undefined : diagnosis,
            },
        ]).then((res) => {
            const mappedSlots = convertSlots(res.data);
            setAvailableSlots(mappedSlots);

            if (targetDate) {
                handleDateChange(mappedSlots, targetDate, targetTime);
            }
        });
    };

    const handleMonthChange = (date: dayjs.Dayjs | undefined) => {
        if (date) {
            retrieveSlotsForMonth(dateForServer(date.startOf('month').toDate()), dateForServer(date.endOf('month').toDate()) + END_DAY_TIME);
        }
    };

    const handleDateChange = (slots: IScheduleDate[], date: dayjs.Dayjs | null | undefined, time?: ITimeSlot) => {
        setTimeData({ date: date as dayjs.Dayjs | undefined, time });
        // setData({ ...data, date: date as dayjs.Dayjs | undefined, time }, isDoctorFirst ? 2 : 1);

        if (slots) {
            setIsDoctorAvailable(slots.some((x) => x.date === date?.format('YYYY-MM-DD')));
        }

        if (date) {
            const times = slots.find((x) => x.date === date.format('YYYY-MM-DD'))?.times;

            setMorning(times?.filter(({ time: x }) => x >= '00:00' && x < '12:00'));
            setDay(times?.filter(({ time: x }) => x >= '12:00' && x < '18:00'));
            setEvening(times?.filter(({ time: x }) => x >= '18:00' && x <= '24:00'));
        }
    };

    const handleSelectTime = (slot: ITimeSlot) => {
        setTimeData((data) => ({ date: data?.date, time: slot }));
        // setData({ ...data, time: slot }, isDoctorFirst ? 2 : 1);
    };

    const nextStep = () => {
        setData({ ...data, date: timeData?.date, time: timeData?.time }, isDoctorFirst ? 2 : 1);
        onChangeStep(isDoctorFirst ? 2 : 1);
    };

    const convertSlots = (slots: AppointmentScheduleDTO[]) => {
        const mappedSlots = slots.map((x) => ({
            ...x,
            dateTime: dayjs(x.dateTime),
            day: dayjs(x.dateTime).format('YYYY-MM-DD'),
            time: dayjs(x.dateTime).format('HH:mm'),
            slotId: x.slotId,
        }));

        const result: IScheduleDate[] = [];
        const grouppedByDay = groupBy(mappedSlots, 'day');

        if (grouppedByDay) {
            Object.keys(grouppedByDay).forEach((s) => {
                if (grouppedByDay[s].length) {
                    const slot: IScheduleDate = { date: s, times: [] };
                    grouppedByDay[s].forEach((sl: { time: string; slotId: string; practitionerId: string }) => {
                        if (sl.time) {
                            const existingTime = slot.times.find((t) => t.time === sl.time);
                            if (existingTime) {
                                existingTime.practitionersMap[sl.practitionerId] = sl.slotId;
                            } else {
                                slot.times.push({ time: sl.time, practitionersMap: { [sl.practitionerId]: sl.slotId } });
                            }
                        }
                    });
                    result.push(slot);
                }
            });
        }

        return result;
    };

    const shouldDisableYear = (date: dayjs.Dayjs | null | undefined) => {
        if (date) {
            return date.get('year') < dayjs().get('year');
        } else {
            return false;
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <p className={styles.title}>{t("date_time_step.select_date_time")}</p>
            <Row gutter={48}>
                <Col span={11}>
                    <CalendarPicker
                        shouldDisableYear={shouldDisableYear}
                        disabled={isContractSigned}
                        onMonthChange={(date) => handleMonthChange(date)}
                        date={timeData?.date}
                        onChange={(date) => handleDateChange(availableSlots, date)}
                        renderDay={(day, _value, DayComponentProps) => {
                            const hasAvailableDoctor = availableSlots.some((x) => x.date === (day || adapter.date(new Date())).format('YYYY-MM-DD'));
                            const inTheFuture = (day || adapter.date(new Date())) >= dayjs();
                            return (
                                <span className={hasAvailableDoctor ? (inTheFuture ? 'filled' : 'skipped') : ''} key={day?.toString()}>
                                    {<PickersDay {...DayComponentProps} />}
                                </span>
                            );
                        }}
                    />
                </Col>
                <Col span={13}>
                    <p className={styles.selectedDate}>{timeData?.date?.format('D MMMM, dddd')}</p>

                    {isDoctorsAvailable ? (
                        <>
                            {!!morning?.length && (
                                <div className="mb-4">
                                    <p className={styles.day}>{t("morning")}</p>
                                    <div className={styles.slots}>
                                        {morning.map((x, i) => (
                                            <div
                                                onClick={() => handleSelectTime(x)}
                                                key={`${x.time} - ${i}`}
                                                className={timeData?.time?.time === x.time ? styles['selected-time'] : styles.time}
                                            >
                                                {x.time}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {!!day?.length && (
                                <div className="mb-4">
                                    <p className={styles.day}>{t("afternoon")}</p>
                                    <div className={styles.slots}>
                                        {day.map((x, i) => (
                                            <div
                                                onClick={() => handleSelectTime(x)}
                                                key={`${x.time} - ${i}`}
                                                className={timeData?.time?.time === x.time ? styles['selected-time'] : styles.time}
                                            >
                                                {x.time}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {!!evening?.length && (
                                <div className="mb-4">
                                    <p className={styles.day}>{t("evening")}</p>
                                    <div className={styles.slots}>
                                        {evening.map((x, i) => (
                                            <div
                                                onClick={() => handleSelectTime(x)}
                                                key={`${x.time} - ${i}`}
                                                className={timeData?.time?.time === x.time ? styles['selected-time'] : styles.time}
                                            >
                                                {x.time}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>{t("date_time_step.no_slots")}</>
                    )}
                </Col>
            </Row>
            {timeData?.date && isDoctorsAvailable && (
                <div>
                    <div className={styles.divider} />
                    <Button onClick={() => nextStep()} disabled={!timeData?.time || isContractSigned} type="primary" size="large">
                        {t("date_time_step.approve_date_time")}
                    </Button>
                </div>
            )}
        </LocalizationProvider>
    );
};
