import { AboutPractitionerDTO, SlotStatusDto } from '@api/mainServiceAPI';

export interface IPractitionerState {
    practitioners: AboutPractitionerDTO[];
    slot: SlotStatusDto | undefined;
}

export interface IScheduleDate {
    date: string;
    times: { time: string; practitionersMap: { [id: string]: string } }[];
}

export const initialPractitionerState: IPractitionerState = {
    practitioners: [],
    slot: undefined,
};
