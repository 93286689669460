import { AVETISOV_TEXTS_SET, AVETISOV_TEXT_TYPE } from '@configProcedures';
import arbuzImg from '../../styles/images/procedures/avetisov_mats/arbuz.jpg';
import tiranozavrImg from '../../styles/images/procedures/avetisov_mats/tiranozavr.jpg';

import { Button } from 'antd';
import { useEffect, useRef, useState } from 'react';
import styles from './AvetisovMats.module.scss';
import {useTranslation} from "react-i18next";

interface IAvetisovMatsProps {
    fontSize: number;
    textType: AVETISOV_TEXT_TYPE;
}

export const AvetisovMats = ({ fontSize = 12, textType }: IAvetisovMatsProps) => {
    const [currentTextIndex, setCurrentTextIndex] = useState(-1);
    const [textsSet] = useState(AVETISOV_TEXTS_SET);
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const currentTextIndex = textsSet.indexOf(textType);
        setCurrentTextIndex(currentTextIndex || 0);
    }, [textType]);

    const goNextText = () => {
        let nextIndex = currentTextIndex + 1;
        if (!textsSet[nextIndex]) {
            nextIndex = 0;
        }

        wrapperRef.current?.scrollTo({ behavior: 'auto', top: 0 });
        setCurrentTextIndex(nextIndex);
    };

    const renderText = () => {
        switch (textsSet[currentTextIndex]) {
            case 'arbuz':
                return (
                    <>
                        <h2>{t("avetisov_mats_procedure.watermelon.title")}</h2>
                        <img src={arbuzImg} />
                        <p>
                            {t("avetisov_mats_procedure.watermelon.p1")}
                        </p>
                        <p>
                            {t("avetisov_mats_procedure.watermelon.p2")}
                        </p>
                        <ul>
                            <li>{t("avetisov_mats_procedure.watermelon.list.l1")}</li>
                            <li>{t("avetisov_mats_procedure.watermelon.list.l2")}</li>
                            <li>{t("avetisov_mats_procedure.watermelon.list.l3")}</li>
                        </ul>
                        <p></p>
                        <p>{t("avetisov_mats_procedure.watermelon.p3")}</p>
                        <p>
                            {t("avetisov_mats_procedure.watermelon.p4")}
                        </p>
                        <p>
                            {t("avetisov_mats_procedure.watermelon.p5")}
                        </p>
                        <p>
                            {t("avetisov_mats_procedure.watermelon.p6")}
                        </p>
                        <p>
                            {t("avetisov_mats_procedure.watermelon.p7")}</p>
                    </>
                );

            case 'tiranozavr':
                return (
                    <>
                        <h2>{t("avetisov_mats_procedure.dinosaur.title")}</h2>
                        <img src={tiranozavrImg} />
                        <p>
                            {t("avetisov_mats_procedure.dinosaur.p1")}
                        </p>
                        <p>
                            {t("avetisov_mats_procedure.dinosaur.p2")}
                        </p>
                        <p>
                            {t("avetisov_mats_procedure.dinosaur.p3")}
                        </p>
                        <p>
                            {t("avetisov_mats_procedure.dinosaur.p4")}
                        </p>
                        <p>
                            {t("avetisov_mats_procedure.dinosaur.p5")}
                        </p>
                        <p>
                            {t("avetisov_mats_procedure.dinosaur.p6")}
                        </p>
                    </>
                );

            default:
                break;
        }
    };

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            <div className={styles.textWrapper} style={{ fontSize: fontSize + 'pt' }}>
                {renderText()}

                <div className={styles.buttons}>
                    <Button type={'primary'} className="mt-4" onClick={() => goNextText()} size={'large'}>
                        {t("avetisov_mats_procedure.next_text_button")}
                    </Button>
                </div>
            </div>
        </div>
    );
};
