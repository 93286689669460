import { ProcedureTypeEnum } from '@enums';
import { BinocularSmall, CheckIconGreen } from '@icons';
import { Button, Col, Input, Row, Select } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DiagnosticBinocular.module.scss';

const { TextArea } = Input;
const { Option } = Select;

type VisType =
    | 'nature_of_vision_33_sm_without_glasses'
    | 'nature_of_vision_33_sm_with_glasses'
    | 'nature_of_vision_1_m_without_glasses'
    | 'nature_of_vision_1_m_with_glasses';

export const BinocularTypeOptions = ['binocular', 'simultaneous', 'alternating', 'impossible_to_determine', 'monocular_od', 'monocular_os'];

interface ISelectBinocularProps {
    value: string;
    label: string;
    key: VisType;
}

const regimesOptions = [
    {
        value: '1',
        label: "diagnostic_binocular.vision_33cm_without_glasses",
        key: 'nature_of_vision_33_sm_without_glasses',
    },
    {
        value: '2',
        label: "diagnostic_binocular.vision_33cm_with_glasses",
        key: 'nature_of_vision_33_sm_with_glasses',
    },
    {
        value: '3',
        label: "diagnostic_binocular.vision_1m_without_glasses",
        key: 'nature_of_vision_1_m_without_glasses',
    },
    {
        value: '4',
        label: "diagnostic_binocular.vision_1m_with_glasses",
        key: 'nature_of_vision_1_m_with_glasses',
    },
] as ISelectBinocularProps[];

export interface IDiagnosticBinocularResult {
    comment: string;
    nature_of_vision_33_sm_without_glasses: number;
    nature_of_vision_33_sm_with_glasses: number;
    nature_of_vision_1_m_without_glasses: number;
    nature_of_vision_1_m_with_glasses: number;
}

interface IDiagnosticBinocularProps {
    diagnosticChange?: (start: boolean, type?: ProcedureTypeEnum) => void;
    onSaveResults?: (results?: IDiagnosticBinocularResult) => void;
    onToggleCalibration?: (state: boolean) => void;
    isCalibrationReady?: boolean;
    onToggleCalibrationReady?: (ready: boolean) => void;
}

export const DiagnosticBinocular = ({
    diagnosticChange,
    onSaveResults,
    onToggleCalibration,
    isCalibrationReady,
    onToggleCalibrationReady,
}: IDiagnosticBinocularProps) => {
    const { t } = useTranslation();

    const [withGlasses, setWithGlasses] = useState(true);
    const [isCalibrationStarted, setIsCalibrationStarted] = useState<boolean>(false);
    const [selectedRegime, setSelectedRegime] = useState<ISelectBinocularProps>(regimesOptions[0]);
    const [regimesResults, setRegimesResults] = useState<{
        nature_of_vision_33_sm_without_glasses: { value: string; isSelected: boolean };
        nature_of_vision_33_sm_with_glasses: { value: string; isSelected: boolean };
        nature_of_vision_1_m_without_glasses: { value: string; isSelected: boolean };
        nature_of_vision_1_m_with_glasses: { value: string; isSelected: boolean };
    }>({
        nature_of_vision_33_sm_without_glasses: { value: '', isSelected: false },
        nature_of_vision_33_sm_with_glasses: { value: '', isSelected: false },
        nature_of_vision_1_m_without_glasses: { value: '', isSelected: false },
        nature_of_vision_1_m_with_glasses: { value: '', isSelected: false },
    });
    const [comment, setComment] = useState('');
    const [isStarted, setIsStarted] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        if (isCalibrationReady) {
            setIsCalibrationStarted(false);
        }
    }, [isCalibrationReady]);

    const handleStartDiagnostic = () => {
        setIsStarted(true);
        onToggleCalibrationReady!(true);
        const newRegimes = {
            ...regimesResults,
            [selectedRegime.key]: {
                ...regimesResults[selectedRegime.key],
                isSelected: false,
            },
        };

        setRegimesResults(newRegimes);
        diagnosticChange?.(true, ProcedureTypeEnum.DISSOCIATION);
    };

    const handleStopDiagnostic = () => {
        setIsStarted(false);
        onToggleCalibrationReady!(false);

        diagnosticChange?.(false);

        const allChecksCompleted = withGlasses
            ? Object.keys(regimesResults).every((x) => !!(regimesResults as any)[x].value)
            : Object.keys(regimesResults)
                  .filter((key) => key !== 'nature_of_vision_33_sm_with_glasses' && key !== 'nature_of_vision_1_m_with_glasses')
                  .every((x) => !!(regimesResults as any)[x].value);

        setIsCompleted(allChecksCompleted);
    };

    const handleChangeResults = (value: string, key: VisType) => {
        const newRegimesResult = {
            ...regimesResults,
            [key]: { ...regimesResults[key], value: value, label: t(selectedRegime.label) },
        };

        setRegimesResults(newRegimesResult);
        const allChecksCompleted = withGlasses
            ? Object.keys(newRegimesResult).every((x) => !!(newRegimesResult as any)[x].value)
            : Object.keys(newRegimesResult)
                  .filter((key) => key !== 'nature_of_vision_33_sm_with_glasses' && key !== 'nature_of_vision_1_m_with_glasses')
                  .every((x) => !!(newRegimesResult as any)[x].value);

        setIsCompleted(allChecksCompleted);
    };

    const isAllChecksCompleted = (): boolean => {
        return withGlasses
            ? Object.keys(regimesResults).every((x) => !!(regimesResults as any)[x].value)
            : Object.keys(regimesResults)
                  .filter((key) => key !== 'nature_of_vision_33_sm_with_glasses' && key !== 'nature_of_vision_1_m_with_glasses')
                  .every((x) => !!(regimesResults as any)[x].value);
    };

    const saveResults = () => {
        const results: any = {};

        Object.keys(regimesResults).forEach((x) => {
            results[x] = (regimesResults as any)[x].value || null;
        });

        const body = {
            comment,
            ...results,
        };

        onSaveResults?.(body);
    };

    const cancelDiagnostic = () => {
        onSaveResults?.();
        diagnosticChange?.(false);
    };

    const toggleCalibration = () => {
        const newState = !isCalibrationStarted;
        setIsCalibrationStarted(newState);
        onToggleCalibrationReady!(false);
        onToggleCalibration?.(newState);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <p className={styles.title}>{t("diagnostic_binocular.title")}</p>
                <Row>
                    <Col className={styles.left} span={9}>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <p className={styles.label}>{t("diagnostic_binocular.has_glasses")}</p>
                            <Select
                                value={withGlasses}
                                defaultValue={true}
                                className="w-25"
                                disabled={isStarted}
                                onChange={(value) => setWithGlasses(value)}
                                options={[
                                    { label: t("diagnostic_binocular.yes"), value: true },
                                    { label: t("diagnostic_binocular.no"), value: false },
                                ]}
                            />
                        </div>
                        <div className={classNames(styles.diagnostic, isStarted && styles.active)}>
                            {isStarted ? (
                                <BinocularSmall height={205} />
                            ) : !isAllChecksCompleted() ? (
                                <>{t("diagnostic_binocular.diagnostic_not_started")}</>
                            ) : (
                                <div className="d-flex flex-column align-items-center">
                                    <CheckIconGreen />
                                    <p className={styles.diagnosticResultText}>{t("diagnostic_binocular.diagnostic_end")}</p>
                                </div>
                            )}
                        </div>
                        <Button onClick={isStarted ? handleStopDiagnostic : handleStartDiagnostic} className="w-100 mt-2" type="primary">
                            {isStarted ? t("diagnostic_binocular.stop_button") : t("diagnostic_binocular.start_button")}
                        </Button>
                        <Button disabled={!isCalibrationReady} onClick={toggleCalibration} className="w-100 mt-2" type="default">
                            {isCalibrationStarted ? t("diagnostic_binocular.stop_calibration") : t("diagnostic_binocular.start_calibration")}
                        </Button>
                    </Col>
                    <Col span={1}>
                        <div className={styles.divider} />
                    </Col>
                    <Col span={14}>
                        <div className={styles['diagnostic-wrapper']}>
                            <div className={styles['selectors-content']}>
                                <div className="mb-5">
                                    <p className={styles['top-label']}>{t("diagnostic_binocular.vision_33cm_without_glasses")}</p>
                                    <Select
                                        disabled={!isStarted}
                                        value={regimesResults.nature_of_vision_33_sm_without_glasses.value || undefined}
                                        placeholder={t("diagnostic_binocular.wait_diagnostic")}
                                        className="w-75"
                                        onChange={(value) => handleChangeResults(value, 'nature_of_vision_33_sm_without_glasses')}
                                    >
                                        {BinocularTypeOptions.map((x) => (
                                            <Option key={x} value={x}>
                                                {t('binocularDiagnostic.binocularTypes.' + x)}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="mb-5">
                                    <p className={styles['top-label']}>{t("diagnostic_binocular.vision_33cm_with_glasses")}</p>
                                    <Select
                                        disabled={!isStarted || !withGlasses}
                                        value={regimesResults.nature_of_vision_33_sm_with_glasses.value || undefined}
                                        placeholder={t("diagnostic_binocular.wait_diagnostic")}
                                        className="w-75"
                                        onChange={(value) => handleChangeResults(value, 'nature_of_vision_33_sm_with_glasses')}
                                    >
                                        {BinocularTypeOptions.map((x) => (
                                            <Option key={x} value={x}>
                                                {t('binocularDiagnostic.binocularTypes.' + x)}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="mb-5">
                                    <p className={styles['top-label']}>{t("diagnostic_binocular.vision_1m_without_glasses")}</p>
                                    <Select
                                        disabled={!isStarted}
                                        value={regimesResults.nature_of_vision_1_m_without_glasses.value || undefined}
                                        placeholder={t("diagnostic_binocular.wait_diagnostic")}
                                        className="w-75"
                                        onChange={(value) => handleChangeResults(value, 'nature_of_vision_1_m_without_glasses')}
                                    >
                                        {BinocularTypeOptions.map((x) => (
                                            <Option key={x} value={x}>
                                                {t('binocularDiagnostic.binocularTypes.' + x)}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="mb-5">
                                    <p className={styles['top-label']}>{t("diagnostic_binocular.vision_1m_with_glasses")}</p>
                                    <Select
                                        disabled={!isStarted || !withGlasses}
                                        value={regimesResults.nature_of_vision_1_m_with_glasses.value || undefined}
                                        placeholder={t("diagnostic_binocular.wait_diagnostic")}
                                        className="w-75"
                                        onChange={(value) => handleChangeResults(value, 'nature_of_vision_1_m_with_glasses')}
                                    >
                                        {BinocularTypeOptions.map((x) => (
                                            <Option key={x} value={x}>
                                                {t('binocularDiagnostic.binocularTypes.' + x)}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="mb-4">
                                <p className={styles.label}>{t("diagnostic_binocular.comment")}</p>
                                <TextArea value={comment} onChange={(e) => setComment(e.target.value)} className="mt-1" rows={3} />
                            </div>
                            <Row gutter={32}>
                                <Col span={12}>
                                    {/* not for the first iteration */}
                                    <Button onClick={cancelDiagnostic} className="w-100">
                                        {t("diagnostic_binocular.cancel_button")}
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button onClick={saveResults} disabled={isStarted || !isCompleted} type="primary" className="w-100">
                                        {t("diagnostic_binocular.save_results_button")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
