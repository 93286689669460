import { EncounterDetailDTOPaymentStatus, EncounterDetailDTOStatus } from '@api/mainServiceAPI';
import { AppointmentStatus } from '@enums';
import { t } from 'i18next';
import { PropsWithChildren, useEffect, useState } from 'react';
import { getEncounterStatus } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/dictionary';
import styles from './EncounterStatusBadge.module.scss';

interface IEncounterStatusBadgeProps {
    encounterStatus?: EncounterDetailDTOStatus;
    paymentStatus?: EncounterDetailDTOPaymentStatus;
}

export const EncounterStatusBadge = ({ encounterStatus, paymentStatus }: PropsWithChildren<IEncounterStatusBadgeProps>) => {
    const [status, setStatus] = useState<AppointmentStatus>();

    useEffect(() => {
        setStatus(getEncounterStatus(encounterStatus, paymentStatus));
    }, [encounterStatus, paymentStatus]);

    return status ? (
        <div className={styles.statusField}>
            <div className={styles[status]}>{t(`callCenterManager.appointmentStatuses.${status}`)}</div>
        </div>
    ) : null;
};
