/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CarePlanDTO, ConsultationAppointmentDetailsDTO, GetPatientEncountersByParamsParams, PatientEncounterDTO } from '@api/mainServiceAPI';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LSScreenSettingsPatientSettings } from 'src/services';
import { RootState } from '../Store';
import { CreateUserProfileDTOExt, IEntityToCheckPayment, initialPatientState, IPatientState } from './state';

// Slice
export const patientSlice = createSlice({
    name: 'patient',
    initialState: initialPatientState,
    reducers: {
        addNewPatient: (state: IPatientState, action: PayloadAction<CreateUserProfileDTOExt>) => {},

        getPatientEncountersById: (
            state: IPatientState,
            action: PayloadAction<{ patientFhirId: string; params: GetPatientEncountersByParamsParams }>,
        ) => {},
        setPatientEncountersById: (state: IPatientState, action: PayloadAction<PatientEncounterDTO[]>) => {
            state.carePlanEncounters = action.payload.sort((a, b) => {
                return a.dateTime! - b.dateTime!;
            });
        },

        getPatientCarePlan: (state: IPatientState, action: PayloadAction<{ patientFhirId: string }>) => {},
        setPatientCarePlan: (state: IPatientState, action: PayloadAction<CarePlanDTO>) => {
            state.carePlan = action.payload;
        },

        getPatientActiveConsultations: (state: IPatientState, action: PayloadAction<{ patientFhirId: string }>) => {},
        setPatientActiveConsultations: (state: IPatientState, action: PayloadAction<ConsultationAppointmentDetailsDTO[]>) => {
            state.activeConsultations = action.payload;
        },

        setPatientWorkspacePrepared: (state: IPatientState, action: PayloadAction<boolean>) => {
            state.workspacePrepared = action.payload;
        },
        setPatientWorkspaceSettings: (state: IPatientState, action: PayloadAction<LSScreenSettingsPatientSettings | null>) => {
            state.workspaceSettings = action.payload;
        },

        getEntityToCheckPayment: (state: IPatientState) => {},

        setEntityToCheckPayment: (state: IPatientState, action: PayloadAction<IEntityToCheckPayment | null>) => {
            state.entityToCheckPayment = action.payload;
        },
    },
});

// Actions
export const {
    addNewPatient,
    getPatientEncountersById,
    setPatientEncountersById,
    getPatientCarePlan,
    setPatientCarePlan,
    getPatientActiveConsultations,
    setPatientActiveConsultations,
    setPatientWorkspacePrepared,
    setPatientWorkspaceSettings,
    getEntityToCheckPayment,
    setEntityToCheckPayment,
} = patientSlice.actions;

// Reducer
export const patientReducer = patientSlice.reducer;

// Selectors
export const selectCarePlan = (state: RootState) => state.patientReducer.carePlan;
export const selectCarePlanEncounters = (state: RootState) => state.patientReducer.carePlanEncounters;
export const selectActiveConsultations = (state: RootState) => state.patientReducer.activeConsultations;
export const selectPatientWorkspacePrepared = (state: RootState) => state.patientReducer.workspacePrepared;
export const selectPatientWorkspaceSettings = (state: RootState) => state.patientReducer.workspaceSettings;
export const selectEntityToCheckPayment = (state: RootState) => state.patientReducer.entityToCheckPayment;
