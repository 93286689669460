import { PROCEDURE_SETTINGS_TYPES, PROCEDURE_SETTING_PROPS_DICTIONARY } from '@configProcedures';
import { ProcedureTypeEnum } from '@enums';
import { ProcedureParamType, ProcedureSettingsValueType } from '@models';
import { CoursePackageType } from '@sliceProcedures';
import { CourseDevice } from 'src/enums/course-device.enum';
import { CourseDiagnosticEnum } from './enums/course-diagnostic.enum';
import { IOcclusionDay } from './pages/_shared/DoctorAppointmentPage/sessions/ConsultationSession/steps/CourseStep/CourseStep';

export const COURSE_MAX_DAYS = 10;

export const CourseProceduresList: { [key in CoursePackageType]: ProcedureTypeEnum[] } = {
    MYOPIA_TREATMENT_PACKAGE: [ProcedureTypeEnum.AVETISOV_MATS, ProcedureTypeEnum.RECOVER_CILLIAR, ProcedureTypeEnum.MAKULOSTIMULATION],
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: [
        ProcedureTypeEnum.CHESS_PATTERN,
        ProcedureTypeEnum.MERIDIONAL_PATTERN,
        ProcedureTypeEnum.MAKULOSTIMULATION,
        ProcedureTypeEnum.PLEOPTIC_SPIRALS,
        ProcedureTypeEnum.PLEOPTIC_CROSS,
        ProcedureTypeEnum.FLASHES,
        ProcedureTypeEnum.RESTORATION_OF_FUSION,
    ],
    STRABISMUS_TREATMENT_PACKAGE: [
        ProcedureTypeEnum.DISSOCIATION,
        ProcedureTypeEnum.BIFIXATION_RECOVERY,
        ProcedureTypeEnum.AMBLYOPIA,
        ProcedureTypeEnum.RESTORATION_OF_FUSION,
    ],
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: [
        ProcedureTypeEnum.AVETISOV_MATS,
        ProcedureTypeEnum.RECOVER_CILLIAR,
        ProcedureTypeEnum.MAKULOSTIMULATION,
        ProcedureTypeEnum.CHESS_PATTERN,
        ProcedureTypeEnum.MERIDIONAL_PATTERN,
        ProcedureTypeEnum.PLEOPTIC_SPIRALS,
        ProcedureTypeEnum.PLEOPTIC_CROSS,
        ProcedureTypeEnum.FLASHES,
        ProcedureTypeEnum.RESTORATION_OF_FUSION,
    ],
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: [
        ProcedureTypeEnum.AVETISOV_MATS,
        ProcedureTypeEnum.RECOVER_CILLIAR,
        ProcedureTypeEnum.MAKULOSTIMULATION,
        ProcedureTypeEnum.DISSOCIATION,
        ProcedureTypeEnum.BIFIXATION_RECOVERY,
        ProcedureTypeEnum.AMBLYOPIA,
        ProcedureTypeEnum.RESTORATION_OF_FUSION,
    ],
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: [
        ProcedureTypeEnum.CHESS_PATTERN,
        ProcedureTypeEnum.MERIDIONAL_PATTERN,
        ProcedureTypeEnum.MAKULOSTIMULATION,
        ProcedureTypeEnum.PLEOPTIC_SPIRALS,
        ProcedureTypeEnum.PLEOPTIC_CROSS,
        ProcedureTypeEnum.DISSOCIATION,
        ProcedureTypeEnum.BIFIXATION_RECOVERY,
        ProcedureTypeEnum.AMBLYOPIA,
        ProcedureTypeEnum.RESTORATION_OF_FUSION,
        ProcedureTypeEnum.FLASHES,
    ],
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: [
        ProcedureTypeEnum.AVETISOV_MATS,
        ProcedureTypeEnum.RECOVER_CILLIAR,
        ProcedureTypeEnum.MAKULOSTIMULATION,
        ProcedureTypeEnum.CHESS_PATTERN,
        ProcedureTypeEnum.MERIDIONAL_PATTERN,
        ProcedureTypeEnum.PLEOPTIC_SPIRALS,
        ProcedureTypeEnum.PLEOPTIC_CROSS,
        ProcedureTypeEnum.DISSOCIATION,
        ProcedureTypeEnum.BIFIXATION_RECOVERY,
        ProcedureTypeEnum.AMBLYOPIA,
        ProcedureTypeEnum.RESTORATION_OF_FUSION,
        ProcedureTypeEnum.FLASHES,
    ],
};

export const DiagnosticTypeOfCourse: { [key in CoursePackageType]: CourseDiagnosticEnum } = {
    MYOPIA_TREATMENT_PACKAGE: CourseDiagnosticEnum.MYOPIA,
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: CourseDiagnosticEnum.STRABISMUS,
    STRABISMUS_TREATMENT_PACKAGE: CourseDiagnosticEnum.STRABISMUS,
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: CourseDiagnosticEnum.STRABISMUS,
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: CourseDiagnosticEnum.STRABISMUS,
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: CourseDiagnosticEnum.STRABISMUS,
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: CourseDiagnosticEnum.STRABISMUS,
};

export const CoursesDevices: { [key in CoursePackageType]: CourseDevice[] } = {
    MYOPIA_TREATMENT_PACKAGE: [CourseDevice.MYOPIA_TREATMENT_KIT],
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: [CourseDevice.AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT],
    STRABISMUS_TREATMENT_PACKAGE: [CourseDevice.STRABISMUS_TREATMENT_KIT],
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: [
        CourseDevice.MYOPIA_TREATMENT_KIT,
        CourseDevice.AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT,
    ],
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: [CourseDevice.MYOPIA_TREATMENT_KIT, CourseDevice.STRABISMUS_TREATMENT_KIT],
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: [CourseDevice.STRABISMUS_TREATMENT_KIT],
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: [CourseDevice.MYOPIA_TREATMENT_KIT, CourseDevice.STRABISMUS_TREATMENT_KIT],
};

export const OcclusionList = ['without', 'od1os1', 'od2os1', 'od3os1', 'od1os2', 'od1os3', 'od2os3', 'od3os2', 'manually'];
export type OcclusionType = 'without' | 'od1os1' | 'od2os1' | 'od3os1' | 'od1os2' | 'od1os3' | 'od2os3' | 'od3os2' | 'manually';

export const getOcclusionByDays = (durationInDays: number, occlusion?: OcclusionType): IOcclusionDay[] => {
    if (!occlusion || occlusion === 'without' || occlusion === 'manually') {
        return [];
    }

    const odDay = +occlusion[2];
    const osDay = +occlusion[5];

    const result: Array<'od' | 'os'> = [];

    let currentOcclusion: 'od' | 'os' = 'od';
    let currentOcclusionMax = odDay;
    let currentOcclusionDay = 1;

    for (let i = 1; i <= durationInDays; i++) {
        result.push(currentOcclusion);

        if (currentOcclusionDay >= currentOcclusionMax) {
            currentOcclusion = currentOcclusion === 'od' ? 'os' : 'od';
            currentOcclusionMax = currentOcclusion === 'od' ? odDay : osDay;
            currentOcclusionDay = 1;
        } else {
            currentOcclusionDay++;
        }
    }

    return result.map((x, i) => ({ day: i + 1, type: x }));
};

export const GET_DEFAULT_PROCEDURE_PARAMS = (procedureType: ProcedureTypeEnum): Partial<ProcedureSettingsValueType> => {
    const propsDictionary = PROCEDURE_SETTING_PROPS_DICTIONARY(procedureType);

    const defaultValues: Partial<ProcedureSettingsValueType> = {};

    // default value - is the first value in dictionary
    Object.keys(propsDictionary).forEach((k) => {
        const key = k as ProcedureParamType;

        const targetProps = PROCEDURE_SETTINGS_TYPES[procedureType];
        if (targetProps.includes(key)) {
            defaultValues[key] = propsDictionary[key]?.values[0];
        }
    });

    // set specific default values, if necessary
    switch (procedureType) {
        case ProcedureTypeEnum.AVETISOV_MATS:
            defaultValues.fontSize = 12;
            defaultValues.duration = 120;
            break;
        case ProcedureTypeEnum.MAKULOSTIMULATION:
            defaultValues.duration = 360;
            defaultValues.color = '#ff0000';
            break;
        case ProcedureTypeEnum.RECOVER_CILLIAR:
            defaultValues.duration = 360;
            break;
        case ProcedureTypeEnum.CHESS_PATTERN:
            defaultValues.duration = 360;
            break;
        case ProcedureTypeEnum.MERIDIONAL_PATTERN:
            defaultValues.duration = 360;
            defaultValues.frequency = 2;
            break;
        case ProcedureTypeEnum.FLASHES:
            defaultValues.duration = 360;
            defaultValues.frequency = 2;
            break;
        case ProcedureTypeEnum.PLEOPTIC_CROSS:
            defaultValues.duration = 360;
            break;
        case ProcedureTypeEnum.PLEOPTIC_SPIRALS:
            defaultValues.duration = 360;
            break;
        case ProcedureTypeEnum.AMBLYOPIA:
            defaultValues.duration = 60;
            defaultValues.accuracy = 5;
            defaultValues.lensFrom = '+4.0';
            defaultValues.lensTo = '-2.0';
            break;
        case ProcedureTypeEnum.RESTORATION_OF_FUSION:
            defaultValues.duration = 360;
            defaultValues.accuracy = 5;
            defaultValues.amblyopia = 'yes';
            break;
        case ProcedureTypeEnum.DISSOCIATION:
            defaultValues.lensFrom = '+4.0';
            defaultValues.lensTo = '-2.0';
            break;
        default:
            break;
    }

    return defaultValues;
};
