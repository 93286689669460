import { Button, Modal } from 'antd';
import { t } from 'i18next';
import styles from './CheckNotExistModal.module.scss';

export interface ICheckNotExistModalProps {
    visible: boolean;
    onClose: () => void;
}

export const CheckNotExistModal = ({ visible, onClose }: ICheckNotExistModalProps) => {
    return visible ? (
        <Modal
            className={styles.checkNotExistModal}
            width={592}
            open={visible}
            onCancel={onClose}
            closable={false}
            title={t('modals.checkNotExist.title')}
            footer={[
                <Button onClick={onClose} key="support">
                    {t('modals.buttons.close')}
                </Button>,
            ]}
        >
            <>
                <p style={{ marginTop: 0 }}>
                    {t("payment.payment_in_process")}
                </p>
            </>
        </Modal>
    ) : null;
};
