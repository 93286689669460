import {SettingsHelpBlock} from '@components';
import {DeviceKindEnum} from '@enums';
import {AUDIO_SAMPLE_LINK} from '@utils/constants';
import {Button, message, Modal, Progress} from 'antd';
import classNames from 'classnames';
import React, {ChangeEvent, MutableRefObject, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DocAudioVideoSettingsLocalStorage, ScreenSettingsLocalStorage} from 'src/services';
import styles from './AudioVideoSettingsModal.module.scss';
import {ca} from "date-fns/locale";

interface IAudioVideoSettingProps {
    showModal: boolean;
    onCancel: () => void;
    onSuccess: (value: {
        audioInput: MediaDeviceInfo | undefined;
        audioOutput: MediaDeviceInfo | undefined;
        video: MediaDeviceInfo | undefined;
    }) => void;
    audioInput: MediaDeviceInfo | undefined;
    audioOutput: MediaDeviceInfo | undefined;
    videoInput: MediaDeviceInfo | undefined;
    backButton?: boolean;
    isPatient?: boolean;
    patientId?: string;
}

export const AudioVideoSettingsModal = ({
                                            showModal,
                                            onCancel,
                                            onSuccess,
                                            audioInput,
                                            audioOutput,
                                            videoInput,
                                            backButton = true,
                                            isPatient = false,
                                            patientId,
                                        }: IAudioVideoSettingProps) => {
    const {t} = useTranslation();
    const audio = useMemo<HTMLAudioElement>(() => new Audio(AUDIO_SAMPLE_LINK), []);
    const video: MutableRefObject<HTMLVideoElement | null> = React.useRef(null);

    const [videoTrack, setVideoTrack] = useState<MediaStream | null>(null);
    const [audioInputTrack, setAudioInputTrack] = useState<MediaStream | null>(null);
    const [audioOutputTrack, setAudioOutputTrack] = useState<MediaStream | null>(null);

    const [micScale, setMicScale] = useState<number>(0);
    const [audioInputDevices, setAudioInputMicDevices] = useState<MediaDeviceInfo[]>([]);
    const [audioOutputDevices, setAudioOutputMicDevices] = useState<MediaDeviceInfo[]>([]);
    const [videoInputDevices, setVideoInputMicDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState<MediaDeviceInfo | undefined>(audioInput);
    const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState<MediaDeviceInfo | undefined>(audioOutput);
    const [selectedVideoInputDevice, setSelectedVideoInputDevice] = useState<MediaDeviceInfo | undefined>(videoInput);

    useEffect(() => {
        if (showModal) {
            (async () => {
                if (navigator.mediaDevices.getUserMedia !== null) {
                    try {
                        await navigator.mediaDevices.enumerateDevices().then((devices) => {
                            loadAllDevices(devices);
                            const videoAudio = isPatient ? ScreenSettingsLocalStorage.getScreenSettings(patientId)?.videoAudio : DocAudioVideoSettingsLocalStorage.getSettings();
                            if (videoAudio) {
                                videoAudio.video ? setDevice(videoAudio.video) : setDevice(findDefaultDevice(devices, DeviceKindEnum.VideoInput));
                                videoAudio.audioInput ? setDevice(videoAudio.audioInput) : setDevice(findDefaultDevice(devices, DeviceKindEnum.AudioInput));
                                videoAudio.audioOutput ? setDevice(videoAudio.audioOutput) : setDevice(findDefaultDevice(devices, DeviceKindEnum.AudioOutput));
                            } else {
                                setDevice(findDefaultDevice(devices, DeviceKindEnum.VideoInput));
                                setDevice(findDefaultDevice(devices, DeviceKindEnum.AudioInput));
                                setDevice(findDefaultDevice(devices, DeviceKindEnum.AudioOutput));
                            }

                        });
                    } catch (err) {
                        console.log(err);
                    }
                }
            })();
        }
    }, [showModal]);

    const loadAllDevices = (devices: MediaDeviceInfo[]) => {
        setVideoInputMicDevices(devices.filter((device) => device.kind === DeviceKindEnum.VideoInput));
        setAudioInputMicDevices(devices.filter((device) => device.kind === DeviceKindEnum.AudioInput));
        setAudioOutputMicDevices(devices.filter((device) => device.kind === DeviceKindEnum.AudioOutput));
    }

    const findDefaultDevice = (devices: MediaDeviceInfo[], kind: string): MediaDeviceInfo => {
        const find = devices.filter(f => f.kind === kind)!;
        return find[0];
    }

    const setDevice = (deviceInfo: MediaDeviceInfo) => {
        const deviceType = deviceInfo.kind;
        switch (deviceType) {
            case DeviceKindEnum.VideoInput:
                setSelectedVideoInputDevice(deviceInfo);
                callVideoStream(deviceInfo);
                break;
            case DeviceKindEnum.AudioInput:
                setSelectedAudioInputDevice(deviceInfo);
                callAudioStream(deviceInfo);
                break;
            case DeviceKindEnum.AudioOutput:
                setSelectedAudioOutputDevice(deviceInfo);
                callAudioStream(deviceInfo);
                break;
        }
    }

    const stopCurrentMediaStream = (device: MediaDeviceInfo | null) => {
        if (videoTrack && (device == null || device.kind === DeviceKindEnum.VideoInput)) {
            videoTrack.getVideoTracks()[0].stop();
        }
        if (audioInputTrack && (device == null || device.kind === DeviceKindEnum.AudioInput)) {
            audioInputTrack.getAudioTracks()[0].stop();
        }
        if (audioOutputTrack && (device == null || device.kind === DeviceKindEnum.AudioOutput)) {
            audioOutputTrack.getAudioTracks()[0].stop();
        }
    }

    const callVideoStream = async (device: MediaDeviceInfo) => {
        try {
            stopCurrentMediaStream(device);

            const deviceId = device.deviceId;
            const streamVideo = await navigator.mediaDevices.getUserMedia({
                video: {advanced: [{deviceId: deviceId}]},
                audio: false
            });
            if (video.current) {
                setVideoTrack(streamVideo);
                video.current.srcObject = streamVideo;
            }
        } catch (err) {
            message.error(
                t("media_devices_settings_modal.video_is_busy")
            );
        }
    }

    const callAudioStream = async (device: MediaDeviceInfo) => {
        try {
            stopCurrentMediaStream(device);

            const deviceId = device.deviceId;
            const streamAudio = await navigator.mediaDevices.getUserMedia({
                audio: {advanced: [{deviceId: deviceId}]},
                video: false
            });

            if (device.kind === DeviceKindEnum.AudioInput) {
                setAudioInputTrack(streamAudio);
            } else if (device.kind === DeviceKindEnum.AudioOutput) {
                setAudioOutputTrack(streamAudio);
            }

            const audioCtx = new AudioContext();

            const analyser = audioCtx.createAnalyser();
            analyser.fftSize = 32;
            analyser.smoothingTimeConstant = 0.2;
            const audioSrc = audioCtx.createMediaStreamSource(streamAudio as MediaStream);
            audioSrc.connect(analyser);
            const spectrum = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(spectrum);

            const loopingFunction = () => {
                requestAnimationFrame(loopingFunction);
                analyser.getByteFrequencyData(spectrum);
                setMicScale((spectrum[0] * 100) / 255);
            };

            requestAnimationFrame(loopingFunction);
        } catch (err) {
            message.error(
                t("media_devices_settings_modal.audio_is_busy")
            );
        }
    }

    const handleChangeDevice = (e: ChangeEvent<HTMLSelectElement>) => {
        const {value, name} = e.target;
        const device = [...videoInputDevices, ...audioInputDevices, ...audioOutputDevices].find((device) => device.deviceId === value && device.kind === name)!;
        setDevice(device);
    };

    const soundOn = () => {
        audio.play();
    };

    const success = () => {
        onSuccess({
            audioInput: selectedAudioInputDevice,
            audioOutput: selectedAudioOutputDevice,
            video: selectedVideoInputDevice,
        });
        stopCurrentMediaStream(null);
    };

    const cancel = () => {
        stopCurrentMediaStream(null);
        onCancel();
    }

    return (
        <Modal
            width={1000}
            title={t('modals.colorSettings.title')}
            open={showModal}
            onCancel={cancel}
            footer={[
                <SettingsHelpBlock key="support"/>,
                <div key="block" className="d-flex">
                    {backButton && (
                        <Button onClick={cancel} key="back">
                            {t('modals.buttons.back')}
                        </Button>
                    )}
                    <Button onClick={success} key="submit" type="primary">
                        {t('modals.buttons.ready')}
                    </Button>
                </div>,
            ]}
        >
            <div className={`${styles.content} mb-2 px-5`}>
                <div className="text-center mb-4">
                    <p>{t('modals.colorSettings.subtitle')}</p>
                    <a className={styles.link} href="">
                        {t('modals.colorSettings.link')}
                    </a>
                </div>
                <div className="d-flex justify-content-between mt-5">
                    <div className="col-5">
                        <h3 className={styles['setting-title']}>{t('audioVideoSettings.video.title')}</h3>
                        <select
                            className={classNames(styles.selector, 'mb-3')}
                            name={DeviceKindEnum.VideoInput}
                            onChange={handleChangeDevice}
                            value={selectedVideoInputDevice?.deviceId || ''}
                        >
                            {videoInputDevices?.map((device, i) => (
                                <option value={device.deviceId} key={i}>
                                    {device.label}
                                </option>
                            ))}
                        </select>
                        <div className={styles.video}>
                            <video ref={video} autoPlay/>
                        </div>
                        <p>{t('audioVideoSettings.video.subtitle')}</p>
                    </div>
                    <div className="col-5 d-flex flex-column justify-content-between mb-4">
                        <div className="mb-5 w-100">
                            <h3 className={styles['setting-title']}>{t('audioVideoSettings.mic.title')}</h3>
                            <select
                                className={styles.selector}
                                name={DeviceKindEnum.AudioInput}
                                onChange={handleChangeDevice}
                                value={selectedAudioInputDevice?.deviceId || ''}
                            >
                                {audioInputDevices?.map((device, i) => (
                                    <option value={device.deviceId} key={i}>
                                        {device.label}
                                    </option>
                                ))}
                            </select>
                            <Progress className={styles.progressBar} showInfo={false} percent={micScale} steps={10}/>
                            <p>{t('audioVideoSettings.mic.subtitle')}</p>
                        </div>
                        <div>
                            <h3 className={styles['setting-title']}>{t('audioVideoSettings.audio.title')}</h3>
                            <select
                                className={styles.selector}
                                name={DeviceKindEnum.AudioOutput}
                                onChange={handleChangeDevice}
                                value={selectedAudioOutputDevice?.deviceId || ''}
                            >
                                {audioOutputDevices?.map((device, i) => (
                                    <option value={device.deviceId} key={i}>
                                        {device.label}
                                    </option>
                                ))}
                            </select>
                            <audio/>
                            <Button className="mt-4 mb-3" onClick={soundOn}>
                                {t('modals.colorSettings.checkSound')}
                            </Button>
                            <p>{t('audioVideoSettings.audio.subtitle')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};