import { AppointmentParticipantDetailsDTOCourseType } from '@api/mainServiceAPI';
import { CalendarInputIcon } from '@icons';
import { Button, Checkbox, DatePicker, Form, Input, message } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './RecommendationsStep.module.scss';
import {
    AdditionalExaminationsEnum,
    DynamicObservationEnum,
    DynamicsRecommendationsEnum,
    LifestyleRecommendationsEnum,
    RecommendationsForConsultingAdditionalSpecialistsEnum,
} from './dictionary';

type RecommendationValueType =
    | DynamicsRecommendationsEnum
    | LifestyleRecommendationsEnum
    | DynamicObservationEnum
    | AdditionalExaminationsEnum
    | RecommendationsForConsultingAdditionalSpecialistsEnum;
interface IRecommendationOption {
    value: RecommendationValueType;
    comment: string;
    selected: boolean;
}

export interface IRecommendationsForm {
    //notes?: string;

    dynamicsRecommendations?: IRecommendationOption[];
    lifestyleRecommendations?: IRecommendationOption[];
    dynamicObservation?: IRecommendationOption[];
    additionalExaminations?: IRecommendationOption[];
    recommendationsForConsultingAdditionalSpecialists?: IRecommendationOption[];

    dateRepeatedConsultation?: dayjs.Dayjs | string;
    datePlanedTherapy?: dayjs.Dayjs | string;
    datePlanedOperation?: dayjs.Dayjs | string;
    datePlanedObservation?: dayjs.Dayjs | string;
    datePlanedRepeatedAndTherapy?: dayjs.Dayjs | string;
}

interface IRecommendationsStepProps {
    initialValues?: IRecommendationsForm;
    disabled?: boolean;
    courseType?: AppointmentParticipantDetailsDTOCourseType;

    onSubmit: (formValue: IRecommendationsForm) => void;
    onFormChange: (formValue: IRecommendationsForm) => void;
}

export const RecommendationsStep = ({ onSubmit, onFormChange, initialValues, courseType, disabled }: IRecommendationsStepProps) => {
    const { t } = useTranslation();
    const [form] = useForm<IRecommendationsForm>();

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const formSubmitHandler = (values: IRecommendationsForm) => {
        if (
            courseType === 'final_consultation' &&
            !(
                values.dateRepeatedConsultation ||
                values.datePlanedTherapy ||
                values.datePlanedOperation ||
                values.datePlanedObservation ||
                values.datePlanedRepeatedAndTherapy
            )
        ) {
            message.error(t("recommendations_step.no_date_selection"));
            return;
        }

        onSubmit(values);
    };

    // Ant doesn't rerender the whole form  if some field changes (even with shouldUpdate)
    // https://nanxiaobei.medium.com/watch-a-more-elegant-way-to-monitor-antd-form-field-changes-7c9b12457d67

    const dynamicsRecommendationsValue = useWatch('dynamicsRecommendations', form);
    const lifestyleRecommendationsValue = useWatch('lifestyleRecommendations', form);
    const dynamicObservationValue = useWatch('dynamicObservation', form);
    const additionalExaminationsValue = useWatch('additionalExaminations', form);
    const recommendationsForConsultingAdditionalSpecialistsValue = useWatch('recommendationsForConsultingAdditionalSpecialists', form);

    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>{t('consultationSession.recommendationsStep.title')}</h2>

            <Form
                form={form}
                autoComplete="off"
                onFinish={formSubmitHandler}
                onValuesChange={(_, all) => onFormChange(all)}
                layout="vertical"
                disabled={disabled}
            >
                {courseType === 'final_consultation' && (
                    <>
                        <p className={styles.groupLabel}>{t('enums.DynamicsRecommendationsEnum.title')}</p>
                        <Form.List name="dynamicsRecommendations">
                            {(fields) =>
                                fields.map((field) => (
                                    <div key={field.key} className={styles.checkboxGroup}>
                                        <Form.Item name={[field.name, 'selected']} valuePropName="checked">
                                            <Checkbox>
                                                {t(`enums.DynamicsRecommendationsEnum.${dynamicsRecommendationsFormArray[field.key].value}`)}
                                            </Checkbox>
                                        </Form.Item>

                                        {dynamicsRecommendationsValue?.[field.key]?.selected && (
                                            <Form.Item name={[field.name, 'comment']}>
                                                <Input placeholder={t("placeholder_comment")} />
                                            </Form.Item>
                                        )}
                                    </div>
                                ))
                            }
                        </Form.List>
                    </>
                )}
                <p className={styles.groupLabel}>{t('enums.LifestyleRecommendationsEnum.title')}</p>
                <Form.List name="lifestyleRecommendations">
                    {(fields) =>
                        fields.map((field) => (
                            <div key={field.key} className={styles.checkboxGroup}>
                                <Form.Item name={[field.name, 'selected']} valuePropName="checked">
                                    <Checkbox>
                                        {t(`enums.LifestyleRecommendationsEnum.${lifestyleRecommendationsFormArray[field.key].value}`)}
                                    </Checkbox>
                                </Form.Item>

                                {lifestyleRecommendationsValue?.[field.key]?.selected && (
                                    <Form.Item name={[field.name, 'comment']}>
                                        <Input placeholder={t("placeholder_comment")} />
                                    </Form.Item>
                                )}
                            </div>
                        ))
                    }
                </Form.List>
                <p className={styles.groupLabel}>{t('enums.DynamicObservationEnum.title')}</p>
                <Form.List name="dynamicObservation">
                    {(fields) =>
                        fields.map((field) => (
                            <div key={field.key} className={styles.checkboxGroup}>
                                <Form.Item name={[field.name, 'selected']} valuePropName="checked">
                                    <Checkbox>{t(`enums.DynamicObservationEnum.${dynamicObservationFormArray[field.key].value}`)}</Checkbox>
                                </Form.Item>

                                {dynamicObservationValue?.[field.key]?.selected && (
                                    <Form.Item name={[field.name, 'comment']}>
                                        <Input placeholder={t("placeholder_comment")} />
                                    </Form.Item>
                                )}
                            </div>
                        ))
                    }
                </Form.List>
                <p className={styles.groupLabel}>{t('enums.AdditionalExaminationsEnum.title')}</p>
                <Form.List name="additionalExaminations">
                    {(fields) =>
                        fields.map((field) => (
                            <div key={field.key} className={styles.checkboxGroup}>
                                <Form.Item name={[field.name, 'selected']} valuePropName="checked">
                                    <Checkbox>{t(`enums.AdditionalExaminationsEnum.${additionalExaminationsFormArray[field.key].value}`)}</Checkbox>
                                </Form.Item>

                                {additionalExaminationsValue?.[field.key]?.selected && (
                                    <Form.Item name={[field.name, 'comment']}>
                                        <Input placeholder={t("placeholder_comment")} />
                                    </Form.Item>
                                )}
                            </div>
                        ))
                    }
                </Form.List>
                <p className={styles.groupLabel}>{t('enums.RecommendationsForConsultingAdditionalSpecialistsEnum.title')}</p>
                <Form.List name="recommendationsForConsultingAdditionalSpecialists">
                    {(fields) =>
                        fields.map((field) => (
                            <div key={field.key} className={styles.checkboxGroup}>
                                <Form.Item name={[field.name, 'selected']} valuePropName="checked">
                                    <Checkbox>
                                        {t(
                                            `enums.RecommendationsForConsultingAdditionalSpecialistsEnum.${
                                                recommendationsForConsultingAdditionalSpecialistsFormArray[field.key].value
                                            }`,
                                        )}
                                    </Checkbox>
                                </Form.Item>

                                {recommendationsForConsultingAdditionalSpecialistsValue?.[field.key]?.selected && (
                                    <Form.Item name={[field.name, 'comment']}>
                                        <Input placeholder={t("placeholder_comment")} />
                                    </Form.Item>
                                )}
                            </div>
                        ))
                    }
                </Form.List>
                {/*<p className={styles.groupLabel}>{t("recommendations_step.notes")}</p>
                <Form.Item name="notes" className={styles.formGroup}>
                    <TextArea />
                </Form.Item>*/}

                {courseType === 'final_consultation' && (
                    <div className={styles.formGrid22}>
                        <label>{t("recommendations_step.repeated_consultation_date")}</label>
                        <Form.Item name="dateRepeatedConsultation">
                            <DatePicker
                                placeholder={t("placeholder_select_date")}
                                className="topIndex"
                                suffixIcon={<CalendarInputIcon />}
                                popupClassName="topIndex"
                            />
                        </Form.Item>

                        <label>{t("recommendations_step.planned_procedures_date")}</label>
                        <Form.Item name="datePlanedTherapy">
                            <DatePicker
                                placeholder={t("placeholder_select_date")}
                                className="topIndex"
                                suffixIcon={<CalendarInputIcon />}
                                popupClassName="topIndex"
                            />
                        </Form.Item>

                        <label>{t("recommendations_step.planned_operation_date")}</label>
                        <Form.Item name="datePlanedOperation">
                            <DatePicker
                                placeholder={t("placeholder_select_date")}
                                className="topIndex"
                                suffixIcon={<CalendarInputIcon />}
                                popupClassName="topIndex"
                            />
                        </Form.Item>

                        <label>{t("recommendations_step.planned_operation_diagnostic")}</label>
                        <Form.Item name="datePlanedObservation">
                            <DatePicker
                                placeholder={t("placeholder_select_date")}
                                className="topIndex"
                                suffixIcon={<CalendarInputIcon />}
                                popupClassName="topIndex"
                            />
                        </Form.Item>

                        <label>
                            {t("recommendations_step.repeated_consultation_date_with_procedures")}
                        </label>
                        <Form.Item name="datePlanedRepeatedAndTherapy">
                            <DatePicker
                                placeholder={t("placeholder_select_date")}
                                className="topIndex"
                                suffixIcon={<CalendarInputIcon />}
                                popupClassName="topIndex"
                            />
                        </Form.Item>
                    </div>
                )}

                {!disabled && (
                    <Button className="w-100 mt-3" type="primary" htmlType="submit">
                        {t("next_button")}
                    </Button>
                )}
            </Form>
        </div>
    );
};

export const dynamicsRecommendationsFormArray: IRecommendationOption[] = Object.keys(DynamicsRecommendationsEnum).map((x) => ({
    value: x as RecommendationValueType,
    comment: '',
    selected: false,
}));

export const lifestyleRecommendationsFormArray: IRecommendationOption[] = Object.keys(LifestyleRecommendationsEnum).map((x) => ({
    value: x as RecommendationValueType,
    comment: '',
    selected: false,
}));

export const dynamicObservationFormArray: IRecommendationOption[] = Object.keys(DynamicObservationEnum).map((x) => ({
    value: x as RecommendationValueType,
    comment: '',
    selected: false,
}));

export const additionalExaminationsFormArray: IRecommendationOption[] = Object.keys(AdditionalExaminationsEnum).map((x) => ({
    value: x as RecommendationValueType,
    comment: '',
    selected: false,
}));

export const recommendationsForConsultingAdditionalSpecialistsFormArray: IRecommendationOption[] = Object.keys(
    RecommendationsForConsultingAdditionalSpecialistsEnum,
).map((x) => ({
    value: x as RecommendationValueType,
    comment: '',
    selected: false,
}));
