export enum EncounterChangeReasonEnum {
    patient_request = 'patient_request',
    changing_doctor_appointments = 'changing_doctor_appointments',
    changing_nurse_appointments = 'changing_nurse_appointments',
    doctor_illness = 'doctor_illness',
    nurse_illness = 'nurse_illness',
    patient_illness = 'patient_illness',
    technical_problem_on_patient_side = 'technical_problem_on_patient_side',
    technical_problem_on_platform = 'technical_problem_on_platform',
    patient_refuse = 'patient_refuse',
    doctor_refuse = 'doctor_refuse',
    doctor_non_appearance = 'doctor_non_appearance',
    doctor_non_appearance_technical = 'doctor_non_appearance_technical',
    patient_non_appearance = 'patient_non_appearance',
}
