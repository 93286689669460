import { CaretRightOutlined } from '@ant-design/icons';
import { AboutPractitionerDTO, GetAllPractitionersChiefComplaintCodesItem } from '@api/mainServiceAPI';
import { ScheduleDoctorIcon, SearchIcon } from '@icons';
import { getDocInfoModel, selectCurrentProfile } from '@sliceUser';
import { currencyFormatter } from '@utils/utils';
import { Avatar, Button, Col, Form, Input, List, Row, Select, Space } from 'antd';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import VirtualList from 'rc-virtual-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPractitioners, selectPractitioners } from 'src/store/consultation';
import { IStepProps } from '../../ConsultationSignUpPage';
import styles from './DoctorStep.module.scss';
import { DoctorList } from 'src/components/DoctorList/DoctorList';
import {selectPaymentServiceActive} from "../../../../../store/organization";

interface IStepDoctorProps {
    diagnosis: GetAllPractitionersChiefComplaintCodesItem[];
    isDoctorFirst?: boolean;
    selectedDate?: Dayjs | null;
    selectedTime?: string;
    isContractSigned: boolean;
}

export const DoctorStep = ({
    diagnosis = [],
    onChangeStep,
    isDoctorFirst,
    selectedDate,
    selectedTime,
    data,
    setData,
    isContractSigned,
}: IStepProps & IStepDoctorProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const doctorsList = useSelector(selectPractitioners);
    const currentProfile = useSelector(selectCurrentProfile);
    const paymentServiceActive = useSelector(selectPaymentServiceActive);

    const [filterForm] = Form.useForm();
    const [filteredDoctorsList, setFilteredDoctorsList] = useState<AboutPractitionerDTO[]>();
    const [filterQuery, setFilterQuery] = useState<string | undefined>();
    const [filterPosition, setFilterPosition] = useState<string>('');
    const [positionOptions, setPositionOptions] = useState<{ label: string; value: string | null }[]>([]);

    useEffect(() => {
        if (currentProfile) {
            const omittedOtherDiagnosis = diagnosis.length === 0 ? undefined : diagnosis;

            dispatch(
                getPractitioners({
                    userId: currentProfile.fhirId!,
                    page: 0,
                    size: 999999,
                    chiefComplaintCodes: omittedOtherDiagnosis,
                }),
            );
        }
    }, [currentProfile]);

    useEffect(() => {
        // Filtering doctors by the previously selected slot:
        const doctorsOfTheSlot = getAvailableDoctors(doctorsList);

        setFilteredDoctorsList([...doctorsOfTheSlot]);

        const docsPositions = doctorsOfTheSlot.map((d) => d.speciality).filter((value, index, array) => array.indexOf(value) === index);
        setPositionOptions([
            ...docsPositions.filter((x) => x).map((x) => ({ label: t(`adminEmployeePage.profilePage.practitionerSpecialties.${x}`), value: `${x}` })),
            { label: t('consultation.DoctorStep.allPositions'), value: '' },
        ]);
    }, [doctorsList]);

    useEffect(() => {
        setFilteredDoctorsList(
            getAvailableDoctors(doctorsList).filter((d) => {
                let res = true;

                if (filterQuery) {
                    res = (res && d.lastName?.toLowerCase()?.startsWith(filterQuery.toLowerCase())) || false;
                }
                if (filterPosition) {
                    res = res && d.speciality === filterPosition;
                }
                return res;
            }),
        );
    }, [filterQuery, filterPosition]);

    const getAvailableDoctors = (doctorsList: AboutPractitionerDTO[]): AboutPractitionerDTO[] => {
        let doctorsOfTheSlot = [...doctorsList];
        if (data?.time && !isDoctorFirst) {
            const availableDoctorIds = Object.keys(data.time.practitionersMap);
            doctorsOfTheSlot = doctorsOfTheSlot.filter((d) => availableDoctorIds.includes(d.id as string));
        }
        return doctorsOfTheSlot;
    };

    const nextStep = (item: AboutPractitionerDTO) => {
        setData({ ...data, doctor: item }, isDoctorFirst ? 1 : 2);
        onChangeStep?.(isDoctorFirst ? 1 : 2);
    };

    const openDocInfoModal = (docId: string) => {
        dispatch(getDocInfoModel(docId));
    };

    return (
        <div className="pb-3">
            <h5 className={classNames(diagnosis.length !== 0 && styles.spaceTitle)}>{t('consultation.DoctorStep.title')}</h5>
            {diagnosis.length === 0 ? (
                <Form form={filterForm}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Input
                                placeholder={`${t('consultation.DoctorStep.searchPlaceholder')}`}
                                prefix={<SearchIcon />}
                                onChange={(e) => setFilterQuery(e.target.value)}
                            />
                        </Col>
                        <Col span={12}>
                            <Select
                                placeholder={t('consultation.DoctorStep.positionPlaceholder')}
                                value={filterPosition}
                                onChange={(value) => setFilterPosition(value)}
                                options={positionOptions}
                                className="w-100"
                            />
                        </Col>
                    </Row>
                </Form>
            ) : (
                <>
                    {selectedDate ? (
                        <p>
                            {selectedDate?.format('D MMMM')} {t('consultation.DoctorStep.at')} {selectedTime}{' '}
                            {t('consultation.DoctorStep.availablePositions')}
                        </p>
                    ) : (
                        <p>{t('consultation.DoctorStep.matchedPositions')}</p>
                    )}
                </>
            )}
            <div className={styles.doctorListWrapper}>
                {
                    filteredDoctorsList && 
                    <DoctorList 
                        doctors={filteredDoctorsList}
                        disabledChooseBtn={isContractSigned}
                        openDocMore={openDocInfoModal}
                        onChooseDoctor={nextStep}
                        height={520}
                        showPrice={paymentServiceActive}
                        showMoreBtn
                    />
                }
            </div>
        </div>
    );
};
