import { CarePlanResultsDTO } from '@api/mainServiceAPI';
import { AlertIconOutlined, CheckIconGreen, ProcedureToggleIcon, RedChatIcon } from '@icons';
import { pluralLabel } from '@utils/utils';
import { Button, Col, Collapse, Modal, Row } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import styles from './CourseResultsModal.module.scss';

interface ICourseResultsProps {
    onSuccess?: () => void;
    onCancel?: () => void;
    showModal: boolean;

    carePlanResults: CarePlanResultsDTO;
}
const { Panel } = Collapse;

export const CourseResultsModal = ({ showModal, onCancel, onSuccess, carePlanResults }: ICourseResultsProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            width={664}
            title={
                <div>
                    <h3 className={classNames(styles.modalTitle, 'mb-2')}>{t("patient_medcard.course_results_modal.course_results")}</h3>
                    <p className={styles.modalSubtitle}> {t(`enums.planDefinition.${carePlanResults?.carePlan?.planDefinitionID}`)}</p>
                </div>
            }
            open={showModal}
            onCancel={onCancel}
            footer={[
                <div key="empty" />,
                <Button key="close" type="primary" onClick={onSuccess}>
                    {t("patient_medcard.course_results_modal.close")}
                </Button>,
            ]}
        >
            <Collapse
                accordion
                bordered={false}
                expandIconPosition="end"
                defaultActiveKey={[0]}
                expandIcon={({ isActive }) => (
                    <ProcedureToggleIcon className={classNames(isActive ? styles.active : styles.nonActive)} rotate={isActive ? 0 : 90} />
                )}
                className={styles.collapse}
            >
                {carePlanResults?.results
                    ?.sort((a, b) => a.dateTime! - b.dateTime!)
                    ?.map((x, i) => (
                        <Panel
                            header={
                                <Row align="middle" justify="space-between">
                                    <Col>
                                        <Row gutter={8} className="d-flex align-items-center">
                                            <Col span={6}>
                                                {x.procedureResults?.some((y) => String(y.result?.unSuccess) === 'true') ? (
                                                    <AlertIconOutlined />
                                                ) : (
                                                    <CheckIconGreen />
                                                )}
                                            </Col>
                                            <Col span={18}>
                                                <p className={styles.headerTitle}>{t("afternoon")} {i + 1}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <p className={styles.nurse}>
                                            {x.nurseFullName} {dayjs(x.dateTime).format('DD.MM.YYYY')}
                                        </p>
                                    </Col>
                                </Row>
                            }
                            key={i}
                            className={styles.panel}
                        >
                            {x.procedureResults?.map((p, i) => {
                                // p type is IProcedureResult

                                let resultString =
                                    (p.result?.duration || 0) + ' ' + t("time_format_declension." + pluralLabel(+p.result?.duration || 0, ["second_i", "second_r", "second_m"]));

                                if (p.result?.totalTries) {
                                    resultString +=
                                        ', ' +
                                        p.result?.totalTries +
                                        ' ' +
                                        t("attempts_format_declension." + pluralLabel(+p.result?.totalTries || 0, ["attempt_i", "attempt_r", "attempt_m"]));
                                }
                                if (p.result?.successTries) {
                                    resultString +=
                                        ', ' +
                                        p.result?.successTries +
                                        ' ' +
                                        t("penetration_format_declension." + pluralLabel(+p.result?.successTries || 0, ["penetration_i", "penetration_r", "penetration_m"]));
                                }
                                if (p.result?.accommodationCapacity) {
                                    resultString = x.nurseFullName + ', ' + dayjs(x.dateTime).format('DD.MM.YYYY') + ', ' + p.result?.accommodationCapacity;
                                }

                                return (
                                    <div key={`procedure-${i}`} className="mb-3">
                                        <p className={styles.procedureName}>{t('procedures.list.' + p.procedureType)}</p>
                                        <p className={styles.duration}>{resultString}</p>

                                        {p.result?.comment && (
                                            <p className={styles['procedure-comment']}>
                                                <RedChatIcon className="me-2" />
                                                {p.result.comment}
                                            </p>
                                        )}
                                    </div>
                                );
                            })}

                            {/* todo not implemented now */}
                            {x.therapySessionResult?.result?.comment && (
                                <p className={styles.comment}>
                                    <AlertIconOutlined className="me-2" /> {x.therapySessionResult?.result?.comment}
                                </p>
                            )}
                        </Panel>
                    ))}
            </Collapse>
        </Modal>
    );
};
