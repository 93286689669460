import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './ReadonlyWarning.module.scss';

interface IReadonlyWarning {
    text: string
}

export const ReadonlyWarning = ({ text }: IReadonlyWarning) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.infoIcon}>
                <InfoCircleOutlined />

            </div>
            <div className={styles.message}>
                {text}
            </div>
        </div>
    );
};