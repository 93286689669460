import { ConfigProvider, ThemeConfig } from "antd";

import { Locale } from "antd/lib/locale";
import { PropsWithChildren, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomThemeNames, CustomThemes } from "src/models/custom-themes";
import { selectCustomTheme } from "src/store/organization";
import { theme } from "src/styles/ant.theme";

interface IThemeWrapper {
    locale: Locale
}

export const ThemeWrapper = ({ children, locale }: PropsWithChildren<IThemeWrapper>) => {
    const selectedThemeName: CustomThemeNames = useSelector(selectCustomTheme);

    useEffect(() => {
        const selectedTheme = CustomThemes[selectedThemeName];
        
        document.body.style.setProperty('--main-bg-color', selectedTheme.bgColor);
        document.body.style.setProperty('--primary-btn-bg-color', selectedTheme.primaryBtnBg);
        document.body.style.setProperty('--primary-btn-hover-bg', selectedTheme.primaryBtnHoverBg);
        document.body.style.setProperty('--default-btn-color', selectedTheme.defaultBtnColor);
        document.body.style.setProperty('--default-btn-hover-bg', selectedTheme.defaultBtnHoverBg);
        document.body.style.setProperty('--default-btn-hover-color', selectedTheme.defaultBtnHoverColor);

    }, [selectedThemeName]); 

    return (
        <ConfigProvider theme={theme} locale={locale}>
            {children}
        </ConfigProvider>
    );
};