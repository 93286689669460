import { useEffect, useRef } from 'react';
import videoFile from '../../assets/recover_cilliar.mp4';

import styles from './RecoverCilliar.module.scss';

interface IRecoverCilliarProps {
    paused?: boolean;
}

export const RecoverCilliar = ({ paused = false }: IRecoverCilliarProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (paused) {
            videoRef.current?.pause();
        } else {
            videoRef.current?.play();
        }
    }, [paused]);

    return (
        <div className={styles.wrapper}>
            <video ref={videoRef} autoPlay>
                <source src={videoFile} type="video/mp4" />
            </video>
        </div>
    );
};
