import { AmblyopiaEyeEnum, ProcedureTypeEnum } from '@enums';
import { IPatientProcedureInteraction } from '@models';
import {
    AmblyopiaProcedure,
    AvetisovMats,
    BifixationRecoveryProcedure,
    ChessPatternProcedure,
    FlashesProcedure,
    IProcedureBase,
    MakulostimulationProcedure,
    MeridionalPatternProcedure,
    PleopticCrossProcedure,
    PleopticSpiralsProcedure,
    convertFrequencyToInterval,
    convertVisualAcuityToPx,
    getPxInMmFromDpi,
} from '@procedures';
import { useEffect, useRef, useState } from 'react';
import { IGeneralProcedure } from 'src/models/general-procedure.model';
import { DiagnosticLetters } from 'src/procedures/DiagnosticLetters/DiagnosticLetters';
import { DiagnosticRings } from 'src/procedures/DiagnosticRings/DiagnosticRings';
import { RecoverCilliar } from 'src/procedures/RecoverCilliar/RecoverCilliar';

import { AVETISOV_TEXT_TYPE } from '@configProcedures';
import { DiagnosticImages } from 'src/procedures/DiagnosticImages/DiagnosticImages';
import { Dissociation } from 'src/procedures/Dissociation/Dissociation';
import styles from './ProcedureCanvas.module.scss';

interface IProcedureCanvasProps {
    procedure: IGeneralProcedure | null;
    distance: number;
    dpi: number;

    paused?: boolean;
    interactionEvent?: (interaction: IPatientProcedureInteraction) => void;
    colors?: { red: number; green: number; blue: number };
}

export const ProcedureCanvas = ({ procedure, dpi, distance, paused = false, interactionEvent, colors }: IProcedureCanvasProps) => {
    const canvasRef = useRef(null);
    const [currentProcedure, setCurrentProcedure] = useState<IProcedureBase | null>(null);

    useEffect(() => {
        currentProcedure?.destroy();
        setCurrentProcedure(null);

        // todo add delay
        if (procedure && !procedureIsNonCanvas()) {
            _renderCanvas();
        }
    }, [procedure, distance, colors]);

    useEffect(() => {
        if (currentProcedure) {
            currentProcedure.run();
        }

        return () => {
            currentProcedure?.destroy();
        };
    }, [currentProcedure]);

    useEffect(() => {
        currentProcedure?.togglePause(paused);
    }, [paused]);

    const procedureIsNonCanvas = (): boolean => {
        return (
            procedure?.type === ProcedureTypeEnum.AVETISOV_MATS ||
            procedure?.type === ProcedureTypeEnum.RECOVER_CILLIAR ||
            procedure?.type === ProcedureTypeEnum.DIAGNOSTIC_LETTERS ||
            procedure?.type === ProcedureTypeEnum.DIAGNOSTIC_RINGS ||
            procedure?.type === ProcedureTypeEnum.DIAGNOSTIC_IMAGES ||
            procedure?.type === ProcedureTypeEnum.DIAGNOSTIC_BINOCULAR ||
            procedure?.type === ProcedureTypeEnum.DISSOCIATION
        );
    };

    const _renderCanvas = (): void => {
        if (!procedure) {
            return;
        }

        const canvas = canvasRef.current! as HTMLCanvasElement;
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;

        const context = canvas.getContext('2d', { willReadFrequently: true })!;

        let targetProcedure: IProcedureBase | null = null;

        switch (procedure.type) {
            case ProcedureTypeEnum.MERIDIONAL_PATTERN:
                {
                    targetProcedure = new MeridionalPatternProcedure(canvas, context, {
                        animationInterval: convertFrequencyToInterval(+procedure.settings!.frequency!),
                        stripeWidth: convertVisualAcuityToPx(+procedure!.settings!.visualAcuity!, dpi, distance || 1),
                        pointSize: convertVisualAcuityToPx(+procedure!.settings!.visualAcuity!, dpi, distance || 1),
                        pointImage: 'circle',
                        angle: +procedure.settings!.angle!,
                    });
                }
                break;
            case ProcedureTypeEnum.CHESS_PATTERN:
                {
                    targetProcedure = new ChessPatternProcedure(canvas, context, {
                        animationInterval: convertFrequencyToInterval(+procedure.settings!.frequency!),
                        squareSize: convertVisualAcuityToPx(+procedure!.settings!.visualAcuity!, dpi, distance || 1),
                        pointSize: convertVisualAcuityToPx(+procedure!.settings!.visualAcuity!, dpi, distance || 1),
                        pointImage: 'circle',
                    });
                }
                break;
            case ProcedureTypeEnum.MAKULOSTIMULATION:
                {
                    targetProcedure = new MakulostimulationProcedure(canvas, context, {
                        spiralColor: procedure.settings!.color!.toString(),
                        stripeWidth: 2,
                        speed: 3,
                    });
                }
                break;

            case ProcedureTypeEnum.PLEOPTIC_CROSS:
                {
                    targetProcedure = new PleopticCrossProcedure(canvas, context, {
                        squareSize: convertVisualAcuityToPx(+procedure!.settings!.visualAcuity!, dpi, distance || 1),
                        accuracy: 10,
                    });
                }
                break;
            case ProcedureTypeEnum.PLEOPTIC_SPIRALS:
                {
                    targetProcedure = new PleopticSpiralsProcedure(canvas, context, {
                        draggableSpiralCenterOffset: 200, // minimal distance from center for draggable spiral (initial)
                        spiralStrokeWidth: 1 / 8, // from radius
                        spiralStep: 1 / 4, // from radius
                        accuracy: 10, // minimal distance from center for draggable spiral (target)
                    });
                }
                break;
            case ProcedureTypeEnum.FLASHES:
                {
                    targetProcedure = new FlashesProcedure(canvas, context, {
                        animationInterval: convertFrequencyToInterval(+procedure.settings!.frequency!),
                        color: procedure.settings?.color?.toString(),
                    });
                }
                break;
            case ProcedureTypeEnum.BIFIXATION_RECOVERY:
                {
                    targetProcedure = new BifixationRecoveryProcedure(canvas, context, {
                        backgroundColor: '#140078',
                        gapColor: '#0a5aaa',
                        duration: +procedure.settings!.duration!,
                        gapWidth: getPxInMmFromDpi(dpi) * 15,
                        gapHeight: getPxInMmFromDpi(dpi) * 80,
                    });
                }
                break;
            case ProcedureTypeEnum.AMBLYOPIA:
                {
                    targetProcedure = new AmblyopiaProcedure(canvas, context, {
                        accuracy: getPxInMmFromDpi(dpi) * (+procedure.settings!.accuracy! || 0),
                        redColor: colors?.red !== undefined ? colors.red : 255, // by design #e60205
                        greenColor: colors?.green !== undefined ? colors.green : 150,
                        blueColor: colors?.blue !== undefined ? colors.blue : 255, // by design #0285dd
                        pxPerMm: getPxInMmFromDpi(dpi),
                    });
                }
                break;
            case ProcedureTypeEnum.RESTORATION_OF_FUSION:
                {
                    targetProcedure = new AmblyopiaProcedure(canvas, context, {
                        accuracy: getPxInMmFromDpi(dpi) * (+procedure.settings!.accuracy! || 0),
                        redColor: colors?.red !== undefined ? colors.red : 255, // by design #e60205
                        greenColor: colors?.green !== undefined ? colors.green : 150,
                        blueColor: colors?.blue !== undefined ? colors.blue : 255, // by design #0285dd
                        pxPerMm: getPxInMmFromDpi(dpi),
                        amblyopiaEye: procedure.settings?.occlusionEye as AmblyopiaEyeEnum,
                    });
                }
                break;

            // technically - it's not procedures, it's diagnostics
            // case ProcedureTypeEnum.DISSOCIATION:
            //     {
            //         targetProcedure = new BinocularDiagnosticProcedure(canvas, context, {
            //             squareColor: `rgb(${colors?.red || 255}, 0, ${colors?.blue || 0})`,
            //             squareSize: Math.sqrt(Math.pow(getPxInMmFromDpi(dpi) * 10, 2) / 2),
            //             crossColor: `rgb(0, ${colors?.green || 255}, ${colors?.blue || 0})`,
            //             crossSize: getPxInMmFromDpi(dpi) * 10,
            //             crossThickness: (getPxInMmFromDpi(dpi) * 10) / 4,
            //             circleColor: 'white',
            //             circleSize: (getPxInMmFromDpi(dpi) * 10) / 2,
            //             backgroundColor: 'black',
            //         });
            //     }
            //     break;
            // case ProcedureTypeEnum.DIAGNOSTIC_LETTERS:
            //     {
            //         targetProcedure = new DiagnosticLettersProcedure(canvas, context, {
            //             size: convertVisualAcuityToPx(+procedure!.settings!.visualAcuity!, dpi, distance || 1),
            //         });
            //     }
            //     break;
            // case ProcedureTypeEnum.DIAGNOSTIC_RINGS:
            //     {
            //         targetProcedure = new DiagnosticRingsProcedure(canvas, context, {
            //             size: convertVisualAcuityToPx(+procedure!.settings!.visualAcuity!, dpi, distance || 1),
            //         });
            //     }
            //     break;
            default:
                break;
        }

        if (targetProcedure) {
            targetProcedure.interactionHandler = (event: IPatientProcedureInteraction) => {
                interactionEvent?.(event);
            };

            setCurrentProcedure(targetProcedure);
        }
    };

    return (
        <>
            {procedure ? (
                procedureIsNonCanvas() ? (
                    <div className={styles.nonCanvasWrapper}>
                        {procedure.type === ProcedureTypeEnum.AVETISOV_MATS ? (
                            <AvetisovMats
                                fontSize={+procedure.settings!.fontSize!}
                                textType={procedure.settings!.textType!.toString() as AVETISOV_TEXT_TYPE}
                            />
                        ) : procedure.type === ProcedureTypeEnum.RECOVER_CILLIAR ? (
                            <RecoverCilliar paused={paused} />
                        ) : procedure.type === ProcedureTypeEnum.DIAGNOSTIC_LETTERS ? (
                            <DiagnosticLetters
                                size={convertVisualAcuityToPx(+procedure!.settings!.visualAcuity!, dpi, distance || 1)}
                                letter={procedure.settings!.letter as string}
                            />
                        ) : procedure.type === ProcedureTypeEnum.DIAGNOSTIC_RINGS ? (
                            <DiagnosticRings
                                size={convertVisualAcuityToPx(+procedure!.settings!.visualAcuity!, dpi, distance || 1)}
                                direction={procedure.settings!.direction as string}
                            />
                        ) : procedure.type === ProcedureTypeEnum.DIAGNOSTIC_IMAGES ? (
                            <DiagnosticImages
                                size={convertVisualAcuityToPx(+procedure!.settings!.visualAcuity!, dpi, distance || 1)}
                                image={procedure.settings!.optotypeImage as string}
                            />
                        ) : procedure.type === ProcedureTypeEnum.DIAGNOSTIC_BINOCULAR || procedure.type === ProcedureTypeEnum.DISSOCIATION ? (
                            <Dissociation
                                colors={colors!}
                                sizes={{
                                    cross: getPxInMmFromDpi(dpi) * 10,
                                    square: Math.sqrt(Math.pow(getPxInMmFromDpi(dpi) * 10, 2) / 2),
                                    circle: getPxInMmFromDpi(dpi) * 10,
                                }}
                                distanceFromCenter={getPxInMmFromDpi(dpi) * 10}
                            />
                        ) : null}
                    </div>
                ) : (
                    <div className={styles.canvasWrapper}>
                        <canvas ref={canvasRef} className={styles.canvas} />
                    </div>
                )
            ) : null}
        </>
    );
};
