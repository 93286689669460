import { getUser, updateUser, UpdateUserDTOStatus, UserDTO, UserProfileDTOProfileType } from '@api/userServiceAPI';
import { CalendarAccent, ChevronLeft, GreyCalendarIcon } from '@icons';
import { Button, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from './ClinicAdminEmployeePage.module.scss';
import { AccountInfo, EditAccountInfo, EditProfileDetails, ProfileDetails } from './components';

export const ClinicAdminEmployeePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams] = useSearchParams();

    const [editAccount, setEditAccount] = useState<boolean>(false);
    const [editProfile, setEditProfile] = useState<boolean>(false);
    const [openProfile, setOpenProfile] = useState<boolean>(false);
    const [role, setRole] = useState<string>();
    const [blocked, setBlocked] = useState<boolean>();
    const [selectedEmployee, setSelectedEmployee] = useState<UserDTO>();

    useEffect(() => {
        getUser(params.userId!).then((result) => {
            if (result?.data) {
                setSelectedEmployee(result.data);
                setRole(result.data.userProfileDTOList && result.data.userProfileDTOList[0].profileType);
                setBlocked(result.data.status == UpdateUserDTOStatus.BLOCKED);
            }
        });
    }, [editAccount, editProfile]);

    useEffect(() => {
        const needOpenProfile = searchParams.get('profileTab');
        if (needOpenProfile) {
            setOpenProfile(true);
        }
    }, [searchParams]);

    const hasSchedule = () => {
        switch (getUserProfile()?.profileType) {
            case UserProfileDTOProfileType.Head_physician:
                return true;
            case UserProfileDTOProfileType.Practitioner:
                return true;
            case UserProfileDTOProfileType.Nurse:
                return true;

            default:
                return false;
        }
    };

    const hasProfile = () => {
        switch (getUserProfile()?.profileType) {
            case UserProfileDTOProfileType.Head_physician:
                return true;
            case UserProfileDTOProfileType.Practitioner:
                return true;
            case UserProfileDTOProfileType.Nurse:
                return true;

            default:
                return false;
        }
    };

    const getUserProfile = () => {
        return selectedEmployee?.userProfileDTOList?.filter((profile) => profile.profileType != UserProfileDTOProfileType.End_user)[0];
    };

    const getUserRole = () => {
        if (selectedEmployee?.userProfileDTOList) {
            return selectedEmployee?.userProfileDTOList[0] ? selectedEmployee?.userProfileDTOList[0].profileType : 'End_user';
        }
        return 'End_user';
    };

    const getFullName = () => {
        if (selectedEmployee?.firstName && selectedEmployee.lastName) {
            return selectedEmployee?.lastName + ' ' + selectedEmployee?.firstName + ' ' + (selectedEmployee?.middleName || '');
        } else {
            return t(`adminMainPage.roles.${getUserRole()}`);
        }
    };

    const onBackToMainPage = () => {
        navigate(-1);
    };

    const onEditAccount = () => {
        setEditAccount(true);
    };

    const onCloseEditAccount = () => {
        setEditAccount(false);
    };

    const onOpenSchedule = () => {
        navigate('/schedule?employeeId=' + selectedEmployee?.id);
    };

    const onBlockEmployeeAccount = () => {
        if (!selectedEmployee) {
            return;
        }

        updateUser(selectedEmployee.id!, { status: UpdateUserDTOStatus.BLOCKED }).then((result) => {
            if (result.data) {
                setBlocked(result.data.status == UpdateUserDTOStatus.BLOCKED);
            }
        });
    };

    const onUnBlockEmployeeAccount = () => {
        if (!selectedEmployee) {
            return;
        }

        updateUser(selectedEmployee.id!, { status: UpdateUserDTOStatus.ACTIVE }).then((result) => {
            if (result.data) {
                setBlocked(result.data.status == UpdateUserDTOStatus.BLOCKED);
            }
        });
    };

    const onOpenAccountInfo = () => {
        setOpenProfile(false);
    };

    const onOpenProfileDetails = () => {
        setOpenProfile(true);
    };

    const onEditProfile = () => {
        setEditProfile(true);
    };

    const onSaveProfile = () => {
        setEditProfile(false);
    };

    const onCloseEditProfile = () => {
        setEditProfile(false);
    };
    return (
        <div className={styles['wrapper']}>
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <Button onClick={() => onBackToMainPage()} className="backBtn">
                        <ChevronLeft className="transparent" />
                    </Button>
                    <p className={styles.title}>{getFullName()}</p>
                </div>
                {hasSchedule() && (
                    <Button disabled={selectedEmployee?.status === 'DRAFT'} onClick={() => onOpenSchedule()}>
                        {selectedEmployee?.status !== 'DRAFT' ? (
                            <CalendarAccent className={styles.calendarIcon} />
                        ) : (
                            <GreyCalendarIcon className={styles.calendarIcon} />
                        )}
                        {t('adminEmployeePage.schedule')}
                    </Button>
                )}
            </div>
            <Row gutter={24}>
                {hasProfile() && (
                    <Col md={6} sm={24} xs={24}>
                        <div className={styles.navigationButtons}>
                            <Button onClick={() => onOpenAccountInfo()} type="ghost" className={!openProfile ? styles.selectedTab : ''}>
                                {t('adminEmployeePage.account')}
                            </Button>
                            <Button onClick={() => onOpenProfileDetails()} type="ghost" className={openProfile ? styles.selectedTab : ''}>
                                {getUserProfile()?.profileType === UserProfileDTOProfileType.Nurse
                                    ? t('adminEmployeePage.nurseProfile')
                                    : t('adminEmployeePage.doctorProfile')}
                            </Button>
                        </div>
                    </Col>
                )}
                {openProfile ? (
                    <Col md={18} sm={24} xs={24}>
                        <div className={styles.accountBlock}>
                            {!editProfile ? (
                                <ProfileDetails
                                    employee={selectedEmployee}
                                    profile={getUserProfile()}
                                    profileType={getUserProfile()?.profileType}
                                    onEditProfile={() => onEditProfile()}
                                />
                            ) : (
                                <EditProfileDetails
                                    employee={selectedEmployee}
                                    profile={getUserProfile()}
                                    profileType={getUserProfile()?.profileType}
                                    onSaveProfile={() => onSaveProfile()}
                                    onCloseEditProfile={() => onCloseEditProfile()}
                                />
                            )}
                        </div>
                    </Col>
                ) : (
                    <Col md={12} sm={24} xs={24}>
                        <div className={styles.accountBlock}>
                            {editAccount ? (
                                <EditAccountInfo
                                    selectedEmployee={selectedEmployee}
                                    blocked={blocked}
                                    onCloseEditAccount={onCloseEditAccount}
                                    onBlockEmployeeAccount={onBlockEmployeeAccount}
                                    onUnBlockEmployeeAccount={onUnBlockEmployeeAccount}
                                />
                            ) : (
                                <AccountInfo
                                    selectedEmployee={selectedEmployee}
                                    blocked={blocked}
                                    onEditAccount={onEditAccount}
                                    onBlockEmployeeAccount={onBlockEmployeeAccount}
                                    onUnBlockEmployeeAccount={onUnBlockEmployeeAccount}
                                />
                            )}
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
};
