import { CaretRightOutlined } from '@ant-design/icons';
import { ScheduleDoctorIcon } from '@icons';
import { Avatar, Button, List, Space } from 'antd';
import styles from './DoctorList.module.scss';
import VirtualList from 'rc-virtual-list';
import { AboutPractitionerDTO } from '@api/userServiceAPI';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '@utils/utils';

interface IDOctorList {
    doctors: AboutPractitionerDTO[]
    disabledChooseBtn: boolean,
    showMoreBtn: boolean,
    showPrice: boolean,
    height?: number;
    listItemClass?: string,
    onChooseDoctor: (item: AboutPractitionerDTO) => void,
    openDocMore?: (itemId: string) => void,
}

export const DoctorList = ({
    doctors,
    disabledChooseBtn,
    showMoreBtn,
    showPrice,
    height,
    listItemClass,
    onChooseDoctor,
    openDocMore
}: IDOctorList) => {
    const { t } = useTranslation();

    return (
        <List>
            <VirtualList data={doctors} height={height} itemHeight={104} itemKey="id" className={styles.list}>
                {(item: AboutPractitionerDTO) => (
                        <List.Item key={item.id} className={listItemClass || styles.listItem}>
                            <List.Item.Meta
                                avatar={<Avatar src={item.photo || <ScheduleDoctorIcon />} size={64} className={styles.avatar} />}
                                title={
                                    <Space className={styles.doctorTitle}>
                                        <div>
                                            {item.lastName} {item.firstName} {item.middleName}
                                        </div>
                                        {
                                            showMoreBtn && openDocMore &&
                                            <Button
                                                type="ghost"
                                                className={`${styles.link} d-flex align-items-center mt-1`}
                                                onClick={() => openDocMore(item.id!)}
                                            >
                                                {t('consultation.DoctorStep.more')} <CaretRightOutlined />
                                            </Button>
                                        }
                                    </Space>
                                }
                                description={
                                    <>
                                        <div className={styles.subtitle}>
                                            {t(`adminEmployeePage.profilePage.practitionerSpecialties.${item.speciality}`)}
                                        </div>
                                        <div className={styles.clinic}>{item.organizationName}</div>
                                    </>
                                }
                            />
                            {showPrice && <div className={styles.price}>{currencyFormatter(item.price)}</div> }
                            <Button disabled={disabledChooseBtn} onClick={() => onChooseDoctor(item)} className={styles.chooseBtn}>
                                {t('consultation.DoctorStep.choose')}
                            </Button>
                        </List.Item>
                )}
            </VirtualList>
        </List>
    );
};
