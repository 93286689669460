import { Button, Modal } from 'antd';
import { t } from 'i18next';
import styles from './PaymentDirectlyModal.module.scss';
import { REACT_APP_SUPPORT_PHONE } from '@utils/constants';

export interface IPaymentDirectlyModalProps {
    visible: boolean;
    onClose: () => void;
}

export const PaymentDirectlyModal = ({ visible, onClose }: IPaymentDirectlyModalProps) => {
    const onPaymentFormOpen = () => {};

    const onPaymentInstructionOpen = () => {};

    return visible ? (
        <Modal
            className={styles.paymentDirectlyModal}
            width={592}
            open={visible}
            onCancel={onClose}
            closable={false}
            title={t('modals.paymentDirectly.title')}
            footer={[
                <Button onClick={onClose} key="support">
                    {t('modals.buttons.close')}
                </Button>,
            ]}
        >
            <>
                <h3 style={{ marginTop: 0 }}>{t("payment.direct_payment_info.title")}</h3>
                <p>{t("payment.direct_payment_info.description")}</p>
                <p>
                    {t("payment.direct_payment_info.assignment_print1")}{' '}
                    <span onClick={() => onPaymentFormOpen()}>{t("payment.direct_payment_info.assignment_print2")}</span> {t("payment.direct_payment_info.assignment_print3")}
                </p>
                <p>
                    {t("payment.direct_payment_info.instruction_url1")} <span onClick={() => onPaymentInstructionOpen()}>{t("payment.direct_payment_info.instruction_url2")}</span>
                </p>
                <h3>{t("payment.direct_payment_info.consultation_case.title")}</h3>
                <p>
                    {t("payment.direct_payment_info.consultation_case.description")}
                </p>
                <p style={{ textAlign: 'center', marginTop: '24px' }}>{t("payment.direct_payment_info.call_etc_questions")}</p>
                <p className={styles['phone-number']}>{REACT_APP_SUPPORT_PHONE}</p>
                <div style={{marginTop: '24px', display: 'flex', alignItems: 'center', columnGap: '16px', justifyContent: 'center'}}>
                    <img style={{marginRight: '50%', position: "absolute", width: '64px', height: '64px', backgroundSize: '100%'}} src={`/img/support/whatsapp.png`} alt="" />
                    <p style={{marginTop: 0}} className={styles['phone-number']}>{t("payment.direct_payment_info.phone")}</p>
                </div>
            </>
        </Modal>
    ) : null;
};
