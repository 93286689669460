import { ParticipantProcedureItem } from '@components';
import { ProcedureStatus } from '@enums';
import {
    CameraDisabledIcon,
    CameraIcon,
    ChatIcon,
    ChatWith3DotsIcon,
    CloseIcon,
    MicroDisabledIcon,
    MicroIcon,
    ProcedureCheckIcon,
    ProcedureTimerIcon,
    ScreenDisabledIcon,
    ScreenIcon,
    SpeakerDisabledIcon,
    SpeakerIcon,
} from '@icons';

import { AppointmentParticipantDetailsDTOServiceType } from '@api/mainServiceAPI';
import { selectAwaitingSharingFrom } from '@sliceProcedures';
import { selectCurrentProfile } from '@sliceUser';
import { secondsAsTime } from '@utils/utils';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SessionParticipant } from 'src/models/session-participant';
import { SelectedParticipantTabType } from 'src/pages/_shared/DoctorAppointmentPage/DoctorAppointmentPage';
import styles from './VideoParticipant.module.scss';

interface VideoParticipantProps {
    participant: SessionParticipant;
    hostView?: boolean;
    diagnosticView?: boolean;
    manageable?: boolean;
    withoutTitles?: boolean;
    proceduresStatuses?: ProcedureStatus[];
    compact?: boolean;
    fullScreenVideo?: boolean;
    serviceType?: AppointmentParticipantDetailsDTOServiceType;

    kickOut?: (participant: SessionParticipant) => void;
    selectEvent?: (type: SelectedParticipantTabType) => void;
    requestSharing?: (share: boolean, participant: SessionParticipant) => void;
}

export const VideoParticipant = ({
    participant,
    hostView,
    diagnosticView,
    manageable = false,
    withoutTitles = false,
    compact,
    fullScreenVideo = false,
    serviceType,

    kickOut,
    selectEvent,
    requestSharing,
}: VideoParticipantProps) => {
    if (!participant.kurentoParticipant) {
        return null;
    }

    const currentProfile = useSelector(selectCurrentProfile);
    const awaitingScreenSharingFrom = useSelector(selectAwaitingSharingFrom);

    const [connectionTime, setConnectionTime] = useState('');

    const videoRef = useRef<HTMLVideoElement | null>(null);

    // DO NOT DELETE IT - it rerenders the component every second, we need it for the procedures statuses
    useEffect(() => {
        // just show current connection time
        const connectionTimeInterval = setInterval(() => {
            if (participant.kurentoParticipant) {
                const milliseconds = new Date().getTime() - participant.kurentoParticipant!.connectedDate.getTime();
                setConnectionTime(new Date(milliseconds).toISOString().slice(11, 19));
            }
        }, 1000);

        return () => {
            clearInterval(connectionTimeInterval);
        };
    }, []);

    useEffect(() => {
        if (participant?.kurentoParticipant?.rtcPeer?.peerConnection?.connectionState === 'connected') {
            let clonedMediaStream: MediaStream;

            if (currentProfile?.fhirId === participant.fhirId) {
                clonedMediaStream = cloneMediaStream(participant.kurentoParticipant!.rtcPeer.getLocalStream(), true);
            } else {
                clonedMediaStream = cloneMediaStream(participant.kurentoParticipant!.rtcPeer.getRemoteStream());
            }

            videoRef.current!.srcObject = clonedMediaStream;

            return () => {
                clonedMediaStream.getTracks().map((x) => x.stop());
            };
        }
    }, [participant?.kurentoParticipant?.rtcPeer?.peerConnection?.connectionState, currentProfile]);

    function cloneMediaStream(mediaStream: MediaStream, isLocal = false) {
        const clonedVideoTracks = mediaStream.getVideoTracks().map((track) => track.clone());
        const clonedAudioTracks = mediaStream.getAudioTracks().map((track) => track.clone());

        if (isLocal) {
            clonedAudioTracks.forEach((x) => {
                x.enabled = false;
            });
        }

        const clonedMediaStream = new MediaStream([...clonedVideoTracks, ...clonedAudioTracks]);
        return clonedMediaStream;
    }

    const requestScreenSharing = (share: boolean) => {
        requestSharing?.(share, participant);
    };

    const selectHandler = (type: SelectedParticipantTabType) => {
        if (selectEvent) {
            selectEvent(type);
        }
    };

    const procedureChips = () => {
        const procedures = [...participant.procedures];

        const startedProcedures = procedures.filter((elem) => elem.startedOrder !== 0);
        const notStartedProcedures = procedures.filter((elem) => elem.startedOrder === 0);
        const sortedStartedProcedures = startedProcedures.sort((a, b) => a.startedOrder! - b.startedOrder!);
        const result = sortedStartedProcedures.concat(notStartedProcedures);

        return result.map((x, i) => (
            <li className={classNames(styles.procedure_item_container)} key={i}>
                <ParticipantProcedureItem status={x.status} duration={+(x.settings?.duration || 0)} progressSeconds={x.progressTime} />
            </li>
        ));
    };

    const needProcedurePreview = participant.procedures.find(
        (p) =>
            p.status === ProcedureStatus.inProgress ||
            p.status === ProcedureStatus.continued ||
            p.status === ProcedureStatus.paused ||
            p.status === ProcedureStatus.changeGlasses ||
            p.status === ProcedureStatus.waitingApproveToChangeGlasses ||
            p.status === ProcedureStatus.started,
    )?.type;

    return (
        <div
            className={classNames(
                styles.participant,
                hostView && styles.host,
                participant.selected && serviceType === 'therapy-session' && [styles.selection, styles['selection_' + participant.selected]],
                compact && styles.compact,
            )}
        >
            <div
                onClick={() => selectHandler('info')}
                className={`${styles.video_wrapper} ${hostView && !participant.kurentoParticipant.videoEnabled ? styles.videoDisabled : ''} ${
                    fullScreenVideo && styles['video_wrapper_fullscreen']
                }`}
            >
                <video autoPlay ref={videoRef}></video>
                {/* {!hostView && <span className={styles.participant_time}>{connectionTime}</span>} */}

                <div className={styles.video_actions}>
                    {!withoutTitles && (
                        <div className={styles.participant_info}>
                            <span className={styles.participant_title}>{participant.title}</span>
                            <span className={styles.participant_subtitle}>{participant.subtitle}</span>
                        </div>
                    )}

                    {manageable &&
                        (hostView && !diagnosticView ? (
                            <div className={styles.action_buttons}>
                                <button type="button" className="iconBtn" onClick={() => participant.kurentoParticipant!.changeOutputMute(true)}>
                                    {participant.kurentoParticipant!.outputAudioEnabledForAll ? <SpeakerIcon /> : <SpeakerDisabledIcon />}
                                </button>
                                <button type="button" className="iconBtn" onClick={() => participant.kurentoParticipant!.changeInputMute(true)}>
                                    {participant.kurentoParticipant!.inputAudioEnabledForAll ? <MicroIcon /> : <MicroDisabledIcon />}
                                </button>
                                <button type="button" className="iconBtn" onClick={() => participant.kurentoParticipant!.changeVideoMute(true)}>
                                    {participant.kurentoParticipant!.videoEnabledForAll ? <CameraIcon /> : <CameraDisabledIcon />}
                                </button>
                            </div>
                        ) : (
                            <div className={styles.action_buttons} onClick={(e) => e.stopPropagation()}>
                                <button type="button" className="iconBtn" onClick={() => participant.kurentoParticipant!.changeOutputMute()}>
                                    {participant.kurentoParticipant!.outputAudioEnabled ? <SpeakerIcon /> : <SpeakerDisabledIcon />}
                                </button>
                                <button type="button" className="iconBtn" onClick={() => participant.kurentoParticipant!.changeInputMute()}>
                                    {participant.kurentoParticipant!.inputAudioEnabled ? <MicroIcon /> : <MicroDisabledIcon />}
                                </button>

                                <button type="button" className="iconBtn" onClick={() => participant.kurentoParticipant!.changeVideoMute()}>
                                    {participant.kurentoParticipant!.videoEnabled ? <CameraIcon /> : <CameraDisabledIcon />}
                                </button>
                            </div>
                        ))}
                </div>

                {needProcedurePreview && (
                    <div className={styles.procedurePreview}>
                        <img src={`/img/procedurePreviews/${needProcedurePreview}.png`} alt="" />
                    </div>
                )}
            </div>

            {manageable && !hostView && !diagnosticView && (
                <>
                    <div className={styles.toolbar}>
                        <div className={styles.procedures_state}>
                            <div className={styles.indicators}>
                                <ProcedureTimerIcon />
                                <span className={styles.value}>{secondsAsTime(participant.relevantProcedure?.progressTime || 0)}</span>
                            </div>
                            <div className={styles.indicators}>
                                <ProcedureCheckIcon />
                                <span className={styles.value}>{participant.relevantProcedure?.interaction?.successTries || 0}</span>
                            </div>
                        </div>

                        <ul className={styles.procedures_list} onClick={() => selectHandler('procedures')}>
                            {procedureChips()}
                        </ul>
                    </div>

                    <div className={styles.patient_actions} onClick={(e) => e.stopPropagation()}>
                        <button
                            type="button"
                            className={classNames('iconBtn', styles.action_btn, styles.close_btn)}
                            onClick={() => kickOut!(participant)}
                        >
                            <CloseIcon />
                        </button>

                        {participant.selected === 'select' && (
                            <>
                                {awaitingScreenSharingFrom === participant.fhirId && (
                                    <button
                                        type="button"
                                        className={classNames('iconBtn', styles.action_btn)}
                                        onClick={() => requestScreenSharing(false)}
                                    >
                                        <ScreenDisabledIcon />
                                    </button>
                                )}
                                {!awaitingScreenSharingFrom && (
                                    <button
                                        type="button"
                                        className={classNames('iconBtn', styles.action_btn)}
                                        onClick={() => requestScreenSharing(true)}
                                    >
                                        <ScreenIcon />
                                    </button>
                                )}
                            </>
                        )}

                        <button type="button" className={classNames('iconBtn', styles.action_btn)} onClick={() => selectHandler('chat')}>
                            <ChatIcon />
                        </button>
                    </div>
                </>
            )}

            {participant.selected === 'chat' && serviceType === 'therapy-session' && (
                <div className={styles.newMessage} onClick={(e) => e.stopPropagation()}>
                    <button type="button" className={classNames('iconBtn', styles.action_btn)} onClick={() => selectHandler('chat')}>
                        <ChatWith3DotsIcon />
                    </button>
                </div>
            )}
        </div>
    );
};
