import i18n from 'i18next';
// import { HMRPlugin } from 'i18next-hmr/plugin';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import packageJson from '../../package.json';

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)

    // detect user language
    // .use(LanguageDetector)   // uncomment if necessary

    // pass the i18n instance to react-i18next.
    .use(initReactI18next)

    // .use(
    //     new HMRPlugin({
    //         client: typeof window !== 'undefined', // enables client side HMR
    //         server: typeof window === 'undefined', // enables server side HMR
    //     }),
    // )

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
        debug: false,
        returnNull: false,
        backend: {
            options: {
                loadPath: '/locales/{{lng}}/{{ns}}.json?version=' + packageJson.version,
            },
        },
    });

export default i18n;
