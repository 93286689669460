import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PATIENT_DIAGONAL } from 'src/ADMINISTRATIVE_CONFIG';
import { IDiagonalProps } from '../ScreenSettingsModal/ScreenSettingsModal';
import styles from './ScreenDiagonalModal.module.scss';

export const ScreenDiagonalModal = ({ onHandleChangeDiagonal, diagonal }: IDiagonalProps) => {
    const { t } = useTranslation();
    const [monitorDiagonal, setMonitorDiagonal] = useState<string>(diagonal);
    const [isDiagonalValid, setIsDiagonalValid] = useState<boolean>(true);

    useEffect(() => {
        if (!diagonal) {
            const value = Math.round(DEFAULT_PATIENT_DIAGONAL) || 0;
            setMonitorDiagonal(value.toString());

            if (value < 100) {
                setIsDiagonalValid(false);
            } else {
                setIsDiagonalValid(true);
            }
            onHandleChangeDiagonal(value.toString());
        }
    }, []);

    const handleChangeMonitorDiagonal = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Math.round(parseFloat(e.target.value)) || 0;
        setMonitorDiagonal(value.toString());

        if (value < 100) {
            setIsDiagonalValid(false);
        } else {
            setIsDiagonalValid(true);
        }
        onHandleChangeDiagonal(value.toString());
    };

    return (
        <div>
            <div className={styles.content}>
                <p className="mb-5">{t('modals.screenSettings.categories.diagonal.description')}</p>
                <div className="d-flex align-items-center justify-content-center mb-4">
                    <img className="col-6 me-4" width={100} src="/icons/modals/diagonal-screen-big.png" alt="monitor" />
                    <div className="col-6">
                        <div className={styles['recommendation-block']}>
                            <p className="col-6">{t('modals.screenSettings.categories.widthHeight.diagonal')}</p>
                            <input type="number" value={monitorDiagonal} onChange={handleChangeMonitorDiagonal} className={styles.input} />
                            <p style={{ marginLeft: '8px', color: 'red' }}>{t('modals.screenSettings.categories.widthHeight.si')}</p>
                        </div>
                        {!isDiagonalValid && <p style={{ textAlign: 'end', color: 'red' }}>{t('modals.screenSettings.categories.widthHeight.more_than_100_mm')}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};
