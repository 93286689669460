import { Input } from 'antd';
import React from 'react';

interface ISnilsInputProps {
    value: string;
    disabled?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }

export const SNILS_PATTERN = /^(\d{3}-){2}\d{3} \d{2}$/;

const SnilsInput = ({value, disabled, onChange}: ISnilsInputProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;
    
        inputValue = inputValue.replace(/\D/g, '');
    
        if (inputValue.length > 3) {
          inputValue = inputValue.slice(0, 3) + '-' + inputValue.slice(3);
        }
        if (inputValue.length > 7) {
          inputValue = inputValue.slice(0, 7) + '-' + inputValue.slice(7);
        }
        if (inputValue.length > 11) {
          inputValue = inputValue.slice(0, 11) + ' ' + inputValue.slice(11);
        }
        if (inputValue.length > 14) {
          inputValue = inputValue.slice(0, 14);
        }
    
        onChange({
          ...event,
          target: { ...event.target, value: inputValue },
        });
    };

    return (
        <Input
          type="text"
          onChange={handleChange}
          value={value}
          placeholder="___-___-___ __"
          disabled={disabled}
        />
      );
};

export default SnilsInput;
