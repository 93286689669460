import { Button, Checkbox, Form, Input, InputNumber } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ComplaintsStep.module.scss';
import { ComplaintsListEnum } from './dictionary';
import * as fs from "fs";

interface IComplaintOption {
    value: ComplaintsListEnum;
    comment: string;
    selected: boolean;
}

export interface IComplaintForm {
    medicalExamination?: boolean;
    complaints?: IComplaintOption[];
    complaintsComment?: string;
    diseaseReportedFrom?: number;
    glassesWearingFrom?: number;
    eventsHeld?: string;

    ownGlassesOdSph?: number;
    ownGlassesOdCyl?: number;
    ownGlassesOdAx?: number;
    ownGlassesOsSph?: number;
    ownGlassesOsCyl?: number;
    ownGlassesOsAx?: number;

    pregnancyBirth?: string;
    pregnancyProperties?: string;
    pregnancyChildbirth?: string;
    pregnancyComment?: string;

    pastIllnessesSurgeries?: string;
    heredity?: string;
    allergologicalHistory?: string;
    [key: string]: any;
}

interface IComplaintsStepProps {
    initialValues?: IComplaintForm;
    disabled?: boolean;

    onSubmit: (formValue: IComplaintForm) => void;
    onFormChange: (formValue: IComplaintForm) => void;
}

export const complaintsFormArray: IComplaintOption[] = Object.keys(ComplaintsListEnum).map((x) => ({
    value: x as ComplaintsListEnum,
    comment: '',
    selected: false,
}));

export const ComplaintsStep = ({ onSubmit, onFormChange, initialValues, disabled }: IComplaintsStepProps) => {
    const { t } = useTranslation();
    const [form] = useForm<IComplaintForm>();

    const isComplaintsRequired = () => {
        return !(form.getFieldValue('complaints')?.filter((x: IComplaintOption) => x.selected).length || form.getFieldValue('complaintsComment'));
    };

    // Ant doesn't rerender the whole form  if some field changes (even with shouldUpdate)
    // https://nanxiaobei.medium.com/watch-a-more-elegant-way-to-monitor-antd-form-field-changes-7c9b12457d67
    const complaintsValue = useWatch('complaints', form);
    const medicalExaminationValue = useWatch('medicalExamination', form);

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const handleFinish = (values: IComplaintForm) => {
        const fieldsToUpdate = [
            'ownGlassesOdSph',
            'ownGlassesOdCyl',
            'ownGlassesOdAx',
            'ownGlassesOsSph',
            'ownGlassesOsCyl',
            'ownGlassesOsAx',
        ];
        
        fieldsToUpdate.forEach(field => {
            if (values[field] > 0) {
                values[field] = `+${values[field]}`;
            }
        });
    
        onSubmit(values);
    };

    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>{t('consultationSession.complaintsStep.title')}</h2>

            <Form
                form={form}
                autoComplete="off"
                onFinish={handleFinish}
                onValuesChange={(_, all) => onFormChange(all)}
                layout="vertical"
                disabled={disabled}
            >
                <Form.Item name={'medicalExamination'} valuePropName="checked">
                    <Checkbox>{t("consultationSession.complaintsStep.patient_under_examination")}</Checkbox>
                </Form.Item>

                {!medicalExaminationValue && (
                    <>
                        <p className={styles.groupLabel}>{t("consultationSession.complaintsStep.complaints_on")}:</p>
                        <Form.List
                            name="complaints"
                            rules={[
                                {
                                    validator: async () => {
                                        if (isComplaintsRequired()) {
                                            return Promise.reject(new Error(''));
                                        }
                                    },
                                },
                            ]}
                        >
                            {(fields) =>
                                fields.map((field) => (
                                    <div key={field.key} className={styles.checkboxGroup}>
                                        <Form.Item name={[field.name, 'selected']} valuePropName="checked" shouldUpdate>
                                            <Checkbox>
                                                {t(`consultationSession.complaintsStep.complaintsList.${complaintsFormArray[field.key].value}`)}
                                            </Checkbox>
                                        </Form.Item>

                                        {complaintsValue?.[field.key]?.selected && (
                                            <Form.Item name={[field.name, 'comment']}>
                                                <Input placeholder={t("placeholder_comment")} />
                                            </Form.Item>
                                        )}
                                    </div>
                                ))
                            }
                        </Form.List>

                        <Form.Item name="complaintsComment" className={styles.formGroup}>
                            <TextArea placeholder={t("placeholder_comment")} />
                        </Form.Item>

                        {isComplaintsRequired() && !disabled && <p className={styles.groupError}>{t("consultationSession.complaintsStep.complaints_required_message")}</p>}

                        <div className={styles.formGrid22}>
                            <label>{t("consultationSession.complaintsStep.disease_from")}</label>

                            <Form.Item className={styles.formGroup}>
                                <Form.Item name="diseaseReportedFrom" noStyle rules={[{ required: true, message: t("required_field") }]}>
                                    <InputNumber min={0} max={100} />
                                </Form.Item>
                                <span className="ant-form-text">{t("consultationSession.complaintsStep.age")}</span>
                            </Form.Item>

                            <label>{t("consultationSession.complaintsStep.glasses_from")}</label>
                            <Form.Item className={styles.formGroup}>
                                <Form.Item name="glassesWearingFrom" noStyle>
                                    <InputNumber min={0} max={100} />
                                </Form.Item>
                                <span className="ant-form-text">{t("consultationSession.complaintsStep.age")}</span>
                            </Form.Item>

                            <label>{t("consultationSession.complaintsStep.ongoing_events")}</label>
                            <Form.Item name="eventsHeld">
                                <Input />
                            </Form.Item>
                        </div>

                        <p style={{display: 'none'}} className={styles.groupLabel}>{t("consultationSession.complaintsStep.my_glasses")}</p>
                        <div style={{display: 'none'}} className={styles.glassesGrid}>
                            <p className={styles.groupLabel}>OD</p>
                            <span className="ant-form-text">sph</span>
                            <Form.Item name={'ownGlassesOdSph'} noStyle>
                                <InputNumber step={0.1} />
                            </Form.Item>
                            <span className="ant-form-text">cyl</span>
                            <Form.Item name={'ownGlassesOdCyl'} noStyle>
                                <InputNumber step={0.1} />
                            </Form.Item>
                            <span className="ant-form-text">ax</span>
                            <Form.Item name={'ownGlassesOdAx'} noStyle>
                                <InputNumber step={0.1} />
                            </Form.Item>

                            <p className={styles.groupLabel}>OS</p>
                            <span className="ant-form-text">sph</span>
                            <Form.Item name={'ownGlassesOsSph'} noStyle>
                                <InputNumber step={0.1} />
                            </Form.Item>
                            <span className="ant-form-text">cyl</span>
                            <Form.Item name={'ownGlassesOsCyl'} noStyle>
                                <InputNumber step={0.1} />
                            </Form.Item>
                            <span className="ant-form-text">ax</span>
                            <Form.Item name={'ownGlassesOsAx'} noStyle>
                                <InputNumber step={0.1} />
                            </Form.Item>
                        </div>
                    </>
                )}

                <p className={styles.groupLabel}>{t("consultationSession.complaintsStep.pregnancy")}</p>
                <div className={styles.formGrid22}>
                    <label>{t("consultationSession.complaintsStep.born")}</label>
                    <Form.Item name="pregnancyBirth" rules={[{ required: false, message: t("required_field") }]}>
                        {/* <Select>
                            {PregnancyBirthOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {x}
                                </Option>
                            ))}
                        </Select> */}
                        <Input />
                    </Form.Item>

                    <label>{t("consultationSession.complaintsStep.pregnancy")}</label>
                    <Form.Item name="pregnancyProperties" rules={[{ required: false, message: t("required_field") }]}>
                        {/* <Select>
                            {PregnancyOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {x}
                                </Option>
                            ))}
                        </Select> */}
                        <Input />
                    </Form.Item>

                    <label>{t("consultationSession.complaintsStep.childbirth")}</label>
                    <Form.Item name="pregnancyChildbirth" rules={[{ required: false, message: t("required_field") }]}>
                        {/* <Select>
                            {PregnancyChildbirthOptions.map((x) => (
                                <Option key={x} value={x}>
                                    {x}
                                </Option>
                            ))}
                        </Select> */}
                        <Input />
                    </Form.Item>
                </div>
                <Form.Item name="pregnancyComment" className={classNames(styles.formGroup, 'mt-2')}>
                    <TextArea placeholder={t("placeholder_comment")} />
                </Form.Item>

                <p className={styles.groupLabel}>{t("consultationSession.complaintsStep.previous_illnesses_operations")}</p>
                <Form.Item name="pastIllnessesSurgeries" className={styles.formGroup}>
                    <TextArea placeholder={t("placeholder_comment")} />
                </Form.Item>

                <Form.Item name="heredity" label={t("consultationSession.complaintsStep.heredity")} className={styles.formGroup}>
                    {/* <Select>
                        {HeredityOptions.map((x) => (
                            <Option key={x} value={x}>
                                {x}
                            </Option>
                        ))}
                    </Select> */}
                    <Input />
                </Form.Item>

                <Form.Item
                    name="allergologicalHistory"
                    label={t("consultationSession.complaintsStep.allergological_history")}
                    className={styles.formGroup}
                    rules={[{ required: true, message: t("required_field") }]}
                >
                    {/* <Select>
                        {AllergologicalHistoryOptions.map((x) => (
                            <Option key={x} value={x}>
                                {x}
                            </Option>
                        ))}
                    </Select> */}
                    <Input />
                </Form.Item>

                {!disabled && (
                    <Button className="w-100 mt-3" type="primary" htmlType="submit">
                        {t("next_button")}
                    </Button>
                )}
            </Form>
        </div>
    );
};
