import { FileSafeDTOBucketType, uploadFile, UserDocumentDTOType } from '@api/documentServiceAPI';
import {
    EncounterDetailDTOServiceType,
    EncounterDetailDTOStatus,
    EncounterDTODetails,
    obtainEncounterDetailsByAppointmentId,
    UserDocumentDTO,
} from '@api/mainServiceAPI';
import { CountdownTimer } from '@components';
import { AppointmentStatus } from '@enums';
import { AlertIconOutlined, CalendarIcon, CameraIconGrey, ChevronLeft, DocumentIcon, DownloadIcon, FileAddedIcon, UserIcon } from '@icons';
import { FilesUploadModal } from '@modals';
import { setFileViewer } from '@sliceCore';
import { selectAuthUser, selectCurrentProfile, UserRole } from '@sliceUser';
import { Logger } from '@utils/logger';
import { separateFileNameAndExtension } from '@utils/utils';
import { Button, Col, Form, MenuProps, Row, Tag } from 'antd';
import { RcFile } from 'antd/es/upload';
import classNames from 'classnames';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { MED_TIME_BEFORE_START_OF_SESSION } from 'src/ADMINISTRATIVE_CONFIG';
import { Chats } from 'src/components/Chats/Chats';
import { EncounterStatusBadge } from 'src/components/EncounterStatusBadge/EncounterStatusBadge';
import { PatientForms } from 'src/components/PatientForms/PatientForms';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import { UploadFileExt } from 'src/modals/FilesUploadModal/FilesUploadModal';
import { getEncounterStatus } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/dictionary';
import styles from './PractitionerAppointmentItemPage.module.scss';

interface IChangeReasonForm {
    lowVision: boolean;
    reducedDistanceVision: boolean;
    reducedNearVision: boolean;
    exotropia: boolean;
    convergentStrabismus: boolean;
    description: string;
}

export const PractitionerAppointmentItemPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const currentUser = useSelector(selectAuthUser);
    const currentProfile = useSelector(selectCurrentProfile);

    const [encounter, setEncounter] = useState<EncounterDTODetails>();
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [showEditReason, setShowEditReason] = useState(false);

    const [changeReasonForm] = Form.useForm();

    const { validateForm } = useFormValidation(changeReasonForm);

    useEffect(() => {
        retrieveEncounterDetails();
    }, []);

    const retrieveEncounterDetails = () => {
        if (params.appointmentId) {
            obtainEncounterDetailsByAppointmentId(params.appointmentId).then((result) => {
                if (result.data) {
                    setEncounter(result.data);
                }
            });
        }
    };

    const onBackToAllAppointments = () => {
        navigate('/appointments');
    };

    // todo payment logic after backend
    const items: MenuProps['items'] = [
        {
            label: <a onClick={() => console.log('Copy payment link')}>{t('appointment.paymentOptions.copyLink')}</a>,
            key: '0',
        },
        {
            label: <a onClick={() => console.log('Send payment on email')}>{t('appointment.paymentOptions.sendEmail')}</a>,
            key: '1',
        },
    ];

    // todo after payment
    const onPaymentDetails = () => {
        navigate('/');
    };

    const onPatientDetails = (patientId?: string) => {
        navigate('/medcard/' + patientId);
    };

    // todo open course for patient
    const onCourseDetails = () => {
        console.log('open course details');
    };

    // todo upload documents modal?
    const onUploadDocuments = () => {
        setShowFileUploadModal(true);
    };

    // todo change appointment
    const handleChangeDate = () => {
        navigate('/');
    };

    // const onCancelConsultation = () => {
    //     endEncounterEvent({
    //         encounterFhirId: encounter?.encounterDetailDTO?.encounterFhirId,
    //         managerId: currentUser?.id,
    //         cancel: true,
    //     }).then((result) => {
    //         if (result) {
    //             retrieveEncounterDetails();
    //         }
    //     });
    // };

    const uploadDocuments = (files: UploadFileExt[]) => {
        if (files.length) {
            files.forEach((f, i) => {
                if (f.done || f.loading) {
                    return;
                }
                f.loading = true;

                uploadFile(
                    {
                        fhirId: encounter?.encounterDetailDTO?.patientFhirId,
                        type: f.docType,
                        file: f as RcFile,
                        typeDescription: f.otherType,
                        ...separateFileNameAndExtension(f.name),
                    },
                    { headers: { 'Content-Type': 'multipart/form-data' } },
                )
                    .then((res: any) => {
                        f.done = true;
                        f.loading = false;
                        f.uuid = res.data.uuid;
                    })
                    .catch((err) => {
                        Logger.error(err);
                    });
            });
        }
    };

    const onEditDiagnosis = () => {
        setShowEditReason(true);
    };

    const validate = () => {
        const fieldsToCheck = ['lastName', 'firstName', 'mobile', 'email', 'role'];
        validateForm(fieldsToCheck);
    };

    const changeReason = (formData: IChangeReasonForm) => {
        if (!changeReasonForm) {
            return;
        }

        changeReasonForm.validateFields();
        setTimeout(() => {
            if (!changeReasonForm.getFieldsError().find(({ errors }: { errors: string[] }) => errors.length > 0)) {
                // todo backend change reason
            }
        });
    };

    //TODO remove redundant field
    const openDocument = (doc: UserDocumentDTO & { s3Key?: string }) => {
        const key = doc.s3Key || doc.s3key;
        if (key) {
            dispatch(
                setFileViewer({
                    s3Key: key,
                    bucketType: doc.type === FileSafeDTOBucketType.VIDEO ? FileSafeDTOBucketType.VIDEO : FileSafeDTOBucketType.DOCUMENT,
                    fileName: doc.extension ? `${doc.name}.${doc.extension}` : (doc.name as string),
                    extension: doc.extension,
                }),
            );
        }
    };

    const encounterStatus = getEncounterStatus(encounter?.encounterDetailDTO?.status, encounter?.encounterDetailDTO?.paymentStatus);

    const isConsultation = () => {
        return encounter?.encounterDetailDTO?.serviceType === EncounterDetailDTOServiceType.consultation;
    };

    const isDocumentFromPatients = (document: UserDocumentDTO): boolean => {
        return (
            document.type === UserDocumentDTOType.DISCHARGE_SUMMARY ||
            document.type === UserDocumentDTOType.CONSULTATION ||
            document.type === UserDocumentDTOType.PERSONAL_DATA_STATEMENT ||
            document.type === UserDocumentDTOType.CERTIFIED_OPHTHALMOLOGISTS_REPORT ||
            document.type === UserDocumentDTOType.ANALYZES ||
            document.type === UserDocumentDTOType.SNAPSHOTS ||
            document.type === UserDocumentDTOType.OTHER
        );
    };

    const isDocumentVideo = (document: UserDocumentDTO): boolean => {
        return document.type === UserDocumentDTOType.VIDEO;
    };

    const onConnectToConsultation = () => {
        navigate('/appointment?appointmentId=' + encounter?.encounterDetailDTO?.appointmentFhirId);
    };

    return encounter ? (
        <div className={styles.wrapper}>
            <div className="d-flex justify-content-between">
                <div className={styles.titleContainer}>
                    <Button onClick={() => onBackToAllAppointments()} className="backBtn">
                        <ChevronLeft className="transparent" />
                    </Button>
                    <p className={styles.title}>
                        {encounter?.encounterDetailDTO?.appointmentType && encounter?.encounterDetailDTO?.appointmentType === 'diagnostic'
                            ? t(`patientAppointmentItemPage.title.diagnosticPlanDefinition.${encounter?.partOf}`)
                            : encounter?.encounterDetailDTO?.appointmentType && encounter?.encounterDetailDTO?.appointmentType === 'course'
                            ? t(`patientAppointmentItemPage.title.coursePlanDefinition.${encounter?.partOf}`)
                            : t(`patientAppointmentItemPage.title.${encounter?.encounterDetailDTO?.appointmentType}`)}
                    </p>
                </div>
            </div>

            <Row gutter={24}>
                <Col md={16} sm={24} xs={24}>
                    <Col md={24} sm={24} xs={24}>
                        <div className={styles.eventBottomBlock}>
                            {/* Encounter Field */}
                            <div className={styles.infoField}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div>
                                            <CalendarIcon />
                                        </div>
                                        <div>
                                            <p>{t(`appointment.dateTime`)}</p>
                                            <div className="d-flex">
                                                <p className={styles.dateText}>
                                                    {encounter?.encounterDetailDTO?.dateTime &&
                                                        format(new Date(encounter?.encounterDetailDTO?.dateTime), 'dd.MM.yyyy HH:mm')}
                                                </p>
                                                <div className={styles.statusField}>
                                                    <EncounterStatusBadge
                                                        encounterStatus={encounter?.encounterDetailDTO?.status}
                                                        paymentStatus={encounter?.encounterDetailDTO?.paymentStatus}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {encounter?.encounterDetailDTO?.dateTime &&
                                        encounterStatus === AppointmentStatus.confirmed &&
                                        encounter?.encounterDetailDTO?.practitionerFhirId === currentProfile?.fhirId && (
                                            <CountdownTimer
                                                timeX={new Date(encounter?.encounterDetailDTO?.dateTime)}
                                                before={MED_TIME_BEFORE_START_OF_SESSION}
                                                keepAfterX={true}
                                                render={(timeLabel: string) => (
                                                    <div className="d-flex">
                                                        {timeLabel && (
                                                            <Tag className={styles.startsOver}>
                                                                {t('docMainPage.startsOver')} <strong className="h6">{timeLabel}</strong>
                                                            </Tag>
                                                        )}
                                                        <Button type="primary" onClick={() => onConnectToConsultation()}>
                                                            {t('appointment.connect')}
                                                        </Button>
                                                    </div>
                                                )}
                                            ></CountdownTimer>
                                        )}
                                </div>

                                {encounter?.encounterDetailDTO?.status == EncounterDetailDTOStatus.cancelled && (
                                    <div className={styles.cancelReasonBlock}>
                                        <AlertIconOutlined />
                                        <p>{t('appointment.cancelReason')}</p>
                                        <p>
                                            {t(
                                                'enums.encounterChangeReason.' + encounter?.encounterDetailDTO?.changeReason?.at(-1)?.type,
                                                t("not_specified_1"),
                                            )}
                                        </p>
                                    </div>
                                )}
                            </div>

                            {/* Patient Field */}
                            <div className={styles.infoField}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex">
                                        <div className={styles.avatar}>
                                            {encounter?.encounterDetailDTO?.patientPhoto ? (
                                                <img src={encounter?.encounterDetailDTO?.patientPhoto} />
                                            ) : (
                                                <UserIcon />
                                            )}
                                        </div>
                                        <div>
                                            <NavLink to={'/medcard/' + encounter?.encounterDetailDTO?.patientFhirId} className={styles.nameText}>
                                                {encounter?.encounterDetailDTO?.patientName}
                                            </NavLink>
                                            <div className="d-flex">
                                                <p className={styles.nameSubtext}>
                                                    {dayjs(encounter?.encounterDetailDTO?.patientBirthDay).fromNow(true)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    {/* Consultation Block Completed */}
                    {isConsultation() &&
                        (encounterStatus === AppointmentStatus.completed && encounter?.documents && encounter.documents.length > 0 ? (
                            <Col md={24} sm={24} xs={24}>
                                <div className={styles.infoBlock}>
                                    <h3>{t('appointment.results')}</h3>
                                    {!!encounter?.forms?.length && (
                                        <div className={styles.infoField}>
                                            <p className={styles.infoLabel}>{t("practitioner_appointment_item.form")}</p>
                                            <PatientForms forms={encounter?.forms} />
                                        </div>
                                    )}

                                    {encounter.documents.filter((item) => isDocumentFromPatients(item)).length > 0 && (
                                        <div className={styles.infoField}>
                                            <p className={styles.infoLabel}>{t("practitioner_appointment_item.documents_by_patient")}</p>
                                            {encounter.documents
                                                .filter((item) => isDocumentFromPatients(item))
                                                .map((item) => (
                                                    <div
                                                        className={classNames(styles.infoItem, 'pointer')}
                                                        key={item.documentFhirId}
                                                        onClick={() => openDocument(item)}
                                                    >
                                                        <DocumentIcon /> {t('appointment.documentTypes.' + item.type)}
                                                    </div>
                                                ))}
                                        </div>
                                    )}

                                    {encounter.documents.filter((item) => item.type === 'CONCLUSION').length > 0 && (
                                        <div className={styles.infoField}>
                                            <p className={styles.infoLabel}>{t("practitioner_appointment_item.report")}</p>
                                            {encounter.documents
                                                .filter((item) => item.type === 'CONCLUSION')
                                                .map((item) => (
                                                    <div
                                                        className={classNames(styles.infoItem, 'pointer')}
                                                        key={item.documentFhirId}
                                                        onClick={() => openDocument(item)}
                                                    >
                                                        <DocumentIcon /> {t('appointment.documentTypes.' + item.type)}
                                                    </div>
                                                ))}
                                        </div>
                                    )}

                                    {encounter.documents.filter((item) => isDocumentVideo(item)).length > 0 && (
                                        <div className={styles.infoField}>
                                            <p className={styles.infoLabel}>{t("practitioner_appointment_item.video")}</p>
                                            {encounter.documents
                                                .filter((item) => isDocumentVideo(item))
                                                .map((item) => (
                                                    <div
                                                        onClick={() => openDocument(item)}
                                                        className={classNames(styles.infoItem, 'pointer')}
                                                        key={item.documentFhirId}
                                                    >
                                                        <div className="d-flex justify-content-between" style={{ margin: 0 }}>
                                                            <div>
                                                                <CameraIconGrey /> {item.name}
                                                            </div>
                                                            <Button type="ghost" className={styles.downloadButton}>
                                                                <DownloadIcon />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        ) : (
                            // Consultation Block Not completed
                            <Col md={24} sm={24} xs={24}>
                                <div className={styles.infoBlock}>
                                    <h3>{t('appointment.initialInfo')}</h3>
                                    <div style={{ marginTop: '24px' }}>
                                        <p className={styles.infoTitle}>{t("practitioner_appointment_item.preliminary_diagnosis")}</p>
                                        <p className={styles.infoDescription}>
                                            {encounter?.encounterDetailDTO?.diagnosis
                                                ?.map((x) => t('enums.preliminaryDiagnosis.' + x))
                                                .join(', ') || <span className={styles.notFilled}>{t("practitioner_appointment_item.empty_data")}</span>}
                                        </p>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        <p className={styles.infoTitle}>{t("practitioner_appointment_item.complaints")}</p>
                                        {!!encounter?.encounterDetailDTO?.reasonCodes?.length ? (
                                            <ul>
                                                {encounter?.encounterDetailDTO.reasonCodes.map((x, i) => (
                                                    <li key={i}>{t('consultationSession.patientInfoStep.reasonCodes.' + x)}</li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <span className={styles.notFilled}>{t("practitioner_appointment_item.empty_data")}</span>
                                        )}
                                        <p>{encounter?.encounterDetailDTO?.comment}</p>
                                    </div>

                                    {encounter?.documents && encounter.documents?.filter((item) => isDocumentFromPatients(item)).length > 0 && (
                                        <div style={{ marginTop: '36px' }} className={styles.infoField}>
                                            <p className={styles.infoTitle}>{t("practitioner_appointment_item.uploaded_documents")}</p>
                                            {encounter.documents
                                                .filter((item) => isDocumentFromPatients(item))
                                                .map((item) => (
                                                    <div
                                                        onClick={() => openDocument(item)}
                                                        className={classNames(styles.infoItem, 'pointer')}
                                                        key={item.documentFhirId}
                                                    >
                                                        <DocumentIcon /> {t('appointment.documentTypes.' + item.type)}{' '}
                                                        {item.typeDescription && <>({item.typeDescription})</>}
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        ))}

                    {/* NotCompleted: Documents */}
                    {encounterStatus !== AppointmentStatus.completed && encounterStatus === AppointmentStatus.cancelled && (
                        <Col md={24} sm={24} xs={24}>
                            <h3 className={styles.loadedDocumentsText}>{t('appointment.loadedDocuments')}</h3>
                            {encounter?.documents && encounter?.documents?.length > 0 && (
                                <>
                                    <div className={styles.documentsBlock}>
                                        {encounter.documents.map((item) => (
                                            <div className={styles.infoField} key={item.documentFhirId}>
                                                <div className="d-flex">
                                                    <div>
                                                        <FileAddedIcon />
                                                    </div>
                                                    <div>
                                                        <h5 className={styles.documentLabel}>{t(`appointment.documentTypes.${item.type}`)}</h5>
                                                        <p>{item.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                            {/* todo check upload documents */}

                            <Button style={{ marginTop: '16px' }} onClick={() => onUploadDocuments()}>
                                {t('appointment.loadDocuments')}
                            </Button>
                        </Col>
                    )}
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Chats
                        currentUserRole={UserRole.Practitioner}
                        currentUserId={currentProfile?.fhirId}
                        chats={encounter?.chats}
                        headerInfo={t("chat.any_questions")}
                    />
                </Col>
            </Row>

            {showFileUploadModal && (
                <FilesUploadModal
                    onCancel={() => setShowFileUploadModal(false)}
                    onSuccess={(files) => {
                        // setData({ ...data, filesToUpload: files });
                        uploadDocuments(files);
                        setShowFileUploadModal(false);
                    }}
                    files={[]}
                    showModal={showFileUploadModal}
                />
            )}
        </div>
    ) : null;
};
