import {
    AppointmentParticipantDetailsDTO,
    createOrUpdateDiagnosticResult,
    createOrUpdateProcedureResult,
    getAppointmentParticipantDetails,
    ParticipantDTO,
    storeTherapySession,
    updateAppointment,
    updateStatusEncounter,
    updateTherapySession,
} from '@api/mainServiceAPI';
import { VideoParticipant } from '@components';
import { getProcedureTargetPoints, INTERACTIVE_PROCEDURES } from '@configProcedures';
import { ProcedureStatus, ProcedureTypeEnum } from '@enums';
import { ShrinkIcon } from '@icons';
import { IChatMessage, IParticipantProcedure, IPatientProcedureInteraction, ParticipantProcedure, ProcedureSettingsValueType } from '@models';
import { selectActiveScreenSharingParticipant, setAwaitingScreenSharingParticipant } from '@sliceProcedures';
import { selectCurrentProfile } from '@sliceUser';
import { StompSubscription } from '@stomp/stompjs';
import { Logger } from '@utils/logger';
import { WsProcedureTopicType, WsTopic } from '@utils/websocket.topics';
import { Button, message, Modal } from 'antd';
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { KurentoContext } from 'src/contexts/kurento.context';
import { WebsocketContext } from 'src/contexts/ws.context';
import { DiagnosticTypeOfCourse } from 'src/COURSES_CONFIG';
import { useForceUpdate } from 'src/hooks/force-update.hook';
import { IGeneralProcedure } from 'src/models/general-procedure.model';
import { IProcedureResult } from 'src/models/procedure-result.model';
import { SessionParticipant } from 'src/models/session-participant';
import { ConsultationSession } from 'src/pages/_shared/DoctorAppointmentPage/sessions/ConsultationSession/ConsultationSession';
import { TherapySession } from 'src/pages/_shared/DoctorAppointmentPage/sessions/TherapySession/TherapySession';
import { HostSidebar } from './components/HostSidebar/HostSidebar';
import styles from './DoctorAppointmentPage.module.scss';
import { ParticipantMockProcedures } from './mock';
import { fixProcedureSettingsTypes, IProcedureFormItem } from './sessions/ConsultationSession/steps/CourseStep/CourseStep';
import { DiagnosticSession } from './sessions/DiagnosticSession/DiagnosticSession';

export type IParticipantPlaceholder = ParticipantDTO;

export type SelectedParticipantTabType = 'info' | 'procedures' | 'chat' | 'documents' | null;

export const DoctorAppointmentPage = () => {
    const { t } = useTranslation();
    const forceUpdate = useForceUpdate();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { connectToAppointment, closeSession, approveFromLobby, participants, lobbyParticipants, host, kurentoConnected } =
        useContext(KurentoContext);
    const { wsClient, send, wsReconnected } = useContext(WebsocketContext);

    const currentProfile = useSelector(selectCurrentProfile);
    const selectedParticipantId = useRef<string | undefined>();

    const [someProceduresInProgress, setSomeProceduresInProgress] = useState(false);
    const [appointmentDetails, setAppointmentDetails] = useState<AppointmentParticipantDetailsDTO>();

    const [wsMessage, setWsMessage] = useState<any>(null);
    const [startedTime, setStartedTime] = useState<Date | null>(null);
    const [hostSessionParticipant, setHostSessionParticipant] = useState<SessionParticipant | undefined>();
    const [sessionParticipants, setSessionParticipants] = useState<SessionParticipant[]>([]);
    const [selectedParticipant, setSelectedParticipant] = useState<SessionParticipant | undefined>();
    const [selectedParticipantTab, setSelectedParticipantTab] = useState<SelectedParticipantTabType>(null);
    const [participantForModal, setParticipantForModal] = useState<SessionParticipant | undefined>();
    const [releaseParticipantConfirmationModalOpen, setReleasePatientConfirmationModalOpen] = useState(false);

    const [closeRoomConfirmationModalOpen, setCloseRoomConfirmationModalOpen] = useState(false);
    const [kickParticipantConfirmationModalOpen, setKickPatientConfirmationModalOpen] = useState(false);

    const activeScreenSharingParticipantId = useSelector(selectActiveScreenSharingParticipant);
    const [activeScreenSharingParticipant, setActiveScreenSharingParticipant] = useState<SessionParticipant | undefined>(undefined);
    const [isPatientFullScreen, setIsPatientFullScreen] = useState(false);
    const [isCalibrationReady, setIsCalibrationReady] = useState<boolean>(false);
    const [activePatientDiagonal, setActivePatientDiagonal] = useState<string>('');

    useEffect(() => {
        setSessionParticipants((prev) => {
            const newParticipants = [...prev];
            newParticipants.forEach((p) => {
                const kurentoParticipant = participants.find((x) => x.id === p.fhirId);
                p.kurentoParticipant = kurentoParticipant;

                // if participant left - stop/reset all his procedures
                if (!kurentoParticipant) {
                    stopProceduresForAll(p);
                }
            });

            return newParticipants;
        });
    }, [participants]);

    useEffect(() => {
        if (host) {
            const participant = new SessionParticipant();
            participant.kurentoParticipant = host;
            participant.fhirId = host.id;
            setHostSessionParticipant(participant);
        }
    }, [host]);

    useEffect(() => {
        setSessionParticipants((prev) => {
            const newParticipants = [...prev];
            newParticipants.forEach((p) => {
                p.inLobby = !!lobbyParticipants.find((x) => x.id === p.fhirId);
            });

            return newParticipants;
        });
    }, [lobbyParticipants]);

    useEffect(() => {
        if (wsReconnected) {
            location.reload(); // todo hotfix
        }
    }, [wsReconnected]);

    useEffect(() => {
        if (activeScreenSharingParticipantId && sessionParticipants.length) {
            const participantToBeActive = sessionParticipants.find((p) => p.fhirId === activeScreenSharingParticipantId);
            if (participantToBeActive) {
                setActiveScreenSharingParticipant(participantToBeActive);
                // selectEvent('procedures', participantToBeActive);
            }
        } else {
            setActiveScreenSharingParticipant(undefined);
        }
    }, [activeScreenSharingParticipantId, sessionParticipants]);

    useEffect(() => {
        if (appointmentDetails?.serviceType === 'therapy-session') {
            sessionParticipants.forEach((part) => {
                if (!part.therapySessionResultId) {
                    if (part.therapySessionResultDTO?.id) {
                        part.therapySessionResultId = part.therapySessionResultDTO?.id;
                    } else {
                        storeTherapySession({
                            encounterId: part?.encounterFhirId,
                            carePlanId: part.carePlanDTO?.fhirId,
                            patientId: part.fhirId,
                            result: {},
                        }).then((res) => {
                            part.therapySessionResultId = res.data.id;
                        });
                    }
                }
            });
        }
    }, [sessionParticipants, appointmentDetails]);

    useEffect(() => {
        sessionParticipants.forEach((part) => {
            if (!part.procedures.length) {
                // the type of DTO from the frontend (ConsultationSession->CourseStep)
                const targetProcedures: IParticipantProcedure[] =
                    part.carePlanDTO?.procedureSettings?.map((p: IProcedureFormItem, i: number) => {
                        const targetSettings = p[part.currentSessionTargetEye];
                        const parsedSettings: Partial<ProcedureSettingsValueType> = fixProcedureSettingsTypes(targetSettings || {});

                        if (p.procedure === ProcedureTypeEnum.DISSOCIATION) {
                            parsedSettings.duration = 7200; // technically infinite
                        }

                        const result: IParticipantProcedure = {
                            id: i.toString(), // todo
                            order: i, // todo
                            type: p.procedure,
                            settings: parsedSettings,
                            targetPoints: parsedSettings.duration ? getProcedureTargetPoints(+parsedSettings.duration, p.procedure) : 0,
                            isInteractive: INTERACTIVE_PROCEDURES.includes(p.procedure),
                        };

                        const procedureResult: IProcedureResult | undefined = part.procedureResults?.find(
                            (x) => x.procedureType === p.procedure,
                        )?.result;

                        if (procedureResult) {
                            result.doctorComment = procedureResult.comment;
                            result.status = (procedureResult.unSuccess as any) === 'true' ? ProcedureStatus.failed : ProcedureStatus.finished;
                            result.doctorConfirmed = !!procedureResult.comment;
                            result.startedOrder = +(procedureResult.startedOrder || 0);

                            const duration = +(procedureResult.duration || parsedSettings.duration || 0);
                            // result.settings!.duration = duration;
                            result.targetPoints = duration ? getProcedureTargetPoints(duration, p.procedure) : 0;
                            result.totalDuration = duration;
                            result.interaction = {
                                successTries: +(procedureResult.successTries || 0),
                                failedTries: +(procedureResult.failedTries || 0),
                                totalTries: +(procedureResult.totalTries || 0),
                            };
                        }

                        return result;
                    }) || JSON.parse(JSON.stringify(ParticipantMockProcedures)); // todo it's just for testing

                const procedures = targetProcedures.map((p) => new ParticipantProcedure(p, () => procedureEndedInteraction(part.fhirId!, p.id!)));

                if (part.procedures.some((x) => x.status === 'failed' || x.status === 'finished')) {
                    // move completed procedures to the bottom
                    part.procedures = procedures.sort((a, b) => {
                        if (a.status === ProcedureStatus.finished && b.status !== ProcedureStatus.finished) {
                            return 1;
                        }
                        if (a.status === ProcedureStatus.failed && b.status !== ProcedureStatus.finished && b.status !== ProcedureStatus.failed) {
                            return 1;
                        }
                        return -1;
                    });
                } else {
                    part.procedures = procedures;
                }
            }
        });

        forceUpdate();
    }, [sessionParticipants]);

    useEffect(() => {
        if (wsMessage) {
            switch (wsMessage.type) {
                case WsProcedureTopicType.procedureInteraction:
                    handleProcedurePatientInteraction(wsMessage.participantId, wsMessage.procedureId, wsMessage.interaction);
                    break;
                case WsProcedureTopicType.callDoctor:
                    {
                        if (appointmentDetails?.serviceType === 'therapy-session') {
                            const participant = sessionParticipants.find((x) => x.fhirId === wsMessage.participantId);
                            if (participant) {
                                participant.select('call');
                            } else {
                                Logger.error('Cannot find participant', { wsMessage, sessionParticipants });
                            }
                        }
                    }
                    break;
                case WsProcedureTopicType.cancelScreenSharing:
                    {
                        const participant = sessionParticipants.find((x) => x.fhirId === wsMessage.participantId);
                        dispatch(setAwaitingScreenSharingParticipant(undefined));
                        message.info(
                            `${t("doctor_appointments.screen_share_request_rejected")}${participant ? ' "' + participant?.patient?.givenName + '"' : ''}`,
                        );
                    }
                    break;
                case WsProcedureTopicType.chatMessage:
                    {
                        const msg = wsMessage.message as IChatMessage;
                        const senderParticipant = sessionParticipants.find((x) => x.fhirId === msg.senderId);

                        if (senderParticipant) {
                            senderParticipant.chatHistory = [...senderParticipant.chatHistory, { ...msg, sent: true }];

                            if (!(selectedParticipant?.fhirId === senderParticipant.fhirId && selectedParticipantTab === 'chat')) {
                                senderParticipant.select('chat');
                            }

                            const message: IChatMessage = {
                                id: msg.id,
                                senderId: currentProfile?.fhirId,
                            };

                            send('/msg/gateway-ws/message', '/procedure/' + senderParticipant.fhirId, {
                                type: WsProcedureTopicType.messageSent,
                                message,
                            });
                        }
                    }
                    break;
                case WsProcedureTopicType.messageSent:
                    {
                        const msg = wsMessage.message as IChatMessage;
                        const senderParticipant = sessionParticipants.find((x) => x.fhirId === msg.senderId);
                        if (senderParticipant) {
                            const message = senderParticipant?.chatHistory.find((x) => x.id === msg.id);

                            if (message) {
                                message.sent = true;
                            }
                        }
                    }
                    break;
                case WsProcedureTopicType.messageViewed:
                    {
                        const msg = wsMessage.message as IChatMessage;
                        const senderParticipant = sessionParticipants.find((x) => x.fhirId === msg.senderId);
                        if (senderParticipant) {
                            const message = senderParticipant?.chatHistory.find((x) => x.id === msg.id);

                            if (message) {
                                message.viewed = true;
                            }
                        }
                    }
                    break;
                case WsProcedureTopicType.calibrationReady:
                    setIsCalibrationReady(wsMessage.state);
                    onToggleCalibration(false);
                    break;
                // patient toggled pause
                case WsProcedureTopicType.togglePauseProcedure:
                    {
                        const targetParticipant = sessionParticipants.find((x) => x.fhirId === wsMessage.participantId);
                        const participantProcedures = targetParticipant?.procedures || [];
                        const targetProcedureIndex = participantProcedures.findIndex((x) => x.id === wsMessage.procedureId);
                        if (targetProcedureIndex !== -1) {
                            participantProcedures[targetProcedureIndex].status = wsMessage.state ? ProcedureStatus.paused : ProcedureStatus.continued;
                            targetParticipant?.select('warning');

                            // allow for the user to set pause
                            const body = {
                                type: WsProcedureTopicType.togglePauseProcedure,
                                state: wsMessage.state,
                            };
                            send('/msg/gateway-ws/message', '/procedure/' + selectedParticipant?.fhirId, body);

                            forceUpdate();
                        }
                    }
                    break;
                case WsProcedureTopicType.screenSettingsUpdate:
                    {
                        if (wsMessage?.body?.diagonal) {
                            setActivePatientDiagonal(wsMessage.body.diagonal);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }, [wsMessage, sessionParticipants]);

    useEffect(() => {
        let subscription: StompSubscription;

        if (currentProfile && wsClient) {
            getAppointmentParticipantDetails(searchParams.get('appointmentId')!).then(async (res) => {
                if (res.data) {
                    if (res.data.appointmentStatus === 'fulfilled' || res.data.appointmentStatus === 'cancelled') {
                        message.warning(t("doctor_appointments.session_already_ended"));
                        navigate('/');
                        return;
                    }

                    const mediaDevicesAvailable = await checkMediaDevices();
                    if (!mediaDevicesAvailable) {
                        alert(t("doctor_appointments.setup_audio_video_devices"));
                        navigate('/');
                        return;
                    }

                    if (res.data.participantDTOList?.length) {
                        setSessionParticipants(
                            res.data.participantDTOList
                                .sort((a, b) => a.patient!.familyName!.localeCompare(b.patient!.familyName!))
                                .map((x) => new SessionParticipant(x)),
                        );
                    }
                    setAppointmentDetails(res.data);

                    connectToAppointment(
                        currentProfile.fhirId!,
                        searchParams.get('appointmentId')!,
                        true,
                        res.data.serviceType === 'therapy-session',
                    );

                    subscription = wsClient.subscribe(WsTopic.procedureTopic + currentProfile.fhirId, (messageObj: any) => {
                        const msg = JSON.parse(messageObj.body);
                        console.log('procedureTopic', msg);

                        setWsMessage(msg);
                    });

                    updateAppointment(res.data!.appointmentFhirId!, { status: 'arrived' });
                }
            });
        }

        return () => {
            subscription?.unsubscribe();
        };
    }, [currentProfile, wsClient]);

    useEffect(() => {
        if (kurentoConnected) {
            setStartedTime(new Date());
        }
    }, [kurentoConnected]);

    useEffect(() => {
        if (appointmentDetails?.serviceType === 'diagnostics' || appointmentDetails?.serviceType === 'consultation') {
            setSelectedParticipant(sessionParticipants[0]);
            setSelectedParticipantTab('info');
        }
    }, [appointmentDetails, sessionParticipants]);

    useEffect(() => {
        selectedParticipantId.current = selectedParticipant?.fhirId;
    }, [selectedParticipant]);

    const handleProcedurePatientInteraction = (participantId: string, procedureId: string, interaction: IPatientProcedureInteraction) => {
        const participant = sessionParticipants.find((x) => x.fhirId === participantId);
        const participantProcedures = participant?.procedures || [];
        const activeProcedureIndex = participantProcedures.findIndex((x) => x.id === procedureId);

        if (participant && activeProcedureIndex !== -1) {
            participantProcedures[activeProcedureIndex].interaction = interaction;
        }

        setSomeProceduresInProgress(
            sessionParticipants.some((x) =>
                x.procedures.some(
                    (y) => y.status === ProcedureStatus.inProgress || y.status === ProcedureStatus.continued || y.status === ProcedureStatus.started,
                ),
            ),
        );

        forceUpdate();
    };

    const onApproveFromLobby = (ids: string[]) => {
        send('/msg/gateway-ws/message', '/procedure/' + selectedParticipant?.fhirId, {
            type: WsProcedureTopicType.screenSettingsUpdate,
        });
        approveFromLobby(ids);
    };

    const procedureEndedInteraction = (participantId: string, procedureId: string | number) => {
        const participant = sessionParticipants.find((x) => x.fhirId === participantId);
        const participantProcedures = participant?.procedures || [];
        const procedure = participantProcedures.find((x) => x.id === procedureId);

        if (!participant || !procedure) {
            return;
        }

        procedure.finishedStepsCount++;

        const procedureIsLast = participantProcedures.every((x) =>
            x.id === procedure.id ? true : x.status !== ProcedureStatus.notStarted && x.status !== ProcedureStatus.paused,
        );

        const body: any = {
            type: WsProcedureTopicType.stopProcedure,
            procedureIsLast,
        };

        let procedureResult: IProcedureResult | null = null;

        switch (procedure.type) {
            case ProcedureTypeEnum.AVETISOV_MATS:
            case ProcedureTypeEnum.DISSOCIATION:
            case ProcedureTypeEnum.BIFIXATION_RECOVERY:
                if (procedure.isLastStep) {
                    procedure.status = ProcedureStatus.finishedButNeedConfirmation;
                    participant.select('warning');
                } else {
                    body.type = WsProcedureTopicType.toggleNeedChangeGlasses;
                    body.state = true;
                    procedure.status = ProcedureStatus.waitingApproveToChangeGlasses;
                    participant.select('warning');
                }

                // result will be confirmed by doctor through the comment
                break;
            case ProcedureTypeEnum.CHESS_PATTERN:
            case ProcedureTypeEnum.MERIDIONAL_PATTERN:
            case ProcedureTypeEnum.PLEOPTIC_SPIRALS:
            case ProcedureTypeEnum.PLEOPTIC_CROSS:
            case ProcedureTypeEnum.RESTORATION_OF_FUSION:
                if ((procedure.interaction?.successTries || 0) >= procedure.targetPoints) {
                    procedure.status = ProcedureStatus.finished;
                    participant.select('success');
                } else {
                    procedure.status = ProcedureStatus.failed;
                    participant.select('failed');
                }

                procedureResult = {
                    duration: procedure.progressTime,
                    unSuccess: procedure.status === ProcedureStatus.failed,
                    totalTries: procedure.interaction?.totalTries,
                    successTries: procedure.interaction?.successTries,
                    failedTries: procedure.interaction?.failedTries,
                };
                break;
            case ProcedureTypeEnum.MAKULOSTIMULATION:
            case ProcedureTypeEnum.FLASHES:
            case ProcedureTypeEnum.RECOVER_CILLIAR:
                procedure.status = ProcedureStatus.finished;
                participant.select('success');

                procedureResult = {
                    duration: procedure.progressTime,
                    unSuccess: false,
                };
                break;
            case ProcedureTypeEnum.AMBLYOPIA:
                if (procedure.isLastStep) {
                    if ((procedure.interaction?.successTries || 0) >= procedure.targetPoints) {
                        procedure.status = ProcedureStatus.finished;
                        participant.select('success');
                    } else {
                        procedure.status = ProcedureStatus.failed;
                        participant.select('failed');
                    }

                    procedureResult = {
                        duration: procedure.progressTime,
                        unSuccess: procedure.status === ProcedureStatus.failed,
                        totalTries: procedure.interaction?.totalTries,
                        successTries: procedure.interaction?.successTries,
                        failedTries: procedure.interaction?.failedTries,
                    };
                } else {
                    body.type = WsProcedureTopicType.toggleNeedChangeGlasses;
                    body.state = true;
                    procedure.status = ProcedureStatus.waitingApproveToChangeGlasses;
                    participant.select('warning');
                }
                break;
            default:
                break;
        }

        console.log('TO PATIENT', body);
        send('/msg/gateway-ws/message', '/procedure/' + participantId, body);

        if (procedureResult) {
            procedureResult.startedOrder = procedure.startedOrder;

            if (!procedureResult.unSuccess) {
                // move procedure to the end
                const targetProcedureIndex = participant.procedures.findIndex((x) => x.id === procedureId)!;
                participant.procedures.push(participant.procedures.splice(targetProcedureIndex, 1)[0]);
            }

            submitPatientResults(participant, procedureResult, procedure.type);
        }
    };

    const commentProcedureByDoctor = (preResult: IProcedureResult, procedureId?: string) => {
        const targetProcedure = selectedParticipant?.procedures.find((x) => x.id === procedureId);

        if ((preResult.comment || preResult.accommodationCapacity) && targetProcedure) {
            targetProcedure.doctorComment = preResult.comment;
            targetProcedure.doctorConfirmed = true;
            targetProcedure.status = preResult.status;

            // move procedure to the end
            const targetProcedureIndex = selectedParticipant!.procedures.findIndex((x) => x.id === procedureId)!;
            selectedParticipant?.procedures.push(selectedParticipant.procedures.splice(targetProcedureIndex, 1)[0]);

            const finalResult: IProcedureResult = {
                // TODO Discover why we get 0 here instead of real duration. The back PUT method is processing this as additional data to merge
                // duration: targetProcedure.progressTime,
                comment: preResult.comment,
                unSuccess: targetProcedure.status === ProcedureStatus.failed,
                startedOrder: preResult.startedOrder,
            };

            targetProcedure.doctorComment = preResult.comment;
            targetProcedure.accommodationCapacity = preResult.accommodationCapacity;

            if (
                targetProcedure.type === ProcedureTypeEnum.AVETISOV_MATS ||
                targetProcedure.type === ProcedureTypeEnum.BIFIXATION_RECOVERY ||
                targetProcedure.type === ProcedureTypeEnum.DISSOCIATION
            ) {
                targetProcedure.status = ProcedureStatus.finished;
            }

            if (preResult.accommodationCapacity) {
                finalResult.accommodationCapacity = preResult.accommodationCapacity;
            }

            // TODO Discover why we get wrong data here. The back PUT method is processing this as additional data to merge
            // if (targetProcedure.isInteractive) {
            //     finalResult.totalTries = preResult.totalTries;
            //     finalResult.successTries = preResult.successTries;
            //     finalResult.failedTries = preResult.failedTries;
            // }

            submitPatientResults(selectedParticipant!, finalResult, targetProcedure.type);
            selectedParticipant?.select(null);
        }
    };

    const submitPatientResults = (patient: SessionParticipant, result?: IProcedureResult, procedureType?: ProcedureTypeEnum) => {
        if (!procedureType) {
            return;
        }

        createOrUpdateProcedureResult({
            encounterId: patient?.encounterFhirId,
            patientId: patient.fhirId,
            carePlanId: patient.carePlanDTO?.fhirId,
            therapySessionResultId: patient.therapySessionResultId,
            procedureType,
            result,
            unSuccess: result?.unSuccess,
        }).then((res) => {
            console.log('Procedure result saved: ', res.data);
            message.info(t("doctor_appointments.procedure_result_saved"));
        });
    };

    const closeRoom = () => {
        if (appointmentDetails?.serviceType === 'therapy-session' || appointmentDetails?.serviceType === 'diagnostics') {
            updateAppointment(appointmentDetails!.appointmentFhirId!, { status: 'fulfilled' })
                .then((res) => {
                    console.log(res);
                })
                .finally(() => {
                    closeSession(true);
                    setCloseRoomConfirmationModalOpen(false);
                });
        } else {
            closeSession(true);
            setCloseRoomConfirmationModalOpen(false);
        }
    };

    const kickParticipant = (participant?: SessionParticipant) => {
        if (!participant) {
            Logger.error('No selected participant');
            return;
        }

        participant.kurentoParticipant?.leaveRoom(true);
        delete participant.kurentoParticipant;
        setKickPatientConfirmationModalOpen(false);
        setSelectedParticipant(undefined);
    };

    const releaseParticipant = (participant: SessionParticipant) => {
        if (participant) {
            updateStatusEncounter(participant.encounterFhirId!, { aidEncounterStatus: 'onleave' }).then((res) => {
                participant.kurentoParticipant?.leaveRoom(true);
                delete participant.kurentoParticipant;
                setReleasePatientConfirmationModalOpen(false);
            });
        }
    };

    const startStopPauseProcedure = (procedureStatus: ProcedureStatus, procedure: ParticipantProcedure) => {
        if (!selectedParticipant?.fhirId) {
            Logger.error('No id');
            return;
        }

        const settings = { ...procedure.settings };

        if (procedure.type === ProcedureTypeEnum.RESTORATION_OF_FUSION) {
            if (settings.amblyopia === 'yes') {
                settings.occlusionEye = selectedParticipant.currentSessionOcclusionEye as string;
            }
        }

        selectedParticipant.relevantProcedureType = procedure.type;

        const participantProcedures = selectedParticipant.procedures;
        const targetProcedureIndex = participantProcedures.findIndex((x) => x.id === procedure.id);

        let body = {};
        let newProcedureStatus = procedureStatus;

        switch (procedureStatus) {
            case ProcedureStatus.started:
                body = {
                    type: WsProcedureTopicType.startProcedure,
                    procedure: {
                        settings,
                        type: procedure.type,
                        id: procedure.id,
                    } as IGeneralProcedure,
                };
                break;
            case ProcedureStatus.changeGlasses:
                body = {
                    type: WsProcedureTopicType.toggleNeedChangeGlasses,
                    state: false,
                };

                if (procedure.type !== ProcedureTypeEnum.DISSOCIATION) {
                    newProcedureStatus = ProcedureStatus.started; // to reset timer for next step
                }
                break;
            case ProcedureStatus.paused:
                body = {
                    type: WsProcedureTopicType.togglePauseProcedure,
                    state: true,
                };
                break;
            case ProcedureStatus.continued:
                body = {
                    type: WsProcedureTopicType.togglePauseProcedure,
                    state: false,
                };
                break;
            case ProcedureStatus.stopped:
                {
                    body = {
                        type: WsProcedureTopicType.stopProcedure,
                    };
                    newProcedureStatus = ProcedureStatus.notStarted;
                }
                break;
            case ProcedureStatus.finishedButNeedConfirmation:
                body = {
                    type: WsProcedureTopicType.stopProcedure,
                };
                break;

            default:
                break;
        }

        if (targetProcedureIndex !== -1) {
            participantProcedures[targetProcedureIndex].status = newProcedureStatus;

            if (procedureStatus === ProcedureStatus.stopped) {
                participantProcedures[targetProcedureIndex].startedOrder = 0;
            }

            if (procedureStatus === ProcedureStatus.started && participantProcedures[targetProcedureIndex].startedOrder === 0) {
                let newStartedOrder = [...participantProcedures].reverse().find((x) => (x.startedOrder || 0) > 0)?.startedOrder || 0;
                participantProcedures[targetProcedureIndex].startedOrder = ++newStartedOrder;
            }
        } else {
            Logger.error('Procedure not found', { participantProcedures, procedure });
        }

        // for dissociation procedure message sends from participant-procedure.model.ts
        if (!(procedure.type === ProcedureTypeEnum.DISSOCIATION && procedureStatus === ProcedureStatus.waitingApproveToChangeGlasses)) {
            send('/msg/gateway-ws/message', '/procedure/' + selectedParticipant?.fhirId, body);
        }
        selectedParticipant?.select(null);

        forceUpdate();

        setSomeProceduresInProgress(
            sessionParticipants.some((x) =>
                x.procedures.some(
                    (y) => y.status === ProcedureStatus.inProgress || y.status === ProcedureStatus.continued || y.status === ProcedureStatus.started,
                ),
            ),
        );
    };

    const startStopDiagnostic = (start: boolean, procedureType?: ProcedureTypeEnum, value?: string, visualAcuity?: number, distance?: number) => {
        if (start) {
            const body = {
                type: WsProcedureTopicType.startProcedure,
                procedure: {
                    type: procedureType,
                } as IGeneralProcedure,
                distance: +(distance || 1),
            };

            if (
                procedureType === ProcedureTypeEnum.DIAGNOSTIC_LETTERS ||
                procedureType === ProcedureTypeEnum.DIAGNOSTIC_RINGS ||
                procedureType === ProcedureTypeEnum.DIAGNOSTIC_IMAGES
            ) {
                body.procedure.settings = {
                    visualAcuity,
                };

                if (procedureType === ProcedureTypeEnum.DIAGNOSTIC_LETTERS) {
                    body.procedure.settings!.letter = value;
                }

                if (procedureType === ProcedureTypeEnum.DIAGNOSTIC_RINGS) {
                    body.procedure.settings!.direction = value;
                }

                if (procedureType === ProcedureTypeEnum.DIAGNOSTIC_IMAGES) {
                    body.procedure.settings!.optotypeImage = value;
                }
            }

            send('/msg/gateway-ws/message', '/procedure/' + selectedParticipant?.fhirId, body);
        } else {
            const body = {
                type: WsProcedureTopicType.stopProcedure,
            };

            send('/msg/gateway-ws/message', '/procedure/' + selectedParticipant?.fhirId, body);
        }
    };

    const diagnosticSaveResult = (result: any) => {
        createOrUpdateDiagnosticResult({
            id: appointmentDetails?.appointmentFhirId,
            encounterId: selectedParticipant?.encounterFhirId,
            result,
        }).then((res) => {
            message.success(t("doctor_appointments.diagnostic_results_saved"));
            closeRoom();
        });
    };

    const onSaveGeneralComment = (comment: string) => {
        if (selectedParticipant) {
            updateTherapySession(selectedParticipant.therapySessionResultId!, {
                encounterId: selectedParticipant.encounterFhirId,
                carePlanId: selectedParticipant.carePlanDTO?.fhirId,
                patientId: selectedParticipant.fhirId,
                result: { comment },
            }).then((res) => {
                selectedParticipant.therapySessionResultDTO = res.data;
            });
        }
    };

    const onToggleCalibration = (state: boolean) => {
        if (selectedParticipant) {
            send('/msg/gateway-ws/message', '/procedure/' + selectedParticipant?.fhirId, { type: WsProcedureTopicType.startCalibration, state });

            requestScreenSharing(state, selectedParticipant);
        }
    };

    const onToggleCalibrationReady = (ready: boolean) => {
        setIsCalibrationReady(ready);
    };

    const requestScreenSharing = (state: boolean, participant: SessionParticipant) => {
        send('/msg/gateway-ws/message', '/procedure/' + participant.fhirId, {
            type: WsProcedureTopicType.requestScreenSharing,
            state,
        });

        if (state) {
            dispatch(setAwaitingScreenSharingParticipant(participant.fhirId));
            message.info(`${t("doctor_appointments.screen_share_request_sent")} "${participant.patient?.givenName}"`);
        } else {
            dispatch(setAwaitingScreenSharingParticipant(undefined));

            // doctor can finish calibration too, not only the patient
            if (!isCalibrationReady) {
                setIsCalibrationReady(true);
                send('/msg/gateway-ws/message', '/procedure/' + selectedParticipant?.fhirId, {
                    type: WsProcedureTopicType.startCalibration,
                    state: false,
                });
            }
        }
    };

    const selectEvent = (e: SelectedParticipantTabType, participant: SessionParticipant) => {
        sessionParticipants.forEach((p) => {
            if (p.fhirId === participant.fhirId) {
                p.select('select');
            } else {
                p.select(null);
            }
        });

        setSelectedParticipantTab(e);
        setSelectedParticipant(participant);
    };

    const stopProceduresForAll = (specificParticipant?: SessionParticipant) => {
        sessionParticipants
            .filter((x) => (specificParticipant ? x.fhirId === specificParticipant.fhirId : true))
            .forEach((participant) => {
                participant.procedures
                    .filter(
                        (y) =>
                            y.status === ProcedureStatus.inProgress || y.status === ProcedureStatus.continued || y.status === ProcedureStatus.started,
                    )
                    .forEach((x) => {
                        x.status = ProcedureStatus.notStarted;
                    });

                send('/msg/gateway-ws/message', '/procedure/' + participant?.fhirId, {
                    type: WsProcedureTopicType.stopProcedure,
                });
            });

        setSomeProceduresInProgress(
            sessionParticipants.some((x) =>
                x.procedures.some(
                    (y) => y.status === ProcedureStatus.inProgress || y.status === ProcedureStatus.continued || y.status === ProcedureStatus.started,
                ),
            ),
        );
    };

    const onExpandShrink = () => {
        setIsPatientFullScreen(!isPatientFullScreen);
    };

    const checkMediaDevices = async () => {
        return await navigator.mediaDevices
            .getUserMedia({ audio: true, video: true })
            .then((stream) => stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0)
            .catch(() => false);
    };

    const getLastVisualAcuityResult = useCallback(() => {
        const targetForm = appointmentDetails?.participantDTOList?.[0].forms?.reverse().find((x) => {
            return x.observationCode === 'consultation_report'
                ? !!x.result?.statusOculorumStep?.diagnosticVisualAcuity && !!Object.keys(x.result?.statusOculorumStep?.diagnosticVisualAcuity).length
                : !!x.result?.diagnosticVisualAcuity && !!Object.keys(x.result?.diagnosticVisualAcuity).length;
        });

        if (targetForm) {
            if (targetForm.observationCode === 'consultation_report') {
                return { ...targetForm.result?.statusOculorumStep?.diagnosticVisualAcuity, dateTime: targetForm.dateTime };
            } else {
                return { ...targetForm.result?.diagnosticVisualAcuity, dateTime: targetForm.dateTime };
            }
        }

        return null;
    }, [appointmentDetails]);

    return (
        <div className={styles.wrapper}>
            {kurentoConnected && hostSessionParticipant ? (
                <>
                    <section className={styles.conference}>
                        <div className={styles.room}>
                            {/* always for 1 patient only */}

                            {appointmentDetails?.serviceType === 'diagnostics' && (
                                <>
                                    <div
                                        className={classNames(
                                            styles.fullScreenVideo,
                                            hostSessionParticipant?.kurentoParticipant?.videoEnabled ? 'd-flex' : 'd-none',
                                            isPatientFullScreen ? 'd-flex' : 'd-none',
                                        )}
                                    >
                                        {selectedParticipant?.kurentoParticipant && isPatientFullScreen && (
                                            <VideoParticipant
                                                fullScreenVideo
                                                participant={selectedParticipant}
                                                hostView={true}
                                                withoutTitles={true}
                                                serviceType={appointmentDetails?.serviceType}
                                            />
                                        )}
                                        <Button
                                            className={classNames(selectedParticipant?.kurentoParticipant ? styles.shrink : 'd-none')}
                                            onClick={onExpandShrink}
                                        >
                                            <ShrinkIcon />
                                        </Button>
                                    </div>
                                    <div className={classNames(!isPatientFullScreen ? 'd-block h-100' : 'd-none')}>
                                        <DiagnosticSession
                                            planDefinitionID={appointmentDetails.planDefinitionID}
                                            diagnosticType={DiagnosticTypeOfCourse[appointmentDetails.planDefinitionID!]}
                                            // there is always one patient in the consultation/diagnostic, and we gave result from the last consultation
                                            lastConsultationResult={appointmentDetails.participantDTOList?.[0].forms
                                                ?.reverse()
                                                .find((x) => x.observationCode === 'consultation_report')}
                                            lastVisualAcuityResult={getLastVisualAcuityResult()}
                                            accommodationCapacityHistory={
                                                appointmentDetails.participantDTOList?.[0].carePlanDTO?.accommodationCapacityHistory
                                            }
                                            onToggleCalibration={onToggleCalibration}
                                            diagnosticChange={startStopDiagnostic}
                                            diagnosticSaveResult={diagnosticSaveResult}
                                            onApproveFromLobby={(fhirId) => onApproveFromLobby([fhirId])}
                                            participantInLobby={lobbyParticipants.find(
                                                (x) => x.id === appointmentDetails.participantDTOList?.[0].fhirId,
                                            )}
                                            participant={sessionParticipants[0]}
                                            activeScreenSharingParticipant={activeScreenSharingParticipant}
                                            onRequestScreenSharing={(state, participant) => requestScreenSharing(state, participant)}
                                            releaseParticipant={() => setReleasePatientConfirmationModalOpen(true)}
                                            isCalibrationReady={isCalibrationReady}
                                            onToggleCalibrationReady={onToggleCalibrationReady}
                                            participantDiagonal={activePatientDiagonal}
                                        />
                                    </div>
                                </>
                            )}

                            {/* always for 1 patient only */}
                            {appointmentDetails?.serviceType === 'consultation' && (
                                <>
                                    <div
                                        className={classNames(
                                            styles.fullScreenVideo,
                                            hostSessionParticipant?.kurentoParticipant?.videoEnabled ? 'd-flex' : 'd-none',
                                            isPatientFullScreen ? 'd-flex' : 'd-none',
                                        )}
                                    >
                                        {selectedParticipant?.kurentoParticipant && isPatientFullScreen && (
                                            <VideoParticipant
                                                fullScreenVideo
                                                participant={selectedParticipant}
                                                hostView={true}
                                                withoutTitles={true}
                                                serviceType={appointmentDetails?.serviceType}
                                            />
                                        )}
                                        <Button
                                            className={classNames(selectedParticipant?.kurentoParticipant ? styles.shrink : 'd-none')}
                                            onClick={onExpandShrink}
                                        >
                                            <ShrinkIcon />
                                        </Button>
                                    </div>
                                    <div className={classNames(!isPatientFullScreen ? 'd-block h-100' : 'd-none')}>
                                        <ConsultationSession
                                            courseType={appointmentDetails.courseType}
                                            encounterId={sessionParticipants[0]?.encounterFhirId}
                                            participant={sessionParticipants[0]}
                                            activeScreenSharingParticipant={activeScreenSharingParticipant}
                                            onRequestScreenSharing={(state, participant) => requestScreenSharing(state, participant)}
                                            onToggleCalibration={onToggleCalibration}
                                            diagnosticChange={startStopDiagnostic}
                                            onApproveFromLobby={(fhirId) => onApproveFromLobby([fhirId])}
                                            participantInLobby={lobbyParticipants.find(
                                                (x) => x.id === appointmentDetails.participantDTOList?.[0].fhirId,
                                            )}
                                            releaseParticipant={() => setReleasePatientConfirmationModalOpen(true)}
                                            isCalibrationReady={isCalibrationReady}
                                            onToggleCalibrationReady={onToggleCalibrationReady}
                                            closeSession={closeRoom}
                                            participantDiagonal={activePatientDiagonal}
                                        />
                                    </div>
                                </>
                            )}

                            {appointmentDetails?.serviceType === 'therapy-session' && (
                                <TherapySession
                                    participants={sessionParticipants}
                                    activeScreenSharingParticipant={activeScreenSharingParticipant}
                                    onRequestScreenSharing={(state, participant) => requestScreenSharing(state, participant)}
                                    onApproveFromLobby={(ids) => approveFromLobby(ids)}
                                    onParticipantSelect={selectEvent}
                                    onParticipantKickOut={(e) => {
                                        setParticipantForModal(e);
                                        setKickPatientConfirmationModalOpen(true);
                                    }}
                                    participantDiagonal={activePatientDiagonal}
                                />
                            )}
                        </div>
                        <aside className={styles.hostAside}>
                            <HostSidebar
                                isPatientFullScreen={isPatientFullScreen}
                                onExpand={onExpandShrink}
                                startedTime={startedTime}
                                selectedParticipant={selectedParticipant}
                                selectedParticipantTab={selectedParticipantTab}
                                host={hostSessionParticipant}
                                participants={sessionParticipants}
                                appointmentDetails={appointmentDetails}
                                someProceduresInProgress={someProceduresInProgress}
                                saveComment={onSaveGeneralComment}
                                stopProceduresForAll={stopProceduresForAll}
                                changeStatusProcedure={startStopPauseProcedure}
                                commentProcedureResult={commentProcedureByDoctor}
                                onSetSelectedParticipant={setSelectedParticipant}
                                onSetSelectedParticipantTab={setSelectedParticipantTab}
                                onApproveFromLobby={approveFromLobby}
                                closeSession={() => setCloseRoomConfirmationModalOpen(true)}
                            />
                        </aside>
                    </section>

                    <Modal
                        title={t('appointmentPage.modals.kickPatient.title')}
                        okText={t('appointmentPage.modals.common.yes')}
                        cancelText={t('appointmentPage.modals.common.cancel')}
                        open={kickParticipantConfirmationModalOpen}
                        onOk={() => kickParticipant(participantForModal)}
                        onCancel={() => setKickPatientConfirmationModalOpen(false)}
                    >
                        <p>
                            {t('appointmentPage.modals.kickPatient.text', {
                                patientName: participantForModal?.patient?.givenName,
                                procedureName: t('enums.planDefinition.' + appointmentDetails?.planDefinitionID),
                            })}
                        </p>
                    </Modal>

                    <Modal
                        title={t('appointmentPage.modals.closeRoom.title')}
                        okText={t('appointmentPage.modals.common.yes')}
                        cancelText={t('appointmentPage.modals.common.cancel')}
                        open={closeRoomConfirmationModalOpen}
                        onOk={() => closeRoom()}
                        onCancel={() => setCloseRoomConfirmationModalOpen(false)}
                    >
                        <p>
                            {appointmentDetails?.planDefinitionID
                                ? t('appointmentPage.modals.closeRoom.text', {
                                      procedureName: t('enums.planDefinition.' + appointmentDetails?.planDefinitionID),
                                  })
                                : t('appointmentPage.modals.leaveRoom.text')}
                        </p>
                    </Modal>

                    <Modal
                        title={t('appointmentPage.modals.releasePatient.title')}
                        okText={t('appointmentPage.modals.common.yes')}
                        cancelText={t('appointmentPage.modals.common.cancel')}
                        open={releaseParticipantConfirmationModalOpen}
                        onOk={() => releaseParticipant(sessionParticipants?.[0])} // we can release only from consultation or diagnostics
                        onCancel={() => setReleasePatientConfirmationModalOpen(false)}
                    >
                        <p>
                            {t('appointmentPage.modals.releasePatient.text', {
                                patientName: selectedParticipant?.patient?.givenName,
                            })}
                        </p>
                    </Modal>
                </>
            ) : null}
        </div>
    );
};
