import { EyeClosed, EyeOpen } from '@icons';
import { saveRecoveredPassword, selectEmailRecoverToken, selectPasswordRecoveredSuccessfully } from '@sliceUser';
import { Button, Card, Form, Input, Space } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './ChangePassword.module.scss';
import { Logo } from 'src/components/Logo/Logo';

interface IChangePasswordForm {
    password: string;
    confirmPassword: string;
}

interface PasswordValidationOptions {
    minLength?: number;
    requireUppercase?: boolean;
    requireLowercase?: boolean;
    requireNumbers?: boolean;
    requireSpecialChars?: boolean;
}

export const ChangePassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const emailToken = useSelector(selectEmailRecoverToken);
    const passwordRecoveredSuccessfully = useSelector(selectPasswordRecoveredSuccessfully);

    const { validateForm, isSubmitDisabled } = useFormValidation(form);

    useEffect(() => {
        if (passwordRecoveredSuccessfully) {
            navigate('/password-changed');
        }
    }, [passwordRecoveredSuccessfully]);

    const savePassword = (formValue: IChangePasswordForm) => {
        if (!form) {
            return;
        }
        form.validateFields();
        setTimeout(() => {
            if (!form.getFieldsError().find(({ errors }: { errors: string[] }) => errors.length > 0)) {
                dispatch(saveRecoveredPassword({ newPassword: formValue.password, token: emailToken }));
            }
        });
    };

    const validateConfirmPwd = () => {
        if (!form) {
            return;
        }
        if (form.getFieldValue('confirmPassword')) {
            form.validateFields(['confirmPassword']);
        }
    };

    const validate = () => {
        const fieldsToCheck = ['password', 'confirmPassword'];
        validateForm(fieldsToCheck);
    };

    const validatePassword = (password: string, {
        minLength = 8,
        requireUppercase = true,
        requireLowercase = true,
        requireNumbers = true,
        requireSpecialChars = true
    }: PasswordValidationOptions = {}): boolean => (
        password.length >= minLength &&
        (!requireUppercase || /[A-Z]/.test(password)) &&
        (!requireLowercase || /[a-z]/.test(password)) &&
        (!requireNumbers || /\d/.test(password)) &&
        (!requireSpecialChars || /[^A-Za-z0-9]/.test(password))
    );

    return (
        <div className={styles.wrapper}>
            <Card>
                <Logo marginBottom />
                <Form
                    form={form}
                    name="changepwd"
                    wrapperCol={{ span: 24 }}
                    initialValues={{
                        password: '',
                        confirmPassword: '',
                    }}
                    autoComplete="off"
                    onFinish={savePassword}
                    onChange={validate}
                    requiredMark={false}
                >
                    <h4 className="text-center">{t('EmailConfirmation.restore')}</h4>
                    <Form.Item name="password" rules={[
                        { required: true, message: t('Account.validation.passwordIsRequired') },
                        () => ({
                            validator(_, value: string) {
                                if (!value || validatePassword(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('Account.validation.tooSimplePassword')));
                            },
                        })
                    ]} className="w-100">
                        <Input.Password
                            autoFocus
                            placeholder={t("Account.labels.newPassword")}
                            onChange={validateConfirmPwd}
                            iconRender={(isOpen) =>
                                isOpen ? <EyeClosed style={{ cursor: 'pointer' }} /> : <EyeOpen style={{ cursor: 'pointer' }} />
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        className="w-100 mb-5"
                        name="confirmPassword"
                        rules={[
                            { required: true, message: t('Account.validation.confirmPasswordIsRequired') },
                            ({ getFieldValue }: { getFieldValue: (fld: string) => string | undefined }) => ({
                                validator(_, value: string) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('Account.validation.confirmPasswordDoesNotMatch')));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            placeholder={t("Account.labels.confirmNewPassword")}
                            iconRender={(isOpen) =>
                                isOpen ? <EyeClosed style={{ cursor: 'pointer' }} /> : <EyeOpen style={{ cursor: 'pointer' }} />
                            }
                        />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" className="w-100 mb-3" disabled={isSubmitDisabled}>
                        {t('Account.labels.save')}
                    </Button>
                </Form>
            </Card>
            <Space align="center" className={styles.bottomTxt}>
                {t('Register.labels.hasAccount')}
                <Link to="/login">{t('Register.labels.login')}</Link>
            </Space>
        </div>
    );
};
