import { drawChessBoard, getRandomPosition, targetColorClicked } from './helpers';
import { IProcedureBase, ProcedureBase } from './procedureBase.abstract';

export interface IChessPatternConfig {
    squareSize: number;
    animationInterval: number;
    pointImage: string;
    pointSize: number;
}

export class ChessPatternProcedure extends ProcedureBase implements IProcedureBase {
    // Procedure's global configuration
    // private _pointFadeOutTimerMax = this._fps * 0.5;
    // TODO Since there's no fade out effect, removing the timeout:
    private _pointFadeOutTimerMax = 0;
    private _pointFadeOutTimer = this._pointFadeOutTimerMax;
    private _assetsPath = '/icons/procedures/chessPattern';
    private _pointTargetColor = 'rgb(150,0,0)'; // pointImage must be the same color, and must be RGB
    private _squareColor1 = 'white';
    private _squareColor2 = 'black';

    private _config: IChessPatternConfig;

    private _pointImageObj: HTMLImageElement;
    private _pointPosX: number;
    private _pointPosY: number;

    constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, config: IChessPatternConfig) {
        super(canvas, ctx, config);

        this._config = config;

        // this._pointImageObj = new Image();
        // this._pointImageObj.src = `${this._assetsPath}/${config.pointImage}.png`;

        this._setPointRandomPosition();
    }

    public run(): void {
        // animation
        this.animationInterval = setInterval(() => {
            [this._squareColor1, this._squareColor2] = [this._squareColor2, this._squareColor1];
        }, this._config.animationInterval);

        // interactive
        this._setEventListeners();

        // draw canvas (animation)
        this._drawProcedure();
    }

    private _drawProcedure(): void {
        if (!this.paused) {
            this._drawBoard();
            this._drawPoint();
        }

        if (this._animationNeeded) {
            requestAnimationFrame(() => this._drawProcedure());
        }
    }

    private _setEventListeners(): void {
        const mouseclickHandler = (e: MouseEvent) => {
            if (targetColorClicked(this._canvas, this._ctx, e, this._pointTargetColor)) {
                this._successTries++;

                // start animation to fade out point
                this._pointFadeOutTimer = 0;

                // change point position after fading out animation
                setTimeout(() => {
                    this._setPointRandomPosition();
                }, (this._pointFadeOutTimerMax / this._fps) * 1000);
            } else {
                this._failedTries++;
            }
            this._totalTries++;

            this.interact();
        };

        this.mouseclickHandler = mouseclickHandler.bind(this);
        this._canvas.addEventListener('click', this.mouseclickHandler);
    }

    private _setPointRandomPosition(): void {
        const position = getRandomPosition(this._canvas, this._config.squareSize / 2, this._config.squareSize);

        this._pointPosX = position.x;
        this._pointPosY = position.y;
        this._pointFadeOutTimer = this._pointFadeOutTimerMax;
    }

    // ========================================================================
    // Just drawing logic below
    // ========================================================================
    private _drawBoard(): void {
        // draw chess board
        drawChessBoard(this._canvas, this._ctx, this._squareColor1, this._squareColor2, this._config.squareSize);
    }

    private _drawPoint(): void {
        // =========== it was a requirement - to animate fadeIn/fadeOut. Now - it's not necessary

        // this._pointFadeOutTimer++;
        // if (this._pointFadeOutTimer < this._pointFadeOutTimerMax) {
        //     this._ctx.save();

        //     this._ctx.translate(this._pointPosX, this._pointPosY);
        //     this._ctx.rotate((360 * Math.PI) / 180 + this._pointFadeOutTimer / 5);
        //     this._ctx.drawImage(
        //         this._pointImageObj,
        //         -(this._config.pointSize / 2),
        //         -(this._config.pointSize / 2),
        //         this._config.pointSize,
        //         this._config.pointSize,
        //     );

        //     this._ctx.restore();
        // } else {
        //     this._ctx.drawImage(
        //         this._pointImageObj,
        //         this._pointPosX - this._config.pointSize / 2,
        //         this._pointPosY - this._config.pointSize / 2,
        //         this._config.pointSize,
        //         this._config.pointSize,
        //     );
        // }
        // ===========

        this._ctx.beginPath();
        this._ctx.arc(this._pointPosX, this._pointPosY, this._config.pointSize / 2, 0, Math.PI * 2);
        this._ctx.fillStyle = this._pointTargetColor;
        this._ctx.fill();
        this._ctx.closePath();
    }
}
