export const VideoStreamConstraintsEconomic = {
    audio: true,
    video: {
        mandatory: {
            maxWidth: 480,
            maxFrameRate: 30,
            minFrameRate: 20,
        },
    },
};

export const VideoStreamConstraintsFull = {
    audio: true,
    video: {
        mandatory: {
            maxWidth: 720,
            maxFrameRate: 30,
            minFrameRate: 20,
        },
    },
};

export const ScreenSharingStreamConstraints = {
    audio: false,
    video: {
        width: { ideal: 1366, max: 1920 },
        height: { ideal: 768, max: 1080 },
    },
    preferCurrentTab: true,
};

export const HostScreenSharingStreamConstraints = {
    audio: true,
    video: {
        width: { ideal: 1920, max: 1920 },
        height: { ideal: 1080, max: 1080 },
    },
    preferCurrentTab: true,
};

export const RtcPeerConnectionConfiguration = {
    iceServers: [
        {
            urls: process.env.REACT_APP_KURENTO_TURN_URL,
            username: process.env.REACT_APP_KURENTO_TURN_USERNAME,
            credential: process.env.REACT_APP_KURENTO_TURN_CREDENTIAL,
        },
    ],
    iceTransportPolicy: 'relay',
};
