import { getPatientWithFlagCountDTO } from '@api/mainServiceAPI';
import { DropdownArrowIcon, ProfileIcon } from '@icons';
import { getUnhandledMsgs, logoutLocal, selectAuthUser, selectCurrentProfile, selectUnhandledMsgs } from '@sliceUser';
import { Badge, Dropdown } from 'antd';
import classNames from 'classnames';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import { WebsocketContext } from 'src/contexts/ws.context';
import { ClosestNotification } from 'src/pages/_general/ClosestNotification/ClosestNotification';
import { Footer } from '../../_general/Footer/Footer';
import styles from './PractitionerLayout.module.scss';
import { Logo } from 'src/components/Logo/Logo';

export const PractitionerLayout = () => {
    const authUser = useSelector(selectAuthUser);
    if (!authUser) {
        return null;
    }

    const unhandledMsgs = useSelector(selectUnhandledMsgs);

    const { wsClient, connect } = useContext(WebsocketContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentProfile = useSelector(selectCurrentProfile);
    const [patientsWithActiveFlag, setPatientsWithActiveFlag] = useState<number>(0);

    useEffect(() => {
        if (currentProfile) {
            connect(currentProfile.fhirId!);
        }
    }, [currentProfile]);

    useEffect(() => {
        if (currentProfile) {
            dispatch(getUnhandledMsgs());
            getPatientWithFlagCountDTO(currentProfile.fhirId!).then((res) => {
                setPatientsWithActiveFlag(+(res.data.numberOfPatientsWithActiveFlag || 0));
            });
        }
    }, [currentProfile]);

    return (
        wsClient && (
            <section className={styles.wrapper}>
                <ClosestNotification />

                <div className="sticky-top">
                    <header className={styles.header}>
                        <nav className={styles['header-content']}>
                            <Link className={`col-3`} to="/">
                                <Logo />
                            </Link>
                            <div className="col-4 d-flex justify-content-center">
                                <NavLink className={({ isActive }) => classNames(styles['nav-item'], isActive && styles.active)} to="/appointments">
                                    {t('practitionerLayout.header.navigators.appointments')}{' '}
                                    {unhandledMsgs > 0 && <Badge className={styles.badge} size="small" color="#F64D4D" count={unhandledMsgs} />}
                                </NavLink>

                                <NavLink className={({ isActive }) => classNames(styles['nav-item'], isActive && styles.active)} to="/patients">
                                    {t('practitionerLayout.header.navigators.patients')}{' '}
                                    {patientsWithActiveFlag > 0 && (
                                        <Badge className={styles.badge} size="small" color="#F64D4D" count={patientsWithActiveFlag} />
                                    )}
                                </NavLink>

                                <NavLink className={({ isActive }) => classNames(styles['nav-item'], isActive && styles.active)} to="/profile">
                                    {t('practitionerLayout.header.navigators.profile')}
                                </NavLink>
                            </div>
                            <div className="col-3 d-flex justify-content-end">
                                <Dropdown
                                    overlayClassName={styles.dropdownMenu}
                                    menu={{
                                        items: [
                                            {
                                                key: '3',
                                                onClick: () => navigate('/account'),
                                                label: t('practitionerLayout.header.labels.account'),
                                            },
                                            {
                                                key: '4',
                                                onClick: () => dispatch(logoutLocal({ isEsia: !!authUser.esia })),
                                                label: t('practitionerLayout.header.labels.exit'),
                                            },
                                        ],
                                    }}
                                >
                                    <div className={styles.dropdownBtn}>
                                        <ProfileIcon />
                                        <div className="d-flex flex-column align-items-start ms-2">
                                            <div className="d-flex align-items-center">
                                                <p className={`${styles.dropdownBtnTitle} me-2`}>{`${authUser?.lastName} ${authUser?.firstName}`}</p>
                                                <DropdownArrowIcon />
                                            </div>
                                            <span className={styles.dropdownBtnSubtitle}>{t('practitionerLayout.header.labels.doctor')}</span>
                                        </div>
                                    </div>
                                </Dropdown>
                            </div>
                        </nav>
                    </header>
                </div>

                <section className={styles.mainContent}>
                    <Outlet />
                </section>

                <Footer />
            </section>
        )
    );
};
