import { ConsultationAppointmentDetailsDTO } from '@api/mainServiceAPI';
import { useTranslation } from 'react-i18next';
import { PatientDocuments } from 'src/components/PatientDocuments/PatientDocuments';
import styles from './PatientInfoStep.module.scss';

interface IPatientInfoStepProps {
    data?: ConsultationAppointmentDetailsDTO;
}

export const PatientInfoStep = ({ data }: IPatientInfoStepProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>{t('consultationSession.patientInfoStep.title')}</h2>

            {data && (
                <div className={styles.info}>
                    <div className={styles.group}>
                        <label className={styles.label}>{t("patient_info_step.consultation_type")}</label>
                        <p className={styles.value}>{t('consultationSession.patientInfoStep.courseType.' + data?.courseType)}</p>
                    </div>

                    <div className={styles.group}>
                        <label className={styles.label}>{t("patient_info_step.preliminary_diagnosis")}</label>
                        <p className={styles.value}>
                            {data?.preliminaryDiagnosis?.length
                                ? data?.preliminaryDiagnosis
                                      ?.map((x) => t('consultationSession.patientInfoStep.preliminaryDiagnosis.' + x))
                                      .join(', ')
                                : '-'}
                        </p>
                    </div>

                    <div className={styles.group}>
                        <label className={styles.label}>{t("patient_info_step.complaints")}</label>
                        {!!data?.reasonCodes?.length ? (
                            <ul>
                                {data?.reasonCodes.map((x, i) => (
                                    <li key={i}>{t('consultationSession.patientInfoStep.reasonCodes.' + x)}</li>
                                ))}
                            </ul>
                        ) : (
                            <p className={styles.value}>-</p>
                        )}

                        {data?.comment && <p className={styles.value}>{data?.comment}</p>}
                    </div>

                    {!!data.documentDTOs?.length && (
                        <div className={styles.group}>
                            <label className={styles.label}>{t("patient_info_step.loaded_documents")}</label>
                            <PatientDocuments documents={data.documentDTOs} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
