import {
    AppointmentDTOAppointmentType,
    AppointmentDTOPartOf,
    AppointmentDTOServiceType,
    AppointmentDTOStatus,
    EventDayDTO,
    getEvents,
    GetEventsParams,
    getPractitionerActiveAppointments,
    GetPractitionerActiveAppointmentsParams,
    PageDTOAppointmentDTO,
    ParticipantDTO,
    PatientBaseDTO,
} from '@api/mainServiceAPI';
import { CountdownTimer } from '@components';
import { AlertIconOutlined, CalendarNo, ChatWith3DotsIcon, ChevronRight, ConsultationIcon, DiagnosticIcon2, Procedure, SoundVideo } from '@icons';
import { AudioVideoSettingsModal } from '@modals';
import { LocalizationProvider, PickersDay } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';

import { selectCurrentProfile, UserRole } from '@sliceUser';
import { dateForServer, DEBUG } from '@utils/utils';
import { Button, Checkbox, Col, Row, Space, Tag } from 'antd';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { MED_TIME_BEFORE_START_OF_SESSION } from 'src/ADMINISTRATIVE_CONFIG';
import { DocAudioVideoSettingsLocalStorage } from 'src/services';
import { LSAudioVideoSettings } from 'src/services/types/ls-doc-audio-video-settings';
import styles from './DoctorAppointmentsPage.module.scss';

const adapter = new AdapterDayjs();

type AppointmentFilterType = 'all' | 'diagnostics' | 'procedures' | 'consultation';

interface IDoctorAppointmentChip {
    id: string;
    dateTime: Date;
    dayJsDateTime: dayjs.Dayjs;
    time?: string;
    weekDay?: string;
    fullDay?: string;
    isPast?: boolean;
    patientsNumber?: number;
    patientName?: string;
    patient?: PatientBaseDTO;
    serviceType?: AppointmentDTOServiceType;
    appointmentType?: AppointmentDTOAppointmentType;
    partOf?: AppointmentDTOPartOf;
    patients?: ParticipantDTO[];
    status?: AppointmentDTOStatus;
    hasActiveMessages?: boolean;
}

export const DoctorAppointmentsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentProfile = useSelector(selectCurrentProfile);

    const [doctorAppointmentsForMonth, setDoctorAppointmentsForMonth] = useState<EventDayDTO[]>([]);
    const [doctorAppointmentsForDay, setDoctorAppointmentsForDay] = useState<PageDTOAppointmentDTO | null>(null);

    const firstAvailableSlotDay = adapter.date(new Date()); //(appointmentSlots && appointmentSlots[0].dateFrom) || null;

    const [selectedFiltering, setSelectedFiltering] = useState<AppointmentFilterType>('all');
    const [filteringCount, setFilteringCount] = useState<{ all: number; diagnostics: number; procedures: number; consultation: number }>({
        all: 0,
        diagnostics: 0,
        procedures: 0,
        consultation: 0,
    });

    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(firstAvailableSlotDay);
    const [appointmentsListOrigin, setAppointmentsListOrigin] = useState<IDoctorAppointmentChip[]>([]);
    const [appointmentsList, setAppointmentsList] = useState<IDoctorAppointmentChip[]>([]);
    const [pastAppointments, setPastAppointments] = useState(true);
    const [audioVideoSettings, setAudioVideoSettings] = useState<LSAudioVideoSettings>({
        audioInput: undefined,
        audioOutput: undefined,
        video: undefined,
    });
    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
        const audioVideoSettingsLS = DocAudioVideoSettingsLocalStorage.getSettings();
        if (audioVideoSettingsLS) {
            setAudioVideoSettings(audioVideoSettingsLS);
        }
    }, []);

    useEffect(() => {
        if (currentProfile) {
            const todayDayJs = dayjs();
            const firstMonthDay = new Date(todayDayJs.get('year'), todayDayJs.get('M'), 1);
            const lastMonthDay = new Date(todayDayJs.get('year'), todayDayJs.get('M') + 1, 0);

            getAppointmentsForMonth({
                practitionerRoleFhirId: currentProfile.fhirId,
                start: dateForServer(firstMonthDay),
                end: dateForServer(lastMonthDay),
            });
        }
    }, [currentProfile]);

    useEffect(() => {
        const mappedData: IDoctorAppointmentChip[] =
            doctorAppointmentsForDay?.content?.map((x) => {
                const dayJsDate = dayjs(x.dateTime);

                return {
                    id: x.id!,
                    dateTime: new Date(x.dateTime!),
                    dayJsDateTime: dayJsDate,
                    time: dayJsDate.format('HH:mm'),
                    weekDay: dayJsDate.format('dddd').charAt(0).toUpperCase() + dayJsDate.format('dddd').slice(1),
                    fullDay: dayJsDate.format('D MMMM'),
                    patientsNumber: x.patientsNumber,
                    patientName: x.patientName,
                    patient: x.patient,
                    patients: undefined,
                    isPast: x.status === 'fulfilled',
                    serviceType: x.serviceType,
                    appointmentType: x.appointmentType,
                    partOf: x.partOf,
                    status: x.status,
                    hasActiveMessages: x.hasActiveMessages,
                };
            }) || [];

        mappedData.sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime());

        setFilteringCount({
            all: mappedData.length,
            diagnostics: mappedData.filter((x) => x.serviceType === 'diagnostics').length,
            procedures: mappedData.filter((x) => x.serviceType === 'therapy-session').length,
            consultation: mappedData.filter((x) => x.serviceType === 'consultation').length,
        });

        setAppointmentsListOrigin(mappedData);
    }, [doctorAppointmentsForDay]);

    useEffect(() => {
        switch (selectedFiltering) {
            case 'all':
                setAppointmentsList([...appointmentsListOrigin.filter((x) => (!pastAppointments ? !x.isPast : true))]);
                break;
            case 'diagnostics':
                setAppointmentsList(
                    appointmentsListOrigin.filter((x) => x.serviceType === 'diagnostics').filter((x) => (!pastAppointments ? !x.isPast : true)),
                );
                break;
            case 'procedures':
                setAppointmentsList(
                    appointmentsListOrigin.filter((x) => x.serviceType === 'therapy-session').filter((x) => (!pastAppointments ? !x.isPast : true)),
                );
                break;
            case 'consultation':
                setAppointmentsList(
                    appointmentsListOrigin.filter((x) => x.serviceType === 'consultation').filter((x) => (!pastAppointments ? !x.isPast : true)),
                );
                break;
            default:
                break;
        }
    }, [selectedFiltering, pastAppointments, appointmentsListOrigin]);

    useEffect(() => {
        if (selectedDate && currentProfile?.fhirId) {
            // const todayDayJs = dayjs();
            // const firstMonthDay = new Date(todayDayJs.get('year'), todayDayJs.get('M'), 1);
            // const lastMonthDay = new Date(todayDayJs.get('year'), todayDayJs.get('M') + 1, 0);

            getAppointmentsForDay({
                page: 0,
                size: 100,
                // dateStart: dateForServer(firstMonthDay, true, true),
                // dateEnd: dateForServer(dayjs(lastMonthDay).add(1, 'day').toDate()!, true, true),
                dateStart: dateForServer(selectedDate!.toDate()!, true, true),
                dateEnd: dateForServer(selectedDate.add(1, 'day').toDate()!, true, true),
            });
        }
    }, [selectedDate]);

    const getAppointmentsForMonth = (params: GetEventsParams) => {
        getEvents(params).then((res) => {
            setDoctorAppointmentsForMonth(res.data.eventDayDTOList || []);
        });
    };

    const getAppointmentsForDay = (params: GetPractitionerActiveAppointmentsParams) => {
        getPractitionerActiveAppointments(currentProfile!.fhirId!, params).then((res) => {
            setDoctorAppointmentsForDay(res.data);
        });
    };

    const connectToAppointment = (appointmentId: string) => {
        navigate('/appointment?appointmentId=' + appointmentId);
    };

    const openAppointment = (appointmentId: string, appointmentType: AppointmentDTOAppointmentType | undefined) => {
        if (appointmentType === 'course') {
            navigate('/procedure/' + appointmentId);
        } else {
            navigate('/appointments/' + appointmentId);
        }
    };

    const handleMonthChange = (date: dayjs.Dayjs | null) => {
        if (date && currentProfile) {
            const lastDayOfMonth = dayjs(new Date(date.get('year'), date.get('M') + 1, 0));

            getAppointmentsForMonth({
                practitionerRoleFhirId: currentProfile.fhirId,
                start: dateForServer(date!.toDate()!),
                end: dateForServer(lastDayOfMonth!.toDate()!),
            });
        }
    };

    const handleAudioVideoSuccess = (value: {
        audioInput: MediaDeviceInfo | undefined;
        audioOutput: MediaDeviceInfo | undefined;
        video: MediaDeviceInfo | undefined;
    }) => {
        setAudioVideoSettings(value);
        DocAudioVideoSettingsLocalStorage.setSettings(value);
        setShowSettings(false);
    };

    return (
        <div className={styles.wrapper}>
            {showSettings && (
                <AudioVideoSettingsModal
                    audioInput={audioVideoSettings.audioInput}
                    audioOutput={audioVideoSettings.audioOutput}
                    videoInput={audioVideoSettings.video}
                    onCancel={() => setShowSettings(false)}
                    onSuccess={handleAudioVideoSuccess}
                    showModal={showSettings}
                    backButton={false}
                />
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Row justify="space-between" gutter={32}>
                    <Col span={18}>
                        <h1>{t('docMainPage.title')}</h1>
                        {Object.values(audioVideoSettings).some((item) => !item) && (
                            <Row className={styles.settings}>
                                <Col span={2}>
                                    <SoundVideo />
                                </Col>
                                <Col span={18} className="d-flex flex-column">
                                    <p className={styles.title}>{t('userMainPage.setting.videoSound.title')}</p>
                                    <p className={styles.subtitle}>{t('docMainPage.settings')}</p>
                                </Col>
                                <Col span={4}>
                                    <Button onClick={() => setShowSettings(true)} className="w-100" type="primary">
                                        {t('docMainPage.checkButton')}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row align="middle" justify="space-between" className={styles.filters}>
                            <Col span={6}>
                                <p className={styles.date}>{selectedDate?.format('dddd, D MMMM')}</p>
                            </Col>
                            {!!appointmentsListOrigin.length && (
                                <Col span={17} className="w-100">
                                    <Row gutter={8} align="middle">
                                        {selectedDate && selectedDate < dayjs() ? (
                                            <Col span={8}>
                                                <Checkbox
                                                    className={styles.last}
                                                    checked={pastAppointments}
                                                    onChange={() => setPastAppointments(!pastAppointments)}
                                                >
                                                    {t('docMainPage.tabs.past')}
                                                </Checkbox>
                                            </Col>
                                        ) : (
                                            <Col span={8}></Col>
                                        )}

                                        <Col span={4}>
                                            <div
                                                className={classNames(styles['filter-btn'], selectedFiltering === 'all' && styles.active)}
                                                onClick={() => setSelectedFiltering('all')}
                                            >
                                                {t("doctor_appointments.all")} ({filteringCount.all})
                                            </div>
                                        </Col>
                                        {currentProfile?.profileType === UserRole.Nurse ? (
                                            <Col span={6}>
                                                <div
                                                    className={classNames(styles['filter-btn'], selectedFiltering === 'procedures' && styles.active)}
                                                    onClick={() => setSelectedFiltering('procedures')}
                                                >
                                                    {t("doctor_appointments.procedures")} ({filteringCount.procedures})
                                                </div>
                                            </Col>
                                        ) : (
                                            <Col span={6}>
                                                <div
                                                    className={classNames(
                                                        styles['filter-btn'],
                                                        selectedFiltering === 'consultation' && styles.active,
                                                    )}
                                                    onClick={() => setSelectedFiltering('consultation')}
                                                >
                                                    {t("doctor_appointments.consultations")} ({filteringCount.consultation})
                                                </div>
                                            </Col>
                                        )}
                                        <Col span={6}>
                                            <div
                                                className={classNames(styles['filter-btn'], selectedFiltering === 'diagnostics' && styles.active)}
                                                onClick={() => setSelectedFiltering('diagnostics')}
                                            >
                                                {t("doctor_appointments.diagnostic")} ({filteringCount.diagnostics})
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        {selectedDate?.format('DD/MM/YYYY') !== dayjs().format('DD/MM/YYYY') && (
                            <div className={styles['today-notice']}>
                                <AlertIconOutlined className="me-3" />
                                {t("doctor_appointments.not_today_attention")}
                            </div>
                        )}
                        <div>
                            {!!appointmentsList.length ? (
                                appointmentsList.map((appo, i) => {
                                    return (
                                        <Row
                                            align="top"
                                            key={appo.id}
                                            className={classNames(styles.schedule, (appo.isPast || appo.status === 'fulfilled') && styles.past)}
                                            onDoubleClick={() => DEBUG({ url: 'appointment?appointmentId=' + appo.id })}
                                        >
                                            <Col lg={2} md={3} sm={4}>
                                                <div className={styles.time}>{appo.time}</div>
                                            </Col>
                                            <Col lg={16} md={15} sm={14} className="d-flex align-items-start">
                                                {appo.serviceType === AppointmentDTOServiceType.diagnostics && (
                                                    <div className={styles.icon}>
                                                        <DiagnosticIcon2 />
                                                    </div>
                                                )}
                                                {appo.serviceType === AppointmentDTOServiceType['therapy-session'] && (
                                                    <div className={styles.icon}>
                                                        <Procedure />
                                                    </div>
                                                )}
                                                {appo.serviceType === AppointmentDTOServiceType.consultation && (
                                                    <div className={styles.icon}>
                                                        <ConsultationIcon />
                                                    </div>
                                                )}

                                                <div className="flex-grow-1">
                                                    <p className={styles['schedule-title']} title={appo.id}>
                                                        {appo.serviceType === AppointmentDTOServiceType['therapy-session']
                                                            ? t('enums.planDefinition.' + appo.partOf, t("doctor_appointments.care_plan"))
                                                            : appo.serviceType === AppointmentDTOServiceType.diagnostics
                                                            ? t('enums.diagnosticPlanDefinition.' + appo.partOf, t("doctor_appointments.diagnostic"))
                                                            : t('enums.appointmentTypes.' + appo.appointmentType)}
                                                    </p>

                                                    <p className={styles['schedule-subtitle']}>
                                                        {appo.serviceType === AppointmentDTOServiceType['therapy-session'] ? (
                                                            <>
                                                                {t('docMainPage.patients')} &mdash; {appo.patientsNumber}
                                                            </>
                                                        ) : (
                                                            <Link className={styles.patientName} to={'/medcard/' + appo.patient?.id}>
                                                                {appo.patient?.lastName} {appo.patient?.firstName}
                                                            </Link>
                                                        )}

                                                        {!(appo.isPast || appo.status === 'fulfilled') && (
                                                            <CountdownTimer
                                                                timeX={appo.dateTime}
                                                                before={MED_TIME_BEFORE_START_OF_SESSION}
                                                                render={(timeLabel: string) => (
                                                                    <Tag className={styles.startsOver}>
                                                                        {t('docMainPage.startsOver')} <strong className="h6">{timeLabel}</strong>
                                                                    </Tag>
                                                                )}
                                                            ></CountdownTimer>
                                                        )}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col span={6} className={styles.joinButtonCell}>
                                                <Space>
                                                    {appo.status === AppointmentDTOStatus.cancelled && (
                                                        <Tag className={styles.statusCancelled}>
                                                            {t('patientAppointmentsPage.statuses.cancelled')}
                                                        </Tag>
                                                    )}
                                                    <Button
                                                        className="p-0"
                                                        type="link"
                                                        onClick={() => openAppointment(appo.id, appo.appointmentType)}
                                                    >
                                                        <ChatWith3DotsIcon className={classNames(appo.hasActiveMessages && styles.hasMsgs)} />
                                                    </Button>
                                                    {!(appo.isPast || appo.status === 'fulfilled') &&
                                                        appo.status !== AppointmentDTOStatus.cancelled && (
                                                            <CountdownTimer
                                                                timeX={appo.dateTime}
                                                                keepAfterX={true}
                                                                before={MED_TIME_BEFORE_START_OF_SESSION}
                                                                render={() => (
                                                                    <Button type="primary" onClick={() => connectToAppointment(appo.id)}>
                                                                        {t('docMainPage.schedule.joinButton')}
                                                                    </Button>
                                                                )}
                                                            ></CountdownTimer>
                                                        )}
                                                </Space>
                                            </Col>
                                            {/* {appo.status && (
                                                <Col className="d-flex justify-content-end" span={4}>
                                                    <div
                                                        className={classNames(
                                                            styles.status,
                                                            appo.status === AppointmentDTOStatus.booked && styles.success,
                                                            appo.status === AppointmentDTOStatus.cancelled && styles.cancel,
                                                        )}
                                                    >
                                                        {appo.status}
                                                    </div>
                                                </Col>
                                            )} */}
                                        </Row>
                                    );
                                })
                            ) : (
                                <div>
                                    <div className={styles.noAppointmentsWrapper}>
                                        <CalendarNo />
                                        {!!appointmentsListOrigin.length ? (
                                            <p className={styles.noAppointments}>{t("no_appointments")}</p>
                                        ) : (
                                            <p className={styles.noAppointments}>{t("doctor_appointments.no_appointments")}</p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="position-relative">
                            <CalendarPicker
                                onMonthChange={(date) => handleMonthChange(date)}
                                date={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                renderDay={(day, _value, DayComponentProps) => {
                                    const hasAppointments = doctorAppointmentsForMonth.some((x) => x.date === day.format('YYYY-MM-DD'));
                                    const inTheFuture = day >= dayjs();

                                    return (
                                        <span className={hasAppointments ? (inTheFuture ? 'filled' : 'skipped') : ''} key={day.toString()}>
                                            {<PickersDay {...DayComponentProps} />}
                                        </span>
                                    );
                                }}
                            />
                            <Button onClick={() => setSelectedDate(dayjs())} type="link" className={styles.today}>
                                {t("doctor_appointments.today")}
                            </Button>
                        </div>
                        {!Object.values(audioVideoSettings).some((setting) => !setting) && (
                            <Row onClick={() => setShowSettings(true)} className={styles['small-settings']}>
                                <Col span={4}>
                                    <SoundVideo />
                                </Col>
                                <Col span={12}>
                                    <p className={styles.title}>{t('userMainPage.setting.videoSound.title')}</p>
                                </Col>
                                <Col span={2}>
                                    <ChevronRight />
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </LocalizationProvider>
        </div>
    );
};
