import classNames from 'classnames';
import styles from './ProgressBar.module.scss';
import {useTranslation} from "react-i18next";

interface IProgressBarProps {
    steps: number,
    processed: number
}

export const ProgressBar = ({steps, processed}: IProgressBarProps) => {
    const {t} = useTranslation();

    return (
        <div className={styles.progress}>
            <span className='mb-3'>{t("progress_bar.filled")} {processed} {t("progress_bar.from")} {steps}</span>
            <div className='d-flex gap-1 mt-3'>
                {
                    Array.from({ length: steps}, (_, index) => index).map((i) => 
                        <div className={classNames(styles.step, i < processed ? styles.processed : '')} key={i}></div>
                    )
                }
            </div>
        </div>
    );
};
