import { DropdownArrowIcon, ProfileIcon } from '@icons';
import { logoutLocal, selectAuthUser } from '@sliceUser';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { Footer } from '../../_general/Footer/Footer';

import { useContext, useEffect } from 'react';
import { WebsocketContext } from 'src/contexts/ws.context';
import styles from './CallCenterManagerLayout.module.scss';
import { Logo } from 'src/components/Logo/Logo';

export const CallCenterManagerLayout = () => {
    const authUser = useSelector(selectAuthUser);
    if (!authUser) {
        return null;
    }

    const { wsClient, connect } = useContext(WebsocketContext);

    const dispatch = useDispatch();

    useEffect(() => {
        if (authUser) {
            connect(authUser.id!);
        }
    }, [authUser]);

    return (
        wsClient && (
            <div className={styles.wrapper}>
                <div className="sticky-top">
                    <header className={styles.header}>
                        <nav className={styles['header-content']}>
                            <Link to="/">
                                <Logo />
                            </Link>
                            <div className="col-4 d-flex justify-content-center">
                                <NavLink className={({ isActive }) => classNames(styles['nav-item'], isActive && styles.active)} to="/encounters">
                                    {t('callCenterManagerLayout.header.navigators.appointments')}
                                </NavLink>

                                {/* <NavLink className={({ isActive }) => classNames(styles['nav-item'], isActive && styles.active)} to="/schedule">
                                    {t('callCenterManagerLayout.header.navigators.schedule')}
                                </NavLink> */}
                            </div>
                            <div className="col-3 d-flex justify-content-end">
                                <Dropdown
                                    overlayClassName={styles.dropdownMenu}
                                    menu={{
                                        items: [
                                            {
                                                key: '4',
                                                onClick: () => dispatch(logoutLocal({isEsia: !!authUser.esia})),
                                                label: t('nurseLayout.header.labels.exit'),
                                            },
                                        ],
                                    }}
                                >
                                    <div className={styles.dropdownBtn}>
                                        <ProfileIcon />
                                        <div className="d-flex flex-column align-items-start ms-2">
                                            <div className="d-flex align-items-center">
                                                <p className={`${styles.dropdownBtnTitle} me-2`}>{`${authUser?.lastName} ${authUser?.firstName}`}</p>
                                                <DropdownArrowIcon />
                                            </div>
                                            <span className={styles.dropdownBtnSubtitle}>
                                                {t('callCenterManagerLayout.header.labels.callCenterManager')}
                                            </span>
                                        </div>
                                    </div>
                                </Dropdown>
                            </div>
                        </nav>
                    </header>
                </div>

                <section className={styles.mainContent}>
                    <Outlet />
                </section>

                <Footer />
            </div>
        )
    );
};
