import {
    confirmPhone,
    resetSessionIdSecond,
    selectAccountFormData,
    selectAttemptsSMS,
    selectCodeLengthSMS,
    selectEmailTokenAfter,
    selectExpirationDatePhone,
    selectPhoneNumber,
    selectRegisteredSuccessfully,
    selectRemoteValidationMessages,
    selectResendAfterSecond,
    selectSessionIdSecond,
    sendSMS,
} from '@sliceUser';
import { Button, Card, Col, Form, Input, InputRef, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { ClipboardEvent, createRef, FocusEvent, FormEvent, KeyboardEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './ConfirmMobilePage.module.scss';
import { Logo } from 'src/components/Logo/Logo';

export const ConfirmMobilePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [canResend, setCanResend] = useState<boolean>(false);
    const [backwardDirection, setBackwardDirection] = useState<boolean>(false);
    const [fromNow, setFromNow] = useState<string>('');
    const [validFor, setValidFor] = useState<string>('');

    const [wrongCodeError, setWrongCodeError] = useState<string | null>(null);
    const [latestInput, setLatestInput] = useState<{ elm: any }>({ elm: null });

    const accountFormData = useSelector(selectAccountFormData);
    const emailTokenAfter = useSelector(selectEmailTokenAfter);
    const registeredSuccessfully = useSelector(selectRegisteredSuccessfully);
    const mobileNumber = useSelector(selectPhoneNumber);
    const remoteValidationMessages = useSelector(selectRemoteValidationMessages);
    const sessionIdSecond = useSelector(selectSessionIdSecond);
    const resendAfter = useSelector(selectResendAfterSecond);
    const expirationDateMobile = useSelector(selectExpirationDatePhone);
    const attempts = useSelector(selectAttemptsSMS);
    const codeLength = useSelector(selectCodeLengthSMS);

    const [form] = useForm();
    const { validateForm, isSubmitDisabled } = useFormValidation(form);

    const btnRef = useRef<any>(null);
    const [inputRefs, setElRefs] = useState([]);

    useEffect(() => {
        setElRefs((inputRefs) =>
            Array(codeLength)
                .fill(null)
                .map((_, i) => inputRefs[i] || createRef()),
        );
    }, [codeLength]);

    useEffect(() => {
        if (!accountFormData) {
            navigate('/');
        }
    }, [accountFormData]);

    useEffect(() => {
        setTimeout(() => {
            if (latestInput.elm) {
                latestInput.elm.focus();
            }
        });
    }, [latestInput]);

    useEffect(() => {
        let timer: number;
        let seconds: number;
        if (resendAfter) {
            const diffMilliseconds = dayjs(resendAfter).utc().diff(dayjs().utc());
            seconds = window.setInterval(() => {
                setFromNow(dayjs(resendAfter).utc().fromNow());
            }, 1000);
            timer = window.setTimeout(() => {
                setCanResend(true);
                clearInterval(seconds);
                setFromNow('');
            }, diffMilliseconds);
        } else {
            setCanResend(false);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
                clearInterval(seconds);
            }
        };
    }, [resendAfter]);

    useEffect(() => {
        if (expirationDateMobile) {
            setValidFor(dayjs(expirationDateMobile).utc().fromNow(true));
        }
    }, [expirationDateMobile]);

    useEffect(() => {
        if (remoteValidationMessages.smsCode) {
            setWrongCodeError(t(remoteValidationMessages.smsCode, { attemptsLeft: attempts }));
        }
    }, [remoteValidationMessages, attempts]);

    useEffect(() => {
        if (registeredSuccessfully) {
            navigate('/account-success');
        }
    }, [registeredSuccessfully]);

    const submit = (formValue: { [part: string]: number }) => {
        if (!(accountFormData && emailTokenAfter && sessionIdSecond)) {
            return;
        }

        dispatch(confirmPhone({ code: Object.values(formValue).join(''), accountFormData, sessionId: sessionIdSecond, emailTokenAfter }));
    };

    const next = (evt: FormEvent<HTMLInputElement>, i: number) => {
        const target = evt.target as HTMLInputElement;
        if (/[^0-9]/g.test(target.value)) {
            target.value = target.defaultValue;
            target.select();
            return;
        }
        if (backwardDirection) {
            return;
        }
        const nextInputRef: RefObject<InputRef> = inputRefs[i + 1] || btnRef;

        if (nextInputRef.current) {
            setLatestInput({ elm: nextInputRef.current });
        }
    };

    const validate = () => {
        setWrongCodeError(null);
        validateForm(new Array(codeLength).fill(null).map((_, i) => `part${i + 1}`));
    };

    const sendPhoneAgain = () => {
        if (mobileNumber) {
            setCanResend(false);
            setWrongCodeError(null);
            form.resetFields();
            dispatch(sendSMS({ phone: mobileNumber, afterToken: emailTokenAfter }));
        }
    };

    const resetTicket = () => {
        dispatch(resetSessionIdSecond());
    };

    const selectThis = (evt: FocusEvent<HTMLInputElement>) => {
        evt.target.defaultValue = evt.target.value;
        evt.target.select();
    };

    const pasteCode = (evt: ClipboardEvent) => {
        const paste = evt.clipboardData.getData('text');
        const fldsToFill = new Array(codeLength).fill(null).map((_, i) => `part${i + 1}`);
        const targetIndex = +(evt.target as HTMLElement).id.split('part')[1];
        const values: { [name: string]: string } = {};
        let i = targetIndex - 1;
        for (let imax = fldsToFill.length, jmax = paste.length, j = 0; i < imax && j < jmax; i++, j++) {
            values[fldsToFill[i]] = paste[j];
        }
        form.setFieldsValue(values);
        validate();
        setTimeout(() => {
            setLatestInput({ elm: form.getFieldInstance(fldsToFill[i])?.input || btnRef.current });
        });
    };

    const setDirection = (evt: KeyboardEvent, i: number) => {
        if (evt.key === 'Backspace') {
            setBackwardDirection(true);
            const prevRef: RefObject<InputRef> = inputRefs[i - 1] || inputRefs[0];
            if (prevRef.current) {
                setLatestInput({ elm: prevRef.current });
            }
        } else {
            setBackwardDirection(false);
        }
    };

    return (
        <>
            <div className={styles.wrapper}>
                <Card className={styles.contentLayout}>
                    <Logo marginBottom />

                    <Form
                        name="confirmMobile"
                        wrapperCol={{ span: 24 }}
                        initialValues={inputRefs.reduce((obj, key, i) => Object.assign(obj, { [`part${i + 1}`]: '' }), {})}
                        autoComplete="off"
                        onFinish={submit}
                        onChange={validate}
                        form={form}
                    >
                        <h4 className="text-center">{t('ConfirmMobile.title')}</h4>
                        <p>
                            {t('ConfirmMobile.description')} <strong>+{mobileNumber}</strong>
                        </p>
                        {validFor && <p>{t('ConfirmMobile.codeIsValidFor', { minutes: validFor })}</p>}
                        <Row gutter={8}>
                            {codeLength &&
                                inputRefs.map((ref, i) => (
                                    <Col span={Math.floor(24 / codeLength)} key={i}>
                                        <Form.Item name={`part${i + 1}`} rules={[{ required: true, message: '' }]}>
                                            <Input
                                                onPaste={pasteCode}
                                                onFocus={selectThis}
                                                className={classNames('text-center', wrongCodeError && styles.error)}
                                                onInput={(evt) => next(evt, i)}
                                                maxLength={1}
                                                autoFocus={i === 0}
                                                ref={ref}
                                                onKeyDown={(evt) => {
                                                    setDirection(evt, i);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                ))}
                        </Row>
                        {wrongCodeError && (
                            <div className={styles.commonErrorWrapper}>
                                <div className={styles.commonError}>{wrongCodeError}</div>
                            </div>
                        )}
                        <Button ref={btnRef} className="w-100" type="primary" htmlType="submit" disabled={isSubmitDisabled || !attempts}>
                            {t('ConfirmMobile.labels.continue')}
                        </Button>
                        {!attempts ? (
                            <div className={classNames('mt-4', styles.txt)}>{t('EmailConfirmation.labels.attemptsExceeded')}</div>
                        ) : (
                            <>
                                {canResend ? (
                                    <Button type="link" className={classNames('mt-4', styles.txt, styles.txtlink)} onClick={sendPhoneAgain}>
                                        {t('EmailConfirmation.labels.sendAgain')}
                                    </Button>
                                ) : (
                                    <div className={classNames('mt-4', styles.txt)}>
                                        {t('EmailConfirmation.labels.sendAfterAWhile')} {fromNow}
                                    </div>
                                )}
                            </>
                        )}
                    </Form>
                </Card>
                <Space align="center" className={classNames(styles.txtlink, styles.bottomTxt)}>
                    <Link onClick={resetTicket} to="/account">
                        {t('ConfirmMobile.labels.changeMobile')}
                    </Link>
                </Space>
            </div>
        </>
    );
};
