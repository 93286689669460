import {
    ChangeOkoEventDTO,
    CourseAppointmentDetails,
    EncounterDetailDTOStatus,
    endEncounterEvent,
    getCourseAppointmentDetails,
    takeEncounterEvent,
} from '@api/mainServiceAPI';
import { CountdownTimer } from '@components';
import { AppointmentStatus } from '@enums';
import { AlertIconOutlined, CalendarIcon, CalendarNo, ChatWith3DotsIcon, ChevronLeft, ChevronRight, DocumentIcon, UserIcon } from '@icons';
import { UserRole, selectAuthUser, selectCurrentProfile } from '@sliceUser';
import { Button, Col, Row, Tag } from 'antd';
import classNames from 'classnames';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { MED_TIME_BEFORE_START_OF_SESSION } from 'src/ADMINISTRATIVE_CONFIG';
import { Chats } from 'src/components/Chats/Chats';
import { EncounterStatusBadge } from 'src/components/EncounterStatusBadge/EncounterStatusBadge';
import { AidOkoEventStatus } from 'src/enums/aid-oko-event-status.enum';
import { AidOkoEventType } from 'src/enums/aid-oko-event-type.enum';
import { getEncounterStatus } from '../../dictionary';
import { CancelProcedureForAllModal } from '../modals/CancelProcedureForAllModal/CancelProcedureForAllModal';
import styles from './CallCenterManagerProcedurePage.module.scss';

interface ICallCenterManagerProcedurePageProps {
    forEvent?: boolean;
}

export const CallCenterManagerProcedurePage = ({ forEvent = false }: ICallCenterManagerProcedurePageProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const authUser = useSelector(selectAuthUser);
    const currentProfile = useSelector(selectCurrentProfile);

    const [encounter, setEncounter] = useState<CourseAppointmentDetails>();
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

    useEffect(() => {
        retrieveEncounterDetails();
    }, []);

    const retrieveEncounterDetails = () => {
        if (params.encounterOrEventId) {
            getCourseAppointmentDetails(params.encounterOrEventId).then((result) => {
                setEncounter(result.data);
            });
        }
    };

    const onBackToAllAppointments = () => {
        navigate(-1);
    };

    const takeInWork = () => {
        const body: ChangeOkoEventDTO = {
            sourceFhirId: encounter?.appointmentDetailsDTO?.appointmentId,
            managerId: authUser?.id,
            sourceType: 'Appointment',
            cancel: false,
        };

        takeEncounterEvent(body).then((result) => {
            if (result) {
                retrieveEncounterDetails();
            }
        });
    };

    // todo check is correct
    const returnEvent = () => {
        endEncounterEvent({
            okoEventFhirId: encounter?.okoEventDTO?.id,
            managerId: authUser?.id,
            cancel: true,
        }).then((result) => {
            if (result) {
                retrieveEncounterDetails();
            }
        });
    };

    const resolveEvent = () => {
        endEncounterEvent({
            okoEventFhirId: encounter?.okoEventDTO?.id,
            managerId: authUser?.id,
            cancel: false,
        }).then((result) => {
            if (result) {
                retrieveEncounterDetails();
            }
        });
    };

    const onDoctorDetails = (practitionerUserId?: string) => {
        navigate('/staff/' + practitionerUserId);
    };

    const onPatientDetails = (patientId?: string) => {
        if (encounter?.okoEventDTO?.manager?.id === authUser?.id && encounterStatus === AppointmentStatus.pending) {
            navigate(
                '/medcard/' +
                    patientId +
                    '?appointmentId=' +
                    encounter?.appointmentDetailsDTO?.appointmentId +
                    '&encounterFhirId=' +
                    encounter?.appointmentDetailsDTO?.appointmentId +
                    '&managerId=' +
                    encounter?.okoEventDTO?.manager?.id +
                    '&appointmentStatus=' +
                    encounterStatus,
            );
        } else {
            navigate('/medcard/' + patientId);
        }
    };

    const onPatientProcedureDetails = (encounterId: string) => {
        if (authUser?.role === UserRole.Nurse) {
            navigate(`/procedures/${encounterId}`);
        } else {
            navigate(`/encounters/all/${encounterId}`);
        }
    };

    const onThreatmentResultsDetails = (patientId?: string) => {
        console.log('Open results.');
    };

    const getManagerName = () => {
        return (
            encounter?.okoEventDTO?.manager?.display &&
            encounter.okoEventDTO.manager?.display?.split(' ')[0] + ' ' + encounter.okoEventDTO.manager?.display?.split(' ')[1].substring(0, 1) + '.'
        );
    };

    const canEdit = (): boolean => {
        return encounter?.okoEventDTO?.status === AidOkoEventStatus.in_progress && encounter?.okoEventDTO?.manager?.id === authUser?.id;
    };

    const encounterStatus = getEncounterStatus(encounter?.appointmentDetailsDTO?.appointmentStatus, encounter?.okoEventDTO?.paymentStatus);

    const onConnectToAppointment = () => {
        navigate('/appointment?appointmentId=' + encounter?.appointmentDetailsDTO?.appointmentId);
    };

    return (
        <div className={styles.wrapper}>
            {showCancelModal && (
                <CancelProcedureForAllModal
                    encounter={encounter!}
                    showModal={showCancelModal}
                    onSuccess={() => {
                        resolveEvent();
                        setShowCancelModal(false);
                    }}
                    onCancel={() => setShowCancelModal(false)}
                />
            )}
            <div className="d-flex justify-content-between">
                <div className={styles.titleContainer}>
                    <Button onClick={() => onBackToAllAppointments()} className="backBtn">
                        <ChevronLeft className="transparent" />
                    </Button>
                    <p className={styles.title}>
                        {encounter?.appointmentDetailsDTO?.partOf && t(`enums.planDefinition.${encounter?.appointmentDetailsDTO?.partOf}`)}
                    </p>
                </div>
            </div>

            <Row gutter={24}>
                <Col md={16} sm={24} xs={24}>
                    {authUser?.role === UserRole.Call_center_manager && (
                        <Col md={24} sm={24} xs={24}>
                            <div className={styles.eventTopBlock}>
                                <div className={styles.eventField}>
                                    {forEvent ? (
                                        <>
                                            {encounter?.okoEventDTO?.type && forEvent && (
                                                <div
                                                    className={classNames(
                                                        encounter?.okoEventDTO?.isActive
                                                            ? encounter?.okoEventDTO?.manager?.id
                                                                ? styles.statusSuccess
                                                                : styles.statusError
                                                            : styles.statusInfo,
                                                        styles.typeIcon,
                                                    )}
                                                >
                                                    {encounter?.okoEventDTO?.type === AidOkoEventType.new_message ? (
                                                        <ChatWith3DotsIcon />
                                                    ) : encounter?.okoEventDTO?.type === AidOkoEventType.new_document ? (
                                                        <DocumentIcon />
                                                    ) : encounter?.okoEventDTO?.type === AidOkoEventType.new_patient ? (
                                                        <DocumentIcon />
                                                    ) : (
                                                        <CalendarNo />
                                                    )}
                                                </div>
                                            )}

                                            <div>
                                                <p>
                                                    {encounter?.okoEventDTO?.type &&
                                                        t(`callCenterManager.encounterEventTypes.${encounter?.okoEventDTO?.type}`)}
                                                </p>
                                                <p className={styles.secondaryField}>
                                                    {encounter?.okoEventDTO?.dateTime &&
                                                        format(new Date(encounter?.okoEventDTO?.dateTime), 'dd.MM.yyyy HH:mm')}
                                                </p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <p>{t("appointment_card")}</p>
                                                <p className={styles.secondaryField}>
                                                    {encounter?.appointmentDetailsDTO?.start &&
                                                        format(new Date(encounter?.appointmentDetailsDTO?.start), 'dd.MM.yyyy HH:mm')}
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="d-flex">
                                    {encounter?.okoEventDTO?.status === AidOkoEventStatus.in_progress && encounter?.okoEventDTO?.manager?.display && (
                                        <div style={{ marginRight: '24px' }}>
                                            <p className={styles.managerLabel}>{t('appointment.manager')}</p>
                                            <p className={styles.managerName}>{getManagerName()}</p>
                                        </div>
                                    )}
                                    {encounter?.okoEventDTO?.status === AidOkoEventStatus.in_progress &&
                                        encounter?.okoEventDTO?.manager?.id === authUser?.id && (
                                            <>
                                                <Button onClick={() => returnEvent()}>{t('appointment.return')}</Button>
                                                <Button type="primary" style={{ marginLeft: '8px' }} onClick={() => resolveEvent()}>
                                                    {t('appointment.issueResolved')}
                                                </Button>
                                            </>
                                        )}
                                    {encounter?.okoEventDTO?.status !== AidOkoEventStatus.in_progress && (
                                        <Button type="primary" style={{ marginLeft: '8px' }} onClick={() => takeInWork()}>
                                            {t('appointment.takeInWork')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Col>
                    )}
                    <Col md={24} sm={24} xs={24}>
                        <div className={styles.eventBottomBlock}>
                            <div className={styles.infoField}>
                                <div className="d-flex justify-content-between w-100">
                                    <div className="d-flex">
                                        <div>
                                            <CalendarIcon />
                                        </div>
                                        <div>
                                            <p>{t(`appointment.dateTime`)}</p>
                                            <div className="d-flex">
                                                <p className={styles.dateText}>
                                                    {encounter?.appointmentDetailsDTO?.start &&
                                                        format(new Date(encounter?.appointmentDetailsDTO?.start), 'dd.MM.yyyy HH:mm')}
                                                </p>
                                                <div className={styles.statusField}>
                                                    <EncounterStatusBadge
                                                        encounterStatus={encounter?.appointmentDetailsDTO?.appointmentStatus}
                                                        paymentStatus={encounter?.okoEventDTO?.paymentStatus}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {encounter?.appointmentDetailsDTO?.start &&
                                        encounterStatus === AppointmentStatus.confirmed &&
                                        encounter?.appointmentDetailsDTO?.practitionerFhirId === currentProfile?.fhirId && (
                                            <CountdownTimer
                                                timeX={new Date(encounter?.appointmentDetailsDTO?.start)}
                                                before={MED_TIME_BEFORE_START_OF_SESSION}
                                                keepAfterX={true}
                                                render={(timeLabel: string) => (
                                                    <div className="d-flex">
                                                        {timeLabel && (
                                                            <Tag className={styles.startsOver}>
                                                                {t('docMainPage.startsOver')} <strong className="h6">{timeLabel}</strong>
                                                            </Tag>
                                                        )}
                                                        <Button type="primary" onClick={() => onConnectToAppointment()}>
                                                            {t('appointment.connect')}
                                                        </Button>
                                                    </div>
                                                )}
                                            ></CountdownTimer>
                                        )}
                                    {encounter?.appointmentDetailsDTO?.appointmentStatus === EncounterDetailDTOStatus.cancelled && (
                                        <div className={styles.cancelReasonBlock}>
                                            <AlertIconOutlined />
                                            <p>{t('appointment.cancelReason')}</p>
                                            <p>
                                                {t(
                                                    'enums.encounterChangeReason.' + encounter?.appointmentDetailsDTO?.changeReason?.at(-1)?.type,
                                                    t("not_specified_1"),
                                                )}
                                            </p>
                                        </div>
                                    )}
                                </div>

                                {canEdit() && <Button onClick={() => setShowCancelModal(true)}>{t('appointment.change')}</Button>}
                            </div>

                            <div className={styles.infoField}>
                                <div className="d-flex align-items-center">
                                    <div className={styles.avatar}>
                                        {encounter?.appointmentDetailsDTO?.practitionerPhoto ? (
                                            <img src={encounter?.appointmentDetailsDTO?.practitionerPhoto} />
                                        ) : (
                                            <UserIcon />
                                        )}
                                    </div>
                                    <div>
                                        <p
                                            onClick={() => onDoctorDetails(encounter?.appointmentDetailsDTO?.practitionerId)}
                                            className={styles.nameText}
                                        >
                                            {encounter?.appointmentDetailsDTO?.practitionerName}
                                        </p>
                                        <div className="d-flex">
                                            <p>
                                                {encounter?.appointmentDetailsDTO?.practitionerRoleCode &&
                                                    t(`appointment.practitionerRoles.${encounter?.appointmentDetailsDTO?.practitionerRoleCode}`)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {encounter?.patientParticipants &&
                                encounter?.patientParticipants?.length > 0 &&
                                encounter?.patientParticipants.map((item, i) => (
                                    <div className={styles.infoField} key={`patient - ${i}`}>
                                        <div className="d-flex">
                                            <div className={styles.avatar}>
                                                <UserIcon />
                                            </div>
                                            <div>
                                                <p onClick={() => onPatientDetails(item?.patient?.id)} className={styles.nameText}>
                                                    {item?.patient?.fullName
                                                        ? item?.patient?.fullName
                                                        : `${item?.patient?.lastName} ${item?.patient?.firstName} ${item?.patient?.middleName}`}
                                                </p>
                                                <div className="d-flex">
                                                    <p>{dayjs(item?.patient?.birthDate).fromNow(true)}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            {/* {encounterStatus === AppointmentStatus.completed && (
                                                <a className={styles.resultsLink} onClick={() => onThreatmentResultsDetails(item.id)}>
                                                    {t('appointment.results')}
                                                </a>
                                            )} */}

                                            {item.encounterId && (
                                                <Button onClick={() => onPatientProcedureDetails(item.encounterId!)} className="gotoBtn">
                                                    <ChevronRight />
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Col>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Chats
                        currentUserRole={authUser?.role}
                        currentUserId={authUser?.role === UserRole.Nurse ? currentProfile?.fhirId : authUser?.id}
                        chats={encounter?.chat}
                        isProcedure={encounter?.appointmentDetailsDTO?.serviceType === 'therapy-session'}
                        haveAccessForEdit={
                            authUser?.role === UserRole.Nurse ||
                            (encounter?.okoEventDTO?.manager?.id === authUser?.id && encounter?.okoEventDTO?.status === 'in_progress')
                        }
                        headerInfo={authUser?.role !== UserRole.Call_center_manager ? t("chat.any_questions") : undefined}
                    />
                </Col>
            </Row>
        </div>
    );
};
