export enum ComplaintsListEnum {
    LOW_VISION = 'LOW_VISION',
    REDUCED_DISTANCE_VISION = 'REDUCED_DISTANCE_VISION',
    EXOTROPIA = 'EXOTROPIA',
    CONVERGENT_STRABISMUS = 'CONVERGENT_STRABISMUS',
    DISCHARGE_FROM_RIGHT_EYE = 'DISCHARGE_FROM_RIGHT_EYE',
    DISCHARGE_FROM_LEFT_EYE = 'DISCHARGE_FROM_LEFT_EYE',
    DISCHARGE_FROM_BOTH_EYES = 'DISCHARGE_FROM_BOTH_EYES',
    OSCILLATORY_EYE_MOVEMENTS = 'OSCILLATORY_EYE_MOVEMENTS',
}

export const PregnancyBirthOptions = ['yes', 'no'];
export const PregnancyOptions = ['common', 'normal'];
export const PregnancyChildbirthOptions = ['common', 'cesarean'];

export const HeredityOptions = ['yes', 'no'];

export const AllergologicalHistoryOptions = ['yes', 'no'];
