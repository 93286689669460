import { resetData, restorePassword, selectRemoteValidationMessages, selectSessionIdFirst, selectSessionRecoverId, sendEmail } from '@sliceUser';
import { Button, Card, Form, Input, Space } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './RegisterPage.module.scss';
import { Logo } from 'src/components/Logo/Logo';

interface IRegisterPageProps {
    restore?: boolean;
}

export const RegisterPage = ({ restore }: IRegisterPageProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const sessionIdFirst = useSelector(selectSessionIdFirst);
    const sessionRecoverId = useSelector(selectSessionRecoverId);
    const remoteValidationMessages = useSelector(selectRemoteValidationMessages);

    useEffect(() => {
        dispatch(resetData());
    }, []);

    useEffect(() => {
        if (remoteValidationMessages.email) {
            const value = form.getFieldValue('email');
            form.setFields([{ name: 'email', value, errors: [t(remoteValidationMessages.email)] }]);
        }
    }, [remoteValidationMessages]);

    const { validateForm } = useFormValidation(form);

    useEffect(() => {
        if (!restore && sessionIdFirst) {
            navigate('/confirm-email');
        }
    }, [sessionIdFirst, restore]);

    useEffect(() => {
        if (restore && sessionRecoverId) {
            navigate('/confirm-email-restore');
        }
    }, [sessionRecoverId, restore]);

    const submit = (formValue: { email: string }) => {
        if (restore) {
            dispatch(restorePassword(formValue));
        } else {
            dispatch(sendEmail(formValue));
        }
    };

    const validate = () => {
        validateForm(['email']);
    };

    return (
        <>
            <div className={styles.wrapper}>
                <Card>
                    <Logo 
                        big 
                        marginBottom 
                    /> 
                    <Form
                        form={form}
                        name="register"
                        wrapperCol={{ span: 24 }}
                        initialValues={{ email: '' }}
                        autoComplete="off"
                        onFinish={submit}
                        className={styles.form}
                        onChange={validate}
                    >
                        <h4>{restore ? t('Register.restore') : t('Register.register')}</h4>
                        {restore && <p className={styles.restoreDescr}>{t('Register.restore-description')}</p>}
                        <Form.Item
                            className="w-100 mb-5"
                            name="email"
                            validateTrigger={'onBlur'}
                            rules={[
                                { type: 'email', message: t('Register.validation.wrongEmail') },
                                { required: true, message: t('Register.validation.emailIsRequired') },
                            ]}
                        >
                            <Input autoFocus placeholder={t('Register.labels.email')} />
                        </Form.Item>
                        <Button className="w-100" type="primary" htmlType="submit">
                            {t('Register.labels.continue')}
                        </Button>
                    </Form>
                </Card>
                <Space align="center" className={styles.bottomTxt}>
                    {t('Register.labels.hasAccount')}
                    <Link to="/login">{t('Register.labels.login')}</Link>
                </Space>
            </div>
        </>
    );
};
