import { targetColorClicked } from './helpers';
import { IProcedureBase, ProcedureBase } from './procedureBase.abstract';

export interface IMeridionalPatternConfig {
    animationInterval: number;
    stripeWidth: number;
    pointImage: string;
    pointSize: number;
    angle: number;
}

export class MeridionalPatternProcedure extends ProcedureBase implements IProcedureBase {
    // Procedure's global configuration
    // private _pointFadeOutTimerMax = this._fps * 0.5;
    // TODO Since there's no fade out effect, removing the timeout:
    private _pointFadeOutTimerMax = 0;
    private _pointFadeOutTimer = this._pointFadeOutTimerMax;
    private _assetsPath = '/icons/procedures/meridionalPattern';
    private _pointTargetColor = 'rgb(150,0,0)'; // pointImage must be the same color, and must be RGB
    private _stripeColor1 = 'white';
    private _stripeColor2 = 'black';

    private _config: IMeridionalPatternConfig;

    private _maxStripesContainerSize: number; // is equal diagonal, when angle is 45 deg
    private _maxStripesCount: number;
    private _pointImageObj: HTMLImageElement;
    private _pointPosX: number;
    private _pointPosY: number;
    private _lines: { start: number[]; end: number[] }[];

    constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, config: IMeridionalPatternConfig) {
        super(canvas, ctx, config);

        this._config = config;
        this._maxStripesContainerSize = Math.sqrt((this._canvas.width * 2) ** 2 + (this._canvas.height * 2) ** 2);
        this._maxStripesCount = Math.ceil(this._maxStripesContainerSize / this._config.stripeWidth);

        // Adding space on each side to hide the endings of the lines
        const extraSpace = -0.5 * this._config.stripeWidth;
        // Counting coordinates from the virtual rectangle
        const virtualRect = {
            x: extraSpace,
            y: extraSpace,
            w: this._canvas.width - 2 * extraSpace,
            h: this._canvas.height - 2 * extraSpace,
        };
        const vStep = Math.abs(this._config.stripeWidth / Math.cos((this._config.angle * Math.PI) / 180));
        const hStep = this._config.stripeWidth / Math.sin((this._config.angle * Math.PI) / 180);
        // Number of lines crossing the vertical edge
        const stripVSteps = Math.ceil(virtualRect.h / vStep);
        // Number of lines crossing the horizontal edge
        const stripHSteps = Math.ceil(virtualRect.w / hStep);
        // Arranging the virtual rectangle to round the number of lines
        virtualRect.h = this._config.angle === 90 || this._config.angle === 180 ? virtualRect.h : stripVSteps * vStep;
        virtualRect.w = this._config.angle === 0 ? virtualRect.w : stripHSteps * hStep;

        const mirrored = this._config.angle > 90;

        const lines: { start: number[]; end: number[] }[] = [];

        const startX = extraSpace + (mirrored ? virtualRect.w : 0);
        const startY = extraSpace;

        const startPoint = [startX, startY];
        const endPoint = [startX, startY];
        for (let i = 0; i < this._maxStripesCount; i++) {
            if (this._config.angle === 0 || this._config.angle === 180) {
                startPoint[0] = 0;
                startPoint[1] += vStep;
                endPoint[0] = this._canvas.width;
                endPoint[1] = startPoint[1];
            } else if (this._config.angle === 90) {
                startPoint[0] += hStep;
                startPoint[1] = 0;
                endPoint[0] = startPoint[0];
                endPoint[1] = this._canvas.height;
            } else {
                if (i <= stripVSteps) {
                    startPoint[1] += vStep;
                } else {
                    startPoint[0] += (mirrored ? -1 : 1) * hStep;
                }
                if (i <= stripHSteps) {
                    endPoint[0] += (mirrored ? -1 : 1) * hStep;
                } else {
                    endPoint[1] += vStep;
                }
            }
            lines.push({ start: [...startPoint], end: [...endPoint] });
        }

        this._lines = lines;

        // this._pointImageObj = new Image();
        // this._pointImageObj.src = `${this._assetsPath}/${config.pointImage}.png`;

        this._setPointRandomPosition();
    }

    public run(): void {
        // animation
        this.animationInterval = setInterval(() => {
            [this._stripeColor1, this._stripeColor2] = [this._stripeColor2, this._stripeColor1];
        }, this._config.animationInterval);

        // interactive
        this._setEventListeners();

        // draw canvas (animation)
        this._drawProcedure();
    }

    private _drawProcedure(): void {
        if (!this.paused) {
            this._drawStripes();
            this._drawPoint();
        }

        if (this._animationNeeded) {
            // reduce FPS
            this.animationTimeout = setTimeout(() => {
                requestAnimationFrame(() => this._drawProcedure());
            }, 1000 / this._fps);
        }
    }

    private _setEventListeners(): void {
        const mouseclickHandler = (e: MouseEvent) => {
            // start animation to fade out point
            this._pointFadeOutTimer = 0;

            if (targetColorClicked(this._canvas, this._ctx, e, this._pointTargetColor)) {
                this._successTries++;
                // change point position after fading out animation
                setTimeout(() => {
                    this._setPointRandomPosition();
                }, (this._pointFadeOutTimerMax / this._fps) * 1000);
            } else {
                this._failedTries++;
            }

            this._totalTries++;

            this.interact();
        };

        this.mouseclickHandler = mouseclickHandler.bind(this);
        this._canvas.addEventListener('click', this.mouseclickHandler);
    }

    private _setPointRandomPosition(): void {
        let dotCoords;
        const limitXs = this._config.stripeWidth / 2;
        const limitYs = limitXs;
        const limitXe = this._canvas.width - limitXs;
        const limitYe = this._canvas.height - limitXs;
        do {
            const randomLineForDot = Math.floor(Math.random() * this._lines.length);
            const line = this._lines[randomLineForDot];
            // On a random line trying to find a random point
            const point = this.getRandomPointOnLine(line.start[0], line.start[1], line.end[0], line.end[1]);
            // Checking out the circle is going to be visible
            if (point.x && point.y && point.x > limitXs && point.y > limitYs && point.x < limitXe && point.y < limitYe) {
                dotCoords = point;
            }
            // Repeating if the point is not visible. Some lines can be invisible completely, so - looking for a new line too.
        } while (!dotCoords);
        this._pointPosX = dotCoords?.x as number;
        this._pointPosY = dotCoords?.y as number;
        this._pointFadeOutTimer = this._pointFadeOutTimerMax;
    }

    // ========================================================================
    // Just drawing logic below
    // ========================================================================
    private _drawStripes(): void {
        this._ctx.save();

        this._lines.forEach((p, i) => {
            this._ctx.beginPath();
            this._ctx.moveTo(p.start[0], p.start[1]);
            this._ctx.lineWidth = this._config.stripeWidth;
            this._ctx.strokeStyle = i % 2 == 0 ? this._stripeColor1 : this._stripeColor2;
            this._ctx.lineTo(p.end[0], p.end[1]);
            this._ctx.closePath();
            this._ctx.stroke();
        });

        this._ctx.restore();
    }

    private getRandomPointOnLine = (x1: number, y1: number, x2: number, y2: number): { x: number; y: number } => {
        const random = Math.random();
        const x = x1 + (x2 - x1) * random;
        const y = y1 + (y2 - y1) * random;
        return { x, y };
    };

    private _drawPoint(): void {
        // =========== it was a requirement - to animate fadeIn/fadeOut. Now - it's not necessary

        // this._ctx.save();

        // // set angle of canvas for the default value
        // this._ctx.rotate((this._config.angle * Math.PI) / 180);

        // this._pointFadeOutTimer++;
        // if (this._pointFadeOutTimer < this._pointFadeOutTimerMax) {
        //     this._ctx.save();

        //     this._ctx.translate(this._pointPosX, this._pointPosY);
        //     this._ctx.rotate((360 * Math.PI) / 180 + this._pointFadeOutTimer / 5);
        //     this._ctx.drawImage(
        //         this._pointImageObj,
        //         -(this._config.pointSize / 2),
        //         -(this._config.pointSize / 2),
        //         this._config.pointSize,
        //         this._config.pointSize,
        //     );

        //     this._ctx.restore();
        // } else {
        //     this._ctx.drawImage(
        //         this._pointImageObj,
        //         this._pointPosX - this._config.pointSize / 2,
        //         this._pointPosY - this._config.pointSize / 2,
        //         this._config.pointSize,
        //         this._config.pointSize,
        //     );
        // }

        // this._ctx.restore();
        // ===========

        this._ctx.beginPath();
        this._ctx.arc(this._pointPosX, this._pointPosY, this._config.pointSize / 2, 0, Math.PI * 2);
        this._ctx.fillStyle = this._pointTargetColor;
        this._ctx.fill();
        this._ctx.closePath();
    }
}
