import { AboutPractitionerDTO, ConsultationSlotDTO, GetAllPractitionersChiefComplaintCodesItem } from '@api/mainServiceAPI';
import { AgreementsIcon, Bubble, CalendarIcon, Check, ChevronLeft, ChevronRight, DiagnosisIcon, Dino2_1, DoctorIcon, PaymentIcon } from '@icons';
import { Button, Checkbox, Col, Modal, Row, Select, SelectProps } from 'antd';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { usePrompt } from 'src/hooks/useRouterPrompt';
import { UploadFileExt } from 'src/modals/FilesUploadModal/FilesUploadModal';
import { ContractStep } from './components/ContractStep/ContractStep';
import { DateTimeStep } from './components/DateTimeStep/DateTimeStep';
import { DoctorStep } from './components/DoctorStep/DoctorStep';
import { PaymentStep } from './components/PaymentStep/PaymentStep';
import { ReasonStep } from './components/ReasonStep/ReasonStep';

import { sendPatientInConsultationSlot } from '@sliceConsultation';
import { selectCurrentProfile } from '@sliceUser';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ConsultationSignUpPage.module.scss';
import {useTranslation} from "react-i18next";
import {selectPaymentServiceActive} from "../../../store/organization";
import {registerAppointmentPayment} from "@api/paymentServiceAPI";
import {OrderTypes} from "@utils/constants";

export interface ITimeSlot {
    practitionersMap: { [id: string]: string };
    time: string;
    slotId?: string;
}

export interface IConsultationDTO {
    doctor?: AboutPractitionerDTO;
    date?: Dayjs;
    time?: ITimeSlot;
    contractAgree?: boolean;
    serviceAgree?: boolean;
    reasons?: boolean[];
    customReason?: string;
    filesToUpload?: UploadFileExt[];
}

export interface IStepProps {
    onChangeStep: (step: number) => void;
    data?: IConsultationDTO;
    setData: (data: IConsultationDTO, step?: number) => void;
}

export const ConsultationSignUpPage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const currentProfile = useSelector(selectCurrentProfile);

    const [keepWatching, setKeepWatching] = useState(true);
    const { showWarningModal, handleOk, handleCancel } = usePrompt(t("consultation_sign_up.interrupt_recording"), keepWatching);

    const [selectedDiagnosis, setSelectedDiagnosis] = useState<GetAllPractitionersChiefComplaintCodesItem[]>([]);
    const [unknownDiagnosis, setUnknownDiagnosis] = useState<boolean>(false);
    const [signStep, setSignStep] = useState<number>(0);
    const [consultationStep, setConsultationStep] = useState<number>(0);
    const [signForDoctor, setSignForDoctor] = useState<boolean>(false);
    const [isStepDataChanged, setIsStepDataChanged] = useState<boolean>(false);
    const [maxEditedStep, setMaxEditedStep] = useState<number>(0);
    const [isContractSigned, setIsContractSigned] = useState<boolean>(false);

    const [data, setData] = useState<IConsultationDTO>();

    const paymentServiceActive = useSelector(selectPaymentServiceActive);

    const options: SelectProps['options'] = [
        {
            value: GetAllPractitionersChiefComplaintCodesItem.amblyopia,
            label: t('consultation_sign_up.amblyopia'),
        },
        {
            value: GetAllPractitionersChiefComplaintCodesItem.astigmatism,
            label: t('consultation_sign_up.astigmatism'),
        },
        {
            value: GetAllPractitionersChiefComplaintCodesItem.hypermetropia,
            label: t('consultation_sign_up.hypermetropia'),
        },
        {
            value: GetAllPractitionersChiefComplaintCodesItem.strabismus,
            label: t('consultation_sign_up.strabismus'),
        },
        {
            value: GetAllPractitionersChiefComplaintCodesItem.myopia,
            label: t('consultation_sign_up.myopia'),
        },
        {
            value: GetAllPractitionersChiefComplaintCodesItem.other,
            label: t('consultation_sign_up.other'),
        },
    ];

    const startDoctorOptions = [
        {
            title: t('consultation_sign_up.doctor'),
            icon: <DoctorIcon />,
            getData: (data?: IConsultationDTO): string | null =>
                data?.doctor
                    ? `${data.doctor.lastName} ${data.doctor.firstName?.[0]}. ${data.doctor.middleName ? data.doctor.middleName[0] + '.' : ''}`
                    : null,
        },
        {
            title: t('consultation_sign_up.date_and_time'),
            icon: <CalendarIcon />,
            getData: (data?: IConsultationDTO): string | null =>
                data?.date ? `${data.date.format('D MMMM YYYY')}${data.time ? ', ' + data.time.time : ''}` : null,
        },
        {
            title: t('consultation_sign_up.reason_for_visit'),
            icon: <DiagnosisIcon />,
        },
        {
            title: t('consultation_sign_up.contract'),
            icon: <AgreementsIcon />,
        },
        {
            title: t('consultation_sign_up.payment'),
            icon: <PaymentIcon />,
        },
    ].filter(f => !paymentServiceActive ? f.title !== t('consultation_sign_up.payment') : f);


    useEffect(() => {
        if (signStep === 0 || consultationStep > 3) {
            setKeepWatching(false);
        } else {
            setKeepWatching(true);
        }
    }, [signStep, consultationStep]);

    useEffect(() => {
        if (unknownDiagnosis) {
            setSelectedDiagnosis([]);
        }
    }, [unknownDiagnosis]);

    const startCalendarOptions = [...startDoctorOptions];
    startCalendarOptions.splice(0, 2, startCalendarOptions[1], startCalendarOptions[0]);

    const handleChange = (value: GetAllPractitionersChiefComplaintCodesItem[]) => {
        setSelectedDiagnosis(value);
    };

    const handleSignForDoctor = () => {
        setSignStep(1);
        setSignForDoctor(true);
    };

    const handleBack = () => {
        setSignStep(0);
        setConsultationStep(0);
        setSignForDoctor(false);
        setData(undefined);
    };

    const handleChangeStep = (step: number) => {
        setConsultationStep(step);

        if (step > maxEditedStep) {
            setMaxEditedStep(step);
        }
    };

    const setStepData = (stepData: any, step?: number) => {
        if (step) {
            setMaxEditedStep(step);
        }

        if ((step || 0) < maxEditedStep && signForDoctor && step === 1) {
            // if we changed doctor - we need to reset slots
            setData((prev) => ({ ...prev, ...stepData, date: null, time: null }));
        } else {
            setData((prev) => ({ ...prev, ...stepData }));
        }
    };

    const submitSignUpPage = (formValue: ConsultationSlotDTO) => {
        setIsContractSigned(true);
        formValue.preliminaryDiagnosis = selectedDiagnosis;
        dispatch(sendPatientInConsultationSlot(formValue));
    };

    return (
        <div className={signStep === 0 ? styles['start-wrapper'] : styles.wrapper}>
            {signStep === 0 && (
                <div>
                    <div className="text-center">
                        <p className={styles.title}>{t("consultation_sign_up.appointment")}</p>
                    </div>
                    <div className="mt-5">
                        <p className={styles['diagnosis-title']}>{t("diagnosis")}</p>
                        {!unknownDiagnosis && (
                            <Select
                                value={selectedDiagnosis}
                                placeholder={t("consultation_sign_up.select_diagnoses")}
                                mode="multiple"
                                style={{ width: '100%' }}
                                onChange={handleChange}
                                tokenSeparators={[',']}
                                options={options}
                                showSearch={false}
                                showArrow
                            />
                        )}
                    </div>
                    <div className="mt-4 d-flex align-items-center">
                        <Checkbox checked={unknownDiagnosis} onChange={() => setUnknownDiagnosis(!unknownDiagnosis)} className="m-0">
                            <span className={styles.unknown}>{t("consultation_sign_up.unknown_diagnosis")}</span>
                        </Checkbox>
                    </div>
                    {(unknownDiagnosis || selectedDiagnosis?.length > 0) && (
                        <div className="mt-5">
                            <Row onClick={handleSignForDoctor} align="middle" justify="space-between" className={styles['appointment-type']}>
                                <Col span={2}>
                                    <DoctorIcon />
                                </Col>
                                <Col span={20}>
                                    <p className={styles.title}>{t("consultation_sign_up.specific_doctor_appointment")}</p>
                                    <p className={styles.subtitle}>{t("consultation_sign_up.select_doctor_by_name")}</p>
                                </Col>
                                <Col className="d-flex justify-content-end" span={2}>
                                    <ChevronRight />
                                </Col>
                            </Row>
                            <Row onClick={() => setSignStep(1)} align="middle" justify="space-between" className={styles['appointment-type']}>
                                <Col span={2}>
                                    <CalendarIcon />
                                </Col>
                                <Col span={20}>
                                    <p className={styles.title}>{t("consultation_sign_up.any_doctor_specific_date")}</p>
                                    <p className={styles.subtitle}>
                                        {t("consultation_sign_up.select_date_and_time")}
                                    </p>
                                </Col>
                                <Col className="d-flex justify-content-end" span={2}>
                                    <ChevronRight />
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            )}
            {signStep === 1 && (
                <div>
                    <div className="d-flex align-items-center">
                        <Button onClick={handleBack} className="backBtn me-3">
                            <ChevronLeft className={styles.transparentIcon} />
                        </Button>
                        <p className={styles.title}>{t("consultation_sign_up.appointment")}</p>
                    </div>
                    <Row justify="space-between" className={styles['consultation-step']}>
                        <Col span={6}>
                            {signForDoctor
                                ? startDoctorOptions.map((step, i) => (
                                      <Row
                                          key={i}
                                          className={classNames(
                                              styles['sign-step'],
                                              consultationStep === i && styles.active,
                                              maxEditedStep < i && consultationStep < i && consultationStep < i && styles.bw,
                                              (maxEditedStep >= i || consultationStep > i) && styles.hover,
                                          )}
                                          onClick={() => {
                                              ((!isStepDataChanged && maxEditedStep >= i) || consultationStep > i) && handleChangeStep(i);
                                          }}
                                      >
                                          <Col className="p-0" span={4}>
                                              {step.icon}
                                          </Col>
                                          <Col span={20}>
                                              <div className={styles.title}>
                                                  {i + 1}. {step.title}
                                                  <Check />
                                                  {step.getData && <div>{step.getData(data)}</div>}
                                              </div>
                                          </Col>
                                      </Row>
                                  ))
                                : startCalendarOptions.map((step, i) => (
                                      <Row
                                          key={i}
                                          className={classNames(
                                              styles['sign-step'],
                                              consultationStep === i && styles.active,
                                              maxEditedStep < i && consultationStep < i && styles.bw,
                                              (maxEditedStep >= i || consultationStep > i) && styles.hover,
                                          )}
                                          onClick={() => {
                                              ((!isStepDataChanged && maxEditedStep >= i) || consultationStep > i) && handleChangeStep(i);
                                          }}
                                      >
                                          <Col className="p-0" span={4}>
                                              {step.icon}
                                          </Col>
                                          <Col span={20}>
                                              <div className={styles.title}>
                                                  {i + 1}. {step.title}
                                                  <Check />
                                                  {step.getData && <div>{step.getData(data)}</div>}
                                              </div>
                                          </Col>
                                      </Row>
                                  ))}
                        </Col>
                        <Col span={16}>
                            {signForDoctor ? (
                                <>
                                    {consultationStep === 0 && (
                                        <DoctorStep
                                            data={data}
                                            setData={setStepData}
                                            diagnosis={selectedDiagnosis}
                                            isDoctorFirst={signForDoctor}
                                            onChangeStep={handleChangeStep}
                                            isContractSigned={isContractSigned}
                                        />
                                    )}
                                    {consultationStep === 1 && (
                                        <DateTimeStep
                                            data={data}
                                            setData={setStepData}
                                            diagnosis={selectedDiagnosis}
                                            isDoctorFirst={signForDoctor}
                                            onChangeStep={handleChangeStep}
                                            isContractSigned={isContractSigned}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    {consultationStep === 1 && (
                                        <DoctorStep
                                            data={data}
                                            setData={setStepData}
                                            diagnosis={selectedDiagnosis}
                                            isDoctorFirst={signForDoctor}
                                            onChangeStep={handleChangeStep}
                                            isContractSigned={isContractSigned}
                                        />
                                    )}
                                    {consultationStep === 0 && (
                                        <DateTimeStep
                                            data={data}
                                            setData={setStepData}
                                            diagnosis={selectedDiagnosis}
                                            isDoctorFirst={signForDoctor}
                                            onChangeStep={handleChangeStep}
                                            isContractSigned={isContractSigned}
                                        />
                                    )}
                                </>
                            )}
                            {consultationStep === 2 && (
                                <ReasonStep
                                    data={data}
                                    setData={setStepData}
                                    onChangeStep={handleChangeStep}
                                    isContractSigned={isContractSigned} />
                            )}
                            {consultationStep === 3 && (
                                <ContractStep
                                    data={data}
                                    setData={setStepData}
                                    onChangeStep={handleChangeStep}
                                    onSubmit={submitSignUpPage}
                                    isContractSigned={isContractSigned}
                                />
                            )}
                            {consultationStep === 4 && <PaymentStep data={data} setData={setStepData} onChangeStep={handleChangeStep} />}
                        </Col>
                    </Row>
                </div>
            )}

            <Modal
                title={t("consultation_sign_up.interrupt_recording")}
                width={592}
                open={showWarningModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="support" className="w-100" onClick={handleCancel}>
                        {t("cancel_button")}
                    </Button>,
                    <Button className="w-100" onClick={handleOk} key="submit" type="primary">
                        {t("consultation_sign_up.schedule_later")}
                    </Button>,
                ]}
            >
                <div className={styles.modal}>
                    <div className={styles.dino2_1}>
                        <div className={styles.bubble}>
                            <p>{t("consultation_sign_up.not_finished_yet")}</p>
                            <Bubble />
                        </div>
                        <Dino2_1 />
                    </div>
                    <div className="text-center">
                        <p className={styles.description}>
                            {t("consultation_sign_up.about_to_leave")}
                        </p>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
