import {CheckOutlined} from '@ant-design/icons';
import {UserProfileDTO} from '@api/userServiceAPI';
import {CalendarInputIcon, DropdownArrowIcon, ProfileIcon} from '@icons';
import {
    getUnhandledMsgs,
    logoutLocal,
    selectAuthUser,
    selectCurrentProfile,
    selectUnhandledMsgs,
    setCurrentProfile,
    switchToEndUser,
} from '@sliceUser';
import {Badge, Button, Dropdown, Space, message} from 'antd';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import classNames from 'classnames';
import {t} from 'i18next';
import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, NavLink, Outlet, useNavigate} from 'react-router-dom';
import {WebsocketContext} from 'src/contexts/ws.context';
import {Footer} from '../../_general/Footer/Footer';

import {
    getPatientActiveConsultations,
    getPatientCarePlan,
    getPatientEncountersById,
    selectActiveConsultations,
    selectCarePlan,
    setPatientWorkspacePrepared,
    setPatientWorkspaceSettings,
} from '@slicePatient';
import {StompSubscription} from '@stomp/stompjs';
import {WsProcedureTopicType, WsTopic} from '@utils/websocket.topics';
import {DocInfoModal} from 'src/modals/DocInfoModal/DocInfoModal';
import {ScreenSettingsLocalStorage} from 'src/services';
import styles from './PatientLayout.module.scss';
import { Logo } from 'src/components/Logo/Logo';

export const PatientLayout = () => {
    const authUser = useSelector(selectAuthUser);
    if (!authUser) {
        return null;
    }

    const {connect, wsClient} = useContext(WebsocketContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentProfile = useSelector(selectCurrentProfile);
    const carePlan = useSelector(selectCarePlan);
    const activeConsultations = useSelector(selectActiveConsultations);
    const unhandledMsgs = useSelector(selectUnhandledMsgs);

    const [dataLoaded, setDataLoaded] = useState(false);
    const [disableMenuActions, setDisableMenuActions] = useState(false);
    const [patientsMenuItems, setPatientsMenuItems] = useState<ItemType[]>([]);
    const [showSupportBlock, setShowSupportBlock] = useState(false);
    const [canHaveRepeatedConsultation, setCanHaveRepeatedConsultation] = useState(false);



    useEffect(() => {
        setShowSupportBlock(location.pathname === '/consultation' || location.pathname === '/create-schedule');
        setDisableMenuActions(location.pathname === '/consultation' || location.pathname === '/profile');
    }, [location.pathname]);

    useEffect(() => {
        if (currentProfile) {
            connect(currentProfile.fhirId!);

            dispatch(getUnhandledMsgs());

            dispatch(
                getPatientCarePlan({
                    patientFhirId: currentProfile.fhirId!,
                }),
            );

            dispatch(
                getPatientActiveConsultations({
                    patientFhirId: currentProfile.fhirId!,
                }),
            );

            checkLS(currentProfile.fhirId!);
        }
    }, [currentProfile]);

    useEffect(() => {
        if (wsClient && currentProfile) {
            const subscription: StompSubscription = wsClient.subscribe(WsTopic.procedureTopic + currentProfile.fhirId, (wsMessage: any) => {
                const msg = JSON.parse(wsMessage.body);
                console.log('globalProcedureTopic', msg);

                switch (msg.type) {
                    case WsProcedureTopicType.conclusionSigned: {
                        dispatch(
                            getPatientCarePlan({
                                patientFhirId: currentProfile.fhirId!,
                            }),
                        );

                        dispatch(
                            getPatientActiveConsultations({
                                patientFhirId: currentProfile.fhirId!,
                            }),
                        );

                        message.info(t("patientLayout.get_report_message"));
                    }
                        break;
                }
            });

            const paymentSubscription = wsClient.subscribe(WsTopic.paymentApproved, (wsMessage: any) => {
                const msg = wsMessage.body;
                dispatch(
                    getPatientCarePlan({
                        patientFhirId: currentProfile.fhirId!,
                    }),
                );
                dispatch(
                    getPatientActiveConsultations({
                        patientFhirId: currentProfile.fhirId!,
                    }),
                );
                if (msg === 'success') {
                    message.info(t("patientLayout.payment_success_message"));
                } else if (msg === 'failed') {
                    message.error(t("patientLayout.payment_error_message"));
                }
            });

            const approveDocumentSubscription = wsClient.subscribe(WsTopic.appointmentApproved, () => {
                dispatch(
                    getPatientCarePlan({
                        patientFhirId: currentProfile.fhirId!,
                    }),
                );
                dispatch(
                    getPatientActiveConsultations({
                        patientFhirId: currentProfile.fhirId!,
                    }),
                );
                message.info(t("patientLayout.get_report_message"));
            });
            return () => {
                subscription?.unsubscribe();
                approveDocumentSubscription?.unsubscribe();
                paymentSubscription?.unsubscribe();
            };
        }
    }, [wsClient]);

    useEffect(() => {
        setCanHaveRepeatedConsultation(
            (activeConsultations?.some((x) => x.courseType === 'initial_consultation' && x.status === 'fulfilled') &&
                !activeConsultations?.some((x) => x.paymentStatus === 'pending')) ||
            false,
        );

    }, [activeConsultations]);

    useEffect(() => {
        const checkActiveAppointments = () => {
            const checkable = activeConsultations?.some(s => s.status === 'pending' || s.paymentStatus === 'pending') || carePlan?.status === 'on-hold' || carePlan?.status === 'draft'
            if(checkable) {
                dispatch(
                    getPatientCarePlan({
                        patientFhirId: currentProfile!.fhirId!,
                    }),
                );

                dispatch(
                    getPatientActiveConsultations({
                        patientFhirId: currentProfile!.fhirId!,
                    }),
                );
            }
        }
        const updateAppointmentDataTimer = setInterval(checkActiveAppointments, 10000);
        return () => clearInterval(updateAppointmentDataTimer);
    }, [activeConsultations]);

    useEffect(() => {
        if (carePlan) {
            dispatch(
                getPatientEncountersById({
                    patientFhirId: currentProfile!.fhirId!,
                    params: {
                        carePlanId: carePlan.fhirId!,
                    },
                }),
            );
        }
    }, [carePlan]);

    useEffect(() => {
        if (wsClient || authUser) {
            setDataLoaded(true);
        }
    }, [wsClient, authUser]);

    useEffect(() => {
        if (authUser?.userProfileDTOList) {
            const mappedPatients = authUser.userProfileDTOList
                .filter((x) => x.profileType !== 'End_user')
                .map((x: UserProfileDTO) => ({
                    key: x.fhirId,
                    label: (
                        <>
                            {x.userProfileDetailsDTO?.lastName} {x.userProfileDetailsDTO?.firstName}
                        </>
                    ),
                    onClick: () => {
                        navigate('');
                        dispatch(setCurrentProfile(x));
                    },
                    icon: currentProfile?.fhirId === x.fhirId ? <CheckOutlined className="green"/> : null,
                    disabled: disableMenuActions,
                })) as ItemType[];

            setPatientsMenuItems(mappedPatients);
        }
    }, [authUser, currentProfile, location.pathname, disableMenuActions]);

    const checkLS = (profileId: string) => {
        const patientSettings = ScreenSettingsLocalStorage.getScreenSettings(profileId);
        if (patientSettings) {
            if (
                patientSettings.diagonal !== '' ||
                patientSettings.distance !== '' ||
                !Object.values(patientSettings.colors).some((item) => item) ||
                Object.values(patientSettings.videoAudio).length === 3
            ) {
                dispatch(setPatientWorkspacePrepared(true));
                dispatch(setPatientWorkspaceSettings(patientSettings));
            } else {
                dispatch(setPatientWorkspacePrepared(false));
            }
        } else {
            dispatch(setPatientWorkspacePrepared(false));
            dispatch(setPatientWorkspaceSettings(null));
        }
    };

    return dataLoaded ? (
        <div className={styles.wrapper}>
            <div className="sticky-top">
                <header className={styles.header}>
                    <nav className={styles['header-content']}>
                        <Link to="/">
                            <Logo />
                        </Link>
                        <div className={classNames('col-5 d-flex justify-content-center', styles.menu)}>
                            <NavLink
                                className={({isActive}) => classNames(styles['nav-item'], isActive && styles.active)}
                                to="/dashboard">
                                {t('patientLayout.header.navigators.myOKO')}
                            </NavLink>

                            <NavLink
                                className={({isActive}) => classNames(styles['nav-item'], isActive && styles.active)}
                                to="/appointments">
                                {t('patientLayout.header.navigators.appointments')}{' '}
                                {unhandledMsgs > 0 && <Badge className={styles.badge} size="small" color="#F64D4D"
                                                             count={unhandledMsgs}/>}
                            </NavLink>

                            <NavLink
                                className={({isActive}) => classNames(styles['nav-item'], isActive && styles.active)}
                                to="/medcard">
                                {t('patientLayout.header.navigators.medcard')}
                            </NavLink>
                        </div>
                        {canHaveRepeatedConsultation && (
                            <Link to="/consultation">
                                <Button icon={<CalendarInputIcon/>} className={styles.appointmentLink}>
                                    {t('patientLayout.header.navigators.signUp')}
                                </Button>
                            </Link>
                        )}
                        <div className={classNames('col-3', styles.rightSection)}>
                            <Dropdown
                                menu={{
                                    items: [
                                        ...patientsMenuItems,
                                        {
                                            key: '2',
                                            label: <span
                                                className="blue">{`+ ${t('patientLayout.header.labels.addPatient')}`}</span>,
                                            onClick: () => {
                                                dispatch(switchToEndUser('/create-patient'));
                                            },
                                        },
                                        {
                                            key: '3',
                                            label: (
                                                <Space>
                                                    {t('patientLayout.header.labels.userProfile')}
                                                    {location.pathname === '/profile' &&
                                                        <CheckOutlined className="green"/>}
                                                </Space>
                                            ),
                                            onClick: () => {
                                                dispatch(switchToEndUser('/profile'));
                                            },
                                        },
                                        {
                                            key: '4',
                                            onClick: () => dispatch(logoutLocal({ isEsia: !!authUser.esia })),
                                            label: t('patientLayout.header.labels.exit'),
                                        },
                                    ],
                                }}
                                overlayClassName={styles.dropdownMenu}
                            >
                                <div className={styles.dropdownBtn}>
                                    <ProfileIcon/>
                                    <div className="d-flex flex-column align-items-start justify-content-center ms-2">
                                        <div className="d-flex align-items-center">
                                            <p className={`${styles.dropdownBtnTitle} me-2`}>
                                                {currentProfile?.userProfileDetailsDTO?.lastName} {currentProfile?.userProfileDetailsDTO?.firstName}
                                            </p>
                                            <DropdownArrowIcon/>
                                        </div>
                                        <span
                                            className={styles.dropdownBtnSubtitle}>{t('patientLayout.header.labels.patient')}</span>
                                    </div>
                                </div>
                            </Dropdown>
                        </div>
                    </nav>
                </header>
            </div>

            <section className={styles.mainContent}>
                <Outlet/>
            </section>

            <Footer showSupportBlock={showSupportBlock}/>

            <DocInfoModal/>
        </div>
    ) : null;
};
