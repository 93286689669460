import Icon from '@ant-design/icons';
import { uploadFile, UserDocumentDTOType } from '@api/documentServiceAPI';
import { AddFile, CrossIcon, ErrorFile, Preloader, SuccessFile } from '@icons';
import { selectAuthUser } from '@sliceUser';
import { separateFileNameAndExtension } from '@utils/utils';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './UploadFile.module.scss';

interface IUploadFileProps {
    title: string;
    maxSizeMb: number;
    docType: UserDocumentDTOType;
    extensions?: string;
    subtitle?: string;
    multiple?: boolean;
    changeEvent?: (files: File[] | string[]) => void;
    immediateLoad?: boolean;
    disabled?: boolean;
    fhirId?: string;
    fileList?: FileList
}

export const UploadFile = ({
    title,
    subtitle,
    docType,
    maxSizeMb,
    changeEvent,
    multiple = false,
    extensions = 'image/gif, image/jpeg, image/png, application/pdf',
    immediateLoad = true,
    disabled = false,
    fhirId,
    fileList
}: IUploadFileProps) => {
    const { t } = useTranslation();

    const filePicker = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<FileList | null>(fileList ?? null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);
    const [remoteLoading, setRemoteLoading] = useState<boolean>(false);
    const user = useSelector(selectAuthUser);

    const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const file = files[0];
            if(!extensions?.split(', ').some(s => s === file.type)) {
                setErrorMessage(t('uploadFileComponent.validation.invalidFileType'));
                return;
            }
            if (file) {
                if (file.size / 1024 / 1024 < maxSizeMb) {
                    setSelectedFile(files);
                    setErrorMessage(null);

                    if (immediateLoad) {
                        if (done || remoteLoading) {
                            return;
                        }
                        setRemoteLoading(true);

                        setLoading(true);
                        uploadFile(
                            {
                                fhirId: fhirId ?? user?.id,
                                type: docType,
                                file,
                                ...separateFileNameAndExtension(file.name),
                            },
                            { headers: { 'Content-Type': 'multipart/form-data' } },
                        )
                            .then((res: any) => {
                                setDone(true);
                                setRemoteLoading(false);
                                changeEvent?.([res.data.uuid]);
                            })
                            .catch((err) => {
                                setErrorMessage(t('uploadFileComponent.validation.fileWasNotUploaded'));
                                setDone(false);
                                setRemoteLoading(false);
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    } else {
                        changeEvent?.(Array.from(files));
                    }
                } else {
                    setErrorMessage(t('uploadFileComponent.validation.maxSizeError'));
                }
            }
        } else {
            setErrorMessage(t('uploadFileComponent.validation.commonError'));
        }
    };

    const handlePick = () => {
        if (filePicker.current) {
            filePicker.current.click();
        }
    };

    const handleDelete = () => {
        setSelectedFile(null);
        setDone(false);
        setRemoteLoading(false);
        changeEvent?.([]);
    };

    return (
        <div className={styles['upload-container']}>
            <div>{selectedFile ? <SuccessFile /> : errorMessage ? <ErrorFile /> : <AddFile />}</div>

            <div className={styles.mainContent}>
                <span className={errorMessage ? styles['error-title'] : styles.title}>{errorMessage ? t("uploadFileComponent.errorTitle") : title}</span>

                {selectedFile ? (
                    <span className={styles['uploaded-title']}>{selectedFile[0].name}</span>
                ) : errorMessage ? (
                    <span className={styles['error-subtitle']}>{errorMessage}</span>
                ) : (
                    <span className={styles.subtitle}>
                        {subtitle}, {t('uploadFileComponent.maxSizeMbLabel', { size: maxSizeMb })}
                    </span>
                )}
            </div>

            <div className="d-flex justify-content-end">
                {loading || remoteLoading ? (
                    <Icon className={styles.spinner} component={Preloader} spin />
                ) : selectedFile ? (
                    <button type="button" onClick={handleDelete} className={styles['upload-button']}>
                        <CrossIcon />
                    </button>
                ) : (
                    <button type="button" onClick={handlePick} className={styles['upload-button']}>
                        {errorMessage ? t('uploadFileComponent.repeatButton') : t('uploadFileComponent.uploadButton')}
                        <input
                            disabled={disabled}
                            type="file"
                            className="d-none"
                            ref={filePicker}
                            onChange={handleChangeFile}
                            accept={extensions}
                            multiple={multiple}
                        />
                    </button>
                )}
            </div>
        </div>
    );
};
