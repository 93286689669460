import { getPractitionersForScheduleWithSlots, registerPatientInConsultationSlot } from '@api/mainServiceAPI';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getPractitioners, sendPatientInConsultationSlot, setPatientInConsultationSlot, setPractitioners } from './slice';

export const consultationListenerMiddleware = createListenerMiddleware();

consultationListenerMiddleware.startListening({
    actionCreator: getPractitioners,
    effect: ({ payload }, listenerApi) => {
        const { userId, ...params } = payload;

        getPractitionersForScheduleWithSlots(userId, params, {
            paramsSerializer: {
                encode: (params) => {
                    if (params === 'chiefComplaintCodes[]') {
                        return 'chiefComplaintCodes';
                    } else {
                        return params;
                    }
                },
            },
        }).then((x) => {
            listenerApi.dispatch(setPractitioners(x.data.content));
        });
    },
});

consultationListenerMiddleware.startListening({
    actionCreator: sendPatientInConsultationSlot,
    effect: ({ payload }, listenerApi) => {
        registerPatientInConsultationSlot(payload).then((x) => {
            const slot = x.data[0];

            listenerApi.dispatch(setPatientInConsultationSlot(slot));

            // registerAppointmentPayment(
            //     {
            //         appointmentId: slot.appointmentId,
            //         failUrl: `${process.env.REACT_APP_PAYMENT_URL}-fail?order=${OrderTypes.consultation}&entityId=${slot.appointmentId}`,
            //         returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-ok?order=${OrderTypes.consultation}&entityId=${slot.appointmentId}`,
            //     },
            //     {},
            // ).then((res) => {
            //     if (res.data.formUrl) {
            //         localStorage.setItem('paymentLink', res.data.formUrl!);

            //         listenerApi.dispatch(
            //             getPatientActiveConsultations({
            //                 patientFhirId: payload.patientId!,
            //             }),
            //         );
            //     } else {
            //         window.location.href = window.location.origin;
            //     }
            // });
        });
    },
});
