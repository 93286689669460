/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

export function useFormValidation(form: { getFieldValue: (field: string) => any; getFieldsError: (fields: string[]) => any[] }) {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const fieldIsEmpty = (field: any) => {
        const fieldName = field.name.join('.');
        const fieldValue = form.getFieldValue(fieldName);
        return fieldValue === undefined || [].concat(fieldValue).join().trim() === '';
    };

    const fieldHasError = (field: any) => {
        return field.errors.length > 0;
    };

    const validateForm = (fieldsToCheck: string[], additionalCheck?: () => boolean) => {
        setTimeout(() => {
            const fieldErrors = form.getFieldsError(fieldsToCheck).filter((field) => fieldIsEmpty(field) || fieldHasError(field));
            const additionalCheckRes = additionalCheck && additionalCheck();
            setIsSubmitDisabled(fieldErrors.length > 0 || !!additionalCheckRes);
        });
    };
    return {
        validateForm,
        isSubmitDisabled,
    };
}
