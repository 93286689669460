import { UserDTO, UserProfileDTO, UserProfileDTOProfileType } from '@api/userServiceAPI';

import { t } from 'i18next';
import { DoctorProfileEdit, NurseProfileEdit } from '..';

interface EditProfileDetailsProps {
    employee: UserDTO | undefined;
    profile: UserProfileDTO | undefined;
    profileType: UserProfileDTOProfileType | undefined;
    onSaveProfile: () => void;
    onCloseEditProfile: () => void;
}

export const EditProfileDetails = ({ employee, profileType, profile, onSaveProfile, onCloseEditProfile }: EditProfileDetailsProps) => {
    return (
        <div>
            <h4 className="mb-0">{t('adminEmployeePage.editProfile')}</h4>
            {profileType === UserProfileDTOProfileType.Head_physician || profileType === UserProfileDTOProfileType.Practitioner ? (
                <DoctorProfileEdit employee={employee} profile={profile} onSaveProfile={onSaveProfile} onCloseEditProfile={onCloseEditProfile} />
            ) : profileType === UserProfileDTOProfileType.Nurse ? (
                <NurseProfileEdit employee={employee} profile={profile} onSaveProfile={onSaveProfile} onCloseEditProfile={onCloseEditProfile} />
            ) : (
                <div></div>
            )}
        </div>
    );
};
