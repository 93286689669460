import { ProcedureStatus } from '@enums';
import { AlertIcon, CheckIcon } from '@icons';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './ParticipantProcedureItem.module.scss';

interface ParticipantProcedureItemProps {
    status?: ProcedureStatus;
    progressSeconds?: number;

    /**
     * In seconds
     */
    duration?: number;
}

export const ParticipantProcedureItem = ({ status, duration = 0, progressSeconds = 0 }: ParticipantProcedureItemProps) => {
    const [progressPercentage, setProgressPercentage] = useState<number>(0);

    useEffect(() => {
        if (progressSeconds > 0) {
            let percentage = (100 * progressSeconds) / duration;
            percentage = isFinite(percentage) ? percentage : 0;

            setProgressPercentage(Math.round(percentage));
        }
    }, [progressSeconds]);

    const renderContent = () => {
        switch (status) {
            case ProcedureStatus.failed:
                return <AlertIcon />;

            case ProcedureStatus.finished:
                return <CheckIcon />;

            case ProcedureStatus.started:
            case ProcedureStatus.continued:
            case ProcedureStatus.inProgress:
            case ProcedureStatus.paused:
                return <div className={styles.progressBar} style={{ width: progressPercentage + '%' }}></div>;

            default:
                break;
        }
    };

    return <div className={classNames(styles.procedure_item, styles['procedure_item_' + status])}>{renderContent()}</div>;
};
