export const WsTopic = {
    procedureTopic: '/topic/procedure/',
    kurentoTopic: '/topic/kurento/',
    notificationsTopic: '/topic/notifications/',
    chatTopic: '/topic/chat/',
    appointmentApproved: '/topic/documents-approved/',
    paymentApproved: '/topic/payment-status/'
};

export enum WsProcedureTopicType {
    'startProcedure' = 'startProcedure',
    'stopProcedure' = 'stopProcedure',
    'togglePauseProcedure' = 'togglePauseProcedure',
    'toggleNeedChangeGlasses' = 'toggleNeedChangeGlasses',

    'requestScreenSharing' = 'requestScreenSharing',
    'cancelScreenSharing' = 'cancelScreenSharing',
    'procedureInteraction' = 'procedureInteraction',
    'callDoctor' = 'callDoctor',
    'chatMessage' = 'chatMessage',
    'messageSent' = 'messageSent',
    'messageViewed' = 'messageViewed',
    'startCalibration' = 'startCalibration',
    'calibrationReady' = 'calibrationReady',

    'conclusionSigned' = 'conclusionSigned',
    'screenSettingsChange' = 'screenSettingsChange',
    'screenSettingsUpdate' = 'screenSettingsUpdate',
}

export enum WsKurentoIncomingTopicType {
    'PARTICIPANT_APPROVED' = 'PARTICIPANT_APPROVED',
    'PARTICIPANT_JOINED_LOBBY' = 'PARTICIPANT_JOINED_LOBBY',
    'PARTICIPANT_LEFT_LOBBY' = 'PARTICIPANT_LEFT_LOBBY',

    'SESSION_CREATED' = 'SESSION_CREATED',
    'SESSION_CLOSED' = 'SESSION_CLOSED',
    'SESSION_STARTED' = 'SESSION_STARTED',
    'SESSION_ALREADY_EXIST' = 'SESSION_ALREADY_EXIST',

    'RECEIVE_VIDEO_ANSWER' = 'RECEIVE_VIDEO_ANSWER',
    'RECEIVE_VIDEO_SCREEN_ANSWER' = 'RECEIVE_VIDEO_SCREEN_ANSWER',
    'ICE_CANDIDATE' = 'ICE_CANDIDATE',
    'ICE_CANDIDATE_SHARE' = 'ICE_CANDIDATE_SHARE',

    'HOST_AUDIO_CHANGED' = 'HOST_AUDIO_CHANGED',
    'HOST_VIDEO_CHANGED' = 'HOST_VIDEO_CHANGED',

    'PARTICIPANT_ARRIVED' = 'PARTICIPANT_ARRIVED',
    'PARTICIPANT_LEFT' = 'PARTICIPANT_LEFT',
    'PARTICIPANT_KICKED' = 'PARTICIPANT_KICKED',
    'PARTICIPANT_AUDIO_CHANGED' = 'PARTICIPANT_AUDIO_CHANGED',
    'PARTICIPANT_STARTED_SHARE' = 'PARTICIPANT_STARTED_SHARE',
    'PARTICIPANT_STOPPED_SHARE' = 'PARTICIPANT_STOPPED_SHARE',

    'ERROR' = 'ERROR',
}

export enum WsKurentoOutgoingTopicType {
    'JOIN_LOBBY' = 'JOIN_LOBBY',
    'LEAVE_LOBBY' = 'LEAVE_LOBBY',
    'JOIN_SESSION_APPROVE' = 'JOIN_SESSION_APPROVE',

    'CREATE_SESSION' = 'CREATE_SESSION',
    'JOIN_SESSION' = 'JOIN_SESSION',
    'LEAVE_SESSION' = 'LEAVE_SESSION',
    'CLOSE_SESSION' = 'CLOSE_SESSION',
    'KICK_PARTICIPANT' = 'KICK_PARTICIPANT',

    'RECEIVE_VIDEO_FROM' = 'RECEIVE_VIDEO_FROM',
    'RECORD' = 'RECORD',

    'START_SCREEN_SHARE' = 'START_SCREEN_SHARE',
    'STOP_SCREEN_SHARE' = 'STOP_SCREEN_SHARE',
    'ON_ICE_CANDIDATE' = 'ON_ICE_CANDIDATE',
    'ON_OFF_MEDIA_TYPE' = 'ON_OFF_MEDIA_TYPE',

    'START_VIDEO_SCREEN_RECORD' = 'START_VIDEO_SCREEN_RECORD',
    'END_VIDEO_SCREEN_RECORD' = 'END_VIDEO_SCREEN_RECORD',
}
