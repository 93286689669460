import { CheckOutlined } from '@ant-design/icons';
import { UserProfileDTO } from '@api/userServiceAPI';
import { DropdownArrowIcon, ProfileIcon } from '@icons';
import { logoutLocal, selectAuthUser, selectCurrentProfile, setCurrentProfile } from '@sliceUser';
import { Dropdown, Space } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Footer } from '../../_general/Footer/Footer';

import styles from './UserLayout.module.scss';
import { Logo } from 'src/components/Logo/Logo';

export const UserLayout = () => {
    const authUser = useSelector(selectAuthUser);
    if (!authUser) {
        return null;
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentProfile = useSelector(selectCurrentProfile);

    const [patientsMenuItems, setPatientsMenuItems] = useState<ItemType[]>([]);
    const [isCreatePatientPage, setIsCreatePatientPage] = useState(false);

    useEffect(() => {
        if (authUser?.userProfileDTOList) {
            const mappedPatients = authUser.userProfileDTOList
                .filter((x) => x.profileType !== 'End_user')
                .map((x: UserProfileDTO) => ({
                    key: x.fhirId,
                    label: x.userProfileDetailsDTO?.lastName + ' ' + x.userProfileDetailsDTO?.firstName,
                    onClick: () => {
                        navigate('');
                        dispatch(setCurrentProfile(x));
                    },
                    icon: null,
                })) as ItemType[];

            setPatientsMenuItems(mappedPatients);
        }
    }, [authUser, currentProfile]);

    useEffect(() => {
        setIsCreatePatientPage(location.pathname === '/create-patient');
    }, [location.pathname]);

    return (
        <div className={styles.wrapper}>
            <div className="sticky-top">
                <header className={styles.header}>
                    <nav className={styles['header-content']}>
                        <Link to="/">
                            <Logo />
                        </Link>
                        <div className="col-5 d-flex justify-content-center"></div>
                        <div className="col-2">
                            <Dropdown
                                menu={{
                                    items: [
                                        ...patientsMenuItems,
                                        !isCreatePatientPage
                                            ? {
                                                  key: '2',
                                                  label: (
                                                      <Link className="blue" to="/create-patient">{`+ ${t(
                                                          'patientLayout.header.labels.addPatient',
                                                      )}`}</Link>
                                                  ),
                                              }
                                            : null,
                                        {
                                            key: '3',
                                            label: (
                                                <Space>
                                                    {t('patientLayout.header.labels.userProfile')}
                                                    {location.pathname === '/profile' && <CheckOutlined className="green" />}
                                                </Space>
                                            ),
                                            onClick: () => {
                                                if (location.pathname !== '/profile') {
                                                    navigate('/profile');
                                                }
                                            },
                                        },
                                        {
                                            key: '4',
                                            onClick: () => dispatch(logoutLocal({ isEsia: !!authUser.esia})),
                                            label: t('patientLayout.header.labels.exit'),
                                        },
                                    ],
                                }}
                                overlayClassName={styles.dropdownMenu}
                            >
                                <div className={styles.dropdownBtn}>
                                    <ProfileIcon />
                                    <div className="d-flex flex-column align-items-start justify-content-center ms-2">
                                        <div className="d-flex align-items-center">
                                            <p className={`${styles.dropdownBtnTitle} me-2`}>
                                                {authUser?.lastName} {authUser?.firstName}
                                            </p>
                                            <DropdownArrowIcon />
                                        </div>
                                        <span className={styles.dropdownBtnSubtitle}>{t('patientLayout.header.labels.representative')}</span>
                                    </div>
                                </div>
                            </Dropdown>
                        </div>
                    </nav>
                </header>
            </div>

            <section className={styles.mainContent}>
                <Outlet />
            </section>

            <Footer showSupportBlock={isCreatePatientPage} />
        </div>
    );
};
